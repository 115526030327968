import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    ACTION_CREATE,
    ACTION_DELETE,
    ACTION_UPDATE,
    DATA_EMPTY,
    DATA_FAILED,
    DATA_LOADED
} from "../../../../component/Constants";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as dayjs from "dayjs";
import EmailService from "../../../../types/integration/EmailService";
import { findEmailIntegrationValue } from "../../../../helpers/utils";
import ConditionalRender from "../../../../helpers/ConditionalRender";

function List(props: { loading: number, emailIntegration: EmailService[], refresh: Function }) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);

    function refresh() {
        props.refresh();
    }

    useEffect(() => {
        if (props.loading === DATA_LOADED) {
            {/*@ts-ignore*/}
            const showButton = !props.emailIntegration.length || (!props.emailIntegration.find(emil => emil.name === 'mail.smtp.host'))
            setShowCreate(showButton)
        }
    }, [props.loading]);

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = [{
        host: findEmailIntegrationValue('host', props.emailIntegration),
        port: findEmailIntegrationValue('port', props.emailIntegration),
        enable: findEmailIntegrationValue('enable', props.emailIntegration),
        url: findEmailIntegrationValue('url', props.emailIntegration)
    }]

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="far fa-envelope fa-fw" aria-hidden="true"/>
                    Email integration
                    <span className="badge bg-info" data-tip="Total Email integration count">{data[0].host ? 1 : 0}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseEmail" aria-expanded="false" aria-controls="collapseEmail"/>
                </div>
                <div id="collapseEmail" className="card-body collapse show">

                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"/>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>No Email Integrations
                                    Found - Click to Refresh
                                </button>
                            </p>
                        </div>
                    ) : (
                        props.loading === DATA_FAILED ? (
                            <div className="w-100 text-center text-muted my-3">
                                <i className="fal fa-ban fa-fw fa-2x text-danger"/>
                                <p>
                                    <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve
                                        Email Integrations - Click to Refresh
                                    </button>
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row row-cols-1 row-cols-md-2 table-search">
                                    <div className="col col-md-9">
                                        {/* show create button if host filed which is required is not present */}
                                        <ConditionalRender if={showCreate}>
                                            <Link to={{
                                                pathname: `/admin/integrations/email/create`,
                                                state: {
                                                    action: ACTION_CREATE,
                                                    emailIntegration: props.emailIntegration
                                                }
                                            }} role="button" className="btn btn-sm btn-success">
                                                <i className="fal fa-plus-square fa-fw"/><span>Create</span>
                                            </Link>
                                        </ConditionalRender>
                                        <CSVLink role="button"
                                                 headers={[{ label: 'Host', key: 'host' }, {
                                                     label: 'Port',
                                                     key: 'port'
                                                 }, { label: 'URL', key: 'url' }, { label: 'Enabled', key: 'enable' }]}
                                                 data={data}
                                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Integrations - Email.csv`}
                                                 className="btn btn-sm btn-primary">
                                            <i className="fal fa-file-export fa-fw"/><span>Export</span>
                                        </CSVLink>
                                        <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                            className="far fa-undo"/><span>Clear</span></button>
                                    </div>
                                    <div className="col col-md-3">
                                        <input type="text" autoFocus className="form-control form-control-sm"
                                               placeholder="Search" value={tableFilter} data-lpignore={true}
                                               onChange={(e) => setTableFilter(e.target.value.toLowerCase())}/>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Host</th>
                                            <th scope="col">Port</th>
                                            <th scope="col">Url</th>
                                            <th scope="col">Enabled</th>
                                            <th className="actions" scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data[0].host && data.filter(email => email.port.toLowerCase().includes(tableFilter) || email.host.toLowerCase().includes(tableFilter) || email.url.toLowerCase().includes(tableFilter)).map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    {item.host}
                                                </th>
                                                <td className="concatenate">
                                                    {item.port}
                                                </td>
                                                <td className="concatenate">
                                                    {item.url}
                                                </td>
                                                <td className="concatenate">
                                                    <div className='form-check form-switch'>
                                                        <input checked={item.enable}
                                                               type="checkbox"
                                                               id="enabled"
                                                               className="form-check-input"
                                                               disabled={true}
                                                               data-lpignore={true}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="actions">
                                                    <Link to={{
                                                        pathname: `/admin/integrations/email/edit`,
                                                        state: { action: ACTION_UPDATE, data }
                                                    }} role="button" className="btn btn-sm btn-primary">
                                                        <i className="fal fa-edit fa-fw"/><span>Edit</span>
                                                    </Link>
                                                    <Link to={{
                                                        pathname: `/admin/integrations/email/delete`,
                                                        state: { action: ACTION_DELETE, data }
                                                    }} role="button" className="btn btn-sm btn-danger">
                                                        <i className="fal fa-trash-alt fa-fw"/><span>Delete</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
