import { createContext, useContext } from 'react';

// Types.
import InstanceTarget from '../types/instance/InstanceTarget';

// Set the internal context object.
let instances: InstanceTarget[] = [];

// Define the context type, including the update function.
export type InstanceContextType = {
    instances: InstanceTarget[];
    setInstances: (InstanceTargets: InstanceTarget[]) => void;
}

// Define the context object.
export const InstanceContext = createContext<InstanceContextType>({ instances: instances, setInstances: instances => console.warn('No instances set.', instances) });
InstanceContext.displayName = 'Instances';

// Export the update function for the context object.
export const useInstanceTargets = () => useContext(InstanceContext);