// React.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Contexts.
import { useLicences } from '../context/LicenceContext';

// Components.
import NoData from './NoData';

// Helpers.
import { highchartsCredits } from '../helpers/utils';

export default function HighchartsWithData (props: { error: string  | null,
	loading: boolean, length: number, options: object })
{
	Highcharts.useSerialIds (process.env.NODE_ENV === 'test');
	const { error, loading, length, options } = props;

	// Override Highcharts credits.
	const { licences } = useLicences ();
	(options as any)['credits'] =
	{
		// Could add href, position and text.
		enabled: highchartsCredits (licences)
	};

	if (!error && !loading && length > 0)
	{
		return (
			<HighchartsReact constructorType="chart" highcharts={Highcharts}
				options={options} />
		)
	}
	else
	{
		return (
			<NoData error={error} loading={loading} length={length} />
		)
	}
}
