import React, { useMemo } from 'react';

// Helper.
import Helper from '../../../helpers/Helper';

import LazyLoad from 'react-lazyload';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';
import Statement from '../../../types/instance/Statement';

// Constants.
import { REPORT_PERIOD_CURRENT, REPORT_PERIOD_PREVIOUS } from '../../../component/Constants';

// Components.
//import StatementRow from './StatementRow';
import StatementDetails from './StatementDetails';

function Statements(props: { period: Period, chartCategories: string[], matchedInstances: InstanceTarget[], timeA: null | number, timeB: null | number, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], statements: Statement[], statementsA: Statement[], statementsB: Statement[] }) {

    const statementsTable = useMemo(() => {

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th className="col-2" scope="col">
                            &nbsp;
                        </th>
                        <th className="col-5 text-center highlight" scope="col">
                            {Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_PREVIOUS)}
                        </th>
                        <th className="col-5 text-center" scope="col">
                        {Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_CURRENT)}
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">
                            #
                        </th>
                        <th className="text-end highlight" scope="col">
                            Wait Time
                        </th>
                        <th className="text-end" scope="col">
                            Wait Time
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.statements.map((statement: Statement, index: number) => (
                        <StatementDetails 
                            period={props.period}
                            key={index}
                            chartCategories={props.chartCategories}
                            matchedInstances={props.matchedInstances}
                            timeA={props.timeA} timeB={props.timeB}
                            plotBands={props.plotBands} plotLines={props.plotLines} 
                            statement={statement} 
                            statementsA={props.statementsA} 
                            statementsB={props.statementsB} />
                    ))}
                </tbody>
            </table>
        )

    }, [props.period, props.chartCategories, props.matchedInstances, props.timeA, props.timeB, props.statements, props.statementsA, props.statementsB, props.plotBands, props.plotLines]);

    return (
        <React.Fragment>
            <h3 className="mt-3">Top statements</h3>
            <LazyLoad height={200} offset={100} once={true}>
                {statementsTable}
            </LazyLoad>
        </React.Fragment>
    );
}

export default Statements;
