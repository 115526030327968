import { ChangeEvent } from 'react';
import ThirdPartyLinksType from "../../types/ThirsPartyLinks";

const ThirdPartyLinks = (props: { links: ThirdPartyLinksType[], setLinks: Function, instanceId: number | undefined }) => {
    const {links, setLinks} = props;

    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const updatedFields = [...links];
        // @ts-ignore
        updatedFields[index][name] = value;
        setLinks(updatedFields);
    };

    const handleAddField = () => {
        setLinks([...links, {name: '', url: '', validName: true, validUrl: true, datasourceid: props.instanceId}]);
    };

    const handleRemoveField = (index: number) => {
        const updatedFields = [...links];
        updatedFields.splice(index, 1);
        setLinks(updatedFields);
    };

    const handleFieldBlur = (pos: number, type: string) => {
        const updatedLinks = [...links];
        if (type === 'name') {
            updatedLinks[pos].validName = !!links[pos].name.trim()
        }
        if (type === 'url') {
            updatedLinks[pos].validUrl = !!links[pos].url.trim()
        }
        setLinks(updatedLinks)
    }

    return (<>
            <div className="col-xs-12 col-md-3">
                                    <span><label htmlFor="3rdparty" className="reminder">3rd-Party Links</label></span>
                <span className="badge bg-info" data-tip="Total 3rd-Party Links">{links.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
                {links.map((field: ThirdPartyLinksType, index: number) => (
                    <div key={index} className='d-flex g-0 mb-1'>
                        <input
                            type="text"
                            name="name"
                            className={`form-control ${field.validName === false ? 'is-invalid' : ''}`}
                            placeholder="Name"
                            onBlur={() => handleFieldBlur(index, 'name')}
                            value={field.name}
                            onChange={(event) => handleChange(index, event)}
                        />
                        <input
                            type="text"
                            name="url"
                            className={`form-control mx-1 ${field.validUrl === false ? 'is-invalid' : ''}`}
                            placeholder="Link"
                            onBlur={() => handleFieldBlur(index, 'url')}
                            value={field.url}
                            onChange={(event) => handleChange(index, event)}
                        />
                        <button type="button" className="btn btn-small btn-dark"
                                onClick={() => handleRemoveField(index)}><i className="fal fa-times"/></button>
                    </div>
                ))}
                <button className="btn btn-success" onClick={handleAddField}><i
                    className='fal fa-square-plus'/><span>Add more</span>
                </button>
            </div>
            <div className="w-100"/>
        </>


    );
};

export default ThirdPartyLinks;
