import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as dayjs from 'dayjs';

import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';

// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import ExecutionPlan from '../../types/instance/ExecutionPlan';
import CockroachPlan from '../instance/plan/CockroachPlan';
import Db2Plan from '../instance/plan/Db2Plan';
import MySqlPlan from '../instance/plan/MySqlPlan';
import OraclePlan from '../instance/plan/OraclePlan';
import PostgresPlan from '../instance/plan/PostgresPlan';
import SapHanaPlan from '../instance/plan/SapHanaPlan';
import SqlBatchPlan from '../instance/plan/SqlBatchPlan';
import GenericPlan from '../instance/plan/GenericPlan';
import Statement from "../../types/instance/Statement";

//import MySqlPlan from './plan/MySqlPlan';
//import PostgrePlan from './plan/PostgrePlan';

// Constants.

import {
    INSTANCE_TYPE_COCKROACHDB,
    INSTANCE_TYPE_DB2,
    INSTANCE_TYPE_MYSQL,
    INSTANCE_TYPE_ORACLE,
    INSTANCE_TYPE_POSTGRES,
    INSTANCE_TYPE_SAPHANA,
    INSTANCE_TYPE_SQLSERVER
} from '../Constants';

// Helpers.
import { databaseLabel, formatNumber } from '../../helpers/utils';
import { TimeRangeContext } from "../../context/TimeRangeContext";
import ConditionalRender from "../../helpers/ConditionalRender";
import { getAPIString, isGenericStatement } from "../../views/instances/tabs/utils";
import ChatButton from "../chatGPT/ChatButton";
import Prompts from "../chatGPT/Prompts";
import api from "../../api/Base";

// Components.

function ExecutionPlans(props: { instance: InstanceTarget, batchId?: string, statementId?: string, setExecutionPlanOptions: Function, statement?: Statement }) {
    const [executionPlans, setExecutionPlans] = useState<ExecutionPlan[]>([]);
    const [executionPlan, setExecutionPlan] = useState<ExecutionPlan>();
    const [loading, setLoading] = useState<boolean>(true);
    const timeRangeContext = useContext(TimeRangeContext)

    useEffect(() => {

        function getTotalCost(sqlplan: string): number {
            let cost: number = 0;

            try {
                // Try to get the first cost value
                // (which should be the accumulative total for the plan).
                switch (props.instance.type) {
                    case INSTANCE_TYPE_COCKROACHDB:
                        break;

                    case INSTANCE_TYPE_DB2:
                        cost = JSON.parse(sqlplan)[0]['total_cost'];
                        break;

                    case INSTANCE_TYPE_MYSQL:
                        cost = JSON.parse(sqlplan)
                            ['query_block']['cost_info']['query_cost'];
                        break;

                    case INSTANCE_TYPE_ORACLE:
                        cost = JSON.parse(sqlplan)[0]['cost'];
                        break;

                    case INSTANCE_TYPE_POSTGRES:
                        cost = JSON.parse(sqlplan)[0]['Plan']['Total Cost'];
                        break;

                    case INSTANCE_TYPE_SAPHANA:
                        cost = JSON.parse(sqlplan)[0]['subtree_cost'];
                        break;

                    case INSTANCE_TYPE_SQLSERVER:
                        // Plan is in XML format.
                        break;

                    default:
                        // Unknown instance type.
                        console.error('Unknown instance type.',
                            props.instance.type);
                        break;
                }
            } catch (error: any) {
                console.warn('Failed to retrieve execution plan cost.', error);
            }

            return cost;
        }

        const processExecutionPlans = (plans: ExecutionPlan[]) => {
            for (let index = 0; index < plans.length; index++) {

                let executionPlan = plans[index],
                    descriptor: string = '',
                    cost: number = 0,
                    valid: boolean = executionPlan.sqlplan !== null;

                if (executionPlan.earliest && executionPlan.latest) {

                    // We have both a start and end date for the execution plan.
                    if (executionPlan.earliest === executionPlan.latest) {

                        // Identical start and end dates, so show just the one date value.
                        descriptor = dayjs.default(executionPlan.earliest).format('DD MMM YYYY HH:mm');
                    } else {

                        // Different start and end dates, so display a range.
                        if (dayjs.default(executionPlan.earliest).format('YYYY-MM-DD') === dayjs.default(executionPlan.latest).format('YYYY-MM-DD')) {

                            // Same day, so shorten the descriptor.
                            descriptor = `${dayjs.default(executionPlan.earliest).format('DD MMM YYYY HH:mm')} to ${dayjs.default(executionPlan.latest).format('HH:mm')}`;
                        } else {

                            // Different days, so full descriptor required.
                            descriptor = `${dayjs.default(executionPlan.earliest).format('DD MMM YYYY HH:mm')} to ${dayjs.default(executionPlan.latest).format('DD MMM YYYY HH:mm')}`;
                        }
                    }
                }

                // Try to find the execution plan cost - this can be hit-and-miss, and varies between instance types.
                if (valid) {
                    cost = getTotalCost(executionPlan.sqlplan);
                }

                // Update the execution plan options.
                executionPlan.planId = index;
                executionPlan.descriptor = descriptor;
                executionPlan.cost = cost;
                executionPlan.valid = valid;

                plans[index] = executionPlan;
            }

            // Filter out plans with no description or plan to explain.
            plans = plans.filter(plan => plan.valid && plan.descriptor && plan.sqlplan !== null);

            setExecutionPlans(plans);
            setLoading(false)

            if (plans.length > 0) {
                // Set the default plan to the first.
                setExecutionPlan(plans[0]);
            }
            // Return the total execution plan count back to the parent component.
            props.setExecutionPlanOptions.call(null, plans.filter(plan => plan.valid && plan.descriptor));
        }

        const getExecutionPlans = async() => {

            let planFormat: string = '&format=json';

            const apiString = getAPIString(props.statementId || props.batchId)

            if (props.batchId === undefined && props.statementId === undefined) {
                console.error('Execution plan component requires either a batch or statement ID property to be set.')
                return;
            }

            if (props.instance.type === INSTANCE_TYPE_SQLSERVER) {
                planFormat = '&format=xml'
            }


            api.get(`sql/plan?${timeRangeContext.getTimeRangeQueryString()}&sort=earliest&id=${props.instance.id}${apiString}${planFormat}`)
                .then(async(response: { data: ExecutionPlan[]; }) => {
                    let plans: ExecutionPlan[] = response.data;

                    // if it's statement search check also for group plans (DBMAR-1657)
                    const isGeneric = isGenericStatement()
                    if (!plans.length && isGeneric) {
                        // Make another API call with the "grouphash" parameter
                        api.get(`sql/plan?${timeRangeContext.getTimeRangeQueryString()}&sort=earliest&id=${props.instance.id}&grouphash=${props.statementId}${planFormat}`)
                            .then(async(response: { data: ExecutionPlan[]; }) => {
                                plans = response.data;
                                processExecutionPlans(plans);
                            })
                            .catch((error: any) => {
                                console.error('Failed to retrieve execution plans with grouphash.', error);
                            });
                    } else {
                        processExecutionPlans(plans);
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve execution plans.', error);
                });
        }

        getExecutionPlans();

    }, [props.instance, props.batchId, props.statementId, timeRangeContext]);

    function selectExecutionPlan(planId: number) {

        // Get selected plan.
        const matchedPlan = executionPlans.filter(item => item.planId === planId);

        if (matchedPlan.length > 0) {
            setExecutionPlan(matchedPlan[0]);
        }
    }

    function showPlan() {
        // Keep the compiler happy: the execution plan should not be null here.
        if (!executionPlan) return (<></>);

        switch (props.instance.type) {
            case INSTANCE_TYPE_COCKROACHDB:
                return (<CockroachPlan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_DB2:
                return (<Db2Plan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_MYSQL:
                return (<MySqlPlan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_ORACLE:
                return (<OraclePlan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_POSTGRES:
                return (<PostgresPlan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_SAPHANA:
                return (<SapHanaPlan executionPlan={executionPlan}/>);

            case INSTANCE_TYPE_SQLSERVER:
                return (<SqlBatchPlan executionPlan={executionPlan}/>);

            default:
                return (<GenericPlan executionPlan={executionPlan}/>);
        }
    }

    // Function to trigger the json download
    function downloadJson() {
       const isJSONFormat = (value: any) => {
            try {
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        };
        if (executionPlan) {
            const isJSON = isJSONFormat(executionPlan.sqlplan);
            if (isJSON) {
                const blob = new Blob([executionPlan.sqlplan], {type: 'application/json'});
                saveAs(blob, `${props.instance.name} - ${((props.batchId !== undefined) ? props.batchId : props.statementId)} - Execution Plan - ${executionPlan.descriptor}.json`);
            } else {
                // Display the text content with a monospaced font in a <pre> tag
                const executionPlanText = executionPlan.sqlplan
                const preTag = document.createElement('pre');
                preTag.style.fontSize = '14px';
                preTag.style.fontFamily = 'Courier New, Courier, monospace';
                preTag.textContent = executionPlanText;
                document.body.appendChild(preTag);

                const blob = new Blob([executionPlanText], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, `${props.instance.name} - ${((props.batchId !== undefined) ? props.batchId : props.statementId)} - Execution Plan - ${executionPlan.descriptor}.txt`);
            }
        }
    }

    return (
        <div id="plans" className="tab-pane" role="tabpanel" aria-labelledby="plans-tab">
            <div className="card">
                <div className="card-header sticky-card-header">
                    <i className="fal fa-ruler-triangle fa-fw" aria-hidden="true"/>
                    Execution Plans
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapsePlans" aria-expanded="false" aria-controls="collapsePlans"/>
                    <div className="btn-group float-end" role="group">
                        <ConditionalRender if={props.statement && executionPlan?.sqlplan}>
                            {/*@ts-ignore*/}
                            <ChatButton value={Prompts.getStatementTextPrompt(props.statement, executionPlan?.sqlplan.toString(), props.instance.type)}/>
                        </ConditionalRender>
                        {executionPlan && (<div><ConditionalRender if={props.instance.type === INSTANCE_TYPE_SQLSERVER}>
                            <CSVLink role="button" data={executionPlan.sqlplan}
                                     className="btn btn-xsm btn-primary float-end ms-1"
                                     download={`${props.instance.name} - ${((props.batchId !== undefined) ? props.batchId : props.statementId)} - Execution Plan - ${executionPlan.descriptor}.sqlplan`}>Download
                                Plan</CSVLink>
                        </ConditionalRender>
                            <ConditionalRender if={props.instance.type !== INSTANCE_TYPE_SQLSERVER}>
                                <button onClick={downloadJson} className="btn btn-xsm btn-primary float-end ms-1">
                                    Download Plan
                                </button>
                            </ConditionalRender></div>)}
                        {executionPlans.length > 1 && (
                            <React.Fragment>
                                <button type="button" className="btn btn-xsm btn-primary dropdown-toggle float-end ms-1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Select Plan
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="executionPlans">
                                    {executionPlans.filter(plan => plan.valid && plan.descriptor).map((plan: ExecutionPlan) => (
                                        <li key={plan.planId}>
                                            <span
                                                className={`dropdown-item ${((plan.planId === executionPlan?.planId) ? 'active' : '')}`}
                                                role="button" onClick={() => selectExecutionPlan(plan.planId)}>
                                                {plan.descriptor}
                                                {plan.cost !== undefined && (
                                                    <span className="cost">(cost {formatNumber(plan.cost)})</span>
                                                )}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div id="collapsePlans" className="card-body collapse show">
                    <ConditionalRender if={loading}>
                        <div className="w-100 text-center text-muted mt-3">
                            <div className="loader spinner chartSpinner">
                            </div>
                            <p className="mt-3">
                                Loading data...
                            </p>
                        </div>
                    </ConditionalRender>

                    <ConditionalRender if={!loading && executionPlan === undefined}>
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x text-muted"/>
                            <p>No Execution Plans Found</p>
                        </div>
                    </ConditionalRender>
                    <ConditionalRender if={!loading && executionPlan}>
                        <div className='execution-plan'>
                            <p>The execution plan below is for the period <span
                                className="fw-bold">{executionPlan?.descriptor}
								</span> for the {databaseLabel(props.instance.type)} <span
                                className="fw-bold">{executionPlan?.database}
								</span> and username <span className="fw-bold">{executionPlan?.username}</span>.
                            </p>
                            {showPlan()}
                        </div>
                    </ConditionalRender>
                </div>
            </div>
        </div>
    );
}

ExecutionPlans.propTypes = {
    setExecutionPlanOptions: PropTypes.func.isRequired
};

export default ExecutionPlans;
