// React.
import { useEffect, useState } from 'react';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';

// Components.
import SqlStatisticChart from '../../../component/instance/chart/SqlStatisticChart';
import StatementSqlStatisticsTable from '../../../component/instance/table/StatementSqlStatisticsTable';

// Helper.
import { archiverUrl, fetchResults } from '../../../helpers/utils';
import {OracleSqlHashTimeSlice} from "../types";

export default function SqlStatistics (props: {period: Period, instance: InstanceTarget}) {
    const { period, instance } = props;

    const [sqlStatistics, setSqlStatistics] = useState<OracleSqlHashTimeSlice[]> ([]);
    const [loading, setLoading] = useState<boolean> (true);
    const [error, setError] = useState<string | null> (null);
    useEffect (() =>
    {
        async function load ()
        {
            try
            {
                setLoading (true);
                const results: OracleSqlHashTimeSlice[][] = await fetchResults (
                    [
                        fetch (archiverUrl (2) + '/sql/statistic/time' +
                            '?from=' + period.api.current.from +
                            '&to=' + period.api.current.to +
                            '&tz=' + period.api.timezone +
                            '&id=' + instance.id)
                    ]);

                if (active)
                {
                    setLoading (false);
                    setSqlStatistics(results[0]);
                }
            }
            catch (x: any)
            {
                console.log (x.message);
                setError (x.message);
                setLoading (false);
                setSqlStatistics ([]);
            }

            return true;
        }

        let active: boolean = true;
        load ();
        return () =>
        {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [period.api, instance.id]);

    return (<>
        <div className="row row-cols-1">
            <div id="statements" className="tab-pane fade show active" role="tabpanel" aria-labelledby="statements-tab">
                <StatementSqlStatisticsTable instance={props.instance} />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="duration"
                                   title="Duration (ms)" />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="executions"
                                   title="Executions" />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="cpu_time_milliseconds"
                                   title="CPU time (ms)" />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="physical_reads"
                                   title="Physical reads" />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="logical_reads"
                                   title="Logical Reads" />
                <SqlStatisticChart loading={loading} error={error}
                                   data={sqlStatistics} statistic="logical_writes"
                                   title="Logical writes" />
            </div>
        </div>
    </>)
}
