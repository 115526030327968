import {useContext, useEffect, useMemo, useState} from "react";
import {InstanceContext} from "../../context/InstanceContext";
import DatePicker from "react-datepicker";
import {FilterContext} from "../../context/FilterContext";
import {InstanceComparisonOptionsProps} from "./types";
import InstanceTarget from "../../types/instance/InstanceTarget";

const InstanceComparisonOptions = (props: InstanceComparisonOptionsProps) => {
    const {instances} = useContext(InstanceContext);
    const {filters} = useContext (FilterContext);

    const [instanceId, setInstanceId] = useState<number>(props.instanceId)
    const [instanceType, setInstanceType] = useState<string | undefined>(props.instanceType)
    const [instanceName, setInstanceName] = useState<string>(props.instanceName)
    const [snapshotId, setSnapshotId] = useState<number | undefined>(props.snapshotId)
    const [periodStart, setPeriodStart] = useState<Date | null>(props.periodStart)
    const [periodEnd, setPeriodEnd] = useState<Date | null>(props.periodEnd)

    const availableInstances = useMemo(() => {
        return instances.filter((instance: InstanceTarget) => instance.collectsqlstatistics)
    }, [instances])


    useEffect(() => {
        props.onOptionsChange({instanceId, instanceType, snapshotId, periodStart, periodEnd, instanceName})
    }, [instanceId, snapshotId, periodEnd, periodStart, instanceType, instanceName])

    const onInstanceSelect = (e: any) => {
        const intId = parseInt(e.target.value, 10)
        setInstanceId(intId);
        setInstanceType(availableInstances.find(i => i.id == intId)?.type);
        setInstanceName(availableInstances.find(i => i.id == intId)?.name || '');
    }

    useEffect(() => {
        if (instanceId === -1 && props.instanceId !== -1) {
            setInstanceId(props.instanceId)
            setInstanceType(props.instanceType)
            setInstanceName(props.instanceName)
            setSnapshotId(props.snapshotId)
        }
    }, [props.instanceId])

    useEffect(() => {
        if (instanceId && !instanceType) {
            setTimeout(() => {
                setInstanceType(availableInstances.find(i => i.id == instanceId)?.type);
                setInstanceName(availableInstances.find(i => i.id == instanceId)?.name || '');
            }, 0)
        }
    }, [])

    return <div className="row row-cols-lg-12 mt-3" key={props.instanceId}>
        <div className="col-xs-4 col-md-4 col-lg-4">
            <span className="required"><label>Instance</label></span>
            <select className="form-select"
                    value={instanceId}
                    disabled={props.loading}
                    aria-label="Select Instance"
                    onChange={(e) => onInstanceSelect(e)}>
                <option key={-1} value="-1" disabled={true}>Select Instance</option>
                {availableInstances.sort((a, b) => a.name.localeCompare(b.name)).map((instance, key) => <option key={key} value={instance.id}>{instance.name}</option>)}
            </select>
        </div>
        <div className="col-xs-4 col-md-4 col-lg-4">
            <span className="required"><label htmlFor="period-start" className="reminder" data-tip="The start time to compare against">Period Start</label></span><br />
            <DatePicker
                name="period-start"
                className={`form-control w-100`}
                dateFormat="yyyy/MM/dd HH:mm"
                placeholderText="yyyy/MM/dd"
                popperPlacement="auto"
                showTimeSelect
                timeFormat="HH:mm"
                maxDate={null}
                onChange={(date: Date) => setPeriodStart(date)}
                selected={periodStart}
                shouldCloseOnSelect={true} />
        </div>
        <div className="col-xs-4 col-md-4 col-lg-4">
            <span className="required"><label htmlFor="period-end" className="reminder"
                                              data-tip="The end time to compare against">Period End</label></span><br/>
            <DatePicker
                name="period-end"
                className={`form-control w-100`}
                dateFormat="yyyy/MM/dd HH:mm"
                placeholderText="yyyy/MM/dd"
                popperPlacement="auto"
                showTimeSelect
                timeFormat="HH:mm"
                maxDate={null}
                onChange={(date: Date) => setPeriodEnd(date)}
                selected={periodEnd}
                shouldCloseOnSelect={true}/>
        </div>
        <div className="col-xs-4 col-md-4 col-lg-3 d-none">
            <label>Saved Snapshot</label>
            <select className="form-select"
                    value={snapshotId}
                    disabled={props.loading}
                    aria-label="Select Snapshot"
                    onChange={(e) => {
                        setSnapshotId(parseInt(e.target.value, 10));
                    }}>
                <option key={-1} value="-1">None</option>
                <optgroup label="Saved snapshots">
                    {filters.map((snapshot, key) => <option key={key} value={snapshot.id}>{snapshot.name}</option>)}
                </optgroup>
            </select>
        </div>
    </div>
}

export default InstanceComparisonOptions;
