import { Component } from 'react';
import PropTypes from "prop-types";
import Period from "../../../types/Period";
import Helmet from "react-helmet";
import Breadcrumb from "../../../container/breadcrumb";
import { DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from "../../../component/Constants";
import { Link, Route, Switch } from "react-router-dom";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";
import List from './List';
import IntegrationType from "../../../types/integration/IntegrationType";
import api from "../../../api/Base";
import InstanaIntegration from "../../../types/integration/InstanaIntegration";

interface IIntegrationsProps {
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function
}

interface IIntegrationsState {
    loading: number,
    integrations: any
}

const initialState = {
    loading: DATA_INITIALISING,
    integrations: []
}

class Integrations extends Component<IIntegrationsProps, IIntegrationsState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IIntegrationsProps) {
        super(props);

        this.state = initialState;
    };


    async getData() {
        let url = `/parameter`;
        let data: IntegrationType[] = [];

        const isServiceEnabled = (data: any[], serviceName: string) => {
            const findService = data.find(item => item.name === serviceName)
            return findService ? findService.value : 'false'
        }

        const isServiceSet = (data: any[], serviceName: string) => {
            const findService = data.find(item => item.name === serviceName)
            return !!findService?.value
        }
        let instanaUrl = `integration`;
        let instanaIntegration: InstanaIntegration[] = [];

        await api.get<InstanaIntegration[]>(instanaUrl)
            .then((response) => {
                instanaIntegration = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve integrations.', error);
            })
            .then(function () {});

        await api.get<IntegrationType[]>(url)
            .then((response) => {
                data = response.data;
                const updatedState = {
                    loading: DATA_LOADED,
                    integrations: [
                        {
                            name: 'Slack',
                            desc: 'Slack Integration',
                            url: '/admin/integrations/slack/',
                            enabled: isServiceEnabled(data, 'slack.enable'),
                            isSet: isServiceSet(data, 'slack.dbmarlin.url')
                        },
                        {
                            name: 'PagerDuty',
                            desc: 'Alert to PagerDuty',
                            url: '/admin/integrations/pager-duty/',
                            enabled: isServiceEnabled(data, 'pagerduty.enable'),
                            isSet: isServiceSet(data, 'pagerduty.dbmarlin.url')
                        }, {
                            name: 'Instana',
                            desc: 'Click through to Instana APM',
                            url: '/admin/integrations/instana/',
                            // Not sure hot to check this, TODO: add an enabled field?
                            enabled: instanaIntegration.length && instanaIntegration[0].host ? 'true' : 'false',
                            isSet: instanaIntegration.length ? !!instanaIntegration[0].host : false,
                        }, {
                            name: 'Email',
                            desc: 'SMTP settings for email alerting',
                            url: '/admin/integrations/email/',
                            enabled: isServiceEnabled(data, 'mail.smtp.enable'),
                            isSet: isServiceSet(data, 'mail.smtp.dbmarlin.url'),
                        }, {
                            name: 'New Relic',
                            desc: 'New Relic integration',
                            url: '/admin/integrations/new-relic/',
                            enabled: isServiceEnabled(data, 'newrelic.enable'),
                            isSet: isServiceSet(data, 'newrelic.dbmarlin.url')
                        }]
                }

                this.setState(updatedState)
            })
            .catch((error: any) => {
                console.error('Failed to retrieve pager duty data.', error);
            })

        return data;
    }

    componentDidMount() {
        const {loading} = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    /* Action Methods */
    refresh = () => {
    }

    render() {
        const {loading, integrations} = this.state;

        return (

            <div id="content">
                <Helmet>
                    <title>Integrations - Settings - DBmarlin</title>
                    <meta name="description" content="Manage custom alert actions"/>
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-2">
                    <Breadcrumb heading="Integrations">
                        <Link to="/admin">Settings</Link>
                        <Switch>
                            <Route exact path="/admin/integrations/" render={() => <span>Integrations</span>}/>
                        </Switch>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}
                                   applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"/>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/integrations/"
                               render={(props) => <List {...props} integrations={integrations} loading={loading}
                                                        refresh={this.refresh}/>}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

Integrations.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Integrations;

