import { useContext, useEffect, useMemo, useState } from 'react';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { useLicences } from "../../../context/LicenceContext";

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import ConditionalRender from "../../../helpers/ConditionalRender";

// Helper.
import Helper from '../../../helpers/Helper';
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";

// Types.
import HostTarget from '../../../types/host/HostTarget';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import HostDiskVolume from "../../../types/host/HostDiskVolume";
import HostDiskVolumeData from "../../../types/host/HostDiskVolumeData";

// Constants.
import { DATA_LOADING, DATA_LOADED, CHART_COLOURS_STATEMENTS, CHART_DATA_TYPE } from '../../Constants';
import { VIEW_MODE } from '../../Constants';
import api from "../../../api/Base";

function DiskUtilisationChart(props: { host: HostTarget, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], applyPeriod: Function }) {
    const [loadingBasic, setLoadingBasic] = useState<number>(DATA_LOADING);

    const [diskVolumes, setDiskVolumes] = useState<HostDiskVolume[]>([]);
    const [basicTimeSeries, setBasicTimeSeries] = useState<any[]>([]);
    const [detailsDataSeries, setDetailsDataSeries] = useState<any[]>([]);

    const timePeak = useMemo(() => {
        const peakSeries: any[] = []
        if (basicTimeSeries.length) {
            basicTimeSeries.map(item => {
                if (item.data.length) {
                    peakSeries.push(...item.data)
                }
            })
            return Helper.getPeakValue(peakSeries.map(i => i[1]))
        } else {
            return 0
        }
    }, [basicTimeSeries])

    const [mode, setMode] = useState<number>(VIEW_MODE.BASIC);
    const {licences} = useLicences();
    const timeRangeContext = useContext(TimeRangeContext)

    useEffect(() => {
        api.get(`disk/statistic/disk?${timeRangeContext.getTimeRangeQueryString()}&id=${props.host.id}`).then(response => {
            setDiskVolumes(response.data)
        })
    }, [timeRangeContext, props.host, props.plotLines]);

    useEffect(() => {
        setLoadingBasic(DATA_LOADING);
        if (diskVolumes.length) {
            Promise.all(
                diskVolumes.map((volume: HostDiskVolume, index: number) =>
                    api.get(`disk/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&id=${props.host.id}&disk=${volume.disk}`)
                        .then((response: { data: HostDiskVolumeData[] }) => {
                            const data: any = response.data
                            // Save all data to avoid multiple api calls
                            return ([{
                                name: volume.disk,
                                color: CHART_COLOURS_STATEMENTS[index],
                                type: 'spline',
                                data: data.map((dat: any) => [
                                    getConvertedTimeToUTC(dat),
                                    dat.diskutilisation
                                ]),
                                zIndex: 1
                            }, {
                                name: `${volume.disk} Free Space (GB)`,
                                displayName: volume.disk,
                                color: CHART_COLOURS_STATEMENTS[0],
                                data: data.map((dat: any) => [
                                    getConvertedTimeToUTC(dat),
                                    dat['K available disk space'] / 1048576
                                ]),
                                type: 'column'
                            }, {
                                name: `${volume.disk} Used Space (GB)`,
                                color: CHART_COLOURS_STATEMENTS[1],
                                data: data.map((dat: any) => [
                                    getConvertedTimeToUTC(dat),
                                    dat['K used disk space'] / 1048576
                                ]),
                                type: 'column'
                            }

                            ])
                        })
                        .catch((error: any) => {
                            console.error('Failed to retrieve activity time.', error);
                            return null;
                        })
                )
            ).then((seriesData: any[]) => {
                setLoadingBasic(DATA_LOADED);
                // data for basic chart
                const firstObjectsArray = seriesData.map(subArray => subArray[0]);
                setBasicTimeSeries(firstObjectsArray);

                // data for detailed charts
                const secondAndThirdObjectsArray = seriesData.map(subArray => subArray.slice(1));
                setDetailsDataSeries(secondAndThirdObjectsArray);
            });
        } else {
            setLoadingBasic(DATA_LOADED);
            setBasicTimeSeries([]);
            setDetailsDataSeries( []);
        }
    }, [diskVolumes]);


    const basicChart = useMemo(() => {
        const basicChartOptions = chartOptionsGranularity(props.applyPeriod, basicTimeSeries, timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            timeRangeContext: timeRangeContext,
            // plotLines: props.plotLines,
            // plotBands: props.plotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
                }
            },
            legend: true,
        }, 300)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={basicChartOptions}/>;

    }, [basicTimeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    function toggleChart() {
        // Toggle the displayed chart.
        setMode(((mode === VIEW_MODE.BASIC) ? VIEW_MODE.DETAIL : VIEW_MODE.BASIC));
    }

    const chartOptions = {
        credits: {enabled: highchartsCredits(licences)},
        timeRangeContext: timeRangeContext,
        legend: {
            align: 'left',
            backgroundColor: 'rgba(255,255,255,0.25)',
            enabled: false,
            floating: false,
            itemMarginTop: 0,
            itemMarginBottom: 2,
            itemStyle: {
                color: '#212529',
                fontSize: '.7rem'
            },
            layout: 'horizontal',
            padding: 0,
            x: 0,
            y: 0,
            verticalAlign: 'top'
        },
        // plotLines: props.plotLines,
        // plotBands: props.plotBands,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
            },
        },
    }

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                Average disk utilisation over time
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseAvailableDiskCharts" aria-expanded="false" aria-controls="collapseAvailableDiskCharts"/>
                <ConditionalRender if={basicTimeSeries.length}>
                    <div className="btn-group float-end">
                        <button type="button" className="btn btn-xsm btn-primary float-end ms-1"
                                onClick={toggleChart}>{((mode === VIEW_MODE.BASIC) ? 'Show Breakdown' : 'Show Summary')}</button>
                    </div>
                </ConditionalRender>
            </div>
            <div id="collapseAvailableDiskCharts" className="card-body collapse show p-0">
                <div className="row row-cols-1">
                    <div className="col p-3">
                        <ConditionalRender if={mode === VIEW_MODE.BASIC}>
                            <h4 className='icons-chart-title'>
                                Average disk utilisation
                                <span className="peak">Peak Average utilisation: {timePeak.toFixed(2)}%</span>
                            </h4>
                            <ConditionalRender if={loadingBasic === DATA_LOADING}>
                                <div className="w-100 text-center text-muted mt-3">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="mt-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={loadingBasic === DATA_LOADED && basicTimeSeries.length}>
                                {basicChart}
                            </ConditionalRender>

                            <ConditionalRender if={loadingBasic === DATA_LOADED && !basicTimeSeries.length}>
                                <div className="w-100 text-center text-muted my-3">
                                    <i className="fal fa-ban fa-fw fa-2x"></i>
                                    <p className="mt-3">
                                        No data found
                                    </p>
                                </div>
                            </ConditionalRender>
                        </ConditionalRender>

                        <ConditionalRender if={mode === VIEW_MODE.DETAIL}>
                            <ConditionalRender if={loadingBasic === DATA_LOADING}>
                                <div className="w-100 text-center text-muted mt-3">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="mt-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={loadingBasic === DATA_LOADED}>
                                {detailsDataSeries.map((item, i) => {
                                    return <div key={i}>
                                        <h4 className='icons-chart-title'>
                                            Average disk utilisation for {item[0].displayName}
                                        </h4>
                                        <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={chartOptionsGranularity(props.applyPeriod,
                                        item, 100, chartOptions)}/>
                                    </div>}
                                )}
                            </ConditionalRender>
                        </ConditionalRender>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiskUtilisationChart;
