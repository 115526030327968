import ReactTooltip from 'react-tooltip';
import { useEffect, useState } from "react";

import ThirdPartyLinksType from "../../types/ThirsPartyLinks";
import ConditionalRender from "../../helpers/ConditionalRender";
import api from "../../api/Base";

function ThirdPartyLinks(props: { instanceId: number }) {
    const [links, setLinks] = useState<ThirdPartyLinksType[]>([])

	useEffect (() =>
	{
		ReactTooltip.rebuild ();
	}, []);

    useEffect(() => {
        const get3rdPartyLinks = async() => {
            const response: any = await api.get(`datasource/link?datasourceid=${props.instanceId}`)
            if (response.data) {
                const currentInstanceLinks = response.data.filter((item: any) => item.datasourceid === props.instanceId)
                setLinks(currentInstanceLinks)
            }
        }
        void get3rdPartyLinks()
    }, [props.instanceId])


    return (
        <ConditionalRender if={links.length}>
            <button type="button" className="btn btn-heading stan"  data-bs-toggle="dropdown" data-tip="View 3rd Party Links"><i className="fal fa-link fa-fw" aria-hidden="true" /></button>
            <div id="info-menu" className="row g-0 dropdown-menu dropdown-menu-scroll links" aria-labelledby="info"
                 onClick={(e) => e.stopPropagation()}>
                    {links.map((item: ThirdPartyLinksType, index : number) => <a href={item.url} rel="noreferrer" className='dropdown-item' target='_blank' key={index}>{item.name}</a>)}
            </div>
        </ConditionalRender>
    );
}

export default ThirdPartyLinks;
