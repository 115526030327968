// Api.
import api from '../Base';

// Types.
import EventType from '../../types/instance/EventType';

const EventTypeApi = {

    getEventTypes:async () => {

        let url = `event/type?sort=2+asc`;

        let eventTypes: EventType[] = [];

        await api.get<EventType[]>(url)
            .then((response) => {
                eventTypes = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve event types.', error);
            })
            .then(function () {});
        return eventTypes;
    },
    
    // getEventType:(eventTypeId: number) => {
    //
    //     let url = `event/type?sort=2+asc&eventTypeId=${eventTypeId}`;
    //
    //     api.get<EventType[]>(url)
    //         .then((response) => {
    //             return response.data[0];
    //         })
    //         .catch((error: any) => {
    //             console.error('Failed to retrieve event type.', error);
    //         })
    //         .then(function () {});
    // }
}

export default EventTypeApi;