import React from "react";

// Types
import Period from "../../types/Period";
import InstanaSnapshot from "../../types/integration/InstanaSnapshot";


// Components.
import CopyUrl from "../../component/buttons/CopyUrl";
import ConditionalRender from "../../helpers/ConditionalRender";
import InstanaInstanceButton from "../../component/buttons/InstanaInstanceButton";
import RefreshButton from "../../component/buttons/RefreshButton";
import FilterListButton from "../../component/FilterListButton";
import FilterSaveButton from "../../component/FilterSaveButton";
import PeriodSelector from "../../component/PeriodSelector";
import ThirdPartyLinks from "../../component/buttons/ThirdPartyLinks";
import EditButton from "../../component/buttons/Edit";

// Style
import './style.scss'

type HeaderActionsProps = {
    period: Period
    toggleMenu: Function,
    refresh?: () => void,
    applyPeriod?: Function
    instanaSnapshots?: InstanaSnapshot[]
}

const HeaderActions = (props: HeaderActionsProps) => {
    const url = window.location.pathname
    const instancePattern = /^\/instances\/(\d+)\/([A-Za-z0-9-]+)$/;
    const hostPattern = /^\/hosts\/(\d+)(?:\/([A-Za-z0-9-]+))?$/;
    const match = url.match(instancePattern || hostPattern);
    const id = match ? parseInt(match[1]) : null
    return <React.Fragment>
        <div className="col col-lg-5 col-md-6 col-sm-6 order-last icon text-md-end actions">
            <button type="button" className="btn btn-heading btn-menu d-lg-none" onClick={() => props.toggleMenu()}><i className="fal fa-bars fa-fw" /></button>
            {instancePattern.test(window.location.pathname) || hostPattern.test(window.location.pathname) ? <EditButton type={window.location.pathname.split("/")[1]} id={window.location.pathname.split("/")[2]} /> : ''}
            <CopyUrl period={props.period}/>
            {instancePattern.test(window.location.pathname) && id ? <ThirdPartyLinks instanceId={id} /> : ''}

            <ConditionalRender if={props.instanaSnapshots}>
                <InstanaInstanceButton period={props.period} instanaSnapshots={props.instanaSnapshots || []} />
            </ConditionalRender>

            <ConditionalRender if={props.refresh}>
                <RefreshButton refresh={props.refresh || (() => {})}/>
            </ConditionalRender>

            <ConditionalRender if={props.applyPeriod}>
                <FilterListButton applyPeriod={props.applyPeriod || (() => {})}/>
                <FilterSaveButton period={props.period}/>
                <PeriodSelector period={props.period} applyPeriod={props.applyPeriod || (() => {})}/>
            </ConditionalRender>

            <button type="button" className="btn btn-heading d-none"><i className="fal fa-play fa-fw" /></button>
        </div>
    </React.Fragment>
};


export default HeaderActions;
