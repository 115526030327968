import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";

import {OracleSqlHashTimeSlice, OracleSqlHashWithMetric} from "../statistics/types";
import Highcharts, {Chart, SeriesLegendItemClickEventObject} from "highcharts";

import {InstanceContext} from "../../context/InstanceContext";
import {OTHER_COLOUR_CODE, TARGET_COLOUR_CODES} from "../../component/Constants";

import Helper from "../../helpers/Helper";
import {
    archiverUrl,
    fetchResults,
    fetchWithAuthorization,
    highchartsCredits,
    stringFormatting,
    timeSeries
} from "../../helpers/utils";
import {InstanceOptions, StatisticsResultsTableProps} from "./types";
import ConditionalRender from "../../helpers/ConditionalRender";
import {useLicences} from "../../context/LicenceContext";

const UPMOST_CHART_INDEX_VALUE = 99999

const StatisticsComparisonChart = (props: StatisticsResultsTableProps) => {
    const {instances} = useContext(InstanceContext);
    const { licences } = useLicences();

    let statementColors: {[key: string]: string} = {}

    const [loading, setLoading] = useState(false)
    const [chartOptions, setChartOptions] = useState<any[]>([{title: {text: ''}}, {title: {text: ''}}])
    const [topNChartData, setTopNChartData] = useState<{ [instanceIndex: string | number]: { [key: string]: { values: OracleSqlHashTimeSlice[], orderIndex: number } } }>({});

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);

    const interval = useMemo(() => {
        return Math.max(...props.instanceOptions.map(option => Helper.getInterval(dayjs(option.periodStart), dayjs(option.periodEnd))))
    }, [props.generateId])

    const chartMetric = useMemo(() => {
        return props.options.metric
    }, [props.generateId])

    const aggregateInt = useMemo(() => {
        switch (props.options.aggregate) {
            case 'auto':
                return Math.max(...props.instanceOptions.map(option => Helper.getInterval(dayjs(option.periodStart), dayjs(option.periodEnd))))
            case 'timeslice':
                return Math.max(...props.instanceOptions.map(option => instances.find(i => i.id === option.instanceId)?.batchintervalseconds || 0)) * 1000
            default:
                return parseInt(props.options.aggregate, 10)
        }
    }, [props.options.aggregate])

    const __getOtherTotal = (allMetrics: any, topNMetrics: any): OracleSqlHashTimeSlice => {
        let totalValue = allMetrics[props.options.metric]
        topNMetrics.forEach((item: any) => {
            const getTopNItemAtTimeSlice = item.find((x: OracleSqlHashTimeSlice) => x.timeslice === allMetrics.timeslice) || 0
            totalValue = getTopNItemAtTimeSlice[props.options.metric] ? totalValue - getTopNItemAtTimeSlice[props.options.metric] : totalValue
        })
        return {
            timeslice: allMetrics.timeslice,
            [props.options.metric]: totalValue < 0 ? 0 : totalValue
        }
    }

    const getInstanceResults = useCallback(async (instanceOption: InstanceOptions) => {
        try {
            let chartApiDataResults: OracleSqlHashWithMetric[][] = [[]]
            const apiTagString = `${archiverUrl(2)}/sql/statistic/sql?`
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
            let customIdApiString = ''
            if (props.options.customSqlId) {
                customIdApiString = props.options.showSqlId ? `&sqlid=${props.options.customSqlId}` : `&sqlhash=${props.options.customSqlId}`
            }
            chartApiDataResults = await fetchResults([fetchWithAuthorization(`${apiTagString}from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${instanceOption.instanceType}&${customIdApiString}`)])

            let topNChartApiDataResults: OracleSqlHashWithMetric[] = []
            topNChartApiDataResults = chartApiDataResults[0].slice(0, props.options.topN)

            let topNChartTimePromises = []
            for (let sqlHashData of topNChartApiDataResults) {
                const apiSqlHash = `sqlhash=${sqlHashData.sqlhash}`
                topNChartTimePromises.push(fetchWithAuthorization(`${archiverUrl(2)}/sql/statistic/time?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${instanceOption.instanceType}&${apiSqlHash}`))
            }

            const topNChartTimeResults = await fetchResults(topNChartTimePromises)

            // sort by time
            topNChartTimeResults.forEach(chartTime => {
                chartTime.sort(function compare(a: OracleSqlHashTimeSlice, b: OracleSqlHashTimeSlice) {
                    const aa: number = dayjs(a.timeslice).valueOf();
                    const bb: number = dayjs(b.timeslice).valueOf();
                    return aa < bb ? -1 : 1;
                })
            })

            let allChartTimeResults: OracleSqlHashTimeSlice[][] = []

            if (props.options.other) {
                allChartTimeResults = await fetchResults([fetchWithAuthorization(`${archiverUrl(2)}/sql/statistic/time?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${instanceOption.instanceType}&limit=50000`)])

                // sort by time
                allChartTimeResults[0].sort(function compare(a: any, b: any) {
                    const aa: number = dayjs(a.timeslice).valueOf();
                    const bb: number = dayjs(b.timeslice).valueOf();
                    return aa - bb;
                })
            }

            const topNData: { [key: string]: { values: OracleSqlHashTimeSlice[], orderIndex: number } } = {}

            topNChartTimeResults.forEach((result: OracleSqlHashTimeSlice[], index: number) => {
                const sqlhash = props.options.showSqlId ? topNChartApiDataResults[index].sqlid : topNChartApiDataResults[index].sqlhash

                if (sqlhash) {
                    topNData[sqlhash] = {
                        values: result.map((item: any) => {
                            return {
                                timeslice: item.timeslice,
                                [props.options.metric]: item[props.options.metric],
                            }
                        }),
                        orderIndex: index + 1
                    }
                }
                if (props.options.other) {
                    const otherValues = result.map((item: OracleSqlHashTimeSlice) => {
                        const getAllAtTimeSlice = allChartTimeResults[0].find((otherItem: OracleSqlHashTimeSlice) => otherItem.timeslice === item.timeslice)
                        if (getAllAtTimeSlice) {
                            const otherTotal = __getOtherTotal(getAllAtTimeSlice, topNChartTimeResults)
                            const valuesExist = topNData['Other SQL']?.values?.find(item => item.timeslice === otherTotal.timeslice)
                            // return topNData['Other SQL']?.values.find(item => item.timeslice === otherTotal.timeslice) ? [] : otherTotal
                            return valuesExist || otherTotal
                        }
                        return []
                    })
                    topNData['Other SQL'] = {
                        // @ts-ignore
                        // values:  topNData['Other SQL']?.values ? topNData['Other SQL']?.values.concat(otherValues) : otherValues,
                        values: topNData['Other SQL']?.values || otherValues,
                        orderIndex: UPMOST_CHART_INDEX_VALUE
                    }
                }
            })

            return topNData
        } catch (error: any) {
            console.error(error)
        }
    }, [topNChartData, props.generateId])

    const getMaxFromSeries = (data: number[][][][]) => {
        const max: number[] = []
        data.forEach((series: number[][][], index: number) => {
            const aux: { [key: string]: number } = {}
            max[index] = 0
            series.forEach((serie: number[][]) => {
                serie.forEach((s: number[]) => {
                    const key = s[0].toString()
                    aux[key] = aux[key] || 0
                    aux[s[0]] += s[1]
                })
            })

            Object.keys(aux).forEach((key: string) => {
                max[index] = Math.max(max[index], aux[key])
            })
        })

        return Math.max(max[0], max[1]) || 1
    }

    const getColourForStatement = useCallback((key: string) => {
        function getUnusedColorFromList() {
            return TARGET_COLOUR_CODES[Object.keys(statementColors).length]
        }

        statementColors[key] = statementColors[key] || getUnusedColorFromList()
        if (props.setStatementColors) {
            props.setStatementColors(statementColors)
        }
        return statementColors[key]
    }, [topNChartData])

    useEffect(() => {
        if (Object.keys(topNChartData).length != props.instanceOptions.length) {
            return
        }

        statementColors = {}

        let chartTickIntervals: number[] = []
        let labelsFormat: string[] = []
        let seriesXDatesFormat: string[] = []
        let dataSeries: number[][][][] = []

        props.instanceOptions.forEach((instanceOption: InstanceOptions, index: number) => {
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')

            chartTickIntervals.push(Helper.getChartTickInterval(dayjs(from), dayjs(to), aggregateInt, aggregateInt) * aggregateInt);
            labelsFormat.push(`{value:${Helper.getChartDateFormatHighChartsXAxis(interval, aggregateInt)}}`);
            seriesXDatesFormat.push(Helper.getChartDateFormatHighChartsXAxis(interval, aggregateInt))
            dataSeries.push(Object.keys(topNChartData[index]).map((key: string) => timeSeries(topNChartData[index][key].values, 'timeslice', props.options.metric)))
        })

        const max = getMaxFromSeries(dataSeries)

        const optionsToSet: any[] = []
        props.instanceOptions.forEach((_, index: number) => {
            let availableTimeSlices: Date[] = []
            Object.keys(topNChartData[index]).forEach((key: any) => {
                availableTimeSlices = availableTimeSlices.concat(topNChartData[index][key].values.filter((v: any) => !availableTimeSlices.includes(v.timeslice)).map((v: any) => v.timeslice))
            })
            availableTimeSlices = availableTimeSlices.sort()

            optionsToSet.push({
                chart: {
                    height: '500px',
                    spacing: [5, 0, 0, 0],
                    type: 'column',
                },
                title: {text: ''},
                xAxis: [{
                    crosshair: true,
                    type: 'datetime',
                    endOnTick: false,
                    showFirstLabel: true,
                    showLastLabel: true,
                    tickInterval: Math.max(...chartTickIntervals),
                    labels: {
                        format: labelsFormat[0]
                    },
                }],
                yAxis: [{
                    labels: {
                        enabled: index === 0
                    },
                    crosshair: true,
                    max: max + ((10 * max) / 100),
                    title: {
                        text: index === 0 ? `${stringFormatting(props.options.metric).replace(/_/gi, ' ')} ${Helper.getMetricFormatString(props.options.metric, true)}` : null
                    }
                }],
                plotOptions: {
                    animation: false,
                    column: {stacking: 'normal'},
                    area: {stacking: 'normal'},
                    series: {
                        // pointPlacement: 'between',
                        tooltip: {
                            shared: true,
                            xDateFormat: seriesXDatesFormat[0]
                        },
                    },
                },
                legend: {
                    align: 'center',
                    backgroundColor: 'rgba(255,255,255,0.25)',
                    enabled: true,
                    floating: false,
                    itemMarginTop: 0,
                    itemMarginBottom: 2,
                    itemStyle: {
                        color: '#212529',
                        fontSize: '.7rem'
                    },
                    layout: 'horizontal',
                    padding: 0,
                    x: 0,
                    y: 0,
                    verticalAlign: 'bottom'
                },
                series: Object.keys(topNChartData[index]).map((key: string) => {
                    return {
                        name: key,
                        id: key,
                        type: props.options.chart,
                        data: timeSeries(topNChartData[index][key].values, 'timeslice', props.options.metric),
                        zIndex: 1,
                        color: key === 'Other SQL' ? OTHER_COLOUR_CODE : getColourForStatement(key),
                        legendIndex: topNChartData[index][key].orderIndex,
                        index: (UPMOST_CHART_INDEX_VALUE - topNChartData[index][key].orderIndex),
                        boostThreshold: 0,
                        events: {
                            legendItemClick: function (event: SeriesLegendItemClickEventObject) {
                                Highcharts.charts.forEach((chart: Chart | undefined) => {
                                    if (chart && chart.container.id !== event.target.chart.container.id) {
                                        const item = chart.series.find((item: any) => item.name === event.target.name)
                                        if (item) {
                                            event.target.visible ? item.hide() : item.show()
                                        }
                                    }
                                })
                            }
                        }
                    }
                }),
                exporting: {enabled: false},
                credits: {enabled: highchartsCredits(licences)},
                accessibility: {
                    enabled: false
                }
            })
        })

        setChartOptions(optionsToSet)
    }, [topNChartData, props.options.chart])

    // @ts-ignore
    useEffect(() => {
        const start = async () => {
            setLoading(true)
            const promiseArray: any[] = []
            const dataToSet: any = {}
            props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
                promiseArray.push(getInstanceResults(instanceOption))
            })

            const results = await Promise.all(promiseArray)

            results.forEach((result, index: number) => {
                dataToSet[index] = result || []
            })

            setTopNChartData(dataToSet)
            setLoading(false)
        }
        void start()
    }, [props.generateId])

    return <div className="card">
        <div className="card-header">
            <ConditionalRender if={loading}>
                <div className="loader" style={{borderWidth: 0, position: "absolute", width: '80%', margin: 'auto', height: "5px"}}>
                    <div className="bar" />
                </div>
            </ConditionalRender>
            <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
            Statements {`By ${stringFormatting(chartMetric).replace(/_/gi, ' ')}`}
            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
               data-bs-target="#collapseStatementsChart" aria-expanded="false"
               aria-controls="collapseStatementsChart"/>
        </div>
        <div id="collapseStatementsChart" className="card-body collapse show combined-charts">
            <HighchartsReact ref={chart1Ref} constructorType={"chart"} highcharts={Highcharts} options={chartOptions[0]}/>
            <div className={"separator"}/>
            <HighchartsReact ref={chart2Ref} constructorType={"chart"} highcharts={Highcharts} options={chartOptions[1]}/>
        </div>
    </div>
}

export default StatisticsComparisonChart;
