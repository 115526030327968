import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';

// Third-parties.

import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Alert from '../../Alert';
import Period from '../../../types/Period';
import ProgramsDataTable from '../../../types/instance/tables/ProgramsDataTable';
import PieChartSeries from "../../../types/PieChartSeries";
import Program from '../../../types/instance/Program';
import { FilterType } from '../../../typescript/Enums';

// Components.
import GenericBreakdown from '../../GenericBreakdown';
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED } from "../../Constants";
import { CHART_COLOURS_STATEMENTS } from "../../Constants";
import { getDefaultPageSize } from "../../../helpers/utils";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { getAPIString } from "../../../views/instances/tabs/utils";
import api from "../../../api/Base";

function ProgramsTable(props: { period: Period, instanceId: number, batchId?: string, statementId?: string, instanceTime: number, setProgramsOptions: Function, setFilterOptions: Function }) {
    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [programsDataTable, setProgramsDataTable] = useState<ProgramsDataTable[]>([]);
    const timeRangeContext = useContext(TimeRangeContext)

    const {period, instanceTime, setFilterOptions} = props;

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    useEffect(() => {

        const getPrograms = async() => {

            let data: ProgramsDataTable[] = [],
                pieChartSeries: PieChartSeries[] = [];

            const apiString = props.statementId || props.batchId ? getAPIString(props.statementId || props.batchId) : ''

            await api.get(`activity/program?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${props.instanceId}${apiString}${props.period.filters.parameters}`)
                .then((response: { data: Program[]; }) => {

                    for (let index = 0; index < response.data.length; index++) {

                        const item = response.data[index];

                        let program: string = ((item.program) ? item.program : '-');

                        /*
                        if (program.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH)) {
                            program = `${program.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...`;
                        }
                        */

                        data.push({
                            id: index,
                            color: '',
                            program,
                            waittime: item.waittime
                        })

                        if (index < Number(process.env.REACT_APP_CHART_SERIES_CEILING)) {
                            pieChartSeries.push({
                                color: CHART_COLOURS_STATEMENTS[index],
                                formatted: Helper.getTimeInEnglish(item.waittime),
                                name: program,
                                y: item.waittime
                            });
                        }
                    }

                    // Return the total count and pie chart series back to the parent component.
                    props.setProgramsOptions.call(null, response.data.length, pieChartSeries);
                })
                .catch((error: any) => {

                    console.error('Failed to retrieve programs.', error);
                })
                .then(function () {
                });

            setProgramsDataTable(data);
        }

        // Get the batches for the selected instance and period.
        getPrograms();

        // Mark the data as loaded.
        setLoading(DATA_LOADED);

    }, [props.period, props.instanceId, timeRangeContext, props.batchId, props.statementId]);

    const data = useMemo(() => programsDataTable.filter(row => (row.program.toLowerCase().includes(tableFilter.toLowerCase()))), [programsDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => {
        function filterData(filter: string): void {
            setFilterOptions(Helper.updateFilterOptions
            (FilterType.Programs, filter, period.filters.options));
        }

        return [
            {
                accessor: 'color',
                disableSortBy: true
            },
            {
                accessor: 'id',
                disableSortBy: true
            },
            {
                Header: 'Program',
                accessor: 'program',
                className: 'fw-bold',
                defaultCanSort: true,
                sortType: 'alphanumeric',
                Cell: ({cell}) => (
                    <React.Fragment>
                        <span className="me-1 ps-1" style={{backgroundColor: cell.row.values.color}}>&nbsp;</span>
                        {(cell.row.values.program !== '-') ? (
                            <button key={cell.column.id} className="btn btn-sm btn-link btn-filter"
                                    onClick={() => filterData(cell.row.values.program)}>{cell.row.values.program}</button>
                        ) : (
                            <React.Fragment>-</React.Fragment>
                        )}
                    </React.Fragment>
                ),
            },
            {
                Header: 'Total Time',
                accessor: 'breakdown',
                className: 'generic-waits text-nowrap',
                disableSortBy: true,
                Cell: ({cell}) => (
                    cell.row.values.waittime !== null ? (
                        cell.row.values.id < 20 ? (
                            <GenericBreakdown individualTime={cell.row.values.waittime} totalTime={instanceTime}
                                              color={((cell.row.values.color !== '') ? cell.row.values.color : '#999')}
                                              tooltip={cell.row.values.program}/>
                        ) : (
                            <React.Fragment>-</React.Fragment>
                        )
                    ) : (
                        <React.Fragment>-</React.Fragment>
                    )
                )
            },
            {
                Header: 'Wait Time',
                accessor: 'waittime',
                headerClassName: 'text-end',
                className: 'text-end',
                defaultCanSort: true,
                sortType: 'basic',
                Cell: ({cell}) => (
                    cell.row.values.waittime !== null ? (
                        Helper.getTimeInEnglish(cell.row.values.waittime)
                    ) : (
                        <React.Fragment>-</React.Fragment>
                    )
                )
            },
            {
                Header: 'Weight %',
                accessor: 'weight',
                headerClassName: 'text-end',
                className: 'text-end',
                defaultCanSort: true,
                sortType: 'basic',
                Cell: ({cell}) => (
                    instanceTime ? (
                        <React.Fragment>
                            {Math.floor((100 / instanceTime) * cell.row.values.waittime)}%
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            -
                        </React.Fragment>
                    )
                )
            }
        ]
    }, [instanceTime, period.filters.options, setFilterOptions]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id', 'color'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'waittime', desc: true}]
            }
        },
        useSortBy, usePagination);

    return (
        <div id="programs" className="tab-pane fade show" role="tabpanel" aria-labelledby="programs-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-computer-classic fa-fw" aria-hidden="true"/>
                    Programs
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapsePrograms" aria-expanded="false" aria-controls="collapsePrograms"/>
                </div>
                <div id="collapsePrograms" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 && (
                        <Alert
                            message={`To help preserve performance, we limit the total number of program records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their total wait time.`}
                            heading="Program records" variant="alert-info"/>
                    )}
                    <div className="row row-cols-1 row-cols-md-2 table-search">
                        <div className="col col-md-9">
                            <CSVLink role="button"
                                     headers={[{label: 'ID', key: 'id'}, {
                                         label: 'Program',
                                         key: 'program'
                                     }, {label: 'Wait Time', key: 'waittime'}]}
                                     data={data}
                                     download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Programs.csv`}
                                     className="btn btn-sm btn-primary">
                                <i className="fal fa-file-export fa-fw"/><span>Export</span>
                            </CSVLink>
                            <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                className="far fa-undo"/><span>Clear</span></button>
                        </div>
                        <div className="col col-md-3">
                            <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                                   value={tableFilter} data-lpignore={true}
                                   onChange={(e) => setTableFilter(e.target.value)}/>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={(column as any).className}>
                                            {column.render('Header')}
                                            {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                    <i className="fal fa-sort-amount-up-alt"/> :
                                                    <i className="fal fa-sort-amount-down-alt"/>) :
                                                <i className="fal fa-sort-amount-down-alt text-light"/>) : ''}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} className={(cell.column as any).className}
                                                    style={{borderLeft: (cell.column as any).color}}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <NoData
                            error={null}
                            loading={loading === DATA_LOADING}
                            length={data.length}
                        />
                        <TablePagination
                            length={data.length}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            canPreviousPage={canPreviousPage}
                            previousPage={previousPage}
                            canNextPage={canNextPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            pageIndex={pageIndex}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

ProgramsTable.propTypes = {
    setProgramsOptions: PropTypes.func.isRequired
};

export default ProgramsTable;
