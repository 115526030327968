import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {Link, Route, useHistory} from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Helper.
import Helper from '../../../helpers/Helper';
import { closeFilterDropdown, dateWithTimeZone, getParameter }
	from '../../../helpers/utils';

// Context.
import { InstanceContext } from '../../../context/InstanceContext';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';

// Components.
import Alert from '../../../component/Alert';
import FilterDropdown from '../../../component/FilterDropdown';

// Constants.
import { PERIOD_CUSTOM, PERIOD_FIVE_MINUTES, PERIOD_TEN_MINUTES, PERIOD_THIRTY_MINUTES, PERIOD_ONE_HOUR, PERIOD_SIX_HOURS, PERIOD_TWELVE_HOURS, PERIOD_TWENTY_FOUR_HOURS, PERIOD_SEVEN_DAYS } from '../../../component/Constants';
import { INTERVAL_SECOND, INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_TEN_MINUTES, INTERVAL_THIRTY_MINUTES, INTERVAL_ONE_HOUR, INTERVAL_SIX_HOURS, INTERVAL_TWELVE_HOURS, INTERVAL_ONE_DAY } from '../../../component/Constants';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

function Settings(props: { period: Period, toggleMenu: Function }) {
    const { instances } = useContext(InstanceContext);
    instances.sort((a, b) => a.name.localeCompare(b.name))
    const history = useHistory();

    const filter: string = '';
    const [error, setError] = useState<boolean>(false);
    const [advanced, setAdvanced] = useState<boolean>(false);
    const [firstInstanceId, setFirstInstanceId] = useState<number>(0);
    const [secondInstanceId, setSecondInstanceId] = useState<number>(0);
    const [compareToDifferent, setCompareToDifferent] = useState<boolean>(false);
    const [firstPeriod, setFirstPeriod] = useState<Date>(dayjs.default(props.period.ui.previous.from).set('second', 0).toDate())
    const [secondPeriod, setSecondPeriod] = useState<Date>(dayjs.default(props.period.ui.current.from).set('second', 0).toDate())
    const [periodDuration, setPeriodDuration] = useState<number>(PERIOD_ONE_HOUR);
    const [statementFilter, setStatementFilter] = useState<string>('');

    function setComparisonOptions() {

        let firstInstance: InstanceTarget | null = null,
            secondInstance: InstanceTarget | null = null;

        // Get first selected instance.
        let matchedInstances: InstanceTarget[] = instances.filter(instance => instance.id === firstInstanceId);

        if (matchedInstances.length === 0) {

            setError(true);

            return;
        }

        // Set the first and second instances (second may yet get overriden in the below code).
        firstInstance = matchedInstances[0];
        secondInstance = matchedInstances[0];

        if (compareToDifferent) {

            matchedInstances = instances.filter(instance => instance.id === secondInstanceId);

            if (matchedInstances.length === 0) {
    
                setError(true);
    
                return;
            }

            // Override the second instance.
            secondInstance = matchedInstances[0];
        }

        let duration: number = 0,
            interval = INTERVAL_ONE_DAY;
        
        switch (periodDuration) {
            case PERIOD_FIVE_MINUTES:
                duration = 5;
                interval = INTERVAL_SECOND;
                break;
            case PERIOD_TEN_MINUTES:
                duration = 10;
                interval = INTERVAL_MINUTE;
                break;
            case PERIOD_THIRTY_MINUTES:
                duration = 30;
                interval = INTERVAL_MINUTE;
                break;
            case PERIOD_ONE_HOUR:
                duration = 60;
                interval = INTERVAL_MINUTE;
                break;
            case PERIOD_SIX_HOURS:
                duration = 360;
                interval = INTERVAL_ONE_HOUR;
                break;
            case PERIOD_TWELVE_HOURS:
                duration = 720;
                interval = INTERVAL_ONE_HOUR;
                break;
            case PERIOD_TWENTY_FOUR_HOURS:
                duration = 1440;
                interval = INTERVAL_ONE_HOUR;
                break;
            case PERIOD_SEVEN_DAYS:
                duration = 10080;
                interval = INTERVAL_ONE_DAY
                break;        
            default:
                break;
        }

        let firstFrom: Dayjs = dayjs.default(firstPeriod),
            firstTo: Dayjs = firstFrom.add(duration, 'minute'),
            secondFrom: Dayjs = dayjs.default(secondPeriod),
            secondTo: Dayjs = secondFrom.add(duration, 'minute');

        // Build the requested period object.
        let period: Period = {
            chartTickInterval: Helper.getChartTickInterval(secondFrom, secondTo, PERIOD_CUSTOM, interval),
            comparisonSupported: true,
            isValid: true,
            timestamp: `${dayjs.default(secondFrom).valueOf()}${dayjs.default(secondTo).valueOf()}`,
            dateFormat: Helper.getChartDateFormat(interval),
            api: {
                interval: 0,
                periodOption: PERIOD_CUSTOM,
                time: 1,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                current: {
                    from: secondFrom.format('YYYY-MM-DD+HH:mm'),
                    to: secondTo.format('YYYY-MM-DD+HH:mm')
                },
                previous: {
                    from: firstFrom.format('YYYY-MM-DD+HH:mm'),
                    to: firstTo.format('YYYY-MM-DD+HH:mm')
                }
            },
            ui: {
                heading: '',
                subheading: '',
                queryString: '',
                current: {
                    chartCategories: Helper.getChartAxisCategories(secondFrom, secondTo, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(secondFrom, secondTo, PERIOD_CUSTOM, interval),
                    from: secondFrom,
                    to: secondTo
                },
                previous: {
                    chartCategories: Helper.getChartAxisCategories(firstFrom, firstTo, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(firstFrom, firstTo, PERIOD_CUSTOM, interval),
                    from: firstFrom,
                    to: firstTo
                }
            },
            filters: {
                options: [],
                parameters: ''
            }
        };
        
        // Build the location state which gets passed with the location push.
        // history.push({ pathname: `/reports/time-comparison/results`, search: `?instances=${firstInstance.id},${secondInstance.id}&a=${period.api.previous.from},${period.api.previous.to}&b=${period.api.current.from},${period.api.current.to}`, state: { period, firstInstance, secondInstance } });
        history.push({ pathname: `/reports/time-comparison/results`, search: `?instances=${firstInstance.id},${secondInstance.id}&a=${period.api.previous.from}&b=${period.api.current.from}&d=${duration}&tz=${period.api.timezone}` });
    }

	function applyFilter (parameters: string): Date
	{
		const from: string | null = getParameter (parameters, 'from');
		const to: string | null = getParameter (parameters, 'to');
		const tz: string | null = getParameter (parameters, 'tz');
		const fromDate: Dayjs = dateWithTimeZone (from, tz);
		const toDate: Dayjs = dateWithTimeZone (to, tz);
		const duration: number = toDate.valueOf () - fromDate.valueOf ();
		let durationOption: number = periodDuration;

		if (duration <= INTERVAL_FIVE_MINUTES)
		{
			durationOption = PERIOD_FIVE_MINUTES;
		}
		else if (duration <= INTERVAL_TEN_MINUTES)
		{
			durationOption = PERIOD_TEN_MINUTES;
		}
		else if (duration <= INTERVAL_THIRTY_MINUTES)
		{
			durationOption = PERIOD_THIRTY_MINUTES;
		}
		else if (duration <= INTERVAL_ONE_HOUR)
		{
			durationOption =  PERIOD_ONE_HOUR;
		}
		else if (duration <= INTERVAL_SIX_HOURS)
		{
			durationOption = PERIOD_SIX_HOURS;
		}
		else if (duration <= INTERVAL_TWELVE_HOURS)
		{
			durationOption = PERIOD_TWELVE_HOURS;
		}
		else if (duration <= INTERVAL_ONE_DAY)
		{
			durationOption = PERIOD_TWENTY_FOUR_HOURS;
		}
		else
		{
			durationOption = PERIOD_SEVEN_DAYS;
		}

		setPeriodDuration (durationOption);
		return fromDate.toDate ();
	}

	function applyFirstFilter (parameters: string)
	{
		closeFilterDropdown ('firstFilter', 'firstFilterDropdown');
		setFirstPeriod (applyFilter (parameters));
	}

	function applySecondFilter (parameters: string)
	{
		closeFilterDropdown ('secondFilter', 'secondFilterDropdown');
		setSecondPeriod (applyFilter (parameters));
	}

    return (
        <div id="content">
            <Helmet>
                <title>Time Comparison - DBmarlin</title>
                <meta name="description" content="Compare your DB instances over two defined periods" />
            </Helmet>
            {/* Heading */}

            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="Time Comparison">
                    <Link to="/reports/time-comparison">Reports</Link>
                    <Route exact path="/reports/time-comparison" render={() => <span>Time Comparison</span>} />
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} />
            </div>

            <div className="loader">
                <ConditionalRender if={false}>
                    <div className="bar"></div>
                </ConditionalRender>
            </div>

            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-stopwatch fa-fw" aria-hidden="true"></i>
                            Time comparison options
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings"></i>
                            <div className="btn-group float-end d-none">
                                <button className="btn btn-xsm btn-primary ms-1" type="button" onClick={() => setAdvanced(((advanced === true) ? false : true))}>
                                    Advanced
                                </button>
                            </div>
                        </div>
                        <div id="collapseSettings" className="card-body collapse show">

                            {error && (
                                <Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
                            )}

                            <div className="row g-0 form form-stacked">

                                {/* First Instance */}
                                <div className="col-md-5 col-lg-3">
                                    <span className="required"><label htmlFor="firstInstanceId" className="reminder" data-tip="The instance to report against">Instance</label></span>
                                    <select
                                        id="firstInstanceId"
                                        className="form-select"
                                        value={firstInstanceId}
                                        onChange={e => setFirstInstanceId(Number(e.target.value))}
                                        aria-label="Select instance">
                                        <option value="0">Select Instance</option>
                                        {instances.filter(item => item.name.toLowerCase().includes(filter)).map((instance: { id: number; name: string; }) => (
                                            <option key={instance.id} value={instance.id}>{instance.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* Compare to Self */}
                                <div className="col-md-5 col-lg-3 ms-md-0 ms-lg-3 mt-3 mt-md-0">
                                    <label htmlFor="compareToDifferent" className="reminder" data-tip="Select to compare with a different instance">Compare with Different Instance</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="compareToDifferent" checked={compareToDifferent}
                                            onChange={() => ((compareToDifferent === true) ? setCompareToDifferent(false) : setCompareToDifferent(true))} />
                                    </div>
                                </div>

                                {/* Second Instance */}
                                <div className="col-md-5 col-lg-3 ms-md-3 mt-3 mt-md-0">
                                    <span className={((compareToDifferent) ? 'required' : '')}><label htmlFor="secondInstanceId" className="reminder" data-tip="The instance to compare against">Second Instance</label></span>
                                    <select
                                        id="secondInstanceId"
                                        className="form-select"
                                        value={secondInstanceId}
                                        onChange={e => setSecondInstanceId(Number(e.target.value))}
                                        aria-label="Select instance"
                                        disabled={!compareToDifferent}>
                                        <option value="0">Select Instance</option>
                                        {instances.filter(item => item.name.toLowerCase().includes(filter)).map((instance: { id: number; name: string; }) => (
                                            <option key={instance.id} value={instance.id}>{instance.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-100"></div>

                                {/* First Period */}
                                <div className="col-md-5 col-lg-3 ms-md-0 mt-3">
                                    <span className="required"><label htmlFor="firstPeriod" className="reminder" data-tip="The first period to compare against">Period A - Start Date and Time</label></span>
                                    <br/>
                                    <DatePicker
                                        id="firstPeriod"
                                        className={`form-control`}
                                        dateFormat="yyyy/MM/dd HH:mm"
                                        placeholderText="yyyy/MM/dd"
                                        popperPlacement="auto"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        maxDate={secondPeriod}
                                        onChange={(date: Date) => setFirstPeriod(date)}
                                        selected={firstPeriod}
                                        shouldCloseOnSelect={true} />
                                </div>

                                {/* Second Period */}
                                <div className="col-md-5 col-lg-3 ms-md-3 mt-3">
                                    <span className="required"><label htmlFor="secondPeriod" className="reminder" data-tip="The second period to compare against">Period B - Start Date and Time</label></span>
                                    <br/>
                                    <DatePicker
                                        id="secondPeriod"
                                        className={`form-control`}
                                        dateFormat="yyyy/MM/dd HH:mm"
                                        placeholderText="yyyy/MM/dd"
                                        popperPlacement="auto"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        minDate={firstPeriod}
                                        onChange={(date: Date) => setSecondPeriod(date)}
                                        selected={secondPeriod}
                                        shouldCloseOnSelect={true} />
                                </div>

                                {/* Period Duration */}
                                <div className="col-md-5 col-lg-3 ms-md-0 ms-lg-3 mt-3">
                                    <span className="required"><label htmlFor="periodDuration" className="reminder" data-tip="The period duration to compare against">Period Duration</label></span>
                                    <select
                                        id="periodDuration"
                                        className="form-select"
                                        value={periodDuration}
                                        onChange={e => setPeriodDuration(Number(e.target.value))}
                                        aria-label="Select duration">
                                        <option value={PERIOD_FIVE_MINUTES}>5 minutes</option>
                                        <option value={PERIOD_TEN_MINUTES}>10 minutes</option>
                                        <option value={PERIOD_THIRTY_MINUTES}>30 minutes</option>
                                        <option value={PERIOD_ONE_HOUR}>1 hour</option>
                                        <option value={PERIOD_SIX_HOURS}>6 hours</option>
                                        <option value={PERIOD_TWELVE_HOURS}>12 hours</option>
                                        <option value={PERIOD_TWENTY_FOUR_HOURS}>24 hours</option>
                                        <option value={PERIOD_SEVEN_DAYS}>7 days</option>
                                    </select>
                                </div>

                                {/* First Filter */}
                                <div className="col-md-5 col-lg-3 ms-md-0 mt-3">
                                <span>Or choose a saved snapshot</span>
									<FilterDropdown filterId="firstFilter" dropdownId="firstFilterDropdown" dataTip="Choose snapshot for period A" applyFilter={applyFirstFilter} />
                                    
                                </div>

                                {/* Second Filter */}
                                <div className="col-md-5 col-lg-3 ms-md-3 mt-3">
                                <span>Or choose a saved snapshot</span>
									<FilterDropdown filterId="secondFilter" dropdownId="secondFilterDropdown" dataTip="Choose snapshot for period B" applyFilter={applySecondFilter} />
                                </div>
                                
                                {advanced && (
                                    <div className="mt-3">
                                        <h4>Advanced Mode</h4>

                                        {/* Statement Filters */}
                                        <div className="col-md-10 col-lg-6 ms-md-0 mt-3">
                                            <label htmlFor="statementFilter" className="reminder" data-tip="Set any specific statement IDs you want to compare against">Statement IDs</label>
                                            <input
                                                type="text"
                                                id="statementFilter"
                                                autoFocus
                                                className="form-control"
                                                required
                                                maxLength={250}
                                                onChange={e => setStatementFilter(e.target.value)}
                                                value={statementFilter}
                                                data-lpignore={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Actions */}
                                <div className="col-xs-12 col-md-10 col-lg-12 mt-3 actions">
                                    <button className="btn btn-success" onClick={setComparisonOptions}><i className="fal fa-play fa-fw fa-sm"></i><span>Generate Report</span></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Settings.propTypes = {
    toggleMenu: PropTypes.func.isRequired
};

export default Settings;
