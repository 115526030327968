import { useMemo, useState } from 'react';

// Third-party packages.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import HostTarget from '../../../types/host/HostTarget';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';
import ChartSeries from '../../../types/ChartSeries';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';

// Constants.
import { CHART_COLOURS_STATEMENTS, CHART_DATA_TYPE } from '../../../component/Constants';

// Highcharts boost mode.
import Boost from 'highcharts/modules/boost';
import {highchartsCredits} from "../../../helpers/utils";
import {useLicences} from "../../../context/LicenceContext";
import api from "../../../api/Base";
Boost(Highcharts);

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function HostsDiskReadWritesChart(props: { period: Period, matchedHosts: [HostTarget[], HostTarget[]], chartCategories: string[], plotBands: ChartPlotBand[], plotLines: ChartPlotLine[] }) {
    const [diskReadSeries, setDiskReadSeries] = useState<ChartSeries[]>([]);
    const [diskReadPeak, setDiskReadPeak] = useState<null | number>(null);
    const [diskWriteSeries, setDiskWriteSeries] = useState<ChartSeries[]>([]);
    const [diskWritePeak, setDiskWritePeak] = useState<null | number>(null);
    const { licences } = useLicences();

    // Get host chart metrics.
    useMemo(() => {

        async function getHostsChartData() {

            let diskReadChartSeries: ChartSeries[] = [],
                diskReadPeakSeries: number[] = [],
                diskWriteChartSeries: ChartSeries[] = [],
                diskWritePeakSeries: number[] = [];

            // Get a unique list of all hosts for both possible instances and periods.
            const filteredHosts = Helper.getUniqueArrayValues(props.matchedHosts[0], props.matchedHosts[1]);

            for (let index = 0; index < filteredHosts.length; index++) {

                const host = filteredHosts[index];
                
                // Get processors over time.
                let diskReadData: (number | null)[] = [],
                    diskWriteData: (number | null)[] = [];

                //#region Disk Reads

                // Get disk reads data over time for the first period.
                await api.get(`host/statistic/time?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=time%20spent%20reading%20(ms)&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.previous.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            diskReadData.push(0);
                        } else {
                            // Data exists.
                            diskReadData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve disk reads over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    diskReadPeakSeries.push(Helper.getPeakValue(diskReadData));
                });
    
                // Interim data push.
                diskReadData.push(null);

                // Get disk reads data over time for the second period.
                await api.get(`host/statistic/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=time%20spent%20reading%20(ms)&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.current.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            diskReadData.push(0);
                        } else {
                            // Data exists.
                            diskReadData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve disk reads over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    diskReadPeakSeries.push(Helper.getPeakValue(diskReadData));
                });
        
                // Add the chart series.
                diskReadChartSeries.push({
                    name: `${host.name} - time spent reading (ms)`,
                    color: CHART_COLOURS_STATEMENTS[0],
                    type: 'column',
                    data: diskReadData,
                    tooltip: {
                        valueSuffix: '%'
                    },
                    zIndex: 1
                });

                //#endregion Disk Reads

                //#region Disk Writes

                // Get disk writes data over time for the first period.
                await api.get(`host/statistic/time?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=time%20spent%20writing%20(ms)&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.previous.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            diskWriteData.push(0);
                        } else {
                            // Data exists.
                            diskWriteData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve disk writes over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    diskWritePeakSeries.push(Helper.getPeakValue(diskWriteData));
                });
    
                // Interim data push.
                diskWriteData.push(null);

                // Get disk writes data over time for the second period.
                await api.get(`host/statistic/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=time%20spent%20writing%20(ms)&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.current.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            diskWriteData.push(0);
                        } else {
                            // Data exists.
                            diskWriteData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve disk writes over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    diskWritePeakSeries.push(Helper.getPeakValue(diskWriteData));
                });
        
                // Add the chart series.
                diskWriteChartSeries.push({
                    name: `${host.name} - time spent writing (ms)`,
                    color: CHART_COLOURS_STATEMENTS[1],
                    type: 'column',
                    data: diskWriteData,
                    tooltip: {
                        valueSuffix: '%'
                    },
                    zIndex: 1
                });

                //#endregion Disk Writes
            }
    
            setDiskReadSeries(diskReadChartSeries);
            setDiskWriteSeries(diskWriteChartSeries);
            setDiskReadPeak(Helper.getPeakValue(diskReadPeakSeries));
            setDiskWritePeak(Helper.getPeakValue(diskWritePeakSeries));
        }

        getHostsChartData();

    }, [props.matchedHosts, props.period.api, props.period.ui]);

    const diskChart = useMemo(() => {

        const options = {
            chart: {
                height: '200px',
                spacing: [0, 0, 0, 0],
                type: 'column'
            },
            title: { text: '' },
            xAxis: [{
                categories: props.chartCategories,
                crosshair: true,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tickInterval: props.period.chartTickInterval
            }],
            yAxis: [{
                labels: {
                    align: 'left',
                    enabled: true,
                    x: 0,
                    y: 15
                },
                min: 0,
                showFirstLabel: false,
                title: {
                    text: ''
                }
            }],
            plotOptions: {
				animation: false,
                column: { stacking: 'normal' },
                series: { marker: { enabled: false } }
            },
            legend: {
                align: 'center',
                backgroundColor: 'rgba(255,255,255,0.25)',
                borderColor: '#dee2e6',
                borderRadius: 5,
                borderWidth: 1,
                enabled: true,
                floating: false,
                itemMarginTop: 0,
                itemMarginBottom: 0,
                itemStyle: {
                    color: '#212529',
                    fontSize: '.7rem'
                },
                layout: 'horizontal',
                padding: 10,
                x: 0,
                y: 0,
                verticalAlign: 'bottom'
            },
            series: [...diskReadSeries, ...diskWriteSeries],
            exporting: { enabled: false },
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltips(this, CHART_DATA_TYPE.TIME);
                },
                borderWidth: 0,
                outide: true,
                padding: 0,
                shadow: false,
                shared: true,
                useHTML: true
            },
            credits: { enabled: highchartsCredits(licences) },
            accessibility: {
                enabled: false
            }
        };

        return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={options} />

    }, [props.period, props.chartCategories, props.plotBands, props.plotLines, diskReadSeries, diskWriteSeries])

    return (
        <div className="mb-3">
            <h4>
                Average time spent reading and writing to disk (ms)
                {diskReadPeak !== null && diskReadPeak !== Infinity  && diskReadPeak !== -Infinity && diskWritePeak !== null && diskWritePeak !== Infinity && diskWritePeak !== -Infinity && (
                    <span className="peak">Peak Average Read Time: {Helper.getTimeInEnglish(diskReadPeak)}, Peak Average Write Time: {Helper.getTimeInEnglish(diskWritePeak)}</span>
                )}
            </h4>
            {diskChart}
        </div>
    );
}

export default HostsDiskReadWritesChart;
