import React, { useMemo } from 'react';

// Third-party packages.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import ChartSeries from '../../../types/ChartSeries';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';

// Highcharts boost mode.
import Boost from 'highcharts/modules/boost';
import { CHART_DATA_TYPE } from '../../../component/Constants';
import {highchartsCredits} from "../../../helpers/utils";
import {useLicences} from "../../../context/LicenceContext";
Boost(Highcharts);

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function WaitsChart(props: { period: Period, chartCategories: string[], plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], series: ChartSeries[], peak: null | number }) {
    const { licences } = useLicences();

    const waitsChart = useMemo(() => {
        const options = {
            chart: {
                height: '200px',
                // spacing: [0, 0, 0, 0],
                type: 'column',
                style: {
                    fontFamily: 'system-ui, Arial, sans-serif',
                    letterSpacing: '1px'
                }
            },
            title: { text: '' },
            xAxis: [{
                categories: props.chartCategories,
                crosshair: true,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tickInterval: props.period.chartTickInterval,
                labels: {
                    distance: 9,
                    style: {
                        color: '#666666',
                        cursor: 'default',
                        fontSize: '11px',
                        letterSpacing: '1px'
                    }
                },
            }],
            yAxis: [{
                labels: {
                    align: 'left',
                    enabled: true,
                    x: 0,
                    y: 15,
                    distance: 9,
                    style: {
                        color: '#666666',
                        cursor: 'default',
                        fontSize: '11px',
                        letterSpacing: '1px'
                    }
                },
                min: 0,
                showFirstLabel: false,
                title: {
                    text: ''
                }
            }],
            plotOptions: {
				animation: false,
                column: { stacking: 'normal' },
                series: { marker: { enabled: false } }
            },
            legend: {
                align: 'center',
                backgroundColor: 'rgba(255,255,255,0.25)',
                borderColor: '#dee2e6',
                borderRadius: 5,
                borderWidth: 1,
                enabled: true,
                floating: false,
                itemMarginTop: 0,
                itemMarginBottom: 0,
                itemStyle: {
                    color: '#212529',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    letterSpacing: 'normal'
                },
                layout: 'horizontal',
                padding: 10,
                x: 0,
                y: 0,
                verticalAlign: 'bottom'
            },
            series: props.series,
            exporting: { enabled: false },
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltips(this, CHART_DATA_TYPE.TIME);
                },
                borderWidth: 0,
                outide: true,
                padding: 0,
                shadow: false,
                shared: true,
                useHTML: true
            },
            credits: { enabled: highchartsCredits(licences) },
            accessibility: {
                enabled: false
            }
        };

        return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={options} />

    }, [props.period, props.chartCategories, props.plotBands, props.plotLines, props.series]);

    return (
        <React.Fragment>
            <h4>
                Top waits over time (ms)
                {props.peak !== null && (
                    <span className="peak">Peak Wait Time: {Helper.getTimeInEnglish(props.peak)}</span>
                )}
            </h4>
            {waitsChart}
        </React.Fragment>
    );
}

export default WaitsChart;
