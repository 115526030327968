import React from 'react';

// Third-parties.
import Skeleton from 'react-loading-skeleton';

import ReactTooltip from 'react-tooltip';

function ProcessorWidget(props: { metric: number, metricPrevious: number | null }) {

    ReactTooltip.rebuild();

    return (
        <div className="col flexlist-item">
            <div className="card">
                <div className="card-body widget">
                    <div className="row g-0 align-items-center card-row">
                        <div className="icon processor" data-tip="The average CPU usage">
                            <span className="fal fa-microchip fa-fw"></span>
                        </div>
                        <div className="col data">
                            CPU Average
                            <div>
                                {props.metric ? (
                                    <React.Fragment>
                                        {props.metric}%
                                        {props.metricPrevious !== null && (
                                            <span className="comparison">
                                                {props.metric === props.metricPrevious ? (
                                                    <i className="fal fa-equals equals fa-fw"></i>
                                                ) : (
                                                    props.metric > props.metricPrevious ? (
                                                        <i className="fal fa-long-arrow-up fa-fw increase"></i>
                                                    ) : (
                                                        <i className="fal fa-long-arrow-up fa-fw decrease"></i>
                                                    )
                                                )}
                                                {props.metricPrevious}%
                                                {props.metricPrevious > 0 && (
                                                    <React.Fragment>
                                                        &nbsp;({Math.round(((props.metric - props.metricPrevious) / props.metricPrevious) * 100)}%)
                                                    </React.Fragment>
                                                )}
                                            </span>
                                        )}
                                    </React.Fragment>
                                ) : (
                                        <Skeleton />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessorWidget;