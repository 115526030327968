import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

//import Skeleton from 'react-loading-skeleton';

// Helpers.
//import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import HostTarget from '../../../types/host/HostTarget';
import HostsAdminDataTable from '../../../types/host/tables/HostsAdminDataTable';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED } from '../../../component/Constants';
import { ACTIONS } from '../../../component/Constants';

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IHostsProps {
    period: Period,
    hosts: HostTarget[]
    toggleMenu: Function,
    applyPeriod: Function
}

interface IHostsState {
    loading: number,
    hosts: HostTarget[],
    host: HostTarget,
    hostsAdminDataTable: HostsAdminDataTable[]
}

const initialState: IHostsState = {
    loading: DATA_INITIALISING,
    hosts: [],
    host: {
        id: 0,
        color: '',
        name: '',
        host: '',
        type: '',
        agentid: 1,
        port: 22,
        username: '',
        password: '',
        keyfile: '',
        region: '',
        monitor: true,
        reason: '',
        attempt: 0,
        debug: false,
		monitoringintervalmilliseconds: 0,
        sensorstatus: 0,
        message: '',
        updated: new Date(),
        timedout: false,
        state: '',
        status: 0
    },
    hostsAdminDataTable: []
};

class Hosts extends Component<IHostsProps, IHostsState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IHostsProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }
    
    componentDidUpdate() {

        if (this.props.hosts !== this.state.hosts) {

            // Refresh the data.
            this.getData();
        }
    }

    async getData() {
        const { hosts } = this.props;

        let hostsAdminDataTable: HostsAdminDataTable[] = [];

        for (let index = 0; index < hosts.length; index++) {

            hostsAdminDataTable.push({
                id: hosts[index].id,
                name: hosts[index].name,
                hostAddress: hosts[index].host,
                updated: hosts[index].updated,
                type: hosts[index].type,
                host: hosts[index]
            });
        }

        this.setState({
            loading: DATA_LOADED,
            hosts,
            hostsAdminDataTable
        });
    }

    /* Action Methods */

    refresh = () => {
        
        this.setState({
            loading: DATA_LOADING
        });
        
        this.getData();
    }

    render() {
        const { loading, hostsAdminDataTable } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>Hosts - Settings - DBmarlin</title>
                    <meta name="description" content="Manage monitored hosts" />
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Hosts">
                        <Link to="/admin">Settings</Link>
                        <span>Hosts</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh} />
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/hosts/create" render={() => <Detail actionId={ACTIONS.CREATE.type} />} />
                        <Route exact path="/admin/hosts/:actionId/:hostId" render={(props) => <Detail {...props} actionId={props.match.params.actionId} hostId={Number(props.match.params.hostId)} />} />
                        <Route exact path="/admin/hosts/" render={(props) => <List {...props} loading={loading} hostsAdminDataTable={hostsAdminDataTable} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

Hosts.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Hosts;
