import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeRangeContext } from "../../../context/TimeRangeContext";

// Third-party packages.

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Helpers.
import Helper from '../../../helpers/Helper';
import { getInstanceExecutions, getInstanceTotalTime } from "./utils";

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import HostTarget from '../../../types/host/HostTarget';
import Period from '../../../types/Period';
import PieChartSeries from '../../../types/PieChartSeries';
import Statement from '../../../types/instance/Statement';
import Wait from '../../../types/instance/Wait';
import Change from '../../../types/instance/Change';
import Event from '../../../types/instance/Event';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine'
import StatementsDataTable from '../../../types/instance/tables/StatementsDataTable';
import WaitsDataTable from '../../../types/instance/tables/WaitsDataTable';
import ChartOption from '../../../types/instance/ChartOption';
import Batch from "../../../types/instance/Batch";
import FilterOption from '../../../types/instance/FilterOption';

// Constants.
import { CHART_DATA_OPTION } from '../../../component/Constants';
import { INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER } from '../../../component/Constants';
import { DATA_LOADING, DATA_FAILED, DATA_LOADED } from '../../../component/Constants';
import { CHART_COLOURS_STATEMENTS, CHART_COLOURS_WAITS } from '../../../component/Constants';

// Components.
import TimeChart from '../../../component/instance/chart/TimeChart';
import HostsOverTimeCharts from '../../../component/instance/chart/HostsOverTimeCharts';
import PieChart from '../../../component/PieChart';
import WidgetsWrappers from "../../../component/instance/widget/WidgetsWrapper";
import TablesWrapper from "../../../component/instance/table/TablesWrapper";
import ActivityTabs from "../../../component/instance/Tabs";
import Alert from "../../../types/instance/Alert";
import api from "../../../api/Base";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IActivityProps {
    instance: InstanceTarget,
    hosts: HostTarget[],
    batchId?: string,
    filterOptions: FilterOption[],
    filterParameters: string,
    period: Period,
    chartPlotBands: ChartPlotBand[],
    chartPlotLines: ChartPlotLine[],
    changes: Change[],
    events: Event[],
    alerts: Alert[],
    setFilterOptions: Function,
    applyPeriod: Function
}

const chartSeriesCeiling = Number(process.env.REACT_APP_CHART_SERIES_CEILING);

const Activity = (props: IActivityProps) => {
    const [loading, setLoading] = useState(DATA_LOADING);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [totalInstanceTime, setTotalInstanceTime] = useState(0);
    const [totalPreviousInstanceTime, setTotalPreviousInstanceTime] = useState(0);
    const [executions, setExecutions] = useState(0);
    const [previousExecutions, setPreviousExecutions] = useState(0);
    const [timeChartDataOption, setTimeChartDataOption] = useState(CHART_DATA_OPTION.ACTIVITY);
    const [timeChartDataTitle, setTimeChartDataTitle] = useState('Activity Chart');

    const [displayHostMetrics, setDisplayHostMetrics] = useState<boolean>(false);

    const [batchesLength, setBatchesLength] = useState<number>(0);
    const [databasesLength, setDatabasesLength] = useState<number>(0);
    const [sessionsLength, setSessionsLength] = useState<number>(0);
    const [clientsLength, setClientsLength] = useState<number>(0);
    const [usersLength, setUsersLength] = useState<number>(0);
    const [programsLength, setProgramsLength] = useState<number>(0);

    const [waits, setWaits] = useState<Wait[]>([]);
    const [batches, setBatches] = useState<Batch[]>([]);
    const [statements, setStatements] = useState<Statement[]>([]);

    const [statementsDataTable, setStatementsDataTable] = useState<StatementsDataTable[]>([]);
    const [waitsDataTable, setWaitsDataTable] = useState<WaitsDataTable[]>([]);

    const [statementsPieChartSeries, setStatementsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [waitsPieChartSeries, setWaitsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [batchesPieChartSeries, setBatchesPieChartSeries] = useState<PieChartSeries[]>([]);
    const [clientsPieChartSeries, setClientsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [databasesPieChartSeries, setDatabasesPieChartSeries] = useState<PieChartSeries[]>([]);
    const [programsPieChartSeries, setProgramsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [sessionsPieChartSeries, setSessionsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [usersPieChartSeries, setUsersPieChartSeries] = useState<PieChartSeries[]>([]);

    const {
        instance,
        hosts,
        chartPlotBands,
        chartPlotLines,
        changes,
        events,
        alerts,
        setFilterOptions
    } = props;
    const timeRangeContext = useContext(TimeRangeContext);

    useEffect(() => {
        void getData();
    }, []);

    const getData = async() => {
        const {instance} = props;
        try {
            await getStatements(instance.id);
            await getInstanceTime(instance.id);
            if (!props.filterParameters) {
                await getExecutions(instance.id);
            }
            await getWaits(instance.id);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (timeChartDataOption === CHART_DATA_OPTION.BATCHES) {
            getBatches(instance.id);
        }
    }, [timeChartDataOption]);

    const getInstanceTime = async(instanceId: number) => {
        const executionsTimeResult = await getInstanceTotalTime(instanceId, timeRangeContext, props.filterParameters)
        setTotalInstanceTime(executionsTimeResult.totalInstanceTime)
        setTotalPreviousInstanceTime(executionsTimeResult.totalPreviousInstanceTime)
    }

    const getExecutions = async(instanceId: number) => {
        const executionsResult = await getInstanceExecutions(instanceId, timeRangeContext, props.filterParameters)
        setExecutions(executionsResult.executions)
        setPreviousExecutions(executionsResult.previousExecutions)
    }

    const getStatements = async(instanceId: number) => {
        // Get statements.
        api.get(`activity/sql?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&statistic=executions&sort=waittime+desc&id=${instanceId}${props.filterParameters}`)
            .then((response: { data: Statement[]; }) => {

                let statementsDataTable: StatementsDataTable[] = [],
                    topStatementsChartSeries: PieChartSeries[] = [];
                // Build top statements pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_STATEMENTS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_STATEMENTS[index];
                    }
                    statementsDataTable.push({
                        id: index,
                        color: ((index < CHART_COLOURS_STATEMENTS.length) ? CHART_COLOURS_STATEMENTS[index] : ''),
                        sqlhash: response.data[index].sqlhash,
                        sqltext: response.data[index].sqltext.slice(0, 1000),
                        executions: ((response.data[index].executions === undefined) ? null : Number(response.data[index].executions)),
                        waittime: response.data[index].waittime,
                        averagetime: ((response.data[index].executions === undefined) ? null : Number((response.data[index].waittime / Number(response.data[index].executions)).toFixed(2))),
                        statement: response.data[index]
                    });

                    topStatementsChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: ((response.data[index].sqlhash !== null) ? response.data[index].sqlhash : '-'),
                        y: response.data[index].waittime,
                        url: ((response.data[index].sqlhash !== null && response.data[index].sqlhash !== '-') ? `/instances/${instanceId}/activity/statement/${response.data[index].sqlhash}` : null)
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Statement) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    topStatementsChartSeries.push({
                        color: '#5e4fa2',
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Statements',
                        y: remainingTime,
                        url: null
                    });
                }
                setStatementsDataTable(statementsDataTable)
                setLoading(DATA_LOADED)
                setStatements(response.data)
                setStatementsPieChartSeries(topStatementsChartSeries.slice(0, chartSeriesCeiling))

            })
            .catch((error: any) => {

                // Todo: handle and log the error.
                console.log('An error occurred:', error);
                setLoading(DATA_FAILED)
            })
    }

    const getBatches = (instanceId: number) => {
        // Get batches.
        api.get(`activity/batch?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${props.filterParameters}`)
            .then((response: { data: Batch[]; }) => {
                let topBatchesChartSeries: PieChartSeries[] = [];

                // Build top batches pie chart.
                for (let index = 0; index < response.data.length &&
                index < CHART_COLOURS_STATEMENTS.length; index++) {
                    topBatchesChartSeries.push({
                        color: CHART_COLOURS_STATEMENTS[index],
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: ((response.data[index].batchsqlhash !== null) ? response.data[index].batchsqlhash : '-'),
                        y: response.data[index].waittime,
                        url: ((response.data[index].batchsqlhash !== null && response.data[index].batchsqlhash !== '-') ? `/instances/${instanceId}/activity/batch/${response.data[index].batchsqlhash}` : null)
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Batch) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    topBatchesChartSeries.push({
                        color: '#5e4fa2',
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Statements',
                        y: remainingTime,
                        url: null
                    });
                }
                setBatches(response.data)
                setBatchesPieChartSeries(topBatchesChartSeries.slice(0, chartSeriesCeiling))
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.log('An error occurred:', error);
                setLoading(DATA_FAILED)
            })
    }

    const getWaits = async(instanceId: number) => {
        // Get waits.
        api.get(`activity/waitevent?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${props.filterParameters}`)
            .then(async(response: { data: Wait[]; }) => {

                let waitsDataTable: WaitsDataTable[] = [],
                    topWaitsChartSeries: PieChartSeries[] = [];

                // Build top waits pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_WAITS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_WAITS[index];
                    }

                    waitsDataTable.push({
                        id: index,
                        color: response.data[index].color,
                        waitevent: response.data[index].waitevent,
                        waittime: response.data[index].waittime
                    });

                    topWaitsChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: response.data[index].waitevent,
                        y: response.data[index].waittime
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Wait) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    topWaitsChartSeries.push({
                        color: CHART_COLOURS_WAITS[chartSeriesCeiling],
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Waits',
                        y: remainingTime
                    });
                }
                setWaitsDataTable(waitsDataTable)
                setWaits(response.data)
                setPieChartLoading(false)
                setWaitsPieChartSeries(topWaitsChartSeries.slice(0, chartSeriesCeiling))
            })
            .catch((error: any) => {

                // Todo: handle and log the error.
                console.log('An error occurred:', error);

                setLoading(DATA_FAILED)
            })
    }

    const toggleTimeChartData = (timeChartDataOption: number) => {

        let timeChartDataTitle: string = 'Unknown Chart';

        switch (timeChartDataOption) {
            case CHART_DATA_OPTION.ACTIVITY:
                timeChartDataTitle = 'Activity Chart';
                break;
            case CHART_DATA_OPTION.BATCHES:
                timeChartDataTitle = 'Batches Chart';
                break;
            case CHART_DATA_OPTION.STATEMENTS:
                timeChartDataTitle = 'Statements Chart';
                break;
            case CHART_DATA_OPTION.WAITS:
                timeChartDataTitle = 'Waits Chart';
                break;
            default:
                break;
        }
        setTimeChartDataOption(timeChartDataOption)
        setTimeChartDataTitle(timeChartDataTitle)
    }

    const toggleHostMetricChart = () => {
        setDisplayHostMetrics(!displayHostMetrics)
    }

    const setBatchesOptions = (batchesLength: number, batchesPieChartSeries: PieChartSeries[]) => {
        setBatchesLength(batchesLength)
        setBatchesPieChartSeries(batchesPieChartSeries)
    }

    const setDatabasesOptions = (databasesLength: number, databasesPieChartSeries: PieChartSeries[]) => {
        setDatabasesLength(databasesLength)
        setDatabasesPieChartSeries(databasesPieChartSeries)
    }

    const setSessionsOptions = (sessionsLength: number, sessionsPieChartSeries: PieChartSeries[]) => {
        setSessionsLength(sessionsLength)
        setSessionsPieChartSeries(sessionsPieChartSeries)
    }

    const setClientsOptions = (clientsLength: number, clientsPieChartSeries: PieChartSeries[]) => {
        setClientsLength(clientsLength)
        setClientsPieChartSeries(clientsPieChartSeries)
    }

    const setUsersOptions = (usersLength: number, usersPieChartSeries: PieChartSeries[]) => {
        setUsersLength(usersLength)
        setUsersPieChartSeries(usersPieChartSeries)
    }

    const setProgramsOptions = (programsLength: number, programsPieChartSeries: PieChartSeries[]) => {
        setProgramsLength(programsLength)
        setProgramsPieChartSeries(programsPieChartSeries)
    }

    let chartOptions: ChartOption[] = [];

    if (instance.type === INSTANCE_TYPE_SQLSERVER) {

        // Batches.
        chartOptions.push({
            optionId: 0,
            key: 'Batches',
            data: batchesPieChartSeries
        });
    }

    // Clients.
    chartOptions.push({
        optionId: 0,
        key: 'Clients',
        data: clientsPieChartSeries
    });

    // Databases.
    chartOptions.push({
        optionId: 0,
        key: ((instance.type === INSTANCE_TYPE_ORACLE) ? 'Schemas' : 'Databases'),
        data: databasesPieChartSeries
    });

    // Programs.
    chartOptions.push({
        optionId: 0,
        key: 'Programs',
        data: programsPieChartSeries
    });

    // Statements.
    chartOptions.push({
        optionId: 0,
        key: 'Statements',
        data: statementsPieChartSeries
    });

    // Sessions.
    chartOptions.push({
        optionId: 0,
        key: 'Sessions',
        data: sessionsPieChartSeries
    });

    // Users.
    chartOptions.push({
        optionId: 0,
        key: 'Users',
        data: usersPieChartSeries
    });

    // Waits.
    chartOptions.push({
        optionId: 0,
        key: 'Waits',
        data: waitsPieChartSeries
    });

    const pieChartDataExist = (chartData: ChartOption[], type: string) => {
        const getData = chartData.find(item => item.key === type)
        return !!(getData && getData.data.length);
    }

    const loadingPieChart = (title: String) => {
        return <div className="col">
            <div className="card collapsible">
                <div className="card-header">
                    <i className="fal fa-chart-pie-alt fa-fw" aria-hidden="true"></i>
                    Top {title}
                    <div className="btn-group float-end">
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                           data-bs-target=".multi-collapse-pie" aria-expanded="false" aria-controls=""></i>
                    </div>
                </div>
                {pieChartLoading ?
                    <div className="col text-center text-muted mt-3 pie-chart-loader">
                        <div className="loader spinner">
                        </div>
                        <p className="mt-3">
                            Loading data...
                        </p>
                    </div> : <div className="w-100 text-center text-muted pie-chart-no-data">
                        <i className="fal fa-ban fa-fw fa-2x"></i>
                        <p className="mt-3">
                            No data found
                        </p>
                    </div>}
            </div>
        </div>
    }

    return (
        <React.Fragment>
            <WidgetsWrappers executions={executions}
                             previousExecutions={previousExecutions}
                             filterParameters={props.filterParameters}
                             totalInstanceTime={totalInstanceTime}
                             totalPreviousInstanceTime={totalPreviousInstanceTime}
                             alerts={alerts} changes={changes} events={events}
            />

            {/* Main Content Widgets */}
            <div className="row">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                            Time spent
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseExecutions" aria-expanded="false"
                               aria-controls="collapseExecutions"/>
                            <div className="btn-group float-end">
                                {hosts.length > 0 && (
                                    <button type="button" className="btn btn-xsm btn-primary float-end ms-1"
                                            onClick={() => toggleHostMetricChart()}>
                                        {((displayHostMetrics) ? 'Hide Hosts' : 'Show Hosts')}
                                    </button>
                                )}

                                <button id="btnChartList" type="button"
                                        className="btn btn-xsm btn-primary dropdown-toggle float-end ms-1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    {timeChartDataTitle}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnChartList">
                                    <li>
                                            <span
                                                className={`dropdown-item ${((timeChartDataOption === CHART_DATA_OPTION.ACTIVITY) ? 'active' : '')}`}
                                                role="button"
                                                onClick={() => toggleTimeChartData(CHART_DATA_OPTION.ACTIVITY)}>Activity Chart</span>
                                        {instance.type === INSTANCE_TYPE_SQLSERVER && (
                                            <span
                                                className={`dropdown-item ${((timeChartDataOption === CHART_DATA_OPTION.BATCHES) ? 'active' : '')}`}
                                                role="button"
                                                onClick={() => toggleTimeChartData(CHART_DATA_OPTION.BATCHES)}>Batches Chart</span>
                                        )}
                                        <span
                                            className={`dropdown-item ${((timeChartDataOption === CHART_DATA_OPTION.STATEMENTS) ? 'active' : '')}`}
                                            role="button"
                                            onClick={() => toggleTimeChartData(CHART_DATA_OPTION.STATEMENTS)}>Statements Chart</span>
                                        <span
                                            className={`dropdown-item ${((timeChartDataOption === CHART_DATA_OPTION.WAITS) ? 'active' : '')}`}
                                            role="button"
                                            onClick={() => toggleTimeChartData(CHART_DATA_OPTION.WAITS)}>Waits Chart</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="collapseExecutions" className="card-body collapse show">
                            <TimeChart dataOption={timeChartDataOption} instanceId={instance.id}
                                       plotBands={chartPlotBands} plotLines={chartPlotLines} waits={waits}
                                       statements={statements}
                                       applyPeriod={props.applyPeriod}
                                       batches={batches} filters={props.filterParameters}/>
                            {displayHostMetrics && (
                                <HostsOverTimeCharts instance={instance} hosts={hosts}
                                                     plotBands={chartPlotBands}
                                                     applyPeriod={props.applyPeriod}
                                                     plotLines={chartPlotLines}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row row-cols-1 row-cols-lg-2">
                {loading === DATA_LOADED && pieChartDataExist(chartOptions, 'Statements') ?
                    <PieChart filtersOptions={props.period.filters.options} uniqueIndex={0}
                              chartOptions={chartOptions}
                              instanceType={instance.type}
                              defaultChartOption={chartOptions.filter(option => option.key === 'Statements')[0]}
                              setFilterOptions={props.setFilterOptions}/> : loadingPieChart('statements')}

                {loading === DATA_LOADED && pieChartDataExist(chartOptions, 'Waits') ?
                    <PieChart filtersOptions={props.period.filters.options} uniqueIndex={1}
                              chartOptions={chartOptions}
                              instanceType={instance.type}
                              defaultChartOption={chartOptions.filter(option => option.key === 'Waits')[0]}
                              setFilterOptions={props.setFilterOptions}/>
                    : loadingPieChart('waits')}
            </div>

            <div className="row row-cols-1">


                {/* Tab Headings */}
                <ActivityTabs
                    instanceType={instance.type}
                    statementsLength={statements.length}
                    batchesLength={batchesLength}
                    waitsLength={waits.length}
                    clientsLength={clientsLength}
                    databasesLength={databasesLength}
                    sessionsLength={sessionsLength}
                    usersLength={usersLength}
                    programsLength={programsLength}
                    isGroup={false}
                    filterOptions={props.filterOptions}
                />

                <TablesWrapper period={props.period}
                               instance={instance}
                               isGroup={false}
                               loading={loading}
                               totalInstanceTime={totalInstanceTime}
                               statementsDataTable={statementsDataTable}
                               waits={waits}
                               waitsDataTable={waitsDataTable} setFilterOptions={setFilterOptions}
                               setBatchesOptions={setBatchesOptions}
                               setDatabasesOptions={setDatabasesOptions}
                               setClientsOptions={setClientsOptions}
                               setProgramsOptions={setProgramsOptions}
                               setSessionsOptions={setSessionsOptions}
                               setUsersOptions={setUsersOptions}
                />
            </div>

        </React.Fragment>
    );
};

Activity.propTypes = {
    setFilterOptions: PropTypes.func.isRequired
};

export default Activity;
