import React, { useContext, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Types.
import Helper from '../../../helpers/Helper';
import { Link } from "react-router-dom";

// Helpers
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import { highchartsCredits } from "../../../helpers/utils";

// Context
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { useLicences } from "../../../context/LicenceContext";

function SimpleTimeChart(props: {
    instanceId: number,
    statementId: number,
    timeSeries: number[],
    executionsSeries?: number[],
    type: string,
    applyPeriod: Function
}) {
    const timeRangeContext = useContext(TimeRangeContext)
    const {licences} = useLicences();

    const timePeak = useMemo(() => {
        // @ts-ignore
        return Helper.getPeakValue(props.timeSeries.map(i => i[1]))
    }, [props.timeSeries])

    const timeChart = useMemo(() => {

        const timeAxisName = props.executionsSeries ? 'Duration' : 'Time Spent'
        const timeChartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                type: 'column', color: "rgb(105, 209, 191)", data: props.timeSeries, name: timeAxisName, zIndex: 1, tooltip: {valueSuffix: 'ms',}
            },
            ...(props.executionsSeries
                    ? [
                        {
                            type: 'spline',
                            color: '#ff853d',
                            minHeight: 3,
                            data: props.executionsSeries,
                            name: 'Executions',
                            zIndex: 1,
                            yAxis: 1
                        }
                    ]
                    : []
            )
        ], timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            legend: true,
            timeRangeContext: timeRangeContext,
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                options={timeChartOptions}/>;

    }, [props.timeSeries, timePeak, timeRangeContext, licences]);

    const getURLString = (type: string) => {
        let statementType = ''

        switch (type) {
            case 'Statement':
                statementType = 'statement';
                break;
            case 'Grouped Statement':
                statementType = 'groupedstatement';
                break;
            case 'Batch':
                statementType = 'batch';
                break;
            case 'Grouped Batch':
                statementType = 'groupedbatch';
                break;
            default:
                statementType = 'statement';
                break;
        }
        const sqLStatisticUrl = props.executionsSeries ? '/sql-statistics' : ''
        return `/instances/${props.instanceId}/activity/${statementType}/${props.statementId}${sqLStatisticUrl}`
    }

    return (
        <div className="row w-50">
            <div className="col">
                <div className="card collapsible">
                    <div className="card-header">
                        <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                        {props.executionsSeries ? 'Duration (ms) and Executions' : 'Time Spent'}
                        <strong> ({props.type})</strong>
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                           data-bs-target="#collapseTimeChart" aria-expanded="false"
                           aria-controls="collapseExecutions"/>
                        <div className="btn-group float-end">
                            <Link to={getURLString(props.type)} type="button"
                                  className="btn btn-xsm btn-primary float-end ms-1">
                                View More
                            </Link>
                        </div>
                    </div>

                    <div id="collapseTimeChart" className="card-body collapse show">
                    <span className="peak">
                        {(timePeak === Infinity || timePeak === -Infinity) ? (
                            <React.Fragment>Peak Time: -</React.Fragment>
                        ) : (
                            <React.Fragment>Peak Time: {Helper.getTimeInEnglish(timePeak)}</React.Fragment>
                        )}
                    </span>
                        <br/>
                        {timeChart}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimpleTimeChart;
