// Api.
import api from '../Base';
import api_v2 from '../Base_v2';

// Types.
import Period from '../../types/Period';
import Event from '../../types/instance/Event';
import { TimeRangeContextType } from "../../context/TimeRangeContext";

const EventsApi = {

    getEventsChangesAndAlerts: async(timeRangeContext: TimeRangeContextType, instanceId: number) => {
        try {
            let url = `event?${timeRangeContext.getTimeRangeQueryString()}&sort=timeslice+desc&limit=10000&datasourceid=${instanceId}`

            const response = await api_v2.get(url)
            return response ? response.data : []
        } catch (error: any) {
            console.error('Failed to retrieve event data.', error);
        }
    },

    getAllEventsChangesAndAlerts: async(timeRangeContext: TimeRangeContextType) => {
        try {
            let url = `event?${timeRangeContext.getTimeRangeQueryString()}&sort=timeslice+desc&limit=10000`
            const response = await api_v2.get(url)
            return response ? response.data : []
        } catch (error: any) {
            console.error('Failed to retrieve event data.', error);
        }
    },

    getAllEvents: async(period: Period) => {
        let url = `event?from=2020-01-01+00:00:00&to=${period.api.current.to}&tz=${period.api.timezone}&interval=0&sort=eventId+desc&limit=50000`;
        let events: Event[] = [];

        await api.get<Event[]>(url)
            .then((response) => {
                events = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve events.', error);
            })
            .then(function () {
            });

        return events;
    }
}

export default EventsApi;