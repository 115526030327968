import React, { useContext, useMemo, useState } from 'react';
import { TimeRangeContext } from "../../../context/TimeRangeContext";

import Skeleton from 'react-loading-skeleton';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';
import api from "../../../api/Base";

function PeakStatistic(props: { hostId: number, statistic: string, metric: number | null }) {
    const [previousMetric, setPreviousMetric] = useState<number | null>(null);
    const [differenceMetric, setDifferenceMetric] = useState<number | null>(null);
    const timeRangeContext = useContext(TimeRangeContext)

    useMemo(() => {

        const getStatistic = async() => {

            let previousData: number[] = [],
                previousPeak: number = 0;

            // Previous period.
            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=${props.statistic}&id=${props.hostId}`)
                .then((response: { data: HostTimeStatistic[], status: number }) => {
                    if (response.status === 200) {
                        for (let index = 0; index < response.data.length; index++) {
                            previousData.push(response.data[index].avg);
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to get peak host data', error, props.statistic);
                })
                .then(() => {
                    // Get the peak value.
                    previousPeak = Math.round(Helper.getPeakValue(previousData));
                });

            setPreviousMetric(previousPeak);
            setDifferenceMetric(Helper.getPercentageDifference(props.metric, previousPeak));
        }

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {
            getStatistic();
        }

    }, [timeRangeContext, props.hostId, props.statistic, props.metric]);

    return (
        props.metric === null ? (
            <Skeleton/>
        ) : (
            <React.Fragment>
                <span className="table-metric-value">
                    {Math.round(props.metric)}%
                </span>
                {previousMetric !== null && previousMetric !== Infinity && previousMetric !== -Infinity && (
                    <span className="comparison">
                        {previousMetric}%
                        {differenceMetric !== null && (
                            <span>({differenceMetric}%)</span>
                        )}
                        {props.metric === previousMetric ? (
                            <i className="fal fa-equals fa-fw equals"/>
                        ) : (
                            props.metric > previousMetric ? (
                                <i className="fal fa-long-arrow-up fa-fw increase"/>
                            ) : (
                                <i className="fal fa-long-arrow-up fa-fw decrease"/>
                            )
                        )}
                    </span>
                )}
            </React.Fragment>
        )
    );
}

export default PeakStatistic;