import React, { useContext, useMemo } from 'react';

// Third-party.
import Skeleton from 'react-loading-skeleton';
import Helper from '../../../helpers/Helper';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import ConditionalRender from "../../../helpers/ConditionalRender";

function ExecutionsStaticWidget(props: { metric: undefined | null | number, metricPrevious: undefined | null | number }) {
    const {metricPrevious, metric: metricProp} = props
    const timeRangeContext = useContext(TimeRangeContext)
    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)

    const metric = useMemo(() => {

        let metricPercentageDifference: null | number = null;

        if (metricProp !== undefined && metricPrevious !== undefined && metricProp !== null && metricPrevious !== null) {
            metricPercentageDifference = Helper.getPercentageDifference(metricProp, metricPrevious);
        }

        if (metricPercentageDifference === Infinity) {
            metricPercentageDifference = 100;
        } else if (metricPercentageDifference === -Infinity) {
            metricPercentageDifference = -100;
        }

        return (
            <div className="col flexlist-item">
                <div className="card">
                    <div className="card-body widget">
                        <div className="row g-0 align-items-center card-row">
                            <div className="icon executions" data-tip="The total number of DB executions">
                                <span className="fal fa-chart-line fa-fw"></span>
                            </div>
                            <div className="col data">
                                Executions
                                <div>
                                    {
                                        metricProp !== undefined ? (
                                            metricProp !== null ? (
                                                <React.Fragment>
                                                    {metricProp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    <ConditionalRender if={comparisonSupported}>
                                                        {metricPrevious !== undefined && metricPrevious !== null && (
                                                            <span className="comparison">
                                                    {metricProp === metricPrevious ? (
                                                        <i className="fal fa-equals equals fa-fw"></i>
                                                    ) : (
                                                        metricProp > metricPrevious ? (
                                                            <i className="fal fa-long-arrow-up fa-fw increase"></i>
                                                        ) : (
                                                            <i className="fal fa-long-arrow-up fa-fw decrease"></i>
                                                        )
                                                    )}
                                                                {metricPrevious.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                {metricPercentageDifference !== null && (
                                                                    <React.Fragment>&nbsp;({metricPercentageDifference}%)</React.Fragment>
                                                                )}
                                                </span>
                                                        )}
                                                    </ConditionalRender>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>-</React.Fragment>
                                            )
                                        ) : (
                                            <Skeleton/>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }, [metricPrevious, metricProp])

    return (
        metric
    );
}

export default ExecutionsStaticWidget;