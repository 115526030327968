// React.
import { useMemo } from 'react';
import PropTypes from "prop-types";
import Highlight from "react-highlight";
import { Link } from "react-router-dom";

// Components.
import { formatSqlStatement } from "../../../helpers/utils";

function StatementToolTip(props: { cell: any, link: string, id: string, instanceType: string, position: number }) {

	const { cell, link, id, instanceType } = props;
	const limit: number = 999;
	const showFull: boolean = cell.row.values.sqltext.length <= limit;

    const text: string = useMemo (() =>
	{
		let text: string = formatSqlStatement (cell.row.values.sqltext,
			instanceType);

		if (text.length > limit) {
			text = text.substring (0, limit) + '\n...';
		}

		return text;
	}, [ cell.row.values.sqltext, instanceType ]);

    return <div id={id} data-testid='tooltip-wrapper' className="tooltip-wrapper"
				style={{ left: props.position }}>
        <div className="tooltip-content">
            <Highlight className={showFull ? "sql" : "sql preview"}>
                {text}
            </Highlight>
            {!showFull && <Link className='tooltip-link' to={link}>See full statement</Link>}
        </div>
    </div>
}

StatementToolTip.propTypes = {
    cell: PropTypes.object.isRequired,
};

export default StatementToolTip;
