function DifferenceIcon(props: { valueA: number, valueB: number }) {
    return (
        props.valueA === props.valueB ? (
            <span className="comparison"><i className="fal fa-equals fa-fw equals"></i></span>
        ) : (
            props.valueA > props.valueB ? (
                <span className="comparison"><i className="fal fa-long-arrow-up fa-fw decrease"></i></span>
            ) : (
                <span className="comparison"><i className="fal fa-long-arrow-up fa-fw increase"></i></span>
            )
        )
    );
}

export default DifferenceIcon;