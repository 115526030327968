import React, { useContext, useMemo, useState } from "react";

import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { InstanceContext } from "../../context/InstanceContext";
import { Link } from "react-router-dom";

import { CSVLink } from 'react-csv';

// Components.
import TablePagination from '../../component/TablePagination';
import StatementToolTip from "../../component/instance/table/StatementToolTip";

// Types
import InstanceType from "../../component/instance/InstanceType";

// Utils
import { getDefaultPageSize, getToolTipPosition } from "../../helpers/utils";

function ResultsTable(props: { loading: boolean, list: any }) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const {instances} = useContext(InstanceContext);
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = useMemo(() => {
        const tableData: any[] = []
        props.list.forEach((item: any) => {
            const getInstance = instances.find(instance => instance.id === item.id)
            if (getInstance) {
                tableData.push({
                    id: getInstance.id,
                    instance: getInstance.name,
                    type: getInstance.type,
                    sqlhash: item.sqlhash,
                    grouphash: item.grouphash,
                    similarity: item.similarity,
                    similarityPercent: `${Math.round(item.similarity * 100)}%`,
                    sqltext: item.sqltext,
                })
            }
        })
        return tableData.filter((row: any) => (
            !row.sqltext.toLowerCase().startsWith('explain') &&
            (row.sqltext.toLowerCase().includes(tableFilter.toLowerCase()) || row.instance.toLowerCase().includes(tableFilter.toLowerCase()))
        ))
    }, [props.list, tableFilter]);

    const columns: Column[] = useMemo(() => {
        return ([
            {
                accessor: 'id',
                disableSortBy: true
            },
            {
                accessor: 'grouphash',
            },
            {
                accessor: 'sqlhash',
                disableSortBy: true
            },
            {
                Header: 'Instance',
                accessor: 'instance',
                className: 'border-spacer fw-bold col-width-20 text-nowrap',
            },
            {
                Header: '',
                accessor: 'type',
                className: 'text-center col-width-15',
                headerClassName: 'text-center col-width-15',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
                Cell: ({ cell }) => (
                    <React.Fragment>
                        {/* @ts-ignore*/}
                        <InstanceType instance={instances.find(instance => instance.id === cell.row.values.id)} />
                    </React.Fragment>
                )
            },
            {
                Header: 'Statement',
                accessor: 'sqltext',
                className: 'concatenate col-width-60',
                disableSortBy: true,

                Cell: ({cell}: any) => cell.row.values.sqltext ? <Link
                        to={`/instances/${cell.row.values.id}/statement-activity/${cell.row.values.sqlhash}/text`}>{cell.row.values.sqltext.substring(0, 200)}</Link> :
                    <React.Fragment>-</React.Fragment>

            },
            {
                Header: 'Similarity',
                accessor: 'similarity',
                sortType: 'basic',
                className: 'fw-bold col-width-10 text-nowrap text-end',
                Cell: ({ cell }) => {
                    const roundedValue = cell.row.values.similarity > 1 ? 1 : cell.row.values.similarity
                    return `${Math.round(roundedValue * 100)}%`
                }
            },
        ])
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id', 'sqlhash', 'grouphash'],
                pageIndex: 0,
                pageSize: getDefaultPageSize()
            }
        },
        useSortBy, usePagination);

    return (
        <div>
            <div className="row row-cols-1 row-cols-md-2 table-search">
                <div className="col col-md-9">
                    <CSVLink role="button"
                             headers={[
                                 {label: 'ID', key: 'id'},
                                 {label: 'Instance', key: 'instance'},
                                 {label: 'Statement', key: 'sqltext'},
                                 {label: 'Similarity', key: 'similarityPercent'}
                             ]}
                             data={data}
                             download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Users.csv`}
                             className="btn btn-sm btn-primary">
                        <i className="fal fa-file-export fa-fw"/><span>Export</span>
                    </CSVLink>
                    <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                        className="far fa-undo"/><span>Clear</span></button>
                </div>
                <div className="col col-md-3">
                    <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                           value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)}/>
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={(column as any).className}>
                                    {column.render('Header')}
                                    {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                            <i className="fal fa-sort-amount-up-alt"/> :
                                            <i className="fal fa-sort-amount-down-alt"/>) :
                                        <i className="fal fa-sort-amount-down-alt text-light"/>) : ''}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={(cell.column as any).className}
                                            style={{borderLeft: (cell.column as any).color}}>


                                            {cell.column.id === "sqltext" ?
                                                <div className='tooltip-scroll ellipsis'
                                                     onMouseEnter={(event) => setVisibleToolTip({
                                                         id: `${cell.row.values.sqlhash}-${cell.row.values.id}`,
                                                         position: getToolTipPosition(event)
                                                     })}
                                                     onMouseLeave={() => setVisibleToolTip({
                                                         id: '',
                                                         position: 0
                                                     })}
                                                >
                                                    {cell.render('Cell')}
                                                    {(`${cell.row.values.sqlhash}-${cell.row.values.id}` === visibleToolTip.id &&
                                                        <StatementToolTip instanceType={cell.row.values.type}
                                                                          position={visibleToolTip.position}
                                                                          id={`${cell.row.values.sqlhash}-${cell.row.values.id}`}
                                                                          link={`/instances/${cell.row.values.id}/statement-activity/${cell.row.values.sqlhash}/text`}
                                                                          cell={cell}/>
                                                    )}
                                                </div> : cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </div>
    );
}

ResultsTable.propTypes = {};

export default ResultsTable;
