import React from "react";
import ConditionalRender from "../../../helpers/ConditionalRender";

import './style.scss';
import Skeleton from "react-loading-skeleton";

function WidgetCard(props: { values: { icon: string, value: string | number, tooltip: string }[], cardTooltipText: string, name: string, iconClassName: string, icon?: string, loading?: boolean }) {
    const icon = props.icon || 'fa-exchange'
    return (
        <div className="col flexlist-item">
            <div className="card">
                <div className="card-body widget">
                    <div className="row g-0 align-items-center card-row">
                        <div className={`icon ${props.iconClassName}`} data-tip={props.cardTooltipText}>
                            <span className={`fal ${icon} fa-fw`}></span>
                        </div>
                        <div className="col data">
                            {props.name}
                            <ConditionalRender if={props.loading}>
                                <Skeleton />
                            </ConditionalRender>
                            <ConditionalRender if={!props.loading}>
                                <div>
                                    {props.values.map((value, index) => {
                                        return <React.Fragment key={index}>
                                            <ConditionalRender if={value.icon}>
                                                <i className={`fal ${value.icon} fa-fw me-1`} data-tip={value.tooltip}></i>
                                            </ConditionalRender>
                                            <span className={"me-3"}>{value.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </React.Fragment>
                                    })}
                                </div>
                            </ConditionalRender>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WidgetCard;