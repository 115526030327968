import dayjs, { Dayjs } from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import { combinedIcons, determineTimeUnit, getParameterValues, instanaTechnology } from "./utils";
import { TimeRangeContextType } from "../context/TimeRangeContext";

// Api.
import api from '../api/Base';
import axios from 'axios';

// Constants.
import {
    CHART_DATA_TYPE,
    HOST_TYPE_LINUX,
    HOST_TYPE_WINDOWS,
    HOST_TYPE_AWSRDS,
    INSTANCE_TYPE_COCKROACHDB,
    INSTANCE_TYPE_DB2,
    INSTANCE_TYPE_MYSQL,
    INSTANCE_TYPE_ORACLE,
    INSTANCE_TYPE_POSTGRES,
    INSTANCE_TYPE_SQLSERVER,
    INTERVAL_FIFTEEN_MINUTES,
    INTERVAL_FIVE_MINUTES,
    INTERVAL_FOUR_HOURS,
    INTERVAL_MINUTE,
    INTERVAL_ONE_DAY,
    INTERVAL_ONE_HOUR,
    INTERVAL_SECOND,
    INTERVAL_SIX_HOURS,
    PERIOD_CUSTOM,
    PERIOD_DAY_BEFORE_YESTERDAY,
    PERIOD_FIVE_MINUTES,
    PERIOD_MINUTE,
    PERIOD_ONE_HOUR,
    PERIOD_PREVIOUS_WEEK,
    PERIOD_SEVEN_DAYS,
    PERIOD_SIX_HOURS,
    PERIOD_TEN_MINUTES,
    PERIOD_THIRTY_MINUTES,
    PERIOD_THIRTY_ONE_DAYS,
    PERIOD_THIS_WEEK,
    PERIOD_TODAY,
    PERIOD_TWELVE_HOURS,
    PERIOD_TWENTY_FOUR_HOURS,
    PERIOD_YESTERDAY,
    REPORT_PERIOD_INTERIM,
    REPORT_PERIOD_PREVIOUS,
    INTERVAL_TEN_MINUTES,
    INTERVAL_THIRTY_MINUTES,
    INTERVAL_TWELVE_HOURS,
    TARGET_COLOUR_CODES,
    periodToValidIntervals,
    INSTANCE_TYPE_INFORMIX,
    INSTANCE_TYPE_SAPHANA,
    INTERVAL_15_SECONDS,
    INTERVAL_ONE_WEEK
} from '../component/Constants';
import { TIME_METRICS } from "../container/statistics-comparison/constants";

// Types.
import InstanceTarget from '../types/instance/InstanceTarget';
import HostTarget from '../types/host/HostTarget';
import Period from '../types/Period';
import ChartPlotLine from '../types/ChartPlotLine';
import ChartPlotBand from '../types/ChartPlotBand';
import CombinedChanges from '../types/instance/CombinedChanges';
import Database from '../types/instance/Database';
import InstanaIntegration from '../types/integration/InstanaIntegration';
import InstanaService from '../types/integration/InstanaService';
import ExecutionPlan from '../types/instance/ExecutionPlan';
import EventType from '../types/instance/EventType';
import FilterOption from '../types/instance/FilterOption';
import { FilterType } from '../typescript/Enums';


// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(advancedFormat.default);

const Helper = {
    getFilterParams: () => {
        return [{
            filterType: FilterType.Waits,
            filters: getParameterValues(window.location.search, 'waits')
        }, {
            filterType: FilterType.Databases,
            filters: getParameterValues(window.location.search, 'databases')
        }, {
            filterType: FilterType.Sessions,
            filters: getParameterValues(window.location.search, 'sessions')
        }, {
            filterType: FilterType.Clients,
            filters: getParameterValues(window.location.search, 'clients')
        }, {
            filterType: FilterType.Users,
            filters: getParameterValues(window.location.search, 'users')
        }, {
            filterType: FilterType.Programs,
            filters: getParameterValues(window.location.search, 'programs')
        }]
    },

    getChartTooltips: (tooltip: any, chartDataType: number) => {

        // Get series total.
        let sum: number = tooltip.points.reduce(function (a: any, b: any) {
            return a + b['y'];
        }, 0);

        let headerData: string = '',
            data: string = '';

        for (let index = 0; index < tooltip.points.length; index++) {

            const point: any = tooltip.points[index];

            if (point.series.name === 'Executions') {
                data = data + `<tr><td><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}</td><td>${Helper.getFormattedNumber(point.y)}</td></tr>`
            } else {

                if (point.y > 0) {

                    switch (chartDataType) {
                        case CHART_DATA_TYPE.GENERIC:

                            data = data + `<tr><td><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}</td><td>${Helper.getFormattedNumber(point.y)}</td></tr>`

                            break;

                        case CHART_DATA_TYPE.PERCENTAGE:

                            data = data + `<tr><td><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}</td><td>${point.y}%</td></tr>`

                            break;

                        case CHART_DATA_TYPE.TIME:

                            data = data + `<tr><td><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}</td><td>${Helper.getTimeInEnglish(point.y)}</td></tr>`

                            break;

                        default:

                            console.error('Unknown chart data type requested for custom tooltip formatter.', chartDataType);

                            break;
                    }
                }
            }
        }

        if (chartDataType === CHART_DATA_TYPE.TIME) {

            // Set the tooltip header value.
            headerData = Helper.getTimeInEnglish(sum);
        }

        return `<div class="border rounded"><table class="chart-tooltip"><thead><tr><th>${tooltip.x}</th><th>${headerData}</th></tr></thead><tbody>${data}</tbody></table></div>`;
    },

    getChartTooltipsNew: (tooltip: any, chartDataType: number) => {

        let data: string = '';

        for (let index = 0; index < tooltip.points.length; index++) {

            const point: any = tooltip.points[index];

            if (point.series.name === 'Executions') {
                data = data + `<div class="d-flex justify-content-between"><div><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}:</div> <span style="padding-left: 20px" class="value">${Helper.getFormattedNumber(point.y)}</span></div>`
            } else {

                if (point.y > 0) {

                    switch (chartDataType) {
                        case CHART_DATA_TYPE.GENERIC:

                            data = data + `<div class="d-flex justify-content-between"><div><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}:</div> <span style="padding-left: 20px" class="value">${Helper.getFormattedNumber(point.y)}</span></div>`

                            break;

                        case CHART_DATA_TYPE.PERCENTAGE:

                            data = data + `<div class="d-flex justify-content-between"><div><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}:</div> <span style="padding-left: 20px" class="value">${point.y.toFixed(2)}%</span></div>`

                            break;

                        case CHART_DATA_TYPE.TIME:

                            data = data + `<div class="d-flex justify-content-between"><div><div class="dot" style="background-color: ${point.color}"></div>${point.series.name}:</div> <span style="padding-left: 20px" class="value">${Helper.getTimeInEnglish(point.y)}</span></div>`

                            break;

                        default:

                            console.error('Unknown chart data type requested for custom tooltip formatter.', chartDataType);

                            break;
                    }
                }
            }
        }
        return `<div class="chart-tooltip-new"><div class="tooltip-header">${Helper.formatDate(tooltip.x).replace(" at", ",")}</div>${data}`;
    },

    formatDate: (date: number | Date) => {
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        };

        // @ts-ignore
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date);
    },

    getChartDateFormat: (interval: number) => {

        let dateFormat = '';

        switch (interval) {
            case INTERVAL_SECOND:
                dateFormat = 'yyyy/MM/dd HH:mm:ss';
                break;
            case INTERVAL_MINUTE:
            case INTERVAL_FIVE_MINUTES:
            case INTERVAL_TEN_MINUTES:
            case INTERVAL_FIFTEEN_MINUTES:
            case INTERVAL_THIRTY_MINUTES:
                dateFormat = 'yyyy/MM/dd HH:mm:00';
                break;
            case INTERVAL_ONE_HOUR:
            case INTERVAL_FOUR_HOURS:
            case INTERVAL_SIX_HOURS:
                dateFormat = 'yyyy/MM/dd HH:00:00';
                break;
            case INTERVAL_TWELVE_HOURS:
                dateFormat = 'yyyy/MM/dd HH:00:00';
                break;
            case INTERVAL_ONE_DAY:
                dateFormat = 'yyyy/MM/dd 00:00:00';
                break;
            default:
                dateFormat = 'yyyy/MM/dd HH:mm:00';
                break;
        }

        return dateFormat;
    },

    getChartDateFormatHighChartsXAxis: (interval: number, aggregate: number = 0) => {
        let dateFormat = '';

        switch (interval) {
            case INTERVAL_SECOND:
                dateFormat = '%H:%M:%S';
                break;
            case INTERVAL_MINUTE:
            case INTERVAL_FIVE_MINUTES:
            case INTERVAL_TEN_MINUTES:
            case INTERVAL_FIFTEEN_MINUTES:
            case INTERVAL_THIRTY_MINUTES:
                if (aggregate < INTERVAL_MINUTE) {
                    dateFormat = '%H:%M:%S';
                } else {
                    dateFormat = '%H:%M';
                }
                break;
            case INTERVAL_ONE_HOUR:
            case INTERVAL_FOUR_HOURS:
            case INTERVAL_SIX_HOURS:
            case INTERVAL_TWELVE_HOURS:
                switch (aggregate) {
                    case INTERVAL_MINUTE:
                        dateFormat = '%H:%M:%S';
                        break;
                    case INTERVAL_FIVE_MINUTES:
                    case INTERVAL_TEN_MINUTES:
                    case INTERVAL_FIFTEEN_MINUTES:
                    case INTERVAL_THIRTY_MINUTES:
                        dateFormat = '%H:%M';
                        break;
                    default:
                        dateFormat = '%H:00';
                        break;
                }
                break;
            case INTERVAL_ONE_DAY:
                switch (aggregate) {
                    case INTERVAL_ONE_HOUR:
                        dateFormat = '%b %d %H:00';
                        break;
                    default:
                        dateFormat = '%b %d';
                        break;
                }
                break;
            default:
                dateFormat = '%m-%d %H:%M';
                break;
        }

        return dateFormat;
    },

    getChartAxisCategories: (from: Dayjs, to: Dayjs, interval: number) => {
        let chartCategories: string[] = [],
            dateFormat: string = '';

        switch (interval) {
            case INTERVAL_SECOND:
                dateFormat = 'YYYY-MM-DD HH:mm:ss';
                break;
            case INTERVAL_MINUTE:
            case INTERVAL_FIVE_MINUTES:
            case INTERVAL_TEN_MINUTES:
            case INTERVAL_FIFTEEN_MINUTES:
            case INTERVAL_THIRTY_MINUTES:
                dateFormat = 'YYYY-MM-DD HH:mm:00';
                break;
            case INTERVAL_ONE_HOUR:
            case INTERVAL_FOUR_HOURS:
            case INTERVAL_SIX_HOURS:
                dateFormat = 'YYYY-MM-DD HH:00:00';
                from = from.minute(0);
                break;
            case INTERVAL_TWELVE_HOURS:
                dateFormat = 'YYYY-MM-DD HH:00:00';
                from = from.minute(0);
                break;
            case INTERVAL_ONE_DAY:
                dateFormat = 'YYYY-MM-DD 00:00:00';
                from = from.hour(0).minute(0);
                break;
            default:
                dateFormat = 'YYYY-MM-DD HH:mm:00';
                break;
        }


        let current = from.clone();

        while (current.isBefore(to)) {
            chartCategories.push(current.format(dateFormat));
            current = current.add(interval, 'ms');
        }

        return chartCategories;
    },

    getFormattedChartAxisCategories: (from: Dayjs, to: Dayjs, periodOption: number, interval: number, includeLast: boolean = false) => {
        let chartCategories: string[] = [],
            dateFormat: string = '';

        switch (periodOption) {
            case PERIOD_CUSTOM:
                switch (interval) {
                    case INTERVAL_SECOND:
                        dateFormat = 'HH:mm:ss';
                        break;
                    case INTERVAL_MINUTE:
                    case INTERVAL_FIVE_MINUTES:
                    case INTERVAL_TEN_MINUTES:
                    case INTERVAL_FIFTEEN_MINUTES:
                    case INTERVAL_THIRTY_MINUTES:
                        dateFormat = 'HH:mm';
                        break;
                    case INTERVAL_ONE_HOUR:
                    case INTERVAL_FOUR_HOURS:
                    case INTERVAL_SIX_HOURS:
                    case INTERVAL_TWELVE_HOURS:
                        dateFormat = 'HH:00';
                        from = from.minute(0);
                        break;
                    case INTERVAL_ONE_DAY:
                        dateFormat = 'MMM DD';
                        from = from.hour(0).minute(0);
                        break;
                    default:
                        dateFormat = 'YYYY-MM-DD HH:mm';
                        break;
                }
                break;
            case PERIOD_MINUTE:
            case PERIOD_FIVE_MINUTES:
                dateFormat = 'HH:mm:ss';
                break;
            case PERIOD_TEN_MINUTES:
            case PERIOD_THIRTY_MINUTES:
            case PERIOD_ONE_HOUR:
            case PERIOD_SIX_HOURS:
                dateFormat = 'HH:mm';
                break;
            case PERIOD_TWELVE_HOURS:
            case PERIOD_TWENTY_FOUR_HOURS:
            case PERIOD_TODAY:
            case PERIOD_YESTERDAY:
            case PERIOD_DAY_BEFORE_YESTERDAY:
                dateFormat = 'HH:00';
                from = from.minute(0);
                break;
            case PERIOD_SEVEN_DAYS:
            case PERIOD_THIRTY_ONE_DAYS:
            case PERIOD_THIS_WEEK:
            case PERIOD_PREVIOUS_WEEK:
                dateFormat = 'MMM DD';
                from = from.hour(0).minute(0);
                break;
            default:
                dateFormat = 'YYYY-MM-DD';
                from = from.hour(0).minute(0);
                break;
        }
        ;

        // Apply the above defined date formatting.
        from = dayjs(from, dateFormat);
        to = dayjs(to, dateFormat);

        while (from.isBefore(to) || (includeLast && from.isSame(to))) {
            // Build the expected axis categories.
            chartCategories.push(from.format(dateFormat));

            // Increment the from value by the defined interval.
            from = from.add(interval, 'ms');
        }

        return chartCategories;
    },

    getInterval: (from: Dayjs, to: Dayjs, interval?: number) => {

        // If interval is already set, return it
        if (interval) {
            return interval
        }

        // If interval is auto calculate an interval
        const time = to.diff(from, 'ms');

        if (time <= INTERVAL_MINUTE) return INTERVAL_SECOND;
        if (time <= INTERVAL_TEN_MINUTES) return INTERVAL_15_SECONDS;
        if (time <= INTERVAL_THIRTY_MINUTES) return INTERVAL_MINUTE;
        if (time <= INTERVAL_ONE_HOUR) return INTERVAL_MINUTE;
        if (time <= INTERVAL_FOUR_HOURS) return INTERVAL_MINUTE;
        if (time <= INTERVAL_SIX_HOURS) return INTERVAL_FIFTEEN_MINUTES;
        if (time <= INTERVAL_TWELVE_HOURS) return INTERVAL_FIFTEEN_MINUTES;
        if (time <= INTERVAL_ONE_DAY) return INTERVAL_ONE_HOUR;
        if (time <= INTERVAL_ONE_WEEK) return INTERVAL_SIX_HOURS;
        return INTERVAL_ONE_DAY;
    },

    groupByInterval: (data: any[], interval: number): [number, number][] => {
        const groupedData: any = {};
        data.forEach((item: any) => {
            const date = dayjs(item.timeslice);
            const roundedDate = dayjs(Math.floor(date.valueOf() / interval) * interval);
            const roundedDateString = roundedDate.toISOString();

            if (!groupedData[roundedDateString]) {
                groupedData[roundedDateString] = [];
            }

            groupedData[roundedDateString].push(item);
        });

        return Object.entries(groupedData).map(([timeslice, items]) => ([
            dayjs(timeslice).valueOf(),
            // @ts-ignore
            items.length
        ]));
    },

    extractUniqueTimeslices: (alertsData: [number, number][], changesData: [number, number][], eventsData: [number, number][]) => {
        let uniqueTimeslices: any[] = [];

        function addUniqueTimeslices(data: any) {
            data.forEach((entry: string) => {
                if (!uniqueTimeslices.includes(entry[0])) {
                    uniqueTimeslices.push(entry[0]);
                }
            });
        }

        addUniqueTimeslices(alertsData);
        addUniqueTimeslices(changesData);
        addUniqueTimeslices(eventsData);

        uniqueTimeslices.sort((a, b) => a - b);

        return uniqueTimeslices;
    },

    populateMissingTimeslices: (data: [number, number][], uniqueTimeSlices: number[]) => {
        let timeSliceMap: any = {};
        data.forEach((entry: [number, number]) => {
            timeSliceMap[entry[0]] = entry[1];
        });

        uniqueTimeSlices.forEach(timeslice => {
            if (timeSliceMap[timeslice] === undefined) {
                data.push([timeslice, 0]);
            }
        });

        return data.sort((a, b) => a[0] - b[0]);
    },

    getChartTickInterval: (fromDate: Dayjs, toDate: Dayjs, periodOption: number, interval: number) => {
        let chartCategories: string[] = [],
            dateFormat: string = '';

        switch (periodOption) {
            case PERIOD_CUSTOM:
                switch (interval) {
                    case INTERVAL_SECOND:
                        dateFormat = 'HH:mm:ss';
                        break;
                    case INTERVAL_MINUTE:
                    case INTERVAL_FIVE_MINUTES:
                    case INTERVAL_TEN_MINUTES:
                    case INTERVAL_FIFTEEN_MINUTES:
                        dateFormat = 'HH:mm';
                        break;
                    case INTERVAL_THIRTY_MINUTES:
                    case INTERVAL_ONE_HOUR:
                    case INTERVAL_FOUR_HOURS:
                    case INTERVAL_SIX_HOURS:
                    case INTERVAL_TWELVE_HOURS:
                        dateFormat = 'HH:mm';
                        break;
                    case INTERVAL_ONE_DAY:
                        dateFormat = 'MMM DD';
                        break;
                    default:
                        dateFormat = 'YYYY-MM-DD HH:mm';
                        break;
                }
                break;
            case PERIOD_MINUTE:
            case PERIOD_FIVE_MINUTES:
                dateFormat = 'HH:mm:ss';
                break;
            case PERIOD_TEN_MINUTES:
                dateFormat = 'HH:mm';
                break;
            case PERIOD_THIRTY_MINUTES:
            case PERIOD_ONE_HOUR:
            case PERIOD_SIX_HOURS:
            case PERIOD_TWELVE_HOURS:
            case PERIOD_TWENTY_FOUR_HOURS:
            case PERIOD_TODAY:
            case PERIOD_YESTERDAY:
            case PERIOD_DAY_BEFORE_YESTERDAY:
                dateFormat = 'HH:mm';
                break;
            case PERIOD_SEVEN_DAYS:
            case PERIOD_THIRTY_ONE_DAYS:
            case PERIOD_THIS_WEEK:
            case PERIOD_PREVIOUS_WEEK:
                dateFormat = 'MMM DD';
                break;
            default:
                dateFormat = 'YYYY-MM-DD';
                break;
        }

        let from = dayjs(fromDate, dateFormat),
            to = dayjs(toDate, dateFormat);

        while (from.isBefore(to)) {

            // Build the expected axis categories.
            chartCategories.push(from.format(dateFormat));

            // Increment the from value by the defined interval.
            from = from.add(interval, 'ms');
        }

        if (chartCategories.length > 100) {
            return 10;
        }

        if (chartCategories.length === 7) {
            return 1;
        }

        return 5;
    },

    getTimeInEnglish(milliseconds: number) {
        if (!milliseconds) {
            return ''
        }
        milliseconds = parseFloat(milliseconds.toString())

        // if (milliseconds > 0 && milliseconds < 1) {
        //     return `${(milliseconds.toFixed(5))}ms`;
        // }

        if (milliseconds < 0) {
            return `${this.getFormattedNumber(milliseconds)}ms`;
        }

        if (milliseconds < 1000) {
            return `${milliseconds.toFixed(2)}ms`;
        }

        let description = '',
            seconds = milliseconds / 1000,
            days = Math.floor(seconds / (3600 * 24));

        seconds -= days * 3600 * 24;

        let hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;

        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        let ms = Math.floor(seconds / 60);
        ms -= seconds * 1000;

        if (days > 0) {
            description += days + 'd ';
        }

        if (hours > 0) {
            description += hours + 'h ';
        }

        if (minutes > 0) {
            description += minutes + 'm ';
        }

        if (seconds > 0) {

            if (Math.round(seconds) === 60) {

                // It's a minute.
                description = description.replace(minutes + 'm ', (minutes + 1) + 'm ');

                // if there is no description yet set i to 1 min
                if (description === '') {
                    description = '1m';
                }
            } else {

                // if there are only seconds, add 2 decimals
                if (hours < 1 && minutes < 1) {
                    description += seconds.toFixed(2) + 's ';
                } else {
                    // Keep the seconds.
                    description += Math.round(seconds) + 's ';
                }
            }
        }

        if (ms > 0) {
            description += Math.round(ms) + 'ms';
        }

        if (description === '') {
            description = '0ms';
        }

        return description.trim();
    },

    getInstanceType(instance: InstanceTarget) {

        let instanceType = '';

        switch (instance.type) {
            case INSTANCE_TYPE_SQLSERVER:
                instanceType = 'SQL Server';
                break;
            case INSTANCE_TYPE_MYSQL:
                instanceType = 'MySQL';
                break;
            case INSTANCE_TYPE_ORACLE:
                instanceType = 'Oracle';
                break;
            case INSTANCE_TYPE_POSTGRES:
                instanceType = 'Postgres';
                break;
            case INSTANCE_TYPE_COCKROACHDB:
                instanceType = 'CockroachDB';
                break;
            case INSTANCE_TYPE_DB2:
                instanceType = 'db2';
                break;
            case INSTANCE_TYPE_INFORMIX:
                instanceType = 'Informix';
                break;
            case INSTANCE_TYPE_SAPHANA:
                instanceType = 'SAP HANA';
                break;
            default:
                instanceType = 'Unknown';
                break;
        }

        if (instance.version) {
            instanceType = `${instanceType} v${instance.version}`;
        }

        return instanceType;
    },

    getHostType(host: HostTarget) {

        let hostType = '';

        switch (host.type) {
            case HOST_TYPE_LINUX:
                hostType = 'Linux';
                break;
            case HOST_TYPE_WINDOWS:
                hostType = 'Windows';
                break;
            case HOST_TYPE_AWSRDS:
                hostType = 'AWS RDS';
                break;
            default:
                hostType = 'Unknown';
                break;
        }

        return hostType;
    },

    getPeakValue(data: (number | null)[]) {
        let values = data.map(function (value) {
            return value === null ? 0 : value;
        });

        return Math.max(...values);
    },

    getFormattedBadgeCount(count: number) {

        if (count > Number(process.env.REACT_APP_API_LIMIT) - 1) {
            return `${(Number(process.env.REACT_APP_API_LIMIT) - 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}+`
        }

        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getFormattedNumber(count: number | null, metric: string = '') {
        if (count === null) {
            return 0;
        }
        if (metric === 'cpu_percent') {
            return `${count.toFixed(2)?.toString()}`
        }
        return Math.round(count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getFormattedPeriodHeadingFromDate(dateFrom: Date | Dayjs, dateTo: Date | Dayjs) {
        let from: Dayjs = dayjs(dateFrom),
            to: Dayjs = dayjs(dateTo);

        if (from.isSame(to, 'year')) {
            // Same year.
            if (from.isSame(to, 'month')) {
                // Same month.
                if (from.isSame(to, 'day')) {
                    // Same day.
                    return `${from.format('Do MMM HH:mm')} to ${to.format('HH:mm')}`;
                } else {
                    // Different day.
                    return `${from.format('Do HH:mm')} to ${to.format('Do MMM HH:mm')}`;
                }
            } else {
                // Different month.
                return `${from.format('Do MMM HH:mm')} to ${to.format('Do MMM HH:mm')}`;
            }
        } else {
            // Different year.
            return `${from.format('Do MMM YYYY HH:mm')} to ${to.format('Do MMM YYYY HH:mm')}`;
        }
    },

    getFormattedPeriodHeading(period: Period, reportPeriod: number) {
        const dateFormat: string = 'YYYY-MM-DD+HH:mm:00';
        let from: Dayjs = dayjs(period.api.current.from, dateFormat),
            to: Dayjs = dayjs(period.api.current.to, dateFormat);

        if (reportPeriod === REPORT_PERIOD_PREVIOUS) {
            from = dayjs(period.api.previous.from, dateFormat);
            to = dayjs(period.api.previous.to, dateFormat);
        } else if (reportPeriod === REPORT_PERIOD_INTERIM) {
            from = dayjs(period.api.previous.to, dateFormat);
            to = dayjs(period.api.current.from, dateFormat);
        }

        return this.getFormattedPeriodHeadingFromDate(from, to)
    },

    getFormattedPeriodSubheading(period: Period, reportPeriod: number) {

        const dateFormat: string = 'YYYY-MM-DD+HH:mm:00';
        let from: Dayjs = dayjs(period.api.current.from, dateFormat),
            to: Dayjs = dayjs(period.api.current.to, dateFormat);

        if (reportPeriod === REPORT_PERIOD_PREVIOUS) {
            from = dayjs(period.api.previous.from, dateFormat);
            to = dayjs(period.api.previous.to, dateFormat);
        } else if (reportPeriod === REPORT_PERIOD_INTERIM) {
            from = dayjs(period.api.previous.to, dateFormat);
            to = dayjs(period.api.current.from, dateFormat);
        }

        if (from.isSame(to, 'year')) {
            // Same year.
            if (from.isSame(to, 'month')) {
                // Same month.
                if (from.isSame(to, 'day')) {
                    // Same day.
                    return `${from.format('Do MMM')}`;
                } else {
                    // Different day.
                    return `${from.format('Do')} to ${to.format('Do MMM')}`;
                }
            } else {
                // Different month.
                return `${from.format('Do MMM')} to ${to.format('Do MMM')}`;
            }
        } else {
            // Different year.
            return `${from.format('Do MMM YYYY')} to ${to.format('Do MMM YYYY')}`;
        }
    },

    getChartPlotLines(period: Period | null, combinedChanges: CombinedChanges[], chartCategories: string[], interval?: number) {
        let plotLines: ChartPlotLine[] = [],
            dateFormat: string = '';
        switch (period?.api?.interval || interval) {
            case INTERVAL_SECOND:

                dateFormat = 'YYYY-MM-DD HH:mm:ss';
                break;

            case INTERVAL_ONE_DAY:

                dateFormat = 'YYYY-MM-DD 00:00:00';
                break;

            case INTERVAL_ONE_HOUR:

                dateFormat = 'YYYY-MM-DD HH:00:00';
                break;

            default:

                dateFormat = 'YYYY-MM-DD HH:mm:00';
                break;
        }

        const changes = combinedChanges.filter(item => item.change);
        const events = combinedChanges.filter(item => item.custom);
        const alerts = combinedChanges.filter(item => item.alert && item.instanceId);
        const combinedEvents = [...changes, ...events, ...alerts]

        let uniqueTimeslices = combinedEvents.map(change => dayjs(change.timeslice).format(dateFormat)).filter((x, i, a) => a.indexOf(x) === i);

        const changesByTimeslice: { [key: string]: CombinedChanges[] } = {}
        const isUtc = period?.api?.timezone === 'UTC'

        changes.forEach(change => {
            const ts = dayjs(change.timeslice).format(dateFormat)
            changesByTimeslice[ts] = changesByTimeslice[ts] || []
            changesByTimeslice[ts].push(change)
        });

        const eventsByTimeslice: { [key: string]: CombinedChanges[] } = {}
        events.forEach(event => {
            const ts = dayjs(event.timeslice).format(dateFormat)
            eventsByTimeslice[ts] = eventsByTimeslice[ts] || []
            eventsByTimeslice[ts].push(event)
        });

        const alertsByTimeslice: { [key: string]: CombinedChanges[] } = {}
        alerts.forEach(alert => {
            const ts = dayjs(alert.timeslice).format(dateFormat)
            alertsByTimeslice[ts] = alertsByTimeslice[ts] || []
            alertsByTimeslice[ts].push(alert)
        });


        for (let index = 0; index < uniqueTimeslices.length; index++) {
            // let totalAlertChanges = 0;
            const timeslice = isUtc ? dayjs.utc(uniqueTimeslices[index]) : dayjs(uniqueTimeslices[index]);
            // Get total combined change count for the current timeslice.
            let timesliceChanges = changesByTimeslice[timeslice.format(dateFormat)] || []
            let timesliceEvents = eventsByTimeslice[timeslice.format(dateFormat)] || []
            let timesliceAlerts = alertsByTimeslice[timeslice.format(dateFormat)] || []

            // Get the chart position.
            let value = chartCategories.findIndex(dateTime => dateTime === timeslice.format(dateFormat));
            // Build the plotlines.
            if (value > -1) {
                plotLines.push({
                    value: value,
                    timeValue: timeslice.valueOf(),
                    color: '#666',
                    width: 1,
                    dashStyle: 'dot',
                    label: {
                        useHTML: true,
                        align: 'left',
                        text: combinedIcons(timesliceEvents, timesliceChanges, timesliceAlerts),
                        x: -8,
                        y: 15,
                        rotation: 0,
                        style: {
                            color: '#666',
                            fontWeight: 'normal'
                        }
                    },
                    zIndex: 1
                });
            }
        }
        return plotLines;
    },

    getChartPlotLinesForExecutionPlans(period: Period, executionPlans: ExecutionPlan[], chartCategories: string[]) {
        let plotLines: ChartPlotLine[] = [],
            dateFormat: string = '';

        switch (period.api.interval) {
            case INTERVAL_SECOND:

                dateFormat = 'YYYY-MM-DD HH:mm:ss';
                break;

            default:

                dateFormat = 'YYYY-MM-DD HH:mm:00';
                break;
        }

        let uniqueTimeslices = executionPlans.map(plan => dayjs(plan.earliest.toString().substring(0, 18)).format(dateFormat)).filter((x, i, a) => a.indexOf(x) === i);

        for (let index = 0; index < uniqueTimeslices.length; index++) {

            let timeslice = dayjs(uniqueTimeslices[index]);

            // Get total combined change count for the current timeslice.
            let timesliceChanges = executionPlans.filter(plan => timeslice.isSame(dayjs(plan.earliest).format(dateFormat)));

            // Get the chart position.
            let value = chartCategories.findIndex(dateTime => dateTime === timeslice.format(dateFormat));

            // Build the plotlines.
            if (value > -1) {

                let totalPlans: string = timesliceChanges.length.toString();

                if (totalPlans === '1') {
                    totalPlans = '';
                }

                plotLines.push({
                    value,
                    timeValue: timeslice.valueOf(),
                    color: '#666',
                    width: 1,
                    dashStyle: 'dot',
                    label: {
                        useHTML: true,
                        text: `<i class="fal fa-ruler-triangle fa-fw mr-1"></i><sup class="text-secondary">${totalPlans}</sup>`,
                        x: -7,
                        y: 32,
                        rotation: 0,
                        style: {
                            color: '#666',
                            fontWeight: 'normal'
                        }
                    },
                    zIndex: 1
                });
            }
        }

        return plotLines;
    },

    getChartPlotbands(combinedChanges: CombinedChanges[], chartCategories: string[]) {
        let plotBands: ChartPlotBand[] = [];

        const
            dateFormat: string = 'YYYY-MM-DD HH:mm:00',
            periodEvents = combinedChanges.filter(item => item.event !== undefined && item.event.endDateTime !== null),
            chartFm = dayjs(chartCategories[0], dateFormat),
            chartTo = dayjs(chartCategories[chartCategories.length], dateFormat);

        for (let index = 0; index < periodEvents.length; index++) {

            const event = periodEvents[index].event;

            if (event !== undefined) {

                let from: number = 0,
                    to: number = chartCategories.length;

                const
                    eventFm = dayjs(event.startDateTime),
                    eventTo = dayjs(event.endDateTime);

                // Work out the chart start position.
                if (eventFm.isAfter(chartFm)) {

                    // Event starts during the chart period.
                    for (let index = 0; index < chartCategories.length; index++) {
                        if (eventFm.format(dateFormat) === chartCategories[index]) {
                            from = index;
                        }
                    }
                }

                // Work out the chart end position.
                if (eventTo.isBefore(chartTo)) {

                    // Event starts during the chart period.
                    for (let index = 0; index < chartCategories.length; index++) {
                        if (eventTo.format(dateFormat) === chartCategories[index]) {
                            to = index;
                        }
                    }
                }

                let color = 'rgba(204, 204, 204, .5)',
                    text = `<sup class="text-dark">${event.title}</sup>`;

                if (event.htmlIconCode !== undefined && event.htmlIconCode.length > 0) {

                    // Note: Highcharts doesn't support embedded icons as per below for plotbands, but it works for plotlines - we'll still do it, as it may come to life in a future update.
                    text = `<i class="${event.htmlIconCode} mr-1"></i>${text}`;
                }

                // Set the plot band text colour.
                switch (event.colourCode) {
                    case '#007bff':
                        // Blue.
                        color = 'rgba(0, 123, 255, .25)';
                        break;
                    case '#6c757d':
                        // Grey.
                        color = 'rgba(108, 117, 125, .25)';
                        break;
                    case '#28a745':
                        // Green.
                        color = 'rgba(40, 167, 69, .25)';
                        break;
                    case '#dc3545':
                        // Red.
                        color = 'rgba(220, 53, 69, .25)';
                        break;
                    case '#ffc107':
                        // Yellow.
                        color = 'rgba(255, 193, 7, .25)';
                        break;
                    case '#17a2b8':
                        // Teal.
                        color = 'rgba(23, 162, 184, .25)';
                        break;
                    default:
                        break;
                }

                let y = 50;

                if (index % 2) {

                    // To help reduce overlapping, move the title for odd events further down the chart.
                    y = 25
                }

                // Add the plot band to the object array.
                plotBands.push({
                    from,
                    to,
                    color,
                    width: 0,
                    label: {
                        text: `<sup class="text-dark">${event.title}</sup>`,
                        y,
                        rotation: 0,
                        style: {
                            color: '#000',
                            fontWeight: 'normal'
                        }
                    }
                });
            }
        }

        return plotBands;
    },

    getInstanaDatabaseIntegrations: async(instanaIntegrations: InstanaIntegration[], databases: Database[], instance: InstanceTarget) => {

        let serviceMatches: InstanaService[] = [];

        if (instanaIntegrations.length === 0 || databases.length === 0) {

            // No Instana integrations or databases defined, so exit method.
            return serviceMatches;
        }

        for (let index = 0; index < instanaIntegrations.length; index++) {

            // Get the current Instana integration.
            const
                instanaIntegration = instanaIntegrations[index],
                axiosRequestConfig = {
                    headers: {
                        'authorization': `apiToken ${instanaIntegration.apikey}`
                    }
                };

            // Loop through instance databases.
            for (let databaseIndex = 0; databaseIndex < databases.length; databaseIndex++) {

                // Get the current database name.
                const databaseName = databases[databaseIndex].database;

                if (databaseName !== undefined) {

                    // Check Instana... with the name filter applied, we're only going to check one page for matches and take the first response data item.
                    const instanaServices: any = await axios.get(`${instanaIntegration.host}/api/application-monitoring/applications/services?page=1&nameFilter=${databaseName}`, axiosRequestConfig);

                    if (instanaServices.status !== 200) {

                        // Instana request was not successful.
                        console.error('Failed to retrieve a success response from Instana monitored services API.')

                        return serviceMatches;
                    }

                    // Make sure the items array exists.
                    instanaServices.data.items ||= [];

                    for (const item of instanaServices.data.items) {
                        if (item.types.includes('DATABASE') &&
                            item.technologies.includes
                            (instanaTechnology(instance.type))) {
                            let instanaServiceId = item.id,
                                instanaServiceTechnologies = item.technologies,
                                instanaServiceUrl = `${instanaIntegration.host}#/service;serviceId=${item.id}/summary`;

                            serviceMatches.push({
                                databaseName,
                                instanaServiceId,
                                instanaServiceTechnologies,
                                instanaServiceUrl
                            });
                        }
                    }
                }
            }
        }

        return serviceMatches;
    },

    getQueryString(period: Period, from?: string, to?: string, interval?: number) {

        if (from === undefined) {
            from = period.api.current.from;
        }

        if (to === undefined) {
            to = period.api.current.to;
        }
        if (interval === undefined) {
            interval = period.api.interval;
        }
        const intervalParam = interval ? `&interval=${interval}` : ''

        return `?fm=${from}&to=${to}&tz=${period.api.timezone}${intervalParam}`;
    },

    getQueryStringWithInterval(timeRangeContext: TimeRangeContextType, from?: string, to?: string) {

        if (from === undefined) {
            from = timeRangeContext.timeRange.from.format('YYYY-MM-DD+HH:mm:ss')
        }

        if (to === undefined) {
            to = timeRangeContext.timeRange.to.format('YYYY-MM-DD+HH:mm:ss')
        }
        let interval = ''
        if (timeRangeContext.timeRange.interval) {
            interval = `&interval=${timeRangeContext.timeRange.interval}`
        }
        return `?fm=${from}&to=${to}&tz=${timeRangeContext.timeRange.timezone}${interval}`;
    },

    getQueryStringNoInterval(timeRangeContext: TimeRangeContextType, from?: string, to?: string) {

        if (from === undefined) {
            from = timeRangeContext.timeRange.from.format('YYYY-MM-DD+HH:mm:ss')
        }

        if (to === undefined) {
            to = timeRangeContext.timeRange.to.format('YYYY-MM-DD+HH:mm:ss')
        }

        return `?fm=${from}&to=${to}&tz=${timeRangeContext.timeRange.timezone}`;
    },

    getPercentageDifference(firstNumber: number | null, secondNumber: number | null): null | number {

        if (firstNumber === null || firstNumber === 0) {

            // Percentages cannot be calculated when the first number is empty or zero.
            return null;
        }

        if (secondNumber === null) {

            secondNumber = 0;
        }

        const difference: number = (firstNumber - secondNumber);

        const percentage: number = Math.round((difference / secondNumber) * 100);

        return percentage;
    },

    getAverageFromArray(array: number[]) {

        if (array.length === 0) {
            return 0;
        }

        const sum = array.reduce((a, b) => a + b, 0);

        return sum / array.length;
    },

    getUniqueArrayValues(firstArray: any[], secondArray: any[]) {

        let uniqueArray = firstArray.concat(secondArray);

        for (let i = 0; i < uniqueArray.length; ++i) {
            for (let j = i + 1; j < uniqueArray.length; ++j) {
                if (uniqueArray[i] === uniqueArray[j]) {
                    uniqueArray.splice(j--, 1);
                }
            }
        }

        return uniqueArray;
    },

    getUniqueArray(firstArray: any[], secondArray: any[], uniqueProperty: string) {
        return firstArray.filter(aa => !secondArray.find(bb => aa[uniqueProperty] === bb[uniqueProperty])).concat(secondArray);
    },

    async createInstanceEvent(eventTypes: EventType[], id: number, eventTitle: string, eventDescription: string) {

        const title: string = 'DBmarlin Configuration Change';

        // Add a custom event type for monitored instance changes.
        let filteredEventTypes = eventTypes.filter((eventType: EventType) => eventType.title === title);

        if (filteredEventTypes.length === 0) {

            // Expected event type is missing, get the latest event types via another API call.
            let updatedEventTypes: any = await api.get('event/type');

            if (updatedEventTypes.status === 200) {

                // Filter for the required event type (configuration change).
                filteredEventTypes = updatedEventTypes.data.filter((eventType: EventType) => eventType.title === title);

                if (filteredEventTypes.length === 0) {

                    // Expected event is missing, so create it.
                    await api.post('event/type', [{
                        eventTypeId: 0,
                        title,
                        htmlIconCode: 'fal fa-cog'
                    }]);

                    // Get the event types again - the APIs do not return the just created object ID.
                    updatedEventTypes = await api.get('event/type');
                }

                if (updatedEventTypes.status === 200) {

                    filteredEventTypes = updatedEventTypes.data.filter((eventType: EventType) => eventType.title === title);
                }

                /*
                // Todo: convert this component to a function, and get the event types context updated (there is not enough time right now).
                // Sort the array order and add the new object to the context.
                setEventTypes(updatedEventTypes.concat(data).sort(function (a: EventType, b: EventType) {
                    if (a.title < b.title) { return -1; }
                    if (a.title > b.title) { return 1; }
                    return 0;
                }));
                */
            }
        }

        if (filteredEventTypes.length > 0) {

            // Create event for creation of database instance monitoring.
            await api.post('event', [{
                eventId: 0,
                databaseTargetId: id,
                eventTypeId: filteredEventTypes[0].eventTypeId,
                startDateTime: new Date(),
                endDateTime: null,
                colourCode: '#6c757d',
                title: eventTitle,
                description: eventDescription,
                detailsUrl: ''
            }]);

        } else {

            // Not a critical error as such, so just add a warning to the console.
            console.warn('"DBmarlin Configuration Change" event type missing - unable to create event type and event for the requested instance action');
        }
    },

    updateFilterOptions(filterType: FilterType, filter: string, existingOptions: FilterOption[]): FilterOption[] {

        if (filter === '-') {
            return existingOptions;
        }

        let options: FilterOption[] = existingOptions;

        let filteredOption: FilterOption[] = options.filter(option => option.filterType === filterType);

        if (filteredOption.length > 0 &&
            !filteredOption[0].filters.includes(filter)) {

            // Add the new filter option.
            filteredOption[0].filters.push(filter);

            const updatedFilterOptions = options.map(option =>
                option.filterType === filterType
                    ? { ...option, filters: filteredOption[0].filters }
                    : option
            );

            // Return the updated filters.
            return updatedFilterOptions;
        }

        return existingOptions;
    },

    getChangeIcon(val1: number, val2: number) {
        if (!val1 || !val2) {
            return 'no-comparison';
        }

        if (val1 === val2) {
            return "fas fa-equals no-change"
        }

        return val1 < val2 ? "fas fa-long-arrow-down changed-up" : "fas fa-long-arrow-down changed-down"
    },

    getNumberFormatType(metric: string, value: number) {
        if ((TIME_METRICS.includes(metric) || metric.includes('_milliseconds')) && (metric !== 'cpu_time')) {
            return Helper.getTimeInEnglish(value)
        }
        if (metric.includes('_percent')) {
            return `${parseFloat(value.toString()).toFixed(2)}%`
        }
        return Helper.getFormattedNumber(value)
    },

    getMetricFormatString(metric: string, useParentheses = false) {
        if ((TIME_METRICS.includes(metric) || metric.includes('_milliseconds')) && (metric !== 'cpu_time')) {
            return useParentheses ? '(ms)' : 'ms'
        }
        if (metric.includes('_percent')) {
            return useParentheses ? '(%)' : '%'
        }
        return ''
    },

    getToolTip(val1: number, val2: number, metric: string) {
        if ((val1 === val2) || (!val1 || !val2)) {
            return null
        }
        const value = val2 - val1
        const isTime = ((TIME_METRICS.includes(metric) || metric.includes('_milliseconds')) && (metric !== 'cpu_time'))
        const sign = value > 0 ? '+' : isTime ? '-' : ''
        return isTime ? `${sign}${Helper.getTimeInEnglish(Math.abs(value))}` : `${sign}${Helper.getFormattedNumber(value)}`
    },

    addAverageAndColor(time: string, count: string, list: any[], toAddColor: number) {
        const listWithAverage: any[] = []

        list.forEach((item, index) => {
            const noZeroCountValue = item[count] || 1
            item.average_duration = item[time] / noZeroCountValue
            item.color = index < toAddColor ? TARGET_COLOUR_CODES[index] : '#FFFFFF'
            listWithAverage.push(item)
        })
        return listWithAverage
    },

    isComparisonSupported(period: TimeRangeContextType) {
        const timePeriod = period.timeRange
        if (timePeriod) {
            const diff = timePeriod.to.diff(timePeriod.from, 'ms');
            const previousFromDiff = timePeriod.previousFrom.isBefore(dayjs().subtract(31, 'day'));
            // Over week period
            if (diff > 604800000 || previousFromDiff) {
                return false
            }
        }

        return true
    },

    getInstanceIdByName(name: string, list: InstanceTarget[]) {

        // check if instance param is id or name
        if (!Number(name)) {
            const findInstance = list.find(instance => instance.name === name)
            return Number(findInstance?.id)
        }
        return Number(name)
    },

    aggregateIsValid(aggregateValue: number, timeRangeContext: TimeRangeContextType) {
        const differenceInMilliseconds = timeRangeContext.timeRange.to.diff(timeRangeContext.timeRange.from, 'milliseconds');
        const getTimePeriod = determineTimeUnit(differenceInMilliseconds)
        const isValid = periodToValidIntervals[getTimePeriod].includes(aggregateValue)
        if (!isValid) {
            window.localStorage.setItem('dbmar-interval', '0')
        }
        return isValid
    },

    filterTagsByTagName(tags: any[]) {
        const uniqueTags: any = {};


        tags?.forEach(item => {
            if (!uniqueTags[item.tagname]) {
                uniqueTags[item.tagname] = [item.tagvalue];
            } else {
                if (!uniqueTags[item.tagname].includes(item.tagvalue)) {
                    uniqueTags[item.tagname].push(item.tagvalue);
                }
            }
        });
        const result: { tagname: string; tagvalues: string[] }[] = Object.keys(uniqueTags).map(tagname => ({
            tagname,
            tagvalues: uniqueTags[tagname]
        }));
        return result

    },

    filterArraysByKey(a: any[], b: any[], key: string) {
        const namesInB = new Set(b.map(item => item[key]));
        return a.filter(item => !namesInB.has(item[key]));
    },

    checkForTwoWithSameKeyValue(key: string, data: any[]) {
        const nameCount: any = {};
        let isDuplicate = false
        for (const item of data) {
            if (nameCount[item[key]]) {
                nameCount[item[key]]++;
                if (nameCount[item[key]] === 2) {
                    isDuplicate = true
                }
            } else {
                nameCount[item[key]] = 1;
            }
        }
        return isDuplicate
    },

    getUrlParamsForTags(tags: any[]) {
        const tagsParam = tags.map(tag => `${tag.tagName}:${tag.tagValues.join(',')}`).join(';');
        return tagsParam
    },

    fillChartDataWithZeroValues(data: { [key: string]: { values: any[], orderIndex: number } }, metric: string) {

        // Find the complete set of timeslices
        const allTimeslices = new Set();
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const innerObj = data[key];
                if (innerObj.values && Array.isArray(innerObj.values)) {
                    innerObj.values.forEach((dataPoint: { timeslice: unknown; }) => {
                        allTimeslices.add(dataPoint.timeslice);
                    });
                }
            }
        }
        // sort timeslices
        const allTimeslicesArray = Array.from(allTimeslices).sort();

        // Fill in missing timeslices with a value of 0 for each object
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const innerObj: any = data[key];
                if (innerObj.values && Array.isArray(innerObj.values)) {
                    const timesliceSet: any = new Set(innerObj.values.map((dataPoint: {
                        timeslice: any;
                    }) => dataPoint.timeslice));
                    allTimeslicesArray.forEach(timeslice => {
                        if (!timesliceSet.has(timeslice)) {
                            innerObj.values.push({ timeslice: timeslice, [metric]: 0 });
                        }
                    });
                    innerObj.values.sort((a: any, b: any) => (a.timeslice > b.timeslice ? 1 : -1)); // Sort by timeslice
                }
            }
        }
        return data
    }
};

export default Helper;
