import React from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, Redirect } from 'react-router-dom';

// Third-parties.
import Helmet from 'react-helmet';

// Types.
import Period from '../../types/Period';
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import EventType from '../../types/instance/EventType';
import InstanaIntegration from '../../types/integration/InstanaIntegration';
import Licence from '../../types/Licence';

// Admin Views.
import Instances from './instances/Instances';
import Hosts from './hosts/Hosts';
import AlertRules from './alert-rules';
import AlertActions from './alert-actions';
import EventTypes from './eventTypes/EventTypes';
import Instana from './integrations/instana/Instana';
import EmailIntegration from './integrations/email';
import Integrations from './integrations/';
import Licences from './licences/Licences';
import Filters from './filters/Filters';
import Breadcrumb from "../../container/breadcrumb";
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ExtendedStatistics from "./sql-statistics/";
import ConditionalRender from "../../helpers/ConditionalRender";
import NewRelic from "./integrations/new-relic";
import PagerDuty from "./integrations/pager-duty";
import Slack from "./integrations/slack";

function Admin(props: { period: Period, instances: InstanceTarget[], hosts: HostTarget[], eventTypes: EventType[], instanaIntegrations: InstanaIntegration[], licences: Licence[], toggleMenu: Function, applyPeriod: Function } ) {
  return (
    <Switch>
        <Route exact path="/admin" render={() => 
            <React.Fragment>
                <div id="content">
                    <Helmet>
                        <title>Settings - DBmarlin</title>
                        <meta name="description" content="Settings and administration options within DBmarlin" />
                    </Helmet>

                    <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                        <Breadcrumb heading="Settings">
                            <Link to="/admin">Settings</Link>
                            <PeriodBreadcrumb />
                        </Breadcrumb>
                        <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={() => {}} />
                    </div>

                    <div className="loader">
                        <ConditionalRender if={false}>
                            <div className="bar"></div>
                        </ConditionalRender>
                    </div>

                    <div className="row row-cols-1">
                        <div className="col h-100">
                            <div className="card">
                                <div className="card-header">
                                    <i className="fal fa-cog fa-fw" aria-hidden="true" />
                                    Settings
                                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings" />
                                </div>
                                <div id="collapseSettings" className="card-body collapse show">
                                    <p>Please select an option from below:</p>
                                    <ul>
                                        <li><Link to="/admin/instances">Manage Database Instances</Link></li>
                                        <li><Link to="/admin/hosts">Manage Hosts</Link></li>
                                        <li><Link to="/admin/database-extended-statistics">Manage SQL Statistics</Link></li>
                                        <li><Link to="/admin/alert-rules">Manage Alert Rules</Link></li>
                                        <li><Link to="/admin/filters">Manage Snapshots</Link></li>
                                        <li><Link to="/admin/integrations/">Manage Integrations</Link></li>
                                        <li><Link to="/admin/event-types">Manage Event Types</Link></li>
                                        <li><Link to="/admin/licences">Manage Licences</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        } />
        <Route path="/admin/instances">
            <Instances key={props.period.timestamp} period={props.period} instances={props.instances} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/hosts">
            <Hosts key={props.period.timestamp} period={props.period} hosts={props.hosts} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/alert-actions">
            <AlertActions key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/database-extended-statistics">
            <ExtendedStatistics key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} />
        </Route>
        <Route path="/admin/alert-rules">
            <AlertRules key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu}
                        instances={props.instances} hosts={props.hosts} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/filters">
            <Filters key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/event-types">
            <EventTypes key={props.period.timestamp} period={props.period} eventTypes={props.eventTypes} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations/instana">
            <Instana key={props.period.timestamp} period={props.period} instanaIntegrations={props.instanaIntegrations} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations/email">
            <EmailIntegration key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations/new-relic">
            <NewRelic key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations/pager-duty">
            <PagerDuty key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations/slack">
            <Slack key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Route path="/admin/integrations">
            <Integrations key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>
        <Route path="/admin/licences">
            <Licences key={props.period.timestamp} period={props.period} licences={props.licences} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod} />
        </Route>

        <Redirect to="/not-found" />
  </Switch>
  );
}

Admin.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Admin;
