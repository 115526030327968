import { useContext, useEffect, useMemo, useState } from "react";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

// Components
import Databases from "../../../component/instance/Tabs/Databases";
import Sessions from "../../../component/instance/Tabs/Sessions";
import Clients from "../../../component/instance/Tabs/Clients";
import Users from "../../../component/instance/Tabs/Users";
import Programs from "../../../component/instance/Tabs/Programs";
import Changes from "../../../component/instance/Tabs/Changes";
import StatementsTable from "../../../component/instance/table/StatementsTable";
import ConditionalRender from "../../../helpers/ConditionalRender";
import PieChart from "../../../component/PieChart";
import Waits from "../../../component/instance/Tabs/Waits";
import GroupedStatementsTable from "../../../component/instance/table/GroupedStatementsTable";

// Types
import ChartOption from "../../../types/instance/ChartOption";
import Period from "../../../types/Period";
import PieChartSeries from "../../../types/PieChartSeries";
import InstanceTarget from "../../../types/instance/InstanceTarget";
import { FilterType } from "../../../typescript/Enums";
import Wait from "../../../types/instance/Wait";
import CombinedChanges from "../../../types/instance/CombinedChanges";
import EventsDataTable from "../../../types/instance/tables/EventsDataTable";
import Statement from "../../../types/instance/Statement";
import StatementsDataTable from "../../../types/instance/tables/StatementsDataTable";
import GroupedStatement from "../../../types/instance/GroupedStatement";
import GroupedStatementsDataTable from "../../../types/instance/tables/GroupedStatementsDataTable";

// Utils
import Helper from "../../../helpers/Helper";
import { getAPIString, getStatementTypeFromURL } from "../../instances/tabs/utils";

// Constants
import {
    CHART_COLOURS_STATEMENTS,
    CHART_COLOURS_WAITS,
    INSTANCE_TYPE_ORACLE,
    INSTANCE_TYPE_SQLSERVER
} from "../../../component/Constants";
import Batch from "../../../types/instance/Batch";
import { ActivityBatchGroup } from "../../../types/instance/Activity";
import api from "../../../api/Base";

function StatementPieChartAndTabs(props: {
    instance: InstanceTarget,
    filterParameters: string,
    period: Period,
    statementId?: string,
    batchStatementId?: string,
    setFilterOptions: Function,
    statementTime: number,
    batchId?: string,
    statementCombinedChanges: CombinedChanges[],
    statementEventsDataTable: EventsDataTable[]
}) {
    const {
        instance,
        period,
        statementId,
        setFilterOptions,
        statementTime,
        batchId,
        batchStatementId,
        statementCombinedChanges,
        filterParameters,
        statementEventsDataTable
    } = props
    const chartSeriesCeiling = Number(process.env.REACT_APP_CHART_SERIES_CEILING);
    const timeRangeContext = useContext(TimeRangeContext)
    const [waits, setWaits] = useState<Wait[]>([]);
    const [statements, setStatements] = useState<StatementsDataTable[]>([]);
    const [groupedStatements, setGroupedStatements] = useState<GroupedStatementsDataTable[]>([]);

    const [statementsPieChartSeries, setStatementsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [groupStatementsPieChartSeries, setGroupStatementsPieChartSeries] = useState<PieChartSeries[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const [databasesLength, setDatabasesLength] = useState<number>(0);
    const [databasesPieChartSeries, setDatabasesPieChartSeries] = useState<PieChartSeries[]>([]);
    const [sessionsLength, setSessionsLength] = useState<number>(0);
    const [sessionsPieChartSeries, setSessionsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [clientsLength, setClientsLength] = useState<number>(0);
    const [clientsPieChartSeries, setClientsPieChartSeries] = useState<PieChartSeries[]>([]);
    const [usersLength, setUsersLength] = useState<number>(0);
    const [usersPieChartSeries, setUsersPieChartSeries] = useState<PieChartSeries[]>([]);
    const [programsLength, setProgramsLength] = useState<number>(0);
    const [programsPieChartSeries, setProgramsPieChartSeries] = useState<PieChartSeries[]>([]);

    const [batchesPieChartSeries, setBatchesPieChartSeries] = useState<PieChartSeries[]>([]);

    const [waitsPieChartSeries, setWaitsPieChartSeries] = useState<PieChartSeries[]>([]);

    const statementType = getStatementTypeFromURL()

    const [visibleTabs, setVisibleTabs] = useState<string[]>([]);

    useEffect(() => {
        const getData = async() => {
            getWaits(instance.id);
            if (statementType.isGroup) {
                await getGroupedBatches(instance.id);
            } else {
                await getBatches(instance.id);
            }
            if (statementType.isGroup || statementType.isBatch) {
                getStatements(instance.id);
            }
            if (statementType.isGroup && statementType.isBatch) {
                getGroupedStatements(instance.id);
            }
        }
        if (instance) {
            void getData()
        }
    }, [])

    useEffect(() => {
        let tabs: string[] = [];
        if (instance.type === INSTANCE_TYPE_SQLSERVER) {
            switch (statementType.type) {
                case 'statement':
                    tabs = [];
                    break;
                case 'groupedstatement':
                    tabs = ['statements'];
                    break;
                case 'batch':
                    tabs = ['statements'];
                    break;
                case 'groupedbatch':
                    tabs = ['groupedstatements'];
                    break;
                default:
                    tabs = [];
                    break;
            }
        } else {
            tabs = statementType.type === 'groupedstatement' ? ['statements'] : [];
        }

        setVisibleTabs(tabs);
    }, []);

    const isDefault = (type: string) => {

        if (type === 'waits' && (statementType.type === 'statement')) {
            return true
        }
        return visibleTabs.indexOf(type) === 0
    }

    const getStatements = (instanceId: number) => {
        const apiString = getAPIString(statementId || batchId, batchStatementId)
        // Get statements.
        api.get(`activity/sql?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${filterParameters}`)
            .then((response: { data: Statement[]; }) => {

                let statementsDataTable: StatementsDataTable[] = [],
                    statementsPieChartSeries: PieChartSeries[] = [];

                // Build top statements pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_STATEMENTS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_STATEMENTS[index];
                    }

                    statementsDataTable.push({
                        id: index,
                        color: ((index < CHART_COLOURS_STATEMENTS.length) ? CHART_COLOURS_STATEMENTS[index] : ''),
                        sqlhash: response.data[index].sqlhash,
                        sqltext: response.data[index].sqltext,
                        executions: ((response.data[index].executions === undefined) ? null : Number(response.data[index].executions)),
                        waittime: response.data[index].waittime,
                        averagetime: ((response.data[index].executions === undefined) ? null : Number((response.data[index].waittime / Number(response.data[index].executions)).toFixed(2))),
                        statement: response.data[index]
                    });

                    statementsPieChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: response.data[index].sqlhash,
                        y: response.data[index].waittime,
                        url: `/instances/${instanceId}/activity/statement/${response.data[index].sqlhash}`
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Statement) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    statementsPieChartSeries.push({
                        color: '#5e4fa2',
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Statements',
                        y: remainingTime
                    });
                }

                setStatements(statementsDataTable)
                setStatementsPieChartSeries(statementsPieChartSeries.slice(0, chartSeriesCeiling))
            })
    }

    const getGroupedStatements = (instanceId: number) => {

        // Get statements.
        api.get(`activity/sql/group?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&statistic=executions&sort=waittime+desc&id=${instanceId}&batchgrouphash=${batchId}${filterParameters}`)
            .then((response: { data: GroupedStatement[]; }) => {

                let statementsDataTable: GroupedStatementsDataTable[] = [],
                    statementsPieChartSeries: PieChartSeries[] = [];

                // Build top statements pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_STATEMENTS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_STATEMENTS[index];
                    }

                    statementsDataTable.push({
                        id: index,
                        color: ((index < CHART_COLOURS_STATEMENTS.length) ? CHART_COLOURS_STATEMENTS[index] : ''),
                        grouphash: response.data[index].grouphash,
                        sqltext: response.data[index].sqltext,
                        executions: ((response.data[index].executions === undefined) ? null : Number(response.data[index].executions)),
                        waittime: response.data[index].waittime,
                        averagetime: ((response.data[index].executions === undefined) ? null : Number((response.data[index].waittime / Number(response.data[index].executions)).toFixed(2))),
                        statement: response.data[index]
                    });

                    statementsPieChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: response.data[index].grouphash,
                        y: response.data[index].waittime,
                        url: `/instances/${instanceId}/activity/groupedbatch/${batchId}/groupedstatement/${response.data[index].grouphash}`
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: GroupedStatement) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    statementsPieChartSeries.push({
                        color: '#5e4fa2',
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Statements',
                        y: remainingTime
                    });
                }
                setGroupedStatements(statementsDataTable)
                setGroupStatementsPieChartSeries(statementsPieChartSeries.slice(0, chartSeriesCeiling))
            })
    }

    const getWaits = (instanceId: number) => {
        const apiString = getAPIString(statementId || batchId, batchStatementId)

        // Get waits.
        api.get(`activity/waitevent?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${filterParameters}`)
            .then(async(response: { data: Wait[]; }) => {

                let waitsPieChartSeries: PieChartSeries[] = [];

                // Build top waits pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_WAITS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_WAITS[index];
                    }

                    waitsPieChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: response.data[index].waitevent,
                        y: response.data[index].waittime
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Wait) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    waitsPieChartSeries.push({
                        color: CHART_COLOURS_WAITS[chartSeriesCeiling],
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Waits',
                        y: remainingTime
                    });
                }
                setWaitsPieChartSeries(waitsPieChartSeries.slice(0, chartSeriesCeiling))
                setWaits(response.data)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log('An error occurred:', error);
            })
    }

    const getBatches = async(instanceId: number) => {
        let pieChartSeries: PieChartSeries[] = [];

        let apiString = ''
        const statementType = getStatementTypeFromURL()

        if (statementType.type === 'statement') {
            apiString = `&sqlhash=${props.batchStatementId || props.statementId}`
        }
        if (statementType.type === 'groupedstatement') {
            apiString = `&grouphash=${props.batchStatementId || props.statementId}`
        }
        if (statementType.type === 'groupedbatch') {
            apiString = `&batchgrouphash=${props.statementId}`
        }

        await api.get(`activity/batch?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${filterParameters}`)
            .then(async(response: { data: Batch[] }) => {
                // Build top batches pie chart.
                for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_CHART_SERIES_CEILING)).length; index++) {

                    let name: string = ((response.data[index].batchsqlhash === null) ? '-' : response.data[index].batchsqlhash);
                    if (name.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH)) {
                        name = `${name.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...`;
                    }

                    pieChartSeries.push({
                        color: CHART_COLOURS_STATEMENTS[index],
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name,
                        y: response.data[index].waittime,
                        url: ((response.data[index].batchsqlhash !== null && response.data[index].batchsqlhash !== '-') ? `/instances/${instanceId}/activity/batch/${response.data[index].batchsqlhash}` : null)
                    });
                }

                setBatchesPieChartSeries(pieChartSeries)

            })
            .catch((error: any) => {
                console.error('Failed to retrieve batches.', error);
            })
    }

    const getGroupedBatches = async(instanceId: number) => {
        let pieChartSeries: PieChartSeries[] = [];

        await api.get(`activity/batch/group?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}&grouphash=${props.statementId}${filterParameters}`)
            .then(async(response: { data: ActivityBatchGroup[] }) => {

                // Build top batches pie chart.
                for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_CHART_SERIES_CEILING)).length; index++) {

                    let name: string = ((response.data[index].batchgrouphash === null) ? '-' : response.data[index].batchgrouphash);

                    if (name.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH)) {
                        name = `${name.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...`;
                    }

                    pieChartSeries.push({
                        color: CHART_COLOURS_STATEMENTS[index],
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name,
                        y: response.data[index].waittime,
                        url: ((response.data[index].batchgrouphash !== null && response.data[index].batchgrouphash !== '-') ? `/instances/${instanceId}/activity/groupedbatch/${response.data[index].batchgrouphash}` : null)
                    });
                }

                setBatchesPieChartSeries(pieChartSeries)

            })
            .catch((error: any) => {
                console.error('Failed to retrieve batches.', error);
            })
            .then(function () {
            });
    }

    let chartOptions: ChartOption[] = [];

    // Clients.
    chartOptions.push({
        optionId: 0,
        key: 'Clients',
        data: clientsPieChartSeries
    });

    // Databases.
    chartOptions.push({
        optionId: 0,
        key: ((instance.type === INSTANCE_TYPE_ORACLE) ? 'Schemas' : 'Databases'),
        data: databasesPieChartSeries
    });

    // Programs.
    chartOptions.push({
        optionId: 0,
        key: 'Programs',
        data: programsPieChartSeries
    });

    if (instance.type === INSTANCE_TYPE_SQLSERVER) {

        // Batches.
        chartOptions.push({
            optionId: 0,
            key: 'Batches',
            data: batchesPieChartSeries
        });
    }

    if (statementType.type === 'batch' || statementType.type === 'groupedstatements') {
        // Statements.
        chartOptions.push({
            optionId: 0,
            key: 'Statements',
            data: statementsPieChartSeries
        });
    }

    if (statementType.type === 'groupedbatches') {
        // Grouped Statements.
        chartOptions.push({
            optionId: 0,
            key: 'Grouped Statements',
            data: groupStatementsPieChartSeries
        });
    }

    // Sessions.
    chartOptions.push({
        optionId: 0,
        key: 'Sessions',
        data: sessionsPieChartSeries
    });

    // Users.
    chartOptions.push({
        optionId: 0,
        key: 'Users',
        data: usersPieChartSeries
    });

    // Waits.
    chartOptions.push({
        optionId: 0,
        key: 'Waits',
        data: waitsPieChartSeries
    });

    const setDatabasesOptions = (databasesLength: number, databasesPieChartSeries: PieChartSeries[]) => {
        setDatabasesLength(databasesLength)
        setDatabasesPieChartSeries(databasesPieChartSeries)
    }

    const setSessionsOptions = (sessionsLength: number, sessionsPieChartSeries: PieChartSeries[]) => {
        setSessionsLength(sessionsLength)
        setSessionsPieChartSeries(sessionsPieChartSeries)
    }

    const setClientsOptions = (clientsLength: number, clientsPieChartSeries: PieChartSeries[]) => {
        setClientsLength(clientsLength)
        setClientsPieChartSeries(clientsPieChartSeries)
    }

    const setUsersOptions = (usersLength: number, usersPieChartSeries: PieChartSeries[]) => {
        setUsersLength(usersLength)
        setUsersPieChartSeries(usersPieChartSeries)
    }

    const setProgramsOptions = (programsLength: number, programsPieChartSeries: PieChartSeries[]) => {
        setProgramsLength(programsLength)
        setProgramsPieChartSeries(programsPieChartSeries)
    }

    const badgeStyle = (filterType: FilterType): string => {
        // TODO Find out why the options array becomes empty...
        return filterType < period.filters.options.length &&
        period.filters.options[filterType].filters.length > 0 ?
            "badge bg-filter" : "badge bg-info";
    }

    const tableTabsComponent = useMemo(() => (
        <div className="row row-cols-1">
            {/* Tab Headings */}
            <ul id="menu-inner" className="col nav nav-tabs" role="tablist">

                {/*Show grouped statements for group Batch Activity*/}
                <ConditionalRender if={visibleTabs.indexOf('groupedstatements') > -1}>
                    <li className="nav-item" role="presentation">
                        <a id="groupedstatements-tab" data-bs-toggle="tab"
                           className={isDefault('groupedstatements') ? 'active' : ''}
                           href="#groupedstatements" role="tab"
                           aria-controls="grouped-statements-tab" aria-selected="true">Grouped Statements</a>
                        <span className="badge bg-info"
                              data-tip="Total grouped statement count">{Helper.getFormattedBadgeCount(groupedStatements.length)}</span>
                    </li>
                </ConditionalRender>

                {/*Show  statements for Batch Activity and Grouped Statements */}
                <ConditionalRender if={visibleTabs.indexOf('statements') > -1}>
                    <li className="nav-item" role="presentation">
                        <a id="statements-tab"
                           className={isDefault('statements') ? 'active' : ''}
                           data-bs-toggle="tab"
                           href="#statements" role="tab"
                           aria-controls="statements"
                           aria-selected="true">Statements</a>
                        <span className="badge bg-info"
                              data-tip="Total associated statement count">{Helper.getFormattedBadgeCount(statements.length)}</span>
                    </li>
                </ConditionalRender>


                <li className="nav-item" role="presentation">
                    <a id="waits-tab"
                       className={isDefault('waits') ? 'active' : ''}
                       data-bs-toggle="tab" href="#waits"
                       role="tab"
                       aria-controls="waits"
                       aria-selected="true">Waits</a>
                    <span className={badgeStyle(FilterType.Waits)}
                          data-tip="Total wait state count">{Helper.getFormattedBadgeCount(waits.length)}</span>
                </li>

                <li className="nav-item" role="presentation">
                    <a id="databases-tab" data-bs-toggle="tab" href="#databases" role="tab"
                       aria-controls="databases"
                       aria-selected="true">{((instance.type === INSTANCE_TYPE_ORACLE) ? 'Schemas' : 'Databases')}</a>
                    <span className={badgeStyle(FilterType.Databases)}
                          data-tip="Total database count">{Helper.getFormattedBadgeCount(databasesLength)}</span>
                </li>
                <li className="nav-item" role="presentation">
                    <a id="sessions-tab" data-bs-toggle="tab" href="#sessions" role="tab"
                       aria-controls="sessions" aria-selected="true">Sessions</a>
                    <span className={badgeStyle(FilterType.Sessions)}
                          data-tip="Total session count">{Helper.getFormattedBadgeCount(sessionsLength)}</span>
                </li>
                <li className="nav-item" role="presentation">
                    <a id="clients-tab" data-bs-toggle="tab" href="#clients" role="tab" aria-controls="clients"
                       aria-selected="true">Clients</a>
                    <span className={badgeStyle(FilterType.Clients)}
                          data-tip="Total client count">{Helper.getFormattedBadgeCount(clientsLength)}</span>
                </li>
                <li className="nav-item" role="presentation">
                    <a id="users-tab" data-bs-toggle="tab" href="#users" role="tab" aria-controls="users"
                       aria-selected="true">Users</a>
                    <span className={badgeStyle(FilterType.Users)}
                          data-tip="Total user count">{Helper.getFormattedBadgeCount(usersLength)}</span>
                </li>
                <li className="nav-item" role="presentation">
                    <a id="programs-tab" data-bs-toggle="tab" href="#programs" role="tab"
                       aria-controls="programs" aria-selected="true">Programs</a>
                    <span className={badgeStyle(FilterType.Programs)}
                          data-tip="Total program count">{Helper.getFormattedBadgeCount(programsLength)}</span>
                </li>
                <li className="nav-item" role="presentation">
                    <a id="changes-tab" data-bs-toggle="tab" href="#changes" role="tab" aria-controls="changes"
                       aria-selected="true">Changes</a>
                    <span className="badge bg-info"
                          data-tip="Total change count">{Helper.getFormattedBadgeCount(statementCombinedChanges.length)}</span>
                </li>
            </ul>

            <div className="tab-content">
                {/*Show grouped statements for group Batch Activity*/}
                <ConditionalRender if={visibleTabs.indexOf('groupedstatements') > -1}>
                    <GroupedStatementsTable filtersParameters={filterParameters}
                                            instance={instance} instanceTime={statementTime} batchId={batchId}
                                            showByDefault={isDefault('groupedstatements')}
                                            waits={waits}
                                            statementsDataTable={groupedStatements}/>
                </ConditionalRender>

                <ConditionalRender if={visibleTabs.indexOf('statements') > -1}>
                    <StatementsTable period={period} instance={instance}
                                     instanceTime={statementTime}
                                     batchId={batchId}
                                     batchStatementId={batchStatementId}
                                     showByDefault={isDefault('statements')}
                                     waits={waits}
                                     statementsDataTable={statements}/>
                </ConditionalRender>


                <Waits loading={loading} waits={waits} instanceId={instance.id} instance={instance} period={period}
                       totalInstanceTime={statementTime}
                       showByDefault={isDefault('waits')}
                       setFilterOptions={setFilterOptions}/>

                <Databases instance={instance} batchId={batchId} statementId={statementId} period={period}
                           totalInstanceTime={statementTime} setDatabasesOptions={setDatabasesOptions}
                           setFilterOptions={setFilterOptions}/>
                <Sessions instanceId={instance.id} batchId={batchId} statementId={statementId} period={period}
                          totalInstanceTime={statementTime} setSessionsOptions={setSessionsOptions}
                          setFilterOptions={setFilterOptions}/>
                <Clients instanceId={instance.id} batchId={batchId} statementId={statementId} period={period}
                         totalInstanceTime={statementTime} setClientsOptions={setClientsOptions}
                         setFilterOptions={setFilterOptions}/>
                <Users instanceId={instance.id} batchId={batchId} statementId={statementId} period={period}
                       totalInstanceTime={statementTime} setUsersOptions={setUsersOptions}
                       setFilterOptions={setFilterOptions}/>
                <Programs instanceId={instance.id} batchId={batchId} statementId={statementId} period={period}
                          totalInstanceTime={statementTime} setProgramsOptions={setProgramsOptions}
                          setFilterOptions={setFilterOptions}/>
                <Changes loading={loading} eventsDataTable={statementEventsDataTable}/>
            </div>
        </div>
    ), [period,
        instance,
        batchId,
        statementId,
        statementTime,
        loading,
        waits,
        setFilterOptions,
        setDatabasesOptions,
        setClientsOptions,
        setProgramsOptions,
        setSessionsOptions,
        setUsersOptions]);

    const pieChartsComponent = useMemo(() => (<div>
            {waitsPieChartSeries.length > 0 && clientsPieChartSeries.length > 0 && chartOptions.length && (
                <div className="row row-cols-1 row-cols-lg-2">
                    <PieChart filtersOptions={period.filters.options} uniqueIndex={0}
                              chartOptions={chartOptions}
                              defaultChartOption={chartOptions.filter(option => option.key === 'Waits')[0]}
                              setFilterOptions={setFilterOptions}/>
                    <PieChart filtersOptions={period.filters.options} uniqueIndex={1}
                              chartOptions={chartOptions}
                              defaultChartOption={chartOptions.filter(option => option.key === 'Clients')[0]}
                              setFilterOptions={setFilterOptions}/>
                </div>
            )}
        </div>
), [period, setFilterOptions, chartOptions]);

    return (<>
        {pieChartsComponent}
        {tableTabsComponent}
    </>);
}

export default StatementPieChartAndTabs