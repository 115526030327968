import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {Link, Route, Switch, useHistory} from 'react-router-dom';

// Third-parties.
import Helmet from 'react-helmet';

import * as QueryString from 'query-string';

import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

import LazyLoad from 'react-lazyload';

// Context.
import { InstanceContext } from '../../../context/InstanceContext';
import { HostContext } from '../../../context/HostContext';
import { EventTypeContext } from '../../../context/EventTypeContext';

// Constants.
import {INTERVAL_SECOND, INTERVAL_MINUTE, INTERVAL_ONE_HOUR, INTERVAL_ONE_DAY, PERIOD_CUSTOM} from '../../../component/Constants';
import { CHART_COLOURS_WAITS, TARGET_COLOUR_CODES, } from '../../../component/Constants';
import { REPORT_PERIOD_PREVIOUS, REPORT_PERIOD_CURRENT } from '../../../component/Constants';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import HostTarget from '../../../types/host/HostTarget';
import InstanceTargetHost from '../../../types/instance/InstanceTargetHost';
import DatabaseTime from '../../../types/instance/DatabaseTime';
import Statistic from '../../../types/instance/Statistic';
import HostStatistic from '../../../types/host/HostStatistic';
import Change from '../../../types/instance/Change';
import EventType from '../../../types/instance/EventType';
import Event from '../../../types/instance/Event';
import CombinedChanges from '../../../types/instance/CombinedChanges';
import Time from '../../../types/instance/Time';
import ChartSeries from '../../../types/ChartSeries';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';
import Wait from '../../../types/instance/Wait';
import Statement from '../../../types/instance/Statement';

// Components.
import Alert from '../../../component/Alert';
import DifferenceIcon from '../../../component/DifferenceIcon';
import WaitsChart from './WaitsChart';
import StatementsChart from './StatementsChart';
import HostsProcessorMemoryChart from './HostsProcessorMemoryChart';
import HostsDiskReadWritesChart from './HostsDiskReadWritesChart';
import Statements from './Statements';
import ComparisonChangesTable from './ComparisonChangesTable';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";
import api from "../../../api/Base";

const 
    chartSeriesCeiling: number = 10,
    statementLimit: number = 20;

function Results(props: { period: Period, toggleMenu: Function }) {
    const now: Dayjs = dayjs.default();
    const history = useHistory();

    const { instances } = useContext(InstanceContext);
    const { hosts } = useContext(HostContext);
    const { eventTypes } = useContext(EventTypeContext);

    const [error, setError] = useState<null | string>(null);
    const [period, setPeriod] = useState<Period | null>(null);
    const [matchedInstances, setMatchedInstances] = useState<InstanceTarget[]>([]);
    const [matchedHosts, setMatchedHosts] = useState<[HostTarget[], HostTarget[]]>([[], []]);

    const [timeA, setTimeA] = useState<null | number>(null);
    const [timeB, setTimeB] = useState<null | number>(null);
    const [timeDifference, setTimeDifference] = useState<null | number>(null);

    const [executionsA, setExecutionsA] = useState<null | number>(null);
    const [executionsB, setExecutionsB] = useState<null | number>(null);
    const [executionsDifference, setExecutionsDifference] = useState<null | number>(null);

    const [averageTimeA, setAverageTimeA] = useState<null | number>(null);
    const [averageTimeB, setAverageTimeB] = useState<null | number>(null);
    const [averageTimeDifference, setAverageTimeDifference] = useState<null | number>(null);

    const [processorA, setProcessorA] = useState<null | number>(null);
    const [processorB, setProcessorB] = useState<null | number>(null);

    const [memoryA, setMemoryA] = useState<null | number>(null);
    const [memoryB, setMemoryB] = useState<null | number>(null);

    const [diskReadA, setDiskReadA] = useState<null | number>(null);
    const [diskReadB, setDiskReadB] = useState<null | number>(null);

    const [diskWriteA, setDiskWriteA] = useState<null | number>(null);
    const [diskWriteB, setDiskWriteB] = useState<null | number>(null);

    const [changesA, setChangesA] = useState<CombinedChanges[]>([]);
    const [changeCountA, setChangeCountA] = useState<number>(0);
    const [eventCountA, setEventCountA] = useState<number>(0);
    const [changesB, setChangesB] = useState<CombinedChanges[]>([]);
    const [changeCountB, setChangeCountB] = useState<number>(0);
    const [eventCountB, setEventCountB] = useState<number>(0);
    const [changeCountDifference, setChangeCountDifference] = useState<number>(0);
    const [eventCountDifference, setEventCountDifference] = useState<number>(0);
    
    const [waits, setWaits] = useState<Wait[]>([]);
    const [waitsSeries, setWaitsSeries] = useState<ChartSeries[]>([]);
    const [waitsPeak, setWaitsPeak] = useState<null | number>(null);
    
    const [statements, setStatements] = useState<Statement[]>([]);
    const [statementsA, setStatementsA] = useState<Statement[]>([]);
    const [statementsB, setStatementsB] = useState<Statement[]>([]);
    const [statementsSeries, setStatementsSeries] = useState<ChartSeries[]>([]);
    const [statementsPeak, setStatementsPeak] = useState<null | number>(null);

    // Get the QueryString parameters.
    const query = window.location.search;

    // Get requested data from the QueryString, set the instances.
    useMemo(() => {

        // Check for QueryString parameters.
        let queryString = QueryString.parse(query);

        let
            timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
            queryInstances: undefined | string[] = queryString.instances?.toString().split(','),
            a: undefined | string | string[] = queryString.a?.toString(),
            b: undefined | string | string[] = queryString.b?.toString(),
            d: undefined | string | string[] = queryString.d?.toString(),
            tz: undefined | string | string[] = queryString.tz?.toString();

        if (queryInstances === undefined || a === undefined || b === undefined || d === undefined) {

            return;
        }

        // Check to see if the timezone has been set or not.
        if (tz !== undefined) {

            // Set the timezone to match the passed QueryString parameter.
            timezone = tz;
        }

        if (isNaN(parseInt(d)) === true) {

            // Invalid instances requested.
            setError('Invalid period requested, please set valid options from the report settings below.');

            return;
        }

        // Get the requested duration.
        const duration: number = parseInt(d);

        // Get the requested instances.
        const
            instanceA: string = queryInstances[0],
            instanceB: string = queryInstances[1];

        if (isNaN(parseInt(instanceA)) === true || isNaN(parseInt(instanceB)) === true) {

            // Invalid instances requested.
            setError('Invalid instances requested, please set valid options from the report settings below.');

            return;
        }

        // Find the requested instances.
        const filteredInstances = [...instances.filter(instance => instance.id === Number(instanceA)), ...instances.filter(instance => instance.id === Number(instanceB))];

        if (filteredInstances.length !== 2) {

            // Invalid instances requested.
            setError('Unable to find one or more requested instances, please check ensure the requested instances are still valid.');

            return;
        }      

        setMatchedInstances(filteredInstances);

        // Get the requested period values.
        const
            aa = dayjs.default(a, 'YYYY-MM-DD+HH:mm'),
            ab = dayjs.default(a, 'YYYY-MM-DD+HH:mm').add(duration, 'minute'),
            ba = dayjs.default(b, 'YYYY-MM-DD+HH:mm'),
            bb = dayjs.default(b, 'YYYY-MM-DD+HH:mm').add(duration, 'minute');

        if (aa.isValid() === false || ab.isValid() === false || ba.isValid() === false || bb.isValid() === false) {

            // Invalid dates requested.
            setError('One or more requested report dates are invalid, please set valid options from the report settings below.');

            return;
        }        

        // Get the timeslice interval.
        const time = bb.diff(ba, 'ms');

        let interval: number = INTERVAL_ONE_DAY;

        // Calculate the time difference and set the interval.
        if (time < 300000) {
            // Five minutes or less.
            interval = INTERVAL_SECOND;
        } else if (time < 600000) {
            // Ten minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 900000) {
            // Fifteen minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 1800000) {
            // Thirty minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 3600000) {
            // Hour or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 14400000) {
            // Four hours or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 21600000) {
            // Six hours or less.
            interval = INTERVAL_MINUTE;
        } else if (time < 43200000) {
            // Twelve hours or less.
            interval = INTERVAL_ONE_HOUR;
        } else if (time < 86400000) {
            // 24 hour period - set interval to hourly.
            interval = INTERVAL_ONE_HOUR;
        } else if (time < 137460000) {
            // Twenty four hours of less - set interval to hourly..
            interval = INTERVAL_ONE_HOUR;
        } else if (time < 604800000) {
            // Over week period - set interval to a day.
            interval = INTERVAL_ONE_DAY;
        } else if (time < 2678400000) {
            // Over a month period - set interval to a day.
            interval = INTERVAL_ONE_DAY;
        }

        // Build the period object.
        let reportPeriod: Period = {
            chartTickInterval: Helper.getChartTickInterval(ba, bb, PERIOD_CUSTOM, interval),
            comparisonSupported: true,
            isValid: true,
            timestamp: '0',
            dateFormat: Helper.getChartDateFormat(interval),
            api: {
                interval: 0,
                periodOption: PERIOD_CUSTOM,
                time: 1,
                timezone,
                current: {
                    from: ba.format('YYYY-MM-DD+HH:mm:00'),
                    to: bb.format('YYYY-MM-DD+HH:mm:00')
                },
                previous: {
                    from: aa.format('YYYY-MM-DD+HH:mm:00'),
                    to: ab.format('YYYY-MM-DD+HH:mm:00')
                }
            },
            ui: {
                heading: '',
                subheading: '',
                queryString: '',
                current: {
                    chartCategories: Helper.getChartAxisCategories(ba, bb, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(ba, bb, PERIOD_CUSTOM, interval),
                    from: ba,
                    to: bb
                },
                previous: {
                    chartCategories: Helper.getChartAxisCategories(aa, ab, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(aa, ab, PERIOD_CUSTOM, interval),
                    from: aa,
                    to: ab
                }
            },
            filters: {
                options: [],
                parameters: ''
            }
        };

        setPeriod(reportPeriod);

    }, [instances, query]);

    // Get the chart categories.
    const chartCategories: string[] = useMemo(() => {

        if (period === null) {

            // Return empty array list.
            return [];
        }

        return [...period.ui.previous.formattedChartCategories, ...['Interim'], ...period.ui.current.formattedChartCategories];

    }, [period]);

    // Set the interval chart plot band, as used by all charts within this report to differentiate between the two time periods.
    const plotBands: ChartPlotBand[] = useMemo(() => {

        if (period === null) {

            return [];
        }
            
        const bands: ChartPlotBand[] = [{
            from: 0,
            to: period.ui.previous.formattedChartCategories.length,
            color: '#f5f5f5',
            width: 1,
            label: {
                rotation: 0,
                text: Helper.getFormattedPeriodHeading(period, REPORT_PERIOD_PREVIOUS),
                style: {
                    color: '#666',
                    fontSize: '.7rem',
                    fontWeight: 'normal'
                },
                y: 30
            }
        }, 
        {
            from: period.ui.current.formattedChartCategories.length,
            to: period.ui.current.formattedChartCategories.length * 2,
            color: '#fff',
            width: 1,
            label: {
                rotation: 0,
                text: Helper.getFormattedPeriodHeading(period, REPORT_PERIOD_CURRENT),
                style: {
                    color: '#999',
                    fontSize: '.7rem',
                    fontWeight: 'normal'
                },
                y: 30
            }
        }];

        return bands;

    }, [period]);

    // Get the base chart plot lines, including the interim marker.
    const plotLines: ChartPlotLine[] = useMemo(() => {

        if (period === null) {

            // Return empty array list.
            return [];
        }

        let chartPlotLines: ChartPlotLine[] = Helper.getChartPlotLines(period, [...changesA, ...changesB], [...period.ui.previous.chartCategories, ...['Interim'], ...period.ui.current.chartCategories]);

        // Add period differentiator plot line.
        chartPlotLines.push({
            value: period.ui.previous.chartCategories.length,
            color: '#666',
            width: 12,
            dashStyle: 'solid',
            label: {
                useHTML: true,
                text: '',
                x: -8,
                y: 15,
                rotation: 0,
                style: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            zIndex: 1
        });

        return chartPlotLines;

    }, [period, changesA, changesB]);

    // Get any instance hosts, total time and executions.
    useMemo(() => {

        if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {

            // The period needs to be set...
            return;
        }

        async function getHosts(instance: InstanceTarget) {

            let instanceHosts: HostTarget[] = [];

            await api.get(`datasource/host?id=${instance.id}`)
                .then((response: { data: InstanceTargetHost[]; }) => {

                    for (let index = 0; index < response.data.length; index++) {

                        // Get any matching hosts.                
                        const matchedHosts = hosts.filter(host => host.id === response.data[index].hostid);

                        if (matchedHosts.length === 1) {

                            // Append the matched hosts to any pre-existing hosts.
                            instanceHosts.push(matchedHosts[0]);
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve linked hosts', error, instance);
                })
                .then(async () => {
                });

            return instanceHosts;
        }

        function getTotalTime() {

            if (period === null) {

                // The period needs to be set...
                return;
            }

            api.get(`activity/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&id=${matchedInstances[0].id}`)
                .then((response: { data: DatabaseTime[]; }) => {
                    if (response.data.length > 0) {
                        setTimeA(response.data[0].waittime);
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve total first instance time', error, matchedInstances[0]);
                })
                .then(function () {
                });

            api.get(`activity/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&id=${matchedInstances[1].id}`)
                .then((response: { data: DatabaseTime[]; }) => {
                    if (response.data.length > 0) {
                        setTimeB(response.data[0].waittime);
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve total second instance time', error, matchedInstances[1]);
                })
                .then(function () {
                });
        }

        function getTotalExecutions() {

            if (period === null) {

                // The period needs to be set...
                return;
            }

            api.get(`statistic/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=executions&id=${matchedInstances[0].id}`)
            .then((response: { data: Statistic[]; }) => {
                if (response.data.length > 0) {
                    setExecutionsA(response.data[0].sum);
                }
            })
            .catch((error: any) => {
                console.error('Failed to retrieve total first instance executions', error, matchedInstances[0]);
            })
            .then(function () {
            });

            api.get(`statistic/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=executions&id=${matchedInstances[1].id}`)
            .then((response: { data: Statistic[]; }) => {
                if (response.data.length > 0) {
                    setExecutionsB(response.data[0].sum);
                }
            })
            .catch((error: any) => {
                console.error('Failed to retrieve total second instance executions', error, matchedInstances[0]);
            })
            .then(function () {
            });
        }

        async function getData() {

            // Get the first instance hosts.
            let hostsA: HostTarget[] = await getHosts(matchedInstances[0]),
                hostsB: HostTarget[] = [];

            if (matchedInstances[0].id !== matchedInstances[1].id) {

                // Get the second instance hosts.
                hostsB = await getHosts(matchedInstances[1]);
            } else {

                // The same instance is getting compared, so the hosts are the same.
                hostsB = hostsA;
            }

            setMatchedHosts([hostsA, hostsB]);

            // Get instance time and executions.
            getTotalTime();
            getTotalExecutions();
        }

        getData();

    }, [period, hosts, matchedInstances]);

    // Get core host metrics for the highlights table.
    useMemo(() => {

        if (period === null) {

            // The period needs to be set...
            return;
        }

        async function getHostMetrics() {

            if (period === null) {

                // The period needs to be set...
                return;
            }

            let avgProcessorA: number[] = [],
                avgProcessorB: number[] = [],
                avgMemoryA: number[] = [],
                avgMemoryB: number[] = [],
                avgDiskReadA: number[] = [],
                avgDiskReadB: number[] = [],
                avgDiskWriteA: number[] = [],
                avgDiskWriteB: number[] = [];

            // Get the first instance and period.
            for (let index = 0; index < matchedHosts[0].length; index++) {

                const host = matchedHosts[0][index];
                
                // Processor.
                await api.get(`host/statistic/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=cpuutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgProcessorA.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve processor metric for host', error, host);
                    })
                    .then(function () {
                    });
                
                // Memory.
                await api.get(`host/statistic/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=memoryutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgMemoryA.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve memory metric for host', error, host);
                    })
                    .then(function () {
                    });
            
                // Disk Read.
                await api.get(`host/statistic/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=time%20spent%20reading%20(ms)&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgDiskReadA.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve disk read metric for host', error, host);
                    })
                    .then(function () {
                    });
        
                // Disk Write.
                await api.get(`host/statistic/summary?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=time%20spent%20writing%20(ms)&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgDiskWriteA.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve disk write metric for host', error, host);
                    })
                    .then(function () {
                    });
            }

            // Get the second instance and period.
            for (let index = 0; index < matchedHosts[1].length; index++) {

                const host = matchedHosts[1][index];
                
                // Processor.
                await api.get(`host/statistic/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=cpuutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgProcessorB.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve processor metric for host', error, host);
                    })
                    .then(function () {
                    });
                
                // Memory.
                await api.get(`host/statistic/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=memoryutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgMemoryB.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve memory metric for host', error, host);
                    })
                    .then(function () {
                    });
            
                // Disk Read.
                await api.get(`host/statistic/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=time%20spent%20reading%20(ms)&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgDiskReadB.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve disk read metric for host', error, host);
                    })
                    .then(function () {
                    });
        
                // Disk Write.
                await api.get(`host/statistic/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=time%20spent%20writing%20(ms)&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            avgDiskWriteB.push(Math.round(response.data[0].avg));
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve disk write metric for host', error, host);
                    })
                    .then(function () {
                    });
            }

            // Get the average value for all the hosts.
            const
                calcProcessorA: number = parseInt(Helper.getAverageFromArray(avgProcessorA).toFixed(0)),
                calcProcessorB: number = parseInt(Helper.getAverageFromArray(avgProcessorB).toFixed(0)),
                calcMemoryA: number = parseInt(Helper.getAverageFromArray(avgMemoryA).toFixed(0)),
                calcMemoryB: number = parseInt(Helper.getAverageFromArray(avgMemoryB).toFixed(0)),
                calcDiskReadA: number = Number(Helper.getAverageFromArray(avgDiskReadA).toFixed(2)),
                calcDiskReadB: number = Number(Helper.getAverageFromArray(avgDiskReadB).toFixed(2)),
                calcDiskWriteA: number = Number(Helper.getAverageFromArray(avgDiskWriteA).toFixed(2)),
                calcDiskWriteB: number = Number(Helper.getAverageFromArray(avgDiskWriteB).toFixed(2));

            setProcessorA(calcProcessorA);
            setProcessorB(calcProcessorB);

            setMemoryA(calcMemoryA);
            setMemoryB(calcMemoryB);

            setDiskReadA(calcDiskReadA);
            setDiskReadB(calcDiskReadB);

            setDiskWriteA(calcDiskWriteA);
            setDiskWriteB(calcDiskWriteB);
        }

        getHostMetrics();

    }, [period, matchedHosts]);

    // Work out the total time and executions difference.
    useMemo(() => {

        // Time.
        if (timeA === null || timeB === null) {

            // No times set.
            return;
        }

        setTimeDifference(timeB - timeA);

        // Executions.
        if (executionsA === null || executionsB === null) {

            // No executions set.
            return;
        }

        setExecutionsDifference(executionsB - executionsA);

        // Average time.
        const
            avgTimeA: number = parseInt((timeA / executionsA).toFixed(0)),
            avgTimeB: number = parseInt((timeB / executionsB).toFixed(0));

        setAverageTimeA(avgTimeA);
        setAverageTimeB(avgTimeB);
        setAverageTimeDifference(parseInt((avgTimeB - avgTimeA).toFixed(0)));

    }, [timeA, timeB, executionsA, executionsB]);

    // Get detected changes and registered events, execution plan changes build plot lines.
    useMemo(() => {

        if (period === null) {

            // The period needs to be set...
            return;
        }

        async function getChanges() {

            if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {

                // The period needs to be set...
                return;
            }

            //#region Instance Changes

            let changesA: Change[] = [],
                changesB: Change[] = [];

            // Get first instance changes.
            await api.get(`change?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${INTERVAL_SECOND}&sort=timeslice+desc&limit=5000&id=${matchedInstances[0].id}`)
                .then((response: { data: Change[], status: number }) => {
                    if (response.status === 200) {
                        changesA = response.data;
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve changes for the first instance', error);
                })
                .then(function () {
                });

            // Get second instance changes.
            await api.get(`change?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${INTERVAL_SECOND}&sort=timeslice+desc&limit=5000&id=${matchedInstances[1].id}`)
                .then((response: { data: Change[], status: number }) => {
                    if (response.status === 200) {
                        changesB = response.data;
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve changes for the second instance', error);
                })
                .then(function () {
                });

            //#endregion Instance Changes

            //#region Instance Events

            let eventsA: Event[] = [],
                eventsB: Event[] = [];

            // Get first instance events.
            await api.get(`event?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${INTERVAL_SECOND}&sort=startDateTime+desc&limit=5000&id=${matchedInstances[0].id}`)
                .then((response: { data: Event[], status: number }) => {
                    if (response.status === 200) {
                        eventsA = response.data;
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve registered events for the first instance', error);
                })
                .then(function () {
                });

            // Get second instance events.
            await api.get(`event?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${INTERVAL_SECOND}&sort=startDateTime+desc&limit=5000&id=${matchedInstances[1].id}`)
                .then((response: { data: Event[], status: number }) => {
                    if (response.status === 200) {
                        eventsB = response.data;
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve registered events for the second instance', error);
                })
                .then(function () {
                });

            //#endregion Instance Events

            //#region Combine and Sort Changes and Events

            let combinedA: CombinedChanges[] = [];

            // Add the changes to the combined array.
            for (let index = 0; index < changesA.length; index++) {

                let change: Change = changesA[index];

                change.id = matchedInstances[0].id;
                change.instanceName = matchedInstances[0].name;

                combinedA.push({
                    id: index,
                    instanceId: change.id,
                    timeslice: change.timeslice,
                    change
                });
            }

            // Add the events to the combined array.
            for (let index = 0; index < eventsA.length; index++) {

                // Get the event type ID.
                let event: Event = eventsA[index];

                // Find the matching event type.
                const matchedEventType: EventType[] = eventTypes.filter(item => {
                    return item.eventTypeId === event.eventTypeId
                });
    
                if (matchedEventType.length > 0) {
                    event.eventType = matchedEventType[0];
                }

                event.databaseTargetId = matchedInstances[0].id;
                event.databaseTargetName = matchedInstances[0].name;

                if (event.endDateTime !== null) {
                    event.isPlotband = true;
                }

                combinedA.push({
                    id: index,
                    instanceId: event.databaseTargetId,
                    timeslice: event.startDateTime,
                    event
                });
            }

            // Set the sort order for the combined changes and events.
            combinedA.sort(function compare(a, b) {
                var aa: number = dayjs.default(a.timeslice).valueOf();
                var bb: number = dayjs.default(b.timeslice).valueOf();
                return bb - aa;
            });

            let combinedB: CombinedChanges[] = [];

            // Add the changes to the combined array.
            for (let index = 0; index < changesB.length; index++) {

                let change: Change = changesB[index];

                change.id = matchedInstances[1].id;
                change.instanceName = matchedInstances[1].name;

                combinedB.push({
                    id: index,
                    instanceId: change.id,
                    timeslice: change.timeslice,
                    change
                });
            }

            // Add the events to the combined array.
            for (let index = 0; index < eventsB.length; index++) {

                // Get the event type ID.
                let event: Event = eventsB[index];

                // Find the matching event type.
                const matchedEventType: EventType[] = eventTypes.filter(item => {
                    return item.eventTypeId === event.eventTypeId
                });
    
                if (matchedEventType.length > 0) {
                    event.eventType = matchedEventType[0];
                }

                event.databaseTargetId = matchedInstances[1].id;
                event.databaseTargetName = matchedInstances[1].name;

                if (event.endDateTime !== null) {
                    event.isPlotband = true;
                }

                combinedB.push({
                    id: index,
                    instanceId: event.databaseTargetId,
                    timeslice: event.startDateTime,
                    event
                });
            }

            // Set the sort order for the combined changes and events.
            combinedB.sort(function compare(a, b) {
                var aa: number = dayjs.default(a.timeslice).valueOf();
                var bb: number = dayjs.default(b.timeslice).valueOf();
                return bb - aa;
            });

            //#endregion Combine and Sort Changes and Events

            setChangesA(combinedA);
            setChangeCountA(changesA.length);
            setEventCountA(eventsA.length);

            setChangesB(combinedB);
            setChangeCountB(changesB.length);
            setEventCountB(eventsB.length);

            setChangeCountDifference(changesA.length - changesB.length);
            setEventCountDifference(eventsA.length - eventsB.length);
        }

        getChanges();

    }, [period, matchedInstances, eventTypes]);

    // Get the top waits.
    useMemo(() => {

        async function getWaits() {

            if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {
    
                // The period needs to be set...
                return;
            }
    
            let waitEventsA: Wait[] = [],
                waitEventsB: Wait[] = [],
                waitevents: Wait[] = [];
        
            // Get the first set of top waits.
            await api.get(`activity/waitevent?limit=${chartSeriesCeiling}&from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&sort=waittime+desc&id=${matchedInstances[0].id}`)
                .then(async (response: { data: Wait[]; }) => {
                    waitEventsA = response.data;
                })
                .catch((error: any) => {                    
                    console.error('Failed to retrieve instance wait events', error);
                })
                .then(() => {
                });
    
            // Get the second set of top waits.
            await api.get(`activity/waitevent?limit=${chartSeriesCeiling}&from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&sort=waittime+desc&id=${matchedInstances[1].id}`)
                .then(async (response: { data: Wait[]; }) => {
                    waitEventsB = response.data;
                })
                .catch((error: any) => {                    
                    console.error('Failed to retrieve instance wait events', error);
                })
                .then(() => {
                });

            // Merge the wait events into a unique list based upon their name value.
            waitevents = Helper.getUniqueArray(waitEventsA, waitEventsB, 'waitevent');
            
            // Sort the wait events by total time descending.
            waitevents.sort(function compare(a, b) {
                return b.waittime - a.waittime;
            });
    
            for (let index = 0; index < waitevents.slice(0, chartSeriesCeiling).length; index++) {
                // Set the wait state colour.
                waitevents[index].color = CHART_COLOURS_WAITS[index];
            }
    
            // Set the waits.
            setWaits(waitevents);
        }

        getWaits();

    }, [period, matchedInstances]);

    // Get top waits over time.
    useMemo(() => {

        async function getWaitsOverTime() {

            if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {
    
                // The period needs to be set...
                return;
            }

            let series: ChartSeries[] = [],
                peakSeries: (number | null)[] = [];

            // Get the top individual waits for both periods.
            for (let waitIndex = 0; waitIndex < waits.length; waitIndex++) {

                let wait: Wait = waits[waitIndex],
                    data: (number | null)[] = [];

                // First period.
                await api.get(`activity/time?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${matchedInstances[0].id}`)
                    .then(async (response: { data: Time[]; }) => {

                        // Check, does the time match?
                        for (let index = 0; index < period.ui.previous.chartCategories.length; index++) {

                            let result = response.data.filter((item: Time) => {
                                return item.timeslice === period.ui.previous.chartCategories[index]
                            });

                            if (result.length === 0) {

                                // Data missing.
                                data.push(0);
                            } else {

                                // Data exists.
                                data.push(result[0].waittime);
                            }
                        }
                    })
                    .catch((error: any) => {            
                        console.error('Failed to retrieve instance wait event timings', error);
                    })
                    .then(function () {
                    });

                // Interim data push.
                data.push(null);

                // Second period.
                await api.get(`activity/time?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${matchedInstances[1].id}`)
                    .then(async (response: { data: Time[]; }) => {

                        // Check, does the time match?
                        for (let index = 0; index < period.ui.current.chartCategories.length; index++) {

                            let result = response.data.filter((item: Time) => {
                                return item.timeslice === period.ui.current.chartCategories[index]
                            });
                            
                            if (result.length === 0) {

                                // Data missing.
                                data.push(0);
                            } else {

                                // Data exists.
                                data.push(result[0].waittime);
                            }
                        }
                    })
                    .catch((error: any) => {            
                        console.error('Failed to retrieve instance wait event timings', error);
                    })
                    .then(function () {
                    });

                peakSeries.push(Helper.getPeakValue(data));

                // Add the wait state chart series.
                series.push({
                    name: wait.waitevent,
                    color: wait.color,
                    type: 'column',
                    data,
                    tooltip: {
                        valueSuffix: 'ms',
                    },
                    zIndex: 1
                });
            }

            const peak: number = Helper.getPeakValue(peakSeries);

            if (peak !== Infinity && peak !== -Infinity) {
                setWaitsPeak(peak);
            }
            
            setWaitsSeries(series);
        }

        getWaitsOverTime();

    }, [period, matchedInstances, waits]);

    // Get top statements.
    useMemo(() => {

        async function getStatements() {

            if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {
    
                // The period needs to be set...
                return;
            }

            let statements: Statement[] = [],
                statementsA: Statement[] = [],
                statementsB: Statement[] = [];

            //#region Get Top Statements

            // Get first period's top statements.
            await api.get(`activity/sql?limit=${statementLimit}&from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=executions&sort=waittime+desc&id=${matchedInstances[0].id}`)
                .then((response: { data: Statement[]; }) => {
                    statementsA = response.data;
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve top statements for the first period', error);
                })
                .then(function () {
                });

			// Get second period's top statements.
			await api.get(`activity/sql?limit=${statementLimit}&from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&statistic=executions&sort=waittime+desc&id=${matchedInstances[1].id}`)
				.then((response: { data: Statement[]; }) => {
					statementsB = response.data;
				})
				.catch((error: any) => {
					console.error('Failed to retrieve top statements for the second period', error);
				})
				.then(function () {
				});

            //#endregion Get Top Statements

            // Merge the statements into a unique list based upon their SQL hash value.
            statements = Helper.getUniqueArray(statementsA, statementsB, 'sqlhash');
    
            //#region Set Statement Colours
            
            for (let index = 0; index < statements.length; index++) {

                if (index < TARGET_COLOUR_CODES.length) {

                    // Set the statement colour.
                    statements[index].color = TARGET_COLOUR_CODES[index];
                }
            }

            //#endregion Set Statement Colours

            // Set the sort order for the combined statements.
            statements.sort(function compare(a, b) {
                var aa: number = dayjs.default(a.waittime).valueOf();
                var bb: number = dayjs.default(b.waittime).valueOf();
                return bb - aa;
            });

            setStatements(statements);
            setStatementsA(statementsA);
            setStatementsB(statementsB);
        };

        getStatements();

    }, [period, matchedInstances]);

    // Get top statements for the over time chart.
    useMemo(() => {

        async function getStatementsOverTime() {

            if (period === null || matchedInstances[0] === undefined || matchedInstances[1] === undefined) {
    
                // The period needs to be set...
                return;
            }

            let series: ChartSeries[] = [],
                peakSeries: (number | null)[] = [];

            // Get the top individual statements for both periods.
            for (let statementIndex = 0; statementIndex < statements.length; statementIndex++) {

                let statement: Statement = statements[statementIndex],
                    data: (number | null)[] = [];

                // First period.
                await api.get(`activity/time?from=${period.api.previous.from}&to=${period.api.previous.to}&tz=${period.api.timezone}&interval=${period.api.interval}&sqlhash=${statement.sqlhash}&id=${matchedInstances[0].id}`)
                    .then(async (response: { data: Time[]; }) => {

                        // Check, does the time match?
                        for (let index = 0; index < period.ui.previous.chartCategories.length; index++) {

                            let result = response.data.filter((item: Time) => {
                                return item.timeslice === period.ui.previous.chartCategories[index]
                            });

                            if (result.length === 0) {

                                // Data missing.
                                data.push(0);
                            } else {

                                // Data exists.
                                data.push(result[0].waittime);
                            }
                        }
                    })
                    .catch((error: any) => {            
                        console.error('Failed to retrieve instance statement timings', error);
                    })
                    .then(function () {
                    });

                // Interim data push.
                data.push(null);

                // Second period.
                await api.get(`activity/time?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=${period.api.interval}&sqlhash=${statement.sqlhash}&id=${matchedInstances[1].id}`)
                    .then(async (response: { data: Time[]; }) => {

                        // Check, does the time match?
                        for (let index = 0; index < period.ui.current.chartCategories.length; index++) {

                            let result = response.data.filter((item: Time) => {
                                return item.timeslice === period.ui.current.chartCategories[index]
                            });
                            
                            if (result.length === 0) {

                                // Data missing.
                                data.push(0);
                            } else {

                                // Data exists.
                                data.push(result[0].waittime);
                            }
                        }
                    })
                    .catch((error: any) => {            
                        console.error('Failed to retrieve instance statement timings', error);
                    })
                    .then(function () {
                    });

                peakSeries.push(Helper.getPeakValue(data));

                // Add the statement chart series.
                series.push({
                    name: statement.sqlhash,
                    color: statement.color,
                    type: 'column',
                    data,
                    tooltip: {
                        valueSuffix: 'ms',
                    },
                    zIndex: 1
                });
            }

            const peak: number = Helper.getPeakValue(peakSeries);

            if (peak !== Infinity && peak !== -Infinity) {
                setStatementsPeak(peak);
            }
            
            setStatementsSeries(series);
        }

        getStatementsOverTime();

    }, [period, matchedInstances, statements]);

    function resetReport() {
        
        // Build the location state which gets passed with the location push.
        history.push({ pathname: `/reports/time-comparison/` });
        
        //setPeriod(null);

        /*
        setError(null);
        
        setMatchedInstances([]);
        setMatchedHosts([[], []]);
    
        setTimeA(null);
        setTimeB(null);
        setTimeDifference(null);
    
        setExecutionsA(null);
        setExecutionsB(null);
        setExecutionsDifference(null);
    
        setAverageTimeA(null);
        setAverageTimeB(null);
        setAverageTimeDifference(null);
    
        setProcessorA(null);
        setProcessorB(null);
    
        setMemoryA(null);
        setMemoryB(null);
    
        setDiskReadA(null);
        setDiskReadB(null);
    
        setDiskWriteA(null);
        setDiskWriteB(null);
    
        setChangesA([]);
        setChangeCountA(0);
        setEventCountA(0);
        setChangesB([]);
        setChangeCountB(0);
        setEventCountB(0);
        setChangeCountDifference(0);
        setEventCountDifference(0);
        
        setWaits([]);
        setWaitsSeries([]);
        setWaitsPeak(null);
        
        setStatements([]);
        setStatementsA([]);
        setStatementsB([]);
        setStatementsSeries([]);
        setStatementsPeak(null);
        
        setProcessorSeries([]);
        setProcessorPeak(null);
        setMemorySeries([]);
        setMemoryPeak(null);
        setDiskReadSeries([]);
        setDiskReadPeak(null);
        setDiskWriteSeries([]);
        setDiskWritePeak(null);
        */
    }

    return (
        <div id="content">

            <Helmet>
                <title>Time Comparison - DBmarlin</title>
                <meta name="description" content="Compare your DB instances over two defined periods" />
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="Time Comparison">

                    <Link to="/reports/time-comparison">Reports</Link>
                    <Link to="/reports/time-comparison">Time Comparison</Link>
                    <Switch>
                        <Route exact path="/reports/time-comparison/results" render={() => <span>Results</span>} />
                    </Switch>

                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} />
            </div>

            <div className="loader">
                <ConditionalRender if={false}>
                    <div className="bar"></div>
                </ConditionalRender>
            </div>

            {period !== null && matchedInstances[0] !== undefined && matchedInstances[1] !== undefined && (
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="card collapsible">
                            <div className="card-header">
                                <i className="fal fa-stopwatch fa-fw" aria-hidden="true"></i>
                                Time comparison results
                                <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseReport" aria-expanded="false" aria-controls="collapseReport"></i>
                                <div className="btn-group float-end" role="group" aria-label="Button group with nested dropdown">
                                    <button type="button" className="btn btn-xsm btn-primary float-end ms-1" onClick={resetReport}>Reset Report</button>
                                </div>
                            </div>
                            <div id="collapseReport" className="card-body collapse show report">
                                {period !== null && error === null && (
                                    <React.Fragment>

                                        {(period.ui.previous.to.isAfter(now) || period.ui.current.to.isAfter(now)) && (
                                            <Alert heading="Future date or time selected" message="One or more of your selected comparison periods are in the future, which may result in inaccurate comparisons" variant="alert-info" />
                                        )}

                                        {/* Highlights */}
                                        <h3>Differential highlights</h3>

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="col-3" scope="col">
                                                        Metric
                                                    </th>
                                                    <th className="col-3 text-end highlight" scope="col">
                                                        {Helper.getFormattedPeriodHeading(period, REPORT_PERIOD_PREVIOUS)}
                                                    </th>
                                                    <th className="col-3 text-end" scope="col">
                                                        {Helper.getFormattedPeriodHeading(period, REPORT_PERIOD_CURRENT)}
                                                    </th>
                                                    <th className="col-3 text-end highlight" scope="col">
                                                        Period Difference
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Instance Titles */}
                                                <tr>
                                                    <td>
                                                        Instance Name
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {matchedInstances[0].name}
                                                    </td>
                                                    <td className="text-end">
                                                        {matchedInstances[1].name}
                                                    </td>
                                                    <td className="text-end highlight">
                                                    </td>
                                                </tr>

                                                {/* Total Time */}
                                                <tr>
                                                    <td>
                                                        Total DB Time
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {timeA !== null ? (
                                                            Helper.getTimeInEnglish(timeA)
                                                        ) : (
                                                            <React.Fragment>-</React.Fragment>
                                                        )}
                                                    </td>
                                                    <td className="text-end">
                                                        {timeB !== null ? (
                                                            Helper.getTimeInEnglish(timeB)
                                                        ) : (
                                                            <React.Fragment>-</React.Fragment>
                                                        )}
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {timeDifference !== null && (
                                                            timeDifference > 0 ? (
                                                                <span className="comparison"><i className="fal fa-long-arrow-up fa-fw increase"></i></span>
                                                            ) : (
                                                                timeA === timeB ? (
                                                                    <span className="comparison"><i className="fal fa-equals fa-fw equals"></i></span>
                                                                ) : (
                                                                    <span className="comparison"><i className="fal fa-long-arrow-up fa-fw decrease"></i></span>
                                                                )
                                                            )
                                                        )}
                                                        {timeDifference !== null ? (
                                                            <React.Fragment>
                                                                {Helper.getFormattedNumber(Helper.getPercentageDifference(timeB, timeA))}%
                                                                <span className="comparison">({Helper.getTimeInEnglish(timeDifference)})</span>
                                                            </React.Fragment>
                                                        ) : (
                                                            `-`
                                                        )}
                                                    </td>
                                                </tr>
                                                
                                                {/* Total Executions */}
                                                <tr>
                                                    <td>
                                                        Total Executions
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {((executionsA === null) ? '-' : Helper.getFormattedNumber(executionsA))}
                                                    </td>
                                                    <td className="text-end">
                                                        {((executionsB === null) ? '-' : Helper.getFormattedNumber(executionsB))}
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {executionsDifference !== null && (
                                                            executionsDifference > 0 ? (
                                                                <span className="comparison"><i className="fal fa-long-arrow-up fa-fw increase"></i></span>
                                                            ) : (
                                                                timeA === timeB ? (
                                                                    <span className="comparison"><i className="fal fa-equals fa-fw equals"></i></span>
                                                                ) : (
                                                                    <span className="comparison"><i className="fal fa-long-arrow-up fa-fw decrease"></i></span>
                                                                )
                                                            )
                                                        )}
                                                        {executionsDifference !== null ? (
                                                            executionsB === 0 || executionsA === 0 ? (
                                                                <React.Fragment>
                                                                    100%
                                                                    <span className="comparison">({Helper.getTimeInEnglish(executionsDifference)})</span>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {Helper.getPercentageDifference(executionsB, executionsA)}%
                                                                    <span className="comparison">({Helper.getFormattedNumber(executionsDifference)})</span>
                                                                </React.Fragment>
                                                            )
                                                        ) : (
                                                            `-`
                                                        )}
                                                    </td>
                                                </tr>
                                                
                                                {/* Average Time */}
                                                <tr>
                                                    <td>
                                                        Average Execution Time
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {((averageTimeA === null) ? '-' : Helper.getTimeInEnglish(averageTimeA))}
                                                    </td>
                                                    <td className="text-end">
                                                        {((averageTimeB === null) ? '-' : Helper.getTimeInEnglish(averageTimeB))}
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {averageTimeDifference !== null && (
                                                            averageTimeDifference > 0 ? (
                                                                <span className="comparison"><i className="fal fa-long-arrow-up fa-fw increase"></i></span>
                                                            ) : (
                                                                averageTimeDifference === 0 ? (
                                                                    <span className="comparison"><i className="fal fa-equals fa-fw equals"></i></span>
                                                                ) : (
                                                                    <span className="comparison"><i className="fal fa-long-arrow-up fa-fw decrease"></i></span>
                                                                )
                                                            )
                                                        )}
                                                        {averageTimeDifference === 0 ? (
                                                            <React.Fragment>
                                                                0%
                                                                <span className="comparison">({Helper.getTimeInEnglish(averageTimeDifference)})</span>
                                                            </React.Fragment>
                                                        ) : (
                                                            averageTimeDifference !== null ? (
                                                                averageTimeB === 0 || averageTimeA === 0 ? (
                                                                    <React.Fragment>
                                                                        100%
                                                                        <span className="comparison">({Helper.getTimeInEnglish(averageTimeDifference)})</span>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {Helper.getPercentageDifference(averageTimeB, averageTimeA)}%
                                                                        <span className="comparison">({Helper.getTimeInEnglish(averageTimeDifference)})</span>
                                                                    </React.Fragment>
                                                                )
                                                            ) : (
                                                                `-`
                                                            )
                                                        )}
                                                    </td>
                                                </tr>

                                                {(matchedHosts[0].length > 0 || matchedHosts[1].length > 0) && (
                                                    <React.Fragment>

                                                        {/* Host Average Processor */}
                                                        <tr>
                                                            <td>
                                                                Host Average CPU
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {matchedHosts[0].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[0].name} has ${Helper.getFormattedBadgeCount(matchedHosts[0].length)} associated host${((matchedHosts[0].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[0].length)}</span>
                                                                )}
                                                                {((processorA === null) ? '-' : `${processorA}%`)}
                                                            </td>
                                                            <td className="text-end">
                                                                {matchedHosts[1].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[1].name} has ${Helper.getFormattedBadgeCount(matchedHosts[1].length)} associated host${((matchedHosts[1].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[1].length)}</span>
                                                                )}
                                                                {((processorB === null) ? '-' : `${processorB}%`)}
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {processorA !== null && processorB !== null && (
                                                                    <DifferenceIcon valueA={processorA} valueB={processorB} />
                                                                )}
                                                                {(processorA !== null && processorB !== null) ? (
                                                                    processorA === 0 && processorB === 0 ? (
                                                                        <React.Fragment>
                                                                            0%
                                                                            <span className="comparison">({Helper.getFormattedNumber(processorB - processorA)})</span>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        processorA === 0 || processorB === 0 ? (
                                                                            <React.Fragment>
                                                                                100%
                                                                                <span className="comparison">({Helper.getFormattedNumber(processorB - processorA)})</span>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {Helper.getPercentageDifference(processorB, processorA)}%
                                                                                <span className="comparison">({Helper.getFormattedNumber(processorB - processorA)})</span>
                                                                            </React.Fragment>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    `-`
                                                                )}
                                                            </td>
                                                        </tr>

                                                        {/* Host Average Memory */}
                                                        <tr>
                                                            <td>
                                                                Host Average Memory
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {matchedHosts[0].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[0].name} has ${Helper.getFormattedBadgeCount(matchedHosts[0].length)} associated host${((matchedHosts[0].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[0].length)}</span>
                                                                )}
                                                                {((memoryA === null) ? '-' : `${memoryA}%`)}
                                                            </td>
                                                            <td className="text-end">
                                                                {matchedHosts[1].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[1].name} has ${Helper.getFormattedBadgeCount(matchedHosts[1].length)} associated host${((matchedHosts[1].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[1].length)}</span>
                                                                )}
                                                                {((memoryB === null) ? '-' : `${memoryB}%`)}
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {memoryA !== null && memoryB !== null && (
                                                                    <DifferenceIcon valueA={memoryA} valueB={memoryB} />
                                                                )}
                                                                {(memoryA !== null && memoryB !== null) ? (
                                                                    memoryA === 0 && memoryB === 0 ? (
                                                                        <React.Fragment>
                                                                            0%
                                                                            <span className="comparison">({Helper.getFormattedNumber(memoryB - memoryA)})</span>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        memoryA === 0 || memoryB === 0 ? (
                                                                            <React.Fragment>
                                                                                100%
                                                                                <span className="comparison">({Helper.getFormattedNumber(memoryB - memoryA)})</span>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {Helper.getPercentageDifference(memoryB, memoryA)}%
                                                                                <span className="comparison">({Helper.getFormattedNumber(memoryB - memoryA)})</span>
                                                                            </React.Fragment>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    `-`
                                                                )}
                                                            </td>
                                                        </tr>

                                                        {/* Host Average Disk Reads */}
														{null && (<>
                                                        <tr>
                                                            <td>
                                                                Host Average Disk Read Time
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {matchedHosts[0].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[0].name} has ${Helper.getFormattedBadgeCount(matchedHosts[0].length)} associated host${((matchedHosts[0].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[0].length)}</span>
                                                                )}
                                                                {((diskReadA === null) ? '-' : Helper.getTimeInEnglish(diskReadA))}
                                                            </td>
                                                            <td className="text-end">
                                                                {matchedHosts[1].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[1].name} has ${Helper.getFormattedBadgeCount(matchedHosts[1].length)} associated host${((matchedHosts[1].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[1].length)}</span>
                                                                )}
                                                                {((diskReadB === null) ? '-' : Helper.getTimeInEnglish(diskReadB))}
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {diskReadA !== null && diskReadB !== null && (
                                                                    <DifferenceIcon valueA={diskReadA} valueB={diskReadB} />
                                                                )}
                                                                {(diskReadA !== null && diskReadB !== null) ? (
                                                                    diskReadA === 0 && diskReadB === 0 ? (
                                                                        <React.Fragment>
                                                                            0%
                                                                            <span className="comparison">({Helper.getFormattedNumber(diskReadB - diskReadA)})</span>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        diskReadA === 0 || diskReadB === 0 ? (
                                                                            <React.Fragment>
                                                                                100%
                                                                                <span className="comparison">({Helper.getFormattedNumber(diskReadB - diskReadA)})</span>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {Helper.getPercentageDifference(diskReadB, diskReadA)}%
                                                                                <span className="comparison">({Helper.getFormattedNumber(diskReadB - diskReadA)})</span>
                                                                            </React.Fragment>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    `-`
                                                                )}
                                                            </td>
                                                        </tr>
														</>)}

                                                        {/* Host Average Disk Writes */}
														{null && (<>
                                                        <tr>
                                                            <td>
                                                                Host Average Disk Write Time
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {matchedHosts[0].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[0].name} has ${Helper.getFormattedBadgeCount(matchedHosts[0].length)} associated host${((matchedHosts[0].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[0].length)}</span>
                                                                )}
                                                                {((diskWriteA === null) ? '-' : Helper.getTimeInEnglish(diskWriteA))}
                                                            </td>
                                                            <td className="text-end">
                                                                {matchedHosts[1].length > 0 && (
                                                                    <span className="badge bg-info tip" data-tip={`${matchedInstances[1].name} has ${Helper.getFormattedBadgeCount(matchedHosts[1].length)} associated host${((matchedHosts[1].length > 1) ? 's' : '')}`}>{Helper.getFormattedBadgeCount(matchedHosts[1].length)}</span>
                                                                )}
                                                                {((diskWriteB === null) ? '-' : Helper.getTimeInEnglish(diskWriteB))}
                                                            </td>
                                                            <td className="text-end highlight">
                                                                {diskWriteA !== null && diskWriteB !== null && (
                                                                    <DifferenceIcon valueA={diskWriteA} valueB={diskWriteB} />
                                                                )}
                                                                {(diskWriteA !== null && diskWriteB !== null) ? (
                                                                    diskWriteA === 0 && diskWriteB === 0 ? (
                                                                        <React.Fragment>
                                                                            0%
                                                                            <span className="comparison">({Helper.getFormattedNumber(diskWriteB - diskWriteA)})</span>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        diskWriteA === 0 || diskWriteB === 0 ? (
                                                                            <React.Fragment>
                                                                                100%
                                                                                <span className="comparison">({Helper.getFormattedNumber(diskWriteB - diskWriteA)})</span>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {Helper.getPercentageDifference(diskWriteB, diskWriteA)}%
                                                                                <span className="comparison">({Helper.getFormattedNumber(diskWriteB - diskWriteA)})</span>
                                                                            </React.Fragment>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    `-`
                                                                )}
                                                            </td>
                                                        </tr>
														</>)}

                                                    </React.Fragment>
                                                )}

                                                {/* Detected Changes */}
                                                <tr>
                                                    <td>
                                                        Detected Changes
                                                        <sup><i className="fal fa-exchange fa-fw" aria-hidden="true"></i></sup>
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {Helper.getFormattedNumber(changeCountA)}
                                                    </td>
                                                    <td className="text-end">
                                                        {Helper.getFormattedNumber(changeCountB)}
                                                    </td>
                                                    <td className="text-end highlight">
                                                        <DifferenceIcon valueA={changeCountA} valueB={changeCountB} />
                                                        {changeCountDifference === 0 ? (
                                                            `0%`
                                                        ) : (
                                                            changeCountA === 0 || changeCountB === 0 || Helper.getPercentageDifference(changeCountB, changeCountA) === null ? (
                                                                `100%`
                                                            ) : (
                                                                `${Helper.getPercentageDifference(changeCountB, changeCountA)}%`
                                                            )
                                                        )}
                                                        <span className="comparison">({Helper.getFormattedNumber(changeCountB - changeCountA)})</span>
                                                    </td>
                                                </tr>

                                                {/* Regisered Events */}
                                                <tr>
                                                    <td>
                                                        Registered Events
                                                    </td>
                                                    <td className="text-end highlight">
                                                        {Helper.getFormattedNumber(eventCountA)}
                                                    </td>
                                                    <td className="text-end">
                                                        {Helper.getFormattedNumber(eventCountB)}
                                                    </td>
                                                    <td className="text-end highlight">
                                                        <DifferenceIcon valueA={eventCountA} valueB={eventCountB} />
                                                        {eventCountDifference === 0 ? (
                                                            `0%`
                                                        ) : (
                                                            eventCountA === 0 || eventCountB === 0 || Helper.getPercentageDifference(eventCountB, eventCountA) === null ? (
                                                                `100%`
                                                            ) : (
                                                                `${Helper.getPercentageDifference(eventCountB, eventCountA)}%`
                                                            )
                                                        )}
                                                        <span className="comparison">({Helper.getFormattedNumber(eventCountB - eventCountA)})</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* Top Charts */}
                                        <h3 className="mt-3">Comparison charts</h3>

                                        {timeA === null && timeB === null ? (
                                            <div className="w-100 text-center text-muted my-3">
                                                <i className="fal fa-ban fa-fw fa-2x"></i>
                                                <p>
                                                    No Instance Activity Found
                                                </p>
                                            </div>
                                        ) : (
                                            <React.Fragment>

                                                <LazyLoad offset={100} once={true}>
                                                    <WaitsChart period={period} chartCategories={chartCategories} plotBands={plotBands} plotLines={plotLines} series={waitsSeries} peak={waitsPeak} />
                                                </LazyLoad>

												<LazyLoad offset={100} once={true}>
													<StatementsChart period={period} chartCategories={chartCategories} plotBands={plotBands} plotLines={plotLines} series={statementsSeries} peak={statementsPeak} />
												</LazyLoad>

                                                {null && (matchedHosts[0].length > 0 || matchedHosts[1].length > 0) && (
                                                    <React.Fragment>
                                                        <LazyLoad offset={10} once={true}>
                                                            <HostsProcessorMemoryChart period={period} matchedHosts={matchedHosts} chartCategories={chartCategories} plotBands={plotBands} plotLines={plotLines} />
                                                        </LazyLoad>
                                                        <LazyLoad offset={10} once={true}>
                                                            <HostsDiskReadWritesChart period={period} matchedHosts={matchedHosts} chartCategories={chartCategories} plotBands={plotBands} plotLines={plotLines} />
                                                        </LazyLoad>
                                                    </React.Fragment>
                                                )}

                                                {/* Statements */}
												<Statements period={period} chartCategories={chartCategories} matchedInstances={matchedInstances} timeA={timeA} timeB={timeB} plotBands={plotBands} plotLines={plotLines} statements={statements} statementsA={statementsA} statementsB={statementsB} />

                                                {/* Change History */}
                                                <LazyLoad offset={100} once={true}>
                                                    <ComparisonChangesTable period={period} matchedInstances={matchedInstances} firstCombinedChanges={changesA} secondCombinedChanges={changesB} />
                                                </LazyLoad>

                                            </React.Fragment>
                                        )}
                                        
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

Results.propTypes = {
    toggleMenu: PropTypes.func.isRequired
};

export default Results;
