const Prompts = {

    getStatementTextPrompt(statement: string, executionPlan: string, instanceType: string): string {
        return `I have a sql statement for ${instanceType}: ${statement}, With execution plan: ${executionPlan}. How can I tune it?`
    },

    getInstanceWaitsPrompt(waits: string[], instanceType: string | undefined ): string {
        return `My ${instanceType} database is spending a lot of time in "${waits.join(', ')}". How can I reduce these?`;
    },

};

export default Prompts;
