// Third-parties.
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

// Types.
import Period from '../../types/Period';

function InstanaDatabaseButton(props: { period: Period, instanaServiceUrl: string }) {
        
	useEffect (() =>
	{
		ReactTooltip.rebuild ();
	}, []);

    return (
        <button type="button" className="btn btn-xsm btn-outline-secondary float-end" data-tip="View this database in Instana" onClick={() => window.open(`${props.instanaServiceUrl}?timeline.to=${props.period.ui.current.to.valueOf()}&timeline.fm=${props.period.ui.current.from.valueOf()}&timeline.ws=${props.period.ui.current.to.diff(props.period.ui.current.from, 'millisecond')}`, "_blank")}><i className="fak fa-stan fa-fw" aria-hidden="true"></i>Instana</button>
    );
}

export default InstanaDatabaseButton;
