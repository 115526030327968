// Api.
import api from '../Base';

// Types.
import HostTarget from '../../types/host/HostTarget';
import HostInstances from "../../types/host/HostInstances";

const HostApi = {

    getHosts:async () => {

        let url = `host?sort=2+asc`;

        let instances: HostTarget[] = [];

        await api.get<HostTarget[]>(url)
            .then((response) => {
                instances = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve hosts.', error);
            })
            .then(function () {});

        return instances;
    },
    
    getHost:(hostId: number) => {

        let url = `host?sort=2+asc&id=${hostId}`;

        api.get<HostTarget[]>(url)
            .then((response) => {
                return response.data[0];
            })
            .catch((error: any) => {
                console.error('Failed to retrieve host.', error);
            })
            .then(function () {});
    },

    getHostInstances:async (id: number) => {

        let url = `datasource/host?hostid=${id}`;

        let instances: HostInstances[] = [];

        await api.get<HostInstances[]>(url)
            .then((response) => {
                instances = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve hosts.', error);
            })
            .then(function () {});

        return instances;
    },
}

export default HostApi;