import {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import Highcharts from 'highcharts';

// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import Period from '../../types/Period';
import EventType from '../../types/instance/EventType';

// Constants.;
import {DATA_INITIALISING} from '../../component/Constants';

// Components.
import PeriodBreadcrumb from '../../component/PeriodBreadcrumb';
import Breadcrumb from "../../container/breadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import PageContent from "../../container/page_content";
import EventHistoryContainer from "../../container/event-history";

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IChangeHistoryProps {
    period: Period,
    instances: InstanceTarget[],
    hosts: HostTarget[],
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function
}

class EventHistory extends Component<IChangeHistoryProps> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IChangeHistoryProps) {
        super(props);
        this.state = {
            loading: DATA_INITIALISING
        };
    };

    render() {
        return (
            <PageContent title={"Event History"} description={"See auto-detected events and registered custom change events against your monitored DB instances and hosts"}>

                {/* Heading */}
                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Event History">
                        <Link to="/event-history">Analysis</Link>
                        <NavLink to="/event-history" activeClassName={"current-breadcrumb-link"}>Event History</NavLink>
                        <PeriodBreadcrumb />
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <EventHistoryContainer {...this.props} />
            </PageContent>
        );
    }
}

EventHistory.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default EventHistory;
