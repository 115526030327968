import {useMemo} from "react";
import PropTypes from 'prop-types';

// Types.
import Period from "../types/Period";
import FilterOption from "../types/instance/FilterOption";
import {FilterType} from "../typescript/Enums";

function FilterBar(props: { period: Period, setFilterOptions: Function }) {
    const {setFilterOptions, period} = props

    const filters = useMemo(() => {
        function removeAllFilterOptions() {

            const resetFilters: FilterOption[] = [{
                filterType: FilterType.Waits,
                filters: []
            }, {
                filterType: FilterType.Databases,
                filters: []
            }, {
                filterType: FilterType.Sessions,
                filters: []
            }, {
                filterType: FilterType.Clients,
                filters: []
            }, {
                filterType: FilterType.Users,
                filters: []
            }, {
                filterType: FilterType.Programs,
                filters: []
            }];

            setFilterOptions(resetFilters);
        }

        function removeFilterOption(typeId: number, filter: string) {

            // Copy the current set of filters.
            let updatedOptions: FilterOption[] = period.filters.options;

            // Remove the clicked filter option.
            let matchedOption = updatedOptions.filter(filter => filter.filterType === typeId);

            if (matchedOption.length > 0) {

                // Get the clicked filter option.
                matchedOption[0].filters = matchedOption[0].filters.filter(item => item !== filter);

                // Update the filter options and set the local state.
                const updatedFilterOptions = period.filters.options.map(option =>
                    option.filterType === typeId
                        ? {...option, filters: matchedOption[0].filters}
                        : option
                );

                setFilterOptions(updatedFilterOptions);
            }
        }

        function filterName(filterType: FilterType): string {
            if (filterType === FilterType.Databases) {
                return "Database/Schema";
            }

            // Derive singular filter name from FilterType.
            // Only need to handle the simple case.
            const plural: string = FilterType[filterType];
            return plural.substring(0, plural.length - 1);
        }

        const filteredOptions = period.filters.options.filter(option => option.filters.length > 0);

        return (
            <div className={((filteredOptions.length === 0) ? 'd-none' : 'col filters')}>
                <button type="button" className="btn btn-sm btn btn-primary disabled" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters"><i
                    className="fas fa-filter fa-fw"></i><span>Filters: On</span></button>
                {period.filters.options.map((option: FilterOption) => (
                    option.filters.map(filter => (
                        <button key={option.filterType + filter} type="button" className="btn btn-sm btn-light filter-button" onClick={() => removeFilterOption(option.filterType, filter)}>
                            {filterName(option.filterType)}: {filter} <span className="badge filter-button-icon"><i className="fal fa-times"></i></span>
                        </button>
                    ))
                ))}
                <button type="button" className="btn btn-sm btn btn-link filter-clear" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters"
                        onClick={() => removeAllFilterOptions()}><i className="far fa-undo"></i><span>Clear</span></button>
            </div>
        );
    }, [period, setFilterOptions]);

    return (
        filters
    );
}

FilterBar.propTypes = {
    setFilterOptions: PropTypes.func.isRequired
};

export default FilterBar;
