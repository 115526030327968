import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Api.
import EventsApi from '../../../api/instance/EventsApi';

// Types.
import Period from '../../../types/Period';
import EventType from '../../../types/instance/EventType';
import Event from '../../../types/instance/Event';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED } from '../../../component/Constants';
import { ACTIONS } from '../../../component/Constants';

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IEventTypesProps {
    period: Period,
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function
}

interface IEventTypesState {
    loading: number,
    events: Event[]
}

const initialState: IEventTypesState = {
    loading: DATA_INITIALISING,
    events: []
};

class EventTypes extends Component<IEventTypesProps, IEventTypesState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IEventTypesProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    async getData() {
        const { period } = this.props;

        // Get all events for all time.
        const events = await EventsApi.getAllEvents(period);

        this.setState({
            loading: DATA_LOADED,
            events
        });
    }

    /* Action Methods */

    refresh = () => {
        
        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { eventTypes } = this.props;
        const { loading, events } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>Event Types - Settings - DBmarlin</title>
                    <meta name="description" content="Manage custom event types" />
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Event Types">
                        <Link to="/admin">Settings</Link>
                        <span>Event Types</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/event-types/create" render={() => <Detail actionId={ACTIONS.CREATE.type} />} />
                        <Route exact path="/admin/event-types/:actionId/:eventTypeId" render={(props) => <Detail {...props} actionId={props.match.params.actionId} eventTypeId={Number(props.match.params.eventTypeId)} />} />
                        <Route exact path="/admin/event-types/" render={(props) => <List {...props} loading={loading} eventTypes={eventTypes} events={events} refresh={this.refresh} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

EventTypes.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default EventTypes;
