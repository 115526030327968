import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Helper.
import { getDefaultPageSize } from "../../../helpers/utils";

// Third-parties.
import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import Skeleton from "react-loading-skeleton";

import { CSVLink } from 'react-csv';

// Context.
import { HostContext } from '../../../context/HostContext';

// Types.
import StatusIcon from '../../StatusIcon';
import HostType from '../HostType';
import HostsDataTable from '../../../types/host/tables/HostsDataTable';

// Components.
import AverageStatistic from "../widget/AverageStatistic";
import PeakStatistic from "../widget/PeakStatistic";
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';

// Constants.
import { DATA_LOADING } from "../../Constants";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

function HostsTable(props: { loading: number, hostsDataTable: HostsDataTable[] }) {
    const { hosts } = useContext(HostContext);
    const [tableFilter, setTableFilter] = useState<string>('');
    const timeRangeContext = useContext(TimeRangeContext)

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = useMemo(() => props.hostsDataTable.filter(row => (row.name.toLowerCase().includes(tableFilter.toLowerCase()) || row.type.toLowerCase().includes(tableFilter.toLowerCase()))), [props.hostsDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            Header: 'Host',
            accessor: 'name',
            className: 'fw-bold col-width-40',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <StatusIcon mode="public" hostId={cell.row.values.id} />
                    <Link to={`/hosts/${cell.row.values.id}?${timeRangeContext.getTimeRangeRedirectString()}`}>{cell.row.values.name}</Link>
                </React.Fragment>
            )
        },
        {
            Header: '',
            accessor: 'type',
            className: 'text-center col-width-5',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <HostType host={hosts.filter(host => Number(host.id) === Number(cell.row.values.id))[0]} />
                </React.Fragment>
            ) 
        },
        {
            Header: 'Instances',
            accessor: 'instances',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.instances !== null ? (
                    cell.row.values.instances.length ? <Link to={`/hosts/${cell.row.values.id}/instances`}>{ cell.row.values.instances.length}</Link> : 0

                ) : (
                    <Skeleton />
                )
            )
        },
        {
            Header: 'CPU Average',
            accessor: 'processor',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.processor !== null ? (
                    <AverageStatistic hostId={cell.row.values.id} statistic="cpuutilisation" metric={cell.row.values.processor} />
                ) : (
                    <Skeleton />
                )
            )
        },
        {
            Header: 'CPU Peak',
            accessor: 'processorPeak',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.processorPeak !== null ? (
                    <PeakStatistic hostId={cell.row.values.id} statistic="cpuutilisation" metric={cell.row.values.processorPeak} />
                ) : (
                    <Skeleton />
                )
            )
        },
        {
            Header: 'Memory Average',
            accessor: 'memory',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.memory !== null ? (
                    <AverageStatistic hostId={cell.row.values.id} statistic="memoryutilisation" metric={cell.row.values.memory} />
                ) : (
                    <Skeleton />
                )
            )
        },
        {
            Header: 'Disk I/O',
            accessor: 'ioutilisation',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.ioutilisation !== null ? (
                    <PeakStatistic hostId={cell.row.values.id} statistic="ioutilisation" metric={cell.row.values.ioutilisation} />
                ) : (
                    <Skeleton />
                )
            )
        },
    ], [hosts]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        initialState: { hiddenColumns: ['id'], pageIndex: 0, pageSize: getDefaultPageSize(), sortBy: [{ id: 'name', desc: false }] }
    },
        useSortBy, usePagination);

    return (
        <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2 table-search">
                <div className="col col-md-9">                
                    <CSVLink role="button"
                        headers={[ { label: 'ID', key: 'id' }, { label: 'Name', key: 'name' }, { label: 'Type', key: 'type' }, { label: 'Avg Processor', key: 'processor' }, { label: 'Peak Processor', key: 'processorPeak' }, { label: 'IO Utilisation', key: 'ioutilisation' } ]}
                        data={data}
                        download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Hosts.csv`}
                        className="btn btn-sm btn-primary">
                        <i className="fal fa-file-export fa-fw" /><span>Export</span>
                    </CSVLink>
                    <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i className="far fa-undo" /><span>Clear</span></button>
                </div>
                <div className="col col-md-3">
                    <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)} />
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={(column as any).className}>
                                        {column.render('Header')}
                                        {column.canSort ? (column.isSorted ? (column.isSortedDesc ? <i className="fal fa-sort-amount-up-alt" /> : <i className="fal fa-sort-amount-down-alt" />) : <i className="fal fa-sort-amount-down-alt text-light" />) : ''}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps ()} className={(cell.column as any).className}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
				<NoData
					error={null}
					loading={props.loading === DATA_LOADING}
					length={data.length}
				/>
				<TablePagination
					length={data.length}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageOptions={pageOptions}
					pageCount={pageCount}
					canPreviousPage={canPreviousPage}
					previousPage={previousPage}
					canNextPage={canNextPage}
					nextPage={nextPage}
					gotoPage={gotoPage}
					pageIndex={pageIndex}
				/>
            </div>
        </React.Fragment>
    );
}

export default HostsTable;