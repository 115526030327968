import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Third-party packages.
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import { CSVLink } from 'react-csv';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import EventType from '../../../types/instance/EventType';
import Event from '../../../types/instance/Event';

// Constants.
import { DATA_FAILED, DATA_EMPTY, DATA_LOADING } from '../../../component/Constants';

// Components.
import NoData from '../../../component/NoData';
import TablePagination from '../../../component/TablePagination';
import { getDefaultPageSize } from "../../../helpers/utils";
import SortClass from "../../../component/SortClass";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(relativeTime.default);

function List(props: { loading: number, eventTypes: EventType[], events: Event[], refresh: Function }) {
    const [tableFilter, setTableFilter] = useState<string>('');

    function refresh() {
        props.refresh();
    }

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data: EventType[] = useMemo(() => props.eventTypes.filter(eventType => (eventType.title.toLowerCase().includes(tableFilter.toLowerCase()))), [props.eventTypes, tableFilter]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'eventTypeId',
            disableSortBy: true
        },
        {
            accessor: 'htmlIconCode',
            disableSortBy: true
        },
        {
            Header: 'Event Type',
            accessor: 'title',
            className: 'fw-bold',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    {cell.row.values.title}
                    <span className="badge bg-info" data-tip="Total custom events for this event type">{Helper.getFormattedBadgeCount(props.events.filter(event => event.eventTypeId === cell.row.values.eventTypeId).length)}</span>
                    {cell.row.values.htmlIconCode && (
                        <i className={`${cell.row.values.htmlIconCode} ms-2 text-secondary`} aria-hidden="true"></i>
                    )}
                </React.Fragment>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            className: 'actions',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <Link to={`/admin/event-types/edit/${cell.row.values.eventTypeId}`} role="button" className="btn btn-sm btn-primary">
                        <i className="fal fa-edit fa-fw"></i><span>Edit</span>
                    </Link>
                    <Link to={`/admin/event-types/delete/${cell.row.values.eventTypeId}`} role="button" className="btn btn-sm btn-danger">
                        <i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
                    </Link>
                </React.Fragment>
            )
        }
    ], [props.events]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        initialState: { hiddenColumns: ['eventTypeId', 'htmlIconCode'], pageIndex: 0, pageSize: getDefaultPageSize(), sortBy: [{ id: 'title', desc: false }] }
    },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-object-ungroup fa-fw" aria-hidden="true"></i>
                    Event types
                    <span className="badge bg-info" data-tip="Total event type count">{props.eventTypes.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseHosts" aria-expanded="false" aria-controls="collapseHosts"></i>
                </div>
                <div id="collapseHosts" className="card-body collapse show">
                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"></i>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>No Event Types Found - Click to Refresh</button>
                            </p>
                        </div>
                    ) : (
                        props.loading === DATA_FAILED ? (
                            <div className="w-100 text-center text-muted my-3">
                                <i className="fal fa-ban fa-fw fa-2x text-danger"></i>
                                <p>
                                    <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve Event Types - Click to Refresh</button>
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row row-cols-1 row-cols-md-2 table-search">
                                    <div className="col col-md-9">
                                        <Link to="/admin/event-types/create" role="button" className="btn btn-sm btn-success">
                                            <i className="fal fa-plus-square fa-fw"></i><span>Create</span>
                                        </Link>
                                        <CSVLink role="button"
                                            headers={[ { label: 'ID', key: 'eventTypeId' }, { label: 'Event Type', key: 'title' }, { label: 'Icon Code', key: 'htmlIconCode' } ]}
                                            data={data}
                                            download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Event Types.csv`}
                                            className="btn btn-sm btn-primary">
                                            <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                                        </CSVLink>
                                        <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i className="far fa-undo"></i><span>Clear</span></button>
                                    </div>
                                    <div className="col col-md-3">
                                        <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table">
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={(column as any).className}>
                                                            {column.render('Header')}
                                                            <SortClass column={column} />
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps ()} className={(cell.column as any).className}>
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
									<NoData
										error={null}
										loading={props.loading === DATA_LOADING}
										length={data.length}
									/>
									<TablePagination
										length={data.length}
										pageSize={pageSize}
										setPageSize={setPageSize}
										pageOptions={pageOptions}
										pageCount={pageCount}
										canPreviousPage={canPreviousPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										nextPage={nextPage}
										gotoPage={gotoPage}
										pageIndex={pageIndex}
									/>
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
