import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, NavLink } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

// Helpers.
import Helper from "../../helpers/Helper";

// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import Period from '../../types/Period';
import EventType from '../../types/instance/EventType';
import HostTarget from '../../types/host/HostTarget';
import ExecutionPlan from '../../types/instance/ExecutionPlan';
import ChartPlotLine from "../../types/ChartPlotLine";

// Components.
import PeriodBreadcrumb from '../../component/PeriodBreadcrumb';
import Breadcrumb from "../../container/breadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import TimeShift from "../../component/TimeShift";
import FilterBar from "../../component/FilterBar";
import TabbedMenu from "../../container/tabbed-menu";
import TextAndPlan from "./tabs/TextAndPlan";
import StatementActivity from "./tabs/Activity";
import SqlStatistics from "./tabs/SQLStatistics";
import Batches from "../../component/instance/Tabs/Batches";
import { INSTANCE_TYPE_SQLSERVER } from "../../component/Constants";

interface IStatementDetailProps {
    instanceId: number,
    batchId?: string,
    batchStatementId?: string,
    statementId?: string,
    instances: InstanceTarget[],
    period: Period,
    hosts: HostTarget[],
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}

const SQLStatisticStatement = (props: IStatementDetailProps) => {
    const {instanceId, batchId, statementId, instances, period, batchStatementId} = props;
    const getInstance: InstanceTarget[] = instances.filter(instance => instance.id === Number(instanceId))
    const instance = getInstance[0]
    const [chartPlanPlotLines, setChartPlanPlotLines] = useState<ChartPlotLine[]>([]);

    if (instance === undefined) {
        return (
            <React.Fragment>No Instance</React.Fragment>
        )
    }

    const setExecutionPlanOptions = (executionPlans: ExecutionPlan[]) => {
        setChartPlanPlotLines(Helper.getChartPlotLinesForExecutionPlans(period, executionPlans, period.ui.current.chartCategories))

    }

    const shouldShowTab = (value: string): boolean => {
        const currentInstance: any = instance
        return currentInstance !== undefined && (!!(currentInstance[`has${value}`] && currentInstance[`collect${value}`]))
    }

    return (
        <div id="content">
            <Helmet>
                <title>{instance.name} - Statement View - DBmarlin</title>
                <meta name="description"
                      content="Instance statement performance view, showing key performance metrics and detailed executed statement and waits information"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb instanceId={instance.id}
                            type="instances"
                            heading={instance.name}>
                    <Link to="/instances">Analysis</Link>
                    <Link to="/instances">Instances</Link>
                    <Link to={`/instances/${instanceId}/activity`}>{instance.name}</Link>
                    <Link
                        to={`/instances/${instanceId}/sql-statistics`}>SQL Statistics</Link>

                    <NavLink to={`/instances/`}
                             className="current-breadcrumb-link pe-none">Statement {statementId}</NavLink>
                    <PeriodBreadcrumb/>
                </Breadcrumb>

                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={() => {
                }}
                               applyPeriod={props.applyPeriod}/>
            </div>


            <div className="loader"/>
            <TabbedMenu>
                <TimeShift/>
                <Switch>
                    {/*Statement and Plans*/}
                    <Route
                        path={`/instances/:instanceId/sql-statistics/statement/:id`}
                        render={({match}) => {
                            return (
                                <NavLink
                                    exact
                                    to={`/instances/${match.params.instanceId}/sql-statistics/statement/${match.params.id}/text`}
                                    activeClassName="active"
                                >
                                    Statement
                                </NavLink>
                            );
                        }}
                    />
                </Switch>

                {/*Activity*/}
                <Switch>
                    <Route
                        path={`/instances/:instanceId/sql-statistics/statement/:id`}
                        render={({match}) => {
                            return (
                                <NavLink
                                    exact
                                    to={`/instances/${match.params.instanceId}/sql-statistics/statement/${match.params.id}`}
                                    activeClassName="active"
                                >
                                    Statement Activity
                                </NavLink>
                            );
                        }}
                    />
                </Switch>

                <ConditionalRender if={shouldShowTab('sqlstatistics')}>
                    <Route path="/instances/:instanceId/sql-statistics/statement/:statementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/sql-statistics/statement/${statementId}/sql-statistics`}
                                                  activeClassName="active">SQL Statistics</NavLink>}/>
                </ConditionalRender>

                <ConditionalRender if={instance?.type === INSTANCE_TYPE_SQLSERVER}>
                    <Route path="/instances/:instanceId/sql-statistics/statement/:statementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/sql-statistics/statement/${statementId}/batches`}
                                                  activeClassName="active">Calling Batches</NavLink>}/>
                </ConditionalRender>
            </TabbedMenu>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions &&
                    <FilterBar period={props.period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            <Switch>
                <Route
                    exact
                    path={[
                        "/instances/:instanceId/sql-statistics/statement/:statementId/text",
                    ]}
                    render={() => <TextAndPlan
                        setExecutionPlanOptions={setExecutionPlanOptions}
                        batchId={batchId}
                        batchStatementId={batchStatementId}
                        instance={instance}
                        statementId={statementId}
                    />}
                />

                <Route
                    exact
                    path={[
                        "/instances/:instanceId/sql-statistics/statement/:statementId",
                    ]}
                    render={() => <StatementActivity eventTypes={props.eventTypes}
                                                     instance={instance}
                                                     batchId={batchId}
                                                     applyPeriod={props.applyPeriod}
                                                     batchStatementId={batchStatementId}
                                                     period={period}
                                                     chartPlanPlotLines={chartPlanPlotLines}
                                                     statementId={statementId}
                                                     setFilterOptions={props.setFilterOptions}
                                                     filterParameters={period.filters.parameters}
                        // chartPlanPlotLines={this.state.chartPlanPlotLines}
                        // statementTimePrevious={this.state.statementTimePrevious}/>}
                    />}
                />

                <Route exact path={[
                    "/instances/:instanceId/sql-statistics/statement/:statementId/sql-statistics",
                ]}
                       render={() => <SqlStatistics instance={instance}
                                                    filterParameters={period.filters.parameters}
                                                    applyPeriod={props.applyPeriod}
                                                    batchStatementId={batchStatementId}
                                                    statementId={statementId || '0'}
                       />}/>


                <Route exact path="/instances/:instanceId/sql-statistics/statement/:statementId/batches"
                       render={() => <Batches filtersParameters={period.filters.parameters}
                                              instanceId={instance.id}
                                              statementId={statementId} showByDefault={true}
                                              isBatchesForStatement={true}
                       />}/>

            </Switch>
        </div>
    )
}

SQLStatisticStatement.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default SQLStatisticStatement;