// React.
import { createContext } from 'react';

// Types.
import Filter from '../types/Filter';

// Define the context type, including the update function.
export type FilterContextType =
{
    filters: Filter[],
    setFilters: (filters: Filter[]) => void
}

// Define the default context object.
export const FilterContext = createContext<FilterContextType>
({
	filters: [],
	setFilters: filters => console.warn ('Filters not set', filters)
});

FilterContext.displayName = 'Filters';
