import { Component } from 'react';
import PropTypes from 'prop-types';
import {Link, Switch, Route} from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import InstancesAdminDataTable from '../../../types/instance/tables/InstancesAdminDataTable';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED, ACTIONS } from '../../../component/Constants';

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IInstancesProps {
    period: Period,
    instances: InstanceTarget[],
    toggleMenu: Function,
    applyPeriod: Function
}

interface IInstancesState {
    loading: number,
    instance: InstanceTarget,
    instances: InstanceTarget[],
    instancesAdminDataTable: InstancesAdminDataTable[]
}

const initialState: IInstancesState = {
    loading: DATA_INITIALISING,
    instances: [],
    instance: {
        id: 0,
        color: '',
        name: '',
        agentid: 0,
        type: '',
        driver: '',
        url: '',
		parameters: '',
        username: '',
        password: '',
        validation: '',
        monitor: false,
        attempt: 0,
        debug: false,
		monitoringintervalmilliseconds: 0,
		batchintervalminutes: 0,
        batchintervalseconds: 60,
		detectchanges: true,
		collectsqlstatistics: false,
        reason: '',
        host: '',
        port: 0,
        database: '',
        server: '',
        useurl: false,
        version: '',
        pg_stat_statements: false,
        use_pg_stat_statements: false,
        sensorstatus: 0,
        message: '',
        updated: new Date(),
        timedout: false,
        state: '',
        status: 0
    },
    instancesAdminDataTable: []
};

class Instances extends Component<IInstancesProps, IInstancesState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IInstancesProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    componentDidUpdate() {

        if (this.props.instances !== this.state.instances) {

            // Refresh the data.
            this.getData();
        }
    }

    async getData() {
        const { instances } = this.props;

        let instancesAdminDataTable: InstancesAdminDataTable[] = [];        

        for (let index = 0; index < instances.length; index++) {

            instancesAdminDataTable.push({
                id: instances[index].id,
                name: instances[index].name,
                hostAddress: instances[index].host,
                updated: instances[index].updated,
                type: instances[index].type,
                instance: instances[index]
            });
        }

        this.setState({
            loading: DATA_LOADED,
            instances,
            instancesAdminDataTable
        });
    }

    /* Action Methods */

    refresh = () => {
        
        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, instancesAdminDataTable } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>Instances - Settings - DBmarlin</title>
                    <meta name="description" content="Manage monitored instances" />
                </Helmet>

                {/* Heading */}
                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Database Instances">
                        <Link to="/admin">Settings</Link>
                        <span>Database Instances</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/instances/create" render={() => <Detail actionId={ACTIONS.CREATE.type} />} />
                        <Route exact path="/admin/instances/:actionId/:instanceId" render={(props) => <Detail {...props} actionId={props.match.params.actionId} instanceId={Number(props.match.params.instanceId)} />} />
                        <Route exact path="/admin/instances/" render={(props) => <List {...props} loading={loading} instancesAdminDataTable={instancesAdminDataTable} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

Instances.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Instances;
