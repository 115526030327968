// React.
import { useEffect, useState } from 'react';

// Third-parties.
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { TreeTable, TreeTableExpandedKeysType,
	TreeTableSelectionKeys } from 'primereact/treetable';

// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

// Helpers.
import { Step, formatNumber, getStepDetails } from '../../../helpers/utils';

// Components.
import KnowledgeBasePlanButton from '../../buttons/KnowledgeBasePlanButton';

export default function Db2Plan (props: { executionPlan: ExecutionPlan })
{
	const [treeSteps, setTreeSteps] = useState<Step[]> ([]);
	const [expandedKeys, setExpandedKeys] =
		useState<TreeTableExpandedKeysType> ({});
	const [selectedStepKey, setSelectedStepKey] =
		useState<TreeTableSelectionKeys> (null);
	const [stepDetail, setStepDetails] = useState<any[]> ([]);
	const [stepOperation, setStepOperation] = useState<string> ('');

	function getStepOperation (details: any[]): string
	{
		const detail: any[] =
			details.filter (detail => detail.name === 'operator_type');
		return detail.length === 1 ? detail[0].value : '';
	}

	useEffect (() =>
	{
		// These closure variables are required by the recursive functions.
		let n: number = 0;
		const expanded: TreeTableExpandedKeysType = {};

		function getKey (): string
		{
			return String (++n);
		}

		function logMissedField (field: string, type: string): void
		{
			console.log ('Missed ' + field + ': ' + type);
		}

		function getData (index: number): any
		{
			const data: any = {};
			const step: any = steps[index];

			for (const field in step)
			{
				const type: string = typeof (step[field]);

				if (type === 'string' || type === 'boolean')
				{
					data[field] = String (step[field]);
				}
				else if (type === 'number')
				{
					data[field] = formatNumber (step[field]);
				}
				else
				{
					logMissedField (field, type);
				}
			}

			return data;
		}

		function getChildren (index: number): Step[]
		{
			const children: Step[] = [];
			const parent: number = steps[index]['operator_id'];

			for (let i = index + 1; i < steps.length; i++)
			{
				if (steps[i]['target_id'] === parent)
				{
					children.push (getStep (i));
				}
			}

			return children;
		}

		function getStep (index: number): Step
		{
			const key: string = getKey ();

			// Expand all rows as a side-effect.
			expanded[key] = true;

			const step: Step =
			{
				key: key,
				data: getData (index),
				children: getChildren (index)
			};

			return step;
		}

		// The array steps is used by the recursive functions.
		// This is not the most efficient of algorithms - O(n2).
		const steps: any[] = JSON.parse (props.executionPlan.sqlplan);
		// console.log ('steps', steps);
		const tree: Step[] = [];
		tree.push (getStep (0));
		const details: any[] = getStepDetails (tree, '1');
		setTreeSteps (tree);
		setExpandedKeys (expanded);
		setSelectedStepKey ('1');
		setStepDetails (getStepDetails (tree, '1'));
		setStepOperation (getStepOperation (details));
	}, [props.executionPlan.sqlplan]);

	useEffect (() =>
	{
		const details: any[] = getStepDetails (treeSteps, selectedStepKey);
		const operation: string = getStepOperation (details);
		setStepDetails (details);
		setStepOperation (operation);
	}, [treeSteps, selectedStepKey]);

	// The bracket must be present and on the same line as the return.
	return (<>
		<Splitter>
			<SplitterPanel size={65} className="plan-master-panel">
				<TreeTable
					value={treeSteps}
					expandedKeys={expandedKeys}
					onToggle={e => setExpandedKeys (e.value)}
					selectionMode="single"
					resizableColumns
					reorderableColumns
					selectionKeys={selectedStepKey}
					onSelectionChange={e => setSelectedStepKey (e.value)}
					className="treetable-responsive"
				>
					<Column header="Operation" field="operator_type" expander
						className="col-width-45 plan-first-column"/>
					<Column header="Object" field="object_name"
						className="col-width-30"/>
					<Column header="Cost" field="total_cost"
						className="col-width-25 text-end"/>
				</TreeTable>
			</SplitterPanel>
			<SplitterPanel size={35} className="plan-detail-panel">
				<DataTable value={stepDetail} resizableColumns stripedRows>
					<Column header="Name" field="name"
						className="col-width-50 plan-first-column"/>
					<Column header="Value" field="value"
						className="col-width-50"/>
				</DataTable>
				{
				<KnowledgeBasePlanButton databaseType="db2"
					planOperation={stepOperation}
					planOption=""/>
				}
			</SplitterPanel>
		</Splitter>
	</>)
}
