import ConditionalRender from "../helpers/ConditionalRender";

export default function SqlIdentifiers(props: {
    sqlhash: string,
    sqlid: string
}) {
    let {sqlhash, sqlid} = props;

    // sqlid may be a comma-separated list. Just display the first one.
    // const pos: number = sqlid ? sqlid.indexOf(',') : -1;
    //
    // if (pos !== -1) {
    //     sqlid = sqlid.substring(0, pos);
    // }

    return (
        <>
            <b>#</b> {sqlhash}
            {sqlhash !== sqlid && (
                <ConditionalRender if={sqlid}>
                    <b>, ID</b> {sqlid}
                </ConditionalRender>
            )}
        </>
    )
}
