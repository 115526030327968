import PropTypes from 'prop-types';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';

// Components.
import DatabasesTable from '../table/DatabasesTable';

function Databases(props: { instance: InstanceTarget, batchId?: string, statementId?: string, period: Period, totalInstanceTime: number, setDatabasesOptions: Function, setFilterOptions: Function }) {
    return (
        <div id="databases" className="tab-pane fade" role="tabpanel" aria-labelledby="databases-tab">
            <DatabasesTable period={props.period} instance={props.instance} batchId={props.batchId} statementId={props.statementId} instanceTime={props.totalInstanceTime} setDatabasesOptions={props.setDatabasesOptions} setFilterOptions={props.setFilterOptions} />
        </div>
    );
}

Databases.propTypes = {
    setDatabasesOptions: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Databases;