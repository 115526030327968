import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-party packages.
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

import { CSVLink } from 'react-csv';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

// Context.
import { EventTypeContext } from '../../../context/EventTypeContext';

// Types.
import InstancesAdminDataTable from '../../../types/instance/tables/InstancesAdminDataTable';

// Constants.
import {  DATA_LOADING } from '../../../component/Constants';

// Components.
import StatusIcon from '../../../component/StatusIcon';
import InstanceType from '../../../component/instance/InstanceType';
import ActionButtons from '../../../component/buttons/ActionButtons';
import NoData from '../../../component/NoData';
import TablePagination from '../../../component/TablePagination';
import { getDefaultPageSize } from "../../../helpers/utils";
import SortClass from "../../../component/SortClass";
import api from "../../../api/Base";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(relativeTime.default);
dayjs.extend(customParseFormat.default);

function List(props: { loading: number, instancesAdminDataTable: InstancesAdminDataTable[] }) {
    const {eventTypes} = useContext(EventTypeContext);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [agents, setAgents] = useState<any[]>([]);
    const [instancesList, setInstancesList] = useState<InstancesAdminDataTable[]>([]);

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    useEffect(() => {
        setInstancesList(props.instancesAdminDataTable)
    }, [props.instancesAdminDataTable]);

    const data = useMemo(() => instancesList.filter(row => (row.name.toLowerCase().includes(tableFilter.toLowerCase())) || (row.hostAddress.toLowerCase().includes(tableFilter.toLowerCase()))), [instancesList, tableFilter]);

    useEffect(() => {

        const getAgents = async() => {
            const response = await api.get(`agent `) || []
            if (response) {

                setAgents(response.data)
            }
        }
        void getAgents();
    }, []);

    const removeInstance = (instanceId: number) => {
        const updatedInstancesList = instancesList.filter(instance => instance.id !== instanceId);
        setInstancesList(updatedInstancesList);
    }

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            accessor: 'instance',
            disableSortBy: true
        },
        {
            Header: 'Instance',
            accessor: 'name',
            className: 'fw-bold',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({cell}) => (
                <React.Fragment>
                    <StatusIcon instanceId={cell.row.values.id} removeFromList={removeInstance} mode="admin"/>
                    {cell.row.values.name}
                </React.Fragment>
            )
        },
        {
            Header: '',
            accessor: 'type',
            headerClassName: 'text-center',
            className: 'text-center',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({cell}) => (
                <React.Fragment>
                    <InstanceType instance={cell.row.values.instance}/>
                </React.Fragment>
            )
        },
        {
            Header: 'Host Address',
            accessor: 'hostAddress',
            className: 'concatenate',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({cell}) => (
                cell.row.values.hostAddress
            )
        },
        {
            Header: 'Agent',
            accessor: 'agent',
            sortType: (rowA: any, rowB: any) => {
                return (rowA.values.instance.agentid - rowB.values.instance.agentid)
            },
            Cell: ({cell}) => {
                const getAgent = agents.find(agent => agent.id === cell.row.values.instance.agentid)
                return getAgent ? getAgent.name : ''
            }
        },
        {
            Header: 'Agent Version',
            accessor: 'version',
            sortType: (rowA: any, rowB: any) => {
                return (rowA.values.instance.agentid - rowB.values.instance.agentid)
            },
            Cell: ({cell}) => {
                const getAgent = agents.find(agent => agent.id === cell.row.values.instance.agentid)
                return getAgent?.version ? getAgent.version : ''
            }
        },
        {
            Header: <div className='d-flex justify-content-between'><span className='w-25 text-start'>Last Contacted</span><span>Actions</span></div>,
            accessor: 'actions',
            className: 'actions w-10',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({cell}) => {
                const isDeleted = isInstanceDeleted(cell.row.original)
                return isDeleted ? 'Deletion in progress' : <ActionButtons removeFromList={removeInstance} instance={cell.row.values.instance} eventTypes={eventTypes}/>
            }
        }
    ], [eventTypes, agents]);

    const isInstanceDeleted = (item: any) => {
        const reason = item?.instance?.reason
        return reason === 'Deletion in progress';
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id', 'instance'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'name', desc: false}]
            }
        },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-database fa-fw" aria-hidden="true"></i>
                    Monitored instances
                    <span className="badge bg-info"
                          data-tip="Total instance count">{data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseInstancesAdmin" aria-expanded="false"
                       aria-controls="collapseInstancesAdmin"></i>
                </div>
                <div id="collapseInstancesAdmin" className="card-body collapse show">
                    <div className="row row-cols-1 row-cols-md-2 table-search">
                        <div className="col col-md-9">
                            <Link to="/admin/instances/create" role="button" className="btn btn-sm btn-success">
                                <i className="fal fa-plus-square fa-fw"></i><span>Create</span>
                            </Link>
                            <CSVLink role="button"
                                     headers={[{label: 'ID', key: 'id'}, {
                                         label: 'Instance Name',
                                         key: 'name'
                                     }, {label: 'Type', key: 'type'}, {
                                         label: 'Host Address',
                                         key: 'hostAddress'
                                     }, {label: 'Last Contact', key: 'updated'}]}
                                     data={data}
                                     download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Instances.csv`}
                                     className="btn btn-sm btn-primary">
                                <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                            </CSVLink>
                            <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                className="far fa-undo"></i><span>Clear</span></button>
                        </div>
                        <div className="col col-md-3">
                            <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                                   value={tableFilter} data-lpignore={true}
                                   onChange={(e) => setTableFilter(e.target.value)}/>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={(column as any).className}>
                                            {column.render('Header')}
                                            <SortClass column={column} />
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} className={(cell.column as any).className}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <NoData
                            error={null}
                            loading={props.loading === DATA_LOADING}
                            length={data.length}
                        />
                        <TablePagination
                            length={data.length}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            canPreviousPage={canPreviousPage}
                            previousPage={previousPage}
                            canNextPage={canNextPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            pageIndex={pageIndex}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List;
