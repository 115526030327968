import { useEffect, useState } from "react";
import './style.scss';
import Helmet from "react-helmet";
import api from "../../api/Base";
import InstanceTarget from "../../types/instance/InstanceTarget";
import { EXPIRATION_KEY, LAST_PAGE_KEY, messages, TOKEN_KEY } from "../../component/Constants";

function random(): number {
    return process.env.NODE_ENV === 'test' ? 0 : Math.random();
}

function Login(props: { loginError: string }) {
    const [userName, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [message, setMessage] = useState<string>(messages[Math.floor(random() * messages.length)]);
    const [loading, setLoading] = useState<boolean>(false);

    const url = new URL(window.location.href);
    const errorParam = url.searchParams.get('error') || ''
    const [error, setError] = useState<string>(props.loginError);

    useEffect(() => {
        if (errorParam) {
            setError(errorParam)
        }
    }, [errorParam]);

    useEffect(() => {

        let timeout: NodeJS.Timeout;

        timeout = setInterval(() => setMessage(messages[Math.floor(random() * messages.length)]), 9000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleLogin = async() => {
        setLoading(true)
        const token = btoa(`${userName}:${password}`);
        let url = `datasource?full=true&id=0`;
        window.localStorage.setItem(TOKEN_KEY, token);

        api.get<InstanceTarget[]>(url)
            .then((response) => {
                if (response) {
                    if (response && Array.isArray(response.data)) {
                        const expirationDate = new Date();
                        expirationDate.setMinutes(expirationDate.getMinutes() + 1);
                        window.localStorage.setItem(TOKEN_KEY, token);
                        window.localStorage.setItem(EXPIRATION_KEY, expirationDate.toISOString());
                        const lastPage = window.localStorage.getItem(LAST_PAGE_KEY);
                        window.location.href = lastPage || `/`;
                    } else {
                        console.error('error', error);
                        window.localStorage.removeItem(TOKEN_KEY);
                        window.localStorage.removeItem(EXPIRATION_KEY);
                        setLoading(false)
                        setError('invalid');
                    }
                }
            })
            .catch((error: any) => {
                if (error) {
                    console.error('error', error);
                    window.localStorage.removeItem(TOKEN_KEY);
                    window.localStorage.removeItem(EXPIRATION_KEY);
                    let errorName = '302'
                    if (error.response) {
                        errorName = error.response.status.toString()
                    }
                    setLoading(false)
                    setError(errorName);
                }
            });
    };

    const getErrorText = (value: string) => {
        const messages: { [key: string]: string } = {
            'invalid': 'Invalid Credentials',
            '302': 'Invalid Credentials',
            '502': '502 Bad Gateway',
            '503': '503 Service Unavailable',
            '504': '504 Gateway timeout',
            not_authorized: 'Not authorized, please log in',
            expired: 'Session expired, please log in',
        };

        return messages[value] || '';
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            void handleLogin();
        }
    };

    return (
        <div className='dbmar-login-wrapper'>
            <div className='dbmar-login'>
                <Helmet>
                    <title>DBmarlin Log In</title>
                    <meta name="description" content="Log In"/>
                </Helmet>
                <div className="card">
                    <div className="card-header">
                        <div className='text-center'>
                            <img src="/icons/dbmarlin-badge.svg" alt="DBmarlin" className="logo-large" width="200px"
                                 height="70px"/>
                        </div>
                        <p className='login-header'>Log In to DBmarlin</p>
                    </div>

                    <div className="col">
                        <div className='m-2'>
                            <input
                                type="text"
                                id="userName"
                                placeholder='Username'
                                className="form-control"
                                onChange={(e) => setUserName(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>

                        <div className='m-2 position-relative'>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder='Password'
                                className="form-control"
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <i title={showPassword ? 'Hide password' : ' Show password'}
                               className={`fa-solid show-password ${!showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                               onClick={() => setShowPassword(!showPassword)}></i>
                        </div>
                        {<p className='login-error'>{getErrorText(error)}</p>}

                        <div className='m-2'>
                            <button onClick={() => handleLogin()} type='button'
                                    disabled={!userName || !password || loading}
                                    className={`btn btn-primary w-100`}>
                                <span>Login</span>
                            </button>
                        </div>
                        <p className='login-link'><a href='https://docs.dbmarlin.com/docs/Getting-Started/access-control/authentication#update-user-change-password'>Lost your password?</a></p>
                        <p className='login-link'><a href='https://docs.dbmarlin.com/docs/Getting-Started/access-control/authentication#add-user'>Add new Account</a></p>
                    </div>
                </div>


                <div className="initialising login-animation">
                    <div className="w-100 text-center text-muted">
                        <div className="spinner"><img src="/icons/icon.png" className="fish" width="100" height="100"
                                                      alt="DBmarlin"/></div>

                    </div>
                </div>
                <div className='invisible-wave'></div>
                <div className="login-marlin-facts">
                    <div className="waves"></div>
                    <div className="message">{message}</div>
                    <p className="copyright">&#169;dbmarlin.com</p>
                </div>
            </div>
        </div>
    )
}

export default Login;
