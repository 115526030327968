// Third-party packages.
import { Column } from 'react-table';

export default function SortClass(props: { column: Column }) {
    // Column interface is missing some useful properties.
    const col: any = props.column as any;

    if (col.canSort) {
        let className: string = "fal fa-sort-amount-";

        if (col.isSorted) {
            if (col.isSortedDesc) {
                // Down
                className += "down-alt";
            } else {
                // Up
                className += "up-alt";
            }
        } else {
            if (col.sortDescFirst) {
                // Down, dimmed
                className += "down-alt text-light";
            } else {
                // Up, dimmed
                className += "up-alt text-light";
            }
        }

        return (<i className={className}></i>);
    }

    return null;
}
