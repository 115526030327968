import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Types.
import Period from '../../types/Period';
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import EventType from '../../types/instance/EventType';

// Constants.

// Views.
import List from './List';
import Instances from "./Instances";
import Detail from "./Detail";
import Events from "./Events";

interface IHostsProps {
    period: Period,
    instances: InstanceTarget[],
    hosts: HostTarget[],
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}

interface IHostsState {
}

const initialState: IHostsState = {};

class Hosts extends Component<IHostsProps, IHostsState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>, setFilterOptions: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IHostsProps) {
        super(props);

        this.state = initialState;
    };

    render() {
        const {
            period,
            instances,
            hosts,
            eventTypes,
            toggleMenu,
            applyPeriod,
            setFilterOptions
        } = this.props;

        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/hosts/:hostId/instances"
                           render={(props) => <Instances {...props} hostId={Number(props.match.params.hostId)}
                                                         period={period}
                                                         toggleMenu={toggleMenu} applyPeriod={applyPeriod}
                                                         hosts={hosts}
                                                         setFilterOptions={setFilterOptions}/>}/>
                    <Route exact path="/hosts/:hostId/events"
                           render={(props) => <Events {...props}
                                                      instances={instances}
                                                      hostId={Number(props.match.params.hostId)}
                                                      eventTypes={eventTypes}
                                                      period={period} hosts={hosts}
                                                      toggleMenu={toggleMenu} applyPeriod={applyPeriod}
                                                      setFilterOptions={setFilterOptions}/>}/>

                    <Route exact path="/hosts"
                           render={(props) => <List {...props} key={period.timestamp} period={period}
                                                    instances={instances} hosts={hosts}
                                                    toggleMenu={toggleMenu} applyPeriod={applyPeriod}/>}/>
                    <Route exact path="/hosts/:hostId"
                           render={(props) => <Detail {...props} key={period.timestamp + props.match.params.hostId}
                                                      hostId={Number(props.match.params.hostId)} instances={instances}
                                                      hosts={hosts}
                                                      period={period} toggleMenu={toggleMenu}
                                                      applyPeriod={applyPeriod}/>}/>

                    <Redirect to="/not-found" />

                </Switch>
            </React.Fragment>
        );
    }
}

Hosts.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Hosts;
