import { useContext, useEffect, useRef, useState } from 'react';
import Helmet from "react-helmet";
import api from "../../api/Base";
import { Link, NavLink, useHistory } from "react-router-dom";
import { InstanceContext } from "../../context/InstanceContext";

// Components
import Breadcrumb from "../../container/breadcrumb";
import TabbedMenu from "../../container/tabbed-menu";
import TimeShift from "../../component/TimeShift";
import ConditionalRender from "../../helpers/ConditionalRender";
import ResultsTable from "./ResultsTable";

// Types
import InstanceTarget from "../../types/instance/InstanceTarget";

// Style
import './style.scss'


interface SqlSearchProps {
    instances: InstanceTarget[],
}

const SQLSearch = (props: SqlSearchProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [results, setResults] = useState<any>([])
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [instanceFilter, setInstanceFilter] = useState<string>('');
    const [instanceId, setInstanceId] = useState<number>(NaN);
    const searchButtonRef = useRef<HTMLButtonElement | null>(null);

    const currentInstance = props.instances.find((instance: InstanceTarget) => instance.id === instanceId)
    const {instances} = useContext(InstanceContext);
    const history = useHistory();

    const searchStatement = async(searchString: string) => {
        setResults([])
        setLoading(true)

        const encodedSqlStatement = encodeURIComponent(searchString);
        const version = process.env.SEARCH_VERSION || '2.0'
        history.push(`/sql-search?v=${version}&sql=${encodedSqlStatement}`)

        try {
            const instanceParam = instanceId ? `&id=${instanceId}` : ''
            const response = await api.post(`sql/search?sort=similarity+desc,id,sqlhash&limit=50${instanceParam}`, searchString);
            if (response?.data) {
                setResults(response.data)
                setLoading(false)
                setError('');
            }
        } catch (err) {
            setError('Failed to read  data, request failed');
            setLoading(false)
        }
    }

    const manuallyHideList = () => {
        const dropdownMenu = document.querySelector("#dropdown-menu")
        const buttonMenu = document.querySelector("#filter")
        if (dropdownMenu) {
            dropdownMenu.classList.remove('show'); // Remove the 'show' class
        }
        if (buttonMenu) {
            buttonMenu.setAttribute('aria-expanded', 'false'); // Set aria-expanded attribute to false
            buttonMenu.classList.remove('show'); // Remove the 'show' class
        }
        // focus on search button
        if (searchButtonRef.current) {
            searchButtonRef.current.focus();
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const sqlStatement = searchParams.get('sql');

        if (sqlStatement) {
            const handleParamValue = async() => {
                const decodedParam = decodeURIComponent(sqlStatement)
                if (decodedParam) {
                    setValue(decodedParam)
                    await searchStatement(decodedParam)
                }
            };
            void handleParamValue();
        }

        // Focus on the textarea after clearing data
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    const refresh = async() => {
        await searchStatement(value)
    }

    const clearData = () => {
        setInstanceId(NaN)
        setValue('');
        localStorage.clear()

        // Focus on the textarea after clearing data
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default Enter key behavior (new line in textarea)
            if (!loading) {
                void searchStatement(value)
            }
        }
    };

    return (
        <div id="content">
            <Helmet>
                <title>SQL Search - DBmarlin</title>
                <meta name="description" content="Search SQL"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading='SQL Search' instanceId={instanceId}>
                    <Link to="/sql-search">Analysis</Link>
                    <span className={"current-breadcrumb-link"}>SQL Search</span>
                </Breadcrumb>

            </div>

            <TabbedMenu>
                <TimeShift/>
                <NavLink to={`sql-search`} activeClassName="active">SQL Search</NavLink>
            </TabbedMenu>

            <div className="search-area">

                <div className='search-options'>

                    <span onClick={() => clearData()}>Clear</span></div>
                <textarea ref={textareaRef} disabled={loading} id="searchField" onKeyPress={handleKeyPress}
                          placeholder="SQL Statement text to search for..." onChange={e => setValue(e.target.value)}
                          value={value}/><br/>

                <div className='search-options'>
                    <div id="top-header-wrapper">
                        <div className="dropdown d-inline">
                            <button type="button" id="filter" className="btn btn-dropdown dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false" onClick={() => searchInputRef.current?.focus()}>
                                {currentInstance ? instances.find(item => item.id === currentInstance.id)?.name : 'Select Instance (Optional)'}
                            </button>

                            <div id="dropdown-menu" className="row g-0 dropdown-menu dropdown-menu-scroll"
                                 aria-labelledby="filter"
                                 onClick={(e) => e.stopPropagation()}>
                                <ul className="col">
                                    <li className="search">
                                        <div className="row g-0">
                                            <div className="col-9">
                                                <input type="text" autoFocus className="form-control"
                                                       placeholder={`Search instance`} value={instanceFilter}
                                                       onChange={(e) => setInstanceFilter(e.target.value.toLowerCase())}
                                                       ref={searchInputRef}/>
                                            </div>
                                            <div className="col-3 text-end">
                                                <button type="button" className="btn btn-small btn-dark"
                                                        onClick={() => setInstanceFilter('')}><i className="far fa-undo"
                                                                                         aria-hidden="true"/></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider"/>
                                    </li>

                                    <li onClick={() => manuallyHideList()}><span onClick={() => setInstanceId(NaN)}
                                                                                 className="dropdown-item">All Instances</span>
                                    </li>
                                    {instances.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter(item => item.name.toLowerCase().includes(instanceFilter)).map((item: { id: number; name: string; }) => (

                                        <li key={item.id} onClick={() => manuallyHideList()}>
                                            {item.id === instanceId ? (
                                                <p className='instance-selected'>
                                                     <span onClick={() => setInstanceId(NaN)}
                                                           className="dropdown-item active">{item.name}</span>
                                                    <span onClick={() => setInstanceId(NaN)} className="dropdown-item active">Clear</span>
                                                </p>
                                            ) : (
                                                <span onClick={() => setInstanceId(item.id)}
                                                      className="dropdown-item">{item.name}</span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <button disabled={loading || !value} onClick={() => searchStatement(value)}
                        ref={searchButtonRef}
                        className='search-button'>Search for Statement <i className="fal fa-search fa-fw"
                                                                          aria-hidden="true"/></button>
            </div>

            {/* Results Table */}
            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-server fa-fw" aria-hidden="true"/>
                            Matching Statements
                            <span className="badge bg-info"
                                  data-tip="Total matching atatements">{results.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseResults" aria-expanded="false"
                               aria-controls="collapseResults"/>
                        </div>
                        <div id="collapseResults" className="card-body collapse show">
                            <ConditionalRender if={loading}>
                                <div className="w-100 text-center text-muted my-4">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="my-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={!results.length && !loading && instances.length}>
                                <div id="collapseStatements" className="card-body">
                                    <div className="w-100 text-center text-muted my-3">
                                        <i className="fal fa-ban fa-fw fa-2x"/>
                                        {error ? <p className='request-error'>
                                            {`${error}`}. <span onClick={() => refresh()}>Try again</span>
                                        </p> : <p>No Matching Statements</p>}
                                    </div>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={results.length && !loading}>
                                <ResultsTable loading={loading} list={results}/>
                            </ConditionalRender>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SQLSearch;
