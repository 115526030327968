import React, { useEffect } from 'react';

// Third-party.
import ReactTooltip from 'react-tooltip';

function GenericBreakdown(props: { individualTime: number, totalTime?: number, color: string, tooltip: string }) {

    useEffect(() => {

        if (props.totalTime) {
            ReactTooltip.rebuild();
        }

    }, [props.totalTime]);

    if (props.totalTime === undefined) {
        return (
            <React.Fragment>-</React.Fragment>
        )
    }

    const 
        weight: number = Math.round(Math.floor((100 / props.totalTime) * props.individualTime)),
        tooltip: string = `${props.tooltip} (${weight}%)`;

    if (weight < 5) {

        // Less then 5% time spent - so we either don't care, or there are simply too many rows below 5%.
        return (
            <React.Fragment>-</React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{ width: `${weight}%`, backgroundColor: props.color }} data-tip={tooltip}>&nbsp;</div>
            <ReactTooltip className="tooltip" effect="solid" />
        </React.Fragment>
    );
}

export default GenericBreakdown;