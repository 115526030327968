import React, { useMemo, useState } from "react";

// Third-parties.
import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Alert from '../../Alert';
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import WaitsDataTable from '../../../types/instance/tables/WaitsDataTable';
import { FilterType } from '../../../typescript/Enums';

// Components.
import GenericBreakdown from '../../GenericBreakdown';
import KnowledgeBaseWaitEventButton from '../../buttons/KnowledgeBaseWaitEventButton';
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';

// Constants.
import { getDefaultPageSize } from "../../../helpers/utils";
import { DATA_LOADING } from "../../Constants";

function WaitsTable(props: { loading: boolean | number, period: Period, instance: InstanceTarget, instanceId: number, instanceTime: number, waitsDataTable: WaitsDataTable[], setFilterOptions: Function }) {
    const [tableFilter, setTableFilter] = useState<string>('');

	const { period, instance, instanceTime, setFilterOptions } = props;

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = useMemo(() => props.waitsDataTable.filter(row => (row.waitevent.toLowerCase().includes(tableFilter.toLowerCase()))), [props.waitsDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => {
		function filterData (filter: string): void
		{
			setFilterOptions (Helper.updateFilterOptions
				(FilterType.Waits, filter, period.filters.options));
		}

		return [
        {
            accessor: 'color',
            disableSortBy: true
        },
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            Header: 'Wait',
            accessor: 'waitevent',
            className: 'fw-bold',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <span className="me-1 ps-1" style={{ backgroundColor: cell.row.values.color }}>&nbsp;</span>
                    {(cell.row.values.waitevent !== '-') ? (
                        <React.Fragment>
                            <button key={cell.column.id} className="btn btn-sm btn-link btn-filter" onClick={() => filterData (cell.row.values.waitevent)}>{cell.row.values.waitevent}</button>
                            <KnowledgeBaseWaitEventButton databaseType={instance.type} waitEvent={cell.row.values.waitevent}/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>-</React.Fragment>
                    )}
                </React.Fragment>
            ),
        },
        {
            Header: 'Total Time',
            accessor: 'breakdown',
            className: 'generic-waits text-nowrap',
            disableSortBy: true,
            Cell: ({ cell }) => (
                cell.row.values.waittime !== null ? (
                    cell.row.values.id < 20 ? (
                        <GenericBreakdown individualTime={cell.row.values.waittime} totalTime={instanceTime} color={cell.row.values.color} tooltip={cell.row.values.waitevent} />
                    ) : (
                        <React.Fragment>-</React.Fragment>
                    )
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Wait Time',
            accessor: 'waittime',
            headerClassName: 'text-end',
            className: 'text-end',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                cell.row.values.waittime !== null ? (
                    Helper.getTimeInEnglish(cell.row.values.waittime)
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Weight %',
            accessor: 'weight',
            headerClassName: 'text-end',
            className: 'text-end',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({ cell }) => (
                instanceTime ? (
                    <React.Fragment>
                        {Math.floor((100 / instanceTime) * cell.row.values.waittime)}%
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        -
                    </React.Fragment>
                )
            )
        }
    ]}, [instance.type, instanceTime, period.filters.options, setFilterOptions]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        initialState: { hiddenColumns: ['id', 'color'], pageIndex: 0, pageSize: getDefaultPageSize(), sortBy: [{ id: 'waittime', desc: true }] }
    },
        useSortBy, usePagination);

    return (
        <div id="waits" className="tab-pane fade show" role="tabpanel" aria-labelledby="waits-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-clock fa-fw" aria-hidden="true" />
                    Waits
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseWaits" aria-expanded="false" aria-controls="collapseWaits" />
                </div>
                <div id="collapseWaits" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length -1 && (
                        <Alert message={`To help preserve performance, we limit the total number of wait records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their total wait time.`} heading="Waits records" variant="alert-info" />
                    )}
                    <div className="row row-cols-1 row-cols-md-2 table-search">
                        <div className="col col-md-9">
                            <CSVLink role="button"
                                headers={[ { label: 'ID', key: 'id' }, { label: 'Wait', key: 'waitevent' }, { label: 'Wait Time', key: 'waittime' } ]}
                                data={data}
                                download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Waits.csv`}
                                className="btn btn-sm btn-primary">
                                <i className="fal fa-file-export fa-fw" /><span>Export</span>
                            </CSVLink>
                            <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i className="far fa-undo" /><span>Clear</span></button>
                        </div>
                        <div className="col col-md-3">
                            <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={(column as any).className}>
                                                {column.render('Header')}
                                                {column.canSort ? (column.isSorted ? (column.isSortedDesc ? <i className="fal fa-sort-amount-up-alt" /> : <i className="fal fa-sort-amount-down-alt" />) : <i className="fal fa-sort-amount-down-alt text-light" />) : ''}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps ()} className={(cell.column as any).className} style={{ borderLeft: (cell.column as any).color }}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
						<NoData
							error={null}
							loading={props.loading === DATA_LOADING || props.loading === true}
							length={data.length}
						/>
						<TablePagination
							length={data.length}
							pageSize={pageSize}
							setPageSize={setPageSize}
							pageOptions={pageOptions}
							pageCount={pageCount}
							canPreviousPage={canPreviousPage}
							previousPage={previousPage}
							canNextPage={canNextPage}
							nextPage={nextPage}
							gotoPage={gotoPage}
							pageIndex={pageIndex}
						/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WaitsTable;
