// React.
import { useContext, useEffect, useState } from 'react';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { StatisticTimeslice } from '../../../types/instance/Statistic';

// Components.
import StatisticChart from '../../../component/instance/chart/StatisticChart';

// Helper.
import { archiverUrl, fetchResults, fetchWithAuthorization } from '../../../helpers/utils';
import { TimeRangeContext } from "../../../context/TimeRangeContext";

export default function Statistics(props: { instance: InstanceTarget, applyPeriod: Function }) {
    interface StatisticData {
        name: string,
        cumulative: boolean,
        data: StatisticTimeslice[]
    }

    const {instance} = props;

    const [statistics, setStatistics] = useState<StatisticData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const timeRangeContext = useContext(TimeRangeContext)

    useEffect(() => {
        async function load() {
            try {
                setLoading(true);

                let promises: Promise<any>[] = [];
                let statsArray: StatisticData[] = [];

                const metadata: any[][] = await fetchResults
                ([
                    fetchWithAuthorization(archiverUrl() +
                        '/statistic/type' +
                        '?collect=true' +
                        '&type=' + instance.type)
                ]);

                for (const m of metadata) {
                    for (const t of m) {
                        // There is no data until the promises have completed.
                        statsArray.push({
                            name: t.statistic,
                            cumulative: t.cumulative, data: []
                        });
                        promises.push(fetchWithAuthorization(archiverUrl() + `/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}&statistic=${encodeURIComponent(t.statistic)}`))
                    }
                }

                // Fetch all the data in parallel.
                const results: StatisticTimeslice[][] =
                    await fetchResults(promises);

                if (active) {
                    setLoading(false);

                    // Populate the data fields.
                    for (let i = 0; i < statsArray.length; i++) {
                        statsArray[i].data = results[i];
                    }

                    setStatistics(statsArray);
                    setLoading(false);
                }
            } catch (x: any) {
                console.log(x.message);
                setError(x.message);
                setStatistics([]);
                setLoading(false);

            }
        }

        let active: boolean = true;
        load();
        // console.log ('Leaving useEffect, active);
        return () => {
            // Clean up afterwards to avoid race conditions.
            // console.log ('Clean up');
            active = false;
        }
    }, [timeRangeContext, instance.id, instance.type]);

    // The outer div adds padding around the collapsible charts.
    return (
        <div className="row row-cols-1">
            <div id="statistics" className="tab-pane fade show active" role="tabpanel" aria-labelledby="statistics-tab">
                {statistics.map((statistic: StatisticData, index: number) => (
                    <StatisticChart error={error} loading={loading} applyPeriod={props.applyPeriod}
                    data={statistic.data} statistic={statistic.name}
                                    cumulative={statistic.cumulative} index={index} key={index}
                    />
                ))}
            </div>
        </div>
    )
}
