import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Context.
import { InstanceContext } from '../../context/InstanceContext';

// Api.
import StatisticApi from '../../api/instance/StatisticApi';

// Types.
import Period from '../../types/Period';
import InstancesDataTable from '../../types/instance/tables/InstancesDataTable';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED } from '../../component/Constants';

// Helpers.

// Components.
import InstancesTable from '../../component/instance/table/InstancesTable';
import Breadcrumb from "../../container/breadcrumb";
import PageContent from '../../container/page_content';
import { Link, NavLink } from "react-router-dom";
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import FilterBar from "../../component/FilterBar";

import HostTarget from "../../types/host/HostTarget";
import HostApi from "../../api/host/HostApi";
import HostInstances from "../../types/host/HostInstances";
import { TimeRangeContext } from "../../context/TimeRangeContext";
import EventsApi from "../../api/instance/EventsApi";
import CombinedChanges from "../../types/instance/CombinedChanges";
// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);


function List(props: { period: Period, toggleMenu: Function, applyPeriod: Function, setFilterOptions: Function, hostId: number, hosts: HostTarget[] }) {
    const { instances } = useContext(InstanceContext);

    const [combinedChanges, setCombinedChanges] = useState<CombinedChanges[]>([]);
    const { hostId, hosts } = props

    const [loading, setLoading] = useState<number>(DATA_LOADING);
    const [tableLoading, setTableLoading] = useState<number>(DATA_LOADING);
    const [instancesDataTable, setInstancesDataTable] = useState<InstancesDataTable[]>([]);
    const [hostInstances, setHostInstances] = useState<HostInstances[]>([]);

    const timeRangeContext = useContext(TimeRangeContext)

    const host = hosts.find(item => item.id === hostId)

    useMemo(() => {
        const getHostInstances = async () => {
            setLoading(DATA_LOADING);

            HostApi.getHostInstances(hostId).then((instances: HostInstances[]) => {
                setLoading(DATA_LOADED);
                setHostInstances(instances);
            })
        }
        getHostInstances();
    }, [hostId]);


    useMemo(() => {
        const getChanges = async() => {

            // Get the event types, known, detected changes and registered events.
            const allEvents: any[] = await EventsApi.getAllEventsChangesAndAlerts(timeRangeContext);
            let combinedChanges: CombinedChanges[] = []

            for (let index = 0; index < allEvents.length; index++) {
                const currentItem = allEvents[index];
                combinedChanges.push({
                    id: index,
                    instanceId: currentItem.datasourceid,
                    timeslice: currentItem.timeslice,
                    [currentItem.event]: currentItem
                });
            }

            // Set the sort order for the combined changes and events.
            combinedChanges.sort(function compare(a, b) {
                var aa: number = dayjs.default(a.timeslice).valueOf();
                var bb: number = dayjs.default(b.timeslice).valueOf();
                return bb - aa;
            });

            setCombinedChanges(combinedChanges)
        }
        void getChanges();

    }, [instances, props.period, hostInstances]);

    useMemo(() => {


        const getInstances = async () => {

            let instancesDataTable: InstancesDataTable[] = [];
            const instancesIdsArray = hostInstances.map(a => a.id);
            const instancesToDisplay = instances.filter(instance => instancesIdsArray.includes(instance.id))
            if (instances.length) {
                for (let index = 0; index < hostInstances.length; index++) {

                    // Build the initial hosts table.
                    instancesDataTable.push({
                        id: instancesToDisplay[index].id,
                        name: instancesToDisplay[index].name,
                        type: instancesToDisplay[index].type,
                        status: instancesToDisplay[index].status,
                        color: instancesToDisplay[index].color,
                        changes: null,
                        alerts: null,
                        customEvents: null,
                        time: null,
                        executions: null,
                        averageTime: 0,
                        previousTime: null,
                        previousExecutions: null,
                        previousAverageTime: null,
                        instance: instancesToDisplay[index]
                    });
                }

                const promisesTime = []
                const promisesExecutions = []

                for (let index = 0; index < instancesDataTable.length; index++) {
                    const currentInstanceChanges =  combinedChanges.filter(change => change.instanceId === instancesDataTable[index].id)

                    instancesDataTable[index].changes = currentInstanceChanges.filter(item => item.change).length;
                    instancesDataTable[index].customEvents = currentInstanceChanges.filter(item => item.event).length
                    instancesDataTable[index].alerts = currentInstanceChanges.filter(item => item.alert).length

                    promisesTime.push(StatisticApi.getTimeStatistic(timeRangeContext, instancesDataTable[index].id, props.period.filters.parameters))
                    promisesExecutions.push(StatisticApi.getStatistic(timeRangeContext, instancesDataTable[index].id, 'executions', props.period.filters.parameters))
                }

                const asyncTimeResponses = await Promise.all(promisesTime)
                asyncTimeResponses.forEach((timeResponse: number, index: number) => {
                    instancesDataTable[index].time = timeResponse;
                })

                const asyncExecutionsResponses = await Promise.all(promisesExecutions)
                asyncExecutionsResponses.forEach((executionResponse: number, index: number) => {
                    instancesDataTable[index].executions = executionResponse;
                })

                instancesDataTable.forEach((instance, index) => {
                    // @ts-ignore
                    let averageTime = parseInt((instancesDataTable[index].time / instancesDataTable[index].executions).toFixed(2));
                    averageTime = isNaN(averageTime) || averageTime === Infinity || averageTime === -Infinity ? 0 : averageTime
                    instance.averageTime = averageTime
                })
                if (instancesDataTable.length) {
                    setInstancesDataTable(instancesDataTable)
                }
                setTableLoading(DATA_LOADED)
            }
        }

        void getInstances();

    }, [combinedChanges]);

    function refresh() {
        setLoading(DATA_INITIALISING);
    }

    return (
        <PageContent title={`${host?.name} - Database Instances`}
                     description={`List of all monitored DB instances with high-level performance statistics for ${host?.name}`}>
            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading={host?.name || ''}
                            type="hosts"
                            hostId={hostId}>
                    <Link to="/hosts">Analysis</Link>
                    <Link to="/hosts">Hosts</Link>
                    <Link to={`/hosts/${hostId}`}>{host?.name}</Link>
                    <NavLink to={`/hosts/${hostId}`} activeClassName={"current-breadcrumb-link"}>Database
                        activity</NavLink>
                    <PeriodBreadcrumb />
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={refresh}
                               applyPeriod={props.applyPeriod}/>
            </div>


            <div className="col loader">
                <ConditionalRender if={loading === DATA_LOADING}>
                    <div className="bar"/>
                </ConditionalRender>
            </div>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions && <FilterBar period={props.period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            {/* Tab Headings */}
            <ul id="menu-top" className="row row-cols-auto" role="tablist">
                <Link to={`/hosts/${hostId}`}>Host Metrics</Link>
                <Link to={`/hosts/${hostId}/instances`} className="active">Database Activity</Link>
                <Link to={`/hosts/${hostId}/events`}>Events</Link>
            </ul>

            {/* Instances */}
            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-database fa-fw" aria-hidden="true"/>
                            Monitored instances on host {host?.name}
                            <span className="badge bg-info"
                                  data-tip="Total instances count">{hostInstances.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseInstances" aria-expanded="false"
                               aria-controls="collapseInstances"/>
                        </div>
                        <div id="collapseInstances" className="card-body collapse show">
                            <InstancesTable tags={[]} error={null} loading={tableLoading}
                                            instancesDataTable={instancesDataTable}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageContent>
    )
}

List.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default List;
