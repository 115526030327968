// React.
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// Third-party packages.
import * as dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

// Types.
import Filter from '../../../types/Filter';

// Constants.
import { ACTION_UPDATE, ACTION_DELETE } from '../../../component/Constants';

// Contexts.
import { FilterContext } from '../../../context/FilterContext';

// Components.
import NoData from '../../../component/NoData';

// Helpers.
import { defaultTimeZone, getParameter } from '../../../helpers/utils';

export default function List ()
{
	const { filters } = useContext (FilterContext);
	const [ search, setSearch ] = useState<string> ('');

	function getFrom (filter: Filter): string | null
	{
		return getParameter (filter.parameters, 'from');
	}

	function getTo (filter: Filter): string | null
	{
		return getParameter (filter.parameters, 'to');
	}

	function getTz (filter: Filter): string | null
	{
		// Only print the time zone if it is different from the browser's.
		const tz: string | null = getParameter (filter.parameters, 'tz');
		return tz !== defaultTimeZone () ? tz : null;
	}

	function format (milliseconds: number)
	{
		// A variation on dhms in utils.
		// Convert milliseconds to non-zero days, hours, minutes and seconds.
		if (milliseconds < 1000)
		{
			return '0s';
		}

		const seconds: number = Math.round (milliseconds / 1000);
		const segments: number[] =
		[
			Math.floor (seconds / 86400),
			Math.floor (seconds / 3600) % 24,
			Math.floor (seconds / 60) % 60,
			seconds % 60
		];
		const units: string[] = [ 'd ', 'h ', 'm ', 's' ];
		let result: string = '';

		for (let i = 0; i < segments.length - 1; i++)
		{
			if (segments[i] > 0)
			{
				result += segments[i] + units[i];
			}
		}

		return result;
	}

	function getDuration (filter: Filter): string
	{
		const fromDate: number = dayjs.default (getFrom (filter)).valueOf ();
		const toDate: number = dayjs.default (getTo (filter)).valueOf ();
		return format (toDate.valueOf () - fromDate.valueOf ());
	}

	function notNull (value: string | null | undefined): string
	{
		return value || '';
	}

	function searchFilters (): Filter[]
	{
		return filters.filter (filter =>
			filter.name.toLowerCase ().includes (search) ||
			notNull (filter.description).toLowerCase ().includes (search) ||
			notNull (getFrom (filter)).toLowerCase ().includes (search) ||
			notNull (getTo (filter)).toLowerCase ().includes (search) ||
			notNull (getTz (filter)).toLowerCase ().includes (search));
	}

	ReactTooltip.rebuild ();

	return (
		<div className="col h-100">
			<div className="card">
				<div className="card-header">
					<i className="fal fa-camera-alt fa-fw mr-10" aria-hidden="true"></i>
					Snapshots
					<span className="badge bg-info" data-tip="Total snapshot count">{filters.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
					<i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters"></i>
				</div>
				<div id="collapseFilters" className="card-body collapse show">
					<div className="row row-cols-1 row-cols-md-2 table-search">
						<div className="col col-md-9">
							<Link to="/admin/filters/create" role="button" className="btn btn-sm btn-success">
								<i className="fal fa-plus-square fa-fw"></i><span>Create</span>
							</Link>
							<CSVLink role="button"
								headers={[ { label: 'ID', key: 'id' }, { label: 'Name', key: 'name' }, { label: 'Parameters', key: 'parameters' }, { label: 'Keep Data', key: 'keepdata' } ]}
								data={filters}
								download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Snapshots.csv`}
								className="btn btn-sm btn-primary">
								<i className="fal fa-file-export fa-fw"></i><span>Export</span>
							</CSVLink>
							<button className="btn btn-sm btn-dark" onClick={() => setSearch ('')}><i className="far fa-undo"></i><span>Clear</span></button>
						</div>
						<div className="col col-md-3">
							<input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={search} data-lpignore={true} onChange={(e) => setSearch (e.target.value.toLowerCase ())} />
						</div>
					</div>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Name</th>
									<th scope="col">From</th>
									<th scope="col">To</th>
									<th scope="col">Duration</th>
									<th scope="col">TZ</th>
									<th scope="col">Keep Data</th>
									<th className="actions" scope="col">Actions</th>
								</tr>
							</thead>
							<tbody>
								{searchFilters ().map ((filter: Filter, index) => (
								<tr key={index}>
									<th scope="row">
										<span data-tip={filter.description}>{filter.name}</span>
									</th>
									<td>
										{getFrom (filter)}
									</td>
									<td>
										{getTo (filter)}
									</td>
									<td>
										{getDuration (filter)}
									</td>
									<td>
										{getTz (filter)}
									</td>
									<td>
										{filter.keepdata ? "Yes" : "No"}
									</td>
									<td className="actions">
										<Link to={{ pathname: `/admin/filters/edit/${filter.id}`, state: { action: ACTION_UPDATE, filter } }} role="button" className="btn btn-sm btn-primary">
											<i className="fal fa-edit fa-fw"></i><span>Edit</span>
										</Link>
										<Link to={{ pathname: `/admin/filters/delete/${filter.id}`, state: { action: ACTION_DELETE, filter } }} role="button" className="btn btn-sm btn-danger">
											<i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
										</Link>
									</td>
								</tr>))}
							</tbody>
						</table>
						<NoData error={null} loading={false} length={searchFilters ().length} />
						<ReactTooltip className="tooltip" effect="solid" html={true} />
					</div>
				</div>
			</div>
		</div>
	)
}
