import { useMemo } from "react";

// Constants
import { DATA_LOADED, INSTANCE_TYPE_SQLSERVER } from "../../Constants";

// Components
import WaitsTable from "./WaitsTable";
import DatabasesTable from "./DatabasesTable";
import SessionsTable from "./SessionsTable";
import ClientsTable from "./ClientsTable";
import UsersTable from "./UsersTable";
import ProgramsTable from "./ProgramsTable";
import StatementsTable from "./StatementsTable";
import Batches from "../Tabs/Batches";

// Types
import InstanceTarget from "../../../types/instance/InstanceTarget";
import Period from "../../../types/Period";
import Wait from "../../../types/instance/Wait";
import WaitsDataTable from "../../../types/instance/tables/WaitsDataTable";
import ConditionalRender from "../../../helpers/ConditionalRender";
import GroupedStatementsTable from "./GroupedStatementsTable";
import GroupedBatches from "../Tabs/GroupedBatches";

export default function TablesWrapper(props: {
    period: Period,
    instance: InstanceTarget,
    loading: number,
    totalInstanceTime: number,
    statementsDataTable: any,
    waits: Wait[],
    waitsDataTable: WaitsDataTable[],
    setFilterOptions: Function,
    setBatchesOptions: Function,
    setDatabasesOptions: Function,
    setClientsOptions: Function,
    setProgramsOptions: Function,
    setSessionsOptions: Function,
    setUsersOptions: Function,
    isGroup: boolean
}) {
    const {
        period,
        instance,
        loading,
        totalInstanceTime,
        statementsDataTable,
        waits,
        waitsDataTable,
        setFilterOptions,
        setBatchesOptions,
        setDatabasesOptions,
        setClientsOptions,
        setProgramsOptions,
        setSessionsOptions,
        setUsersOptions,
        isGroup
    } = props

    const tableTabsComponent = useMemo(() => (
        <div className="tab-content">
            <ConditionalRender if={isGroup}>
                <GroupedStatementsTable loading={loading} filtersParameters={period.filters.parameters}
                                        instance={instance}
                                        instanceTime={totalInstanceTime} waits={waits} showByDefault={true}
                                        statementsDataTable={statementsDataTable}/>
                {instance.type === INSTANCE_TYPE_SQLSERVER && loading === DATA_LOADED && (
                    <GroupedBatches instanceId={instance.id}
                                    filtersParameters={period.filters.parameters}
                                    totalInstanceTime={totalInstanceTime} waits={waits} showByDefault={false}
                                    isBatchesForStatement={false} setBatchesOptions={setBatchesOptions}/>
                )}
            </ConditionalRender>
            <ConditionalRender if={!isGroup}>
                <StatementsTable loading={loading} period={period} instance={instance}
                                 instanceTime={totalInstanceTime} waits={waits} showByDefault={true}
                                 statementsDataTable={statementsDataTable}/>
                {instance.type === INSTANCE_TYPE_SQLSERVER && loading === DATA_LOADED && (
                    <Batches instanceId={instance.id}
                             filtersParameters={period.filters.parameters}
                             totalInstanceTime={totalInstanceTime} waits={waits} showByDefault={false}
                             isBatchesForStatement={false} setBatchesOptions={setBatchesOptions}/>
                )}
            </ConditionalRender>
            <WaitsTable loading={loading} period={period} instance={instance}
                        instanceId={instance.id} instanceTime={totalInstanceTime}
                        waitsDataTable={waitsDataTable} setFilterOptions={setFilterOptions}/>
            <DatabasesTable period={period} instance={instance}
                            instanceTime={totalInstanceTime}
                            setDatabasesOptions={setDatabasesOptions}
                            setFilterOptions={setFilterOptions}/>
            <SessionsTable period={period} instanceId={instance.id}
                           instanceTime={totalInstanceTime} setSessionsOptions={setSessionsOptions}
                           setFilterOptions={setFilterOptions}/>
            <ClientsTable period={period} instanceId={instance.id}
                          instanceTime={totalInstanceTime} setClientsOptions={setClientsOptions}
                          setFilterOptions={setFilterOptions}/>
            <UsersTable period={period} instanceId={instance.id}
                        instanceTime={totalInstanceTime}
                        setUsersOptions={setUsersOptions} setFilterOptions={setFilterOptions}/>
            <ProgramsTable period={period} instanceId={instance.id}
                           instanceTime={totalInstanceTime} setProgramsOptions={setProgramsOptions}
                           setFilterOptions={setFilterOptions}/>
        </div>
    ), [period,
        instance,
        loading,
        totalInstanceTime,
        statementsDataTable,
        waits,
        waitsDataTable,
        setFilterOptions,
        setBatchesOptions,
        setDatabasesOptions,
        setClientsOptions,
        setProgramsOptions,
        setSessionsOptions,
        setUsersOptions]);
    return (<div>{tableTabsComponent}</div>
    )
}
