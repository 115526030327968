import React, { useContext, useMemo, useState } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import HostTarget from '../../../types/host/HostTarget';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';

// Constants.
import InstanceTargetHost from "../../../types/instance/InstanceTargetHost";
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";
import { useLicences } from "../../../context/LicenceContext";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import {
    CHART_COLOURS_STATEMENTS, CHART_DATA_TYPE,
    COLOUR_HOST_MEMORY,
    COLOUR_HOST_PROCESSOR,
    DATA_LOADED,
    DATA_LOADING
} from "../../Constants";
import Helper from "../../../helpers/Helper";
import ConditionalRender from "../../../helpers/ConditionalRender";
import api from "../../../api/Base";

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function HostsOverTimeCharts(props: { instance: InstanceTarget, hosts: HostTarget[], plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], applyPeriod: Function }) {
    const [processorSeries, setProcessorSeries] = useState<number[][]>([]);
    const [memorySeries, setMemorySeries] = useState<number[][]>([]);
    const [diskSeries, setDiskSeries] = useState<number[][]>([]);
    const [diskReadPeaks, setDiskReadPeaks] = useState<number[]>([])
    const [diskWritePeaks, setDiskWritePeaks] = useState<number[]>([])
    const [loading, setLoading] = useState(DATA_LOADING)
    const {licences} = useLicences();
    const timeRangeContext = useContext(TimeRangeContext)

    const diskPeaks = useMemo(() => {
        return {
            read: Helper.getPeakValue(diskReadPeaks),
            write: Helper.getPeakValue(diskWritePeaks)
        }
    }, [diskReadPeaks, diskWritePeaks])

    const memoryPeak = useMemo(() => {
        let peaks: number[] = []
        memorySeries.forEach((serie: any) => {
            peaks = peaks.concat(serie.data.map((i: any) => i[1]))
        })
        return Helper.getPeakValue(peaks)
    }, [memorySeries])

    const processorPeak = useMemo(() => {
        let peaks: number[] = []
        processorSeries.forEach((serie: any) => {
            peaks = peaks.concat(serie.data.map((i: any) => i[1]))
        })
        return Helper.getPeakValue(peaks)
    }, [processorSeries])

    // Get the instance hosts processor and memory data over time.
    useMemo(() => {
        // for each host, we need to get processor, memory over time, plus their peak values.
        async function getHostsData() {
            setLoading(DATA_LOADING);
            const hosts: HostTarget[] = []
            await api.get(`datasource/host?id=${props.instance.id}`)
                .then((response: { data: InstanceTargetHost[]; }) => {
                    for (let index = 0; index < response.data.length; index++) {
                        // Get any matching hosts.
                        const matchedHosts = props.hosts.filter(host => host.id === response.data[index].hostid);

                        if (matchedHosts.length === 1) {
                            // Append the matched hosts to any pre-existing hosts.
                            hosts.push(matchedHosts[0]);
                        }
                    }
                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.log('An error occurred:', error);
                })
                .then(async() => {

                });

            const memSeries: any[] = []
            const procSeries: any[] = []
            const diskSeries: any[] = []
            const readPeaks: number[] = []
            const writePeaks: number[] = []

            for (let index = 0; index < hosts.length; index++) {
                // Get the host we're currently dealing with.
                const host = hosts[index];

                // Get host CPU over time.
                await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation&id=${host.id}`).then((response: { data: HostTimeStatistic[]; }) => {
                    procSeries.push({
                        name: `CPU - ${host.name}`,
                        color: COLOUR_HOST_PROCESSOR,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map((item) => {
                            return [getConvertedTimeToUTC(item), Math.round(item.avg)]
                        }),
                        yAxis: 0,
                        zIndex: 2
                    })
                }).catch((error: any) => {
                    console.error('Failed to retrieve processor over time data for host', error, host);
                })

                // Get host memory over time.
                await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=memoryutilisation&id=${host.id}`).then((response: { data: HostTimeStatistic[]; }) => {
                    memSeries.push({
                        name: `Memory - ${host.name}`,
                        color: COLOUR_HOST_MEMORY,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map((item) => {
                            return [getConvertedTimeToUTC(item), Math.round(item.avg)]
                        }),
                        yAxis: 0,
                        zIndex: 1
                    })
                }).catch((error: any) => {
                    console.error('Failed to retrieve memory over time data for host', error, host);
                })

                // Get host reads and writes over time.
                const metrics: string[] = ['time spent reading (ms)', 'time spent writing (ms)'];

                // Get detailed data.
                for (let index = 0; index < metrics.length; index++) {
                    // Prep the metric key for the API call.
                    const metric = metrics[index].replace(' ', '%20');
                    let diskData: any[] = []

                    await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=${metric}&id=${host.id}`).then((response: { data: HostTimeStatistic[]; }) => {
                        diskData = diskData.concat(response.data.map(item => {
                            return [getConvertedTimeToUTC(item), Math.round(item.sum)]
                        }));
                        diskSeries.push({
                            name: `${metrics[index]} - ${host.name}`,
                            color: CHART_COLOURS_STATEMENTS[index],
                            type: 'column',
                            data: diskData,
                            zIndex: 1,
                            yAxis: 0,
                        })

                        if (metrics[index] === 'time spent reading (ms)') {
                            readPeaks.push(Helper.getPeakValue(diskData.map(i => i[1])));
                        } else {
                            writePeaks.push(Helper.getPeakValue(diskData.map(i => i[1])));
                        }
                    }).catch((error: any) => {
                        console.error('Failed to retrieve disk I/O metric over time for host', error, host, metrics[index]);
                    })
                }
            }

            setMemorySeries(memSeries)
            setProcessorSeries(procSeries)
            setDiskSeries(diskSeries)
            setDiskWritePeaks(writePeaks)
            setDiskReadPeaks(readPeaks)
            setLoading(DATA_LOADED);
        }

        void getHostsData();
    }, [props.hosts, timeRangeContext, props.instance.id]);

    // Build the processor and memory chart.
    const processorMemoryChart = useMemo(() => {
        if (processorSeries.length && memorySeries.length) {
            return (
                <React.Fragment>
                    <span className="peak">Peak CPU {processorPeak}%, Peak Memory {memoryPeak}%</span>
                    <br/><br/>
                    <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                     options={chartOptionsGranularity(props.applyPeriod, [...processorSeries, ...memorySeries], Helper.getPeakValue([processorPeak, memoryPeak]), {
                                         credits: {enabled: highchartsCredits(licences)},
                                         timeRangeContext: timeRangeContext,
                                         plotLines: props.plotLines,
                                         tooltip: {
                                             formatter: function () {
                                                 return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
                                             },
                                         },
                                         legend: 'bottom'
                                     })}/>
                </React.Fragment>
            );
        }

        return null
    }, [processorSeries, memorySeries, timeRangeContext, props.plotLines, licences, memoryPeak, processorPeak]);

    // Build the disk read and writes chart.
    const diskChart = useMemo(() => {
        if (diskSeries.length && diskPeaks.read && diskPeaks.write) {
            return (
                <React.Fragment>
                    <span className="peak">Peak Time Spent Reading {Helper.getTimeInEnglish(diskPeaks.read)}, Spent Writing {Helper.getTimeInEnglish(diskPeaks.write)}</span>
                    <br/><br/>
                    <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                     options={chartOptionsGranularity(props.applyPeriod, [...diskSeries], Helper.getPeakValue([diskPeaks.read, diskPeaks.write]), {
                                         credits: {enabled: highchartsCredits(licences)},
                                         timeRangeContext: timeRangeContext,
                                         plotLines: props.plotLines,
                                         legend: 'bottom',
                                         tooltip: {
                                             formatter: function () {
                                                 return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                                             },
                                         },
                                     })}/>
                </React.Fragment>
            );
        }

        return null
    }, [diskSeries, diskPeaks, timeRangeContext, licences, props.plotLines])

    return (
        <React.Fragment>
            <ConditionalRender if={loading === DATA_LOADING}>
                <div className="w-100 text-center text-muted mt-3 time-chart">
                    <div className="loader spinner">
                    </div>
                    <p className="mt-3">
                        Loading data...
                    </p>
                </div>
            </ConditionalRender>
            <ConditionalRender if={loading === DATA_LOADED}>
                {processorMemoryChart}
                {diskChart}
            </ConditionalRender>
        </React.Fragment>
    );
}

export default HostsOverTimeCharts;
