//import React from 'react';

// Constants.
import {
    INSTANCE_TYPE_SQLSERVER,
    INSTANCE_TYPE_MYSQL,
    INSTANCE_TYPE_ORACLE,
    INSTANCE_TYPE_POSTGRES,
    INSTANCE_TYPE_COCKROACHDB,
    INSTANCE_TYPE_DB2,
    INSTANCE_TYPE_INFORMIX, INSTANCE_TYPE_SAPHANA
} from '../../component/Constants';

// Types.
import Instance from '../../types/instance/InstanceTarget';

// Helpers.
import Helper from '../../helpers/Helper';

function InstanceType(props: { instance: Instance }) {

    const tooltip = Helper.getInstanceType(props.instance);

    let iconClassName = '';
    let iconColor = '';

    // For more technology specific icons, see http://fizzed.com/oss/font-mfizz
    switch (props.instance.type) {
        case INSTANCE_TYPE_SQLSERVER:
            iconClassName = 'icon icon-mssql';
            break;
        case INSTANCE_TYPE_MYSQL:
            if (props.instance.version && props.instance.version.toLowerCase().includes('mariadb')) {
                iconClassName = 'icon icon-mariadb';
            } else {
                iconClassName = 'icon icon-mysql-alt';
            }
            break;
        case INSTANCE_TYPE_ORACLE:
            iconClassName = 'icon icon-oracle';
            break;
        case INSTANCE_TYPE_POSTGRES:
            iconClassName = 'icon icon-postgres';
            break;
        case INSTANCE_TYPE_COCKROACHDB:
            iconClassName = 'icon fak fa-cockroachdb';
            break;
        case INSTANCE_TYPE_DB2:
            iconClassName = 'icon fak fa-db2';
            iconColor = '#04992B'
            break;
        case INSTANCE_TYPE_INFORMIX:
            iconClassName = 'icon fak fa-informix';
            iconColor = '#33348F'
            break;
        case INSTANCE_TYPE_SAPHANA:
            iconClassName = 'icon fak fa-sap';
            iconColor = '#027FC5'
            break;
        default:
            // Unknown database type.
            // <i class="icon-mongodb"></i> icon-mongodb
            iconClassName = 'icon icon-database';
            break;
    }

    return (
        <span className="float-center" data-tip={tooltip}>
            <i className={iconClassName} style={{color: iconColor}}>
            </i>
        </span>
    );
}

export default InstanceType;