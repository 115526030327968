import { FilterType } from "../../../typescript/Enums";
import Helper from "../../../helpers/Helper";
import FilterOption from "../../../types/instance/FilterOption";
import { INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER } from "../../Constants";
import ConditionalRender from "../../../helpers/ConditionalRender";


function ActivityTabs(props: {
    instanceType: string,
    waitsLength: number,
    clientsLength: number,
    databasesLength: number,
    sessionsLength: number,
    usersLength: number,
    programsLength: number,
    statementsLength: number,
    batchesLength: number,
    isGroup: boolean,
    filterOptions: FilterOption[]
}) {
    const {
        instanceType,
        waitsLength,
        databasesLength,
        sessionsLength,
        clientsLength,
        usersLength,
        programsLength,
        statementsLength,
        batchesLength,
        isGroup
    } = props
    const badgeStyle = (filterType: FilterType): string => {
        // TODO Find out why the options array becomes empty...
        return filterType < props.filterOptions.length &&
        props.filterOptions[filterType].filters.length > 0 ? "badge bg-filter" : "badge bg-info";
    }

    return (
        <ul id="menu-inner" className="col nav nav-tabs" role="tablist">
            <ConditionalRender if={!isGroup}>
                <li className="nav-item" role="presentation">
                    <a id="statements-tab" data-bs-toggle="tab" href="#statements" className="active"
                       role="tab"
                       aria-controls="statements" aria-selected="true">Statements</a>
                    <span className="badge bg-info"
                          data-tip="Total statement count">{Helper.getFormattedBadgeCount(statementsLength)}</span>
                </li>
                {instanceType === INSTANCE_TYPE_SQLSERVER && (
                    <li className="nav-item" role="presentation">
                        <a id="batches-tab" data-bs-toggle="tab" href="#batches" role="tab"
                           aria-controls="batches" aria-selected="true">Batches</a>
                        <span className="badge bg-info"
                              data-tip="Total batches count">{Helper.getFormattedBadgeCount(batchesLength)}</span>
                    </li>
                )}
            </ConditionalRender>
            <ConditionalRender if={isGroup}>
                <li className="nav-item" role="presentation">
                    <a id="statements-tab" data-bs-toggle="tab" href="#groupedstatements" className="active"
                       role="tab"
                       aria-controls="statements" aria-selected="true">Grouped Statements</a>
                    <span className="badge bg-info"
                          data-tip="Total statement count">{Helper.getFormattedBadgeCount(statementsLength)}</span>
                </li>
                {instanceType === INSTANCE_TYPE_SQLSERVER && (
                    <li className="nav-item" role="presentation">
                        <a id="groupedbatches-tab" data-bs-toggle="tab" href="#groupedbatches" role="tab"
                           aria-controls="groupedbatches" aria-selected="true">Grouped Batches</a>
                        <span className="badge bg-info"
                              data-tip="Total batches count">{Helper.getFormattedBadgeCount(batchesLength)}</span>
                    </li>
                )}
            </ConditionalRender>
            <li className="nav-item" role="presentation">
                <a id="waits-tab" data-bs-toggle="tab" href="#waits" role="tab" aria-controls="waits"
                   aria-selected="true">Waits</a>
                <span className={badgeStyle(FilterType.Waits)}
                      data-tip="Total wait state count">{Helper.getFormattedBadgeCount(waitsLength)}</span>
            </li>
            <li className="nav-item" role="presentation">
                <a id="databases-tab" data-bs-toggle="tab" href="#databases" role="tab"
                   aria-controls="databases"
                   aria-selected="true">{((instanceType === INSTANCE_TYPE_ORACLE) ? 'Schemas' : 'Databases')}</a>
                <span className={badgeStyle(FilterType.Databases)}
                      data-tip="Total database count">{Helper.getFormattedBadgeCount(databasesLength)}</span>
            </li>
            <li className="nav-item" role="presentation">
                <a id="sessions-tab" data-bs-toggle="tab" href="#sessions" role="tab"
                   aria-controls="sessions" aria-selected="true">Sessions</a>
                <span className={badgeStyle(FilterType.Sessions)}
                      data-tip="Total session count">{Helper.getFormattedBadgeCount(sessionsLength)}</span>
            </li>

            <li className="nav-item" role="presentation">
                <a id="clients-tab" data-bs-toggle="tab" href="#clients" role="tab"
                   aria-controls="clients"
                   aria-selected="true">Clients</a>
                <span className={badgeStyle(FilterType.Clients)}
                      data-tip="Total client count">{Helper.getFormattedBadgeCount(clientsLength)}</span>
            </li>
            <li className="nav-item" role="presentation">
                <a id="users-tab" data-bs-toggle="tab" href="#users" role="tab" aria-controls="users"
                   aria-selected="true">Users</a>
                <span className={badgeStyle(FilterType.Users)}
                      data-tip="Total user count">{Helper.getFormattedBadgeCount(usersLength)}</span>
            </li>
            <li className="nav-item" role="presentation">
                <a id="programs-tab" data-bs-toggle="tab" href="#programs" role="tab"
                   aria-controls="programs" aria-selected="true">Programs</a>
                <span className={badgeStyle(FilterType.Programs)}
                      data-tip="Total program count">{Helper.getFormattedBadgeCount(programsLength)}</span>
            </li>
        </ul>
    );
}

export default ActivityTabs;
