// Third-party packages.
import React from "react";
import SqlServerStatistics from './sqlstatistics';
import Db2Statistics from './db2';
import OracleStatistics from './oracle';
import CockroachdbStatistics from './cockroachdb'

const COMPONENTS_MAPPING: {[key: string]: React.ElementType } = {
    'sqlserver': SqlServerStatistics,
    'oracle': OracleStatistics,
    'db2': Db2Statistics,
    'cockroachdb': CockroachdbStatistics
}

type StatisticsDataFactoryProps = {
    [key: string]: any,
}

const StatisticsDataFactory = (props: StatisticsDataFactoryProps) => {
    const Component: React.ElementType = COMPONENTS_MAPPING[props.type]
    if (typeof COMPONENTS_MAPPING[props.type] !== 'undefined') {
        return (
            <React.Fragment>
                <Component {...props}/>
            </React.Fragment>
        )
    } else {
        return null
    }
};

export default StatisticsDataFactory;
