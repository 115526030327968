import { Link, Switch, Route } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

// Types.
import Period from '../../../types/Period';

// Constants.
import {ACTIONS} from '../../../component/Constants';

// Apis.

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

export default function Filters (props: { period: Period, toggleMenu: Function, applyPeriod: Function })
{

	function refresh ()
	{
		// setLoading (true);
	}

	return (
		<div id="content">
			<Helmet>
				<title>Saved Snapshot - Settings - DBmarlin</title>
				<meta name="description" content="Manage your saved Snapshots" />
			</Helmet>

			{/* Heading */}
			<div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
				<Breadcrumb heading="Snapshots">
					<Link to="/admin">Settings</Link>
					<span>Snapshots</span>
				</Breadcrumb>
				<HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={refresh} />
			</div>

			<div className="loader">
				<ConditionalRender if={false}>
					<div className="bar"></div>
				</ConditionalRender>
			</div>

			<div className="row row-cols-1">
				<Switch>
					<Route exact path="/admin/filters/create" render={() => <Detail actionId={ACTIONS.CREATE.type} />} />
					<Route exact path="/admin/filters/:actionId/:filterId" render={(props) => <Detail {...props} actionId={props.match.params.actionId} filterId={Number(props.match.params.filterId)} />} />
					<Route exact path="/admin/filters" render={() => <List />} />
				</Switch>
			</div>
		</div>
	);
}
