import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

// Third-party packages.
import * as dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Column, usePagination, useSortBy, useTable } from 'react-table';

// Constants.
import { DATA_EMPTY, DATA_FAILED, DATA_LOADING } from "../../../component/Constants";

// Components.
import AlertRules from "../../../types/Alerts/AlertRules";
import NoData from "../../../component/NoData";
import TablePagination from "../../../component/TablePagination";
import { getDefaultPageSize, stringFormatting } from "../../../helpers/utils";
import SortClass from "../../../component/SortClass";

function List(props: { loading: number, refresh: Function, alertRules: AlertRules[] }) {
    const [tableFilter, setTableFilter] = useState<string>('');

    function refresh() {
        props.refresh();
    }

    function clearTableFilter() {
        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data: AlertRules[] = useMemo(() => props.alertRules.filter(alertRule => {
        return (
            (alertRule.name === null ? '' : alertRule.name).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (alertRule.entitytype === null ? '' : alertRule.entitytype).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (alertRule.statistic === null ? '' : alertRule.statistic).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (alertRule.entity === null ? '' : alertRule.entity).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (alertRule.metrictype === null ? '' : alertRule.metrictype).toLowerCase().includes(tableFilter.toLowerCase())
        )
    }), [props.alertRules, tableFilter]);


    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            defaultCanSort: false,
        },
        {
            Header: 'Rule',
            accessor: 'name',
            className: 'fw-bold',
            defaultCanSort: true,
        },
        {
            Header: 'Scope',
            accessor: 'entity',
            defaultCanSort: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    <span>{`${!cell.row.values.entity ? cell.row.values.entitytype === 'host' ? 'All Hosts' : 'All Instances' : cell.row.values.entity}`}</span>
                </React.Fragment>
            )
        },
        {
            Header: 'Type',
            accessor: 'entitytype',
            defaultCanSort: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    {/*@ts-ignore*/}
                    <span className='text-capitalize'>{`${cell.row.original.entitytype === 'datasource' ? 'instance' : cell.row.original.entitytype} ${cell.row.original.metrictype}`}</span>
                </React.Fragment>
            )
        },
        {
            Header: 'Metric',
            accessor: 'statistic',
            defaultCanSort: true,
            className: 'text-capitalize',
            Cell: ({ cell }) => (
                <React.Fragment>
                    {stringFormatting(cell.row.values.statistic)}
                </React.Fragment>
            )
        },
        {
            Header: 'Threshold',
            accessor: 'threshold',
            defaultCanSort: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    {/*@ts-ignore*/}
                    {cell.row.original.decreasing ? '<=' : '>='} {cell.row.values.threshold}{cell.row.original.units === '%' ? '%' : cell.row.original.units === 'ms' ? 'ms' : ''}
                </React.Fragment>
            )
        },
        {
            Header: 'Enabled',
            accessor: 'disabled',
            disableSortBy: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    <div className='form-check form-switch'>
                        <input checked={!cell.row.values.disabled}
                               type="checkbox"
                               id="keepdata"
                               className="form-check-input"
                               disabled={true}
                               data-lpignore={true}
                        />
                    </div>
                </React.Fragment>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            className: 'actions',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <Link to={`/admin/alert-rules/edit/${cell.row.values.id}`} role="button"
                          className="btn btn-sm btn-primary">
                        <i className="fal fa-edit fa-fw"/><span>Edit</span>
                    </Link>
                    <Link to={`/admin/alert-rules/delete/${cell.row.values.id}`} role="button"
                          className="btn btn-sm btn-danger">
                        <i className="fal fa-trash-alt fa-fw"/><span>Delete</span>
                    </Link>
                </React.Fragment>
            )
        }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{ id: 'name', desc: false }]
            }
        },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-exclamation-triangle fa-fw mr-10" aria-hidden="true"/>
                    Alert Rules
                    <span className="badge bg-info" data-tip="Total alert rules count">{data.length}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseAlerts" aria-expanded="false" aria-controls="collapseAlerts"/>
                </div>
                <div id="collapseAlerts" className="card-body collapse show">
                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"/>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>No Alert Rules Found -
                                    Click to Refresh
                                </button>
                            </p>
                        </div>
                    ) : (
                        props.loading === DATA_FAILED ? (
                            <div className="w-100 text-center text-muted my-3">
                                <i className="fal fa-ban fa-fw fa-2x text-danger"/>
                                <p>
                                    <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve
                                        Alert Rules - Click to Refresh
                                    </button>
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row row-cols-1 row-cols-md-2 table-search">
                                    <div className="col col-md-9">
                                        <Link to="/admin/alert-rules/create" role="button"
                                              className="btn btn-sm btn-success">
                                            <i className="fal fa-plus-square fa-fw"/><span>Create</span>
                                        </Link>
                                        <CSVLink role="button"
                                                 headers={[
                                                     { label: 'ID', key: 'id' },
                                                     { label: 'Alert Rule', key: 'name' },
                                                     { label: 'Scope', key: 'aggregation' },
                                                     { label: 'Type', key: 'type' },
                                                     { label: 'Threshold', key: 'threshold' }
                                                 ]}
                                                 data={data}
                                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Alert Rules.csv`}
                                                 className="btn btn-sm btn-primary">
                                            <i className="fal fa-file-export fa-fw"/><span>Export</span>
                                        </CSVLink>
                                        <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                            className="far fa-undo"/><span>Clear</span></button>
                                    </div>
                                    <div className="col col-md-3">
                                        <input type="text" autoFocus className="form-control form-control-sm"
                                               placeholder="Search"
                                               value={tableFilter} data-lpignore={true}
                                               onChange={(e) => setTableFilter(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className={(column as any).className}>
                                                        {column.render('Header')}
                                                        <SortClass column={column} />
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                        {page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td {...cell.getCellProps()}
                                                                className={(cell.column as any).className}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    <NoData
                                        error={null}
                                        loading={props.loading === DATA_LOADING}
                                        length={data.length}
                                    />
                                    <TablePagination
                                        length={data.length}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pageOptions={pageOptions}
                                        pageCount={pageCount}
                                        canPreviousPage={canPreviousPage}
                                        previousPage={previousPage}
                                        canNextPage={canNextPage}
                                        nextPage={nextPage}
                                        gotoPage={gotoPage}
                                        pageIndex={pageIndex}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
