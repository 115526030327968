// Third-party packages.
import { Dayjs } from 'dayjs';

// Types.
import FilterOption from '../types/instance/FilterOption';

// Components.
import FilterDropdown from './FilterDropdown';

// Helpers.
import { dateWithTimeZone, defaultTimeZone, getParameter, getFilterOptions }
	from '../helpers/utils';

// Constants.
import { PERIOD_CUSTOM } from './Constants';

export default function FilterListButton (props: { applyPeriod: Function })
{
	const { applyPeriod } = props;

	function applyFilter (parameters: string): void
	{
		// Convert API parameters to UI parameters.
		// TODO Consider common parameter representation.
		// Would have liked to use a link but it does not change the date e.g.
		// <Link to={makeLink (item.parameters)} ... >{item.name}</Link>
		// Links are pseudo-links: the address bar changes but App.tsx is not
		// revisited from the beginning. Instead the current page is re-rendered
		// with the existing time range.
		// Would also have had to specify the interval.
		// Change the state instead. Would appear to be order sensitive:
		// apply the time period and filter options in a single call.
		const from: string | null = getParameter (parameters, 'from');
		const to: string | null = getParameter (parameters, 'to');
		const tz: string | null = getParameter (parameters, 'tz');
		const fromDate: Dayjs =
			dateWithTimeZone (from, tz).tz (defaultTimeZone ());
		const toDate: Dayjs = dateWithTimeZone (to, tz).tz (defaultTimeZone ());
		const options: FilterOption[] = getFilterOptions (parameters);
		applyPeriod (PERIOD_CUSTOM, fromDate, toDate, options);
	}

	return (
		<FilterDropdown filterId="filter" dropdownId="filterDropdown" dataTip="Choose snapshot" applyFilter={applyFilter} />
	);
}
