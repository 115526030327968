// React.
import { useEffect, useState } from 'react';

// Third-parties.
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { TreeTable, TreeTableExpandedKeysType,
	TreeTableSelectionKeys } from 'primereact/treetable';

// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

// Components
import GenericPlan from './GenericPlan';
import KnowledgeBasePlanButton from "../../buttons/KnowledgeBasePlanButton";
import IndexRecommendations from "./IndexRecommendations";

// Helpers.
import { Step, formatNumber, getStepDetails } from '../../../helpers/utils';

export default function CockroachPlan (props: { executionPlan: ExecutionPlan })
{
	const [treeSteps, setTreeSteps] = useState<Step[]> ([]);
	const [expandedKeys, setExpandedKeys] =
		useState<TreeTableExpandedKeysType> ({});
	const [selectedStepKey, setSelectedStepKey] =
		useState<TreeTableSelectionKeys> (null);
	const [stepDetails, setStepDetails] = useState<any[]> ([]);
	const [stepOperation, setStepOperation] = useState<string> ('');
	const [error, setError] = useState<string | null>(null);

	function getStepOperation (details: any[]): string
	{
		const detail: any[] =
			details.filter (detail => detail.name === 'Name');
		return detail.length === 1 ? detail[0].value : '';
	}

	useEffect (() =>
	{
		// These closure variables are required by the recursive functions.
		let n: number = 0;
		const expanded: TreeTableExpandedKeysType = {};

		function getKey (): string
		{
			return String (++n);
		}

		function logMissedField (field: string, type: string): void
		{
			console.log ('Missed ' + field + ': ' + type);
		}

		function getData (step: any): any
		{
			const data: any = {};

			for (const field in step)
			{
				const type: string = typeof (step[field]);

				if (type === 'string' || type === 'boolean')
				{
					data[field] = String (step[field]);
				}
				else if (type === 'number')
				{
					data[field] = formatNumber (step[field]);
				}
				else if (type === 'object')
				{
					if (field !== 'Children')
					{
						logMissedField (field, type);
					}
				}
				else
				{
					logMissedField (field, type);
				}
			}

			return data;
		}

		function getChildren (step: any): Step[]
		{
			const children: Step[] = [];

			// Old-style plans do not have a field called Children.
			if (step.Children)
			{
				for (const child of step.Children)
				{
					children.push (getStep (child));
				}
			}

			return children;
		}

		function getStep (step: any): Step
		{
			const key: string = getKey ();

			// Expand all rows as a side-effect.
			expanded[key] = true;

			const treeStep: Step =
			{
				key: key,
				data: getData (step),
				children: getChildren (step)
			};

			return treeStep;
		}

		try
		{
			const plan: any = JSON.parse (props.executionPlan.sqlplan);
			// console.log ('plan', plan);
			const tree: Step[] = [ getStep (plan) ];
			const details: any[] = getStepDetails (tree, '1');
			setTreeSteps (tree);
			setExpandedKeys (expanded);
			setSelectedStepKey ('1');
			setStepDetails (getStepDetails (tree, '1'));
			setStepOperation (getStepOperation (details));
		}
		catch (x: any)
		{
			setTreeSteps ([]);
			setExpandedKeys ({});
			setSelectedStepKey (null);
			setStepDetails ([]);
			setStepOperation ('');
			setError (String (x));
		}
	}, [props.executionPlan.sqlplan]);

	useEffect (() =>
	{
		const details: any[] = getStepDetails (treeSteps, selectedStepKey);
		setStepDetails (getStepDetails (treeSteps, selectedStepKey));
		setStepOperation (getStepOperation (details));
	}, [treeSteps, selectedStepKey]);

	// The bracket must be present and on the same line as the return.
	if (error)
	{
		return (<>
			<GenericPlan executionPlan={props.executionPlan} />
			<IndexRecommendations
				indexRecommendations={props.executionPlan.indexrecommendations}
			/>
		</>)
	}

	return (<>
		<Splitter>
			<SplitterPanel size={65} className="plan-master-panel">
				<TreeTable
					value={treeSteps}
					expandedKeys={expandedKeys}
					onToggle={e => setExpandedKeys (e.value)}
					selectionMode="single"
					resizableColumns
					reorderableColumns
					selectionKeys={selectedStepKey}
					onSelectionChange={e => setSelectedStepKey (e.value)}
					className="treetable-responsive"
				>
					<Column header="Operation" field="Name" expander
						className="col-width-35 plan-first-column"/>
					<Column header="Table" field="Table"
						className="col-width-35"/>
					<Column header="Estimated Row Count"
						field="Estimated Row Count"
						className="col-width-30 text-end"/>
				</TreeTable>
			</SplitterPanel>
			<SplitterPanel size={35} className="plan-detail-panel">
				<DataTable value={stepDetails} resizableColumns stripedRows>
					<Column header="Name" field="name"
						className="col-width-50 plan-first-column"/>
					<Column header="Value" field="value"
						className="col-width-50"/>
				</DataTable>
				<KnowledgeBasePlanButton databaseType="cockroachdb"
										 planOperation={stepOperation}
										 planOption=""/>
			</SplitterPanel>
		</Splitter>
		<IndexRecommendations
			indexRecommendations={props.executionPlan.indexrecommendations}
		/>
	</>)
}
