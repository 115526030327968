import api from "./Base";
import IntegrationType from "../types/integration/IntegrationType";
import EmailService from "../types/integration/EmailService";

const IntegrationsApi = {
    getIntegrationData:async (name: string) => {
        let url = `/parameter?name=${name}.%25`;
        let data: IntegrationType[] = [];

        await api.get<IntegrationType[]>(url)
            .then((response) => {
                data = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve pager duty data.', error);
            })
            .then(function () {});
        return data;
    },

    getEmailIntegration:async (name: string) => {
        let url = `/parameter?name=${name}.%25`;
        let emailIntegrationData: EmailService[] = [];

        await api.get<EmailService[]>(url)
            .then((response) => {
                emailIntegrationData = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve email service data.', error);
            })
            .then(function () {});
        return emailIntegrationData;
    },
}

export default IntegrationsApi;