// React.
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Helpers.
import { getToken, updateExpiration } from './helpers/utils';
import ConditionalRender from "./helpers/ConditionalRender";

// Types.
import InstanceTarget from './types/instance/InstanceTarget';

// Constants.
import {
    DATA_LOADED,
    DATA_LOADING,
} from './component/Constants';


// Views.
import Login from './views/login';
import Content from "./Content";

// Styles.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'highlight.js/styles/vs.css';
import 'html-query-plan/css/qp.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './css/App.css';
import './css/html-query-plan-overrides.css';
import './css/primereact-overrides.css';
import './css/primereact-execution-plans.css';
import './css/tooltip.css';
import './fonts/mfizz/font-mfizz.css';
import './fonts/fontawesome/css/all.min.css';
import './fonts/fontawesome/css/custom-icons.min.css';
import 'highlight.js/styles/vs.css'
import api from "./api/Base";

// Constants
import { EXPIRATION_KEY, LAST_PAGE_KEY } from './component/Constants';

function App() {
    const [loading, setLoading] = useState<number>(DATA_LOADING);

    const [isLogged, setIsLogged] = useState(false);
    const [mustLogIn, setMustLogIn] = useState(false);
    const [loginError, setLoginError] = useState('');

    const history = useHistory();
    const location = useLocation();

    const isTokenExpired = () => {
        const savedExpirationKey = window.localStorage.getItem(EXPIRATION_KEY);
        return savedExpirationKey ? new Date() > new Date(savedExpirationKey) : false;
    };

    useEffect(() => {
        const checkTokenAndRedirect = () => {

            const savedExpirationKey = window.localStorage.getItem(EXPIRATION_KEY);
            const savedToken = getToken();

            // if user is logged out redirect to login page
            if (savedExpirationKey && !savedToken) {
                window.localStorage.setItem(LAST_PAGE_KEY, location.pathname + location.search);
                window.location.href = '/login';
            }

            if (isTokenExpired()) {
                window.localStorage.setItem(LAST_PAGE_KEY, location.pathname + location.search);
                history.push('/login?error=expired');
            } else {
                updateExpiration();
            }
        };

        if (location.pathname !== '/login' && mustLogIn) {
            checkTokenAndRedirect();
        }
    }, [location.pathname]);

    const loginRequired = async() => {
        let url = `datasource?full=true&id=0`;
        let isRequired = true;
        const savedToken = getToken();

        if (!savedToken) {
            try {
                const response = await api.get<InstanceTarget[]>(url);
                if (response && Array.isArray(response.data)) {
                    window.localStorage.removeItem('dbmar-token');
                    isRequired = false;
                }
            } catch (error) {
                isRequired = true;
            }
        }
        return isRequired;
    };


    useEffect(() => {
        const checkLogin = async() => {
            const logInRequired = await loginRequired();
            setMustLogIn(logInRequired)
            const savedToken = getToken();
            if (logInRequired) {
                if (location.pathname !== '/login' && !savedToken) {
                    setLoading(DATA_LOADED)
                    history.push('/login')
                    window.localStorage.setItem(LAST_PAGE_KEY, location.pathname + location.search);
                } else if (location.pathname !== '/login') {
                    try {
                        const url = `datasource?full=true&id=0`;
                        const response = await api.get<InstanceTarget[]>(url);
                        if (response && Array.isArray(response.data)) {
                            setIsLogged(true);
                            window.localStorage.removeItem(LAST_PAGE_KEY);
                            setLoading(DATA_LOADED);
                        } else {
                            window.localStorage.removeItem('dbmar-token');

                            window.localStorage.setItem(LAST_PAGE_KEY, location.pathname + location.search);
                            setIsLogged(false);
                            setLoginError('invalid');
                            setLoading(DATA_LOADED);
                            // window.location.href = '/login?error=invalid';
                        }
                    } catch (error: any) {
                        setIsLogged(false);
                        setLoading(DATA_LOADED);
                        window.localStorage.removeItem('dbmar-token');
                        // window.location.href = '/login';
                    }
                } else {
                    setLoading(DATA_LOADED)
                }
            } else {
                window.localStorage.removeItem(EXPIRATION_KEY);
                window.localStorage.removeItem('dbmar-token');
                setLoading(DATA_LOADED);
                setIsLogged(true);
            }
        };

        void checkLogin();
    }, []);

    if (document.location.pathname === '/' && process.env.NODE_ENV !== 'test') {

        // Todo: remove this JavaScript redirect once we have a root dashboard view sorted.
        document.location.href = `${document.location.href}instances`
    }

    return (
        <>
            <ConditionalRender if={mustLogIn && !isLogged && loading === DATA_LOADED}>
                <Login loginError={loginError}/>
            </ConditionalRender>
            <ConditionalRender if={isLogged && loading === DATA_LOADED}>
                <Content showLogOut={mustLogIn}/>
            </ConditionalRender>
        </>
    );
}

export default App;
