import PropTypes from 'prop-types';

// Types.
import Period from '../../../types/Period';

// Components.
import ClientsTable from '../table/ClientsTable';

function Clients(props: { instanceId: number, batchId?: string, statementId?: string, period: Period, totalInstanceTime: number, setClientsOptions: Function, setFilterOptions: Function }) {    
    return (
        <div id="clients" className="tab-pane fade" role="tabpanel" aria-labelledby="clients-tab">
            <ClientsTable period={props.period} instanceId={props.instanceId} batchId={props.batchId} statementId={props.statementId} instanceTime={props.totalInstanceTime} setClientsOptions={props.setClientsOptions} setFilterOptions={props.setFilterOptions}/>
        </div>
    );
}

Clients.propTypes = {
    setClientsOptions: PropTypes.func.isRequired
};

export default Clients;
