// React.
import { useContext, useState } from 'react';

// Third-parties.
import { useToasts } from 'react-toast-notifications';

// Types.
import Filter from '../types/Filter';
import Period from '../types/Period';

// Contexts.
import { FilterContext } from '../context/FilterContext';

// Components.
import Alert from './Alert';

// Helpers.
import { defaultTimeZone, fetchFilters, sendData } from '../helpers/utils';

// Local constants.
const DEFAULT_ERROR = 'Sorry, but something went wrong and the requested action failed. If the issue persists, please raise a support request.';

export default function FilterSaveButton (props: { period: Period })
{
	const { period } = props;
	const { filters, setFilters } = useContext (FilterContext);
	const [ isError, setIsError ] = useState<boolean> (false);
	const [ error, setError ] = useState<string> (DEFAULT_ERROR);
	const [ isValid, setIsValid ] = useState<boolean> (true);
    const [ name, setName ] = useState<string> ('');
    const [ description, setDescription ] = useState<string> ('');
    const [ keepData, setKeepData ] = useState<boolean> (false);
	const [ nameClass, setNameClass ] = useState<string> ('');
	const [ nameUsed, setNameUsed ] = useState<boolean> (false);

	const { addToast } = useToasts();

	function isDuplicateName (value: string): boolean
	{
		return filters.findIndex ((object: Filter) =>
			object.name.toLowerCase () === value.toLowerCase ()) >= 0;
	}

	function handleNameBlur ()
	{
		// Trim any white space.
		const value = name.trim ();

		// Save to state.
		setName (value);

		if (value === '')
		{
			setNameClass ('is-invalid');
			setNameUsed (false);
		}
		else if (isDuplicateName (value))
		{
			setNameClass ('is-invalid');
			setNameUsed (true);
		}
		else
		{
			setNameClass ('is-valid');
			setNameUsed (false);
		}
	}

	function isValidForm (): boolean
	{
		return nameClass === 'is-valid';
	}

	async function saveFilterAndRefreshList (method: string, data: any)
	{
		try
		{
			await sendData (method, '/filter', data);
			setFilters (await fetchFilters ());

			// Reset fields ready for next time.
			setIsError (false);
			setError (DEFAULT_ERROR);
			setIsValid (true);
			setName ('');
			setDescription ('');
			setKeepData (false);
			setNameUsed (false);
			setNameClass ('');
		}
		catch (x: any)
		{
			addToast('Snapshot did not save', { appearance: 'warning', autoDismiss: true });
			console.error (x);
			setIsError (true);
			setError (x.message);
		}
		addToast('Snapshot saved', { appearance: 'info', autoDismiss: true });
	}

	async function saveFilter ()
	{
		// Validate the form.
		if (!isValidForm ())
		{
			addToast('Snapshot did not save', { appearance: 'warning', autoDismiss: true });
			setIsValid (false);
			return;
		}

		setIsValid (true);
		const parameters: string =
			'?from=' + period.api.current.from +
			'&to=' + period.api.current.to +
			'&tz=' + defaultTimeZone () +
			period.filters.parameters;

		const data =
		[{
			id: 0,
			name,
			parameters,
			keepdata: keepData,
			description
		}];

		saveFilterAndRefreshList ('POST', data);
	}
    return (<>
		<button id="saveFilter" type="button" className="btn btn btn-dropdown dropdown-toggle btn-heading" data-bs-toggle="dropdown" aria-expanded="false" data-class="text-center" data-tip="Save snapshot">
			<i className="fal fa-camera-alt fa-fw" aria-hidden="true"></i>
		</button>
		<div id="saveFilterDropdown" className="dropdown-menu filter-dropdown-menu" aria-labelledby="saveFilter" >
			<div className="row" onClick={(e) => e.stopPropagation ()}>
				<div className="card-header">
					<i className="fal fa-camera-alt fa-fw mr-10" aria-hidden="true"></i>
					Save snapshot
				</div>
				<div className="card-body">
					{isError && (
						<Alert message={error} heading={`Failed to create snapshot`} variant="alert-danger" />
					)}

					{!isValid && (
						<Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
					)}

					<p>Please complete the required details below:</p>

					<div className="row g-0 form">

						{/* Name */}
						<div className="col-xs-12 col-md-3">
							<span className="required"><label htmlFor="name" className="reminder" data-tip="Snapshot name">Name</label></span>
						</div>
						<div className="col-xs-12 col-md-9 col-lg-9">
							<input
								type="text"
								id="name"
								autoFocus
								className={`form-control ${nameClass}`}
								required
								maxLength={250}
								onBlur={handleNameBlur}
								onChange={e => setName (e.target.value)}
								value={name}
								disabled={false}
								data-lpignore={true}
							/>
							{nameUsed && (
								<small className="text-danger">Snapshot name already used.</small>
							)}
						</div>
						<div className="w-100"></div>

						{/* Description */}
						<div className="col-xs-12 col-md-3">
							<label htmlFor="name" className="reminder" data-tip="Description">Description</label>
						</div>
						<div className="col-xs-12 col-md-9 col-lg-9">
							<input
								type="text"
								id="description"
								className={`form-control`}
								maxLength={250}
								onChange={e => setDescription (e.target.value)}
								value={description}
								disabled={false}
								data-lpignore={true}
							/>
						</div>
						<div className="w-100"></div>

						{/* Keep Data */}
						<div className="col-xs-12 col-md-3">
							<label htmlFor="keepdata" className="reminder" data-tip="Keep data">Keep Data</label>
						</div>
						<div className="col-xs-12 col-md-9 col-lg-9">
							<div className="form-check form-switch">
								<input
									type="checkbox"
									id="keepdata"
									className="form-check-input"
									onChange={() => setKeepData (!keepData)}
									checked={keepData}
									disabled={false}
									data-lpignore={true}
								/>
							</div>
						</div>
						<div className="w-100"></div>


					</div>
				</div>
			</div>
			<div className="row g-0 form">
				{/* Actions */}
				<div className="col-xs-12 col-md-12 col-lg-12 actions p-2">
					<button type="button" className="btn btn-secondary" data-bs-dismiss="dropdown"><i className="fal fa-times-circle fa-fw fa-sm"></i><span>Cancel</span></button>

					<button className="btn btn-success" onClick={saveFilter}><i className="fal fa-save fa-fw fa-sm"></i><span>Create</span></button>
				</div>
		</div>
		</div>
    </>);
}
