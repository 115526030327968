// React.
import { useMemo, useState } from 'react';

// Third-parties.
import * as dayjs from 'dayjs';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { CSVLink } from 'react-csv';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';

// Components.
import NoData from "../../NoData";
import TablePagination from "../../TablePagination";

// Helper.
import { getDefaultPageSize } from '../../../helpers/utils';
import { TransactionContentionType } from "../../../types/instance/TransactionContentionType";
import ExpandableCell from "../../ExpandableCell";
import Helper from "../../../helpers/Helper";

export default function TransactionsContentionTable(props: {
    error: string | null,
    loading: boolean,
    data: TransactionContentionType[],
    instance: InstanceTarget
}) {
    const { error, loading, data, instance } = props;
    const [tableFilter, setTableFilter] = useState<string>('');

    // CSV headers
    let headers: any[] = useMemo(() => {
        return [
            {
                Header: 'Blocking Transaction',
                key: 'blockingtxn'
            },
            {
                Header: 'Waiting Transaction',
                key: 'waitingtxn'
            },
            {
                Header: 'Contention Duration',
                key: 'contentionduration'
            }
        ]
    }, []);

    // Table columns
    const columns: Column[] = useMemo(() => {
        return [
            {
                Header: 'Blocking Transaction',
                accessor: 'blockingtxn',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic'
            },
            {
                Header: 'Waiting Transaction',
                accessor: 'waitingtxn',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic'
            },
            {
                Header: 'Contention Duration',
                accessor: 'contentionduration',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
                Cell: ({ cell }: any) => Helper.getTimeInEnglish(cell.value)
            },
            {
                Header: 'Blocking SQL Array',
                accessor: 'blockingsqlarray',
                className: 'w-25',
                disableSortBy: true,
                canSort: false,
                Cell: ({ cell: { value } }) => <ExpandableCell data={value}/>,
            },
            {
                Header: 'Waiting SQL Array',
                accessor: 'waitingsqlarray',
                disableSortBy: true,
                className: 'w-25',
                canSort: false,
                Cell: ({ cell: { value } }) => <ExpandableCell data={value}/>,
            }
        ]
    }, [instance.id]);

    const tableData = useMemo(() =>
            data.filter((row) =>
                row.blockingtxn.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.waitingtxn.toLowerCase().includes(tableFilter.toLowerCase())),
        [data, tableFilter]);

    const csvData = useMemo(() => {
        // Escape embedded double-quotation marks (") in the SQL text.
        const csvData: any[] = JSON.parse(JSON.stringify(data));

        csvData.forEach((item) => {
            if (item.sqltext) {
                item.sqltext = item.sqltext.replaceAll('"', '""');
            }
        });

        return csvData;
    }, [data]);

    const
        {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            setPageSize,
            pageOptions,
            pageCount,
            canPreviousPage,
            previousPage,
            canNextPage,
            nextPage,
            gotoPage,
            state: { pageIndex, pageSize }
        } = useTable(
            {
                columns,
                data: tableData,
                initialState:
                    {
                        pageIndex: 0,
                        pageSize: getDefaultPageSize()
                    },
                disableSortRemove: true
            }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }

    // The bracket must be present and on the same line as the return.
    return (
        <div className="card">
            <div className="card-header">
                <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                Transaction Contention
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapse-usage" aria-expanded="false" aria-controls="collapse-usage"/>
            </div>
            <div id="collapse-usage" className="card-body collapse show">
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 headers={headers}
                                 data={csvData}
                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - SQL Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table">
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={(column as any).headerClassName}>
                                        {column.render('Header')}

                                        {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                <i className="fal fa-sort-amount-up-alt"></i> :
                                                <i className="fal fa-sort-amount-down-alt"></i>) :
                                            <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={(cell.column as any).className}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <NoData error={error} loading={loading}
                        length={data.length}/>
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </div>
    )
}
