import { ChangeEvent, useEffect, useState } from 'react';
import Tags from "../../../types/Tags";
import ConditionalRender from "../../../helpers/ConditionalRender";
import Helper from "../../../helpers/Helper";
import './style.scss';

const Tagging = (props: { tags: Tags[], allTags: Tags[], setTags: Function, instanceId: number | undefined }) => {
    const {tags, setTags, allTags} = props;
    const [tagsWithoutDuplicates, setTagsWithoutDuplicates] = useState<Tags[]>([]);
    const [suggestions, setSuggestions] = useState<Tags[]>([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [focusedInputIndex, setFocusedInputIndex] = useState<number>(-1);

    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        // Restricting characters `,` and `;` from being entered
        const sanitizedValue = value.replace(/[^A-Za-z0-9\s-]/g, ''); // Allow only numbers, letters, spaces, and hyphens

        const updatedFields: any[] = [...tags];
        updatedFields[index][name] = sanitizedValue;
        setTags(updatedFields);

        if (sanitizedValue.length >= 2) {
            const matchingOptions = tagsWithoutDuplicates.filter(option =>
                option.tagname.toLowerCase().includes(sanitizedValue.toLowerCase())
            );
            // Remove from lists tags that are already added
            const filteredList = Helper.filterArraysByKey(matchingOptions, tags, 'tagname');
            setSuggestions(filteredList);
        }
    };

    useEffect(() => {
        const list: any = Helper.filterTagsByTagName(allTags)
        setTagsWithoutDuplicates(list)
    }, [allTags]);


    const showSuggestions = (event: any, index: number) => {
        if (suggestions.length && event.target.value.length >= 2) {
            setSuggestionsVisible(true)
            setFocusedInputIndex(index);
        }
        if (event.target.value.length < 2) {
            setSuggestionsVisible(false)
            setFocusedInputIndex(-1);
        }
    }
    const handleAddField = () => {
        setTags([...tags, {
            tagname: '',
            tagvalue: '',
            validName: true,
            validValue: true,
            datasourceid: props.instanceId
        }]);
    };

    const handleRemoveField = (index: number) => {
        const updatedFields = [...tags];
        updatedFields.splice(index, 1);
        setTags(updatedFields);
    };

    const handleFieldBlur = (pos: number, type: string, value?: string) => {
        const updatedTags = [...tags];
        if (type === 'tagname') {
            const nameExists = tags.filter(tag => tag.tagname === value)
            updatedTags[pos].validName = !!tags[pos].tagname.trim() && nameExists.length < 2
        }
        if (type === 'tagvalue') {
            updatedTags[pos].validValue = !!tags[pos].tagvalue.trim()

        }
        setTags(updatedTags)
        setTimeout(() => {
            setSuggestionsVisible(false);
        }, 200);
    }

    const selectSuggestedValue = (pos: number, value: Tags) => {
        const updatedFields = [...tags];
        updatedFields[pos] = {
            tagname: value.tagname,
            tagvalue: '',
            datasourceid: props.instanceId
        }
        setTags(updatedFields);
        setSuggestionsVisible(false)
    }

    return (
        <>
            <div className="col-xs-12 col-md-3">
                <span>
                    <label htmlFor="tags" className="reminder">
                        Tags
                    </label>
                </span>
                <span className="badge bg-info" data-tip="Total tags">
                    {tags.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
                {tags.map((field: Tags, index: number) => (
                    <div key={index} className='d-flex g-0 mb-1'>
                        <input
                            type="text"
                            name="tagname"
                            autoComplete="off"

                            className={`form-control ${field.validName === false ? 'is-invalid' : ''}`}
                            placeholder="Tag Name"
                            onKeyUp={(event) => showSuggestions(event, index)}
                            onBlur={() => handleFieldBlur(index, 'tagname', field.tagname)}
                            value={field.tagname}
                            onChange={(event) => handleChange(index, event)}
                        />
                        <input
                            type="text"
                            autoComplete="off"
                            name="tagvalue"
                            className={`form-control mx-1 ${field.validValue === false ? 'is-invalid' : ''}`}
                            placeholder="Value"
                            onBlur={() => handleFieldBlur(index, 'tagvalue')}
                            value={field.tagvalue}
                            onChange={(event) => handleChange(index, event)}
                        />
                        <button type="button" className="btn btn-small btn-dark"
                                onClick={() => handleRemoveField(index)}>
                            <i className="fal fa-times"/>
                        </button>
                        <ConditionalRender if={suggestionsVisible && focusedInputIndex === index && suggestions.length}>
                            <div className='suggestions-list dropdown-menu show'>
                                {suggestions.map((item: Tags, i: number) => (
                                    <ul key={i}>
                                        <li>
                                            <a className='dropdown-item'
                                               onClick={() => selectSuggestedValue(index, item)}>
                                                {item.tagname}
                                            </a>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        </ConditionalRender>
                    </div>
                ))}
                <ConditionalRender if={tags.length < 20}>
                    <button className="btn btn-success" onClick={handleAddField}>
                        <i className='fal fa-square-plus'/>
                        <span>Add more</span>
                    </button>
                </ConditionalRender>
            </div>
            <div className="w-100"/>
        </>
    );
};

export default Tagging;
