function Alert(props: { message: string, heading: string, variant: string }) {
    return (
        <div className={`alert ${props.variant} alert-dismissible fade show`} role="alert">
            <h4 className="alert-heading">{props.heading}</h4>
            <p>{props.message}</p>
            <button type="button" className="btn-close d-none" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    );
}

export default Alert;