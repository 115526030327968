import React, { useContext, useEffect, useState } from 'react';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import Skeleton from 'react-loading-skeleton';

// Helper.
import Helper from "../../../helpers/Helper";

// Types.
import HostStatistic from '../../../types/host/HostStatistic';
import api from "../../../api/Base";

function AverageStatistic(props: { hostId: number, statistic: string, metric: number | null }) {
    const [previousMetric, setPreviousMetric] = useState<number | null>(null);
    const [differenceMetric, setDifferenceMetric] = useState<number | null>(null);
    const timeRangeContext = useContext(TimeRangeContext)

    useEffect(() => {

        const getStatistic = async() => {
            let previousAverageMetric: number = 0;

            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=${props.statistic}&id=${props.hostId}`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageMetric = Math.round(response.data[0].avg);
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve previous average statistic for host', error);
                })
                .then(function () {
                });

            let difference = Math.round(((((props.metric !== null) ? props.metric : 0) - previousAverageMetric) / previousAverageMetric) * 100);

            if (isNaN(difference)) {
                difference = 0;
            }

            if (difference === Infinity || difference === -Infinity) {
                difference = 100;
            }

            setPreviousMetric(previousAverageMetric);
            setDifferenceMetric(difference);
        }

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {
            getStatistic();
        }

    }, [timeRangeContext, props.hostId, props.statistic, props.metric]);

    return (
        props.metric === null ? (
            <Skeleton/>
        ) : (
            <React.Fragment>
                <span className="table-metric-value">
                    {Math.round(props.metric)}%
                </span>
                {previousMetric !== null && (
                    <span className="comparison">
                            {previousMetric}%
                        {differenceMetric !== null && (
                            <span>({differenceMetric}%)</span>
                        )}
                        {props.metric === previousMetric ? (
                            <i className="fal fa-equals fa-fw equals"/>
                        ) : (
                            props.metric > previousMetric ? (
                                <i className="fal fa-long-arrow-up fa-fw increase"/>
                            ) : (
                                <i className="fal fa-long-arrow-up fa-fw decrease"/>
                            )
                        )}
                        </span>
                )}
            </React.Fragment>
        )
    );
}

export default AverageStatistic;