import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Third-parties.
import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';
import { getDefaultPageSize, getToolTipPosition } from "../../../helpers/utils";

// Types.
import Alert from "../../Alert";
import InstanceTarget from '../../../types/instance/InstanceTarget';
import Wait from "../../../types/instance/Wait";
import GroupedStatementsDataTable from '../../../types/instance/tables/GroupedStatementsDataTable';

// Components.
import GroupedStatementBreakdown from '../GroupedStatementBreakdown';
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';
import StatementToolTip from "./StatementToolTip";

// Constants.
import { DATA_LOADING, INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_POSTGRES } from "../../Constants";
import { INSTANCE_TYPE_SQLSERVER } from "../../Constants";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

function GroupedStatementsTable(props: {
    loading?: number,
    filtersParameters: string,
    instance: InstanceTarget,
    showByDefault: boolean,
    batchId?: string,
    instanceTime: number,
    waits: Wait[],
    statementsDataTable: GroupedStatementsDataTable[]
}) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });
    const timeRangeContext = useContext(TimeRangeContext)

    const getRedirectLink = (sqlHash: string) => {
        if (props.batchId !== undefined) {
            return `/instances/${props.instance.id}/activity/groupedbatch/${props.batchId}/groupedstatement/${sqlHash}/text?${timeRangeContext.getTimeRangeRedirectString()}`
        }
        return `/instances/${props.instance.id}/activity/groupedstatement/${sqlHash}/text?${timeRangeContext.getTimeRangeRedirectString()}`
    }

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    //const defaultPropGetter = () => ({});

    const data = useMemo(() => props.statementsDataTable.filter(row => {
        return (
            (row.grouphash === null ? '' : row.grouphash).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (row.sqltext === null ? '' : row.sqltext).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (row.statement.sqlid === null ? '' : row.statement.sqlid).toLowerCase().includes(tableFilter.toLowerCase())
        )
    }), [props.statementsDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'statement',
            disableSortBy: true
        },
        {
            accessor: 'color',
            disableSortBy: true
        },
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            Header: '#',
            accessor: 'grouphash',
            className: 'concatenate-small col-width-5',
            defaultCanSort: true,
            sortType: 'alphanumeric',

            Cell: ({cell}) => (
                <React.Fragment>
                    <span className="me-1 ps-1" style={{backgroundColor: cell.row.values.color}}>&nbsp;</span>
                    {props.batchId !== undefined ? (
                        <Link
                            to={`/instances/${props.instance.id}/activity/groupedbatch/${props.batchId}/groupedstatement/${cell.row.values.grouphash}?${timeRangeContext.getTimeRangeRedirectString()}`}>{cell.row.values.grouphash}</Link>
                    ) : (
                        <Link
                            to={`/instances/${props.instance.id}/activity/groupedstatement/${cell.row.values.grouphash}?${timeRangeContext.getTimeRangeRedirectString()}`}>{cell.row.values.grouphash}</Link>
                    )}
                </React.Fragment>
            ),
        },
        {
            Header: 'Grouped Statement',
            accessor: 'sqltext',
            className: 'concatenate',
            disableSortBy: true,
            Cell: ({cell}) => cell.row.values.sqltext ? <Link
                    to={getRedirectLink(cell.row.values.grouphash)}>{cell.row.values.sqltext.substring(0, 200)}</Link> :
                <React.Fragment>-</React.Fragment>
        },
        {
            Header: 'Breakdown',
            className: 'statement-waits col-width-5',
            disableSortBy: true,
            Cell: ({cell}) => (
                cell.row.values.time !== null ? (
                    cell.row.values.id < 10 ? (
                        <GroupedStatementBreakdown filterParameters={props.filtersParameters}
                                                   statement={cell.row.values.statement} waits={props.waits}
                                                   instanceId={props.instance.id} instanceTime={props.instanceTime}
                                                   batchId={props.batchId}/>
                    ) : (
                        cell.row.values.id < 20 ? (
                            <GroupedStatementBreakdown filterParameters={props.filtersParameters}
                                                       statement={cell.row.values.statement} waits={props.waits}
                                                       instanceId={props.instance.id} instanceTime={props.instanceTime}
                                                       batchId={props.batchId}/>
                        ) : (
                            <React.Fragment>-</React.Fragment>
                        )
                    )
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Executions',
            accessor: 'executions',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.executions !== null && cell.row.values.executions !== 0 ? (
                    Helper.getFormattedNumber(cell.row.values.executions)
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Average Time',
            accessor: 'averagetime',
            headerClassName: 'text-end',
            className: 'text-end col-width-5',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.averagetime !== null && cell.row.values.executions !== null && cell.row.values.executions > 0 ? (
                    <React.Fragment>
                        {Helper.getTimeInEnglish(cell.row.values.averagetime)}
                    </React.Fragment>
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Total Time',
            accessor: 'waittime',
            headerClassName: 'text-end',
            className: 'text-end col-width-5 text-nowrap',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.waittime !== null ? (
                    Helper.getTimeInEnglish(cell.row.values.waittime)
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Weight %',
            accessor: 'weight',
            headerClassName: 'text-end',
            className: 'text-end col-width-5',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.waittime !== null ? (
                    <React.Fragment>
                        {Number((100 / props.instanceTime) * cell.row.values.waittime).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
                    </React.Fragment>
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
    ], [props.batchId, props.instance.id, props.instanceTime, props.filtersParameters, props.waits, props.instance.type]);

    let hiddenColumns: string[] = [];

    switch (props.instance.type) {
        case INSTANCE_TYPE_SQLSERVER:
        case INSTANCE_TYPE_ORACLE:

            hiddenColumns = ['statement', 'id', 'color', 'executions', 'averagetime'];

            break;

        case INSTANCE_TYPE_POSTGRES:

            if (props.instance.use_pg_stat_statements === true) {

                // Show executions.
                hiddenColumns = ['statement', 'id', 'color'];
            } else {

                // Hide executions.
                hiddenColumns = ['statement', 'id', 'color', 'executions', 'averagetime'];
            }

            break;

        default:

            hiddenColumns = ['statement', 'id', 'color', 'executions', 'averagetime'];

            break;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns,
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'waittime', desc: true}]
            }
        },
        useSortBy, usePagination);

    return (
        <div id="groupedstatements"
             className={((props.showByDefault === true) ? 'tab-pane fade show active' : 'tab-pane fade')}
             role="tabpanel" aria-labelledby="statements-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-scroll fa-fw" aria-hidden="true"></i>
                    Grouped Statements
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseStatements" aria-expanded="false"
                       aria-controls="collapseStatements"></i>
                </div>
                <div id="collapseStatements" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 && (
                        <Alert
                            message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their total wait time.`}
                            heading="Statement records" variant="alert-info"/>
                    )}
                    <div className="row row-cols-1 row-cols-md-2 table-search">
                        <div className="col col-md-9">
                            <CSVLink role="button"
                                     headers={[{label: 'ID', key: 'id'}, {
                                         label: 'Statement Text',
                                         key: 'sqltext'
                                     }, {label: 'Executions', key: 'executions'}, {
                                         label: 'Wait Time',
                                         key: 'waittime'
                                     }, {label: 'Average Time', key: 'averagetime'}]}
                                     data={data}
                                     download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Statements.csv`}
                                     className="btn btn-sm btn-primary">
                                <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                            </CSVLink>
                            <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                className="far fa-undo"></i><span>Clear</span></button>
                        </div>
                        <div className="col col-md-3">
                            <input type="text" className="form-control form-control-sm" placeholder="Search"
                                   value={tableFilter} data-lpignore={true}
                                   onChange={(e) => setTableFilter(e.target.value)}/>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={(column as any).className}>
                                            {column.render('Header')}
                                            {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                    <i className="fal fa-sort-amount-up-alt"></i> :
                                                    <i className="fal fa-sort-amount-down-alt"></i>) :
                                                <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} className={(cell.column as any).className}
                                                    style={{borderLeft: (cell.column as any).color}}>

                                                    {cell.column.id === "sqltext" ?
                                                        <div className='tooltip-scroll ellipsis'
                                                             onMouseEnter={(event) => setVisibleToolTip({
                                                                 id: cell.row.values.grouphash,
                                                                 position: getToolTipPosition(event)
                                                             })}
                                                             onMouseLeave={() => setVisibleToolTip({
                                                                 id: '',
                                                                 position: 0
                                                             })}
                                                        >
                                                            {cell.render('Cell')}
                                                            {(cell.row.values.grouphash === visibleToolTip.id &&
                                                                <StatementToolTip instanceType={props.instance.type}
                                                                                  position={visibleToolTip.position}
                                                                                  id={cell.row.values.grouphash}
                                                                                  link={getRedirectLink(cell.row.values.grouphash)}
                                                                                  cell={cell}/>
                                                            )}
                                                        </div> : cell.render('Cell')}

                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <NoData
                            error={null}
                            loading={props.loading === DATA_LOADING}
                            length={data.length}
                        />
                        <TablePagination
                            length={data.length}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            canPreviousPage={canPreviousPage}
                            previousPage={previousPage}
                            canNextPage={canNextPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            pageIndex={pageIndex}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupedStatementsTable;
