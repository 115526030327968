import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Types.
import Batch from '../../../types/instance/Batch';
import BatchesDataTable from '../../../types/instance/tables/BatchesDataTable';
import Wait from '../../../types/instance/Wait';
import PieChartSeries from '../../../types/PieChartSeries';

// Helpers.
import Helper from '../../../helpers/Helper';

// Constants.
import { DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from '../../Constants';
import { CHART_COLOURS_STATEMENTS } from '../../Constants';

// Components.
import Alert from '../../Alert';
import BatchesTable from '../table/BatchesTable';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { getStatementTypeFromURL } from "../../../views/instances/tabs/utils";
import api from "../../../api/Base";

function Batches(props: {
    instanceId: number,
    statementId?: string,
    batchStatementId?: string,
    filtersParameters: string,
    totalInstanceTime?: number,
    waits?: Wait[],
    showByDefault: boolean,
    isBatchesForStatement: boolean,
    setBatchesOptions?: Function
}) {
    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [batchesDataTable, setBatchesTableData] = useState<BatchesDataTable[]>([]);
    const timeRangeContext = useContext(TimeRangeContext)

    const instanceId: number = props.instanceId;

    useMemo(() => {

        const getBatches = async() => {
            setLoading(DATA_LOADING);
            let dataTable: BatchesDataTable[] = [],
                pieChartSeries: PieChartSeries[] = [];

            let apiString = ''
            const statementType = getStatementTypeFromURL()

            if (statementType.type === 'statement') {
                apiString = `&sqlhash=${props.batchStatementId || props.statementId}`
            }
            if (statementType.type === 'groupedstatement') {
                apiString = `&grouphash=${props.batchStatementId || props.statementId}`
            }
            if (statementType.type === 'groupedbatch') {
                apiString = `&batchgrouphash=${props.statementId}`
            }

            await api.get(`activity/batch?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${props.filtersParameters}`)
                .then(async(response: { data: Batch[] }) => {
                    for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_API_LIMIT) - 1).length; index++) {

                        let batchwaittime: number = 0;

                        if (props.statementId !== undefined) {

                            // Get the total batch time without the statement parameter.
                            await api.get(`activity/batchsqlhash?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${props.instanceId}&batchsqlhash=${response.data[index].batchsqlhash}${props.filtersParameters}`)
                                .then((batchResponse: { data: Batch[], status: number }) => {
                                    if (batchResponse.status === 200 && batchResponse.data.length > 0) {
                                        batchwaittime = batchResponse.data[0].waittime;
                                    }
                                })
                                .catch((error: any) => {
                                    console.error('Failed to retrieve batch without statement ID.', error, response.data[index].batchsqlhash);
                                })
                        }

                        dataTable.push({
                            id: index,
                            color: index < CHART_COLOURS_STATEMENTS.length ?
                                CHART_COLOURS_STATEMENTS[index] : '',
                            batchsqlhash: response.data[index].batchsqlhash,
                            sqltext: response.data[index].sqltext,
                            executions: response.data[index].batchexecutions,
                            averagetime: Number((response.data[index].waittime / Number(response.data[index].batchexecutions)).toFixed(2)),
                            waittime: response.data[index].waittime,
                            batchwaittime,
                            batch: response.data[index]
                        });
                    }
                    // Build top batches pie chart.
                    for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_CHART_SERIES_CEILING)).length; index++) {

                        let name: string = ((response.data[index].batchsqlhash === null) ? '-' : response.data[index].batchsqlhash);
                        if (name.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH)) {
                            name = `${name.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...`;
                        }

                        pieChartSeries.push({
                            color: CHART_COLOURS_STATEMENTS[index],
                            formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                            name,
                            y: response.data[index].waittime,
                            url: ((response.data[index].batchsqlhash !== null && response.data[index].batchsqlhash !== '-') ? `/instances/${instanceId}/activity/batch/${response.data[index].batchsqlhash}` : null)
                        });
                    }


                    if (props.setBatchesOptions) {
                        // Return the total count and pie chart series back to the parent component.
                        props.setBatchesOptions.call(null, response.data.length, pieChartSeries);
                    }

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve batches.', error);
                })
            // Mark the data as loaded.
            setLoading(DATA_LOADED);
            setBatchesTableData(dataTable);
        }

        if (loading === DATA_INITIALISING) {

            // Get the batches for the selected instance and period.
            void getBatches();
        }

    }, [timeRangeContext, instanceId]);


    return (
        <div id="batches"
             className={((props.showByDefault === true) ? 'tab-pane fade show active' : 'tab-pane fade')}
             role="tabpanel" aria-labelledby="batches-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-layer-group fa-fw" aria-hidden="true"/>
                    Batches
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseBatches" aria-expanded="false" aria-controls="collapseBatches"/>
                </div>
                <div id="collapseBatches" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === batchesDataTable.length - 1 && (
                        <Alert
                            message={`To help preserve performance, we limit the total number of batch records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their total wait time.`}
                            heading="Batch records" variant="alert-info"/>
                    )}
                    <BatchesTable loading={loading} filtersParameters={props.filtersParameters}
                                  instanceId={props.instanceId}
                                  instanceTime={props.totalInstanceTime || 1}
                                  waits={props.waits || []} batchesDataTable={batchesDataTable}
                                  isBatchesForStatement={props.isBatchesForStatement}/>
                </div>
            </div>
        </div>
    );
}

Batches.propTypes = {
    setBatchesOptions: PropTypes.func
};

export default Batches;
