// React.
import { useMemo } from 'react';

// Types.
import { SqlStatisticsTimeslice } from '../../../types/instance/SqlStatistics';

// Components.
import HighchartsWithData from '../../../component/HighchartsWithData';

// Helpers.
import { highchartsCredits, timeSeries } from '../../../helpers/utils';
import { OracleSqlHashTimeSlice } from "../../../container/statistics/types";
import { useLicences } from "../../../context/LicenceContext";

export default function SqlStatisticChart(props:
                                              {
                                                  error: string | null,
                                                  loading: boolean,
                                                  data: SqlStatisticsTimeslice[] | OracleSqlHashTimeSlice[],
                                                  statistic: string,
                                                  title: string
                                              }) {
    const {error, loading, data, statistic, title} = props;
    const {licences} = useLicences();

    const options = useMemo(() => {
            // The brace is required on the same line otherwise it does not compile!
            return {
                chart:
                    {
                        defaultSeriesType: 'column',
                        height: '200px'
                    },
                plotOptions:
                    {
                        animation: false,
                        column:
                            {
                                stacking: 'normal'
                            },
                        series:
                            {
                                marker:
                                    {
                                        enabled: false
                                    }
                            }
                    },
                title:
                    {
                        text: ''
                    },
                exporting:
                    {
                        enabled: false
                    },
                credits: {enabled: highchartsCredits(licences)},
                xAxis: {
                    type: 'datetime'
                },
                yAxis:
                    {
                        min: 0,
                        title:
                            {
                                text: ''
                            }
                    },
                series:
                    [
                        {
                            name: title,
                            data: timeSeries(data, 'timeslice', statistic),
                            showInLegend: false
                        }
                    ]
            };
        },
        [data, statistic, title]);

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                {title}
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target={"#collapse-" + statistic} aria-expanded="false"
                   aria-controls={"collapse-" + statistic}/>
            </div>
            <div id={"collapse-" + statistic} className="card-body collapse show">
                <HighchartsWithData error={error} loading={loading}
                                    length={data.length} options={options}/>
            </div>
        </div>
    )
}
