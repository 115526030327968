import React, { useMemo } from 'react';

// Third-parties.
import Skeleton from 'react-loading-skeleton';

function DisksWidget(props: { metric: null | number, metricPrevious: null | number }) {

    const widget = useMemo(() => {

        return (
            <div className="col flexlist-item">
                <div className="card">
                    <div className="card-body widget">
                        <div className="row g-0 align-items-center card-row">
                            <div className="icon io" data-tip="The average disk read and writes">
                                <span className="fal fa-hdd fa-fw" aria-hidden="true"></span>
                            </div>
                            <div className="col data">
                                Disk I/O
                                <div>
                                    {props.metric !== undefined && props.metric !== null ? (
                                        <React.Fragment>
                                            {props.metric}%
                                            {props.metricPrevious !== null && (
                                                <span className="comparison">
                                                    {props.metric === props.metricPrevious ? (
                                                        <i className="fal fa-equals equals fa-fw" aria-hidden="true"></i>
                                                    ) : (
                                                        props.metric > props.metricPrevious ? (
                                                            <i className="fal fa-long-arrow-up fa-fw increase" aria-hidden="true"></i>
                                                        ) : (
                                                            <i className="fal fa-long-arrow-up fa-fw decrease" aria-hidden="true"></i>
                                                        )
                                                    )}
                                                    {props.metricPrevious}%
                                                    {props.metricPrevious > 0 && (
                                                        <React.Fragment>
                                                            &nbsp;({Math.round(((props.metric - props.metricPrevious) / props.metricPrevious) * 100)}%)
                                                        </React.Fragment>
                                                    )}
                                                </span>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                            <Skeleton />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }, [props]);

    return (
        widget
    );
}

export default DisksWidget;
