import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import Statement from '../../../types/instance/Statement';
import ExecutionPlan from '../../../types/instance/ExecutionPlan';
import ChartSeries from '../../../types/ChartSeries';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';
import Wait from '../../../types/instance/Wait';
import Time from '../../../types/instance/Time';

// Components.
import DifferenceIcon from '../../../component/DifferenceIcon';
import StatementWaits from './StatementWaits';

// Constants.
import { DATA_INITIALISING, DATA_LOADING } from '../../../component/Constants';
import { REPORT_PERIOD_PREVIOUS, REPORT_PERIOD_CURRENT } from '../../../component/Constants';
import { INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER } from '../../../component/Constants';
import { CHART_COLOURS_WAITS, CHART_DATA_TYPE } from '../../../component/Constants';

// Highcharts boost mode.
import Boost from 'highcharts/modules/boost';
import {highchartsCredits} from "../../../helpers/utils";
import {useLicences} from "../../../context/LicenceContext";
import api from "../../../api/Base";

Boost(Highcharts);

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

const 
    chartSeriesCeiling: number = 10;

function StatementDetails(props: { period: Period, chartCategories: string[], matchedInstances: InstanceTarget[], timeA: null | number, timeB: null | number, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], statement: Statement, statementsA: Statement[], statementsB: Statement[] }) {
    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [statementA, setStatementA] = useState<null | Statement>(null);
    const [statementB, setStatementB] = useState<null | Statement>(null);
    const [waits, setWaits] = useState<Wait[]>([])
    const [peak, setPeak] = useState<null | number>(null);
    const [series, setSeries] = useState<ChartSeries[]>([]);
    const [executionPlansA, setExecutionPlansA] = useState<ExecutionPlan[]>([]);
    const [executionPlansB, setExecutionPlansB] = useState<ExecutionPlan[]>([]);
    const [executionPlotlines, setExecutionPlotlines] = useState<ChartPlotLine[]>([]);
    const [detailRowClassName, setDetailRowClassName] = useState<string>('d-none');
    const { licences } = useLicences();

    // Get props.statements for both periods.
    useMemo(() => {

        async function getStatementWaits() {

            // Get the first and second statement periods.
            const 
                matchedStatementsA: Statement[] = props.statementsA.filter(item => item.sqlhash === props.statement.sqlhash),
                matchedStatementsB: Statement[] = props.statementsB.filter(item => item.sqlhash === props.statement.sqlhash);

            let data: Wait[] = [];

            if (matchedStatementsA.length > 0) {

                // Get top statement waits for the first statement period.
                await api.get(`activity/waitevent?limit=${chartSeriesCeiling}&from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sort=waittime+desc&id=${props.matchedInstances[0].id}&sqlhash=${matchedStatementsA[0].sqlhash}`)
                    .then(async (response: { data: Wait[]; }) => {
                        
                        data = [...data, ...response.data];
                    })
                    .catch((error: any) => {

                        console.error('Failed to retrieve top statement waits', error, props.statementsA);
                    })
                    .then(function () {
                    });

                setStatementA(matchedStatementsA[0]);
            }

            if (matchedStatementsB.length > 0) {

                // Get top statement waits for the second statement period.
                await api.get(`activity/waitevent?limit=${chartSeriesCeiling}&from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sort=waittime+desc&id=${props.matchedInstances[1].id}&sqlhash=${matchedStatementsB[0].sqlhash}`)
                    .then(async (response: { data: Wait[]; }) => {
                        
                        data = [...data, ...response.data];
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve top statement waits', error, props.statementsB);
                    })
                    .then(function () {
                    });

                setStatementB(matchedStatementsB[0]);
            }

            // Remove duplicate waits.
            data = data.filter((wait, index, self) => self.findIndex(item => item.waitevent === wait.waitevent) === index).slice(0, chartSeriesCeiling);

            // Set the wait state colour.
            for (let index = 0; index < data.length; index++) {
                data[index].color = CHART_COLOURS_WAITS[index];
            }

            setWaits(data);
        }

        getStatementWaits();

    }, [props.statement, props.period, props.statementsA, props.statementsB, props.matchedInstances]);

    // Get the chart data.
    useMemo(() => {

        async function getWaitsOverTime() {

            let series: ChartSeries[] = [],
                seriesPeak: number[] = [];

            for (let waitIndex = 0; waitIndex < waits.length; waitIndex++) {

                let wait: Wait = waits[waitIndex],
                    waitData: (number | null)[] = [];

                if (statementA !== null) {

                    // Get the first statement waits over time.
                    await api.get(`activity/time?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${props.matchedInstances[0].id}&sqlhash=${statementA.sqlhash}`)
                        .then(async (response: { data: Time[]; }) => {

                            // Check, does the time match?
                            for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                                let result = response.data.filter((item: Time) => {
                                    return item.timeslice === props.period.ui.previous.chartCategories[index]
                                });

                                if (result.length === 0) {
                                    // Data missing.
                                    waitData.push(0);
                                } else {
                                    // Data exists.
                                    waitData.push(result[0].waittime);
                                }
                            }
                        })
                        .catch((error: any) => {
                            console.error('Failed to retrieve statement waits over time', error);
                        })
                        .then(function () {
                        });

                } else {

                    // Fill in the missing data gaps.
                    for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                        waitData.push(0);
                    }
                }

                // Interim data push.
                waitData.push(null);

                if (statementB !== null) {

                    // Get the second statement waits over time.
                    await api.get(`activity/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${props.matchedInstances[1].id}&sqlhash=${statementB.sqlhash}`)
                        .then(async (response: { data: Time[]; }) => {

                            // Check, does the time match?
                            for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                                let result = response.data.filter((item: Time) => {
                                    return item.timeslice === props.period.ui.current.chartCategories[index]
                                });

                                if (result.length === 0) {
                                    // Data missing.
                                    waitData.push(0);
                                } else {
                                    // Data exists.
                                    waitData.push(result[0].waittime);
                                }
                            }
                        })
                        .catch((error: any) => {
                            console.error('Failed to retrieve statement waits over time', error);
                        })
                        .then(function () {
                        });

                } else {

                    // Fill in the missing data gaps.
                    for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                        waitData.push(0);
                    }
                }

                // Add the chart series.
                series.push({
                    name: wait.waitevent,
                    color: wait.color,
                    type: 'column',
                    data: waitData,
                    tooltip: {
                        valueSuffix: 'ms',
                    },
                    zIndex: 1
                });

                seriesPeak.push(Helper.getPeakValue(waitData));
            }

            setSeries(series);
            setPeak(Helper.getPeakValue(seriesPeak));
        }

        if (loading === DATA_LOADING) {
            getWaitsOverTime();
        }

    }, [props.period, statementA, statementB, waits, loading, props.matchedInstances]);

    // Get statement execution plans.
    useMemo(() => {

        async function getExecutionPlans() {

            let plansA: ExecutionPlan[] = [],
                plansB: ExecutionPlan[] = [];

            if (statementA !== null) {

                await api.get(`sql/plan?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sort=earliest&id=${props.matchedInstances[0].id}&sqlhash=${statementA.sqlhash}`)
                .then(async (response: { data: ExecutionPlan[]; }) => {

                    plansA = response.data.filter(plan => plan.sqlplan !== null);
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve statement execution plans', error, statementA);
                })
                .then(() => {
                });

            }

            if (statementB !== null) {

                await api.get(`sql/plan?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sort=earliest&id=${props.matchedInstances[1].id}&sqlhash=${statementB.sqlhash}`)
                .then(async (response: { data: ExecutionPlan[]; }) => {

                    plansB = response.data.filter(plan => plan.sqlplan !== null);
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve statement execution plans', error, statementB);
                })
                .then(() => {
                });

            }

            const plotlines: ChartPlotLine[] = Helper.getChartPlotLinesForExecutionPlans(props.period, [...plansA, ...plansB], [...props.period.ui.previous.chartCategories, ...['Interim'], ...props.period.ui.current.chartCategories]);

            setExecutionPlansA(plansA);
            setExecutionPlansB(plansB);
            setExecutionPlotlines(plotlines);
        }

        getExecutionPlans();

    }, [props.period, statementA, statementB, props.matchedInstances]);

    // Build the chart options.
    const chartOptions = useMemo(() => {

        return {
            chart: {
                height: '200px',
                spacing: [0, 0, 0, 0],
                type: 'column'
            },
            title: { text: '' },
            xAxis: [{
                categories: props.chartCategories,
                crosshair: true,
                plotLines: [...props.plotLines, ...executionPlotlines],
                plotBands: props.plotBands,
                tickInterval: props.period.chartTickInterval
            }],
            yAxis: [{
                labels: {
                    align: 'left',
                    enabled: true,
                    x: 0,
                    y: 15
                },
                min: 0,
                showFirstLabel: false,
                title: {
                    text: ''
                }
            }],
            plotOptions: {
				animation: false,
                column: { stacking: 'normal' },
                series: { marker: { enabled: false } }
            },
            legend: {
                align: 'center',
                backgroundColor: 'rgba(255,255,255,0.25)',
                borderColor: '#dee2e6',
                borderRadius: 5,
                borderWidth: 1,
                enabled: true,
                floating: false,
                itemMarginTop: 0,
                itemMarginBottom: 0,
                itemStyle: {
                    color: '#212529',
                    fontSize: '.7rem'
                },
                layout: 'horizontal',
                padding: 10,
                x: 0,
                y: 0,
                verticalAlign: 'bottom'
            },
            series,
            exporting: { enabled: false },
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltips(this, CHART_DATA_TYPE.TIME);
                },
                borderWidth: 0,
                outide: true,
                padding: 0,
                shadow: false,
                shared: true,
                useHTML: true
            },
            credits: { enabled: highchartsCredits(licences) },
            accessibility: {
                enabled: false
            }
        };

    }, [props.period, props.chartCategories, props.plotBands, props.plotLines, series, executionPlotlines]);

    // Toggles visibility of the statement detail row.
    function toggleStatementDetails() {

        // Trigger the additional data loading.
        setLoading(DATA_LOADING);

        setDetailRowClassName(((detailRowClassName === 'd-none') ? '' : 'd-none'));
    }

    return (
        <React.Fragment>
            {/* Summary Row */}
            <tr key={props.statement.sqlhash}>
                <th className="concatenate border-spacer" scope="row" style={{ borderLeftColor: `${props.statement.color}` }}>
                    <i id={`button-${props.statement.sqlhash}`} className={((detailRowClassName === 'd-none') ? 'fal fa-plus-square fa-fw' : 'fal fa-minus-square fa-fw')} role="button" onClick={() => toggleStatementDetails()}></i>
                    <button type="button" className="btn btn-xxsm btn-link" onClick={() => toggleStatementDetails()}>{props.statement.sqlhash}</button>
                </th>
                <td className="text-end highlight">
                    {statementA !== null ? (
                        Helper.getTimeInEnglish(statementA.waittime)
                    ) : (
                        `-`
                    )}
                </td>
                <td className="text-end">
                    {statementB !== null ? (
                        <React.Fragment>
                            {statementA !== null && statementB !== null && (
                                <DifferenceIcon valueA={statementA.waittime} valueB={statementB.waittime} />
                            )}
                            {Helper.getTimeInEnglish(statementB.waittime)}
                        </React.Fragment>
                    ) : (
                        `-`
                    )}
                </td>
            </tr>

            {/* Detail Row */}
            {loading === DATA_LOADING && (
                <tr className={detailRowClassName}>
                    <td className="ps-3" colSpan={7}>
                        <h4 className="mb-3">
                            Statement {props.statement.sqlhash}
                            {props.statement.sqltext !== null && props.statement.sqltext.length > 300 && (
                                <span className="peak">Truncated Statement</span>
                            )}
                        </h4>
                        <p className="font-monospace small">
                            {props.statement.sqltext === null ? (
                                `Statement unavailable.`
                            ) : (
                                props.statement.sqltext.length > 300 ? (
                                    <React.Fragment>
                                        {props.statement.sqltext.substring(0, 300)}&hellip;
                                    </React.Fragment>
                                ) : (
                                    props.statement.sqltext
                                )
                            )}
                        </p>
                        
                        <h4>
                            Top statement waits over time (ms)
                            {peak !== null && peak !== Infinity && peak !== -Infinity && (
                                <span className="peak">Peak Wait Time: {Helper.getTimeInEnglish(peak)}</span>
                            )}
                        </h4>
                        <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={chartOptions} />

                        <table className="table table-light mb-0 mt-3">
                            <thead>
                                <tr>
                                    <th className="col-3" scope="col">
                                        Period
                                    </th>
                                    <th className="col text-end" scope="col">
                                        Executions
                                    </th>
                                    <th className="col-2 text-end" scope="col">
                                        <sup><i className="fal fa-ruler-triangle fa-fw mr-1"></i></sup>Execution Plans
                                    </th>
                                    <th className="col-2" scope="col">
                                        Top Statement Waits
                                    </th>
                                    <th className="col-2 text-end" scope="col">
                                        Wait Time
                                    </th>
                                    <th className="col-2 text-end" scope="col">
                                        Weight Percentage
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {statementA !== null ? (
                                    <tr>
                                        <th className="col-3">
                                            <Link to={`/instances/${props.matchedInstances[0].id}/activity/statement/${props.statement.sqlhash}${Helper.getQueryString(props.period, props.period.api.previous.from, props.period.api.previous.to)}`} target="_blank">{Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_PREVIOUS)}</Link><sup className="fal fa-external-link fa-fw"></sup>
                                        </th>
                                        <td className="col text-end">
                                            {statementA.executions !== undefined && statementA.executions > 0 ? (
                                                Helper.getFormattedNumber(statementA.executions)
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                        <td className="col-2 text-end">
                                            {executionPlansA.length}
                                        </td>
                                        <td className="col-2 statement-waits">
                                            {props.timeA !== null ? (
                                                <StatementWaits period={props.period} from={props.period.api.previous.from} to={props.period.api.previous.to} statement={statementA} waits={waits} instanceTime={props.timeA} instanceId={props.matchedInstances[0].id} />
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                        <td className="col-2 text-end">
                                            {Helper.getTimeInEnglish(statementA.waittime)}
                                        </td>
                                        <td className="col-2 text-end">
                                            {props.timeA !== null ? (
                                                `${Math.floor((100 / props.timeA) * statementA.waittime)}%`
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th className="col-3">
                                            {Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_PREVIOUS)}
                                        </th>
                                        <td className="col text-end">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                        <td className="col-2">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                    </tr>
                                )}
                                {statementB !== null ? (
                                    <tr>
                                        <th className="col-3">
                                            <Link to={`/instances/${props.matchedInstances[1].id}/activity/statement/${props.statement.sqlhash}${Helper.getQueryString(props.period, props.period.api.current.from, props.period.api.current.to)}`} target="_blank">{Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_CURRENT)}</Link><sup className="fal fa-external-link fa-fw"></sup>
                                        </th>
                                        <td className="col text-end">
                                            {statementB.executions !== undefined && statementB.executions > 0 ? (
                                                Helper.getFormattedNumber(statementB.executions)
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                        <td className="col-2 text-end">
                                            {executionPlansB.length}
                                        </td>
                                        <td className="col-2 statement-waits">
                                            {props.timeB !== null ? (
                                                <StatementWaits period={props.period} from={props.period.api.current.from} to={props.period.api.current.to} statement={statementB} waits={waits} instanceTime={props.timeB} instanceId={props.matchedInstances[1].id} />
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                        <td className="col-2 text-end">
                                            {Helper.getTimeInEnglish(statementB.waittime)}
                                        </td>
                                        <td className="col-2 text-end">
                                            {props.timeB !== null ? (
                                                `${Math.floor((100 / props.timeB) * statementB.waittime)}%`
                                            ) : (
                                                `-`
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th className="col-3">
                                            {Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_CURRENT)}
                                        </th>
                                        <td className="col text-end">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                        <td className="col-2">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                        <td className="col-2 text-end">
                                            -
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        Difference
                                    </td>
                                    <td className="text-end">
                                        {(props.matchedInstances[0].type === INSTANCE_TYPE_ORACLE || props.matchedInstances[0].type === INSTANCE_TYPE_SQLSERVER) && (props.matchedInstances[1].type === INSTANCE_TYPE_ORACLE || props.matchedInstances[1].type === INSTANCE_TYPE_SQLSERVER) && 
                                        (statementA !== null && statementA.executions !== undefined) && statementB !== null && statementB.executions !== undefined ? (
                                            <React.Fragment>                                                
                                                <DifferenceIcon valueA={statementA.executions} valueB={statementB.executions} />
                                                {statementA.executions - statementB.executions}
                                                {Helper.getPercentageDifference(statementB.executions, statementA.executions) !== null && (
                                                    <span className="comparison">
                                                        ({Helper.getFormattedNumber(Helper.getPercentageDifference(statementB.executions, statementA.executions))}%)
                                                    </span>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            `-`
                                        )}
                                    </td>
                                    <td className="text-end">
                                        <DifferenceIcon valueA={executionPlansA.length} valueB={executionPlansB.length} />
                                        {executionPlansA.length - executionPlansB.length}
                                        {Helper.getPercentageDifference(executionPlansB.length, executionPlansA.length) !== null && (
                                            <span className="comparison">
                                                ({Helper.getFormattedNumber(Helper.getPercentageDifference(executionPlansA.length, executionPlansB.length))}%)
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-end">
                                        -
                                    </td>
                                    <td className="text-end">
                                        {statementA && statementB ? (
                                            <React.Fragment>
                                                <DifferenceIcon valueA={statementA.waittime} valueB={statementB.waittime} />
                                                {(statementB.waittime - statementA.waittime) > 0 ? (
                                                    Helper.getTimeInEnglish((statementA.waittime - statementB.waittime) * -1)
                                                ) : (
                                                    statementA.waittime === statementB.waittime ? (
                                                        Helper.getTimeInEnglish((statementA.waittime - statementB.waittime))
                                                    ) : (
                                                        `-${Helper.getTimeInEnglish((statementA.waittime - statementB.waittime))}`
                                                    )
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>-</React.Fragment>
                                        )}
                                        {statementA && statementB && (
                                            <span className="comparison">
                                                ({Helper.getFormattedNumber(Helper.getPercentageDifference(statementB.waittime, statementA.waittime))}%)
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-end">
                                        {statementA && statementB && props.timeA !== null && props.timeB !== null ? (
                                            <React.Fragment>
                                                <DifferenceIcon valueA={Math.floor((100 / props.timeB) * statementB.waittime)} valueB={Math.floor((100 / props.timeA) * statementA.waittime)} />
                                                {((Math.floor((100 / props.timeB) * statementB.waittime)) - Math.floor((100 / props.timeA) * statementA.waittime)).toString().replace('Infinity', '0')}%
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>-</React.Fragment>
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
}

export default StatementDetails;
