// React.
import { useContext, useEffect, useState } from 'react';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { TransactionContentionType } from '../../../types/instance/TransactionContentionType';

// Components.
import TransactionContentionTable from '../../../component/instance/table/TransactionsContentionTable';

// Helper.
import { archiverUrl, fetchResults, fetchWithAuthorization } from '../../../helpers/utils';


import { TimeRangeContext } from "../../../context/TimeRangeContext";

export default function TransactionContention(props: {
    instance: InstanceTarget
}) {
    const { instance } = props;
    const timeRangeContext = useContext(TimeRangeContext);

    const [transactions , setTransactions] = useState<TransactionContentionType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const results: any[][] = await fetchResults(
                    [
                        fetchWithAuthorization(archiverUrl() + `/transaction/contention?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}&sort=contentionduration+desc`)
                    ]);

                if (active) {
                    setTransactions(results[0]);
                    setLoading(false);
                }
            } catch (x: any) {
                console.log(x.message);
                setError(x.message);
                setTransactions([]);
                setLoading(false);
            }
        }

        let active: boolean = true;
        load();
        return () => {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [instance.id, timeRangeContext]);


    return (<>
        <div className="row row-cols-1">
            <div id="space" className="tab-pane fade show active" role="tabpanel" aria-labelledby="space-tab">
                <TransactionContentionTable error={error} loading={loading}
                                  data={transactions} instance={instance}/>
            </div>
        </div>
    </>)
}
