import React, { useMemo, useState } from 'react';

import ReactTooltip from 'react-tooltip';

// Helpers.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import Statement from '../../../types/instance/Statement';
import Wait from '../../../types/instance/Wait';

// Constants.
import { CHART_COLOURS_WAITS } from '../../../component/Constants';
import api from "../../../api/Base";

function StatementWaits(props: { statement?: Statement, waits: Wait[], instanceTime: number, period: Period, from: string, to: string, instanceId: number }) {
    const [waits, setWaits] = useState<Wait[]>([]);
    const [tooltip, setTooltip] = useState<string>();

    let statementWeight: number = 0;

    if (props.statement !== undefined && props.instanceTime > 0) {
        statementWeight = Math.floor((100 / props.instanceTime) * props.statement.waittime);
    }

    useMemo(() => {

        const getWaits = async() => {

            if (props.statement === undefined || props.instanceTime === 0) {
                return;
            }

            let statement = props.statement;

            // Get the statement waits.
            api.get(`activity/waitevent?from=${props.from}&to=${props.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sort=waittime+desc&id=${props.instanceId}&sqlhash=${statement.sqlhash}`)
                .then(async(response: { data: Wait[]; }) => {

                    let statementWaits: Wait[] = response.data;

                    let generatedTooltip: string = '';

                    // Since waits length can be different get the shortest one length to avoid undefined data
                    for (let index = 0; index <  Math.min(props.waits.length, statementWaits.length) - 1; index++) {

                        const matchedWait = statementWaits.filter(wait => wait.waitevent === props.waits[index].waitevent);

                        if (matchedWait.length > 0) {

                            if (index < CHART_COLOURS_WAITS.length && statementWaits[index]) {

                                // Set the top colours for the chart.
                                statementWaits[index].color = CHART_COLOURS_WAITS[index];
                            }
                            // Work out the wait percentage.
                            const weight = Math.floor((100 / statement.waittime) * statementWaits[index].waittime);

                            // Set the statement weight and build the tooltip.
                            statementWaits[index].weight = weight;

                            if (weight >= 0) {
                                generatedTooltip = `${generatedTooltip}<li>${statementWaits[index].waitevent}: ${Helper.getTimeInEnglish(statementWaits[index].waittime)} (${statementWaits[index].weight}%)</li>`;
                            }
                        }
                    }

                    setWaits(statementWaits);
                    setTooltip('<p>This statement has the following top waits:</p><ol>{waits}</ol>'.replace('{waits}', generatedTooltip));
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve statement wait events.', error);
                })
                .then(function () {
                    // As we're late data-binding, rebuild the tooltips.
                    ReactTooltip.rebuild();
                });
        }

        getWaits();

    }, [props.statement, props.waits, props.instanceTime, props.period, props.from, props.to, props.instanceId]);

    if (props.statement === undefined || statementWeight < 5) {
        return (
            <React.Fragment>-</React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{width: `${statementWeight}%`}} data-tip={tooltip}>
                {waits.filter(wait => wait.weight && wait.weight > 4).map((wait: Wait, index) => (
                    <div key={index} style={{width: `${wait.weight}%`, backgroundColor: wait.color}}>&nbsp;</div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default StatementWaits;
