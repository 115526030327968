import { useMemo } from "react";
import ConditionalRender from "../../../helpers/ConditionalRender";
import TimeStaticWidget from "./TimeStaticWidget";
import ExecutionsStaticWidget from "./ExecutionsStaticWidget";
import AverageTimeStaticWidget from "./AverageTimeStaticWidget";
import LegacyChangesWidget from "./LegacyChangesWidget";

// Types
import Event from "../../../types/instance/Event";
import Change from "../../../types/instance/Change";
import Alert from "../../../types/instance/Alert";

export default function WidgetsWrappers(props: {
    totalInstanceTime: number,
    totalPreviousInstanceTime: number,
    filterParameters: string,
    executions: number,
    previousExecutions: number,
    alerts: Alert[],
    changes: Change[],
    events: Event[]
}) {
    const {
        totalInstanceTime,
        totalPreviousInstanceTime,
        filterParameters,
        executions,
        previousExecutions,
        alerts,
        changes,
        events
    } = props

    const widgetsComponent = useMemo(() => (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
            <TimeStaticWidget metric={totalInstanceTime} metricPrevious={totalPreviousInstanceTime}/>
            <ConditionalRender if={!filterParameters}>
                <ExecutionsStaticWidget metric={executions} metricPrevious={previousExecutions}/>
                <AverageTimeStaticWidget executionsMetric={executions}
                                         executionsMetricPrevious={previousExecutions}
                                         timeMetric={totalInstanceTime}
                                         timeMetricPrevious={totalPreviousInstanceTime}/>
            </ConditionalRender>
            <LegacyChangesWidget alerts={alerts} changes={changes} events={events}/>
        </div>
    ), [totalInstanceTime,
        totalPreviousInstanceTime,
        filterParameters,
        executions,
        previousExecutions,
        alerts,
        changes,
        events]);
    return (<div>{widgetsComponent}</div>
    )
}
