// React
import { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Context.
import { InstanceContext } from '../../context/InstanceContext';
import { TimeRangeContext } from "../../context/TimeRangeContext";

// Types.
import Period from '../../types/Period';
import InstancesDataTable from '../../types/instance/tables/InstancesDataTable';
import InstanceTarget from '../../types/instance/InstanceTarget';
import { ActivityId } from '../../types/instance/Activity';
import { StatisticId } from '../../types/instance/Statistic';
import { ChangeId } from '../../types/instance/Change';
import { EventId } from '../../types/instance/Event';
import { AlertId } from '../../types/instance/Alert';
import Tags from "../../types/Tags";

// Constants.
import { DATA_LOADED, DATA_LOADING, TARGET_COLOUR_CODES } from '../../component/Constants';

// Helpers.
import { archiverUrl, fetchResults, fetchWithAuthorization } from '../../helpers/utils';
import Helper from "../../helpers/Helper";

// Components.
import InstancesTable from '../../component/instance/table/InstancesTable';
import InstancesOverTimeChart from '../../component/instance/chart/InstancesOverTimeChart';
import Breadcrumb from "../../container/breadcrumb";
import PageContent from '../../container/page_content';
import { Link, NavLink } from "react-router-dom";
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import FilterBar from "../../component/FilterBar";
import WidgetCard from "../../component/instance/widget";
import TagFilters from "../../component/instance/Tagging/TagFilters";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

function List(props: {
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function,
    filterParameters: string
}) {
    const {period} = props
    const {instances} = useContext(InstanceContext);
    const timeRangeContext = useContext(TimeRangeContext)

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [chartLoading, setChartLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [instancesDataTable, setInstancesDataTable] = useState<InstancesDataTable[]>([]);
    const [tags, setTags] = useState<Tags[]>([]);
    const [tagFilters, setTagFilters] = useState<any[]>([]);

    const [filteredInstances, setFilteredInstances] = useState<InstancesDataTable[]>([]);
    const [totalAlerts, setTotalAlerts] = useState(0);
    const [totalChanges, setTotalChanges] = useState(0);
    const [totalCustomEvents, setTotalCustomEvents] = useState(0);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tagsParam = urlParams.get('tags');
        let tagFiltersArray: any[] = [];
        if (tagsParam) {
            tagFiltersArray = tagsParam.split(';').map(tag => {
                const [tagName, ...tagValues] = tag.split(':');
                return {
                    tagName: tagName,
                    tagValues: tagValues.join('').split(',')
                };
            });
            setTagFilters(tagFiltersArray);
            window.localStorage.setItem('instance-tags-filters', JSON.stringify(tagFiltersArray))
        } else {
            // if there are no tags in url get the values from local storage
            const savedFilters = window.localStorage.getItem('instance-tags-filters');
            tagFiltersArray = savedFilters ? JSON.parse(savedFilters) : [];

            if (tagFiltersArray?.length && tags?.length) {
                const filteredSavedTags = filterExtraSavedTags(tagFiltersArray, tags)
                setTagFilters(filteredSavedTags);
                window.localStorage.setItem('instance-tags-filters', JSON.stringify(filteredSavedTags))
            }
        }

    }, [tags]);

    // Check if there are any saved tags/values that does not exist anymore
    const filterExtraSavedTags = (list: any[], tagList: Tags[]) => {
        return [...list].filter(filter => {
            const tagExists = tagList.find(dbTag => dbTag.tagname === filter.tagName);

            if (tagExists) {
                // Filter tagValues that exist
                filter.tagValues = filter.tagValues.filter((value: string) =>
                    tagExists.tagvalues?.includes(value)
                );

                // Remove the filter object if tagValues become empty
                if (filter.tagValues.length === 0) {
                    return false;
                }
                return true;
            }

            return false;
        })
    }

    const comparisonSupported = useMemo(() => {
        return Helper.isComparisonSupported(timeRangeContext)
    }, [timeRangeContext]);

    const getTagValue = (list: Tags[], name: string) => {
        const getTag = list.find(item => item.tagname === name)
        return getTag ? getTag.tagvalue : ''
    }

    useEffect(function () {
            function createIdMap(array: any[]): any {
                // Key on id rather than index.
                let map: any = {};

                for (let i = 0; i < array.length; i++) {
                    map[array[i].id] = array[i];
                }

                return map;
            }

            // useEffect is synchronous so put the async function inside.
            async function load() {
                try {
                    setLoading(true);
                    let promises: Promise<any>[] =
                        [
                            fetchWithAuthorization(archiverUrl() + `/activity/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc`),
                            fetchWithAuthorization(archiverUrl() + `/statistic/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&statistic=executions`),
                            fetchWithAuthorization(archiverUrl() + `/change/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}`),
                            fetchWithAuthorization(archiverUrl() + `/event/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}`),
                            fetchWithAuthorization(archiverUrl() + `/alert/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}`),
                            fetchWithAuthorization(archiverUrl() + `/datasource/tag`)
                        ];

                    if (comparisonSupported) {
                        promises.push(fetchWithAuthorization(archiverUrl() + `/activity/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString(true)}`));
                        promises.push(fetchWithAuthorization(archiverUrl() + `/statistic/id?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString(true)}&statistic=executions`));
                    }

                    const results: any[][] = await fetchResults(promises);

                    if (active) {
                        const activity: ActivityId[] = results[0];
                        const statistics: StatisticId[] = results[1];
                        const changes: ChangeId[] = results[2];
                        const events: EventId[] = results[3];
                        const alerts: AlertId[] = results[4];
                        const tags: Tags[] = results[5];
                        let prevActivity: ActivityId[] = [];
                        let prevStatistics: StatisticId[] = [];
                        if (comparisonSupported) {
                            prevActivity = results[6];
                            prevStatistics = results[7];
                        }

                        const activityMap: any = createIdMap(activity);
                        const statisticMap: any = createIdMap(statistics);
                        const changeMap: any = createIdMap(changes);
                        const eventMap: any = createIdMap(events);
                        const alertMap: any = createIdMap(alerts);
                        const prevActivityMap: any = createIdMap(prevActivity);
                        const prevStatisticMap: any = createIdMap(prevStatistics);
                        const instanceMap: any = createIdMap(instances);

                        let data: InstancesDataTable[] = [];

                        // Add the active instances
                        for (let i = 0; i < activity.length; i++) {
                            const current: ActivityId = activity[i];
                            const executions: StatisticId =
                                statisticMap[current.id];
                            const changes: ChangeId = changeMap[current.id];
                            const events: EventId = eventMap[current.id];
                            const alerts: AlertId = alertMap[current.id];
                            const previous: ActivityId =
                                prevActivityMap[current.id];
                            const prevExecutions: StatisticId =
                                prevStatisticMap[current.id];
                            const instance: InstanceTarget =
                                instanceMap[current.id];
                            const currentInstanceTags: Tags[] = tags.filter(tag => tag.datasourceid === current.id)

                            if (instance) {
                                data.push(
                                    {
                                        id: current.id,
                                        name: instance.name,
                                        type: instance.type,
                                        status: instance.status,
                                        color: i < 10 ? TARGET_COLOUR_CODES[i] : '#FFFFFF',
                                        changes: changes ? changes.count : 0,
                                        alerts: alerts ? alerts.count : 0,
                                        customEvents: events ? events.count : 0,
                                        time: current ? current.waittime : null,
                                        executions: executions ? executions.sum : null,
                                        averageTime: current && executions ?
                                            current.waittime / executions.sum : null,
                                        previousTime: previous ? previous.waittime : null,
                                        previousExecutions: prevExecutions ?
                                            prevExecutions.sum : null,
                                        previousAverageTime: previous && prevExecutions ?
                                            previous.waittime / prevExecutions.sum : null,
                                        instance: instance,
                                        // Add properties for each tag with tagname as the property name and value
                                        ...tags.reduce((result, tag) => ({
                                            ...result,
                                            [tag.tagname]: getTagValue(currentInstanceTags, tag.tagname)
                                        }), {}),
                                    });
                            }
                        }

                        // Append the idle instances
                        for (let i = 0; i < instances.length; i++) {
                            const instance: InstanceTarget = instances[i];
                            const currentInstanceTags: Tags[] = tags.filter(tag => tag.datasourceid === instance.id)

                            if (activityMap[instance.id]) {
                                continue;
                            }
                            if (instance) {
                                data.push(
                                    {
                                        id: instance.id,
                                        name: instance.name,
                                        type: instance.type,
                                        status: instance.status,
                                        color: '#FFFFFF',
                                        changes: 0,
                                        alerts: 0,
                                        customEvents: 0,
                                        time: 0,
                                        executions: 0,
                                        averageTime: null,
                                        previousTime: null,
                                        previousExecutions: null,
                                        previousAverageTime: null,
                                        instance: instance,
                                        // Add properties for each tag with tagname as the property name and value
                                        ...tags.reduce((result, tag) => ({
                                            ...result,
                                            [tag.tagname]: getTagValue(currentInstanceTags, tag.tagname)
                                        }), {}),
                                    });
                            }
                        }

                        setInstancesDataTable(data);
                        setTableLoading(false)
                        const filteredTags: any = Helper.filterTagsByTagName(tags)
                        setTags(filteredTags)

                        // At this point would like to start a timer (setInterval)
                        // to update the instance statuses. But rebuilding the table
                        // with an initial sort order overrides the currently
                        // selected order.
                        // timeout = setInterval (() => updateTable (data), 30000);
                        // Could call (on InstanceContext):
                        // setInstances (await InstanceApi.getInstances ());
                        // and wait for useEffect to be triggered as it has a
                        // dependency on instances. This would fetch the data for
                        // the entire table, which may be slow.
                        // Instead just update the status as was done previously.
                    }
                } catch (x: any) {
                    console.log(x.message);
                    setError(x.message);
                    setLoading(false);
                    setInstancesDataTable([]);
                }
            }

            let active: boolean = true;
            load();
            return function () {
                active = false;
            }
        },
        // Don't like disabling dependencies but instances are dependent on period
        // in App.tsx, which results in a double fetch.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [timeRangeContext/*, instances*/]);


    useEffect(() => {
        const filteredByTagInstances = instancesDataTable.filter((instance: any) => {
            if (tagFilters.length) {
                return tagFilters.every(filter => {
                    const tagName = filter.tagName;
                    // Return instances that match any of the tagValues for each tagName
                    return filter.tagValues.includes(instance[tagName]);
                });
            } else {
                return true; // Return all instances if no filters are applied
            }
        });

        let alerts: number = 0;
        let changes: number = 0;
        let customEvents: number = 0;

        filteredByTagInstances.forEach((instance, i) => {
            alerts += instance.alerts || 0;
            changes += instance.changes || 0;
            customEvents += instance.customEvents || 0;
            if (i < 10) {
                instance.color = TARGET_COLOUR_CODES[i]
            }
        })

        setTotalAlerts(alerts);
        setTotalChanges(changes);
        setTotalCustomEvents(customEvents);
        setLoading(false);

        setFilteredInstances(filteredByTagInstances);
    }, [tagFilters, instancesDataTable]);

    const instancesOverTimeChart = useMemo(() => {
        return <InstancesOverTimeChart setLoading={setChartLoading}
                                       applyPeriod={props.applyPeriod}
                                       filters={props.filterParameters}
                                       instancesDataTable={filteredInstances}/>
    }, [loading, filteredInstances])

    return (
        <PageContent title='Instances - DBmarlin'
                     description='List of all monitored DB instances with high-level performance statistics'>
            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="Instances"
                            type="instances">
                    <Link to="/instances">Analysis</Link>
                    <NavLink to="/instances" activeClassName={"current-breadcrumb-link"}>Instances</NavLink>
                    <PeriodBreadcrumb/>
                </Breadcrumb>
                <HeaderActions period={period} toggleMenu={props.toggleMenu} refresh={() => {
                }}
                               applyPeriod={props.applyPeriod}/>
            </div>

            <div className="col loader">
                <ConditionalRender if={loading}>
                    <div className="bar"/>
                </ConditionalRender>
            </div>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions && <FilterBar period={period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            <ConditionalRender if={tags.length}>
                {/* Instance Filters */}
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="card collapsible">
                            <div className="card-header">
                                <i className="fal fa-gear fa-fw" aria-hidden="true"/>
                                Instance Filters
                                <span className="badge bg-info"
                                      data-tip="Applied filters count">{tagFilters.reduce((totalLength, filter) => totalLength + filter.tagValues.length, 0)}</span>
                                <i className="collapse-toggle collapsed" role="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseInstancesFilters" aria-expanded="false"
                                   aria-controls="collapseInstancesFilters"/>
                            </div>
                            <div id="collapseInstancesFilters" className="card-body collapse">
                                <TagFilters tags={tags} loading={chartLoading} tagFilters={tagFilters || []}
                                            setTagFilters={setTagFilters}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ConditionalRender>

            {/* Metric Widgets - Instances */}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
                <WidgetCard cardTooltipText={"The total auto-detected changes count"} iconClassName={"changes"}
                            name={"Changes"}
                            values={[{
                                'icon': '',
                                value: totalChanges,
                                tooltip: 'Total auto-detected change count'
                            }]}/>
                <WidgetCard cardTooltipText={"The total custom events count"} iconClassName={"calendar"}
                            icon="fa-calendar" name={"Custom Events"}
                            values={[{'icon': '', value: totalCustomEvents, tooltip: 'Total custom events count'}]}/>
                <WidgetCard cardTooltipText={"The total alerts count"} iconClassName={"alerts"}
                            icon="fa-exclamation-triangle" name={"Alerts"}
                            values={[{
                                'icon': '',
                                value: totalAlerts,
                                tooltip: 'Total triggered alerts count'
                            }]}/>

            </div>

            {instancesOverTimeChart}

            {/* Instances */}
            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-database fa-fw" aria-hidden="true"/>
                            Monitored instances
                            <span className="badge bg-info"
                                  data-tip="Total instances count">{filteredInstances.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseInstances" aria-expanded="false"
                               aria-controls="collapseInstances"/>
                        </div>
                        <ConditionalRender if={tableLoading}>
                            <div className="w-100 text-center text-muted  table-loader py-4">
                                <div className="loader spinner">
                                </div>
                                <p className="mt-3">
                                    Loading data...
                                </p>
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={!tableLoading}>
                            <div id="collapseInstances" className="card-body collapse show">
                                <InstancesTable tags={tags} error={error}
                                                loading={tableLoading ? DATA_LOADING : DATA_LOADED}
                                                instancesDataTable={filteredInstances}/>
                            </div>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
        </PageContent>
    )
}

List.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default List;
