// React.
import React, { useContext, useEffect, useMemo, useState } from 'react';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import ChartPlotBand from "../../../types/ChartPlotBand";
import ChartPlotLine from "../../../types/ChartPlotLine";
import CombinedChanges from "../../../types/instance/CombinedChanges";

// Helper.
import {
    archiverUrl,
    fetchResults, fetchWithAuthorization,
    getConvertedTimeToUTC,
    highchartsCredits,
    stringFormatting
} from '../../../helpers/utils';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { useLicences } from "../../../context/LicenceContext";
import Helper from "../../../helpers/Helper";
import dayjs from "dayjs";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
    getInstanceTotalDuration,
    getStatementExecutions,
    getStatementTypeFromURL
} from "../../instances/tabs/utils";

// Components.
import ConditionalRender from "../../../helpers/ConditionalRender";
import TimeStaticWidget from "../../../component/instance/widget/TimeStaticWidget";
import ExecutionsStaticWidget from "../../../component/instance/widget/ExecutionsStaticWidget";
import LegacyChangesWidget from "../../../component/instance/widget/LegacyChangesWidget";
import AverageTimeStaticWidget from "../../../component/instance/widget/AverageTimeStaticWidget";
import StatementSqlStatisticsTable from "../../../component/instance/table/StatementSqlStatisticsTable";
import EventsApi from "../../../api/instance/EventsApi";
import { CHART_DATA_TYPE } from "../../../component/Constants";

export default function SqlStatistics(props: {
    instance: InstanceTarget,
    filterParameters: string,
    statementId: string,
    batchStatementId?: string,
    applyPeriod: Function,
}) {
    const {instance, statementId} = props;
    const timeRangeContext = useContext(TimeRangeContext)
    const [loading, setLoading] = useState<boolean>(true);
    const pathname = window.location.pathname

    const [statementCombinedChanges, setStatementCombinedChanges] = useState<CombinedChanges[]>([]);
    const [statementChartPlotBands, setStatementChartPlotBands] = useState<ChartPlotBand[]>([]);
    const [statementChartPlotLines, setStatementChartPlotLines] = useState<ChartPlotLine[]>([]);

    const {licences} = useLicences();
    const statementType = getStatementTypeFromURL()

    const [timeSeries, setTimeSeries] = useState<number[][]>([]);
    const [executionsSeries, setExecutionsSeries] = useState<number[][]>([]);

    const [supportedStatistics, setSupportedStatistics] = useState<any[]>([]);
    const [statisticsToShow, setStatisticsToShow] = useState<any[]>([]);

    const [executions, setExecutions] = useState<number>(0);
    const [previousExecutions, setPreviousExecutions] = useState<number>(0);

    const [totalInstanceTime, setTotalInstanceTime] = useState<number>(0);
    const [totalPreviousInstanceTime, setTotalPreviousInstanceTime] = useState<number>(0);

    const timeSeriesPeak = useMemo(() => {
        return Helper.getPeakValue(timeSeries.map(i => i[1]))
    }, [timeSeries])

    const executionPeak = useMemo(() => {
        return Helper.getPeakValue(executionsSeries.map(i => i[1]))
    }, [executionsSeries])

    const getExecutions = async(instanceId: number) => {
        const executionsResult = await getStatementExecutions(instanceId, instance.type, statementId, timeRangeContext, props.filterParameters, statementType.isGroup, props.batchStatementId)
        setExecutions(executionsResult.data)
        setPreviousExecutions(executionsResult.previousData)
    }

    const getTotalTime = async(instanceId: number) => {
        const executionsTimeResult = await getInstanceTotalDuration(instanceId, timeRangeContext, instance.type, statementId, statementType.isGroup, props.batchStatementId)
        setTotalInstanceTime(executionsTimeResult.totalInstanceTime)
        setTotalPreviousInstanceTime(executionsTimeResult.totalPreviousInstanceTime)
    }


    const getChanges = async(instanceId: number) => {

        let allEvents: any[] = await EventsApi.getEventsChangesAndAlerts(timeRangeContext, instanceId),
            combinedChanges: CombinedChanges[] = [],
            chartPlotLines: ChartPlotLine[] = [];

        for (let index = 0; index < allEvents.length; index++) {
            const currentItem = allEvents[index];
            combinedChanges.push({
                id: index,
                instanceId: currentItem.datasourceid,
                timeslice: currentItem.timeslice,
                [currentItem.event]: currentItem
            });
        }

        // Set the sort order for the combined changes and events.
        combinedChanges.sort(function compare(a, b) {
            var aa: number = dayjs(a.timeslice).valueOf();
            var bb: number = dayjs(b.timeslice).valueOf();
            return bb - aa;
        });

        const interval = Helper.getInterval(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, timeRangeContext.timeRange?.interval)
        const chartCategories = Helper.getChartAxisCategories(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, interval)
        chartPlotLines = Helper.getChartPlotLines(null, combinedChanges, chartCategories, interval);
        const chartPlotBands = Helper.getChartPlotbands(combinedChanges, chartCategories);

        setStatementCombinedChanges(combinedChanges)
        setStatementChartPlotBands(chartPlotBands)
        setStatementChartPlotLines(chartPlotLines)
    }

    useEffect(() => {
        let isMounted = true;
        async function load() {
            try {
                await getExecutions(instance.id)
                await getTotalTime(instance.id)
                await getChanges(instance.id)
                setLoading(true);
                // const sqlHashString = getAPIString(statementId, props.batchStatementId)
                const sqlHashString = statementType.isGroup ? 'grouphash' : 'sqlhash'
                const results: any[][] = await fetchResults(
                    [

                        fetchWithAuthorization(archiverUrl(2) + `/sql/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}&type=${instance.type}&${sqlHashString}=${props.batchStatementId || statementId}`)
                    ]);
                let executions: number[][] = []
                let duration: number[][] = []
                if (active && results[0].length) {
                    const supportedStatisticsList: any[][] = await fetchResults(
                        [
                            fetchWithAuthorization(archiverUrl(1) + `/sql/statistic/name?type=${props.instance.type}&collect=true`)
                        ]);

                    const filteredSupportedStatistics = supportedStatisticsList[0].filter(key => key !== 'duration' && key !== 'executions');
                    const stats: any[] = []

                    filteredSupportedStatistics.forEach(stat => {
                        stats.push({
                            name: stat,
                            data: results[0].map(item => [getConvertedTimeToUTC(item), item[stat]])
                        });
                    })

                    results[0].forEach(item => {
                        executions.push([getConvertedTimeToUTC(item), item.executions])
                        duration.push([getConvertedTimeToUTC(item), item.duration])
                    })
                    setSupportedStatistics(stats)
                    setExecutionsSeries(executions)
                    setTimeSeries(duration)
                }
                setLoading(false);
            } catch (x: any) {
                console.log(x.message);
                setLoading(false);
            }

            return true;
        }

        let active: boolean = true;
        if (instance && isMounted) {
            setLoading(true);

            const getDataPromise = load();

            getDataPromise.then(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        }

        return () => {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [timeRangeContext, instance.id, instance.type, pathname]);

    const timeChart = useMemo(() => {
        const timeChartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                type: 'column',
                color: '#69d1bf',
                data: timeSeries,
                name: 'Duration',
                zIndex: 1,
                tooltip: {valueSuffix: 'ms'}
            }, {
                type: 'spline',
                color: '#ff853d',
                minHeight: 3,
                data: executionsSeries,
                name: 'Executions',
                zIndex: 1,
                yAxis: 1
            }
        ], timeSeriesPeak, {
            credits: {enabled: highchartsCredits(licences)},
            legend: true,
            timeRangeContext: timeRangeContext,
            plotLines: statementChartPlotLines,
            plotBands: statementChartPlotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                },
            },
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                options={timeChartOptions}/>;

    }, [timeRangeContext, timeSeries, timeSeriesPeak, executionsSeries]);

    const getChart = ((statisticData: { name: string, data: [] }) => {
        const chartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                type: 'column', data: statisticData.data, name: statisticData.name, zIndex: 2
            }
        ], null, {
            credits: {enabled: highchartsCredits(licences)},
            timeRangeContext: timeRangeContext,
            plotLines: statementChartPlotLines,
            plotBands: statementChartPlotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                },
            },
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                options={chartOptions}/>;

    });

    const renderedList = useMemo(() => {
        const sortedList = supportedStatistics.sort((a, b) => a.name.localeCompare(b.name));
        return sortedList?.map((statistic: any[any], i) =>
            <div key={i}>
                <input className="form-check-input" type="checkbox" id={`option_${i}`}
                       checked={statisticsToShow.find(item => item.name === statistic.name)}
                       onChange={() => updateSqlExtendedOptionsList(statistic)}
                />
                <label htmlFor={`option_${i}`}>{stringFormatting(statistic.name)}</label>
            </div>)

    }, [supportedStatistics, statisticsToShow])

    const updateSqlExtendedOptionsList = (value: any[any]) => {
        let updatedList = JSON.parse(JSON.stringify(statisticsToShow))
        // @ts-ignore
        const exist = updatedList?.find(item => item.name === value.name)
        if (exist) {
            const filteredList = updatedList.filter((item: any) => item.name !== exist.name);
            setStatisticsToShow(filteredList)
        } else {
            updatedList.push(value)
            setStatisticsToShow(updatedList)
        }
    }

    const checkUncheckAll = (checkState: boolean) => {
        setStatisticsToShow(checkState ? supportedStatistics : [])
    }

    const changes = statementCombinedChanges.filter(item => item.change);
    const events = statementCombinedChanges.filter(item => item.event);
    const alerts = statementCombinedChanges.filter(item => item.alert);

    return (<>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
                <TimeStaticWidget metric={totalInstanceTime} metricPrevious={totalPreviousInstanceTime}/>
                <ExecutionsStaticWidget metric={executions} metricPrevious={previousExecutions}/>
                <AverageTimeStaticWidget executionsMetric={executions}
                                         executionsMetricPrevious={previousExecutions}
                                         timeMetric={totalInstanceTime}
                                         timeMetricPrevious={totalPreviousInstanceTime}/>

                <LegacyChangesWidget changes={changes}
                                     alerts={alerts}
                                     events={events}/>

            </div>
            <ConditionalRender if={loading}>
                <div className="w-100 center-screen text-center text-muted mt-3">
                    <div className="loader spinner chartSpinner">
                    </div>
                    <p className="mt-3">
                        Loading data...
                    </p>
                </div>
            </ConditionalRender>
            <ConditionalRender if={!loading}>
                <div className="row row-cols-1">
                    <div id="statements" className="tab-pane fade show active" role="tabpanel"
                         aria-labelledby="statements-tab">

                        <div className="card collapsible">
                            <div className="card-header">
                                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                                Duration (ms) and Executions
                                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                                   data-bs-target={"#collapse-duration"} aria-expanded="false"
                                   aria-controls={"collapse-duration"}/>
                                <div className="btn-group float-end dropdown ">
                                    {supportedStatistics.length ?
                                        <button type="button" id="filter1" className="btn btn-dropdown dropdown-toggle"
                                                data-bs-toggle="dropdown" aria-expanded="false">More Statistics
                                        </button> : ''}
                                    <div id="dropdown-menu" className="row g-0 dropdown-menu dropdown-menu-scroll"
                                         aria-labelledby="filter" onClick={(e) => e.stopPropagation()}>
                                        <div className='select-all'>
                                            <span onClick={() => checkUncheckAll(true)}>All</span><label
                                            className='separator'>|</label><span
                                            onClick={() => checkUncheckAll(false)}>None</span>
                                        </div>
                                        <div className='statistics-list'>
                                            {renderedList}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div id={"collapse-duration"} className="card-body collapse show">
                                <ConditionalRender if={loading}>
                                    <div className="w-100 text-center text-muted mt-3">
                                        <div className="loader spinner chartSpinner">
                                        </div>
                                        <p className="mt-3">
                                            Loading data...
                                        </p>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender if={!loading}>
                                    <p className="chart-peak">
                                        <React.Fragment>Peak
                                            Time: {Helper.getTimeInEnglish(timeSeriesPeak)}</React.Fragment>,&nbsp;
                                        <React.Fragment>Peak
                                            Executions: {executionPeak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</React.Fragment>
                                    </p>
                                    <br/>
                                    {timeChart}
                                </ConditionalRender>

                            </div>
                        </div>

                        {statisticsToShow.map(stat =>
                            <div className="card collapsible">
                                <div className="card-header">
                                    <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                                    {stat.name.replaceAll('_', ' ')}
                                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                                       data-bs-target={`#${stat.name}`} aria-expanded="false"
                                       aria-controls={stat.name}/>
                                </div>
                                <div id={stat.name} className="card-body collapse show">
                                    <ConditionalRender if={loading}>
                                        <div className="w-100 text-center text-muted mt-3">
                                            <div className="loader spinner chartSpinner">
                                            </div>
                                            <p className="mt-3">
                                                Loading data...
                                            </p>
                                        </div>
                                    </ConditionalRender>

                                    <ConditionalRender if={!loading}>
                                        {getChart(stat)}
                                    </ConditionalRender>
                                </div>
                            </div>)}

                        <StatementSqlStatisticsTable instance={props.instance} statementId={statementId} batchStatementId={props.batchStatementId} filterParameters={props.filterParameters} />
                    </div>
                </div>
            </ConditionalRender>
        </>
    )
}
