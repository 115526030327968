import { useState } from 'react';
import ConditionalRender from "../../helpers/ConditionalRender";
import './style.scss';
import { Link, useHistory } from "react-router-dom";

const ExpandableCell = (props: { data: any }) => {
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const getSQLStatisticsLink = (sqlhash: string) => {
        const currentURL = history.location.pathname;
        return currentURL.replace('transaction-contention', `sql-statistics/statement/${sqlhash}/sql-statistics`);
    }

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + ' ...';
        }
        return text;
    };

    return (props.data.length ?
        <div className='d-flex'>
            <ConditionalRender if={props.data.length > 1}>

                <span className='expand-icon' onClick={toggleExpand}>{expanded ? '-' : '+'}</span>
            </ConditionalRender>
            <ul className='expandable-list'>
                <li>
                    <Link to={getSQLStatisticsLink(props.data[0].sqlhash)}>{props.data[0].sqlhash}</Link>: "{truncateText(props.data[0].sqltext, 50)}"
                </li>
                {expanded && props.data.length > 1 && (
                    props.data.slice(1).map((item: { sqlhash: string; sqltext: string }, index: number) => (
                        <li key={index}>
                            <Link to={getSQLStatisticsLink(item.sqlhash)}>{item.sqlhash}</Link>: "{truncateText(item.sqltext, 50)}"

                        </li>
                    ))
                )}

            </ul>
        </div> : < span />);
}

export default ExpandableCell;
