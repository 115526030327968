import { useState } from 'react';
import PropTypes from 'prop-types';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Context.

// Api.

// Types.
import Period from '../../types/Period';

// Constants.
import { DATA_INITIALISING, DATA_LOADING } from '../../component/Constants';

// Helpers.

// Components.
import Breadcrumb from "../../container/breadcrumb";
import PageContent from '../../container/page_content';
import {Link, NavLink} from "react-router-dom";
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import FilterBar from "../../component/FilterBar";
import HostTarget from "../../types/host/HostTarget";
import EventHistoryContainer from "../../container/event-history";
import EventType from "../../types/instance/EventType";
import InstanceTarget from "../../types/instance/InstanceTarget";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

function Events(props: { period: Period, toggleMenu: Function, applyPeriod: Function, setFilterOptions: Function, hostId: number, hosts: HostTarget[], eventTypes: EventType[], instances: InstanceTarget[] } ) {
    const hostId = props.hostId

    const [loading, setLoading] = useState<number>(DATA_INITIALISING);

    const host = props.hosts.find(item => item.id === hostId)


    function refresh() {
        setLoading(DATA_INITIALISING);
    }

    return (

        <PageContent title='Host Events - DBmarlin' description='List of all evetns'>
            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading={host?.name || ''}
                            type="hosts"
                            hostId={hostId}>
                    <Link to="/hosts">Analysis</Link>
                    <Link to="/hosts">Hosts</Link>
                    <Link to={`/hosts/${hostId}`}>{host?.name}</Link>
                    <NavLink to={`/hosts/${hostId}`} activeClassName={"current-breadcrumb-link"}>Events</NavLink>
                    <PeriodBreadcrumb />
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={refresh} applyPeriod={props.applyPeriod} />
            </div>

            <div className="col loader">
                <ConditionalRender if={loading === DATA_LOADING}>
                    <div className="bar" />
                </ConditionalRender>
            </div>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions && <FilterBar period={props.period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            {/* Tab Headings */}
            <ul id="menu-top" className="row row-cols-auto" role="tablist">
                <Link to={`/hosts/${hostId}`}>Host Metrics</Link>
                <Link to={`/hosts/${hostId}/instances`}>Database Activity</Link>
                <Link to={`/hosts/${hostId}/events`} className="active">Events</Link>
            </ul>

            {/* Events */}
            <div className="row row-cols-1">
                <div className="col">
                    <EventHistoryContainer applyPeriod={props.applyPeriod} host={props.hosts.find(h=> h.id === hostId)} hosts={props.hosts} eventTypes={props.eventTypes} instances={props.instances} />
                </div>
            </div>
        </PageContent>
    )
}

Events.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Events;
