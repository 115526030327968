import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Api.
import api from '../../../../api/Base';
import IntegrationApi from '../../../../api/IntegrationApi';

// Context.
import { InstanaIntegrationContext } from '../../../../context/InstanaIntegrationContext';

// Types.
import Action from '../../../../types/Action';
import InstanaIntegration from '../../../../types/integration/InstanaIntegration';

// Constants.
import { ACTIONS } from '../../../../component/Constants';

// Components.
import Alert from '../../../../component/Alert';

function Detail(props: { actionId: string, instanaId?: number, refresh: Function } ) {
    const history = useHistory();
    const { instanaIntegrations, setInstanaIntegrations } = useContext(InstanaIntegrationContext);

    useEffect(() => {

        const getIntegration = (instanaId: number) => {

            const matchedInstanaIntegrations = instanaIntegrations.filter((object: InstanaIntegration) => Number(object.id) === Number(instanaId));

            if (matchedInstanaIntegrations.length === 1) {
                setId(matchedInstanaIntegrations[0].id);
                setName(matchedInstanaIntegrations[0].name);
                setHost(matchedInstanaIntegrations[0].host);
                setApiKey(matchedInstanaIntegrations[0].apikey);
            } else {
                setIsMissing(true);
            }
        }

        const getAction = () => {

            switch (props.actionId) {
                case 'create':

                    setAction(ACTIONS.CREATE);
                    
                    break;
            
                case 'edit':

                    setAction(ACTIONS.UPDATE);
                    
                    break;
                        
                case 'delete':

                    setAction(ACTIONS.DELETE);
                    setDisabled(true);
                    
                    break;
            
                default:

                    console.error('Unknown action requested', props.actionId);

                    setIsError(true);

                    break;
            }
        }

        getAction();

        if (props.instanaId) {
            getIntegration(props.instanaId);
        }

    }, [instanaIntegrations, props.actionId, props.instanaId]);

    // Set the state.
    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(props.actionId === ACTIONS.DELETE.type);
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState<string>('');
    const [nameUsed, setNameUsed] = useState<boolean>(false);
    const [nameClass, setNameClass] = useState<string>('');
    const [host, setHost] = useState<string>('');
    const [hostUsed, setHostUsed] = useState<boolean>(false);
    const [hostClass, setHostClass] = useState<string>('');
    const [apikey, setApiKey] = useState<string>('');
    const [apikeyClass, setApiKeyClass] = useState<string>('');
    const [apikeyElementType, setApiKeyElementType] = useState<string>('password');

    function handleNameBlur() {

        // Trim any white space.
        let value = name.trim();

        // Save to state.
        setName(value);

        if (value === '') {

            // Invalid, set the class.
            setNameClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setNameClass('is-valid');
        }

        // Check for duplicate names.
        const index = instanaIntegrations.findIndex(object => Number(object.id) !== id && object.name.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setNameClass('is-valid');
            setNameUsed(false);
        } else {

            // Duplicate found.
            setNameClass('is-invalid');
            setNameUsed(true);
        }
    }

    function handleHostBlur() {

        // Trim any white space.
        let value = host.trim().toLowerCase();

        // Save to state.
        setHost(value);

        if (value === '') {

            // Invalid, set the class.
            setHostClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setHostClass('is-valid');
        }

        // Check for duplicate names.
        const index = instanaIntegrations.findIndex(object => Number(object.id) !== id && object.host.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setHostClass('is-valid');
            setHostUsed(false);
        } else {

            // Duplicate found.
            setHostClass('is-valid');
            setHostUsed(false);
        }

        // Validate entry as a valid, but basic, URL, while catering for custom ports too.
        const regexp = /^(http|https):\/\/[^ "]+$/;

        if (regexp.test(value)) {

            setHostClass('is-valid');
        } else {

            setHostClass('is-invalid');
        }
    }

    async function handleActionClick() {

        // Validate the form.
        let valid = true;

        if (name.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setNameClass('is-invalid');
        } else {

            // Valid.
            setNameClass('is-valid');
        }

        if (host.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setHostClass('is-invalid');
        } else {

            // Valid.
            setHostClass('is-valid');
        }

        // Validate entry as a valid, but basic, URL, while catering for custom ports too.
        const regexp = /^(http|https):\/\/[^ "]+$/;

        if (regexp.test(host)) {

            setHostClass('is-valid');
        } else {

            valid = false;

            setHostClass('is-invalid');
        }

        if (apikey.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setApiKeyClass('is-invalid');
        } else {

            // Valid.
            setApiKeyClass('is-valid');
        }

        setIsValid(valid);

        if (!valid) {
            return;
        }

        // Build the event type object.
        let data = [{
            id,
            name,
            host,
            apikey
        }];

        let result: any = undefined;

        switch (action) {
            case ACTIONS.CREATE:

                // Save the object.
                result = await api.post('integration', data);

                break;
            case ACTIONS.UPDATE:

                // Update the object.
                result = await api.put('integration', data);

                break;
            case ACTIONS.DELETE:

                // Delete the object.
                result = await api.delete('integration', { data });

                break;
            default:

                // Unknown action requested.
                console.error('Unknown action requested', props.actionId);

                setIsError(true);

                break;
        }

        if (result.status !== 200) {

            // Failed to complete the API request.
            console.error('Failed to complete requested action', props.actionId, result, data);

            // Failed to complete request.
            setIsError(true);

            // An error occurred, so exit this function.
            return;
        }        

        // Get the latest instances and update the context object.
        const updatedInstanaIntegrations = await IntegrationApi.getIntegrations();
        setInstanaIntegrations(updatedInstanaIntegrations);

        if (result.status === 200) {
            // Redirect the user back to the list view.
            history.push('/admin/integrations/instana');
            props.refresh()
        }
    }

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fak fa-stan fa-fw" aria-hidden="true"></i>
                    {action.title} Instana integration
                </div>
                <div className="card-body">
                    {isMissing ? (
                        <React.Fragment>
                            <Alert message="The requested Instana integration is either invalid or no longer available." heading="Invalid request" variant="alert-danger" />
                            <div className="row g-0 form">
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <Link to="/admin/integrations/instana/" role="button" className="btn btn-secondary"><i className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            {action.type === 'delete' && (
                                <Alert message="Please check and ensure you want to delete this Instana integration." heading="Deletes are final and cannot be undone" variant="alert-warning" />
                            )}

                            {isError && (
                                <Alert message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request." heading={`Failed to ${action.title.toLowerCase()} host`} variant="alert-danger" />
                            )}

                            {!isValid && (
                                <Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
                            )}

                            <p>Please complete the required details below:</p>

                            <div className="row g-0 form">

                                {/* Title */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="name" className="reminder" data-tip="The Instana integration name">Name</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type="text"
                                        id="name"
                                        autoFocus
                                        className={`form-control ${nameClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleNameBlur}
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {nameUsed && (
                                        <small className="text-danger">Instana integration name already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>

                                {/* Host */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="host" className="reminder" data-tip="The Instana host address">Host Address</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type="text"
                                        id="host"
                                        className={`form-control ${hostClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleHostBlur}
                                        onChange={e => setHost(e.target.value)}
                                        value={host}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {hostUsed && (
                                        <small className="text-danger">Host address already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>
                                
                                {/* API Key */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="apikey" className="reminder" data-tip="Set the API key">API Key</label></span>
                                    {action === ACTIONS.CREATE && (
                                        <i className="fas fa-eye fa-fw fa-xs ms-1" role="button" onClick={() => ((apikeyElementType === 'text') ? setApiKeyElementType('password') : setApiKeyElementType('text'))}></i>
                                    )}
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type={apikeyElementType}
                                        id="apikey"
                                        className={`form-control ${apikeyClass}`}
                                        maxLength={250}
                                        onChange={e => setApiKey(e.target.value)}
                                        value={apikey}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    <small className="text-secondary">
                                        For your API key, please refer to <a href="https://www.instana.com/docs/api/web/#tokens" target="_blank" rel="noopener noreferrer">Instana's documentation.</a>
                                        <sup className="fal fa-external-link fa-fw"></sup>
                                    </small>
                                </div>
                                <div className="w-100"></div>

                                {/* Actions */}
                                <div className="col-xs-12 col-md-9 col-lg-7 actions">
                                    <Link to="/admin/integrations/instana" role="button" className="btn btn-secondary"><i className="fal fa-times-circle fa-fw fa-sm"></i><span>Cancel</span></Link>
                                    <button className={`btn btn-${action.variant}`} onClick={handleActionClick}><i className={`${action.icon} fa-fw fa-sm`}></i><span>{action.title}</span></button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Detail;
