import ConditionalRender from "../../../helpers/ConditionalRender";
import Tags from "../../../types/Tags";
// import { useHistory } from 'react-router-dom';
import './style.scss';

const TagFilters = (props: { tags: Tags[], tagFilters: any[], loading: boolean, setTagFilters: Function }) => {

    const {tags, tagFilters, setTagFilters} = props
    // const history = useHistory();

    const updateTags = (tagName: string, tagValue: string, isDelete: string) => {

        if (isDelete) {
            // Remove the specified tagName and tagValue
            const filteredTags = tagFilters.map(tag => ({
                tagName: tag.tagName,
                tagValues: tag.tagName === tagName ? tag.tagValues.filter((val: string) => val !== tagValue) : tag.tagValues
            })).filter(tag => tag.tagValues.length > 0); // Remove empty tagValues

            window.localStorage.setItem('instance-tags-filters', JSON.stringify(filteredTags))
            setTagFilters(filteredTags)
        } else {
            const updatedFilters = [...tagFilters]
            const existingTag = updatedFilters.find(tag => tag.tagName === tagName);

            if (existingTag) {
                existingTag.tagValues.push(tagValue)
            } else {
                // Add a new tag entry if the tag name doesn't exist
                updatedFilters.push({
                    tagName: tagName,
                    tagValues: [tagValue]
                });
            }
            window.localStorage.setItem('instance-tags-filters', JSON.stringify(updatedFilters))
            setTagFilters(updatedFilters)
        }
    };

    const isTagChecked = (tagName: string, tagValue: string) => {
        const getTag = tagFilters.find((tag: any) => tag.tagName === tagName)
        if (getTag) {
            return getTag.tagValues.includes(tagValue)
        }
        return false
    }

    const getTotalValues = (tag: string) => {
        const currentTag = tagFilters.find(item => item.tagName === tag)
        return currentTag ? currentTag.tagValues.length : 0
    }

    const resetFilters = () => {
        setTagFilters([])
        window.localStorage.removeItem('instance-tags-filters')
    }

    return (<div className="d-flex">
            <div className="tag-filters d-flex">
                {tags.map((tag: Tags, i: number) => <div key={i}>
                    <div className="btn-group float-end dropdown">

                        <button type="button" id="filter1" className="btn btn-dropdown dropdown-toggle instance-filters"
                                data-bs-toggle="dropdown" aria-expanded="false">{tag.tagname}
                            <ConditionalRender if={getTotalValues(tag.tagname)}>
                            <span className="badge bg-info"
                                  data-tip="Applied filters count">{getTotalValues(tag.tagname)}</span>
                            </ConditionalRender>
                        </button>
                        <div id="dropdown-menu" className="row g-0 dropdown-menu dropdown-menu-scroll"
                             aria-labelledby="filter" onClick={(e) => e.stopPropagation()}>
                            <ConditionalRender if={tag.tagvalues?.length}>
                                <div className='tag-values-list'>
                                    <ul className="list-unstyled">
                                        {tag?.tagvalues?.map((value: string, index: number) =>
                                            <li key={index}
                                                onClick={() => props.loading ? {} : updateTags(tag.tagname, value, isTagChecked(tag.tagname, value))}>
                                                <input
                                                    type="checkbox"
                                                    disabled={props.loading}
                                                    id={value}
                                                    className="form-check-input"
                                                    checked={isTagChecked(tag.tagname, value)}
                                                />{value}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>
                </div>)}
            </div>
            <button className="btn btn-sm btn-dark btn-reset" onClick={resetFilters}><i
                className="far fa-undo"/><span>Clear</span></button>
        </div>
    );
};

export default TagFilters;
