import {
    INTERVAL_FIFTEEN_MINUTES,
    INTERVAL_FIVE_MINUTES,
    INTERVAL_MINUTE,
    INTERVAL_ONE_DAY,
    INTERVAL_ONE_HOUR
} from "../../component/Constants";
import InstanceComparisonOptions from "./instance-comparison-options";
import { useCallback, useEffect, useMemo, useState } from "react";
import InstanceApi from "../../api/instance/InstanceApi";
import ConditionalRender from "../../helpers/ConditionalRender";
import Alert from "../../component/Alert";
import { ERROR_MESSAGE_DATES } from "./constants";
import { InstanceOptions, StatisticsComparisonOptionsCardProps } from "./types";


const StatisticsComparisonOptionsCard = (props: StatisticsComparisonOptionsCardProps) => {
    const [firstInstanceOptions, setFirstInstanceOptions] = useState<InstanceOptions>(props.options.firstInstanceOptions)
    const [secondInstanceOptions, setSecondInstanceOptions] = useState<InstanceOptions>(props.options.secondInstanceOptions)
    const [metricsList, setMetricsList] = useState<string[]>([])

    const allRequiredOptionsSet = useCallback(() => {
        return !!(props.options.aggregate &&
            props.options.metric &&
            props.options.chart &&
            props.options.topN &&
            firstInstanceOptions.instanceId &&
            firstInstanceOptions.periodEnd &&
            firstInstanceOptions.periodStart &&
            secondInstanceOptions.instanceId &&
            secondInstanceOptions.periodStart &&
            secondInstanceOptions.periodEnd && validDates)
    }, [props.options, firstInstanceOptions, secondInstanceOptions])

    useEffect(() => {
        if (firstInstanceOptions.instanceId !== -1 && firstInstanceOptions.periodEnd && firstInstanceOptions.periodStart) {
            props.onOptionsChange('firstInstanceOptions', firstInstanceOptions)
        }

        if (secondInstanceOptions.instanceId === -1) {
            setSecondInstanceOptions({
                ...secondInstanceOptions,
                instanceId: firstInstanceOptions.instanceId,
                instanceName: firstInstanceOptions.instanceName,
                instanceType: firstInstanceOptions.instanceType,
                snapshotId: firstInstanceOptions.snapshotId,
            })
        }
    }, [firstInstanceOptions])

    useEffect(() => {
        if (secondInstanceOptions.instanceId !== -1 && secondInstanceOptions.periodEnd && secondInstanceOptions.periodStart) {
            props.onOptionsChange('secondInstanceOptions', secondInstanceOptions)
        }
    }, [secondInstanceOptions])

    const showSqlIdToggle = useMemo(() => {
        return firstInstanceOptions.instanceType === 'oracle' || secondInstanceOptions.instanceType === 'oracle';
    }, [firstInstanceOptions, secondInstanceOptions])

    useEffect(() => {
        const instanceTypes = [firstInstanceOptions.instanceType, secondInstanceOptions.instanceType]
        let _metricsList: any[] = []
        instanceTypes.forEach((instanceType: string | undefined) => {
            if (instanceType) {
                InstanceApi.getAvailableMetrics(`/sql/statistic/name?type=${instanceType}`).then(metrics => {
                    _metricsList.push(metrics)

                    let filteredMetrics = []
                    if (_metricsList.length > 1) {
                        filteredMetrics = _metricsList[0].filter((value: string) => _metricsList[1].includes(value));
                    }
                    setMetricsList(filteredMetrics)
                })
            }
        })
    }, [firstInstanceOptions, secondInstanceOptions])

    const validDates = useMemo(() => {
        return firstInstanceOptions.periodStart < firstInstanceOptions.periodEnd && secondInstanceOptions.periodStart < secondInstanceOptions.periodEnd
    }, [firstInstanceOptions, secondInstanceOptions])

    const setTopN = (val: any) => {
        const result = Math.abs(parseInt(val.target.value, 10))
        props.onOptionsChange('topN', result > 100 ? 100 : result)
    }

    return <div className="card collapsible">
        <div className="card-header">
            <i className="fal fa-stopwatch fa-fw" aria-hidden="true"/>
            SQL Stats comparison options
            <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseSettings"
               aria-expanded="false" aria-controls="collapseSettings"/>
        </div>
        <div id="collapseSettings" className="card-body collapse show">
            <ConditionalRender if={!validDates}>
                <Alert message={ERROR_MESSAGE_DATES.INVALID.TEXT} heading={ERROR_MESSAGE_DATES.INVALID.TITLE}
                       variant="alert-danger"/>
            </ConditionalRender>
            <div className='row row-cols-lg-12'>
                <div className="col-xs-10 col-md-11 col-lg-11">
                    <InstanceComparisonOptions key={'firstInstanceOptions'}
                                               loading={props.loading} {...firstInstanceOptions}
                                               onOptionsChange={setFirstInstanceOptions}/>
                    <InstanceComparisonOptions key={'secondInstanceOptions'}
                                               loading={props.loading} {...secondInstanceOptions}
                                               onOptionsChange={setSecondInstanceOptions}/>

                    <div className="row row-cols-lg-12 mt-3">
                        <div className="col-xs-12 col-md-2 col-lg-2 ">
                    <span className="required">
                        <label>Metric</label>
                    </span>
                            <select className="form-select"
                                    value={props.options.metric || ""}
                                    disabled={props.loading}
                                    aria-label="Select Metric"
                                    defaultValue={""}
                                    onChange={(e) => {
                                        props.onOptionsChange('metric', e.target.value)
                                    }}>
                                <option value="" disabled={true}>Select metric</option>
                                {metricsList.map((metric: string, index: number) => <option
                                    key={`${index}${metric}`}>{metric}</option>)}
                            </select>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-2">
                            <label>Aggregate</label>
                            <select className="form-select"
                                    aria-label="Select Aggregate"
                                    disabled={props.loading}
                                    value={props.options.aggregate}
                                    onChange={(e) => {
                                        props.onOptionsChange('aggregate', e.target.value)
                                    }}>
                                <option value={'auto'}>Auto</option>
                                <option value={'timeslice'}>Timeslice</option>
                                <option value={INTERVAL_MINUTE}>Minute</option>
                                <option value={INTERVAL_FIVE_MINUTES}>5 Minutes</option>
                                <option value={INTERVAL_FIFTEEN_MINUTES}>15 Minutes</option>
                                <option value={INTERVAL_ONE_HOUR}>Hour</option>
                                <option value={INTERVAL_ONE_DAY}>Day</option>
                            </select>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-2 ">
                            <label>Chart</label>
                            <select className="form-select"
                                    value={props.options.chart}
                                    disabled={props.loading}
                                    aria-label="Select Chart Type"
                                    onChange={(e) => {
                                        props.onOptionsChange('chart', e.target.value)
                                    }}>
                                <option key='area' value='area'>Stacked Area</option>
                                <option key='column' value='column'>Stacked Column</option>
                                <option key='line' value='line'>Multi-Series Line Chart</option>
                            </select>
                        </div>
                        <div className="col-xs-12 col-md-3 col-lg-1">
                    <span className="required text-nowrap">
                        <label>Top N</label>
                    </span>
                            <input className="form-control"
                                   type='number'
                                   max={100}
                                   min={1}
                                   value={props.options.topN || ''}
                                   disabled={props.loading}
                                   onChange={(e) => {
                                       setTopN(e)
                                   }}
                                   aria-label="Select Top N Statement to Show"/>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-2 d-none">
                            <label>Tag</label>
                            <select className="form-select"
                                    value={props.options.tagName}
                                    disabled={props.loading}
                                    aria-label="Select Tag"
                                    onChange={(e) => {
                                        props.onOptionsChange('tagName', e.target.value)
                                    }}>
                                <option value="none">None</option>
                                {/*{tagList.length &&*/}
                                {/*    <optgroup label="Tag Name">*/}
                                {/*        {tagList.map((tag, i) => <option key={i} value={tag}>{tag}</option>)}*/}
                                {/*    </optgroup>}*/}
                            </select>
                        </div>
                        <div className="col-xs-12 col-md-2 col-lg-1">
                            <label data-tip='All other statements combined'>Other</label>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="disabled"
                                        disabled={props.loading}
                                        className="form-check-input"
                                        onChange={() => {
                                            props.onOptionsChange('other', !props.options.other)
                                        }}
                                        checked={props.options.other}
                                    />
                                </div>
                            </div>
                        </div>
                        <ConditionalRender if={showSqlIdToggle}>
                            <div className="col-xs-12 col-md-2 col-lg-2">
                                <label className='text-nowrap'>Show SQL IDs</label>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <div className='form-check form-switch'>
                                        <input
                                            type="checkbox"
                                            id="disabled"
                                            disabled={props.loading}
                                            className="form-check-input"
                                            onChange={() => {
                                                props.onOptionsChange('showSqlId', !props.options.showSqlId)
                                            }}
                                            checked={props.options.showSqlId}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ConditionalRender>
                        <div className="col-xs-12 col-md-2 col-lg-1">
                            <label className='text-nowrap'>{`${props.options.showSqlId ? 'SQL ID' : 'SQL #'}`}</label>
                            <input className="form-control"
                                   type='text'
                                   max={100}
                                   min={1}
                                   value={props.options.customSqlId}
                                   disabled={props.loading}
                                   onChange={(e) => {
                                       props.onOptionsChange('customSqlId', e.target.value)
                                   }}/>
                        </div>
                    </div>
                </div>
                <div className="col-xs-2 col-md-1 col-lg-1 d-flex align-items-end justify-end">
                    <button className="btn btn-success text-nowrap" disabled={!props.loading && !allRequiredOptionsSet()}
                            onClick={() => props.onGenerate()}>
                        <i className="fal fa-play fa-fw fa-sm"/><span>Generate</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default StatisticsComparisonOptionsCard;
