import Action from '../types/Action';

/* Actions */
export const ACTION_CREATE: Action = {
    icon: 'fal fa-save',
    title: 'Create',
    type: 'create',
    variant: 'success'
};

export const ACTION_UPDATE: Action = {
    icon: 'fal fa-save',
    title: 'Update',
    type: 'update',
    variant: 'success'
};

export const ACTION_DELETE: Action = {
    icon: 'fal fa-trash-alt',
    title: 'Delete',
    type: 'delete',
    variant: 'danger'
};

export class ACTIONS {

    public static readonly CREATE: Action = {
        icon: 'fal fa-save',
        title: 'Create',
        type: 'create',
        variant: 'success'
    };

    public static readonly UPDATE: Action = {
        icon: 'fal fa-save',
        title: 'Update',
        type: 'update',
        variant: 'success'
    };

    public static readonly DELETE: Action = {
        icon: 'fal fa-trash-alt',
        title: 'Delete',
        type: 'delete',
        variant: 'danger'
    }
}

/* Licence Tiers */
export class LICENCE_TIER {
    public static readonly STARTER: number = 1;
    public static readonly STANDARD: number = 2;
    public static readonly PREMIUM: number = 3;
}

/* Chart Data Options */
export class CHART_DATA_OPTION {
    public static readonly ACTIVITY: number = 1;
    public static readonly BATCHES: number = 2;
    public static readonly STATEMENTS: number = 3;
    public static readonly WAITS: number = 4;
}

/* Target Types */
export const TARGET_TYPE_DATABASE = 0;
export const TARGET_TYPE_HOST = 0;

/* Instance Types */
export const INSTANCE_TYPE_COCKROACHDB = 'cockroachdb';
export const INSTANCE_TYPE_DB2 = 'db2';
export const INSTANCE_TYPE_INFORMIX = 'informix';
export const INSTANCE_TYPE_MYSQL = 'mysql';
export const INSTANCE_TYPE_ORACLE = 'oracle';
export const INSTANCE_TYPE_POSTGRES = 'postgresql';
export const INSTANCE_TYPE_SAPHANA = 'saphana';
export const INSTANCE_TYPE_SQLSERVER = 'sqlserver';
export const INSTANCE_TYPE_GENERIC = 'generic';
export const INSTANCE_TYPES = ['CockroachDB', 'DB2', 'Informix', 'MySQL', 'Oracle', 'PostgreSQL', 'SAP HANA', 'SQL Server'];
export const INSTANCE_TYPES_VALUE = ['cockroachdb', 'db2', 'informix', 'mysql', 'oracle', 'postgresql', 'saphana', 'sqlserver'];

/* Host Types */
export const HOST_TYPE_AWSRDS = 'awsrds';
export const HOST_TYPE_LINUX = 'linux';
export const HOST_TYPE_WINDOWS = 'windows';
export const HOST_TYPES = ['AWS RDS', 'Linux', 'Windows'];

/* Loading Statuses */
export const DATA_INITIALISING = 0;
export const DATA_LOADING = 1;
export const DATA_LOADED = 2;
export const DATA_FAILED = 3;
export const DATA_EMPTY = 4;

export const API_CALL_DEBOUNCE_TIME = 750;

/* Interval Constants */
export const INTERVAL_SECOND = 1000;
export const INTERVAL_5_SECONDS = 5000;
export const INTERVAL_10_SECONDS = 10000;
export const INTERVAL_15_SECONDS = 15000;
export const INTERVAL_MINUTE = 60000;
export const INTERVAL_FIVE_MINUTES = 300000;
export const INTERVAL_TEN_MINUTES = 600000;
export const INTERVAL_FIFTEEN_MINUTES = 900000;
export const INTERVAL_THIRTY_MINUTES = 1800000;
export const INTERVAL_ONE_HOUR = 3600000;
export const INTERVAL_FOUR_HOURS = 14400000;
export const INTERVAL_SIX_HOURS = 21600000;
export const INTERVAL_TWELVE_HOURS = 43200000;
export const INTERVAL_ONE_DAY = 86400000;
export const INTERVAL_ONE_WEEK = 604800000;

export const periodToValidIntervals = {
    'minute': [INTERVAL_SECOND, INTERVAL_5_SECONDS, INTERVAL_10_SECONDS, INTERVAL_15_SECONDS],
    'minutes_10': [INTERVAL_5_SECONDS, INTERVAL_10_SECONDS, INTERVAL_15_SECONDS, INTERVAL_MINUTE],
    'minutes_30': [INTERVAL_5_SECONDS, INTERVAL_10_SECONDS, INTERVAL_15_SECONDS, INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES],
    'hours_1': [INTERVAL_10_SECONDS, INTERVAL_15_SECONDS, INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES],
    'hours_2': [INTERVAL_15_SECONDS, INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES],
    'hours_8': [INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES, INTERVAL_ONE_HOUR],
    'day': [INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES, INTERVAL_ONE_HOUR],
    'week_1': [INTERVAL_ONE_HOUR, INTERVAL_SIX_HOURS],
    'weeks_5': [INTERVAL_SIX_HOURS, INTERVAL_ONE_DAY],
    'weeks_20': [INTERVAL_ONE_DAY, INTERVAL_ONE_WEEK],
    'weeks': [INTERVAL_ONE_WEEK]
};

/* Period Constants */
export const PERIOD_CUSTOM = 1;
export const PERIOD_MINUTE = 2;
export const PERIOD_FIVE_MINUTES = 3;
export const PERIOD_TEN_MINUTES = 4;
export const PERIOD_THIRTY_MINUTES = 5;
export const PERIOD_ONE_HOUR = 6;
export const PERIOD_SIX_HOURS = 7;
export const PERIOD_TWELVE_HOURS = 8;
export const PERIOD_TWENTY_FOUR_HOURS = 9;
export const PERIOD_SEVEN_DAYS = 10;
export const PERIOD_THIRTY_ONE_DAYS = 11;
export const PERIOD_TODAY = 12;
export const PERIOD_YESTERDAY = 13;
export const PERIOD_DAY_BEFORE_YESTERDAY = 14;
export const PERIOD_THIS_WEEK = 15;
export const PERIOD_PREVIOUS_WEEK = 16;

/* Report Options */
export const REPORT_PERIOD_CURRENT = 0;
export const REPORT_PERIOD_PREVIOUS = 1;
export const REPORT_PERIOD_INTERIM = 2;

/* Chart Colour Codes */
export const CHART_COLOURS_WAITS = ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'];
export const CHART_COLOURS_STATEMENTS = ['#6610f2', '#c29ffa', '#20c997', '#a6e9d5', '#0dcaf0', '#9eeaf9', '#fd7e14', '#fecba1', '#d63384', '#efadce'];
export const TARGET_COLOUR_CODES_2 = ['#069668', '#312318', '#98d5a0', '#d46052', '#cd924c', '#fb5de7', '#9a23b1', '#d595d9', '#6a6a6a', '#4b85d2', '#8e1023', '#3fe34b', '#1c0062', '#89aa0d', '#c2b6b9', '#1f5113', '#2524f9', '#f7306e', '#35c8ef', '#475de1', '#ff2a0d', '#0a2f64', '#785a0a', '#94548c']
export const TARGET_COLOUR_CODES = ['#2d4bff', '#2da2ff', '#2ee5ff', '#1effb1', '#05ff00', '#c6ff03', '#fcff03', '#fcde00', '#fcb101', '#fc5a02', '#fc2c04', '#fd0194', '#fe00d3', '#d200ff', '#9a02fc', '#4100ff', '#141999', '#1f71cc', '#25b3cc', '#22cc81', '#117a03', '#96cc09', '#c9cc0a', '#c8ae08', '#c88208', '#c63507', '#c60d08', '#c70068', '#77003f', '#960079', '#760099', '#1d0099', '#7a7fff', '#97c8ff', '#7dbbff', '#82ecff', '#7fffc7', '#7bff6a', '#d7ff6c', '#fdff6d', '#fce86c', '#fcc86c', '#fb8e6b', '#fb706c', '#fb66b4', '#fc66df', '#dc66ff', '#6d6998', '#828282', '#000000', '#2d4bff', '#2da2ff', '#2ee5ff', '#1effb1', '#05ff00', '#c6ff03', '#fcff03', '#fcde00', '#fcb101', '#fc5a02', '#fc2c04', '#fd0194', '#fe00d3', '#d200ff', '#9a02fc', '#4100ff', '#141999', '#1f71cc', '#25b3cc', '#22cc81', '#117a03', '#96cc09', '#c9cc0a', '#c8ae08', '#c88208', '#c63507', '#c60d08', '#c70068', '#77003f', '#960079', '#760099', '#1d0099', '#7a7fff', '#97c8ff', '#7dbbff', '#82ecff', '#7fffc7', '#7bff6a', '#d7ff6c', '#fdff6d', '#fce86c', '#fcc86c', '#fb8e6b', '#fb706c', '#fb66b4', '#fc66df', '#dc66ff', '#6d6998', '#828282', '#000000'];
export const OTHER_COLOUR_CODE = '#DE1173'

export const COLOUR_INSTANCE_TIME = '#69d1bf';
export const COLOUR_INSTANCE_EXECUTIONS = '#ff853d';
export const COLOUR_CHANGES = '#0090bd';
export const COLOUR_ALERTS = '#ff853d';
export const COLOUR_EVENTS = '#c29ffa';
export const COLOUR_HOST_PROCESSOR = '#dc3445';
export const COLOUR_HOST_MEMORY = '#6610f2';
export const COLOUR_HOST_IO = '#fcba03';
export const COLOUR_HOST_NETWORK = '#03bafc';

/* Toggle View Mode */
export class VIEW_MODE {
    public static readonly BASIC: number = 1;
    public static readonly DETAIL: number = 2;
}

/* Chart Data Types - Used for Custom Tooltips */
export class CHART_DATA_TYPE {
    public static readonly GENERIC: number = 1;
    public static readonly TIME: number = 2;
    public static readonly PERCENTAGE: number = 3;
}

export class FilterType {
    public static readonly Waits = 1;
    public static readonly Databases = 2;
    public static readonly Sessions = 3;
    public static readonly Clients = 4;
    public static readonly Users = 5;
    public static readonly Programs = 6;
}


/* Alert constants */

export const attributesArray = ['host', 'port', 'auth', 'user', 'host', 'password', 'from', 'to', 'enable', 'url']
export const newRelicAttributesArray = ['url', 'account', 'apikey', 'enable']
export const integrationAttributesArray = ['url', 'apikey', 'enable']
export const instanceType = ['Any', 'CockroachDB', 'DB2', 'MySQL', 'Oracle', 'PostgreSQL', 'SQL Server']
export const hostType = ['Any', 'Windows', 'Linux']

export const metricHostsType = {
    WINDOWS: 'Windows',
    LINUX: 'Linux'
}

export const metricInstanceType = ['CockroachDB', 'DB2', 'MySQL', 'Oracle', 'PostgreSQL', 'SQL Server']
export const metricInstanceTypeMapping = {
    'cockroachdb': 'CockroachDB',
    'mysql': 'MySQL',
    'postgresql': 'PostgreSQL',
    'oracle': 'Oracle',
    'db2': 'DB2',
    'sqlserver': 'SQL Server'
}

export const generalErrorMessage = {
    title: 'Form has errors',
    text: 'Please fix all errors before saving.'
}


export const batchIntervalOptions = [
    {
        name: '10 seconds',
        value: 10
    },
    {
        name: '30 seconds',
        value: 30
    },
    {
        name: '1 minute',
        value: 60
    },
    {
        name: '2 minutes',
        value: 120
    },
    {
        name: '5 minutes',
        value: 300
    },
    {
        name: '10 minutes',
        value: 600
    },
    {
        name: '20 minutes',
        value: 1200
    },
    {
        name: '1 hour',
        value: 3600
    },
]

export const instanceMetaData = ['version', 'hasblockingsessions', 'hasdatalocks', 'hasdeadlocks', 'hasinnodblockwaits', 'hasperformanceschema', 'hassqlstatistics', 'hasthreads']

export const instanceTabs = ['hasblockingsessions', 'hasdeadlocks', 'hassqlstatistics']

export const statementMenu = {
    statement: {
        textAndPlan: 'Statement and Plans',
        activity: 'Statement Activity'
    },
    groupedstatement: {
        textAndPlan: 'Statement and Plans',
        activity: 'Grouped Statement Activity'
    },
    batch: {
        textAndPlan: 'Batch and Plans',
        activity: 'Batch Activity'

    },
    groupedbatch: {
        textAndPlan: 'Batch and Plans',
        activity: 'Grouped Batch Activity'

    }
}
export const messages: string[] = ['Good things come to those who bait...',
    'The blue marlin is the largest of the Atlantic marlins',
    'Marlin are unsustainably fished, especially in the Atlantic',
    'In Ernest Hemingway’s \'The Old Man and the Sea\', a fisherman battles a blue marlin',
    'Marlin spend most of their lives far out at sea, and follow warm ocean currents',
    'Marlin are native to the tropical and temperate waters of the Atlantic, Pacific, and Indian Oceans',
    'The average marlin is 11 feet long and weighs 300 pounds',
    'A female marlin\'s average life span is 27 years, whilst a male is 18 years',
    'The blue marlin is one of the open ocean\'s fastest and strongest predators',
    'Marlin have been known to reach speeds of up to 68mph!'];

export const initialMessage: string[] = ['Trawling for data, please wait...',
    'Prepping the bait box...',
    'Setting up fishing lines and lures...'];



export const TOKEN_KEY = 'dbmar-token';
export const EXPIRATION_KEY = 'dbmar-token-expiration';
export const EXPIRATION_DAYS = 1;
export const LAST_PAGE_KEY = 'lastPage'; // Key for storing the last visited page
