// Api.
import api from '../Base';

// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import Tags from "../../types/Tags";

const InstanceApi = {

    getInstances:async () => {

        let url = `datasource?full=true`;

        let instances: InstanceTarget[] = [];

        await api.get<InstanceTarget[]>(url)
            .then((response) => {
                instances = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve instances.', error);
            })
            .then(function () {});

        return instances;
    },
    
    getInstance:(instanceId: number) => {

        let url = `datasource?full=true&id=${instanceId}`;

        api.get<InstanceTarget[]>(url)
            .then((response) => {
                return response.data[0];
            })
            .catch((error: any) => {
                console.error('Failed to retrieve instance.', error);
            })
            .then(function () {});
    },

    getAvailableMetrics:async (url: string) => {
        let metrics: any = [];

        await api.get<[]>(url)
            .then((response) => {
                metrics = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve metric names.', error);
            })
            .then(function () {});
        return metrics;
    },

    getTags:async (instanceId: number) => {
        let tagsList: Tags[] = [];
        let url = `datasource/tag `;
        await api.get<Tags[]>(url)
            .then((response) => {
              if (response?.data.length) {
                  tagsList = response.data.filter(tag => tag.datasourceid === instanceId)
              }
            })
            .catch((error: any) => {
                console.error('Failed to retrievetags.', error);
            })
        return tagsList;
    },

}

export default InstanceApi;