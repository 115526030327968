// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

// Components.

// Styles
import './generic-plan.css';

function GenericPlan(props: { executionPlan: ExecutionPlan }) {
	// Make no assumptions about the format of the plan; display the raw text.
	return (
		<p className="generic-plan">
			{props.executionPlan.sqlplan}
		</p>
	);
}

export default GenericPlan;
