import SimpleTimeChart from "../../../component/instance/chart/SimpleTimeChart";
import { useContext, useMemo, useState } from "react";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import dayjs from "dayjs";
import api from "../../../api/Base";
import api_v2 from "../../../api/Base_v2";

// Helpers
import ConditionalRender from "../../../helpers/ConditionalRender";

// Types
import Time from "../../../types/instance/Time";
import { INSTANCE_TYPE_SQLSERVER } from "../../../component/Constants";

const GenericActivity = (props: {
    instanceId: number,
    instanceType: string,
    statementId: number,
    apiString: string,
    applyPeriod: Function
}) => {
    const timeRangeContext = useContext(TimeRangeContext)

    const [statementActivity, setStatementActivity] = useState<number[]>([]);
    const [groupedStatementActivity, setGroupedStatementActivity] = useState<number[]>([]);
    const [batchActivity, setBatchActivity] = useState<number[]>([]);
    const [groupedBatchActivity, setGroupedBatchActivity] = useState<number[]>([]);

    const [timeChartLoading, setTimeChartLoading] = useState<boolean>(true);
    const [sqlChartLoading, setSqlChartLoading] = useState<boolean>(true);

    const [statisticsTimeSeries, setStatisticsTimeSeries] = useState<number[]>([]);
    const [executionsSeries, setExecutionsSeries] = useState<number[]>([]);

    const [groupedStatisticsTimeSeries, setGroupedStatisticsTimeSeries] = useState<number[]>([]);
    const [groupedExecutionsSeries, setGroupedExecutionsSeries] = useState<number[]>([]);

    const fetchStatementActivity = async(isGroupStatement: boolean, isGroupBatch: boolean) => {
        try {
            let responses;
            if (props.instanceType === INSTANCE_TYPE_SQLSERVER) {
                responses = await Promise.all([
                    !isGroupStatement && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&sqlhash=${props.statementId}`),
                    isGroupStatement && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&grouphash=${props.statementId}`),
                    !isGroupBatch && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&batchsqlhash=${props.statementId}`),
                    isGroupBatch && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&batchgrouphash=${props.statementId}`),
                ]);
            } else {
                responses = await Promise.all([
                    !isGroupStatement && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&sqlhash=${props.statementId}`),
                    isGroupStatement && api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&grouphash=${props.statementId}`)
                ]);
            }
            // @ts-ignore
            const [statementResponse, groupedStatementResponse, ...additionalResponses] = responses.length && responses.map(response => response?.data);

            if (isGroupStatement) {
                setGroupedStatementActivity(groupedStatementResponse?.map((item: Time) => [dayjs(item.timeslice).unix() * 1000, item.waittime]));
            } else {
                setStatementActivity(statementResponse?.map((item: Time) => [dayjs(item.timeslice).unix() * 1000, item.waittime]));
            }

            if (props.instanceType === INSTANCE_TYPE_SQLSERVER) {
                const [batchResponse, groupedBatchResponse] = additionalResponses;
                if (isGroupBatch) {
                    setGroupedBatchActivity(groupedBatchResponse?.map((item: Time) => [dayjs(item.timeslice).unix() * 1000, item.waittime]));
                } else {
                    setBatchActivity(batchResponse?.map((item: Time) => [dayjs(item.timeslice).unix() * 1000, item.waittime]));
                }
            }

            setTimeChartLoading(false);
        } catch (error) {
            setTimeChartLoading(false);
            console.error('Failed to retrieve activity time.', error);
        }
    };

    const fetchSqlStatistics = async(isGroupStatement: boolean) => {
        try {
            const statisticsResponses = await Promise.all([
                !isGroupStatement && api_v2.get(`sql/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&type=${props.instanceType}&sqlhash=${props.statementId}`),
                isGroupStatement && api_v2.get(`sql/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&type=${props.instanceType}&grouphash=${props.statementId}`)
            ]);

            // @ts-ignore
            const [statisticsResponse, groupedStatisticResponse] = statisticsResponses.map(response => response?.data);

            if (isGroupStatement) {
                setGroupedStatisticsTimeSeries(groupedStatisticResponse?.map((item: any) => [dayjs(item.timeslice).unix() * 1000, item.duration]));
                setGroupedExecutionsSeries(groupedStatisticResponse?.map((item: any) => [dayjs(item.timeslice).unix() * 1000, item.executions]));
            } else {
                setStatisticsTimeSeries(statisticsResponse?.map((item: any) => [dayjs(item.timeslice).unix() * 1000, item.duration]));
                setExecutionsSeries(statisticsResponse?.map((item: any) => [dayjs(item.timeslice).unix() * 1000, item.executions]));
            }

            setSqlChartLoading(false);
        } catch (error) {
            setSqlChartLoading(false);
            console.error('Failed to retrieve SQL Statistics.', error);
        }
    };

    const fetchData = async() => {
        try {
            const statementActivityResponse = await api.get(`activity/sql?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&grouphash=${props.statementId}`);
            // check if it ahs more the one statement ar is a grout with only one statement
            let isGroupStatement = statementActivityResponse?.data?.length > 1 || (statementActivityResponse?.data.length === 1 && statementActivityResponse?.data[0].sqlhash !== props.statementId);
            let isGroupBatch = false

            if (props.instanceType === INSTANCE_TYPE_SQLSERVER) {
                const batchActivityResponse = await api.get(`activity/batch?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&batchgrouphash=${props.statementId}`);
                isGroupBatch = batchActivityResponse?.data?.length > 1 || (batchActivityResponse?.data.length === 1 && batchActivityResponse?.data[0].batchsqlhash !== props.statementId);

                if (!batchActivityResponse?.data?.length) {
                    isGroupBatch = false
                }
            }


            if (!statementActivityResponse?.data?.length) {
                isGroupStatement = false
            }
            
            // Fetch statement activity
            void fetchStatementActivity(isGroupStatement, isGroupBatch);

            // Fetch SQL statistics
            void fetchSqlStatistics(isGroupStatement);

        } catch (error) {
            setTimeChartLoading(false);
            setSqlChartLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useMemo(() => {
        void fetchData();
    }, [timeRangeContext, props.instanceId, props.statementId, props.instanceType]);


    const noData = () => {
        return !timeChartLoading && !sqlChartLoading && !statisticsTimeSeries.length && !groupedStatisticsTimeSeries.length && !statementActivity.length && !groupedStatementActivity.length && !batchActivity.length && !groupedBatchActivity.length
    }

    return <div>
        <ConditionalRender if={noData()}>
            <div className="w-100 text-center text-muted my-3">
                <i className="fal fa-ban fa-fw fa-2x"></i>
                <p className="mt-3">
                    This SQL text has no performance data within the current period. Try expanding your time range
                </p>
            </div>
        </ConditionalRender>


        <ConditionalRender if={timeChartLoading}>
            <div className="w-100 text-center chart-center text-muted mt-3">
                <div className="loader spinner chartSpinner">
                </div>
                <p className="mt-3">
                    Loading data...
                </p>
            </div>
        </ConditionalRender>

        <ConditionalRender if={!timeChartLoading && !noData()}>
            <h3>Database Activity</h3>
            <div className='d-flex flex-wrap'>
                <ConditionalRender if={statementActivity.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     statementId={props.statementId}
                                     applyPeriod={props.applyPeriod}
                                     type='Statement'
                                     timeSeries={statementActivity}
                    />
                </ConditionalRender>

                <ConditionalRender if={groupedStatementActivity.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     statementId={props.statementId}
                                     applyPeriod={props.applyPeriod}
                                     type='Grouped Statement'
                                     timeSeries={groupedStatementActivity}
                    />
                </ConditionalRender>

                <ConditionalRender if={batchActivity.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     statementId={props.statementId}
                                     applyPeriod={props.applyPeriod}
                                     type='Batch'
                                     timeSeries={batchActivity}
                    />
                </ConditionalRender>

                <ConditionalRender if={groupedBatchActivity.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     applyPeriod={props.applyPeriod}
                                     statementId={props.statementId}
                                     type='Grouped Batch'
                                     timeSeries={groupedBatchActivity}
                    />
                </ConditionalRender>
                <ConditionalRender
                    if={!statementActivity.length && !groupedStatementActivity.length && !batchActivity.length && !groupedBatchActivity.length}>
                    <div className="w-100 text-center text-muted my-3">
                        <i className="fal fa-ban fa-fw fa-2x"></i>
                        <p className="mt-3">
                            No data found
                        </p>
                    </div>
                </ConditionalRender>
            </div>
        </ConditionalRender>

        <ConditionalRender if={sqlChartLoading}>
            <div className="w-100 text-center chart-center text-muted mt-3">
                <div className="loader spinner chartSpinner">
                </div>
                <p className="mt-3">
                    Loading data...
                </p>
            </div>
        </ConditionalRender>

        <ConditionalRender if={!sqlChartLoading && !noData()}>
            <h3>SQL Statistics</h3>
            <div className='d-flex flex-wrap'>
                <ConditionalRender if={statisticsTimeSeries.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     statementId={props.statementId}
                                     timeSeries={statisticsTimeSeries}
                                     applyPeriod={props.applyPeriod}
                                     type='Statement'
                                     executionsSeries={executionsSeries}
                    />
                </ConditionalRender>

                <ConditionalRender if={groupedStatisticsTimeSeries.length}>
                    <SimpleTimeChart instanceId={props.instanceId}
                                     applyPeriod={props.applyPeriod}
                                     statementId={props.statementId}
                                     type='Grouped Statement'
                                     timeSeries={groupedStatisticsTimeSeries}
                                     executionsSeries={groupedExecutionsSeries}
                    />
                </ConditionalRender>
                <ConditionalRender if={!statisticsTimeSeries.length && !groupedStatisticsTimeSeries.length}>
                    <div className="w-100 text-center text-muted my-3">
                        <i className="fal fa-ban fa-fw fa-2x"></i>
                        <p className="mt-3">
                            No data found
                        </p>
                    </div>
                </ConditionalRender>
            </div>
        </ConditionalRender>
    </div>
}
export default GenericActivity;
