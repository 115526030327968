import Helmet from "react-helmet";
import './style.scss';
import { useHistory } from "react-router-dom";

function NotFound() {
    const history = useHistory();

    return (
        <div id="content">
            <Helmet>
                <title>Page Not Found</title>
                <meta name="description" content="Page Not Found" />
            </Helmet>
            {/* Heading */}
        <div className='not-found'>
            <h2>Page Not Found</h2>
            <p>or Optional Feature has not been Enabled</p>
            <span onClick={() => history.goBack()}>Go Back</span>
        </div>

        </div>
    );
}


export default NotFound;
