import React from "react";
import PropTypes from 'prop-types';

const ConditionalRender = (props: any) => {
    if (!!props.if) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
};

ConditionalRender.propTypes = {
    if: PropTypes.any,
    children: PropTypes.any,
};

export default ConditionalRender;
