import { Component } from 'react';
import PropTypes from "prop-types";

// Third-party packages.
import Helmet from "react-helmet";

import { Link, Route, Switch } from "react-router-dom";

// Components.
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";
import Detail from './Detail';
import List from './List';

// Types.
import AlertActions from "../../../types/Alerts/AlertActions";
import Period from "../../../types/Period";

// Constants.
import { ACTIONS, DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from "../../../component/Constants";
import AlertsApis from "../../../api/instance/AlertsApis";


interface IAlertActionsProps {
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function
}

interface IAlertActionsState {
    loading: number,
    alertActions: AlertActions[]
}

const initialState = {
    loading: DATA_INITIALISING,
    alertActions: []
}

class Actions extends Component<IAlertActionsProps, IAlertActionsState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IAlertActionsProps) {
        super(props);

        this.state = initialState;
    };

    async getData() {
        // Get all events for all time.
        const alertActions = await AlertsApis.getAlertActions();

        this.setState({
            loading: DATA_LOADED,
            alertActions
        });

    }

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    /* Action Methods */
    refresh = () => {

        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, alertActions } = this.state;
        return (

            <div id="content">
                <Helmet>
                    <title>Alert Actions - Settings - DBmarlin</title>
                    <meta name="description" content="Manage custom alert actions"/>
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-2">
                    <Breadcrumb heading="Alert Actions">
                        <Link to="/admin">Settings</Link>
                        <Link to="/admin/alert-actions">Alert Actions</Link>
                        <Switch>
                            <Route exact path="/admin/alert-actions/create" render={() => <span>Create</span>}/>
                            <Route exact path="/admin/alert-actions/:actionId/:id" render={(props) =>
                                <span>{props.match.params.actionId.charAt(0).toUpperCase() + props.match.params.actionId.slice(1)}</span>}/>
                            <Route exact path="/admin/alert-actions/" render={() => <span>List</span>}/>
                        </Switch>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}
                                   applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"/>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/alert-actions/create" render={(props) => <Detail {...props} alertActions={alertActions} actionId={ACTIONS.CREATE.type} refresh={this.refresh}/>}/>
                        <Route exact path="/admin/alert-actions/:actionId/:id" render={(props) => <Detail {...props} alertActions={alertActions} actionId={props.match.params.actionId} alertActionId={props.match.params.id} refresh={this.refresh}/>}/>
                        <Route exact path="/admin/alert-actions/" render={(props) => <List {...props} alertActions={alertActions} loading={loading} refresh={this.refresh}/>}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

Actions.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Actions;
