import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Api.
import api from '../../../api/Base';
import InstanceApi from '../../../api/instance/InstanceApi';

// Helper.
import Helper from '../../../helpers/Helper';

// Context.
import { HostContext } from '../../../context/HostContext';
import { InstanceContext } from '../../../context/InstanceContext';
import { EventTypeContext } from '../../../context/EventTypeContext';

// Types.
import Action from '../../../types/Action';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import InstanceTargetHost from '../../../types/instance/InstanceTargetHost';

// Constants.
import { ACTIONS, batchIntervalOptions } from '../../../component/Constants';
import { INSTANCE_TYPES, INSTANCE_TYPES_VALUE } from '../../../component/Constants';
import {
    STATISTICS_DISABLED_INSTANCE_TYPES
} from "../../../container/statistics/constants";

// Components.
import Alert from '../../../component/Alert';
import ThirdPartyLinks from "../../../component/instance/ThirdPartyLinks";
import ThirdPartyLinksType from "../../../types/ThirsPartyLinks";
import ConditionalRender from "../../../helpers/ConditionalRender";
import Tagging from "../../../component/instance/Tagging/Tagging";
import Tags from "../../../types/Tags";
import ReactTooltip from "react-tooltip";

function Detail(props: { actionId: string, instanceId?: number }) {
    const history = useHistory();
    const {instances, setInstances} = useContext(InstanceContext);
    const {hosts} = useContext(HostContext);
    const {eventTypes} = useContext(EventTypeContext);

    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(props.actionId === ACTIONS.DELETE.type);
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState<string>('');
    const [nameUsed, setNameUsed] = useState<boolean>(false);
    const [nameClass, setNameClass] = useState<string>('');
    const [host, setHost] = useState<string>('');
    const [hostUsed, setHostUsed] = useState<boolean>(false);
    const [hostClass, setHostClass] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [typeClass, setTypeClass] = useState<string>('');
    const [agentId, setAgentId] = useState<number>(1);
    const [agentIdClass, setAgentIdClass] = useState<string>('');
    const [database, setDatabase] = useState<string>('');
    const [server, setServer] = useState<string>('');
    const [debug, setDebug] = useState<boolean>(false);
    const [port, setPort] = useState<number>(0);
    const [portClass, setPortClass] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [usernameClass] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordClass] = useState<string>('');
    const [passwordElementType, setPasswordElementType] = useState<string>('password');
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [advanced, setAdvanced] = useState<boolean>(false);
    const [driver, setDriver] = useState<string | undefined>(undefined);
    const [url, setUrl] = useState<string>('');
    const [urlClass, setUrlClass] = useState<string>('');
    const [filter, setFilter] = useState<string>('');
    const [mappedHosts, setMappedHosts] = useState<number[]>([]);
    const [parameters, setParameters] = useState<string | undefined>(undefined);
    const [monitoringInterval, setMonitoringInterval] = useState<number>(1000);
    const [batchInterval, setBatchInterval] = useState<number>(60);
    const [detectChanges, setDetectChanges] = useState<boolean>(true);
    const [collectSqlStatistics, setCollectSqlStatistics] = useState<boolean>(true);
    const [collectDeadlocks, setCollectDeadlocks] = useState<boolean>(false);
    const [collectBlockingSessions, setCollectBlockingSessions] = useState<boolean>(false);
    const [agents, setAgents] = useState<any[]>([]);
    const [monitor, setMonitor] = useState<boolean>(false);
    const [initialLinks, setInitialLinks] = useState<any[]>([]);
    const [links, setLinks] = useState<ThirdPartyLinksType[]>([])
    const [initialTags, setInitialTags] = useState<any[]>([]);
    const [tags, setTags] = useState<Tags[]>([])
    const [allTags, setAllTags] = useState<Tags[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [type, advanced]);

    useEffect(() => {
        const getInstance = (instanceId: number) => {
            const instance = instances.filter((instance: InstanceTarget) => instance.id === Number(instanceId));

            if (instance.length === 1) {
                setId(instance[0].id);
                setName(instance[0].name);
                setHost(instance[0].host);
                setType(instance[0].type);
                setAgentId(instance[0].agentid);
                setDatabase(instance[0].database);
                setServer(instance[0].server);
                setMonitor(instance[0].monitor);
                setDebug(instance[0].debug);
                setPort(instance[0].port);
                setUsername(instance[0].username);
                setPassword(((instance[0].password === null) ? '' : instance[0].password));
                setDriver(instance[0].driver);
                setUrl(instance[0].url);
                setAdvanced(instance[0].useurl);
                setParameters(instance[0].parameters);
                setMonitoringInterval(instance[0].monitoringintervalmilliseconds);
                setBatchInterval(instance[0].batchintervalseconds || 60);
                setDetectChanges(instance[0].detectchanges);
                setCollectSqlStatistics(instance[0].collectsqlstatistics);
                setCollectDeadlocks(instance[0].collectdeadlocks || false);

                // temporary remove Collect Blocking Sessions for cockroachdb
                setCollectBlockingSessions(instance[0].type === 'cockroachdb' ? false : instance[0].collectblockingsessions || false);
            } else {
                setIsMissing(true);
            }
        }
        const getMappedHosts = async(instanceId: number) => {

            api.get(`datasource/host?id=${instanceId}`)
                .then((response: { data: InstanceTargetHost[]; }) => {

                    let mappedInstances: number[] = [];

                    for (let index = 0; index < response.data.length; index++) {

                        // Get the instance ID.
                        mappedInstances.push(response.data[index].hostid);
                    }

                    setMappedHosts(mappedInstances);

                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.error('Failed to retrieve mapped instances.', error);
                })
                .then(function () {
                    // Todo: Always executed - do we need this?
                });
        };

        const getAction = () => {

            switch (props.actionId) {
                case 'create':

                    setAction(ACTIONS.CREATE);

                    break;

                case 'edit':

                    setAction(ACTIONS.UPDATE);

                    break;

                case 'delete':

                    setAction(ACTIONS.DELETE);
                    setDisabled(true);

                    break;

                default:

                    console.error('Unknown action requested', props.actionId);

                    setIsError(true);

                    break;
            }
        }

        const getAgents = async() => {
            const response = await api.get(`agent `) || []
            if (response) {

                setAgents(response.data)
            }
        }

        const get3rdPartyLinks = async() => {
            const response: any = await api.get(`datasource/link?datasourceid=${props.instanceId}`)
            if (response.data) {
                const currentInstanceLinks = response.data.filter((item: any) => item.datasourceid === props.instanceId)
                setInitialLinks(JSON.parse(JSON.stringify(currentInstanceLinks)));
                setLinks(JSON.parse(JSON.stringify(currentInstanceLinks)));
            }
        }

        const getInstanceTags = async() => {
            const response: any = await api.get(`datasource/tag`)
            if (response.data) {
                setAllTags(response.data)
                const currentInstanceTags = response.data.filter((item: any) => item.datasourceid === props.instanceId)
                setInitialTags(JSON.parse(JSON.stringify(currentInstanceTags)));
                setTags(JSON.parse(JSON.stringify(currentInstanceTags)));
            }
        }

        getAction();

        void getAgents();

        if (props.instanceId) {
            getInstance(props.instanceId);
            getMappedHosts(props.instanceId);
            void get3rdPartyLinks()
        }
        void getInstanceTags()

    }, [instances, props.actionId, props.instanceId]);

    function handleNameBlur() {

        // Trim any white space.
        let value = name.trim();

        // Save to state.
        setName(value);

        if (value === '') {

            // Invalid, set the class.
            setNameClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setNameClass('is-valid');
        }

        // Check for duplicate names.
        const index = instances.findIndex(object => object.id !== id && object.name.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setNameClass('is-valid');
            setNameUsed(false);
        } else {

            // Duplicate found.
            setNameClass('is-invalid');
            setNameUsed(true);
        }
    }

    function handleHostBlur() {

        // Trim any white space.
        let value = host.trim();

        // Save to state.
        setHost(value);

        if (value === '') {

            // Invalid, set the class.
            setHostClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setHostClass('is-valid');
        }

        // Check for duplicate names.
        const index = instances.findIndex(object => object.host.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setHostClass('is-valid');
            setHostUsed(false);
        } else {

            // Duplicate found.
            setHostClass('is-valid');
            setHostUsed(false);
        }
    }

    function handlePortBlur() {

        // Validate the entered port value.
        const regexp = /^[0-9\b]+$/;

        if ((port.toString().length > 0 || regexp.test(port.toString())) && (port.toString().length < 6) && (port <= 65535)) {
            setPortClass('is-valid');
        } else {
            setPortClass('is-invalid');
        }
    }

    function handleHostMapping(hostId: number) {

        let updatedMappedHosts = [...mappedHosts];

        // Look for a match.
        let match = updatedMappedHosts.filter(id => {
            return id === hostId;
        });

        if (match.length === 0) {
            // Add the association.
            updatedMappedHosts.push(hostId);
        } else {
            // Remove the association.
            updatedMappedHosts = [...updatedMappedHosts.filter(id => id !== hostId)];
        }

        setMappedHosts([...updatedMappedHosts]);
    }

    function clearFilter() {

        // Clear any pre-existing filter values.
        setFilter('');
    }

    function hasStatisticsCollection(): boolean {
        // @ts-ignore
        return (!STATISTICS_DISABLED_INSTANCE_TYPES.includes(type));
    }

    function requireDatabase(): boolean {
        return type === 'cockroachdb' || type === 'db2' || type === 'informix'
			|| type === 'oracle' || type === 'postgresql';
    }

    function requireServer(): boolean {
        return type === 'informix';
    }

    function canViewPassword(): boolean {
        return action === ACTIONS.CREATE ||
            (action === ACTIONS.UPDATE && passwordChanged);
    }

    function changePassword(e: ChangeEvent<HTMLInputElement>): void {
        if (!passwordChanged) {
            // Just want the character entered.
            setPassword((e.nativeEvent as any).data || '');
            setPasswordChanged(true);
        } else {
            setPassword(e.target.value);
        }
    }

    async function handleActionClick() {
        setLoading(true)
        // Validate the form.
        let valid = true;

        if (name.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setNameClass('is-invalid');
        } else {

            // Valid.
            setNameClass('is-valid');
        }

        if (host.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setHostClass('is-invalid');
        } else {

            // Valid.
            setHostClass('is-valid');
        }

        if (type.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setTypeClass('is-invalid');
        } else {

            // Valid.
            setTypeClass('is-valid');
        }

        if (agentId === 0) {

            valid = false;

            // Invalid, set the class.
            setAgentIdClass('is-invalid');
        } else {

            // Valid.
            setAgentIdClass('is-valid');
        }

        if (advanced) {
            if (url.trim() === '') {

                valid = false;

                // Invalid, set the class.
                setUrlClass('is-invalid');
            }
        }

        links.forEach((link: ThirdPartyLinksType, index: number) => {
            const updatedLinks = [...links];
            if (!link.name.trim()) {
                updatedLinks[index].validName = false;
                valid = false;
            }
            if (!link.url.trim()) {
                updatedLinks[index].validUrl = false;
                valid = false;
            }
            setLinks(updatedLinks)
        })

        tags.forEach((tag: Tags, index: number) => {
            const updatedTags = [...tags];
            if (!tag.tagname.trim()) {
                updatedTags[index].validName = false;
                valid = false;
            }
            if (!tag.tagvalue.trim()) {
                updatedTags[index].validValue = false;
                valid = false;
            }
            setTags(updatedTags)
        })

        // check if there are any duplicate tags
        if (Helper.checkForTwoWithSameKeyValue('tagname', tags)) {
            valid = false
        }

        setIsValid(valid);

        if (!valid) {
            setLoading(false)
            return;
        }

        // Build the event type object.
        let data = [{
            id,
            name,
            agentid: agentId,
            type,
            database,
            server,
            debug,
            host,
            port: Number(port),
            username,
            password,
            passwordchanged: passwordChanged,
            driver,
            monitor,
            url,
            useurl: advanced,
            parameters,
            monitoringintervalmilliseconds: monitoringInterval,
            batchintervalseconds: batchInterval,
            detectchanges: detectChanges,
            collectsqlstatistics: hasStatisticsCollection() && collectSqlStatistics,
            collectdeadlocks: (type === 'oracle' || type === 'sqlserver') ? collectDeadlocks : false,
            collectblockingsessions: collectBlockingSessions
        }];

        let result: any = undefined;

        const thirdPartiesArray = links.map(obj => {
            const {validName, validUrl, id, ...rest} = obj;
            return rest;
        });

        const tagsArray = tags.map(obj => {
            const {validName, validValue, id, ...rest} = obj;
            return rest;
        });

        switch (action) {
            case ACTIONS.CREATE:
                // Save the object.
                result = await api.post('datasource', data);

                if (result.status === 200) {

                    // The API doesn't return the created ID value, so we need to make an additional API request to get the created instance.
                    let updatedInstances = await InstanceApi.getInstances();

                    updatedInstances = updatedInstances.filter((instance: InstanceTarget) => instance.name === name);

                    // Get the created ID.
                    setId(updatedInstances[0].id);

                    thirdPartiesArray.forEach(link => {
                        link.datasourceid = updatedInstances[0].id
                    })
                    // add 3rd Party links for this instance
                    await api.post(`datasource/link?datasourceid=${updatedInstances[0].id}`, thirdPartiesArray)

                    tagsArray.forEach(tag => {
                        tag.datasourceid = updatedInstances[0].id
                    })
                    // add tags for this instance
                    await api.post(`datasource/tag?datasourceid=${updatedInstances[0].id}`, tagsArray)


                    // Create any instance mappings.
                    let instanceTargetHosts: InstanceTargetHost[] = [];

                    for (let index = 0; index < mappedHosts.length; index++) {

                        // Build object array of mapped instances for this host.
                        instanceTargetHosts.push({
                            id: updatedInstances[0].id,
                            hostid: mappedHosts[index]
                        });
                    }

                    // Recreate the host and instance associations.
                    result = await api.post('datasource/host', instanceTargetHosts);

                    if (result && result.status !== 200) {
                        // Failed to complete the API request.
                        console.error('Failed to complete requested action', action, result, data);

                        // Failed to complete request.
                        setIsError(true);

                        // An error occurred, so exit this function.
                        return;
                    }
                    // Create event.
                    Helper.createInstanceEvent(eventTypes, updatedInstances[0].id, `${action.title}d Instance`, `${action.title}d monitored instance in DBmarlin`);
                }

                break;
            case ACTIONS.UPDATE:

                // Update the object.
                result = await api.put('datasource', data);

                // Do not make any api call if there is no change
                if (JSON.stringify(initialLinks) !== JSON.stringify(links)) {
                    if (initialLinks.length) {
                        // delete all 3rd Party links for this instance if there are any saved already
                        const arrayOfIds = initialLinks.map((obj: { id: any; }) => ({id: obj.id}));
                        await api.delete(`datasource/link`, {data: arrayOfIds});
                    }

                    // add 3rd Party links for this instance
                    await api.post(`datasource/link`, thirdPartiesArray)
                }

                // Do not make any api call if there is no change
                if (JSON.stringify(initialTags) !== JSON.stringify(tags)) {
                    if (initialTags.length) {
                        // delete all tags for this instance if there are any saved already
                        const arrayOfIds = initialTags.map((obj: { id: any; }) => ({id: obj.id}));
                        await api.delete(`datasource/tag`, {data: arrayOfIds});
                    }

                    // add tags for this instance
                    await api.post(`datasource/tag`, tagsArray)
                }

                // Delete all instance associations.
                result = await api.delete('datasource/host', {data: [{'id': id}]});

                let instanceTargetHosts: InstanceTargetHost[] = [];

                for (let index = 0; index < mappedHosts.length; index++) {

                    // Build object array of mapped instances for this host.
                    instanceTargetHosts.push({
                        id,
                        hostid: mappedHosts[index]
                    });
                }

                // Recreate the host and instance associations.
                result = await api.post('datasource/host', instanceTargetHosts);

                if (result && result.status !== 200) {
                    // Failed to complete the API request.
                    console.error('Failed to complete requested action', action, result, data);

                    // Failed to complete request.
                    setIsError(true);

                    // An error occurred, so exit this function.
                    return;
                }
                // Create event.
                Helper.createInstanceEvent(eventTypes, id, `${action.title}d Instance`, `${action.title}d monitored instance in DBmarlin`);

                break;
            case ACTIONS.DELETE:

                // Delete all host associations.
                result = await api.delete('datasource/host', {data: [{'id': id}]});

                if (initialLinks.length) {
                    // delete all 3rd Party links for this instance if there are any saved already
                    await api.delete(`datasource/link`, {data: initialLinks});
                }

                if (initialTags.length) {
                    // delete all tag for this instance if there are any saved already
                    await api.delete(`datasource/tag`, {data: initialTags});
                }

                if (result.status !== 200) {

                    // Failed to complete the API request.
                    console.error('Failed to complete requested action', action, result, data);

                    // Failed to complete request.
                    setIsError(true);

                    // An error occurred, so exit this function.
                    return;
                }

                // Delete the object.
                result = await api.delete('datasource', {data});
                if (result && result.status !== 202) {
                    // Failed to complete the API request.
                    console.error('Failed to complete requested action', action, result, data);

                    // Failed to complete request.
                    setIsError(true);

                    // An error occurred, so exit this function.
                    return;
                }
                break;
            default:

                // Unknown action requested.
                console.error('Unknown action requested', action);

                setIsError(true);
                setLoading(false)

                break;
        }


        // Get the latest instances and update the context object.
        const updatedInstances = await InstanceApi.getInstances();

        setInstances(updatedInstances);
        // Redirect the user back to the last view.
        history.goBack()
        // history.push('/admin/instances');
    }

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-database fa-fw" aria-hidden="true"></i>
                    {action.title} instance
                </div>
                <div className="card-body">
                    {isMissing ? (
                        <React.Fragment>
                            <Alert message="The requested instance is either invalid or no longer available."
                                   heading="Invalid request" variant="alert-danger"/>
                            <div className="row g-0 form">
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <Link to="/admin/instances/" role="button" className="btn btn-secondary"><i
                                        className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            {action.type === 'delete' && (
                                <Alert
                                    message="Please check and ensure you want to delete this instance.  Any associated media will remain, and will need to be deleted separately."
                                    heading="Deletes are final and cannot be undone" variant="alert-warning"/>
                            )}

                            {isError && (
                                <Alert
                                    message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request."
                                    heading={`Failed to ${action.title.toLowerCase()} host`} variant="alert-danger"/>
                            )}

                            {!isValid && (
                                <Alert message="Please complete all the required fields below."
                                       heading="Missing required fields" variant="alert-danger"/>
                            )}

                            <p>Please complete the required details below:</p>

                            <div className="row g-0 form">

                                {/* Title */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="name" className="reminder"
                                                                      data-tip="The instance name">Name</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="name"
                                        autoFocus
                                        className={`form-control ${nameClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleNameBlur}
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {nameUsed && (
                                        <small className="text-danger">Instance name already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>

                                {/* Host Address */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="host" className="reminder"
                                                                      data-tip="The host address">Host Address</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="host"
                                        className={`form-control ${hostClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleHostBlur}
                                        onChange={e => setHost(e.target.value)}
                                        value={host}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {hostUsed && (
                                        <small className="text-danger">Host address already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>

                                {/* Instance Type */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="type" className="reminder"
                                                                      data-tip="Select the instance type">Instance Type</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <select
                                        id="type"
                                        value={type}
                                        onChange={e => setType(e.target.value)}
                                        className={`form-select ${typeClass}`}
                                        disabled={disabled}>
                                        <option value="">Select Instance Type</option>
                                        {INSTANCE_TYPES.map((item, index) => (
                                            <option key={index} value={INSTANCE_TYPES_VALUE[index]}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-100"></div>

                                {/* Port */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="port" className="reminder"
                                                                      data-tip="Set the connection port number">Port</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="port"
                                        className={`form-control ${portClass}`}
                                        required
                                        maxLength={5}
                                        onBlur={handlePortBlur}
                                        onChange={e => setPort(Number(e.target.value))}
                                        value={port}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    <small className="text-secondary">Port number must be 65,535 or lower.</small>
                                </div>
                                <div className="w-100"></div>

                                {/* Database Name */}
                                {requireDatabase() && (
                                    <React.Fragment>
                                        <div className="col-xs-12 col-md-3">
                                            <label htmlFor="database" className="reminder"
                                                   data-tip="Set the connection database">
                                                {type === 'oracle' ? (
                                                    `SID or Service Name`
                                                ) : (
                                                    `Database`
                                                )}
                                            </label>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <input
                                                type="text"
                                                id="database"
                                                className={`form-control`}
                                                required
                                                maxLength={250}
                                                onChange={e => setDatabase(e.target.value)}
                                                value={database}
                                                disabled={disabled}
                                                data-lpignore={true}
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                    </React.Fragment>
                                )}

                                {/* Server Name */}
                                {requireServer() && (
                                    <React.Fragment>
                                        <div className="col-xs-12 col-md-3">
                                            <label htmlFor="server" className="reminder"
                                                   data-tip="Set the connection server">Server</label>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <input
                                                type="text"
                                                id="server"
                                                className={`form-control`}
                                                required
                                                maxLength={250}
                                                onChange={e => setServer(e.target.value)}
                                                value={server}
                                                disabled={disabled}
                                                data-lpignore={true}
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                    </React.Fragment>
                                )}

                                {/* Connection Username */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="username" className="reminder"
                                           data-tip="Set the connection username">Username</label>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="username"
                                        className={`form-control ${usernameClass}`}
                                        required
                                        maxLength={250}
                                        onChange={e => setUsername(e.target.value)}
                                        value={username}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"/>

                                {/* Connection Password */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="password" className="reminder"
                                           data-tip="Set the connection password">Password</label>
                                    {canViewPassword() && (
                                        <i className="fas fa-eye fa-fw fa-xs ms-1" role="button"
                                           onClick={() => ((passwordElementType === 'text') ? setPasswordElementType('password') : setPasswordElementType('text'))}/>
                                    )}
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type={passwordElementType}
                                        id="password"
                                        className={`form-control ${passwordClass}`}
                                        maxLength={250}
                                        onChange={e => changePassword(e)}
                                        value={password}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"/>

                                {/* Advanced Mode */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="advanced" className="reminder" data-tip="Enable advanced mode">Advanced
                                        Mode</label>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="advanced"
                                               checked={advanced}
                                               onChange={() => ((advanced === true) ? setAdvanced(false) : setAdvanced(true))}
                                               disabled={disabled}/>
                                    </div>
                                </div>
                                <div className="w-100"></div>

                                {advanced && (
                                    <div className="row g-0 form advanced-mod">
                                        {/* Connection String */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                           <span className="required"><label htmlFor="url" className="reminder"
                                                                             data-tip="Set the instance connection string">Connection String</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <textarea id="url" className={`form-control ${urlClass}`} defaultValue={url}
                                                      onBlur={e => setUrl(e.target.value)}/>
                                        </div>
                                        <div className="w-100"></div>

                                        {/* Connection Parameters */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                            <span><label htmlFor="parameters" className="reminder"
                                                         data-tip="Set the instance parameters">Connection Parameters</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <textarea id="parameters" className={`form-control`}
                                                      defaultValue={parameters}
                                                      onBlur={e => setParameters(e.target.value)}/>
                                        </div>
                                        <div className="w-100"></div>

                                        {/* Monitoring Interval */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                            <span className="required"><label htmlFor="monitoring-interval"
                                                                              className="reminder"
                                                                              data-tip="Set the monitoring interval">Monitoring Interval (milliseconds)</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <select id="monitoring-interval" className={`form-control`} required
                                                    onChange={e => setMonitoringInterval(Number(e.target.value))}
                                                    value={monitoringInterval} disabled={disabled}>
                                                {[100, 200, 500, 1000, 2000, 5000, 10000, 20000, 30000, 60000].map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-100"></div>

                                        {/* Batch Interval */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                            <span className="required"><label htmlFor="batch-interval"
                                                                              className="reminder"
                                                                              data-tip="Set the batch interval">Batch Interval</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <select id="batch-interval" className={`form-control`} required
                                                    onChange={e => setBatchInterval(Number(e.target.value))}
                                                    value={batchInterval}
                                                    disabled={disabled}>
                                                {batchIntervalOptions.map((item, index) => (
                                                    <option key={index} value={item.value}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-100"></div>


                                        {/* Collect SQL Statistics */}
                                        {hasStatisticsCollection() && (<>
                                            <div className="col-xs-12 col-md-3 px-4">
                                                <label htmlFor="collect-sql-stats" className="reminder">Collect SQL
                                                    Statistics</label>
                                            </div>
                                            <div className="col-xs-12 col-md-6 col-lg-3">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                           id="collect-sql-stats"
                                                           checked={collectSqlStatistics}
                                                           onChange={() => setCollectSqlStatistics(!collectSqlStatistics)}
                                                           disabled={disabled}/>
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            {collectSqlStatistics !== true && (
                                                <React.Fragment>
                                                    <Alert
                                                        message="SQL Statistics are important to the functioning of DBmarlin, and switching off may result in some loss of functionality. We therefore recommend collecting SQL Statistics."
                                                        heading="Collect SQL Statistics disabled"
                                                        variant="alert-warning"/>
                                                </React.Fragment>
                                            )}
                                        </>)}


                                        {/*temporary remove Collect Blocking Sessions for cockroachdb*/}
                                        <ConditionalRender if={type !== 'cockroachdb'}>
                                            {/* Collect Blocking Sessions*/}
                                            <div className="col-xs-12 col-md-3 px-4">
                                                <label htmlFor="collect-blocking-sessions" className="reminder">Collect
                                                    Blocking Sessions</label>
                                            </div>
                                            <div className="col-xs-12 col-md-6 col-lg-3 d-flex">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                           id="collect-blocking-sessions"
                                                           checked={type === 'cockroachdb' ? false : collectBlockingSessions}
                                                           onChange={() => setCollectBlockingSessions(!collectBlockingSessions)}
                                                           disabled={type === 'cockroachdb' ? true : disabled}/>
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <ConditionalRender if={type !== 'oracle' && type !== 'sqlserver' && collectBlockingSessions}>
                                                <React.Fragment>
                                                    <Alert
                                                        message="Collecting Blocking Sessions will involve some additional overhead. We therefore suggest that you enable this feature when you need to investigate a problem, but do not leave switched on all the time"
                                                        heading="Collect Blocking Sessions enabled"
                                                        variant="alert-warning"/>
                                                </React.Fragment>
                                            </ConditionalRender>
                                        </ConditionalRender>

                                        {/* Collect Deadlocks*/}
                                        {(type === 'oracle' || type === 'sqlserver') && (<>
                                            <div className="col-xs-12 col-md-3 px-4">
                                                <label htmlFor="collect-deadlocks" className="reminder">Collect
                                                    Deadlocks</label>
                                            </div>


                                            <div className="col-xs-12 col-md-6 col-lg-3 d-flex">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                           id="collect-deadlocks"
                                                           checked={collectDeadlocks}
                                                           onChange={() => setCollectDeadlocks(!collectDeadlocks)}
                                                           disabled={disabled}/>
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            {collectDeadlocks === true && (
                                                <React.Fragment>
                                                    <Alert
                                                         message="Collecting Deadlock will involve some additional overhead. We therefore suggest that you enable this feature when you need to investigate a problem, but do not leave switched on all the time"
                                                       heading="Collect Deadlocks enabled" variant="alert-warning"/>
                                                </React.Fragment>
                                            )}
                                        </>)}


                                        {/* Detect Changes */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                            <label htmlFor="detect-changes" className="reminder"
                                                   data-tip="Detect changes">Detect Changes</label>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="detect-changes"
                                                       checked={detectChanges}
                                                       onChange={() => setDetectChanges(!detectChanges)}
                                                       disabled={disabled}/>
                                            </div>
                                        </div>
                                        <div className="w-100"></div>

                                        {/* Debug Mode */}
                                        <div className="col-xs-12 col-md-3 px-4">
                                            <label htmlFor="debug" className="reminder" data-tip="Enable debug mode">Debug
                                                Enabled</label>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="debug"
                                                       checked={debug}
                                                       onChange={() => ((debug === true) ? setDebug(false) : setDebug(true))}
                                                       disabled={disabled}/>
                                            </div>
                                        </div>
                                        <div className="w-100"></div>

                                        {debug === true && (
                                            <React.Fragment>
                                                <Alert
                                                    message="We recommend only enabling debug mode when advised by DBmarlin technical support and for short periods only; otherwise, due to the additional data collected, it may fill your disk."
                                                    heading="Debug mode enabled" variant="alert-warning"/>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                                {/* Agent */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="agentId" className="reminder"
                                                                      data-tip="The agent">Agent</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <select
                                        id="agentId"
                                        value={agentId}
                                        onChange={e => setAgentId(Number(e.target.value))}
                                        className={`form-select ${agentIdClass}`}
                                        disabled={disabled}>
                                        {agents.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name} ({item.hostname})</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-100"></div>


                                {/* Hosts Mappings */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="mappings" className="reminder"
                                           data-tip="Map this instance to one or more hosts">Host Mappings</label>
                                    <span className="badge bg-info"
                                          data-tip="Total mapped host count">{mappedHosts.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <div className="row g-0 mb-2">
                                        <div className="col-9">
                                            <input type="text" className="form-control" placeholder="Filter Instances"
                                                   value={filter} data-lpignore={true}
                                                   onChange={(e) => setFilter(e.target.value.toLowerCase())}/>
                                        </div>
                                        <div className="col-3 text-end">
                                            <button type="button" className="btn btn-small btn-dark"
                                                    onClick={clearFilter}><i className="fal fa-times"></i></button>
                                        </div>
                                    </div>
                                    <div className="list-group">
                                        {hosts.filter(item => item.name.toLowerCase().includes(filter)).sort((a, b) => a.name.localeCompare(b.name)).slice(0, 7).map((host: {
                                            id: number;
                                            name: string;
                                        }) => (
                                            <label key={host.id} className="list-group-item">
                                                <input className="form-check-input me-2" type="checkbox" value={host.id}
                                                       checked={mappedHosts.filter(id => id === host.id).length > 0}
                                                       onChange={() => handleHostMapping(host.id)}/>
                                                {host.name}
                                            </label>
                                        ))}
                                        {hosts.filter(item => item.name.toLowerCase().includes(filter)).length > 7 && (
                                            <label className="list-group-item text-black-50">
                                                <small>Filter for more hosts...</small>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="w-100"/>
                                <ThirdPartyLinks links={links} setLinks={setLinks} instanceId={props.instanceId}/>
                                <Tagging tags={tags} setTags={setTags} instanceId={props.instanceId} allTags={allTags}/>

                                {/* Actions */}
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <button onClick={() => history.goBack()} className="btn btn-secondary"><i
                                        className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span></button>
                                    <button disabled={loading} className={`btn btn-${action.variant}`} onClick={handleActionClick}><i
                                        className={`${action.icon} fa-fw fa-sm`}/><span>{action.title}</span>
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Detail;
