import ConditionalRender from '../../helpers/ConditionalRender';
import { prettifyChangesDetailsString, stringFormatting } from "../../helpers/utils";
import React from "react";


export default function InstanceEvents(props: { event: any }) {
    const { event } = props;
    const formattedText = event.details ? event.details.replace(/\n/g, '<br />') : '' // Convert '\n' to '<br />' for HTML line breaks

    return (<React.Fragment>
        <ConditionalRender if={event.detailsUrl !== null && event.detailsUrl !== ''}>
            <a href={event.detailsUrl} target="_blank"
               rel="noopener noreferrer"
               dangerouslySetInnerHTML={{ __html: ((event.title === '') ? formattedText : `${event.title} - ${formattedText}`) }}/><sup
            className="fal fa-external-link fa-fw"/>
        </ConditionalRender>

        <ConditionalRender if={event.detailsUrl === null || event.detailsUrl === ''}>
            <ConditionalRender if={event.event === 'alert'}>
                <span><strong>{event.startedEnded}</strong></span>
                <span> {stringFormatting(event.statistic ? event.statistic : '')}</span>
                <ConditionalRender if={event.decreasing}>
                    {event.decreasing?.toString() === 'true' ? ' less than ' : ' greater than '}
                </ConditionalRender>
                <span>{`${event.threshold ? event.threshold : ''}${event.units ? event.units : ''}`} </span>
            </ConditionalRender>

            <ConditionalRender if={event.event !== 'alert'}>
                <div
                    dangerouslySetInnerHTML={{ __html: ((event.title === '') ? `${formattedText} ` : `${event.title}${((formattedText) ? ` - ${formattedText} ` : ' ')}`) }}/>
            </ConditionalRender>
            <ConditionalRender if={event.changeFrom || event.changeTo}>
                (<strong>from </strong>
                <span data-tip={event.changeFrom?.length > 30 ? event.changeFrom : ''}>
                                {prettifyChangesDetailsString(event.changeFrom + (event.units ? event.units : ''))}
                            </span>
                <strong> to </strong>
                <span data-tip={event.changeTo?.length > 30 ? event.changeTo : ''}>
                                {prettifyChangesDetailsString(event.changeTo + (event.units ? event.units : ''))}
                            </span>)
            </ConditionalRender>
        </ConditionalRender>
    </React.Fragment>)
}
