import PropTypes from 'prop-types';
import {Switch, Route} from 'react-router-dom';

// Types.
import Period from '../../types/Period';
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import EventType from '../../types/instance/EventType';

// Admin Views.
import TimeComparisonSettings from './comparison/Settings';
import Results from './comparison/Results';
import StatisticsWrapper from "../../container/statistics/statistics-wrapper";
import StatisticsComparison from "../../container/statistics-comparison";

function Reports(props: { period: Period, instances: InstanceTarget[], hosts: HostTarget[], eventTypes: EventType[], toggleMenu: Function, applyPeriod: Function }) {
    return (
        <Switch>
            <Route exact={false} path="/reports/time-comparison/results">
                {/*<Results {...props} key={props.period.timestamp} eventTypes={eventTypes} toggleMenu={props.toggleMenu} />*/}
                <Results {...props} key={props.period.timestamp} period={props.period} toggleMenu={props.toggleMenu}/>
            </Route>
            <Route exact path="/reports/time-comparison">
                <TimeComparisonSettings {...props} period={props.period} toggleMenu={props.toggleMenu}/>
            </Route>
            <Route exact path="/reports/sql-stats-explorer">
                <StatisticsWrapper period={props.period} instance={null} type={null} hosts={props.hosts} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod}/>
            </Route>
            <Route exact path="/reports/sql-stats-comparison">
                <StatisticsComparison period={props.period} toggleMenu={props.toggleMenu} applyPeriod={props.applyPeriod}/>
            </Route>
        </Switch>
    );
}

Reports.propTypes = {
    toggleMenu: PropTypes.func.isRequired
};

export default Reports;
