// React.
import { useEffect, useState } from 'react';

// Third-parties.
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { TreeTable, TreeTableExpandedKeysType,
	TreeTableSelectionKeys } from 'primereact/treetable';

// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

// Helpers.
import { Step, formatNumber, getStepDetails } from '../../../helpers/utils';

// Components.
import KnowledgeBasePlanButton from '../../buttons/KnowledgeBasePlanButton';
import NoData from '../../NoData';

export default function PostgresPlan (props: { executionPlan: ExecutionPlan })
{
	const [treeSteps, setTreeSteps] = useState<Step[]> ([]);
	const [expandedKeys, setExpandedKeys] =
		useState<TreeTableExpandedKeysType> ({});
	const [selectedStepKey, setSelectedStepKey] =
		useState<TreeTableSelectionKeys> (null);
	const [stepDetails, setStepDetails] = useState<any[]> ([]);
	const [stepOperation, setStepOperation] = useState<string> ('');
	const [error, setError] = useState<string | null>(null);

	function getStepOperation (details: any[]): string
	{
		const detail: any[] =
			details.filter (detail => detail.name === 'Node Type');
		return detail.length === 1 ? detail[0].value : '';
	}

	useEffect (() =>
	{
		// These closure variables are required by the recursive functions.
		let n: number = 0;
		const expanded: TreeTableExpandedKeysType = {};

		function getKey (): string
		{
			return String (++n);
		}

		function logMissedField (field: string, type: string): void
		{
			for (const stepName of ['Plans'])
			{
				if (field === stepName)
				{
					return;
				}
			}

			console.log ('Missed ' + field + ': ' + type);
		}

		function getData (plan: any): any
		{
			const data: any = {};

			for (const field in plan)
			{
				const type: string = typeof (plan[field]);

				if (type === 'string' || type === 'boolean')
				{
					data[field] = String (plan[field]);
				}
				else if (type === 'number')
				{
					data[field] = formatNumber (plan[field]);
				}
				else if (type === 'object')
				{
					// Special handling for interesting sub-objects.
					const object = plan[field];

					if (field === 'Group Key' || field === 'Sort Key')
					{
						data[field] = object.join (', ');
					}
					else
					{
						logMissedField (field, type);
					}
				}
				else
				{
					logMissedField (field, type);
				}
			}

			return data;
		}

		function getChildren (plan: any): Step[]
		{
			const children: Step[] = [];
			const childPlans: any[] = plan['Plans'];

			if (childPlans)
			{
				for (const childPlan of childPlans)
				{
					children.push (getStep (childPlan));
				}
			}

			return children;
		}

		function getStep (plan: any): Step
		{
			const key: string = getKey ();

			// Expand all rows as a side-effect.
			expanded[key] = true;

			const step: Step =
			{
				key: key,
				data: getData (plan),
				children: getChildren (plan)
			};

			return step;
		}

		try
		{
			const plans: any[] = JSON.parse (props.executionPlan.sqlplan);
			// console.log ('plans', plans);
			const tree: Step[] = [];

			for (const plan of plans)
			{
				tree.push (getStep (plan.Plan));
			}

			const details: any[] = getStepDetails (tree, '1');
			setTreeSteps (tree);
			setExpandedKeys (expanded);
			setSelectedStepKey ('1');
			setStepDetails (details);
			setStepOperation (getStepOperation (details));
			setError (null);
		}
		catch (x: any)
		{
			setTreeSteps ([]);
			setExpandedKeys ({});
			setSelectedStepKey (null);
			setStepDetails ([]);
			setStepOperation ('');
			setError (String (x));
		}
	}, [props.executionPlan.sqlplan]);

	useEffect (() =>
	{
		const details: any[] = getStepDetails (treeSteps, selectedStepKey);
		const operation: string = getStepOperation (details);
		setStepDetails (details);
		setStepOperation (operation);
	}, [treeSteps, selectedStepKey]);

	// The bracket must be present and on the same line as the return.
	if (error)
	{
		return (
			<NoData error={error} loading={false} length={0} />
		)
	}

	return (
		<Splitter>
			<SplitterPanel size={65} className="plan-master-panel">
				<TreeTable
					value={treeSteps}
					expandedKeys={expandedKeys}
					onToggle={e => setExpandedKeys (e.value)}
					selectionMode="single"
					resizableColumns
					reorderableColumns
					selectionKeys={selectedStepKey}
					onSelectionChange={e => setSelectedStepKey (e.value)}
					className="treetable-responsive"
				>
					<Column header="Operation" field="Node Type" expander
						className="col-width-45 plan-first-column"/>
					<Column header="Object" field="Relation Name"
						className="col-width-25"/>
					<Column header="Cost" field="Total Cost"
						className="col-width-15 text-end"/>
					<Column header="Rows" field="Plan Rows"
						className="col-width-15 text-end"/>
				</TreeTable>
			</SplitterPanel>
			<SplitterPanel size={35} className="plan-detail-panel">
				<DataTable value={stepDetails} resizableColumns stripedRows>
					<Column header="Name" field="name"
						className="col-width-50 plan-first-column"/>
					<Column header="Value" field="value"
						className="col-width-50"/>
				</DataTable>
				<KnowledgeBasePlanButton databaseType="postgresql"
					planOperation={stepOperation}
					planOption=""/>
			</SplitterPanel>
		</Splitter>
	)
}
