// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

// Third-party packages.
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

// Types.
import Licence from '../../../types/Licence';

// Constants.
import { ACTION_CREATE, ACTION_UPDATE, ACTION_DELETE } from '../../../component/Constants';
import { DATA_FAILED, DATA_EMPTY } from '../../../component/Constants';

// Helpers.
import { formatNumber } from '../../../helpers/utils';

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(relativeTime.default);

function getLicenceCount (licence: Licence): string
{
	return licence.settings && licence.settings.count ?
		formatNumber (licence.settings.count) : '0';
}

function getTierName (licence: Licence): string
{
	const tierNames = [ 'Starter', 'Standard', 'Premium' ];
	return licence.settings && licence.settings.tier &&
		licence.settings.tier >= 1 &&
		licence.settings.tier <= tierNames.length ?
		tierNames[licence.settings.tier - 1] : '-';
}

function getType (licence: Licence): string
{
	return licence.settings && licence.settings.type ?
		licence.settings.type : '-';
}

function getIsValid (licence: Licence): string
{
	return licence.valid ? 'Yes' : 'No';
}

function getMacAddress (licence: Licence): string
{
	return licence.settings && licence.settings.macaddress ?
		licence.settings.macaddress : '-';
}

function getPeriod (licence: Licence): string
{
	let period: string = '-';

	if (licence.settings)
	{
		const startDate = licence.settings.startdate;
		const endDate = licence.settings.enddate;

		if (startDate)
		{
			period = 'Begins ' + startDate;
		}

		if (endDate)
		{
			period = 'Expires ' + endDate;
		}

		if (startDate && endDate)
		{
			period = startDate + ' to ' + endDate;
		}
	}

	return period;
}

function getDaysRemaining (licence: Licence): string
{
	return licence.settings && licence.settings.enddate ?
		formatNumber (dayjs.default (licence.settings.enddate).diff (new Date (), 'day') + 1) : '-';
}

function List(props: { loading: number, licences: Licence[], licence: Licence, refresh: Function }) {

    function refresh() {
        props.refresh();
    }

	let rootLicence: Licence[] = props.licences.filter (licence => licence.id === 0);

	if (rootLicence.length === 0)
	{
		rootLicence = [{ id: 0, key: '', properties: '{}', valid: true }];
	}

	const tierName = getTierName (rootLicence[0]);
	const validPeriod = getPeriod (rootLicence[0]);
	const daysRemaining = getDaysRemaining (rootLicence[0]);
	const licenceCount = getLicenceCount (rootLicence[0]);
    let licenceExportData: any[] = [['id', 'key']];

    for (let index = 0; index < props.licences.length; index++) {
        
        licenceExportData.push([props.licences[index].id.toString(), props.licences[index].key]);
    }
    
    return (<>
		{/* Licence table */}
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-file-certificate fa-fw" aria-hidden="true"></i>
                    Licences
                    <span className="badge bg-info" data-tip="Total licence count">{licenceCount}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseLicences" aria-expanded="false" aria-controls="collapseLicences"></i>
                </div>
                <div id="collapseLicences" className="card-body collapse show" style={{width: '60%'}}>
					{props.loading === DATA_EMPTY ? (
						<div className="w-100 text-center text-muted my-3">
							<i className="fal fa-ban fa-fw fa-2x"></i>
							<p>
								<button type="button" className="btn btn-link" onClick={refresh}>No Licences Found - Click to Refresh</button>
							</p>
						</div>
					) : (
							props.loading === DATA_FAILED ? (
								<div className="w-100 text-center text-muted my-3">
									<i className="fal fa-ban fa-fw fa-2x text-danger"></i>
									<p>
										<button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve Licences - Click to Refresh</button>
									</p>
								</div>
							) : (
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th scope="col">Licence Type</th>
													<th scope="col">Current Period</th>
													<th scope="col" className="text-end">Days Remaining</th>
													<th scope="col" className="text-end">Licence Count</th>
												</tr>
											</thead>
											<tfoot>
												<tr>
													<td className="fw-bold">
														{tierName}
													</td>
													<td>
														{validPeriod}
													</td>
													<td className="text-end">
														{daysRemaining}
													</td>
													<td className="text-end fw-bold">
														{licenceCount}
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								)
						)}
				</div>
			</div>
		</div>
		{/* Key table */}
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-file-certificate fa-fw" aria-hidden="true"></i>
                    Keys
                    <span className="badge bg-info" data-tip="Key count">{formatNumber (props.licences.length - 1)}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseKeys" aria-expanded="false" aria-controls="collapseKeys"></i>
                </div>
                <div id="collapseKeys" className="card-body collapse show">
                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"></i>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>No Licences Found - Click to Refresh</button>
                            </p>
                        </div>
                    ) : (
                            props.loading === DATA_FAILED ? (
                                <div className="w-100 text-center text-muted my-3">
                                    <i className="fal fa-ban fa-fw fa-2x text-danger"></i>
                                    <p>
                                        <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve Licences - Click to Refresh</button>
                                    </p>
                                </div>
                            ) : (
                                    <React.Fragment>
                                        <div className="row row-cols-1 row-cols-md-2 table-search">
                                            <div className="col col-md-12">
                                                <Link to={{ pathname: `/admin/licences/create`, state: { action: ACTION_CREATE, licence: props.licence } }} role="button" className="btn btn-sm btn-success">
                                                    <i className="fal fa-plus-square fa-fw"></i><span>Register</span>
                                                </Link>
                                                <CSVLink role="button"
                                                    data={licenceExportData}
                                                    download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Licences.csv`}
                                                    className="btn btn-sm btn-primary">
                                                    <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                                                </CSVLink>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Installed Key</th>
                                                        <th scope="col">Valid</th>
                                                        <th scope="col">Valid Period</th>
                                                        <th scope="col">MAC Address</th>
                                                        <th scope="col" className="text-end">Days Remaining</th>
                                                        <th scope="col" className="text-end">Licence Count</th>
                                                        <th scope="col" className="actions">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.licences.filter(licence => licence.id !== 0).map((licence: Licence) => (
                                                        <tr key={licence.id}>
															<td>
																{getTierName (licence) + ' - ' + getType (licence)}
															</td>
															<td>
																{getIsValid (licence)}
															</td>
                                                            <td className={((licence.class) ? licence.class : '')}>
																{getPeriod (licence)}
                                                            </td>
                                                            <td>
																{getMacAddress (licence)}
                                                            </td>
                                                            <td className="text-end">
																{getDaysRemaining (licence)}
                                                            </td>
                                                            <td className="text-end">
																{getLicenceCount (licence)}
                                                            </td>
                                                            <td className="actions">
                                                                <Link to={{ pathname: `/admin/licences/${licence.id}`, state: { action: ACTION_UPDATE, licences: props.licences, licence: licence } }} role="button" className="btn btn-sm btn-primary">
                                                                    <i className="fal fa-binoculars fa-fw"></i><span>View</span>
                                                                </Link>
                                                                {licence.id !== 1 && (
                                                                    <Link to={{ pathname: `/admin/licences/${licence.id}`, state: { action: ACTION_DELETE, licences: props.licences, licence: licence } }} role="button" className="btn btn-sm btn-danger">
                                                                        <i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
                                                                    </Link>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {props.licences.length <= 1 && (
                                                <div className="w-100 text-center text-muted my-3">
                                                    <i className="fal fa-ban fa-fw fa-2x text-danger"></i>
                                                    <p>
                                                        <Link to={{ pathname: `/admin/licences/create`, state: { action: ACTION_CREATE, licence: props.licence } }} role="button" className="btn btn-link">
                                                            No Licences Found - Click to Register Licence
                                                        </Link>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )
                        )}
                </div>
            </div>
        </div>
    </>)
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
