import Tags from "../types/Tags";
import ConditionalRender from "../helpers/ConditionalRender";

function TagsList(props: {tagList: Tags[] }) {
    const  {tagList} = props
    return (
        <ConditionalRender if={tagList.length}>
            <div className='custom-tags d-inline'>
                {tagList.map((tag,i) => <span className='filter-button tag' key={i}>{`${tag.tagname}: ${tag.tagvalue}`}</span>)}
            </div>
        </ConditionalRender>
    );
}

export default TagsList;
