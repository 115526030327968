// Helpers.
import { dhms } from '../../../helpers/utils';
import Helper from "../../../helpers/Helper";
import { useContext } from "react";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import ConditionalRender from "../../../helpers/ConditionalRender";

export default function TimeStaticWidget(props:
                                             { metric: number, metricPrevious: number }) {
    const {metric, metricPrevious} = props;
    const metricPercentageDifference =
        Math.round((metric - metricPrevious) / metricPrevious * 100.0);
    const timeRangeContext = useContext(TimeRangeContext)
    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)

    return (
        <div className="col flexlist-item">
            <div className="card">
                <div className="card-body widget">
                    <div className="row g-0 align-items-center card-row">
                        <div className="icon time"
                             data-tip="The total instance time spent by user processes either actively working or waiting in a DB call">
                            <span className="fal fa-chart-line fa-fw"/>
                        </div>
                        <div className="col data">
                            Total Time
                            <div>
                                {dhms(metric)}
                                <ConditionalRender if={comparisonSupported}>
										<span className="comparison">
									{metric === metricPrevious ? (
                                        <i className="fal fa-equals equals fa-fw"/>
                                    ) : (
                                        metric > metricPrevious ? (
                                            <i className="fal fa-long-arrow-up fa-fw increase"/>
                                        ) : (
                                            <i className="fal fa-long-arrow-up fa-fw decrease"/>
                                        ))}
                                            {dhms(metricPrevious)}
                                            {isFinite(metricPercentageDifference) &&
                                                (<>
                                                    &nbsp;({metricPercentageDifference}%)
                                                </>)}
								</span>
                                </ConditionalRender>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
