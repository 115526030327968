import { Component } from 'react';
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

// Helpers.
import Helmet from "react-helmet";

// Constants.
import { ACTIONS, DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from "../../../../component/Constants";

// APis
import IntegrationsApi from "../../../../api/Integrations";

//Types
import Period from "../../../../types/Period";
import NewRelicType from "../../../../types/integration/IntegrationType";

// Components.
import Breadcrumb from "../../../../container/breadcrumb";
import HeaderActions from "../../../../container/header_actions";
import ConditionalRender from "../../../../helpers/ConditionalRender";
import Detail from './Detail';
import List from "./List";


interface NewRelicProps {
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function
}

interface NewRelicState {
    loading: number,
    newRelic: NewRelicType[],
}

const initialState = {
    loading: DATA_INITIALISING,
    newRelic: []
}

class NewRelic extends Component<NewRelicProps, NewRelicState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: NewRelicProps) {
        super(props);

        this.state = initialState;
    };

    async getData() {
        const newRelic = await IntegrationsApi.getIntegrationData('newrelic');

        this.setState({
            loading: DATA_LOADED,
            newRelic
        });
    }

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    /* Action Methods */
    refresh = () => {

        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, newRelic } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>New Relic - Settings - DBmarlin</title>
                    <meta name="description" content="Manage New Relic"/>
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-2">
                    <Breadcrumb heading="New Relic">
                        <Link to="/admin">Settings</Link>
                        <Link to="/admin/integrations">Integrations</Link>
                        <span>New Relic</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}
                                   applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"/>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/integrations/new-relic/create"
                               render={() => <Detail refresh={this.refresh} actionId={ACTIONS.CREATE.type} newRelic={[]}/>}/>\
                        <Route exact path="/admin/integrations/new-relic/:actionId"
                               render={(props) => <Detail refresh={this.refresh} actionId={props.match.params.actionId} newRelic={newRelic} {...props} />}/>
                        <Route exact path="/admin/integrations/new-relic/"
                               render={(props) => <List {...props} loading={loading} newRelic={newRelic} refresh={this.refresh}/>}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

NewRelic.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default NewRelic;
