function TimeShift() {
    return (
        <div className="dropdown d-none">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="timeShift" data-bs-toggle="dropdown" aria-expanded="false">
                Time Shift: Previous Window
            </button>
            <ul className="dropdown-menu" aria-labelledby="timeShift">
                <li><button type="button" className="btn btn-link dropdown-item">Compare Option 1</button></li>
                <li><button type="button" className="btn btn-link dropdown-item active">Compare to Previous Hour</button></li>
                <li><button type="button" className="btn btn-link dropdown-item">Compare to Option 2</button></li>
                <li><button type="button" className="btn btn-link dropdown-item">Compare to Option 3</button></li>
                <li><button type="button" className="btn btn-link dropdown-item">Compare to Option 4</button></li>
            </ul>
        </div>
    );
}

export default TimeShift;