import { Component } from 'react';
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

// Helpers.
import Helmet from "react-helmet";

// Constants.
import { ACTIONS, DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from "../../../../component/Constants";

// APis
import IntegrationsApi from "../../../../api/Integrations";

//Types
import Period from "../../../../types/Period";
import PagerDutyType from "../../../../types/integration/IntegrationType";

// Components.
import Breadcrumb from "../../../../container/breadcrumb";
import HeaderActions from "../../../../container/header_actions";
import ConditionalRender from "../../../../helpers/ConditionalRender";
import List from "./List";
import Detail from "./Detail";


interface PagerDutyProps {
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function
}

interface PagerDutyState {
    loading: number,
    pagerDuty: PagerDutyType[],
}

const initialState = {
    loading: DATA_INITIALISING,
    pagerDuty: []
}

class PagerDuty extends Component<PagerDutyProps, PagerDutyState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: PagerDutyProps) {
        super(props);

        this.state = initialState;
    };

    async getData() {
        const pagerDuty = await IntegrationsApi.getIntegrationData('pagerduty');
        this.setState({
            loading: DATA_LOADED,
            pagerDuty
        });
    }

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    /* Action Methods */
    refresh = () => {

        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, pagerDuty } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>PagerDuty - Settings - DBmarlin</title>
                    <meta name="description" content="Manage PagerDuty"/>
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-2">
                    <Breadcrumb heading="PagerDuty">
                        <Link to="/admin">Settings</Link>
                        <Link to="/admin/integrations">Integrations</Link>
                        <span>PagerDuty</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}
                                   applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"/>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/integrations/pager-duty/create"
                               render={() => <Detail refresh={this.refresh} actionId={ACTIONS.CREATE.type} pagerDuty={[]}/>}/>\
                        <Route exact path="/admin/integrations/pager-duty/:actionId"
                               render={(props) => <Detail refresh={this.refresh} actionId={props.match.params.actionId} pagerDuty={pagerDuty} {...props} />}/>
                        <Route exact path="/admin/integrations/pager-duty/"
                               render={(props) => <List {...props} loading={loading} pagerDuty={pagerDuty} refresh={this.refresh}/>}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

PagerDuty.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default PagerDuty;
