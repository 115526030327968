import { COLOUR_INSTANCE_EXECUTIONS, COLOUR_INSTANCE_TIME } from "../component/Constants";
import { ChartPlotLine } from "../types/ChartPlotLine";
import ChartPlotBand from "../types/ChartPlotBand";
import dayjs from "dayjs";
import { getMinMAx } from "./utils";

const COLORS: { [key: string]: string } = {
    'Executions': COLOUR_INSTANCE_EXECUTIONS,
    'Time Spent': COLOUR_INSTANCE_TIME
}

export const chartOptionsGranularity = (applyPeriod: Function, series: any[], max: any, options: any = {}, height: number = 200) => {
    // let pseudoInterval = 0
    let interval = 0

    let minMax: { minX: number | undefined, maxX: number | undefined } = {
        minX: undefined,
        maxX: undefined
    }

    series.forEach((set: any) => {
        set.color = set.color || COLORS[set.name]
        set.gapSize = 2
        set.gapUnit = 'relative'
    })

    const maxValue = max || (series[0] ? Math.max(...(series[0]?.data.map((i: any) => i[1]))) : 0)

    if (options?.plotLines) {
        options.plotLines = options.plotLines.map((chartPlotLine: ChartPlotLine) => {
            return {
                ...chartPlotLine,
                value: chartPlotLine.timeValue
            }
        })
    }

    if (options?.plotBands) {
        options.plotBands = options.plotLines.map((chartPlotBand: ChartPlotBand) => {
            return {
                ...chartPlotBand,
                value: chartPlotBand.timeValue
            }
        })
    }

    if (options.timeRangeContext) {
        interval = options.timeRangeContext.interval
        minMax = getMinMAx(options.timeRangeContext)
    }

    const selectPeriod = (time: { start: number, end: number }) => {
        const savedTimeZone = window.localStorage.getItem('dbmar-timeZone')
        const timeZoneValue = savedTimeZone ? JSON.parse(savedTimeZone) : Intl.DateTimeFormat().resolvedOptions().timeZone
        let startTime = dayjs(time.start);
        let endTime = dayjs(time.end);
        interval = 0

        if (endTime.diff(startTime, 'minute') < 1) {
            endTime = startTime.add(1, 'minute');
        }

        options.timeRangeContext.setTimeRange({
            from: startTime,
            to:  endTime,
            timezone: timeZoneValue,
            interval: interval,
        })

        applyPeriod(1, startTime, endTime, null, timeZoneValue);
    }

    const tooltip = {
        ...(options.tooltip || {}),
        ...(options.tooltip ? {
            borderWidth: 0,
            outide: true,
            padding: 0,
            shadow: false,
            shared: true,
            useHTML: true
        } : {
            shared: true,
            valueDecimals: 0
        })
    };

    const yAxis = options.yAxis || [{
        labels: {
            enabled: false
        },
        title: {
            text: ''
        },
        min: 0,
        ...(max ? { maxValue } : {})
    }, {
        labels: {
            enabled: false
        },
        min: 0,
        title: {
            text: ''
        },
        opposite: true
    }]

    return {
        chart: {
            height: `${height}px`,
            type: 'column',
            zoomType: 'x',
            style: {
                fontFamily: 'system-ui, Arial, sans-serif',
                letterSpacing: 'normal'
            },
            events: {
                selection: function (event: any) {
                    if (event.xAxis) {
                        const start = event.xAxis[0].min;
                        const end = event.xAxis[0].max;
                        selectPeriod({ start, end });
                    }
                    return false; // Don't zoom into the selected area
                },
            }
        },
        title: { text: '' },
        xAxis: [{
            endOnTick: false,
            plotLines: options?.plotLines,
            // plotBands: options?.plotBands,
            // min: minMax.minX ? minMax.minX - pseudoInterval : minMax.minX,
            min: minMax.minX,
            max: minMax.maxX,
            tickInterval: interval || 'auto',
            type: 'datetime',
            lineColor: '#CCCCCC',
            tickColor: '#CCCCCC',
            labels: {
                distance: 9,
                style: {
                    color: '#666666',
                    cursor: 'default',
                    fontSize: '11px',
                    letterSpacing: '1px'
                }
            },
            crosshair: {
                width: 15,
                color: "#EDF8FD"
            },
        }],
        time: {
            useUTC: false
        },
        yAxis: yAxis,
        plotOptions: {
            animation: false,
            column: { stacking: 'normal', minPointLength: options.minHeight || 0, pointPlacement: "on" },
            area: { stacking: 'normal' },
            series: { marker: { enabled: false }, pointPlacement: "on" }
        },
        exporting: { enabled: false },
        tooltip: tooltip,
        legend: {
            align: 'center',
            backgroundColor: 'rgba(255,255,255,0.25)',
            enabled: !!options.legend,
            floating: false,
            itemMarginTop: 0,
            itemMarginBottom: 2,
            itemStyle: {
                color: '#212529',
                fontSize: '12px',
                fontWeight: 'bold',
                letterSpacing: 'normal',
                fontFamily: 'system-ui, Arial, sans-serif'
            },
            layout: 'horizontal',
            padding: 0,
            x: 0,
            y: 0,
        },
        series: series,
        credits: options?.credits || { enabled: false },
        accessibility: {
            enabled: false
        }
    }
}