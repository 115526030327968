import PropTypes from 'prop-types';

// Types.
import Period from '../../../types/Period';

// Components.
import SessionsTable from '../table/SessionsTable';

function Sessions(props: { instanceId: number, batchId?: string, statementId?: string, period: Period, totalInstanceTime: number, setSessionsOptions: Function, setFilterOptions: Function }) {    
    return (
        <div id="sessions" className="tab-pane fade" role="tabpanel" aria-labelledby="sessions-tab">
            <SessionsTable period={props.period} instanceId={props.instanceId} batchId={props.batchId} statementId={props.statementId} instanceTime={props.totalInstanceTime} setSessionsOptions={props.setSessionsOptions} setFilterOptions={props.setFilterOptions}/>
        </div>
    );
}

Sessions.propTypes = {
    setSessionsOptions: PropTypes.func.isRequired
};

export default Sessions;
