import { useContext, useEffect, useMemo, useState } from 'react';
import EventsApi from "../../../api/instance/EventsApi";
import api from "../../../api/Base";

// Components.
import StatementTimeChart from "../../../component/instance/chart/StatementTimeChart";
import HostsOverTimeCharts from "../../../component/instance/chart/HostsOverTimeCharts";
import TimeStaticWidget from "../../../component/instance/widget/TimeStaticWidget";
import LegacyChangesWidget from "../../../component/instance/widget/LegacyChangesWidget";
import StatementPieChartAndTabs from "./StatementPieChartAndTabs";

// Contexts
import { InstanceContext } from "../../../context/InstanceContext";
import { HostContext } from "../../../context/HostContext";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

// Third-party packages.

// Helpers.
import Helper from "../../../helpers/Helper";
import * as dayjs from "dayjs";
import { getAPIString } from "../../instances/tabs/utils";
import ConditionalRender from "../../../helpers/ConditionalRender";

// Types.
import Wait from "../../../types/instance/Wait";
import ChartPlotBand from "../../../types/ChartPlotBand";
import CombinedChanges from "../../../types/instance/CombinedChanges";
import ChartPlotLine from "../../../types/ChartPlotLine";
import EventType from "../../../types/instance/EventType";
import InstanceTarget from "../../../types/instance/InstanceTarget";
import DatabaseTime from "../../../types/instance/DatabaseTime";
import EventsDataTable from "../../../types/instance/tables/EventsDataTable";
import HostTarget from "../../../types/host/HostTarget";
import InstanceTargetHost from "../../../types/instance/InstanceTargetHost";
import PieChartSeries from "../../../types/PieChartSeries";
import Period from "../../../types/Period";

// Constants.
import { CHART_COLOURS_WAITS } from "../../../component/Constants";


interface IStatementActivityProps {
    statementId?: string,
    batchId?: string,
    batchStatementId?: string,
    period: Period,
    chartPlanPlotLines: ChartPlotLine[],
    instance: InstanceTarget,
    setFilterOptions: Function,
    applyPeriod: Function,
    filterParameters: string,
    eventTypes: EventType[]
}

const StatementActivity = (props: IStatementActivityProps) => {
    const timeRangeContext = useContext(TimeRangeContext)
    const { instances } = useContext(InstanceContext);
    const { hosts } = useContext(HostContext);

    const {
        instance,
        statementId,
        filterParameters,
        eventTypes,
        batchId,
        batchStatementId,
        chartPlanPlotLines,
        setFilterOptions,
        period
    } = props;
    const [waits, setWaits] = useState<Wait[]>([]);

    const [statementTime, setStatementTime] = useState<number>(0);
    const [statementTimePrevious, setStatementTimePrevious] = useState<number>(0);
    const [statementCombinedChanges, setStatementCombinedChanges] = useState<CombinedChanges[]>([]);

    const [statementChartPlotBands, setStatementChartPlotBands] = useState<ChartPlotBand[]>([]);
    const [statementChartPlotLines, setStatementChartPlotLines] = useState<ChartPlotLine[]>([]);
    const [statementEventsDataTable, setStatementEventsDataTable] = useState<EventsDataTable[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [displayHostMetrics, setDisplayHostMetrics] = useState<boolean>(false);
    const [displayTimeChart, setDisplayTimeChart] = useState<boolean>(true);
    const [availableHosts, setAvailableHosts] = useState<HostTarget[]>([]);


    const pathname = window.location.pathname

    useEffect(() => {
        const urlString = getAPIString(statementId || batchId, batchStatementId)
        const getData = async() => {
            await getInstanceTime(instance.id, urlString);
            await getChanges(instance.id);
            await getWaits(instance.id, urlString);
            await getInstanceHosts(instance.id);
        }
        if (instance) {
            setLoading(true);
            getData().then(() => setLoading(false))
        }
    }, [pathname])

    const getInstanceHosts = async (instanceId: number) => {

        let instanceHosts: HostTarget[] = [];
        // Get any hosts related to this instance.
        await api.get(`datasource/host?id=${instanceId}`)
            .then((response: { data: InstanceTargetHost[]; }) => {

                for (let index = 0; index < response.data.length; index++) {

                    // Get any matching hosts.
                    const matchedHosts = hosts.filter(host => host.id === response.data[index].hostid);

                    if (matchedHosts.length === 1) {
                        // Append the matched hosts to any pre-existing hosts.
                        instanceHosts.push(matchedHosts[0]);
                    }
                }
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.log('An error occurred:', error);
            })
            .then(async() => {
                setAvailableHosts(instanceHosts)
            });
    }


    const getInstanceTime = (instanceId: number, apiString: string) => {

        // Get total batch/statement time.
        api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}${apiString}${filterParameters}`)
            .then((response: { data: DatabaseTime[]; }) => {
                if (response.data.length > 0) {
                    setStatementTime(response.data[0].waittime)
                }
            })
            .catch((error: any) => {
                console.log('An error occurred:', error);
            })

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {
            // Get total database time for the previous period.
            api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString(true)}&id=${instanceId}${apiString}${filterParameters}`)
                .then((response: { data: DatabaseTime[]; }) => {
                    if (response.data.length > 0) {
                        setStatementTimePrevious(response.data[0].waittime)
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve total instance time.', error);
                })
        }
    }

    const getChanges = async(instanceId: number) => {

        let allEvents: any[] = await EventsApi.getEventsChangesAndAlerts(timeRangeContext, instanceId),
            combinedChanges: CombinedChanges[] = [],
            chartPlotLines: ChartPlotLine[] = [];

        for (let index = 0; index < allEvents.length; index++) {
            const currentItem = allEvents[index];
            combinedChanges.push({
                id: index,
                instanceId: currentItem.datasourceid,
                timeslice: currentItem.timeslice,
                [currentItem.event]: currentItem
            });
        }

        // Set the sort order for the combined changes and events.
        combinedChanges.sort(function compare(a, b) {
            var aa: number = dayjs.default(a.timeslice).valueOf();
            var bb: number = dayjs.default(b.timeslice).valueOf();
            return bb - aa;
        });

        const interval = Helper.getInterval(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, timeRangeContext.timeRange?.interval)
        const chartCategories = Helper.getChartAxisCategories(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, interval)
        chartPlotLines = Helper.getChartPlotLines(null, combinedChanges, chartCategories, interval);

        let eventsDataTable: EventsDataTable[] = [];

        for (let index = 0; index < combinedChanges.length; index++) {

            const
                combinedChange: CombinedChanges = combinedChanges[index],
                matchedInstances: InstanceTarget[] = instances.filter(instance => Number(instance.id) === Number(combinedChange.instanceId));

            let changeType: string = '',
                htmlIconCode: string = '',
                detailsUrl: string = '',
                color: string = '',
                title: string = '',
                details: string = '';

            if (combinedChange.event) {

                const matchedEventTypes: EventType[] = eventTypes.filter(eventType => Number(eventType.eventTypeId) === Number(combinedChange.event?.eventTypeId));

                if (matchedEventTypes.length > 0) {

                    changeType = matchedEventTypes[0].title;

                    if (matchedEventTypes[0].htmlIconCode !== undefined) {
                        htmlIconCode = matchedEventTypes[0].htmlIconCode;
                    }
                }

                if (combinedChange.event.detailsUrl !== undefined) {
                    detailsUrl = combinedChange.event.detailsUrl;
                }

                color = combinedChange.event.colourCode;
                title = combinedChange.event.title;
                details = combinedChange.event.description;
            }

            if (combinedChange.change) {

                if (combinedChange.change.name.length > 0 && combinedChange.change.type.length > 0) {

                    changeType = `${combinedChange.change.type.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')} ${combinedChange.change.type.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}`;
                } else {

                    changeType = combinedChange.change.type.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
                }

                details = combinedChange.change.name;
            }

            eventsDataTable.push({
                id: combinedChange.id,
                instanceId: combinedChange.instanceId,
                changeType,
                changeFrom: combinedChange.change?.oldvalue,
                changeTo: combinedChange.change?.newvalue,
                htmlIconCode,
                instanceName: ((matchedInstances.length > 0) ? matchedInstances[0].name : 'Unknown'),
                detailsUrl,
                color,
                title,
                details,
                timeslice: combinedChange.timeslice,
                timesliceEnd: ((combinedChange.event && combinedChange.event.isPlotband === true) ? combinedChange.event.endDateTime : undefined)
            });
        }

        const chartPlotBands = Helper.getChartPlotbands(combinedChanges, chartCategories);

        // setStatementChanges(changes)
        setStatementCombinedChanges(combinedChanges)
        setStatementChartPlotBands(chartPlotBands)
        setStatementChartPlotLines(chartPlotLines)
        // setStatementEvents(events)
        setStatementEventsDataTable(eventsDataTable)

    }

    const getWaits = async(instanceId: number, apiString: string) => {
        const chartSeriesCeiling = Number(process.env.REACT_APP_CHART_SERIES_CEILING);
        // let batchApi: string = batchId ? `&batchsqlhash=${batchId}` : '';
        // let apiString: string = props.statementId ? `&${getStatementTypeFromURL().api}=${statementId}` : ''

        // Get waits.
        api.get(`activity/waitevent?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${filterParameters}`)
            .then(async(response: { data: Wait[]; }) => {

                let waitsPieChartSeries: PieChartSeries[] = [];

                // Build top waits pie chart.
                for (let index = 0; index < response.data.length; index++) {

                    if (index < CHART_COLOURS_WAITS.length) {
                        // Set the top colours for the chart.
                        response.data[index].color = CHART_COLOURS_WAITS[index];
                    }

                    waitsPieChartSeries.push({
                        color: response.data[index].color,
                        formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                        name: response.data[index].waitevent,
                        y: response.data[index].waittime
                    });
                }

                if (response.data.length > chartSeriesCeiling) {

                    // Get and add a remaining category to the chart.
                    const remainingTime = response.data.slice(chartSeriesCeiling, response.data.length)
                        .map((item: Wait) => item.waittime)
                        .reduce((a: number, b: number) => a + b, 0);

                    waitsPieChartSeries.push({
                        color: CHART_COLOURS_WAITS[chartSeriesCeiling],
                        formatted: Helper.getTimeInEnglish(remainingTime),
                        name: 'Other Waits',
                        y: remainingTime
                    });
                }

                setWaits(response.data)
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.log('An error occurred:', error);

            })
    }

    const toggleHostMetricChart = () => {
        setDisplayHostMetrics(!displayHostMetrics)
    }

    const toggleTimeChart = () => {
        setDisplayTimeChart(!displayTimeChart)
    }

    const changes = statementCombinedChanges.filter(item => item.change);
    const events = statementCombinedChanges.filter(item => item.event);
    const alerts = statementCombinedChanges.filter(item => item.alert);

    const widgetsComponent = useMemo(() => (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
            <TimeStaticWidget metric={statementTime} metricPrevious={statementTimePrevious}/>
            <LegacyChangesWidget changes={changes}
                                 alerts={alerts}
                                 events={events}/>
        </div>
    ), [statementTime,
        statementTimePrevious,
        filterParameters,
        statementCombinedChanges]);
    return (
        <div>
            {widgetsComponent}

            <ConditionalRender if={loading}>
                <div className="w-100 center-screen text-center text-muted mt-3">
                    <div className="loader spinner chartSpinner">
                    </div>
                    <p className="mt-3">
                        Loading data...
                    </p>
                </div>
            </ConditionalRender>
            <ConditionalRender if={!loading}>
                <div className="row">
                    <div className="col">
                        <div className="card collapsible">
                            <div className="card-header">
                                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                                Time spent
                                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                                   data-bs-target="#collapseExecutions" aria-expanded="false"
                                   aria-controls="collapseExecutions"/>
                                <div className="btn-group float-end">
                                    {availableHosts.length > 0 && (
                                        <button type="button" className="btn btn-xsm btn-primary float-end ms-1"
                                                onClick={() => toggleHostMetricChart()}>
                                            {((displayHostMetrics) ? 'Hide Hosts' : 'Show Hosts')}
                                        </button>
                                    )}
                                    <button type="button" className="btn btn-xsm btn-primary float-end ms-1"
                                            onClick={() => toggleTimeChart()}>
                                        {((displayTimeChart) ? 'Show Waits' : 'Hide Waits')}
                                    </button>
                                </div>
                            </div>
                            <ConditionalRender if={loading}>
                                <div className="w-100 text-center text-muted mt-3">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="mt-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>

                            <ConditionalRender if={!loading}>
                                <div id="collapseExecutions" className="card-body collapse show">
                                    <StatementTimeChart displayTimeChart={displayTimeChart}
                                                        instance={instance} batchId={batchId} statementId={statementId}
                                                        filtersParameters={filterParameters}
                                                        plotBands={statementChartPlotBands}
                                                        applyPeriod={props.applyPeriod}
                                                        batchStatementId={batchStatementId}
                                                        plotLines={[...statementChartPlotLines, ...chartPlanPlotLines]}
                                                        waits={waits}/>
                                    {displayHostMetrics && (
                                        <HostsOverTimeCharts instance={instance} hosts={hosts}
                                                             plotBands={statementChartPlotBands}
                                                             applyPeriod={props.applyPeriod}
                                                             plotLines={statementChartPlotLines}/>
                                    )}
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>
                </div>

                <StatementPieChartAndTabs statementCombinedChanges={statementCombinedChanges} batchId={batchId}
                                          statementEventsDataTable={statementEventsDataTable}
                                          filterParameters={filterParameters}
                                          batchStatementId={batchStatementId}
                                          statementTime={statementTime} statementId={statementId} period={period}
                                          instance={instance} setFilterOptions={setFilterOptions}/>
            </ConditionalRender>

        </div>
    )
}

StatementActivity.propTypes = {};

export default StatementActivity;