import React, { useMemo } from 'react';

// Third-party.
import Skeleton from 'react-loading-skeleton';
import Helper from '../../../helpers/Helper';

function AverageTimeStaticWidget(props: { executionsMetric: undefined | null | number, executionsMetricPrevious: undefined | null | number, timeMetric: undefined | null | number, timeMetricPrevious: undefined | null | number }) {
    const {executionsMetric, executionsMetricPrevious, timeMetric, timeMetricPrevious} = props

    const metric = useMemo(() => {

        let averageTime: null | number = null,
            previousAverageTime: null | number = null;

        if (timeMetric !== undefined && timeMetric !== null && executionsMetric !== undefined && executionsMetric !== null) {
            averageTime = Number((timeMetric / executionsMetric).toFixed(2));
        }

        if (timeMetricPrevious !== undefined && timeMetricPrevious !== null && executionsMetricPrevious !== undefined && executionsMetricPrevious !== null) {
            previousAverageTime = Number((timeMetricPrevious / executionsMetricPrevious).toFixed(2));
        }        

        return (
            <div className="col flexlist-item">
                <div className="card">
                    <div className="card-body widget">
                        <div className="row g-0 align-items-center card-row">
                            <div className="icon averagetime" data-tip="The average execution time">
                                <span className="fal fa-clock fa-fw"></span>
                            </div>
                            <div className="col data">
                                Average Time
                            <div>
                                {
                                    averageTime !== null ? (
                                        <React.Fragment>
                                            {averageTime !== Infinity && averageTime !== -Infinity ? (
                                                Helper.getTimeInEnglish(averageTime)
                                            ) : (
                                                `0`
                                            )}
                                            {averageTime !== null && previousAverageTime !== null && !isNaN(averageTime) && !isNaN(previousAverageTime) && (
                                                <span className="comparison">
                                                    {averageTime === previousAverageTime ? (
                                                        <i className="fal fa-equals equals fa-fw"></i>
                                                    ) : (
                                                        averageTime > previousAverageTime ? (
                                                            <i className="fal fa-long-arrow-up fa-fw increase"></i>
                                                        ) : (
                                                            <i className="fal fa-long-arrow-up fa-fw decrease"></i>
                                                        )
                                                    )}
                                                    
                                                    {previousAverageTime !== Infinity && previousAverageTime !== -Infinity ? (
                                                        Helper.getTimeInEnglish(previousAverageTime)
                                                    ) : (
                                                        `0`
                                                    )}
                                                    
                                                    {averageTime !== null && averageTime !== Infinity && averageTime !== -Infinity && averageTime !== 0 && previousAverageTime !== null && previousAverageTime !== Infinity && previousAverageTime !== -Infinity && previousAverageTime !== 0 && (
                                                        <React.Fragment>
                                                            &nbsp;({Helper.getPercentageDifference(averageTime, previousAverageTime)}%)
                                                        </React.Fragment>
                                                    )}
                                                </span>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        executionsMetric === null ? (
                                            `-`
                                        ) : (
                                            <Skeleton />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [executionsMetric, executionsMetricPrevious, timeMetric, timeMetricPrevious]);

    return (
        metric
    );
}

export default AverageTimeStaticWidget;