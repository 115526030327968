import React, { useState, useEffect, useContext } from 'react';
import Helmet from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import StatisticApi from "../../api/host/StatisticApi";

// Types
import HostsDataTable from "../../types/host/tables/HostsDataTable";
import InstanceTargetHost from "../../types/instance/InstanceTargetHost";
import HostTarget from "../../types/host/HostTarget";
import ChartSeries from "../../types/ChartSeries";
import InstanceTarget from "../../types/instance/InstanceTarget";
import HostTimeStatistic from "../../types/host/HostTimeStatistic";
import HostStatistic from "../../types/host/HostStatistic";
import Period from "../../types/Period";

// Components
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ProcessorWidget from "../../component/host/widget/ProcessorWidget";
import MemoryWidget from "../../component/host/widget/MemoryWidget";
import DisksWidget from "../../component/host/widget/DisksWidget";
import Breadcrumb from "../../container/breadcrumb";
import HostsTable from "../../component/host/table/HostsTable";
import ConditionalRender from "../../helpers/ConditionalRender";

// Helpers
import Helper from "../../helpers/Helper";
import { chartOptionsGranularity } from "../../helpers/chartOptionsIncreasedGranularity";
import { getConvertedTimeToUTC, highchartsCredits } from "../../helpers/utils";

// Constants
import { CHART_DATA_TYPE, DATA_LOADED, DATA_LOADING } from "../../component/Constants";

// Contexts
import { TimeRangeContext } from "../../context/TimeRangeContext";
import { LicenceContext } from "../../context/LicenceContext";
import api from "../../api/Base";

interface IListProps {
    instances: InstanceTarget[],
    period: Period,
    hosts: HostTarget[],
    toggleMenu: Function,
    applyPeriod: Function
}

const List: React.FC<IListProps> = (props) => {
    const [loading, setLoading] = useState<number>(DATA_LOADING)
    const [avgChartLoading, setAvgChartLoading] = useState<boolean>(true)
    const [diskChartLoading, setDiskChartLoading] = useState<boolean>(true)
    const [tableDataLoading, setTableDataLoading] = useState<number>(DATA_LOADING)

    const [hostsDataTable, setHostsDataTable] = useState<HostsDataTable[]>([])

    const [peakProcessorSeries, setPeakProcessorSeries] = useState<number>(0)
    const [processorSeries, setProcessorSeries] = useState<ChartSeries[]>([])

    const [ioUtilisationSeries, setIoUtilisationSeries] = useState<number>(0)
    const [ioSeries, setIoSeries] = useState<ChartSeries[]>([])

    const [averageProcessorMetric, setAverageProcessorMetric] = useState<number>(0)
    const [previousAverageProcessorMetric, setPreviousAverageProcessorMetric] = useState<number | null>(0)
    const [averageMemoryMetric, setAverageMemoryMetric] = useState<number>(0)
    const [previousAverageMemoryMetric, setPreviousAverageMemoryMetric] = useState<number | null>(0)
    const [averageDiskMetric, setAverageDiskMetric] = useState<number | null>(0)
    const [previousAverageDiskMetric, setPreviousAverageDiskMetric] = useState<number | null>(0)

    // const [combinedChanges, setCombinedChanges] = useState<CombinedChanges[]>([]);
    // const [events, setEvents] = useState<Event[]>([])
    // const [changes, setChanges] = useState<Change[]>([])

    // const [chartPlotLines, setChartPlotLines] = useState<ChartPlotLine[]>([])

    const timeRangeContext = useContext(TimeRangeContext)
    const licences = useContext(LicenceContext)

    const { hosts } = props;

    const getHostsTableData = async() => {
        const { hosts, instances } = props;

        let instanceTargetHosts: InstanceTargetHost[] = [],
            hostsDataTable: HostsDataTable[] = [];

        // Get any instances related to this host.
        await api.get(`datasource/host`)
            .then((response: { data: InstanceTargetHost[]; }) => {

                instanceTargetHosts = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve list of instance hosts', error);
            })

        const processorPromises = []
        const memoryPromises = []
        const processorPeakPromises = []
        const ioUtilizationPromises = []

        // Loop through all the hosts, and get the initial required data.
        for (let index = 0; index < hosts.length; index++) {
            const host = hosts[index];

            processorPromises.push(StatisticApi.getStatistic(timeRangeContext, host.id, 'cpuutilisation'))
            memoryPromises.push(StatisticApi.getStatistic(timeRangeContext, host.id, 'memoryutilisation'))
            processorPeakPromises.push(StatisticApi.getStatisticPeak(timeRangeContext, host.id, 'cpuutilisation'))
            ioUtilizationPromises.push(StatisticApi.getStatistic(timeRangeContext, host.id, 'ioutilisation'))
        }

        const processResults = await Promise.all(processorPromises)
        const memoryResults = await Promise.all(memoryPromises)
        const processorPeakResults = await Promise.all(processorPeakPromises)
        const ioUtilizationResults = await Promise.all(ioUtilizationPromises)

        for (let index = 0; index < hosts.length; index++) {
            // Get the current host and a list of instance IDs linked to this host.
            const
                host = hosts[index],
                matchedInstanceIds = instanceTargetHosts.filter(item => Number(item.hostid) === Number(host.id));

            // Todo: Get any matched instances.
            let matchedInstances: InstanceTarget[] = [];

            for (let instanceIndex = 0; instanceIndex < matchedInstanceIds.length; instanceIndex++) {
                // Append any matched instances to the existing array.
                matchedInstances = [...matchedInstances, ...instances.filter(instance => Number(instance.id) === Number(matchedInstanceIds[instanceIndex].id))];
            }
            // Get average processor.
            const processor: number = processResults[index]
            // Get average memory.
            const memory: number = memoryResults[index]
            // Get peak processor.
            const processorPeak: number = processorPeakResults[index]
            // Get peak memory.
            const ioutilisation: number = ioUtilizationResults[index]

            hostsDataTable.push({
                id: host.id,
                name: host.name,
                type: host.type,
                processor,
                processorPeak,
                memory,
                ioutilisation,
                instances: matchedInstances
            });
        }
        setTableDataLoading(DATA_LOADED)
        setHostsDataTable(hostsDataTable)
    }

    const getProcessorAverage = async() => {

        let averageProcessorMetric: number = 0,
            previousAverageProcessorMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    averageProcessorMetric = Math.round(response.data[0].avg);
                }
            })

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {
            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=cpuutilisation`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageProcessorMetric = Math.round(response.data[0].avg);
                    }
                })
        }

        setAverageProcessorMetric(averageProcessorMetric)
        setPreviousAverageProcessorMetric(previousAverageProcessorMetric)

    }

    const getMemoryAverage = async() => {

        let averageMemoryMetric: number = 0,
            previousAverageMemoryMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=memoryutilisation`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    averageMemoryMetric = Math.round(response.data[0].avg);
                }
            })

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {
            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=memoryutilisation`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageMemoryMetric = Math.round(response.data[0].avg);
                    }
                })
        }

        setAverageMemoryMetric(averageMemoryMetric)
        setPreviousAverageMemoryMetric(previousAverageMemoryMetric)
    }

    const getDiskAverage = async() => {

        let averageDiskMetric: number = 0,
            previousAverageDiskMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=ioutilisation`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    averageDiskMetric = Math.round(response.data[0].avg);
                }
            })

        const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
        if (comparisonSupported) {

            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=ioutilisation`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageDiskMetric = Math.round(response.data[0].avg);
                    }
                })
        }

        setAverageDiskMetric(averageDiskMetric)
        setPreviousAverageDiskMetric(previousAverageDiskMetric)
    }

    // const getChanges = async(period: Period) => {
    //
    //     const allEvents: any[] = await EventsApi.getAllEventsChangesAndAlerts(timeRangeContext);
    //     let combinedChanges: CombinedChanges[] = [],
    //         chartPlotLines: ChartPlotLine[] = [];
    //
    //
    //     for (let index = 0; index < allEvents.length; index++) {
    //         const currentItem = allEvents[index];
    //         combinedChanges.push({
    //             id: index,
    //             instanceId: currentItem.hostid,
    //             timeslice: currentItem.timeslice,
    //             [currentItem.event]: currentItem
    //         });
    //     }
    //
    //     // Set the sort order for the combined changes and events.
    //     combinedChanges.sort(function compare(a, b) {
    //         var aa: number = dayjs(a.timeslice).valueOf();
    //         var bb: number = dayjs(b.timeslice).valueOf();
    //         return bb - aa;
    //     });
    //     chartPlotLines = Helper.getChartPlotLines(period, combinedChanges, period.ui.current.chartCategories);
    //     setCombinedChanges(combinedChanges)
    //     // setChartPlotLines(chartPlotLines)
    // }

    const getProcessorUsage = async() => {

        let processorSeries: any[] = [],
            processorSeriesPeak: number[] = [];

        for (let index = 0; index < props.hosts.length; index++) {

            const host = props.hosts[index];

            let processorData: number[] = [];

            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Add the chart series.
                    processorSeries.push({
                        name: host.name,
                        color: host.color,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.avg]
                        }),
                        zIndex: 1
                    });

                    response.data.map(item => {
                        return processorData.push(!item.avg ? 0 : Math.round(item.avg))
                    })

                })
                .catch((error: any) => {
                    console.log('An error occurred:', error);
                })
                .then(() => {

                    // Get the peak time spent value.
                    processorSeriesPeak.push(Helper.getPeakValue(processorData));
                });
        }

        const peakTimeSeries = Helper.getPeakValue(processorSeriesPeak);
        setProcessorSeries(processorSeries)
        setAvgChartLoading(false)
        setPeakProcessorSeries(Math.round(peakTimeSeries))
    }

    const getDiskUsage = async() => {

        let ioSeries: any[] = [],
            ioSeriesPeak: number[] = [];

        // Get time for the period for each instance.
        for (let index = 0; index < props.hosts.length; index++) {

            const host = props.hosts[index];

            let memoryData: number[] = [];

            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=ioutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Add the chart series.
                    ioSeries.push({
                        name: host.name,
                        color: host.color,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.avg]
                        }),
                        zIndex: 1
                    });
                    response.data.map(item => {
                        return memoryData.push(!item.avg ? 0 : Math.round(item.avg))
                    })

                })
                .catch((error: any) => {
                    console.log('An error occurred:', error);
                })
                .then(() => {

                    // Get the peak time spent value.
                    ioSeriesPeak.push(Helper.getPeakValue(memoryData));
                });
        }

        const ioUtilisationSeries = Helper.getPeakValue(ioSeriesPeak);
        setDiskChartLoading(false)
        setIoSeries(ioSeries)
        setIoUtilisationSeries(Math.round(ioUtilisationSeries))
    }

    const getData = async() => {
        const promiseArray = [getProcessorAverage(), getMemoryAverage(), getHostsTableData(), getDiskAverage(), getProcessorUsage(), getDiskUsage()]
        await Promise.all(promiseArray)
        setLoading(DATA_LOADED)
    }

    useEffect(() => {
        setLoading(DATA_LOADING)
        void getData();

    }, []);

    const refresh = () => {
        setLoading(DATA_LOADING)
        setHostsDataTable([])
        void getData();
    }

    const processorChartOptions = chartOptionsGranularity(props.applyPeriod, processorSeries, 100, {
        credits: { enabled: highchartsCredits(licences.licences) },
        legend: true,
        timeRangeContext: timeRangeContext,
        // plotLines: chartPlotLines,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
            },
        },
    }, 300)

    const ioChartOptions = chartOptionsGranularity(props.applyPeriod, ioSeries, 100, {
        credits: { enabled: highchartsCredits(licences.licences) },
        legend: true,
        timeRangeContext: timeRangeContext,
        // plotLines: chartPlotLines,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
            },
        },
    }, 300)

    // const changes = combinedChanges.filter(item => item.change);
    // const events = combinedChanges.filter(item => item.event);
    // const alerts = combinedChanges.filter(item => item.alert && item.instanceId);
    return (
        <div id="content">
            <Helmet>
                <title>Hosts - DBmarlin</title>
                <meta name="description" content="List of all monitored hosts with high-level performance statistics"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="Hosts" type="hosts">
                    <Link to="/hosts">Analysis</Link>
                    <NavLink to="/hosts" activeClassName={"current-breadcrumb-link"}>Hosts</NavLink>
                    <PeriodBreadcrumb/>
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={refresh}
                               applyPeriod={props.applyPeriod}/>
            </div>

            <div className="loader">
                <ConditionalRender if={loading === DATA_LOADING}>
                    <div className="bar"/>
                </ConditionalRender>
            </div>

            {/* Metric Widgets - Host */}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
                <ProcessorWidget metric={averageProcessorMetric} metricPrevious={previousAverageProcessorMetric}/>
                <MemoryWidget metric={averageMemoryMetric} metricPrevious={previousAverageMemoryMetric}/>
                <DisksWidget metric={averageDiskMetric} metricPrevious={previousAverageDiskMetric}/>
                {/*<LegacyChangesWidget changes={changes}*/}
                {/*                     alerts={alerts}*/}
                {/*                     events={events}/>*/}
            </div>

            {/* Charts */}
            <div className="row">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                            Top hosts over time
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseCharts" aria-expanded="false" aria-controls="collapseCharts"/>
                        </div>
                        <div id="collapseCharts" className="card-body collapse show p-0">
                            <div className="row row-cols-1 row-cols-lg-2">
                                <div className="col p-3 z-index-10">
                                    <h4>Average CPU<span className="peak">Peak: {peakProcessorSeries}%</span></h4>
                                    <ConditionalRender if={avgChartLoading}>
                                        <div className="w-100 text-center text-muted mt-3 time-chart">
                                            <div className="loader spinner">
                                            </div>
                                            <p className="mt-3">
                                                Loading data...
                                            </p>
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender if={!avgChartLoading}>
                                        <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                                         options={processorChartOptions}/>
                                    </ConditionalRender>
                                </div>
                                <div className="col p-3 border-start z-index-9">
                                    <h4>Disk I/O<span className="peak">Peak: {ioUtilisationSeries}%</span></h4>
                                    <ConditionalRender if={diskChartLoading}>
                                        <div className="w-100 text-center text-muted mt-3 time-chart">
                                            <div className="loader spinner">
                                            </div>
                                            <p className="mt-3">
                                                Loading data...
                                            </p>
                                        </div>
                                    </ConditionalRender>
                                    <ConditionalRender if={!diskChartLoading}>
                                        <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                                         options={ioChartOptions}/>
                                    </ConditionalRender>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Hosts */}
            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-server fa-fw" aria-hidden="true"/>
                            Monitored hosts
                            <span className="badge bg-info"
                                  data-tip="Total host count">{hosts.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseInstances" aria-expanded="false"
                               aria-controls="collapseInstances"/>
                        </div>
                        <div id="collapseInstances" className="card-body collapse show">
                            <HostsTable loading={tableDataLoading} hostsDataTable={hostsDataTable}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;
