import PropTypes from 'prop-types';

// Types.
import Period from '../../../types/Period';

// Components.
import ProgramsTable from '../table/ProgramsTable';

function Programs(props: { instanceId: number, batchId?: string, statementId?: string, period: Period, totalInstanceTime: number, setProgramsOptions: Function, setFilterOptions: Function }) {        
    return (
        <div id="programs" className="tab-pane fade" role="tabpanel" aria-labelledby="programs-tab">
            <ProgramsTable period={props.period} instanceId={props.instanceId} batchId={props.batchId} statementId={props.statementId} instanceTime={props.totalInstanceTime} setProgramsOptions={props.setProgramsOptions} setFilterOptions={props.setFilterOptions}/>
        </div>
    );
}

Programs.propTypes = {
    setProgramsOptions: PropTypes.func.isRequired
};

export default Programs;
