import { createContext, useContext } from 'react';

// Define the context type, including the update function.
export type ChatContextType = {
    isOpen: boolean;
    toggleChat: Function;
    chatData: any;
}

// Define the context object.
export const ChatContext = createContext<ChatContextType>({ isOpen: false, chatData: '', toggleChat: () => {}});
ChatContext.displayName = 'Chat';


export const useChat = () => {
    return useContext(ChatContext);
};