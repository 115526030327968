import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Types.
import { ActivityBatchGroup } from '../../../types/instance/Activity';
import GroupedBatchesDataTable from '../../../types/instance/tables/GroupedBatchesDataTable';
import PieChartSeries from '../../../types/PieChartSeries';
import Wait from '../../../types/instance/Wait';

// Helpers.
import Helper from '../../../helpers/Helper';

// Constants.
import { DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from '../../Constants';
import { CHART_COLOURS_STATEMENTS } from '../../Constants';

// Components.
import Alert from '../../Alert';
import GroupedBatchesTable from '../table/GroupedBatchesTable';
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import api from "../../../api/Base";

function GroupedBatches(props: {
    instanceId: number,
    statementId?: string,
    filtersParameters: string,
    totalInstanceTime?: number,
    batchStatementId?: string,
    waits?: Wait[],
    showByDefault: boolean,
    isBatchesForStatement: boolean,
    setBatchesOptions?: Function
}) {
    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [batchesDataTable, setBatchesTableData] = useState<GroupedBatchesDataTable[]>([]);
    const timeRangeContext = useContext(TimeRangeContext)

    const instanceId: number = props.instanceId;

    useMemo(() => {

        const getBatches = async() => {
            setLoading(DATA_LOADING);

            let dataTable: GroupedBatchesDataTable[] = [],
                pieChartSeries: PieChartSeries[] = [];

            let apiString = ''
            if (props.statementId !== undefined) {
                apiString = `&grouphash=${props.statementId}`
            }
            if (props.batchStatementId) {
                apiString = `&grouphash=${props.batchStatementId}`
            }

            await api.get(`activity/batch/group?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${instanceId}${apiString}${props.filtersParameters}`)
                .then(async(response: { data: ActivityBatchGroup[] }) => {
                    for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_API_LIMIT) - 1).length; index++) {
                        let batchwaittime: number = 0;

                        if (props.statementId !== undefined) {

                            // Get the total batch time without the statement parameter.
                            await api.get(`activity/summary?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&batchgrouphash=${response.data[index].batchgrouphash}${props.filtersParameters}`)
                                .then((batchResponse: { data: ActivityBatchGroup[], status: number }) => {
                                    if (batchResponse.status === 200 && batchResponse.data.length > 0) {
                                        batchwaittime = batchResponse.data[0].waittime;
                                    }
                                })
                                .catch((error: any) => {
                                    console.error('Failed to retrieve batch without statement ID.', error, response.data[index].batchgrouphash);
                                })
                                .then(function () {
                                });

                        }

                        dataTable.push({
                            id: index,
                            color: index < CHART_COLOURS_STATEMENTS.length ?
                                CHART_COLOURS_STATEMENTS[index] : '',
                            batchgrouphash: response.data[index].batchgrouphash,
                            sqltext: response.data[index].sqltext,
                            executions: response.data[index].batchexecutions,
                            averagetime: Number((response.data[index].waittime / Number(response.data[index].batchexecutions)).toFixed(2)),
                            waittime: response.data[index].waittime,
                            batchwaittime,
                            batch: response.data[index]
                        });
                    }

                    // Build top batches pie chart.
                    for (let index = 0; index < response.data.slice(0, Number(process.env.REACT_APP_CHART_SERIES_CEILING)).length; index++) {

                        let name: string = ((response.data[index].batchgrouphash === null) ? '-' : response.data[index].batchgrouphash);

                        if (name.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH)) {
                            name = `${name.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...`;
                        }

                        pieChartSeries.push({
                            color: CHART_COLOURS_STATEMENTS[index],
                            formatted: Helper.getTimeInEnglish(response.data[index].waittime),
                            name,
                            y: response.data[index].waittime,
                            url: ((response.data[index].batchgrouphash !== null && response.data[index].batchgrouphash !== '-') ? `/instances/${instanceId}/activity/groupedbatch/${response.data[index].batchgrouphash}` : null)
                        });
                    }


                    if (props.setBatchesOptions) {
                        // Return the total count and pie chart series back to the parent component.
                        props.setBatchesOptions.call(null, response.data.length, pieChartSeries);
                    }

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve batches.', error);
                })
                .then(function () {
                });

            // Mark the data as loaded.
            setLoading(DATA_LOADED);
            setBatchesTableData(dataTable);
        }

        if (loading === DATA_INITIALISING) {

            // Get the batches for the selected instance and period.
            getBatches();

        }

    }, [loading, timeRangeContext, instanceId, props]);

    return (
        <div id="groupedbatches"
             className={((props.showByDefault === true) ? 'tab-pane fade show active' : 'tab-pane fade')}
             role="tabpanel" aria-labelledby="batches-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-layer-group fa-fw" aria-hidden="true"/>
                    Grouped Batches
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseBatches" aria-expanded="false" aria-controls="collapseBatches"/>
                </div>
                <div id="collapseBatches" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === batchesDataTable.length - 1 && (
                        <Alert
                            message={`To help preserve performance, we limit the total number of batch records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their total wait time.`}
                            heading="Batch records" variant="alert-info"/>
                    )}
                    <GroupedBatchesTable filtersParameters={props.filtersParameters} loading={loading}
                                         instanceId={props.instanceId}
                                         instanceTime={props.totalInstanceTime || 1}
                                         waits={props.waits || []} batchesDataTable={batchesDataTable}
                                         isBatchesForStatement={props.isBatchesForStatement}/>
                </div>
            </div>
        </div>
    );
}

GroupedBatches.propTypes = {
    setBatchesOptions: PropTypes.func
};

export default GroupedBatches;
