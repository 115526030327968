import { Component } from 'react';
import { InstanceContext } from "../context/InstanceContext";
import api from "../api/Base";

// Types.
import Status from '../types/Status';
import InstanceTarget from '../types/instance/InstanceTarget';
import HostTarget from '../types/host/HostTarget';

// Constants.
import { TARGET_TYPE_DATABASE, TARGET_TYPE_HOST } from './Constants';

interface IStatusIconProps {
    instanceId?: number,
    removeFromList?: Function,
    hostId?: number,
    mode: string
}

interface IStatusIconState {
    status: Status
}

const initialState: IStatusIconState = {
    status: {
        type: TARGET_TYPE_DATABASE,
        sensorstatus: 200,
        message: 'OK',
        reason: '',
        updated: new Date(),
        timedout: false,
        state: 'Started',
        status: 200
    }
};

class StatusIcon extends Component<IStatusIconProps, IStatusIconState> {

    intervalTimerId: any | undefined;
    intervalTimer: number = 30000;
    static contextType = InstanceContext

    constructor(props: IStatusIconProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { mode } = this.props;

        if (mode === 'admin') {
            this.intervalTimer = 5000;
        }

        this.getData(true);
    }

    componentWillUnmount() {
        clearInterval(this.intervalTimerId);
    }

    getData(fromContext = false) {
        const { instanceId, hostId } = this.props;

        let successful: boolean = true;

        if (instanceId) {

            // Instance target status.
            successful = this.getInstanceStatus(instanceId, fromContext);
        }

        if (hostId) {

            // Host target status.
            successful = this.getHostStatus(hostId);
        }

        // Only set the interval timer if the API request to get the instance or host status was successful.
        if (successful) {

            // Repeat as per the defined timer interval.
            this.intervalTimerId = setTimeout(this.getData.bind(this), this.intervalTimer)
        }
    }

    getInstanceStatus(instanceId: number, fromContext: boolean) {
        let successful: boolean = true;

        if (fromContext) {
            let status: Status = {
                type: TARGET_TYPE_DATABASE,
                sensorstatus: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).sensorstatus,
                message: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).message,
                reason: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).reason,
                updated: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).updated,
                timedout: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).timedout,
                state: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).state,
                status: this.context.instances.find((instance: InstanceTarget) => instance.id === instanceId).status
            }
            this.setState({
                status
            });

            return successful
        }

        // Get database target status.
        api.get(`datasource?full=true&id=${instanceId}`)
            .then((response: { data: InstanceTarget[]; }) => {

                if (response.data.length) {
                    let status: Status = {
                        type: TARGET_TYPE_DATABASE,
                        sensorstatus: response.data[0].sensorstatus,
                        message: response.data[0].message,
                        reason: response.data[0].reason,
                        updated: response.data[0].updated,
                        timedout: response.data[0].timedout,
                        state: response.data[0].state,
                        status: response.data[0].status
                    }

                    this.setState({
                        status
                    });
                } else if (this.props.removeFromList) {
                    this.props.removeFromList(instanceId)
                }
            })
            .catch((error: any) => {

                successful = false;
                console.error('Failed to get instance status, stopping timer interval until a browser refresh has taken place.', error);
            })

        return successful;
    }

    getHostStatus(hostId: number) {

        let successful: boolean = true;

        // Get host target status.
        api.get(`host?id=${hostId}`)
            .then((response: { data: HostTarget[]; }) => {

                if (response.data.length) {
                    let status: Status = {
                        type: TARGET_TYPE_HOST,
                        sensorstatus: response.data[0].sensorstatus,
                        message: response.data[0].message,
                        reason: response.data[0].reason,
                        updated: response.data[0].updated,
                        timedout: response.data[0].timedout,
                        state: response.data[0].state,
                        status: response.data[0].status
                    }

                    this.setState({
                        status
                    });
                }

            })
            .catch((error: any) => {

                successful = false;

                console.error('Failed to get host status, stopping timer interval until a browser refresh has taken place.', error);
            })

        return successful;
    }

    render() {
        const { instanceId, mode } = this.props;
        const { status } = this.state;

        let className = 'fas fa-question-circle text-secondary fa-fw',
            tooltip = 'Health unknown';
        const reason: string = status.reason ? ' - ' + status.reason : '';

        if (mode.toLowerCase() === "admin") {

            if (status.state.toLowerCase() === 'started') {

                // Sensor is available - check the health status.
                if (status.status === 200) {

                    // Sensor is healthy.
                    className = 'fas fa-shield-check text-success fa-fw';
                    tooltip = `Sensor - ${status.state}${reason}`;
                } else {

                    // Sensor is unhealthy.
                    className = 'fas fa-question-circle text-danger fa-fw';
                    tooltip = `Sensor - ${status.message}`;
                }
            } else {

                // Sensor is unavailable.
                tooltip = `Sensor - ${status.state}${reason}`;
            }

        } else {
            if (status.state.toLowerCase() === 'started' && status.status === 200) {

                // Sensor is healthy.
                className = 'fas fa-shield-check text-success fa-fw';
                tooltip = ((instanceId) ? 'Instance health okay' : 'Host health okay');
            }
        }

        return (
            <i className={className} aria-hidden="true" data-tip={tooltip}></i>
        );
    }
}

export default StatusIcon;
