// React.
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Third-party packages.
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Types.
import Action from '../../../types/Action';
import Filter from '../../../types/Filter';

// Constants.
import { ACTIONS } from '../../../component/Constants';

// Context.
import { FilterContext } from '../../../context/FilterContext';

// Components.
import Alert from '../../../component/Alert';

// Helpers.
import { defaultTimeZone, fetchFilters, getParameter, sendData }
	from '../../../helpers/utils';

// Local constants.
const DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_ERROR = 'Sorry, but something went wrong and the requested action failed. If the issue persists, please raise a support request.';

// Local functions.
function formatDateTime (date: Dayjs, time: string): string
{
	return date.format (DATE_FORMAT) + '+' + time;
}

function makeParameters (fd: Dayjs, ft: string, td: Dayjs, tt: string): string
{
	return '?from=' + formatDateTime (fd, ft) +
		'&to=' + formatDateTime (td, tt) +
		'&tz=' + defaultTimeZone ();
}

function extendTimeString (value: string): string
{
	const extendedValue = '0000-01-01 00:00:00';

	if (value.length < extendedValue.length)
	{
		value += extendedValue.substring (value.length);
	}

	return value;
}

function getDate (date: string | null): Dayjs
{
	return date ? dayjs.default (date) : dayjs.default ();
}

function getTime (date: string | null): string
{
	return date ? extendTimeString (date).substring (11) : '00:00:00';
}

export default function Detail (props: { actionId: string, filterId?: number })
{
	const { actionId, filterId } = props;
	const { filters, setFilters } = useContext (FilterContext);
	const history = useHistory ();

	// Set the state.
	const [ action, setAction ] = useState<Action> (ACTIONS.CREATE);
	const [ isMissing, setIsMissing ] = useState<boolean> (false);
	const [ isError, setIsError ] = useState<boolean> (false);
	const [ error, setError ] = useState<string> (DEFAULT_ERROR);
	const [ isValid, setIsValid ] = useState<boolean> (true);
	const [ disabled, setDisabled ] = useState<boolean>
		(actionId === ACTIONS.DELETE.type);
	const [ id, setId ] = useState<number> (0);
	const [ name, setName ] = useState<string> ('');
	const [ description, setDescription ] = useState<string> ('');
	const [ fromDate, setFromDate ] = useState<Dayjs>
		(dayjs.default ().subtract (1, 'day'));
	const [ fromTime, setFromTime ] = useState<string> ('00:00:00');
	const [ toDate, setToDate ] = useState<Dayjs> (dayjs.default ());
	const [ toTime, setToTime ] = useState<string> ('00:00:00');
	const [ keepData, setKeepData ] = useState<boolean> (false);
	const [ advanced, setAdvanced ] = useState<boolean> (false);
	const [ parameters, setParameters ] = useState<string>
		(makeParameters (fromDate, fromTime, toDate, toTime));
	const [ nameUsed, setNameUsed ] = useState<boolean> (false);
	const [ nameClass, setNameClass ] = useState<string> ('');
	const [ inputClass, setInputClass ] = useState<string> ('');
	const [ parameterClass, setParameterClass ] = useState<string> ('');

	useEffect (() =>
	{
		const getFilter = (filterId: number) =>
		{
			const matchedFilters = filters.filter ((object: Filter) =>
				object.id === filterId);

			if (matchedFilters.length === 1)
			{
				const f: Filter = matchedFilters[0];
				const from: string | null = getParameter (f.parameters, 'from');
				const to: string | null = getParameter (f.parameters, 'to');
				const fromDate: Dayjs = getDate (from);
				const fromTime: string = getTime (from);
				const toDate: Dayjs = getDate (to);
				const toTime: string = getTime (to);
				setId (f.id);
				setName (f.name);
				setDescription (f.description || '');
				setFromDate (fromDate);
				setFromTime (fromTime);
				setToDate (toDate);
				setToTime (toTime);
				setKeepData (f.keepdata);
				setAdvanced (f.parameters !== makeParameters (fromDate,
					fromTime, toDate, toTime));
				setParameters (f.parameters);
			}
			else
			{
				setIsMissing (true);
			}
		}

		const getAction = () =>
		{
			switch (actionId)
			{
				case 'create':
				setAction (ACTIONS.CREATE);
				break;

				case 'edit':
				setAction (ACTIONS.UPDATE);
				break;

				case 'delete':
				setAction (ACTIONS.DELETE);
				setDisabled (true);
				break;

				default:
				console.error ('Unknown action requested', actionId);
				setIsError(true);
				break;
			}
		}

		getAction ();

		if (filterId)
		{
			getFilter (filterId);
		}

	}, [filters, actionId, filterId]);

	function isDuplicateName (value: string): boolean
	{
		return filters.findIndex ((object: Filter) => object.id !== id &&
			object.name.toLowerCase () === value.toLowerCase ()) >= 0;
	}

	function handleNameBlur ()
	{
		// Trim any white space.
		const value = name.trim ();

		// Save to state.
		setName (value);

		if (value === '')
		{
			setNameClass ('is-invalid');
			setNameUsed (false);
		}
		else if (isDuplicateName (value))
		{
			setNameClass ('is-invalid');
			setNameUsed (true);
		}
		else
		{
			setNameClass ('is-valid');
			setNameUsed (false);
		}
	}

	function handleParameterBlur ()
	{
		const from: Dayjs = dayjs.default (getParameter (parameters, 'from'));
		const to: Dayjs = dayjs.default (getParameter (parameters, 'to'));

		if (!from.isValid () || !to.isValid () || !from.isBefore (to))
		{
			setParameterClass ('is-invalid');
			return;
		}

		setParameterClass ('is-valid');
	}

	function changeFromDate (date: Date)
	{
		if (date === null)
		{
			setInputClass ('is-invalid');
			return;
		}

		const value: Dayjs = dayjs.default (date);

		if (!value.isValid ())
		{
			setInputClass ('is-invalid');
			return;
		}

		setFromDate (value);
		setAdvanced (false);
		setParameters (makeParameters (value, fromTime, toDate, toTime));
		setInputClass ('is-valid');
		setParameterClass ('is-valid');
	}

	function changeToDate (date: Date)
	{
		if (date === null)
		{
			setInputClass ('is-invalid');
			return;
		}

		const value: Dayjs = dayjs.default (date);

		if (!value.isValid ())
		{
			setInputClass ('is-invalid');
			return;
		}

		setToDate (value);
		setAdvanced (false);
		setParameters (makeParameters (fromDate, fromTime, value, toTime));
		setInputClass ('is-valid');
		setParameterClass ('is-valid');
	}

	function validateTime ()
	{
		// Validate the entered time values.
		if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(fromTime))
		{
			setInputClass ('is-invalid');
			return;
		}

		if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(toTime))
		{
			setInputClass ('is-invalid');
			return;
		}

		setInputClass ('is-valid');
	}

	function changeFromTime (value: string)
	{
		setFromTime (value);
		setAdvanced (false);
		setParameters (makeParameters (fromDate, value, toDate, toTime));
		setParameterClass ('is-valid');
	}

	function changeToTime (value: string)
	{
		setToTime (value);
		setAdvanced (false);
		setParameters (makeParameters (fromDate, fromTime, toDate, value));
		setParameterClass ('is-valid');
	}

	function toggleAdvanced ()
	{
		setAdvanced (!advanced);
	}

	function isValidForm (): boolean
	{
		return nameClass !== 'is-invalid' &&
			inputClass !== 'is-invalid' &&
			parameterClass !== 'is-invalid';
	}

	async function saveFilterAndRefreshList (method: string, data: any)
	{
		try
		{
			await sendData (method, '/filter', data);
			setFilters (await fetchFilters ());
			setIsError (false);
			setError (DEFAULT_ERROR);
			history.push ('/admin/filters');
		}
		catch (x: any)
		{
			// Error does not contain the status code...
			console.error (x);
			setIsError (true);
			setError (x.message);
		}
	}

	async function saveFilter ()
	{
		// Validate the form.
		if (!isValidForm ())
		{
			setIsValid (false);
			return;
		}

		setIsValid (true);

		const data =
		[{
			id,
			name,
			parameters,
			keepdata: keepData,
			description
		}];

		switch (action)
		{
			case ACTIONS.CREATE:
			await saveFilterAndRefreshList ('POST', data);
			break;

			case ACTIONS.UPDATE:
			await saveFilterAndRefreshList ('PUT', data);
			break;

			case ACTIONS.DELETE:
			await saveFilterAndRefreshList ('DELETE', data);
			break;

			default:
			// Unknown action requested.
			console.error ('Unknown action requested', actionId);
			setIsError (true);
			break;
		}
	}

	return (
		<div className="col">
			<div className="card">
				<div className="card-header">
					<i className="fal fa-camera-alt fa-fw mr-10" aria-hidden="true"></i>
					{action.title} Snapshot
				</div>
				<div className="card-body">
					{isMissing ? (
						<React.Fragment>
							<Alert message="The requested snapshot is either invalid or no longer available." heading="Invalid request" variant="alert-danger" />
							<div className="row g-0 form">
								<div className="col-xs-12 col-md-9 col-lg-6 actions">
									<Link to="/admin/filters" role="button" className="btn btn-secondary"><i className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
								</div>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							{action.type === 'delete' && (
								<Alert message="Please check and ensure you want to delete this snapshot." heading="Deletes are final and cannot be undone" variant="alert-warning" />
							)}

							{isError && (
								<Alert message={error} heading={`Failed to ${action.title.toLowerCase()} snapshot`} variant="alert-danger" />
							)}

							{!isValid && (
								<Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
							)}

							<p>Please complete the required details below:</p>

							<div className="row g-0 form">

								{/* Name */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="name" className="reminder" data-tip="The snapshot name">Name</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<input
										type="text"
										id="name"
										autoFocus
										className={`form-control ${nameClass}`}
										required
										maxLength={250}
										onBlur={handleNameBlur}
										onChange={e => setName(e.target.value)}
										value={name}
										disabled={disabled}
										data-lpignore={true}
									/>
									{nameUsed && (
										<small className="text-danger">Snapshot name already used.</small>
									)}
								</div>
								<div className="w-100"></div>

								{/* Description */}
								<div className="col-xs-12 col-md-3">
									<label htmlFor="description" className="reminder" data-tip="Description">Description</label>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<input
										type="text"
										id="description"
										className={`form-control`}
										maxLength={250}
										value={description}
										onChange={(e) => setDescription (e.target.value)}
										disabled={disabled}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* From Date */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="fromDate" className="reminder" data-tip="From date">From Date</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<DatePicker
										id="fromDate"
										className={`form-control ${inputClass}`}
										dateFormat="yyyy-MM-dd"
										placeholderText="yyyy-MM-dd"
										popperPlacement="top-end"
										selected={fromDate.toDate ()}
										onChange={(date: Date) => changeFromDate (date)}
										shouldCloseOnSelect={true}
										disabled={disabled || advanced}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* From Time */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="fromTime" className="reminder" data-tip="From time">From Time</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<input
										type="text"
										id="fromTime"
										className={`form-control ${inputClass}`}
										maxLength={8}
										placeholder="HH:MM:SS"
										value={fromTime}
										onBlur={validateTime}
										onChange={(e) => changeFromTime (e.target.value)}
										disabled={disabled || advanced}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* To Date */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="toDate" className="reminder" data-tip="To date">To Date</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<DatePicker
										id="toDate"
										className={`form-control ${inputClass}`}
										dateFormat="yyyy-MM-dd"
										placeholderText="yyyy-MM-dd"
										popperPlacement="top-end"
										selected={toDate.toDate ()}
										onChange={(date: Date) => changeToDate (date)}
										shouldCloseOnSelect={true}
										disabled={disabled || advanced}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* To Time */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="toTime" className="reminder" data-tip="To time">To Time</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<input
										type="text"
										id="toTime"
										className={`form-control ${inputClass}`}
										maxLength={8}
										placeholder="HH:MM:SS"
										value={toTime}
										onBlur={validateTime}
										onChange={(e) => changeToTime (e.target.value)}
										disabled={disabled || advanced}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* Keep Data */}
								<div className="col-xs-12 col-md-3">
									<label htmlFor="keepdata" className="reminder" data-tip="Keep data">Keep Data</label>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<div className="form-check form-switch">
										<input
											type="checkbox"
											id="keepdata"
											className="form-check-input"
											onChange={() => setKeepData (!keepData)}
											checked={keepData}
											disabled={disabled}
											data-lpignore={true}
										/>
									</div>
								</div>
								<div className="w-100"></div>

								{/* Advanced Mode */}
								<div className="col-xs-12 col-md-3">
									<label htmlFor="advanced" className="reminder" data-tip="Enable advanced mode">Advanced Mode</label>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox" id="advanced" checked={advanced} onChange={() => toggleAdvanced ()} disabled={disabled} />
									</div>
								</div>
								<div className="w-100"></div>

								{/* Parameters */}
								<div className="col-xs-12 col-md-3">
									<span className="required"><label htmlFor="parameters" className="reminder" data-tip="snapshot parameters">Parameters</label></span>
								</div>
								<div className="col-xs-12 col-md-9 col-lg-9">
									<input
										type="text"
										id="parameters"
										className={`form-control ${parameterClass}`}
										required
										maxLength={250}
										onBlur={handleParameterBlur}
										onChange={e => setParameters(e.target.value)}
										value={parameters}
										disabled={disabled || !advanced}
										data-lpignore={true}
									/>
								</div>
								<div className="w-100"></div>

								{/* Actions */}
								<div className="col-xs-12 col-md-12 col-lg-12 actions">
									<Link to="/admin/filters" role="button" className="btn btn-secondary"><i className="fal fa-times-circle fa-fw fa-sm"></i><span>Cancel</span></Link>
									<button className={`btn btn-${action.variant}`} onClick={saveFilter}><i className={`${action.icon} fa-fw fa-sm`}></i><span>{action.title}</span></button>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	)
}
