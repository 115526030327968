import { useEffect, useMemo, useState } from 'react';
import { archiverUrl, fetchResults, fetchWithAuthorization } from "../../../helpers/utils";
import { Link, useHistory } from "react-router-dom";
import api from "../../../api/Base";
import NoData from "../../../component/NoData";
import { DATA_LOADED, DATA_LOADING } from "../../../component/Constants";

const Details = (props: { instance: string }) => {
    const [sqlExtendedOptionsList, setSqlExtendedOptionsList] = useState<any[][]>([]);
    const [loading, setLoading] = useState<number>(DATA_LOADING);
    const history = useHistory();

    useEffect(() => {
        const getCollectStatisticsList = async() => {
            const results: any[][] = await fetchResults(
                [fetchWithAuthorization(archiverUrl() + `/sql/statistic/type?type=${props.instance}`)]);
            setLoading(DATA_LOADED);
            return results[0]
        }

        getCollectStatisticsList().then(setSqlExtendedOptionsList)
    }, []);

    const updateSqlExtendedOptionsList = (value: string, type: string) => {
        const list = JSON.parse(JSON.stringify(sqlExtendedOptionsList))
        const findIemToChange = list.find((item: { statistic: string; type: string; }) => item.statistic === value && item.type === type)
        findIemToChange.collect = !findIemToChange.collect
        setSqlExtendedOptionsList(list)
    }

    const updateExtendedStatistic = async() => {
        let url = `/sql/statistic/type`;
        const result = await api.post(url, sqlExtendedOptionsList)
        if (result && result.status === 200) {
            history.push('/admin/database-extended-statistics');
        }
    }

    const renderedList = useMemo(() => {
        return sqlExtendedOptionsList?.map((metric: any[any], i) =>
            <div key={i}>
                <input className="form-check-input" type="checkbox" id={`option_${i}`}
                       checked={metric.collect}
                       // Temp Fix
                       disabled={metric.statistic === 'executions' || metric.statistic === 'duration'}
                       onChange={() => updateSqlExtendedOptionsList(metric.statistic, metric.type)}
                />
                <label htmlFor={`option_${i}`}>{metric.statistic}</label>
            </div>)

    }, [sqlExtendedOptionsList])

    const checkUncheckAll = (checkState: boolean) => {
        const list = JSON.parse(JSON.stringify(sqlExtendedOptionsList))
        list.forEach((item: { collect: boolean; }) => {
            item.collect = checkState
        })
        setSqlExtendedOptionsList(list)
    }


    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                    Update SQL Statistics

                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseDetails" aria-expanded="false" aria-controls="collapseDetails"/>
                </div>
                <div className={'form'}>
                    <div id="collapseDetails" className="card-body collapse show">
                        <div className='select-all'>
                            <span onClick={() => checkUncheckAll(true)}>Select All</span><label className='separator'>|</label><span onClick={() => checkUncheckAll(false)}>Deselect All</span>
                        </div>
                        <div className='option-list'>
                            {renderedList}
                        </div>
                        {/* Form Actions */}
                        <div className="col-12 actions with-text">
                            <div />
                            <span>When updates are complete, please restart one sensor of this technology type to ensure changes take effect</span>
                            <div>
                                <Link to="/admin/database-extended-statistics" role="button" className="btn btn-secondary">
                                    <i className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span>
                                </Link>
                                <button onClick={() => updateExtendedStatistic()} type='submit' className='btn btn-success'>
                                    <i className='fal fa-save fa-sm'/><span>Update</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <NoData
                    error={null}
                    loading={loading === DATA_LOADING}
                    length={sqlExtendedOptionsList.length}
                />
            </div>
        </div>
    )
}

export default Details;
