// React.
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-parties.
import * as dayjs from 'dayjs';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { CSVLink } from 'react-csv';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { SqlStatisticsSummary } from '../../../types/instance/SqlStatistics';

// Components.
import Alert from "../../Alert";
import NoData from "../../NoData";
import TablePagination from "../../TablePagination";
import StatementToolTip from "./StatementToolTip";

// Helper.
import Helper from '../../../helpers/Helper';
import {
    archiverUrl, capitalizeString,
    fetchResults, fetchWithAuthorization,
    getDefaultPageSize, getToolTipPosition,
} from '../../../helpers/utils';
import { getAPIString, getStatementTypeFromURL } from "../../../views/instances/tabs/utils";

// Constants.
import { CHART_COLOURS_STATEMENTS, INSTANCE_TYPE_COCKROACHDB } from "../../Constants";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import api from '../../../api/Base';

export default function StatementSqlStatisticsTable(props:
                                               {
                                                   instance: InstanceTarget,
                                                   statementId?: string,
                                                   batchStatementId?: string,
                                                   filterParameters?: string
                                               }) {
    const [sqlStatistics, setSqlStatistics] =
        useState<SqlStatisticsSummary[]>([]);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [supportedStatistics, setSupportedStatistics] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [statements, setStatements] = useState<string[]>([]);
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    const isCockroachDb = props.instance.type === INSTANCE_TYPE_COCKROACHDB
    const hiddenColumnsList = isCockroachDb ? ['logical_writes', 'logical_reads', 'physical_reads', 'cpu_time_millisconds'] : []
    const timeRangeContext = useContext(TimeRangeContext)
    const statementType = getStatementTypeFromURL()
    const sqlHashString = statementType.isGroup ? 'grouphash' : 'sqlhash'

    const extraColumns = [
        {
            label: 'CPU Time',
            key: 'cpu_time_milliseconds'
        },
        {
            label: 'Physical Reads',
            key: 'physical_reads'
        },
        {
            label: 'Logical Reads',
            key: 'logical_reads'
        },
        {
            label: 'Logical Writes',
            key: 'logical_writes'
        }]

    // CSV headers
    let headers: any[] = useMemo(() => {
        const defaultHeaders = [
            {
                label: 'SQL Hash',
                key: sqlHashString
            },
            {
                label: 'Statement Text',
                key: 'sqltext'
            }
        ]
        supportedStatistics.forEach(item => {
            defaultHeaders.push({
                label: capitalizeString(item),
                key: item
            })
        })
        return defaultHeaders
    }, [supportedStatistics]);

    if (!isCockroachDb) {
        headers = [...headers, ...extraColumns];
    }

    // Table columns
    const columns: Column[] = useMemo(() => {
            const defaultColumns = [
                {
                    Header: '#',
                    accessor: sqlHashString,
                    headerClassName: 'text',
                    canSort: true,
                    sortDescFirst: false,
                    sortType: 'basic',
                    Cell: ({cell}: any) => {
                        // const hideTab = (props.instance.type === INSTANCE_TYPE_ORACLE || props.instance.type === INSTANCE_TYPE_DB2) ?  '' : '?hide=activity'
                        return (statementType.isGroup ? cell.row.values[sqlHashString] : <Link to={`/instances/${props.instance.id}/activity/statement/${cell.row.values[sqlHashString]}/sql-statistics`}>{cell.row.values[sqlHashString]}</Link>)
                    }
                },
                {
                    Header: 'Statement',
                    accessor: 'sqltext',
                    headerClassName: 'text',
                    className: 'concatenate',
                    canSort: true,
                    sortDescFirst: false,
                    sortType: 'basic',
                    Cell: ({cell}: any) =><Link to={`/instances/${props.instance.id}/activity/statement/${cell.row.values[sqlHashString]}/text`}>{cell.row.values.sqltext.substring(0, 200)}</Link>
                },
                {
                    Header: 'Duration',
                    accessor: 'duration',
                    headerClassName: 'text-end',
                    className: 'text-end',
                    canSort: true,
                    sortDescFirst: true,
                    sortType: 'basic',
                    Cell: ({cell}: any) =>
                        (Helper.getTimeInEnglish(cell.row.values.duration))
                },
                {
                    Header: 'Executions',
                    accessor: 'executions',
                    headerClassName: 'text-end',
                    className: 'text-end',
                    canSort: true,
                    sortDescFirst: true,
                    sortType: 'basic',
                    Cell: ({cell}: any) =>
                        (Helper.getFormattedNumber(cell.row.values.executions))
                },
                {
                    Header: 'Avg. Duration',
                    accessor: 'average_duration',
                    headerClassName: 'concatenate-medium text-end',
                    className: 'concatenate-medium text-end',
                    canSort: true,
                    sortType: 'basic',
                    sortDescFirst: true,
                    Cell: ({cell}: any) => cell.row.values.average_duration ? Helper.getTimeInEnglish(cell.row.values.average_duration) : ''
                },
            ]
            supportedStatistics.forEach(item => {
                if (item !== 'executions' && item !== 'duration') {
                    defaultColumns.push({
                        Header: capitalizeString(item),
                        accessor: item,
                        headerClassName: 'text-end',
                        className: 'text-end',
                        canSort: true,
                        sortDescFirst: true,
                        sortType: 'basic',
                        Cell: ({cell}) => {
                            return cell.row.values[item] ? cell.row.values[item].toFixed(2).replace(/\.?0+$/, '') : '0'
                        }
                    })
                }
            })
            return defaultColumns
        },
        [props.instance.id, props.instance.type, supportedStatistics]);


    useEffect(() => {
        async function getStatements() {
            const apiString = getAPIString(props.statementId, props.batchStatementId)
            // Get statements.
            api.get(`activity/sql?limit=${process.env.REACT_APP_API_LIMIT}&${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${props.instance.id}${apiString}${props.filterParameters}`)
                .then((response: { data: any[]; }) => {

                    let statementsList = [];

                    // Build top statements pie chart.
                    for (let index = 0; index < response.data.length; index++) {

                        if (index < CHART_COLOURS_STATEMENTS.length) {
                            // Set the top colours for the chart.
                            response.data[index].color = CHART_COLOURS_STATEMENTS[index];
                        }
                        statementsList.push(response.data[index][sqlHashString]);
                    }
                    setStatements(statementsList)
                })
        }
        void getStatements()
        },
        [timeRangeContext, props.instance.id, props.instance.type,
            props.statementId]);

    useEffect(() => {
            async function load() {
                try {
                    setLoading(true);
                    const apiString = statementType.isGroup ? 'group' : 'sql'
                    const results: any[][] = await fetchResults(
                        [
                            fetchWithAuthorization(archiverUrl(2) + `/sql/statistic/${apiString}?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instance.id}&type=${props.instance.type}&sort=duration+desc&${sqlHashString}=${props.batchStatementId || props.statementId}`)
                        ]);
                    const supportedStatistics: any[][] = await fetchResults(
                        [
                            fetchWithAuthorization(archiverUrl(1) + `/sql/statistic/name?type=${props.instance.type}&collect=true`)
                        ]);
                    if (active) {
                        const resultsWithAverage = Helper.addAverageAndColor('duration', 'executions', results[0], 0)
                        // setSupportedStatistics(supportedStatistics[0]);
                        // let filteredList: any[] = []
                        // if (resultsWithAverage.length) {
                        //
                        // }
                        // resultsWithAverage.forEach(item => {
                        //     if (statements.includes(item.sqlhash)){
                        //         filteredList.push(item)
                        //     }
                        // })
                        // // const filteredList = props.statementId ? resultsWithAverage.filter(item => item.sqlhash === props.statementId) : resultsWithAverage
                        //
                        // setSqlStatistics(filteredList);
                        // setLoading(false);
                        setSupportedStatistics(supportedStatistics[0]);
                        // const filteredList = props.statementId ? resultsWithAverage.filter(item => item[sqlHashString] === props.statementId) : resultsWithAverage
                        setSqlStatistics(resultsWithAverage);
                        setLoading(false);
                    }
                } catch (x: any) {
                    console.log(x.message);
                    setError(x.message);
                    setLoading(false);
                    setSqlStatistics([]);
                }
            }

            let active: boolean = true;
            load();
            return () => {
                active = false;
            }
        },
        [statements]);

    const data = useMemo(() =>
            sqlStatistics.filter((row) =>
                row.sqltext.toLowerCase().includes(tableFilter.toLowerCase()) ||
                // @ts-ignore
                row[sqlHashString].toLowerCase().includes(tableFilter.toLowerCase())),
        [sqlStatistics, tableFilter]);

    const csvData = useMemo(() => {
        // Escape embedded double-quotation marks (") in the SQL text.
        const csvData: any[] = JSON.parse(JSON.stringify(data));

        csvData.forEach((item) => {
            item.sqltext = item.sqltext.replaceAll('"', '""');
        });

        return csvData;
    }, [data]);

    const
        {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            setPageSize,
            pageOptions,
            pageCount,
            canPreviousPage,
            previousPage,
            canNextPage,
            nextPage,
            gotoPage,
            state: {pageIndex, pageSize}
        } = useTable(
            {
                columns,
                data,
                initialState:
                    {
                        hiddenColumns: hiddenColumnsList,
                        pageIndex: 0,
                        pageSize: getDefaultPageSize()
                    },
                disableSortRemove: true
            }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }

    // The bracket must be present and on the same line as the return.
    return (
        <div className="card">
            <div className="card-header">
                <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                {statementType.isGroup ? 'Grouped Statement' : 'Statements'}
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseStatements" aria-expanded="false" aria-controls="collapseStatements"/>
            </div>
            <div id="collapseStatements" className="card-body collapse show">
                {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 &&
                    (<Alert
                        message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by the number of executions.`}
                        heading="Statement records" variant="alert-info"/>)}
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 headers={headers}
                                 data={csvData}
                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - SQL Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table">
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={(column as any).headerClassName}>
                                        {column.render('Header')}

                                        {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                <i className="fal fa-sort-amount-up-alt"></i> :
                                                <i className="fal fa-sort-amount-down-alt"></i>) :
                                            <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className={(cell.column as any).className}>
                                                {cell.column.id === "sqltext" ?
                                                    <div className='tooltip-scroll ellipsis'
                                                         onMouseEnter={(event) => setVisibleToolTip({
                                                             id: cell.row.values[sqlHashString],
                                                             position: getToolTipPosition(event)
                                                         })}
                                                         onMouseLeave={() => setVisibleToolTip({
                                                             id: '',
                                                             position: 0
                                                         })}
                                                    >
                                                        {cell.render('Cell')}
                                                        {(cell.row.values[sqlHashString] === visibleToolTip.id &&
                                                            <StatementToolTip instanceType={props.instance.type}
                                                                              position={visibleToolTip.position}
                                                                              id={cell.row.values[sqlHashString]}
                                                                              link={`/instances/${props.instance.id}/activity/statement/${cell.row.values[sqlHashString]}/text`}
                                                                              cell={cell}/>
                                                        )}
                                                    </div> : cell.render('Cell')}

                                            </td>)
                                    })}
                                </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                <NoData error={error} loading={loading}
                        length={data.length}/>
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </div>
    )
}
