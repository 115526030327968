// Components.
import ConditionalRender from '../../helpers/ConditionalRender';

// Helpers.
import { formatNumber } from '../../helpers/utils';

export default function ExecutionsStatistic (props:
	{ current: number, previous: number | null })
{
	const { current, previous } = props;
	const percentDiff = previous !== null ?
		Math.round ((current - previous) / previous * 100.0) : null;

	return (<>
		<span className="table-metric-value" >
			{formatNumber (current)}
		</span>
		{previous !== null && (
			<span className="comparison">
				{formatNumber (previous)}
				<ConditionalRender if={percentDiff !== null} >
					<span>({percentDiff}%)</span>
				</ConditionalRender>
				<ConditionalRender if={current === previous} >
					<i className="fal fa-equals equals fa-fw" />
				</ConditionalRender>
				<ConditionalRender if={current > previous} >
					<i className="fal fa-long-arrow-up fa-fw increase" />
				</ConditionalRender>
				<ConditionalRender if={current < previous} >
					<i className="fal fa-long-arrow-up fa-fw decrease" />
				</ConditionalRender>
			</span>
		)}
	</>)
}
