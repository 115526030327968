import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Third-party packages.
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import * as objectSupport from 'dayjs/plugin/objectSupport';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Helper.
import Helper from '../helpers/Helper';
import { determineTimeUnit } from "../helpers/utils";

// Types.
import Period from '../types/Period';

// Constants.
import {
    INTERVAL_10_SECONDS,
    INTERVAL_15_SECONDS,
    INTERVAL_5_SECONDS,
    INTERVAL_FIFTEEN_MINUTES,
    INTERVAL_FIVE_MINUTES,
    INTERVAL_MINUTE,
    INTERVAL_ONE_DAY,
    INTERVAL_ONE_HOUR,
    INTERVAL_ONE_WEEK,
    INTERVAL_SECOND,
    INTERVAL_SIX_HOURS,
    PERIOD_CUSTOM,
    PERIOD_DAY_BEFORE_YESTERDAY,
    PERIOD_FIVE_MINUTES,
    PERIOD_MINUTE,
    PERIOD_ONE_HOUR,
    PERIOD_PREVIOUS_WEEK,
    PERIOD_SEVEN_DAYS,
    PERIOD_SIX_HOURS,
    PERIOD_TEN_MINUTES,
    PERIOD_THIRTY_MINUTES,
    PERIOD_THIRTY_ONE_DAYS,
    PERIOD_THIS_WEEK,
    PERIOD_TODAY,
    PERIOD_TWELVE_HOURS,
    PERIOD_TWENTY_FOUR_HOURS,
    PERIOD_YESTERDAY,
    periodToValidIntervals,
    REPORT_PERIOD_CURRENT
} from './Constants';
import { TimeRangeContext } from "../context/TimeRangeContext";
import { useHistory } from 'react-router-dom';
import ConditionalRender from "../helpers/ConditionalRender";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(objectSupport.default);

function PeriodSelector(props: { period: Period, applyPeriod: Function }) {
    const timeRangeContext = useContext(TimeRangeContext)
    const [periodOption, setPeriodOption] = useState<number>(props.period.api.periodOption);
    const [fmDate, setFmDate] = useState<Dayjs>(timeRangeContext.timeRange.from);
    const [toDate, setToDate] = useState<Dayjs>(timeRangeContext.timeRange.to);
    const [inputClass, setInputClass] = useState<string>('');
    const savedTimeZone = window.localStorage.getItem('dbmar-timeZone')
    const timeZoneValue = savedTimeZone ? JSON.parse(savedTimeZone) : Intl.DateTimeFormat().resolvedOptions().timeZone
    const [selectedTimezone, setSelectedTimezone] = useState<string>(timeZoneValue)

    const [toTime, setToTime] = useState<string>(timeRangeContext.timeRange.to.format('HH:mm'));
    const [fmTime, setFmTime] = useState<string>(timeRangeContext.timeRange.from.format('HH:mm'));

    const agg = JSON.parse(window.localStorage.getItem('dbmar-interval') || '0')
    const aggValid = Helper.aggregateIsValid(agg, timeRangeContext)
    const [aggregate, setAggregate] = useState<number>(aggValid ? agg : 0);
    const history = useHistory();

    const clearParams = () => {
        const { pathname, search } = history.location;
        const urlSearchParams = new URLSearchParams(search);

        // Remove the specified params
        urlSearchParams.delete('fm');
        urlSearchParams.delete('tz');
        urlSearchParams.delete('to');
        urlSearchParams.delete('interval');

        // Reconstruct the URL with updated search params
        const newSearch = urlSearchParams.toString();
        const newUrl = pathname + (newSearch ? `?${newSearch}` : '');

        // Replace the current URL
        history.replace(newUrl);
    };

    function validatePeriod() {
        // Validate the entered time values.
        if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(fmTime)) {
            setInputClass('is-invalid');
            return;
        }

        if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(toTime)) {
            setInputClass('is-invalid');
            return;
        }
        let from = buildTime(fmDate, fmTime),
            to = buildTime(toDate, toTime);


        if (!from.isValid() || !to.isValid()) {
            setInputClass('is-invalid');
            return;
        }

        if (from.valueOf() >= to.valueOf()) {

            setInputClass('is-invalid');
            return;
        }

        setInputClass('');
    }

    function clearStyle(id: string): void {
        const element = document.getElementById(id);

        if (element) {
            element.classList.remove('show');
            element.setAttribute('aria-expanded', 'false');
        }
    }

    function closePeriodDropdown() {
        clearStyle('period');
        clearStyle('periodDropdown');
    }

    function updatePeriod(option: number, timeZone?: string) {
        // The period selector opens by toggling CSS. It does not close when
        // one of the predefined values are chosen. Close using JavaScript.
        // In contrast, React requires the parent view to be temporarily
        // different, while it is loading, in order to force the period
        // selector to be recreated in the closed state. Every view would need
        // to do this. It also appears quite jarring.
        closePeriodDropdown();
        const currentTimeZone = timeZone || selectedTimezone

        let to = dayjs.default(),
            from = to;

        // Set the from and to state values.
        switch (option) {
            case PERIOD_MINUTE:
                from = to.subtract(1, 'minute');
                break;
            case PERIOD_FIVE_MINUTES:
                from = to.subtract(5, 'minute');
                break;
            case PERIOD_TEN_MINUTES:
                from = to.subtract(10, 'minute');
                break;
            case PERIOD_THIRTY_MINUTES:
                from = to.subtract(30, 'minute');
                break;
            case PERIOD_ONE_HOUR:
                to = to.second(0)
                from = to.subtract(1, 'hour')
                break;
            case PERIOD_SIX_HOURS:
                to = to.second(0)
                from = to.subtract(6, 'hour')
                break;
            case PERIOD_TWELVE_HOURS:
                to = to.second(0);
                from = to.subtract(12, 'hour')
                break;
            case PERIOD_TWENTY_FOUR_HOURS:
                to = to.second(0);
                from = to.subtract(24, 'hour')
                break;
            case PERIOD_SEVEN_DAYS:
                from = to.subtract(7, 'day');
                break;
            case PERIOD_THIRTY_ONE_DAYS:
                from = to.subtract(31, 'day');
                break;
            case PERIOD_TODAY:
                from = to.startOf('day');
                to = from.add(1, 'day').startOf('day');
                break;
            case PERIOD_YESTERDAY:
                from = to.subtract(1, 'day').startOf('day');
                to = from.add(1, 'day').startOf('day');
                break;
            case PERIOD_DAY_BEFORE_YESTERDAY:
                from = to.subtract(2, 'day').startOf('day');
                to = from.add(1, 'day').startOf('day');
                break;
            case PERIOD_THIS_WEEK:
                from = to.startOf('week');
                to = from.add(1, 'week');
                break;
            case PERIOD_PREVIOUS_WEEK:
                from = to.subtract(1, 'week').startOf('week');
                to = from.add(1, 'week');
                break;
            default:
                break;
        }

        const format: string = ((option === PERIOD_MINUTE || option === PERIOD_FIVE_MINUTES) ? 'HH:mm:ss' : 'HH:mm');

        // Set the selected period option and defined period.
        setPeriodOption(option);
        setFmDate(dayjs.tz(from, currentTimeZone));
        setFmTime(from.format(format));
        setToDate(dayjs.tz(to, currentTimeZone));
        setToTime(to.format(format));

        // timeRangeContext.setTimeRange({
        //     from: from,
        //     to: to,
        //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //     interval: 0
        // })

        clearParams()
        window.localStorage.setItem('dbmar-interval', '0')
        let updatedFrom = dayjs.tz(from, currentTimeZone)
        let updatedTo = dayjs.tz(to, currentTimeZone)

        // don't apply timezone if is a day or more days
        if (option === PERIOD_TODAY || option === PERIOD_YESTERDAY || option === PERIOD_DAY_BEFORE_YESTERDAY || option === PERIOD_THIS_WEEK || option === PERIOD_PREVIOUS_WEEK) {
            updatedFrom = from
            updatedTo = to
        }
        // DBMAR-276 - auto apply pre-defined time period.
        props.applyPeriod(option, updatedFrom, updatedTo, null, currentTimeZone);
    }

    const updateAggregateOnPeriodChange = () => {
        window.localStorage.setItem('dbmar-interval', '0')
        setAggregate(0)
    }

    function setPeriod() {

        // Validate the entered time values.
        if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(fmTime)) {
            setInputClass('is-invalid');
            return;
        }

        if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(toTime)) {
            setInputClass('is-invalid');
            return;
        }
        let from = buildTime(fmDate, fmTime),
            to = buildTime(toDate, toTime);


        if (!from.isValid() || !to.isValid()) {
            setInputClass('is-invalid');
            return;
        }

        if (from.valueOf() >= to.valueOf()) {
            setInputClass('is-invalid');
            return;
        }

        setInputClass('');

        // timeRangeContext.setTimeRange({
        //     from: from,
        //     to: to,
        //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //     interval: aggregate
        // })
        window.localStorage.setItem('dbmar-interval', JSON.stringify(aggregate))
        clearParams()

        props.applyPeriod(periodOption, from, to, null, selectedTimezone);
    }

    const applyTimeZone = (value: string) => {
        window.localStorage.setItem('dbmar-timeZone', JSON.stringify(value))
        setSelectedTimezone(value)
        updatePeriod(PERIOD_ONE_HOUR, value)
    }

    function buildTime(date: Dayjs, time: string) {
        // const splitTime = time.split(':');

        // Construct a new Dayjs object with the combined date and time
        return dayjs.default(date.format('YYYY-MM-DD') + 'T' + time);
    }


    // A minute: INTERVAL_SECOND, INTERVAL_5_SECONDS, INTERVAL_15_SECONDS
    // More than a minute less(or equal) than 15 minutes: INTERVAL_SECOND, INTERVAL_5_SECONDS, INTERVAL_15_SECONDS, INTERVAL_MINUTE
    // More than 15 minutes less(or equal) than an hour: INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES
    // More than an hour less(or equal) than a day: INTERVAL_MINUTE, INTERVAL_FIVE_MINUTES, INTERVAL_FIFTEEN_MINUTES, INTERVAL_ONE_HOUR
    // More than a day less(or equal) than a week: INTERVAL_ONE_HOUR, INTERVAL_SIX_HOURS, INTERVAL_ONE_DAY
    // More than a week: INTERVAL_SIX_HOURS, INTERVAL_ONE_DAY, INTERVAL_ONE_WEEK

    const aggregateDisabled = (aggregateValue: number) => {
        const differenceInMilliseconds = timeRangeContext.timeRange.to.diff(timeRangeContext.timeRange.from, 'milliseconds');
        const getTimePeriod = determineTimeUnit(differenceInMilliseconds)
        return !periodToValidIntervals[getTimePeriod].includes(aggregateValue)
    }

    useEffect(() => {
        if (aggregateDisabled(aggregate)) {
            window.localStorage.setItem('dbmar-interval', '0')
            setAggregate(0)
        }
        setToTime(props.period.ui.current.to.format('HH:mm'))
        setFmTime(props.period.ui.current.from.format('HH:mm'))
    }, [timeRangeContext])


    const setDateValue = (date: Date, type: string) => {
        const validDate = date || new Date()
        if (type === 'to') {
            setToDate(dayjs.default(validDate))
        } else {
            setFmDate(dayjs.default(validDate))
        }
        updateAggregateOnPeriodChange()
    }


    return (
        <React.Fragment>
            {/*  data-bs-offset="0,20" */}
            <button id="period" type="button" className="btn btn btn-dropdown dropdown-toggle btn-heading"
                    data-bs-toggle="dropdown" aria-expanded="false" data-class="text-center"
                    data-tip={Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_CURRENT)}>
                <div className="fal fa-clock fa-fw" aria-hidden="true"/>
                <div className="period">
                    {props.period.ui.heading}
                    <div>{props.period.ui.subheading}</div>
                </div>
            </button>
            <div id="periodDropdown" className="dropdown-menu period-dropdown-menu" aria-labelledby="period">
                <div className="row g-0 heading">
                    <div className="col-xs-12 col-lg-5">
                        <i className="fal fa-clock fa-fw" aria-hidden="true"/>
                        Custom Time Range
                    </div>
                    <div className="col-xs-12 col-lg-7 d-none d-lg-block">
                        <i className="fal fa-history fa-fw" aria-hidden="true"/>
                        Time Presets
                    </div>
                </div>
                <div className="row g-0" onClick={(e) => e.stopPropagation()}>
                    {/* Custom Period */}
                    <div className="col-xs-12 col-lg-5 border-lg-end pt-2">
                        <div className="row row-cols-1 g-0">
                            <div className="col px-3 px-md-2">
                                <div className="row g-0">
                                    <div className="col subheading">
                                        From
                                    </div>
                                    <div className="w-100"/>
                                    <div className="col">
                                        <label htmlFor="fmDate">Date</label>
                                    </div>
                                    <div className="col">
                                        {fmDate.isValid() && toDate.isValid() && (
                                            <DatePicker
                                                id="fmDate"
                                                className={`form-control form-control-sm ${inputClass}`}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText="YYYY/MM/DD"
                                                maxDate={toDate.toDate()}
                                                popperPlacement="top-end"
                                                selected={fmDate.toDate()}
                                                onCalendarClose={() => setPeriodOption(PERIOD_CUSTOM)}
                                                onChange={(date: Date) => setDateValue(date, 'from')}
                                                shouldCloseOnSelect={true}/>
                                        )}
                                    </div>
                                    <div className="w-100 py-1"/>
                                    <div className="col">
                                        <label htmlFor="fmTime">Time</label>
                                    </div>
                                    <div className="col">
                                        <input type="text"
                                               id="fmTime"
                                               className={`form-control form-control-sm ${inputClass}`}
                                               maxLength={5}
                                               placeholder="HH:MM"
                                               value={fmTime}
                                               onBlur={validatePeriod}
                                               onChange={(e) => {
                                                   updateAggregateOnPeriodChange()
                                                   setFmTime(e.target.value);
                                                   setPeriodOption(PERIOD_CUSTOM)
                                               }
                                               }/>
                                    </div>
                                    <div className="w-100 py-1"/>
                                    <div className="col subheading">
                                        To
                                    </div>
                                    <div className="w-100"/>
                                    <div className="col">
                                        <label htmlFor="toDate">Date</label>
                                    </div>
                                    <div className="col">
                                        {fmDate.isValid() && toDate.isValid() && (
                                            <DatePicker
                                                id="toDate"
                                                className={`form-control form-control-sm ${inputClass}`}
                                                dateFormat="yyyy/MM/dd"
                                                minDate={fmDate.toDate()}
                                                placeholderText="YYYY/MM/DD"
                                                popperPlacement="top-end"
                                                selected={toDate.toDate()}
                                                onCalendarClose={() => setPeriodOption(PERIOD_CUSTOM)}
                                                onChange={(date: Date) => setDateValue(date, 'to')}/>
                                        )}
                                    </div>
                                    <div className="w-100 py-1"/>
                                    <div className="col">
                                        <label htmlFor="toTime">Time</label>
                                    </div>
                                    <div className="col">
                                        <input type="text"
                                               id="toTime"
                                               className={`form-control form-control-sm ${inputClass}`}
                                               maxLength={5}
                                               placeholder="HH:MM"
                                               value={toTime}
                                               onBlur={validatePeriod}
                                               onChange={(e) => {
                                                   setToTime(e.target.value);
                                                   updateAggregateOnPeriodChange()
                                                   setPeriodOption(PERIOD_CUSTOM)
                                               }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-5 border-top">
                                <div className="row g-0 heading">
                                    <div className="">
                                        <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                                        Custom Interval
                                    </div>
                                </div>

                                <div className="p-3">
                                    <select className="form-select"
                                            aria-label="Select Aggregate"
                                            value={aggregate}
                                            onChange={(e) => {
                                                setAggregate(parseInt(e.target.value))
                                            }}>
                                        <option value={0}>Auto</option>
                                        <option disabled={aggregateDisabled(INTERVAL_SECOND)} value={INTERVAL_SECOND}>1
                                            second
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_5_SECONDS)}
                                                value={INTERVAL_5_SECONDS}>5 seconds
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_10_SECONDS)}
                                                value={INTERVAL_10_SECONDS}>10 seconds
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_15_SECONDS)}
                                                value={INTERVAL_15_SECONDS}>15 seconds
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_MINUTE)} value={INTERVAL_MINUTE}>1
                                            Minute
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_FIVE_MINUTES)}
                                                value={INTERVAL_FIVE_MINUTES}>5 Minutes
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_FIFTEEN_MINUTES)}
                                                value={INTERVAL_FIFTEEN_MINUTES}>15 Minutes
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_ONE_HOUR)}
                                                value={INTERVAL_ONE_HOUR}>1 hour
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_SIX_HOURS)}
                                                value={INTERVAL_SIX_HOURS}>6 hours
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_ONE_DAY)}
                                                value={INTERVAL_ONE_DAY}>1 day
                                        </option>
                                        <option disabled={aggregateDisabled(INTERVAL_ONE_WEEK)}
                                                value={INTERVAL_ONE_WEEK}>1 week
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col p-2 text-end border-top">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => closePeriodDropdown()}>Cancel
                                </button>
                                <button type="button" className="btn btn-primary ms-1" disabled={inputClass !== ''}
                                        onClick={setPeriod}>Apply
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Predefined Periods */}
                    <div className="col-xs-12 col-lg-7">
                        <div className="row row-cols-xs-1 row-cols-sm-1 g-0 pb-lg-2">
                            <div className="col-12 ps-lg-2 heading d-lg-none">
                                <i className="fal fa-history fa-fw" aria-hidden="true"/>
                                Time Presets
                            </div>
                            <div className="col-xs-12 col-lg-6 pt-lg-2 px-2">
                                <ul className="list-group list-group-flush">
                                    <li className={"list-group-item" + (periodOption === PERIOD_MINUTE ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_MINUTE)}>Last minute
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_FIVE_MINUTES ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_FIVE_MINUTES)}>Last 5
                                        minutes
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_TEN_MINUTES ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_TEN_MINUTES)}>Last 10
                                        minutes
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_THIRTY_MINUTES ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_THIRTY_MINUTES)}>Last 30
                                        minutes
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_ONE_HOUR ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_ONE_HOUR)}>Last hour
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_SIX_HOURS ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_SIX_HOURS)}>Last 6 hours
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_TWELVE_HOURS ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_TWELVE_HOURS)}>Last 12
                                        hours
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_TWENTY_FOUR_HOURS ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_TWENTY_FOUR_HOURS)}>Last 24
                                        hours
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_SEVEN_DAYS ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_SEVEN_DAYS)}>Last 7 days
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_THIRTY_ONE_DAYS ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_THIRTY_ONE_DAYS)}>Last 31
                                        days
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xs-12 col-lg-6 pt-lg-2 px-2">
                                <ul className="list-group list-group-flush">
                                    <li className={"list-group-item border-top border-top-lg-none" + (periodOption === PERIOD_TODAY ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_TODAY)}>Today
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_YESTERDAY ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_YESTERDAY)}>Yesterday
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_DAY_BEFORE_YESTERDAY ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_DAY_BEFORE_YESTERDAY)}>Day
                                        Before Yesterday
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_THIS_WEEK ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_THIS_WEEK)}>This Week
                                    </li>
                                    <li className={"list-group-item" + (periodOption === PERIOD_PREVIOUS_WEEK ? ' active' : '')}
                                        role="button" onClick={() => updatePeriod(PERIOD_PREVIOUS_WEEK)}>Previous
                                        Week
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ConditionalRender if={Intl.DateTimeFormat().resolvedOptions().timeZone !== 'UTC'}>
                        <div className="timezone-selector d-flex border-top align-items-center">
                            <label className='col text-end px-2'>Selected TimeZone: </label>
                            <select className="form-select col"
                                    value={selectedTimezone}
                                    onChange={(e) => applyTimeZone(e.target.value)}>
                                <option value='UTC'>
                                    UTC
                                </option>
                                <ConditionalRender if={Intl.DateTimeFormat().resolvedOptions().timeZone !== 'UTC'}>
                                    <option value={Intl.DateTimeFormat().resolvedOptions().timeZone}>
                                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                                    </option>
                                </ConditionalRender>
                            </select>
                        </div>
                    </ConditionalRender>
                </div>
            </div>
        </React.Fragment>
    );
}

PeriodSelector.propTypes = {
    applyPeriod: PropTypes.func.isRequired
};

export default PeriodSelector;
