import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-party packages.

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Context.
import { EventTypeContext } from '../../../context/EventTypeContext';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import EventType from '../../../types/instance/EventType';
import Change from '../../../types/instance/Change';
import Event from '../../../types/instance/Event';
import CombinedChanges from '../../../types/instance/CombinedChanges';
import Alert from '../../../component/Alert';

// Constants.
import { INTERVAL_MINUTE } from '../../../component/Constants';
import { REPORT_PERIOD_PREVIOUS, REPORT_PERIOD_CURRENT } from '../../../component/Constants';
import api from '../../../api/Base';

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

function ComparisonChangesTable(props: { period: Period, matchedInstances: InstanceTarget[], firstCombinedChanges: CombinedChanges[], secondCombinedChanges: CombinedChanges[] }) {
    const { eventTypes } = useContext(EventTypeContext);
    const [interimChanges, setInterimChanges] = useState<CombinedChanges[]>([]);

    useMemo(() => {

        async function getInterimChanges() {

            if (props.period === null) {

                // The period needs to be set...
                return [];
            }
    
            // Get interim changes.
            let combined: CombinedChanges[] = [];
    
            // Get auto-detected changes for first instance.
            await api.get(`change?limit=${process.env.REACT_APP_API_LIMIT}&from=${props.period.api.previous.to}&to=${props.period.api.current.from}&tz=${props.period.api.timezone}&interval=0&sort=timeslice+desc&limit=5000&id=${props.matchedInstances[0].id}`)
                .then((response: { data: Change[]; }) => {
                    for (let index = 0; index < response.data.length; index++) {
        
                        let change: Change = response.data[index];
        
                        change.id = props.matchedInstances[0].id;
                        change.instanceName = props.matchedInstances[0].name;
        
                        combined.push({
                            id: index,
                            instanceId: change.id,
                            timeslice: change.timeslice,
                            change
                        });
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve interim changes for the first instance', error);
                })
                .then(function () {
                });
    
            await api.get(`event?limit=${process.env.REACT_APP_API_LIMIT}&from=${props.period.api.previous.to}&to=${props.period.api.current.from}&tz=${props.period.api.timezone}&interval=0&sort=startDateTime+desc&limit=5000&id=${props.matchedInstances[0].id}`)
                .then((response: { data: Event[]; }) => {
                    for (let index = 0; index < response.data.length; index++) {
        
                        // Get the event type ID.
                        let event: Event = response.data[index];
        
                        // Find the matching event type.
                        const matchedEventType: EventType[] = eventTypes.filter(item => {
                            return item.eventTypeId === event.eventTypeId
                        });
            
                        if (matchedEventType.length > 0) {
                            event.eventType = matchedEventType[0];
                        }
        
                        event.databaseTargetId = props.matchedInstances[0].id;
                        event.databaseTargetName = props.matchedInstances[0].name;
        
                        if (event.endDateTime !== null) {
                            event.isPlotband = true;
                        }
        
                        combined.push({
                            id: index,
                            instanceId: event.databaseTargetId,
                            timeslice: event.startDateTime,
                            event
                        });
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve interim events for the first instance', error);
                })
                .then(function () {
                });
    
            if (props.matchedInstances[0].id !== props.matchedInstances[1].id) {
    
                // Get auto-detected changes and registered events for second instance.
                await api.get(`change?limit=${process.env.REACT_APP_API_LIMIT}&from=${props.period.api.previous.to}&to=${props.period.api.current.from}&tz=${props.period.api.timezone}&interval=0&sort=timeslice+desc&limit=5000&id=${props.matchedInstances[1].id}`)
                    .then((response: { data: Change[] }) => {
                        for (let index = 0; index < response.data.length; index++) {
            
                            let change: Change = response.data[index];
            
                            change.id = props.matchedInstances[1].id;
                            change.instanceName = props.matchedInstances[1].name;
            
                            combined.push({
                                id: index,
                                instanceId: change.id,
                                timeslice: change.timeslice,
                                change
                            });
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve interim changes for the second instance', error);
                    })
                    .then(function () {
                    });
    
                await api.get(`event?limit=${process.env.REACT_APP_API_LIMIT}&from=${props.period.api.previous.to}&to=${props.period.api.current.from}&tz=${props.period.api.timezone}&interval=0&sort=startDateTime+desc&limit=5000&id=${props.matchedInstances[1].id}`)
                    .then((response: { data: Event[] }) => {
                        for (let index = 0; index < response.data.length; index++) {
            
                            // Get the event type ID.
                            let event: Event = response.data[index];
            
                            // Find the matching event type.
                            const matchedEventType: EventType[] = eventTypes.filter(item => {
                                return item.eventTypeId === event.eventTypeId
                            });
                
                            if (matchedEventType.length > 0) {
                                event.eventType = matchedEventType[0];
                            }
            
                            event.databaseTargetId = props.matchedInstances[1].id;
                            event.databaseTargetName = props.matchedInstances[1].name;
            
                            if (event.endDateTime !== null) {
                                event.isPlotband = true;
                            }
            
                            combined.push({
                                id: index,
                                instanceId: event.databaseTargetId,
                                timeslice: event.startDateTime,
                                event
                            });
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve interim events for the second instance', error);
                    })
                    .then(function () {
                    });
            }
    
            // Set the sort order for the combined changes and events.
            combined.sort(function compare(a, b) {
                var aa: number = dayjs.default(a.timeslice).valueOf();
                var bb: number = dayjs.default(b.timeslice).valueOf();
                return bb - aa;
            });

            setInterimChanges(combined.slice(0, Number(process.env.REACT_APP_API_LIMIT)));            
        }
        
        getInterimChanges();

    }, [props.period, props.matchedInstances, eventTypes]);

    return (
        (props.firstCombinedChanges.length > 0 || props.secondCombinedChanges.length > 0 || interimChanges.length > 0) ? (
            <React.Fragment>
                <h3 id="changes">Change and events history</h3>
                <div className="row g-0 row-cols-1 w-100">
                    <ul id="menu-inner" className="col nav nav-tabs menu-inner-report" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a id="previous-tab" data-bs-toggle="tab" href="#changes-previous" className="active" role="tab" aria-controls="changes-previous" aria-selected="true">{Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_PREVIOUS)}</a>
                            <span className="badge bg-info" data-tip="Total period A change count">{Helper.getFormattedNumber(props.firstCombinedChanges.length)}</span>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="current-tab" data-bs-toggle="tab" href="#changes-current" role="tab" aria-controls="changes-current" aria-selected="true">{Helper.getFormattedPeriodHeading(props.period, REPORT_PERIOD_CURRENT)}</a>
                            <span className="badge bg-info" data-tip="Total period B change count">{Helper.getFormattedNumber(props.secondCombinedChanges.length)}</span>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a id="interim-tab" data-bs-toggle="tab" href="#changes-interim" role="tab" aria-controls="changes-interim" aria-selected="true">Interim Changes</a>
                            <span className="badge bg-info" data-tip="Total interim change count">{Helper.getFormattedBadgeCount(interimChanges.length)}</span>
                        </li>
                    </ul>
                    <div className="col">
                        <div className="tab-content">
                            <div id="changes-previous" className="tab-pane fade active show" role="tabpanel" aria-labelledby="changes-previous">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Change Detected</th>
                                            <th scope="col">Instance</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.firstCombinedChanges.map((item: CombinedChanges, index) => (
                                            <tr key={index}>
                                                {item.change ? (
                                                    <React.Fragment>
                                                        <th scope="row">
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{dayjs.default(item.change.timeslice).format('YYYY-MM-DD HH:mm')}</Link>
                                                        </th>
                                                        <th>
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{item.change.instanceName}</Link>
                                                        </th>
                                                        <td>
                                                            {item.change.changetype.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
                                                        </td>
                                                        <td>
                                                            {item.change.objectname}
                                                        </td>
                                                    </React.Fragment>
                                                ) : (
                                                        item.event && (
                                                            <React.Fragment>
                                                                <th scope="row">
                                                                    {item.event.detailsUrl ? (
                                                                        item.event.isPlotband ? (
                                                                            <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                        ) : (
                                                                                <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                            )
                                                                    ) : (
                                                                            item.event.isPlotband ? (
                                                                                <React.Fragment>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</React.Fragment>
                                                                            ) : (
                                                                                dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')
                                                                                )
                                                                        )}
                                                                </th>
                                                                <th>
                                                                    <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{item.event.databaseTargetName}</Link>
                                                                </th>
                                                                <td>
                                                                    {item.event.eventType ? (
                                                                        <React.Fragment>
                                                                            {item.event.eventType.title}
                                                                            {item.event.eventType.htmlIconCode && (
                                                                                <i className={`${item.event.eventType.htmlIconCode} ms-2`} aria-hidden="true"></i>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <React.Fragment>Unknown Event Type</React.Fragment>
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {item.event.detailsUrl ? (
                                                                        <React.Fragment>
                                                                            <a href={item.event.detailsUrl} target="_blank" rel="noopener noreferrer">{item.event.title}</a><sup className="fal fa-external-link fa-fw"></sup>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            item.event.title
                                                                        )}
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {props.firstCombinedChanges.length === 0 && (
                                    <div className="w-100 text-center text-muted my-3">
                                        <i className="fal fa-ban fa-fw fa-2x"></i>
                                        <p>No Changes or Events Found</p>
                                    </div>
                                )}
                            </div>
                            <div id="changes-current" className="tab-pane fade" role="tabpanel" aria-labelledby="changes-current">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Change Detected</th>
                                            <th scope="col">Instance</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.secondCombinedChanges.map((item: CombinedChanges, index) => (
                                            <tr key={index}>
                                                {item.change ? (
                                                    <React.Fragment>
                                                        <th scope="row">
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{dayjs.default(item.change.timeslice).format('YYYY-MM-DD HH:mm')}</Link>
                                                        </th>
                                                        <th>
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{item.change.instanceName}</Link>
                                                        </th>
                                                        <td>
                                                            {item.change.changetype.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
                                                        </td>
                                                        <td>
                                                            {item.change.objectname}
                                                        </td>
                                                    </React.Fragment>
                                                ) : (
                                                        item.event && (
                                                            <React.Fragment>
                                                                <th scope="row">
                                                                    {item.event.detailsUrl ? (
                                                                        item.event.isPlotband ? (
                                                                            <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                        ) : (
                                                                                <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                            )
                                                                    ) : (
                                                                            item.event.isPlotband ? (
                                                                                <React.Fragment>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</React.Fragment>
                                                                            ) : (
                                                                                dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')
                                                                                )
                                                                        )}
                                                                </th>
                                                                <th>
                                                                    <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{item.event.databaseTargetName}</Link>
                                                                </th>
                                                                <td>
                                                                    {item.event.eventType ? (
                                                                        <React.Fragment>
                                                                            {item.event.eventType.title}
                                                                            {item.event.eventType.htmlIconCode && (
                                                                                <i className={`${item.event.eventType.htmlIconCode} ms-2`} aria-hidden="true"></i>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <React.Fragment>Unknown Event Type</React.Fragment>
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {item.event.detailsUrl ? (
                                                                        <React.Fragment>
                                                                            <a href={item.event.detailsUrl} target="_blank" rel="noopener noreferrer">{item.event.title}</a><sup className="fal fa-external-link fa-fw"></sup>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            item.event.title
                                                                        )}
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {props.secondCombinedChanges.length === 0 && (
                                    <div className="w-100 text-center text-muted my-3">
                                        <i className="fal fa-ban fa-fw fa-2x"></i>
                                        <p>No Changes or Events Found</p>
                                    </div>
                                )}
                            </div>
                            <div id="changes-interim" className="tab-pane fade" role="tabpanel" aria-labelledby="changes-interim">
                                {Number(process.env.REACT_APP_API_LIMIT) - 1 === interimChanges.length -1 && (
                                    <Alert message={`To help preserve performance, we limit the total number of interim changes below to the ${Number(process.env.REACT_APP_API_LIMIT) - 1} most recent.`} heading="Changes and events" variant="alert-info" />
                                )}
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Change Detected</th>
                                            <th scope="col">Instance</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {interimChanges.map((item: CombinedChanges, index) => (
                                            <tr key={index} className="highlight">
                                                {item.change ? (
                                                    <React.Fragment>
                                                        <th scope="row">
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{dayjs.default(item.change.timeslice).format('YYYY-MM-DD HH:mm')}</Link>
                                                        </th>
                                                        <th>
                                                            <Link to={`/instances/${item.change.id}/activity${Helper.getQueryString(props.period, dayjs.default(item.change.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(item.change.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'), INTERVAL_MINUTE)}`}>{item.change.instanceName}</Link>
                                                        </th>
                                                        <td>
                                                            {item.change.changetype.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
                                                        </td>
                                                        <td>
                                                            {item.change.objectname}
                                                        </td>
                                                    </React.Fragment>
                                                ) : (
                                                        item.event && (
                                                            <React.Fragment>
                                                                <th scope="row">
                                                                    {item.event.detailsUrl ? (
                                                                        item.event.isPlotband ? (
                                                                            <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                        ) : (
                                                                                <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')}</Link>
                                                                            )
                                                                    ) : (
                                                                            item.event.isPlotband ? (
                                                                                <React.Fragment>{dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')} to {dayjs.default(item.event.endDateTime).format('YYYY-MM-DD HH:mm')}</React.Fragment>
                                                                            ) : (
                                                                                dayjs.default(item.event.startDateTime).format('YYYY-MM-DD HH:mm')
                                                                                )
                                                                        )}
                                                                </th>
                                                                <th>
                                                                    <Link to={`/instances/${item.event.databaseTargetId}/activity`}>{item.event.databaseTargetName}</Link>
                                                                </th>
                                                                <td>
                                                                    {item.event.eventType ? (
                                                                        <React.Fragment>
                                                                            {item.event.eventType.title}
                                                                            {item.event.eventType.htmlIconCode && (
                                                                                <i className={`${item.event.eventType.htmlIconCode} ms-2`} aria-hidden="true"></i>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            <React.Fragment>Unknown Event Type</React.Fragment>
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {item.event.detailsUrl ? (
                                                                        <React.Fragment>
                                                                            <a href={item.event.detailsUrl} target="_blank" rel="noopener noreferrer">{item.event.title}</a><sup className="fal fa-external-link fa-fw"></sup>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                            item.event.title
                                                                        )}
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {interimChanges.length === 0 && (
                                    <div className="w-100 text-center text-muted my-3">
                                        <i className="fal fa-ban fa-fw fa-2x"></i>
                                        <p>No Changes or Events Found</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment></React.Fragment>
        )
    );
}

export default ComparisonChangesTable;