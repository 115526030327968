import { useState, useEffect, useRef } from 'react';
import './style.scss';
import ConditionalRender from "../../helpers/ConditionalRender";
import ReactMarkdown from 'react-markdown';

import OpenAI from 'openai';


type ChatMessage = {
    sender: string;
    message: string;
};

const DBMARLIN_AI = 'DBmarlin Co-pilot'

const ChatComponent = (props: { data: string }) => {
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState(props.data);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const chatContainerRef = useRef<HTMLDivElement>(null);

    const [newAPIKEY, setNewAPIKEY] = useState('');
    let userSavedKey = window.localStorage.getItem('openAIKEY');

    const [savedKey, setSavedKey] = useState('');
    const [error, setError] = useState<string>('')
    let userSavedModel = window.localStorage.getItem('openAIModel');
    const [openAiModel, setOpenAiModel] = useState<string>(userSavedModel || 'gpt-4-turbo')
    // const [copied, setCopied] = useState(false);

    const [agreeTerms, setAgreeTerms] = useState(false);

    useEffect(() => {
        let decodedKey = '';
        let termsAgreed = window.localStorage.getItem('openAITerms')
        if (userSavedKey) {
            try {
                decodedKey = atob(userSavedKey);
            } catch (error) {
                console.error('Error decoding or parsing the key:', error);
            }
        }
        setSavedKey(decodedKey)
        setAgreeTerms(termsAgreed ? JSON.parse(termsAgreed) : false)
    }, []);


    const saveUiModel = (value: string) => {
        setOpenAiModel(value)
        window.localStorage.setItem('openAIModel', value)
    }
    useEffect(() => {
        if (props.data) {
            void askGPT(props.data);
        }
    }, [props.data]);


    const deleteKey = () => {
        setNewAPIKEY('')
        setAgreeTerms(false)
        setSavedKey('')
        setError('')
        userSavedKey = ''
        window.localStorage.setItem('openAIKEY', '')
        window.localStorage.setItem('openAITerms', '')
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
        }, 100); // Adjust the delay as needed (in milliseconds)
    };
    const askGPT = async(defaultQuestion?: string, apiKey? : string) => {
        appendMessage('You', defaultQuestion || question);
        appendMessage(DBMARLIN_AI, '');
        scrollToBottom();

        const promptMessages = chatMessages.map(({sender, message}) => (
            sender === 'You' ? `You: ${message}` : `${DBMARLIN_AI}: ${message}`
        ));
        const prompt = `${promptMessages.join('\n')}\n\n${defaultQuestion || question}`;

        if (savedKey || apiKey) {
            setLoading(true);
            const openAiKey = savedKey.toString() || apiKey?.toString()
            const openai = new OpenAI({
                apiKey: openAiKey,
                dangerouslyAllowBrowser: true
            });

            await openai.chat.completions.create({
                messages: [{role: 'user', content: prompt}],
                model: openAiModel,
            }).then((res: any) => {
                setQuestion('');
                setLoading(false);
                deleteEmptyMessage()
                appendMessage(DBMARLIN_AI, res.choices[0].message.content);
                scrollToBottom();
            })
                .catch((e) => {
                    setLoading(false);
                    deleteEmptyMessage()
                    setError(e.message)
                })
        }
    };

    const appendMessage = (sender: string, message: string) => {
        setChatMessages((prevMessages) => [...prevMessages, {sender, message}]);
    };

    const deleteEmptyMessage = () => {
        setChatMessages((prevMessages) => prevMessages.slice(0, -1));
    };


    const saveAPIKey = async() => {
        const errorMessage = await validateApiKey(newAPIKEY)
        if (!errorMessage) {
            const encodedKey = btoa(newAPIKEY);
            window.localStorage.setItem('openAIKEY', encodedKey)
            window.localStorage.setItem('openAIModel', openAiModel)
            setSavedKey(newAPIKEY)
            setQuestion('')
            setChatMessages([])
            setError('')
            if (props.data) {
                return askGPT(props.data, newAPIKEY);
            }
        } else {
            setError(errorMessage)
        }
    };

    const clearHistory = () => {
        setChatMessages([])
        setQuestion('')
        setError('')
    }

    const validateApiKey = async(key: string) => {
        let validMessage = ''
        const openai = new OpenAI({
            apiKey: key,
            dangerouslyAllowBrowser: true
        });

        await openai.chat.completions.create({
            messages: [{role: 'user', content: 'Hi'}],
            model: openAiModel,
        }).then((res: any) => {
            if (res.status === 200) {
                validMessage = ''
            }
        }).catch((e) => {
            validMessage = e.message
        })
        return validMessage
    }


    const terms = <>
        <p>Before you proceed, we would like you to read, and agree to the following:</p>
        <ol>
            <li><strong>Company Policy. </strong>Any use you may make of our OpenAI integration is in
                accordance with your organisation’s policies.
            </li>
            <li><strong>Testing. </strong>In accordance with best practice, we strongly recommend that you
                evaluate any recommendations, and test to your own satisfaction, before deploying in a
                production environment.
            </li>
            <li><strong>Consequence. </strong>DBmarlin Co-pilot, through its use of OpenAI, may make
                suggestions that have further consequences, for example, on your database licensing. If you
                choose to follow those recommendations, then it is your own responsibility to evaluate the
                consequential impact of such recommendations.
            </li>
            <li><strong>No Liability. </strong>OpenAI does not guarantee the accuracy of its
                recommendations. DBmarlin cannot take any responsibility or liability for any changes that
                you choose to make as a result.
            </li>
        </ol>
    </>

    return (
        <>
            <ConditionalRender if={!savedKey}>
                <div className="api-key-container">
                    <div className='ai-logo'/>
                    <div className='ai-heading'>DBmarlin Co-pilot</div>
                    <p><strong>DBmarlin Co-pilot</strong> makes use of <strong>OpenAI</strong> which requires you to
                        enter your unique API key.
                        Currently, this needs to support the model <strong>{openAiModel}</strong> which requires an
                        appropriate plan.
                        You can obtain your API key by visiting <a target='_blank'
                                                                   href='https://platform.openai.com/account/api-keys'>https://platform.openai.com/account/api-keys</a> and
                        navigating to the "Developer" or "API" section. If you haven't registered yet, you'll find a
                        straightforward process to generate your exclusive key. For your security: </p>
                    <ol>
                        <li><strong>Local Storage Only: </strong>Your API key will only be stored locally within your
                            browser, in an encrypted format. We do not save or access your key on any external servers.
                        </li>
                        <li><strong>Never Share Your Key: </strong>As is usual, do not share your key with anyone, and
                            be cautious of phishing attempts or unauthorised requests.
                        </li>
                        <li><strong>Secure Input Process: </strong>Please enter your API key in the secure input field
                            provided.
                        </li>
                        <li><strong>Security Best Practices: </strong>Regularly monitor and review the usage of your API
                            key to detect any unusual activity.
                        </li>
                    </ol>

                    <div className="col-lg-12 d-flex align-items-center justify-content-end">
                        Change Open Ai model:&nbsp;
                        <select
                            id="type"
                            autoFocus
                            className={`form-control w-25 mr-10`}
                            onChange={e => saveUiModel(e.target.value)}
                            value={openAiModel}
                        >
                            <option value='gpt-4-turbo'>gpt-4-turbo</option>
                            <option value='gpt-4o'>gpt-4o</option>
                            <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
                        </select>
                    </div>
                    < hr/>
                    {terms}

                    <div className="col-12">
                        <div className="form-check form-switch text-center">
                            <input
                                type="checkbox"
                                id="terms"
                                onChange={() => {
                                    setAgreeTerms(!agreeTerms)
                                    window.localStorage.setItem('openAITerms', 'true')
                                }}
                                className='pointer'
                                checked={agreeTerms}
                                data-lpignore={true}
                            />
                            <label htmlFor='terms' className="reminder pointer user-select-none">&nbsp;I accept these
                                conditions</label>
                        </div>
                    </div>
                    <ConditionalRender if={error}>
                        <p className='open-ai-error'>
                            {error}
                        </p>
                    </ConditionalRender>
                    <div className='open-ai-key-wrapper'>

                        <input
                            type="text"
                            placeholder="Your OpenAI API Key"
                            className="form-control"
                            onChange={(e) => {
                                setNewAPIKEY(e.target.value);
                            }}
                            value={newAPIKEY}
                        />
                        <button
                            className="btn btn-sm btn-primary"
                            disabled={!newAPIKEY || !agreeTerms}
                            onClick={() => saveAPIKey()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    saveAPIKey();
                                }
                            }}
                        >
                            <i className="fas fa-arrow-up"></i>
                        </button>
                    </div>

                </div>
            </ConditionalRender>
            <ConditionalRender if={savedKey}>
                <div className="chat-container">
                    <ConditionalRender if={!chatMessages.length}>
                        {terms}
                    </ConditionalRender>
                    <div className="message-list" ref={chatContainerRef}>
                        {chatMessages.map((msg, index) => (
                            <div key={index} className={`message ${msg.sender === DBMARLIN_AI ? 'gpt' : ''}`}>
                                <div className='message-box'>
                                    <strong>{msg.sender}:</strong>
                                    <ReactMarkdown>{msg.message}</ReactMarkdown>
                                    {/*{renderMessageContent(msg.message, msg.sender)}*/}
                                </div>
                                <ConditionalRender if={loading && index === chatMessages.length - 1}>
                                    <div className="chat-loader"></div>
                                </ConditionalRender>
                            </div>
                        ))}
                    </div>
                    <ConditionalRender if={error}>
                        <p className='open-ai-error'>
                            {error}
                        </p>
                    </ConditionalRender>
                </div>
                <div className='position-relative'>
                    <input
                        type="text"
                        placeholder={`Message ${DBMARLIN_AI}`}
                        readOnly={loading}
                        className="form-control open-ai-form-control"
                        onChange={(e) => {
                            setQuestion(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                askGPT();
                            }
                        }}
                        value={question}
                    />
                    <button
                        className="open-ai-input-button btn btn-sm btn-primary ms-2"
                        disabled={!question || loading || !agreeTerms}
                        onClick={() => askGPT('')}
                    >
                        {!loading ? <i className="fas fa-arrow-up"></i> : 'Loading...'}
                    </button>
                </div>
                <div
                    className='d-flex justify-content-between'>
                    <p className='open-ai-info'><a className='pointer' onClick={() => clearHistory()}>Clear
                        History </a></p>
                    <p className='open-ai-info'>AI assistants can make mistakes. Consider checking important information. ({openAiModel})</p>
                    <p className='open-ai-info'><a className='pointer' onClick={() => deleteKey()}>Clear API key</a></p>
                </div>

            </ConditionalRender>
        </>
    )
        ;
};

export default ChatComponent;
