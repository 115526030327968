import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Api.
import api from '../../../api/Base';

// Context.
import { EventTypeContext } from '../../../context/EventTypeContext';

// Types.
import Action from '../../../types/Action';
import EventType from '../../../types/instance/EventType';

// Constants.
import { ACTIONS } from '../../../component/Constants';

// Components.
import Alert from '../../../component/Alert';

function Detail(props: { actionId: string, eventTypeId?: number }) {
    const history = useHistory();
    const { eventTypes, setEventTypes } = useContext(EventTypeContext);

    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(props.actionId === ACTIONS.DELETE.type);
    const [eventTypeId, setEventTypeId] = useState<number>(0);
    const [title, setTitle] = useState<string>('');
    const [titleUsed, setTitleUsed] = useState<boolean>(false);
    const [titleClass, setTitleClass] = useState<string>('');
    const [htmlIconCode, setHtmlIconCode] = useState('');
    const [htmlIconCodeUsed, setHtmlIconCodeUsed] = useState<boolean>(false);

    useEffect(() => {

        const getEventType = (eventTypeId: number) => {

            const matchedEventType = eventTypes.filter((eventType: EventType) => eventType.eventTypeId === Number(eventTypeId));

            if (matchedEventType.length === 1) {

                //setEventType(matchedEventType[0]);
                setEventTypeId(matchedEventType[0].eventTypeId);
                setTitle(matchedEventType[0].title);
                setHtmlIconCode(((matchedEventType[0].htmlIconCode === undefined || matchedEventType[0].htmlIconCode === null) ? '' : matchedEventType[0].htmlIconCode));
            } else {
                setIsMissing(true);
            }
        }

        const getAction = () => {

            switch (props.actionId) {
                case 'create':

                    setAction(ACTIONS.CREATE);
                    
                    break;
            
                case 'edit':

                    setAction(ACTIONS.UPDATE);
                    
                    break;
                        
                case 'delete':

                    setAction(ACTIONS.DELETE);
                    setDisabled(true);
                    
                    break;
            
                default:

                    console.error('Unknown action requested', props.actionId);

                    setIsError(true);

                    break;
            }
        }

        getAction();

        if (props.eventTypeId !== undefined) {
            getEventType(props.eventTypeId);
        }

    }, [eventTypes, props.actionId, props.eventTypeId]);

    function handleNameBlur() {

        // Trim any white space.
        let value = title.trim();

        // Save to state.
        setTitle(value);

        if (value === '') {

            // Invalid, set the class.
            setTitleClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setTitleClass('is-valid');
        }

        // Check for duplicate names.
        const index = eventTypes.findIndex(object => object.eventTypeId !== eventTypeId && object.title.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setTitleClass('is-valid');
            setTitleUsed(false);
        } else {

            // Duplicate found.
            setTitleClass('is-invalid');
            setTitleUsed(true);
        }
    }

    function handleHtmlIconCodeBlur() {

        if (htmlIconCode) {

            // Trim any white space.
            let value = htmlIconCode.trim();

            // Save to state.
            setHtmlIconCode(value);

            // Check for duplicate names.
            const index = eventTypes.findIndex(object => object.htmlIconCode && object.eventTypeId !== eventTypeId && object.htmlIconCode.toLowerCase() === value.toLowerCase());

            if (index === -1) {

                // No duplicates found.
                setHtmlIconCodeUsed(false);
            } else {

                // Duplicate found.
                setHtmlIconCodeUsed(true);
            }
        } else {

            // Valid, set the class.
            setHtmlIconCodeUsed(false);
        }
    }

    async function handleActionClick() {

        // Validate the form.
        let valid = true;

        if (title.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setTitleClass('is-invalid');
        } else {

            // Valid.
            setTitleClass('is-valid');
        }

        setIsValid(valid);

        if (!valid) {
            return;
        }

        // Build the event type object.
        let data = [{
            eventTypeId,
            title,
            htmlIconCode
        }];

        let result: any = undefined;

        switch (action) {
            case ACTIONS.CREATE:

                // Save the object.
                result = await api.post('event/type', data);

                if (result.status === 200) {

                    // Sort the array order and add the new object to the context.
                    setEventTypes(eventTypes.concat(data).sort(function (a: EventType, b: EventType) {
                        if (a.title < b.title) { return -1; }
                        if (a.title > b.title) { return 1; }
                        return 0;
                    }));
                }

                break;
                
            case ACTIONS.UPDATE:

                // Update the object.
                result = await api.put('event/type', data);

                if (result.status === 200) {

                    const updated = eventTypes.map((eventType) => {

                        if (eventType.eventTypeId === eventTypeId) {

                            // Return the updated object.
                            return {
                                ...eventType,
                                title,
                                htmlIconCode
                            };
                        }

                        return eventType;
                    });

                    // Sort the array order and update the context.
                    setEventTypes(updated.sort(function (a: EventType, b: EventType) {
                        if (a.title < b.title) { return -1; }
                        if (a.title > b.title) { return 1; }
                        return 0;
                    }));
                }

                break;

            case ACTIONS.DELETE:

                // Delete the object.
                result = await api.delete('event/type', { data });

                if (result.status === 200) {

                    // Remove the object from the array.
                    setEventTypes(eventTypes.filter(object => Number(object.eventTypeId) !== eventTypeId));
                }

                break;
            default:

                // Unknown action requested.
                console.error('Unknown action requested', action);

                setIsError(true);

                break;
        }

        if (result.status !== 200) {

            // Failed to complete the API request.
            console.error('Failed to complete requested action', action, result, data);

            // Failed to complete request.
            setIsError(true);

            // An error occurred, so exit this function.
            return;
        }

        // Redirect the user back to the list view.
        history.push('/admin/event-types');
    }

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-object-ungroup fa-fw" aria-hidden="true"></i>
                    {action.title} event type
                </div>
                <div className="card-body">
                    {isMissing ? (
                        <React.Fragment>
                            <Alert message="The requested event type is either invalid or no longer available." heading="Invalid request" variant="alert-danger" />
                            <div className="row g-0 form">
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <Link to="/admin/event-types/" role="button" className="btn btn-secondary"><i className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            {action.type === 'delete' && (
                                <Alert message="Please check and ensure you want to delete this event type. Any associated events will also be deleted." heading="Deletes are final and cannot be undone" variant="alert-warning" />
                            )}
        
                            {isError && (
                                <Alert message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request." heading={`Failed to ${action.title.toLowerCase()} event type`} variant="alert-danger" />
                            )}
        
                            {!isValid && (
                                <Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
                            )}
        
                            <p>Please complete the required details below:</p>
        
                            <div className="row g-0 form">
        
                                {/* Title */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="name" className="reminder" data-tip="The event type name">Name</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="name"
                                        autoFocus
                                        className={`form-control ${titleClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleNameBlur}
                                        onChange={e => setTitle(e.target.value)}
                                        value={title}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {titleUsed && (
                                        <small className="text-danger">Event type already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>
        
                                {/* HTML Icon Code */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="htmlIconCode" className="reminder" data-tip="The event type icon code">Icon Code</label>
                                    {htmlIconCode && (
                                        <i className={`${htmlIconCode} ms-2 text-secondary`} aria-hidden="true"></i>
                                    )}
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <input
                                        type="text"
                                        id="htmlIconCode"
                                        className={`form-control`}
                                        maxLength={250}
                                        onBlur={handleHtmlIconCodeBlur}
                                        onChange={e => setHtmlIconCode(e.target.value)}
                                        value={htmlIconCode}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {htmlIconCodeUsed ? (
                                        <small className="text-secondary">Icon code already used.</small>
                                    ) : (
                                            <small className="text-secondary">We use <a href="https://fontawesome.com/icons?d=gallery&amp;p=1" rel="noopener noreferrer" target="_blank">FontAwesome</a><sup className="fal fa-external-link fa-fw"></sup> to allow custom icons to get set against your custom event types.</small>
                                        )}
                                </div>
                                <div className="w-100"></div>
        
                                {/* Actions */}
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <Link to="/admin/event-types/" role="button" className="btn btn-secondary"><i className="fal fa-times-circle fa-fw fa-sm"></i><span>Cancel</span></Link>
                                    <button className={`btn btn-${action.variant}`} onClick={handleActionClick}><i className={`${action.icon} fa-sm`}></i><span>{action.title}</span></button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Detail;