export default function TablePagination (props: { length: number,
	pageSize: number, setPageSize: Function, pageOptions: number[],
	pageCount: number, canPreviousPage: boolean, previousPage: Function,
	canNextPage: boolean, nextPage: Function, gotoPage: Function,
	pageIndex: number })
{
	const { pageSize, setPageSize, pageOptions, pageCount,
		canPreviousPage, previousPage, canNextPage, nextPage, gotoPage,
		pageIndex } = props;

		return (
			<div className="row g-0 table-paging">
				<div className="col-3">
					<select className="form-select" value={pageSize}
						aria-label="Select page size"
						onChange={(e) => {
							setPageSize (Number (e.target.value))
							window.localStorage.setItem('pageSize', JSON.stringify(e.target.value))
						}}>
						{[10, 20, 30, 40, 50].map ((size) =>
						<option key={size} value={size}>{size}</option>
						)}
					</select>
				</div>
				<div className="col-9">
					<button key={'first-page'} className="page-number"
						disabled={!canPreviousPage}
						onClick={() => gotoPage (0)}>First</button>
					<button key={'previous-page'} className="page-number"
						disabled={!canPreviousPage}
						onClick={() => previousPage ()}>Back</button>
					{pageOptions.filter ((index) =>
						index > pageIndex - 3 && index < pageIndex + 3)
						.map ((index) =>
					<button key={'page-' + index} className="page-number"
						disabled={index === pageIndex}
						onClick={() => gotoPage (index)}>{index + 1}</button>
					)}
					<button key={'next-page'} className="page-number"
						disabled={!canNextPage}
						onClick={() => nextPage ()}>Next</button>
					<button key={'last-page'} className="page-number"
						disabled={!canNextPage}
						onClick={() => gotoPage (pageCount - 1)}>Last</button>
				</div>
			</div>
		)
}
