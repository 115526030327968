import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Types.
import Period from '../../types/Period';
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import EventType from '../../types/instance/EventType';

// Constants.

// Views.
import List from './List';
import Detail from './Detail';
import Statement from '../Statement/Statement';
import GenericStatement from "../Statement/GenericStatement";
import Helper from "../../helpers/Helper";
import SQLStatisticStatement from "../Statement/SQLStatisticStatement";

interface IInstancesProps {
    period: Period,
    instances: InstanceTarget[],
    hosts: HostTarget[],
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}

interface IInstancesState {
}

const initialState: IInstancesState = {
};

class Instances extends Component<IInstancesProps, IInstancesState> {
    static propTypes: { toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>, setFilterOptions: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IInstancesProps) {
        super(props);

        this.state = initialState;
    };

    render() {
        const { period, instances, hosts, eventTypes, toggleMenu, applyPeriod, setFilterOptions } = this.props;
        return (
                <Switch>
                    {/* Instances */}
                    <Route exact path="/instances" render={(props) => <List {...props} period={period} toggleMenu={toggleMenu} applyPeriod={applyPeriod} filterParameters={period.filters.parameters} setFilterOptions={setFilterOptions}/>} />

                    <Route
                        path={[
                            "/instances/:instanceId/activity/statement/:statementId",
                            "/instances/:instanceId/activity/groupedstatement/:statementId",
                            "/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId",
                            "/instances/:instanceId/activity/batch/:batchId",
                            "/instances/:instanceId/activity/groupedbatch/:batchId/groupedstatement/:batchStatementId",
                            "/instances/:instanceId/activity/groupedbatch/:batchId",
                        ]}
                        render={(props) => <Statement
                            {...props}
                            key={period.timestamp + props.match.params.instanceId}
                            instanceId={Number(props.match.params.instanceId)}
                            // @ts-ignore
                            statementId={props.match.params.statementId || props.match.params.batchId}
                            // @ts-ignore
                            batchId={props.match.params.batchId}
                            // @ts-ignore
                            batchStatementId={props.match.params.batchStatementId}
                            instances={instances}
                            eventTypes={eventTypes}
                            period={period}
                            toggleMenu={toggleMenu}
                            applyPeriod={applyPeriod}
                            setFilterOptions={setFilterOptions}
                        />}
                    />

                    <Route
                        path={[
                            "/instances/:instanceId/sql-statistics/statement/:statementId",
                        ]}
                        render={(props) => <SQLStatisticStatement
                            {...props}
                            key={period.timestamp + props.match.params.instanceId}
                            instanceId={Number(props.match.params.instanceId)}
                            // @ts-ignore
                            statementId={props.match.params.statementId || props.match.params.batchId}
                            // @ts-ignore
                            batchId={props.match.params.batchId}
                            // @ts-ignore
                            batchStatementId={props.match.params.batchStatementId}
                            instances={instances}
                            hosts={hosts}
                            eventTypes={eventTypes}
                            period={period}
                            toggleMenu={toggleMenu}
                            applyPeriod={applyPeriod}
                            setFilterOptions={setFilterOptions}
                        />}
                    />


                    <Route
                        path={[
                            "/instances/:instanceId/statement-activity/:statementId",
                        ]}
                        render={(props) => <GenericStatement
                            {...props}
                            key={period.timestamp + props.match.params.instanceId}
                            instanceId={Number(props.match.params.instanceId)}
                            // @ts-ignore
                            statementId={props.match.params.statementId || props.match.params.batchId}
                            // @ts-ignore
                            batchId={props.match.params.batchId}
                            // @ts-ignore
                            batchStatementId={props.match.params.batchStatementId}
                            instances={instances}
                            hosts={hosts}
                            eventTypes={eventTypes}
                            period={period}
                            toggleMenu={toggleMenu}
                            applyPeriod={applyPeriod}
                            setFilterOptions={setFilterOptions}
                        />}
                    />
                    {/* Instance Detail */}
                    <Route exact path="/instances/:instanceName/activity" render={(props) => <Detail {...props} key={period.timestamp + Helper.getInstanceIdByName(props.match.params.instanceName, instances)} instanceId={Helper.getInstanceIdByName(props.match.params.instanceName, instances)} instances={instances} hosts={hosts} eventTypes={eventTypes} period={period} toggleMenu={toggleMenu} applyPeriod={applyPeriod} setFilterOptions={setFilterOptions} />} />
                    <Route path="/instances/:instanceId" render={(props) => <Detail {...props} key={period.timestamp + props.match.params.instanceId} instanceId={Number(props.match.params.instanceId)} instances={instances} hosts={hosts} eventTypes={eventTypes} period={period} toggleMenu={toggleMenu} applyPeriod={applyPeriod} setFilterOptions={setFilterOptions} />} />

                    <Redirect to="/not-found" />
                </Switch>
        );
    }
}

Instances.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Instances;
