// React.
import { useContext, useEffect } from 'react';

// Third-parties.
import { useToasts } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';

// Helper.
import Helper from '../../helpers/Helper';

// Types.
import Period from "../../types/Period";
import FilterOption from '../../types/instance/FilterOption';
import { FilterType } from '../../typescript/Enums';
import { getSQStatisticsParameters } from "../../helpers/utils";
import {STATISTICS_FILTERS} from "../../container/statistics/constants";
import { TimeRangeContext } from "../../context/TimeRangeContext";

function CopyUrl(props: { period: Period, useUrl: boolean }) {
    const { addToast } = useToasts();
    const timeRangeContext = useContext(TimeRangeContext)

    function copyLinkToClipboard() {

        let target = document.createElement('textarea');

        target.style.position = 'absolute';
        target.style.left = '-9999px';
        target.style.top = '0';
        target.id = '_hiddenUrlCopyText_';

        let filters: string = '';
        let statisticFilters: string = '';

        props.period.filters.options.forEach((filterOption: FilterOption) => {
            if (filterOption.filters.length > 0) {
                filters += `&${FilterType[filterOption.filterType].toLowerCase()}=${filterOption.filters.join()}`
            }
        });
        // let tabToHide = ''
        // const hideTab = getParameterValues(window.location.search, 'hide')
        // if (hideTab.length) {
        //     tabToHide = `&hide=${hideTab}`
        // }

        statisticFilters = getSQStatisticsParameters(STATISTICS_FILTERS);
        const savedInstanceTags = window.localStorage.getItem('instance-tags-filters');
        let instanceTagsParams = ''
        if (savedInstanceTags) {
            const tagFiltersArray = JSON.parse(savedInstanceTags);
            instanceTagsParams = `&tags=${Helper.getUrlParamsForTags(tagFiltersArray)}`
        }

        if (props.useUrl === false) {

            // Build the URL address.
            target.textContent = `${window.location.href.split(/[?#]/)[0]}${Helper.getQueryStringWithInterval(timeRangeContext)}${filters}${statisticFilters}${instanceTagsParams}`;
        } else {

            // Use the existing URL address.
            target.textContent = window.location.href;
        }
        document.body.appendChild(target);

        // Select the text within the hidden input.
        target.select();
        target.setSelectionRange(0, 99999);

        document.execCommand("copy");

        // Add a toast notification.
        addToast('Web address copied to your clipboard', { appearance: 'info', autoDismiss: true });
    }
        
	useEffect (() => { ReactTooltip.rebuild (); }, []);

    return (
        <button type="button" className="btn btn-heading" data-tip="Copy this view's URL and period to your clipboard" onClick={copyLinkToClipboard}><i className="fal fa-copy fa-fw"></i></button>
    );
}

CopyUrl.defaultProps = {
    useUrl: false
};

export default CopyUrl;
