import { Link, useHistory } from 'react-router-dom';
import { useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";

// Types.
import EmailService from "../../../../types/integration/EmailService";

// Constants.
import { ACTIONS, attributesArray, generalErrorMessage } from '../../../../component/Constants';

//Utils
import { findEmailIntegrationValue, getAction, getValidClass } from "../../../../helpers/utils";
import Action from "../../../../types/Action";
import api from "../../../../api/Base";
import Alert from "../../../../component/Alert";

function Detail(props: { actionId: string, emailIntegration: EmailService[], refresh: Function }) {
    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [smtpPassElementType, setSmtpPassElementType] = useState<string>('password');

    const history = useHistory();
    const isDelete = action.type === ACTIONS.DELETE.type;
    const {
        register,
        trigger,
        handleSubmit,
        getValues,
        setValue,
        control,
        formState: { errors }
    } = useForm<EmailService>({
        mode: "onSubmit",
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });

    useEffect(() => {
        getAction(props.actionId, setAction, setDisabled);
    }, [props.actionId]);

    const [disabled, setDisabled] = useState(isDelete);

    const parseEditResponse = (data: any) => {
        if (data.length) {
            // @ts-ignore
            attributesArray.forEach(attr => setValue(attr, findEmailIntegrationValue(attr, data)))
            const sslValue = data.find((item: { name: string; }) => item?.name === 'mail.smtp.starttls.enable')
            setValue('ssl', sslValue?.value === 'true')
            void trigger()
        }
    }

    useEffect(() => {
        parseEditResponse(props.emailIntegration)
    }, [props.emailIntegration]);

    const { dirtyFields } = useFormState({
        control
    })

    const onSubmit = async (data: EmailService) => {
        setDisabled(true)
        const updatedValues: { name: string; value: any; }[] = []
        let result: any;
        let url = `/parameter`;

        Object.keys(data).forEach(item => {
            let name = item === 'ssl' ? `mail.smtp.starttls.enable` : `mail.smtp.${item}`
            name = item === 'url' ? 'mail.smtp.dbmarlin.url' : name;
            // @ts-ignore
            const value = data[item] === true ? 'true' : data[item] === false ? 'false' : data[item]
            if (action.type === 'create' || action.type === 'delete') {
                updatedValues.push({ name, value })
                // @ts-ignore
            } else if (dirtyFields[item]) {
                updatedValues.push({ name, value })
            }
        })
        result = action.type === 'delete' ? await api.delete(url, { data: updatedValues }) : await api.post(url, updatedValues)

        if (result.status === 200) {
            history.push('/admin/integrations/email')
            props.refresh()
        }
    }
    const isValid = Object.keys(errors).length === 0

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-envelope fa-fw mr-10" aria-hidden="true"/>
                    {action.title} Email Settings
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {!isValid && (
                            <Alert message={generalErrorMessage.text} heading={generalErrorMessage.title}
                                   variant="alert-danger"/>
                        )}

                        <p>Please complete the required details below:</p>

                        <div className="row g-0 form">

                            {/* Base URL */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="url" className="base-url">DBmarlin base URL</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="url"
                                    maxLength={250}
                                    {...register("url", { required: "Please fill in this field" })}
                                    className={`form-control ${getValidClass(errors.url, getValues('url'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.url?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.url?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* Host */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="host" className="reminder">SMTP Host</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="host"
                                    maxLength={250}
                                    {...register("host", { required: "Please fill in this field" })}
                                    className={`form-control ${getValidClass(errors.host, getValues('host'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.host?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.host?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* Port */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="port" className="reminder">SMTP Port</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="port"
                                    maxLength={250}
                                    {...register("port", { required: "Please fill in this field" })}
                                    className={`form-control ${getValidClass(errors.port, getValues('port'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.port?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.port?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* SSL enabled */}
                            <div className="col-xs-12 col-md-3">
                                <label className="auth">SSL enabled</label>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="ssl"
                                        {...register("ssl")}
                                        disabled={disabled}
                                        className="form-check-input"
                                        // onChange={() => setValue('ssl', !getValues('ssl'))}
                                        defaultChecked={true}
                                        data-lpignore={true}
                                    />
                                </div>
                            </div>
                            <div className="w-100"/>


                            {/* Auth enabled */}
                            <div className="col-xs-12 col-md-3">
                                <label className="auth">Authentication</label>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="auth"
                                        {...register("auth")}
                                        className="form-check-input"
                                        disabled={disabled}
                                        defaultChecked={true}
                                        // onChange={() => setValue('auth', !getValues('auth'))}
                                        data-lpignore={true}
                                    />
                                </div>
                            </div>
                            <div className="w-100"/>

                            {/* Username */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="user" className="reminder">SMTP Username</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="user"
                                    maxLength={250}
                                    {...register("user", { required: "Please fill in this field" })}
                                    className={`form-control ${getValidClass(errors.user, getValues('user'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    autoComplete="off"
                                    title={errors.user?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.user?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* Password */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="password" className="reminder">SMTP Password</label>
                                </span>
                                {action === ACTIONS.CREATE && (
                                    <i className="fas fa-eye fa-fw fa-xs ms-1" role="button" onClick={() => ((smtpPassElementType === 'text') ? setSmtpPassElementType('password') : setSmtpPassElementType('text'))}/>
                                )}
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type={smtpPassElementType}
                                    id="password"
                                    maxLength={250}
                                    {...register("password", { required: "Please fill in this field" })}
                                    className={`form-control ${getValidClass(errors.password, getValues('password'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    autoComplete="new-password"
                                    title={errors.password?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.password?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* SMTP From Address */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="from" className="reminder">SMTP From Address</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="from"
                                    maxLength={250}
                                    {...register("from", {
                                        required: "Please fill in this field"
                                    })}
                                    className={`form-control ${getValidClass(errors.from, getValues('from'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.from?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.from?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* SMTP To Address */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="to" className="reminder">SMTP To Address</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="to"
                                    maxLength={250}
                                    {...register("to", {
                                        required: "Please fill in this field"
                                    })}
                                    className={`form-control ${getValidClass(errors.to, getValues('to'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.to?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.to?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/*Email  enabled */}
                            <div className="col-xs-12 col-md-3">
                                <label className="enabled">Email enabled</label>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="enable"
                                        {...register("enable")}
                                        className="form-check-input"
                                        disabled={disabled}
                                        defaultChecked={true}
                                        data-lpignore={true}
                                    />
                                </div>
                            </div>
                            <div className="w-100"/>

                            {/* Actions */}
                            <div className="col-12 actions">
                                <Link to="/admin/integrations/email" role="button" className="btn btn-secondary"><i
                                    className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span></Link>
                                <button type="submit" className={`btn btn-${action.variant}`}><i
                                    className={`${action.icon} fa-sm`}/><span>{action.title}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Detail;