// React.
import { useEffect, useMemo } from 'react';

// Third parties.
import ReactTooltip from 'react-tooltip';

function KnowledgeBaseWaitEventButton(props: { databaseType: string, waitEvent: string}) {
	const { databaseType, waitEvent } = props;

	const waitEventUri: string = useMemo (() =>
	{
		// Some processing of the wait event is needed to match the URL in
		// docs.dbmarlin.com.
		let uri: string = waitEvent;

    	// For CockroachDB the event 'executing (XXX.XX%)' can be any percentage
		// but we have just 1 KB article for XXX.XX%.
		if (databaseType === 'cockroachdb')
		{
			uri = uri.replace (/executing \(.*\)/i, 'executing (XXX.XX%)');
		}

		// Swap slash for dash and strip brackets and percent characters, as
		// they are special characters in Docusaurus (see Notion).
		uri = uri
			.replace ('/', '-')
			.replace ('(', '')
			.replace (')', '')
			.replace ('%', '');
		return encodeURIComponent (uri);
	}, [ databaseType, waitEvent ]);

	useEffect (() => { ReactTooltip.rebuild (); }, []);

    return (
        <button type="button" className="btn btn-xsm btn-outline-secondary float-end" data-tip="Read about this wait event in the knowledge-base" 
        onClick={() => window.open(`https://docs.dbmarlin.com/docs/kb/wait-events/${databaseType}/${waitEventUri}`, "_blank")}>
            <i className="fas fa-book-reader" aria-hidden="true"></i>Read more</button>
    );
}

export default KnowledgeBaseWaitEventButton;
