import { Component } from 'react';
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";

// Types.
import Period from "../../../types/Period";
import AlertRules from "../../../types/Alerts/AlertRules";

// Helpers.
import ConditionalRender from "../../../helpers/ConditionalRender";

// Third-party packages.
import Helmet from "react-helmet";

// Constants.
import { ACTIONS, DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from "../../../component/Constants";

// Views.
import Details from './Detail';
import List from './List';
import HeaderActions from "../../../container/header_actions";
import Breadcrumb from "../../../container/breadcrumb";
import InstanceTarget from "../../../types/instance/InstanceTarget";
import HostTarget from "../../../types/host/HostTarget";
import AlertsApis from "../../../api/instance/AlertsApis";

interface IAlertRulesProps {
    period: Period,
    toggleMenu: Function,
    instances: InstanceTarget[],
    hosts: HostTarget[],
    applyPeriod: Function
}

interface IAlertRulesState {
    loading: number,
    alertRules: AlertRules[]
}

const initialState = {
    loading: DATA_INITIALISING,
    alertRules: []
}

class Rules extends Component<IAlertRulesProps, IAlertRulesState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: IAlertRulesProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    async getData() {
        // Get all events for all time.
        const alertRules = await AlertsApis.getAlertRules();

        this.setState({
            loading: DATA_LOADED,
            alertRules
        });
    }


    /* Action Methods */
    refresh = () => {

        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, alertRules } = this.state;
        const { instances, hosts } = this.props;

        return (

           <div id="content">
               <Helmet>
                   <title>Alert Rules - Settings - DBmarlin</title>
                   <meta name="description" content="Manage custom alert rules" />
               </Helmet>

               <div id="top-header-wrapper" className="row row-cols-2">
                   <Breadcrumb heading="Alert Rules">
                       <Link to="/admin">Settings</Link>

                       {/**/}
                       <Switch>
                           <Route exact path="/admin/alert-rules" render={() => <span>Alert Rules</span>} />
                       </Switch>

                       <Switch>
                           <Route exact path="/admin/alert-rules/create" render={() => <Link to="/admin/alert-rules">Alert Rules</Link>} />
                           <Route exact path="/admin/alert-rules/:actionId/:id" render={() => <Link to="/admin/alert-rules">Alert Rules</Link>} />
                       </Switch>

                       <Switch>
                           <Route exact path="/admin/alert-rules/create" render={() => <span>Create</span> } />
                           <Route exact path="/admin/alert-rules/:actionId/:id" render={(props) => <span>{props.match.params.actionId.charAt(0).toUpperCase() + props.match.params.actionId.slice(1)}</span>} />
                       </Switch>
                   </Breadcrumb>
                   <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh} applyPeriod={this.props.applyPeriod} />
               </div>

               <div className="loader">
                   <ConditionalRender if={false}>
                       <div className="bar" />
                   </ConditionalRender>
               </div>

               <div className="row row-cols-1">
                   <Switch>
                       <Route exact path="/admin/alert-rules/create" render={(props) => <Details loading={loading} {...props} alertRules={alertRules} actionId={ACTIONS.CREATE.type} instances={instances} hosts={hosts} refresh={this.refresh} />} />
                       <Route exact path="/admin/alert-rules/:actionId/:id" render={(props) => <Details loading={loading}alertRules={alertRules} {...props} actionId={props.match.params.actionId} alertRuleId={props.match.params.id} instances={instances} hosts={hosts} refresh={this.refresh} />} />
                       <Route exact path="/admin/alert-rules/" render={(props) => <List {...props} loading={loading} alertRules={alertRules} refresh={this.refresh} />} />
                   </Switch>
               </div>
           </div>
       )
    }
}

Rules.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Rules;
