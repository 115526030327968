import { Link } from "react-router-dom";

function EditButton(props: { id: string, type: string }) {
    return (
        <Link type="button" className="btn btn-heading" data-tip={`${props.type === 'instances' ? 'Instance' : 'Host'} settings`} to={`/admin/${props.type}/edit/${props.id}`}>
            <i className="fal fa-gear fa-fw"/></Link>
    );
}

export default EditButton;
