// Types.
import EventsDataTable from '../../../types/instance/tables/EventsDataTable';

// Components.
import Alert from '../../Alert';
import EventsTable from '../table/EventsTable';

function Changes(props: { loading: boolean, eventsDataTable: EventsDataTable[] }) {
    return (
        <div id="changes" className="tab-pane fade" role="tabpanel" aria-labelledby="changes-tab">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-exchange fa-fw" aria-hidden="true" />
                    Changes
                    <div className="btn-group float-end">
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseChanges" aria-expanded="false" aria-controls="collapseChanges" />
                    </div>
                </div>
                <div id="collapseChanges" className="card-body collapse show">
                    {Number(process.env.REACT_APP_API_LIMIT) - 1 === props.eventsDataTable.length -1 && (
                        <Alert message={`To help preserve performance, we limit the total number of changes records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by their recorded change/event date and time.`} heading="Change and event records" variant="alert-info" />
                    )}
                    <div className="table-responsive">
                        <EventsTable loading={props.loading} eventsDataTable={props.eventsDataTable} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Changes;