import { createContext, useContext } from 'react';
import { TimeRange } from "../types/TimeRange";
import * as dayjs from 'dayjs';
import * as QueryString from "query-string";
import 'dayjs/plugin/utc';
import { periodToValidIntervals } from "../component/Constants";

// Extend dayjs with the UTC plugin
dayjs.extend(require('dayjs/plugin/utc'));

// Check for QueryString parameters.
let queryString = QueryString.parse(window.location.search);

// Set the internal context object.
const savedTimeZone = window.localStorage.getItem('dbmar-timeZone')
const timeZoneValue = savedTimeZone ? JSON.parse(savedTimeZone) : Intl.DateTimeFormat().resolvedOptions().timeZone
const isUTC = timeZoneValue === 'UTC'
const timeNow = isUTC ? dayjs.utc() : dayjs.default()
const savedInterval = JSON.parse(window.localStorage.getItem('dbmar-interval') || '0')
const validInterval  = periodToValidIntervals.hours_1.includes(savedInterval)

let timeRange: TimeRange = {
    interval: validInterval ? savedInterval : 0,
    timezone: timeZoneValue || Intl.DateTimeFormat().resolvedOptions().timeZone,
    previousFrom: timeNow.subtract(2, 'hour').second(0),
    from: timeNow.subtract(1, 'hour').second(0),
    to: timeNow.second(0),
    dateFormat: 'YYYY-MM-DD+HH:mm:00'
};

if (queryString.fm && queryString.to && queryString.tz) {
    const timezone = (typeof queryString.tz === 'object' ? queryString.tz[0] : queryString.tz) || 'UTC'
    const intervalParam = queryString.interval || '0'
    // Override the default settings.
    timeRange.from = dayjs.default(queryString.fm.toString(), 'YYYY-MM-DD+HH:mm', timezone)
    timeRange.from = dayjs.default(timeRange.from)
    timeRange.to = dayjs.default(queryString.to.toString(), 'YYYY-MM-DD+HH:mm', timezone)
    timeRange.to = dayjs.default(timeRange.to)
    timeRange.previousFrom = timeRange.from.subtract(timeRange.to.diff(timeRange.from, 'millisecond'), 'millisecond')
    timeRange.previousFrom = dayjs.default(timeRange.previousFrom)
    if (queryString.interval) {
        const intervalURLvalue = parseInt(intervalParam.toString())
        timeRange.interval = intervalURLvalue
        window.localStorage.setItem('dbmar-interval', JSON.stringify(intervalURLvalue))
    }
    if (queryString.tz) {
        const timeZoneURLvalue = queryString.tz || timeZoneValue
        timeRange.timezone = timeZoneURLvalue
        window.localStorage.setItem('dbmar-timeZone', JSON.stringify(timeZoneURLvalue))
    }
}

export type SetTimeRangeParamType = Pick<TimeRange, 'timezone' | 'from' | 'to' | 'interval'>

// Define the context type, including the update function.
export type TimeRangeContextType = {
    timeRange: TimeRange;
    setTimeRange: (timeRange: SetTimeRangeParamType) => void;
    getTimeRangeQueryString: (previous?: boolean, interval?: number) => string,
    getTimeRangeRedirectString: () => string
}

// Define the context object.
export const TimeRangeContext = createContext<TimeRangeContextType>({
    timeRange: timeRange,
    getTimeRangeQueryString: (previous = false) => {
        let from, to;
        from = previous ? timeRange.previousFrom.format('YYYY-MM-DD+HH:mm:ss') : timeRange.from.format('YYYY-MM-DD+HH:mm:ss');
        to = previous ? timeRange.from.format('YYYY-MM-DD+HH:mm:ss') : timeRange.to.format('YYYY-MM-DD+HH:mm:ss');
        return `from=${from}&to=${to}&tz=${timeRange.timezone}&interval=${timeRange.interval || 0}`;
    },

    getTimeRangeRedirectString: () => {
        const fm = timeRange.timezone === 'UTC' ? timeRange.from.utc().format('YYYY-MM-DD+HH:mm:ss') : timeRange.from.format('YYYY-MM-DD+HH:mm:ss');
        const to = timeRange.timezone === 'UTC' ? timeRange.to.utc().format('YYYY-MM-DD+HH:mm:ss') : timeRange.to.format('YYYY-MM-DD+HH:mm:ss');

        return `fm=${fm}&to=${to}&tz=${timeRange.timezone}&interval=${timeRange.interval}`;
    },
    setTimeRange: (newTimeRange: SetTimeRangeParamType) => {
        const from = newTimeRange.from
        const to = newTimeRange.to
        timeRange.previousFrom = from.subtract(newTimeRange.to.diff(from, 'millisecond'), 'millisecond');
        timeRange.from = from;
        timeRange.to = to;
        timeRange.timezone = newTimeRange.timezone;
        timeRange.interval = newTimeRange.interval;
    }
});
TimeRangeContext.displayName = 'TimeRange';

// Export the update function for the context object.
export const useTimeRange = () => useContext(TimeRangeContext);