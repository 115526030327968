// Third-party packages.
import React, { useContext,useEffect, useState } from "react";

// Apis.
import Breadcrumb from "../breadcrumb";
import {Link, Route, useHistory} from "react-router-dom";
import HeaderActions from "../header_actions";
import PageContent from "../page_content"
import StatisticsComparisonOptionsCard from "./statistics-comparison-options-card";
import StatisticsResultsTable from "./statistics-results-table";
import ConditionalRender from "../../helpers/ConditionalRender";
import dayjs from "dayjs";
import StatisticsComparisonChart from "./statistics-comparison-chart";
import StatementsTable from "./statements-table";
import * as QueryString from "query-string";
import { StatisticsComparisonProps, StatsComparisonOptions } from "./types";
import { InstanceContext } from "../../context/InstanceContext";
import { TimeRangeContext } from "../../context/TimeRangeContext";

const StatisticsComparison = (props: StatisticsComparisonProps) => {

    const searchParams = new URLSearchParams(window.location.search)
    const {instances} = useContext(InstanceContext);
    const timeRangeContext = useContext(TimeRangeContext)

    const getInstanceName = (id: any) => {
        const instance = instances.find(instance => instance.id === parseInt(id))
        return instance ? instance.name : ''
    }
    const statsComparisonInitialOptions: StatsComparisonOptions = {
        aggregate: timeRangeContext.timeRange.interval.toString(),
        metric: searchParams.get('metric') || '',
        chart: searchParams.get('chart') || 'column',
        topN: parseInt(searchParams.get('topN') || '10', 10) || 10,
        tagName: searchParams.get('tagName') || undefined,
        other: searchParams.get('other') === 'true',
        showSqlId: searchParams.get('other') === 'true',
        customSqlId: searchParams.get('customId') || '',
        firstInstanceOptions: {
            instanceId: parseInt(searchParams.get('firstInstanceId') || '-1', 10) || -1,
            instanceType: undefined,
            periodStart: new Date(searchParams.get('firstInstanceFrom') || dayjs().subtract(2, 'hour').toString()),
            periodEnd: new Date(searchParams.get('firstInstanceTo') || dayjs().subtract(1, 'hour').toString()),
            instanceName: getInstanceName(searchParams.get('firstInstanceId'))
        },
        secondInstanceOptions: {
            instanceId: parseInt(searchParams.get('secondInstanceId') || '-1', 10) || -1,
            instanceType: undefined,
            periodStart: new Date(searchParams.get('secondInstanceFrom') || dayjs().subtract(1, 'hour').toString()),
            periodEnd: new Date(searchParams.get('secondInstanceTo') || new Date()),
            instanceName: getInstanceName(searchParams.get('secondInstanceId'))
        },
    }

    const history = useHistory();

    const [loading, _] = useState<boolean>(false)
    const [options, setOptions] = useState<StatsComparisonOptions>(statsComparisonInitialOptions)
    const [generateId, setGenerateId] = useState<number>(0)
    const [statementColors, setStatementColors] = useState<Object>({})
    const [showResultsTable, setShowResultsTable] = useState<boolean>(false)

    const onOptionChange = (optionName: string, value: any) => {
        setOptions({...options, [optionName]: value})
    }

    const updateUrlParams = (type: string, value: string) => {
        const query = window.location.search;
        let queryString = QueryString.parse(query);
        queryString[type] = value
        history.push(`?${QueryString.stringify(queryString)}`)
    }

    useEffect(() => {
        if (generateId) {
            updateUrlParams('metric', options.metric)
            updateUrlParams('chart', options.chart)
            updateUrlParams('topN', options.topN.toString())
            updateUrlParams('other', options.other.toString())
            updateUrlParams('showSqlId', options.showSqlId.toString())
            updateUrlParams('firstInstanceId', options.firstInstanceOptions.instanceId.toString())
            updateUrlParams('firstInstanceFrom', options.firstInstanceOptions.periodStart.toISOString())
            updateUrlParams('firstInstanceTo', options.firstInstanceOptions.periodEnd.toISOString())
            updateUrlParams('secondInstanceId', options.secondInstanceOptions.instanceId.toString())
            updateUrlParams('secondInstanceFrom', options.secondInstanceOptions.periodStart.toISOString())
            updateUrlParams('secondInstanceTo', options.secondInstanceOptions.periodEnd.toISOString())
            updateUrlParams('customId', options.customSqlId.toString())
            if (options.tagName) {
                updateUrlParams('tagName', options.tagName)
            }
        }
    }, [generateId])

    useEffect(() => {
        setOptions({...options, showSqlId: options.firstInstanceOptions.instanceType === 'oracle' || options.secondInstanceOptions.instanceType === 'oracle'})
    }, [options.firstInstanceOptions, options.secondInstanceOptions])

    const onGenerate = () => {
        setShowResultsTable(true)
        setGenerateId(new Date().getTime())
    }

    useEffect(() => {
        if (options.firstInstanceOptions.instanceId && options.secondInstanceOptions.instanceId && options.metric && options.topN && options.firstInstanceOptions.periodStart && options.firstInstanceOptions.periodEnd && options.firstInstanceOptions.instanceType && options.secondInstanceOptions.instanceType) {
            setShowResultsTable(true)
            setGenerateId(new Date().getTime())
        }
    }, [options.firstInstanceOptions.instanceType, options.secondInstanceOptions.instanceType])

    return <React.Fragment>
        <PageContent title='SQL Stats Comparison - DBmarlin' description={''}>
            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="SQL Stats Comparison">
                    <Link to="#">Reports</Link>
                    <Route exact path="/reports/sql-stats-comparison" render={() => <span>SQL Stats Comparison</span>} />
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu}/>
            </div>
            <div className="row row-cols-1">
                <div className="col">
                    <StatisticsComparisonOptionsCard loading={loading} options={options} onOptionsChange={onOptionChange} onGenerate={onGenerate}/>
                    <ConditionalRender if={showResultsTable}>
                        <StatisticsResultsTable instanceOptions={[options.firstInstanceOptions, options.secondInstanceOptions]} options={options} generateId={generateId}/>
                    </ConditionalRender>
                    <ConditionalRender if={showResultsTable}>
                        <StatisticsComparisonChart instanceOptions={[options.firstInstanceOptions, options.secondInstanceOptions]} options={options} generateId={generateId} setStatementColors={setStatementColors}/>
                    </ConditionalRender>
                    <ConditionalRender if={showResultsTable}>
                        <StatementsTable instanceOptions={[options.firstInstanceOptions, options.secondInstanceOptions]} options={options} generateId={generateId} statementColors={statementColors} />
                    </ConditionalRender>
                </div>
            </div>
        </PageContent>
    </React.Fragment>
};

export default StatisticsComparison;
