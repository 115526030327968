import { useChat } from '../../context/ChatContext';

const ChatButton = (props: {value: string}) => {
    const {toggleChat} = useChat();
    return (
        <button type="button" className="btn btn-xsm btn-primary float-end"
                onClick={() => toggleChat(props.value)}>
            Send to DBmarlin Co-pilot
        </button>
    )
        ;
};

export default ChatButton;
