import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Third-party packages.
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import { CSVLink } from 'react-csv';

// Types.
import InstanaIntegration from '../../../../types/integration/InstanaIntegration';

// Constants.
import { ACTION_CREATE, ACTION_UPDATE, ACTION_DELETE, DATA_LOADED } from '../../../../component/Constants';
import { DATA_FAILED, DATA_EMPTY } from '../../../../component/Constants';
import ConditionalRender from "../../../../helpers/ConditionalRender";

// Components.

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(relativeTime.default);

function List(props: { loading: number, instanaIntegrations: InstanaIntegration[], instanaIntegration: InstanaIntegration, refresh: Function }) {
    const [tableFilter, setTableFilter] = useState<string>('');

    function refresh() {
        props.refresh();
    }

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fak fa-stan fa-fw" aria-hidden="true"></i>
                    Instana integrations
                    <span className="badge bg-info" data-tip="Total Instana integration count">{props.instanaIntegrations.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseInstana" aria-expanded="false" aria-controls="collapseInstana"></i>
                </div>
                <div id="collapseInstana" className="card-body collapse show">

                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"></i>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>No Instana Integrations Found - Click to Refresh</button>
                            </p>
                        </div>
                    ) : (
                            props.loading === DATA_FAILED ? (
                                <div className="w-100 text-center text-muted my-3">
                                    <i className="fal fa-ban fa-fw fa-2x text-danger"></i>
                                    <p>
                                        <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve Instana Integrations - Click to Refresh</button>
                                    </p>
                                </div>
                            ) : (
                                    <React.Fragment>
                                        <div className="row row-cols-1 row-cols-md-2 table-search">
                                            <div className="col col-md-9">
                                                <Link to={{ pathname: `/admin/integrations/instana/create`, state: { action: ACTION_CREATE, instanaIntegration: props.instanaIntegration } }} role="button" className="btn btn-sm btn-success">
                                                    <i className="fal fa-plus-square fa-fw"></i><span>Create</span>
                                                </Link>
                                                <CSVLink role="button"
                                                    headers={[ { label: 'ID', key: 'id' }, { label: 'Name', key: 'name' }, { label: 'Host', key: 'host' }, { label: 'API Key', key: 'apikey' } ]}
                                                    data={props.instanaIntegrations}
                                                    download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Integrations - Instana.csv`}
                                                    className="btn btn-sm btn-primary">
                                                    <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                                                </CSVLink>
                                                <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i className="far fa-undo"></i><span>Clear</span></button>
                                            </div>
                                            <div className="col col-md-3">
                                                <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value.toLowerCase())} />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Host Address</th>
                                                        <th className="actions" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.instanaIntegrations.filter(instana => instana.name.toLowerCase().includes(tableFilter) || instana.host.toLowerCase().includes(tableFilter)).map((instanaIntegration: InstanaIntegration, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                {instanaIntegration.name}
                                                            </th>
                                                            <td className="concatenate">
                                                                {instanaIntegration.host}
                                                            </td>
                                                            <td className="actions">
                                                                <Link to={{ pathname: `/admin/integrations/instana/edit/${instanaIntegration.id}`, state: { action: ACTION_UPDATE, instanaIntegration } }} role="button" className="btn btn-sm btn-primary">
                                                                    <i className="fal fa-edit fa-fw"></i><span>Edit</span>
                                                                </Link>
                                                                <Link to={{ pathname: `/admin/integrations/instana/delete/${instanaIntegration.id}`, state: { action: ACTION_DELETE, instanaIntegration } }} role="button" className="btn btn-sm btn-danger">
                                                                    <i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {props.instanaIntegrations.filter(host => host.name.toLowerCase().includes(tableFilter)).length === 0 && (
                                                props.instanaIntegrations.length > 0 ? (
                                                    <div className="w-100 text-center text-muted my-3">
                                                        <i className="fal fa-ban fa-fw fa-2x" />
                                                        <p>
                                                            <button type="button" className="btn btn-link" onClick={clearTableFilter}>No Instana Integrations Found - Click to Remove Filter</button>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <ConditionalRender if={props.loading === DATA_LOADED}>
                                                        <div className="w-100 text-center text-muted my-3">
                                                            <i className="fal fa-ban fa-fw fa-2x text-danger" />
                                                            <p>
                                                                <Link to={{ pathname: `/admin/integrations/instana/create`, state: { action: ACTION_CREATE, instanaIntegrations: props.instanaIntegrations, host: props.instanaIntegration } }} role="button" className="btn btn-link">
                                                                    No Instana Integrations Found - Click to Create Instana Integration
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </ConditionalRender>
                                                    )
                                            )}
                                        </div>
                                    </React.Fragment>
                                )
                        )}
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;