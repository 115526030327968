import React, { useContext } from 'react';

// Third-party packages.
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';

// Types.
import { TimeRangeContext } from "../context/TimeRangeContext";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(advancedFormat.default);

function PeriodBreadcrumb() {
    const timeRangeContext = useContext(TimeRangeContext)

    let from: Dayjs = timeRangeContext.timeRange.from,
        to: Dayjs = timeRangeContext.timeRange.to;
    return (
        <>
            {from.isSame(to, 'year') ? (
                // Same year.
                from.isSame(to, 'month') ? (
                    // Same month.
                    from.isSame(to, 'day') ? (
                        // Same day.
                        <React.Fragment>
                            {from.format('Do MMM HH:mm').replace(' 00:00', '')} to {to.format('HH:mm').replace(' 00:00', '')}
                        {` (${timeRangeContext.timeRange.timezone})`}</React.Fragment>
                    ) : (
                        // Different day.
                        <React.Fragment>
                            {from.format('Do HH:mm').replace(' 00:00', '')} to {to.format('Do MMM HH:mm').replace(' 00:00', '')}{` (${timeRangeContext.timeRange.timezone})`}
                        </React.Fragment>
                    )
                ) : (
                    // Different month.
                    <React.Fragment>
                        {from.format('Do MMM HH:mm').replace(' 00:00', '')} to {to.format('Do MMM HH:mm').replace(' 00:00', '')}{` (${timeRangeContext.timeRange.timezone})`}
                    </React.Fragment>
                )
            ) : (
                // Different year.
                <React.Fragment>
                    {from.format('Do MMM YYYY HH:mm').replace(' 00:00', '')} to {to.format('Do MMM YYYY HH:mm').replace(' 00:00', '')}{` (${timeRangeContext.timeRange.timezone})`}
                </React.Fragment>
            )}
        </>
    );
}

export default PeriodBreadcrumb;