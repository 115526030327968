import { useContext, useEffect, useMemo, useState } from "react";
import { Column, useExpanded, useSortBy, useTable } from 'react-table'
import { Link } from "react-router-dom";
import { TimeRangeContext } from "../../../../context/TimeRangeContext";

// Components
import Alert from "../../../../component/Alert";
import NoData from "../../../../component/NoData";
import SortClass from "../../../../component/SortClass";

// Helpers
import Helper from "../../../../helpers/Helper";
import {
    archiverUrl,
    fetchResults, fetchWithAuthorization,
    getWaitEventLink,
} from "../../../../helpers/utils";

// Types.
import Period from "../../../../types/Period";
import InstanceTarget from "../../../../types/instance/InstanceTarget";
import { Session } from "./types";

import './style.scss'
import StatementToolTip from "../../../../component/instance/table/StatementToolTip";

function BlockingSessions(props: {
    period: Period,
    instance: InstanceTarget,
}) {

    const {instance} = props;
    const [blockingList, setBlockingList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableFilter, setTableFilter] = useState<string>('');
    const timeRangeContext = useContext(TimeRangeContext)
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    // const [restructuredData, setRestructuredData] = useState<any[]>([]);

    // useEffect(() => {
    //     const restructureData = (data: any[], parentId = null) => {
    //         const newData: any[] = [];
    //         data.forEach((obj) => {
    //             const newObj = { ...obj };
    //             newObj.blocked_by = parentId; // Add the 'blocked_by' key with the parent ID
    //
    //             newData.push(newObj);
    //
    //             if (obj.subRows && obj.subRows.length > 0) {
    //                 const subRows = obj.subRows;
    //                 delete newObj.subRows;
    //                 newData.push(...restructureData(subRows, obj.session));
    //             }
    //         });
    //         return newData;
    //     };
    //
    //     const updatedData = restructureData(blockingList);
    //     setRestructuredData(updatedData);
    // }, [blockingList]);

    useEffect(() => {
        async function load() {
            try {
                const results: any[][] = await fetchResults(
                    [fetchWithAuthorization(archiverUrl() + `/blocking/session?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}`)]);

                if (active) {
                    setBlockingList(results[0])
                    setLoading(false)
                }
            } catch (x: any) {
                console.log(x.message);
            }
        }

        let active: boolean = true;
        void load();
        return () => {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [instance.id]);

    const sliceString = (val: string, stringLength: number, isSql: boolean = false) => {
        const length = val ? val.length : 0
        const previewText = length ? val.slice(0, stringLength) : ''
        if (isSql) {
            return length > stringLength ? `${previewText}...` : previewText
        }
        return length > stringLength ? <span title={val}>{`${previewText}...`}</span> : previewText

    }

    const sessionRedirect = (id: string) => {
        window.open(`/instances/${props.instance.id}/activity?fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sessions=${id}`)
    }

    function filterSessions(sessions: Session[], filter: string) {
        return sessions.filter(session => {

            if (session.sqltext?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.username?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.client?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.waitevent?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.waittime?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.program?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.session?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                session.database?.toString().toLowerCase().includes(tableFilter.toLowerCase())) {
                return true;
            } else if (session.subRows.length > 0) {
                session.subRows = filterSessions(session.subRows, filter);
                return session.subRows.length > 0;
            }
            return false;
        });
    }

    function countNonEmptyArrays(arr: Session[]) {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            const subArr = arr[i].subRows;
            if (subArr.length > 0) {
                count += 1 + countNonEmptyArrays(subArr);
            } else {
                count += 1;
            }
        }
        return count;
    }

    const data = useMemo(() => {
        const listToFilter = JSON.parse(JSON.stringify(blockingList))
        return filterSessions(listToFilter, tableFilter)
    }, [blockingList, tableFilter]);

    useEffect(() => {
        const totalResults = countNonEmptyArrays(data)
        // Expand all if there are less than 10 results
        if (totalResults < 10) {
            const expandAllButton = document.getElementById("expandAll");
            if (expandAllButton) {
                expandAllButton.click();
            }
        }
    }, [data]);


    const sortWithoutEmptyValues = (rowA: any, rowB: any, columnId: string, desc: boolean | undefined) => {
        const valueA = rowA.values[columnId];
        const valueB = rowB.values[columnId];

        if (!valueA && !valueB) {
            return 0; // If both values are empty, keep them together
        } else if (!valueA) {
            return desc ? -1 : 1; // Place empty values at the beginning when sorting desc
        } else if (!valueB) {
            return desc ? 1 : -1; // Place empty values at the end when sorting asc
        } else {
            // Normal sorting for non-empty values
            return valueA.localeCompare(valueB); // Adjust for string comparison if needed
        }
    }

    const columns: Column[] = useMemo(
        () => [
            {
                Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}: any) => (
                    <span>
                        <span {...getToggleAllRowsExpandedProps()}>
                            {isAllRowsExpanded ?
                                <span id='expandAll' className='session-icon open all-icon'>&#8211;</span> :
                                <span id='expandAll' className='session-icon closed all-icon'>&#x2b;</span>}
                        </span>
                    </span>
                ),
                accessor: 'sort',
                className: 'concatenate-small col-width-1',
                disableSortBy: true,
                Cell: ({row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                    </div>
            },
            {
                Header: 'Session',
                accessor: 'session',
                Cell: ({row}: any) =>
                    <div style={{
                        paddingLeft: '0px',
                    }}
                         className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth} `}>
                        <>
                            {row.canExpand ? (
                                <p className='has-children'
                                   {...row.getToggleRowExpandedProps()}>
                                    {row.isExpanded ? <span className='session-icon open'>&#8211;</span> :
                                        <span className='session-icon closed'>&#x2b;</span>}
                                    <span className='session-bullet'/>
                                    <span className="blocking-table__link"
                                          title={`Go to session ${row.original.session}`}
                                          onClick={() => sessionRedirect(row.original.session)}>
                                            {sliceString(row.original.session, 37, false)}</span>
                                </p>
                            ) : (
                                <p>
                                    <span className='active-session'>
                                         <span className='session-bullet'/>
                                        <span className="blocking-table__link"
                                              title={`Go to session ${row.original.session}`}
                                              onClick={() => sessionRedirect(row.original.session)}>
                                            {sliceString(row.original.session, 35, false)}
                                        </span>
                                        </span>
                                </p>

                            )}{" "}
                        </>
                    </div>
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>

                        {sliceString(row.original.client, 28, false)}
                    </div>
            },
            {
                Header: 'Username',
                accessor: 'username',
                Cell: ({row}: any) =>
                    <div
                        className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {sliceString(row.original.username, 20, false)}
                    </div>
            },
            {
                Header: () => props.instance.type === 'oracle' ? 'Schema' : 'Database',
                accessor: 'database',
                Cell: ({row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {sliceString(row.original.database, 15, false)}
                    </div>
            },
            {
                Header: 'Program',
                accessor: 'program',
                Cell: ({row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {sliceString(row.original.program, 15, false)}
                    </div>
            },
            {
                Header: 'SQL',
                accessor: 'sqltext',

                Cell: ({cell}: any) => {
                    cell.className = 'position-relative'
                    return <Link target="_blank" rel="noreferrer"
                                 to={`/instances/${props.instance.id}/activity/statement/${cell.row.original.sqlhash}/text?fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&sessions=${cell.row.original.session}`}>
                        {sliceString(cell.row.values.sqltext, 35, true)}
                    </Link>
                },
                sortType: (rowA, rowB, columnId, desc) => sortWithoutEmptyValues(rowA, rowB, columnId, desc),
            },
            {
                Header: 'Wait Event',
                accessor: 'waitevent',
                Cell: ({row, cell}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {cell.row.original.waitevent ? <a target="_blank" rel="noreferrer"
                                                          href={`https://docs.dbmarlin.com/docs/kb/wait-events/${props.instance.type}/${getWaitEventLink(cell.row.original.waitevent)}`}>
                            {sliceString(row.original.waitevent, 20, false)}
                        </a> : ''}
                    </div>
            },
            {
                Header: 'Blocked Time',
                accessor: 'blockedtime',
                Cell: ({cell, row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {Helper.getTimeInEnglish(cell.value)}
                    </div>
            },
            {
                Header: 'Blocking Time',
                accessor: 'blockingtime',
                sortType: 'basic',
                Cell: ({cell, row}: any) =>
                    <div className={`${row.canExpand ? 'table-header' : 'table-row'} table-item item_${row.depth}`}>
                        {Helper.getTimeInEnglish(cell.value)}
                    </div>
            }
        ],
        [data, props.instance.id, props.instance.type, props.period.api, tableFilter]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
            columns,
            data,
            initialState: {
                sortBy: [{id: 'blockingtime', desc: true}],
            },
            disableSortRemove: true
        }, useSortBy, useExpanded
    )

    function clearTableFilter() {
        setTableFilter('');
    }

    useEffect(() => {
        // Set the default sorting column after table initialization
        if (headerGroups.length > 0) {
            const defaultColumn = headerGroups[0].headers.find((column) => column.id === 'blockingtime');
            if (defaultColumn) {
                defaultColumn.toggleSortBy(true);
            }
        }
    }, [headerGroups]);

    return (<div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <i className="fal fa-lock fa-fw" aria-hidden="true"/>
                        Blocking / blocked sessions
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                           data-bs-target="#collapseStatements" aria-expanded="false"
                           aria-controls="collapseStatements"/>
                    </div>
                    <div id="collapseStatements" className="card-body collapse show overflow-visible">
                        {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 &&
                            (<Alert
                                message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by the number of executions.`}
                                heading="Statement records" variant="alert-info"/>)}
                        <div className="row row-cols-1 row-cols-md-2 table-search">
                            <div className="col col-md-9">
                                {/*{restructuredData.length ?*/}
                                {/*    <CSVLink role="button"*/}
                                {/*             headers={[*/}
                                {/*                 {label: 'Session', key: 'session'},*/}
                                {/*                 {label: 'Blocked By', key: 'blocked_by'},*/}
                                {/*                 {label: 'Client', key: 'client'},*/}
                                {/*                 {label: 'Username', key: 'username'},*/}
                                {/*                 {label: 'Data Base', key: 'database'},*/}
                                {/*                 {label: 'Program', key: 'program'},*/}
                                {/*                 {label: 'SQL', key: 'sqltext'},*/}
                                {/*                 {label: 'Wait Event', key: 'waitevent'},*/}
                                {/*                 {label: 'Blocked Time', key: 'blockedtime'},*/}
                                {/*                 {label: 'Blocking Time', key: 'blockingtime'},*/}
                                {/*             ]}*/}
                                {/*             data={restructuredData}*/}
                                {/*             download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Blocking Sessions.csv`}*/}
                                {/*             className="btn btn-sm btn-primary">*/}
                                {/*        <i className="fal fa-file-export fa-fw"/><span>Export</span>*/}
                                {/*    </CSVLink> : ''}*/}
                                <button className="btn btn-sm btn-dark"
                                        onClick={clearTableFilter}>
                                    <i className="far fa-undo"/>
                                    <span>Clear</span>
                                </button>
                            </div>
                            <div className="col col-md-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Search"
                                       value={tableFilter} data-lpignore={true}
                                       onChange={(e) => setTableFilter(e.target.value)}/>
                            </div>
                        </div>
                        <table className="table blocking-table" {...getTableProps()}>
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                            const getClassName = column.getHeaderProps().key.toString().split('_')
                                            return (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${getClassName[1]}`}>
                                                    {column.render('Header')}
                                                    <SortClass column={column}/>
                                                </th>
                                            )
                                        }
                                    )}
                                </tr>
                            ))}

                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                            const getClassName = cell.getCellProps().key.toString().split('_')
                                            return <td
                                                className={`position-relative ${getClassName[2]}`} {...cell.getCellProps()}>

                                                {cell.column.id === "sqltext" ?
                                                    <div
                                                        className={`${row.canExpand ? 'table-header' : 'table-row'} table-item tooltip-scroll ellipsis left-side item_${row.depth}`}
                                                        onMouseEnter={() => setVisibleToolTip({
                                                            id: `${cell.row.original.sqlhash}-${cell.row.values.session}-${cell.row.values.blockingtime}`,
                                                            position: -150
                                                        })}
                                                        onMouseLeave={() => setVisibleToolTip({
                                                            id: '',
                                                            position: 0
                                                        })}
                                                    >
                                                        {cell.render('Cell')}
                                                        {(`${cell.row.original.sqlhash}-${cell.row.values.session}-${cell.row.values.blockingtime}` === visibleToolTip.id && cell.row.values.sqltext &&
                                                            <StatementToolTip instanceType={props.instance.type}
                                                                              position={visibleToolTip.position}
                                                                              id={`${cell.row.original.sqlhash}-${cell.row.values.session}`}
                                                                              link={`/instances/${props.instance.id}/activity/statement/${cell.row.values.sqlhash}`}
                                                                              cell={cell}/>
                                                        )}
                                                    </div> : cell.render('Cell')}


                                            </td>
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <NoData
                            error={null}
                            loading={loading}
                            length={data.length}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockingSessions;
