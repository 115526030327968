// Third-party packages.
import Helmet from 'react-helmet';

import React from "react";
import PropTypes from 'prop-types';

const Index = (props: any) => {
    return <React.Fragment>
        <div id="content">
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description}/>
            </Helmet>
            <React.Fragment>{props.children}</React.Fragment>
        </div>
    </React.Fragment>
};

Index.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Index;
