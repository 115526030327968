// React
import { useEffect, useState } from 'react';

// Styles
import './generic-plan.css';

export default function IndexRecommendations (props:
	{ indexRecommendations: string })
{
	const [ indexRecommendationArray, setIndexRecommendationArray ] =
		useState<string[]>([]);
	const { indexRecommendations } = props;

	useEffect (() =>
	{
		try
		{
			if (indexRecommendations)
			{
				setIndexRecommendationArray (JSON.parse (indexRecommendations));
			}
		}
		catch (x: any)
		{
			// Ignore parsing error while still can have invalid JSON:
			// {"creation : CREATE INDEX ON ...;"}
			// TODO Reinstate code after releases prior to 4.3 unsupported.
			// console.error (x);
			// console.error (indexRecommendations);
		}
	}, [ indexRecommendations ]);

	if (!indexRecommendations)
	{
		return (<></>)
	}

	return (<>
		<p>
			Index recommendations:
		</p>
		<ul>
		{indexRecommendationArray.map ((indexRecommendation: string) =>
		(
			<li className="generic-plan">
				{indexRecommendation}
			</li>
		))}
		</ul>
	</>);
}
