export enum Dimension {
    WaitEvent = 0,
    Database,
    Session,
    Client,
    Username,
    Program,
    Module,
    Change,
    Event,
    Batch,
    Statement
}

export enum WidgetType {
    Time,
    Executions,
    AverageTime,
    Changes,
    Processor,
    Memory,
    Disk
}

export enum ChartDataOption {
    Activity,
    Batches,
    Statements,
    Waits
}

export enum PieChartKey {
    Batches,
    Clients,
    Databases,
    Programs,
    Statements,
    Sessions,
    Users,
    Waits
}

/* Filter Options */
export enum FilterType {
    Waits,
    Databases,
    Sessions,
    Clients,
    Users,
    Programs,
    Modules
}

export enum SqlTextType {
    Batch,
    Statement
}