import React, { Component } from 'react';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Helpers.
import Helper from '../../helpers/Helper';

// Types.
import Period from '../../types/Period';
import HostTarget from '../../types/host/HostTarget';
import InstanceTarget from '../../types/instance/InstanceTarget';
import InstanceTargetHost from '../../types/instance/InstanceTargetHost';
import CombinedChanges from '../../types/instance/CombinedChanges';
import EventType from '../../types/instance/EventType';
import Change from '../../types/instance/Change';
import Event from '../../types/instance/Event';
import ChartPlotBand from '../../types/ChartPlotBand'
import ChartPlotLine from '../../types/ChartPlotLine'

// Constants.
import { DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from '../../component/Constants';

// Components.
import PeriodBreadcrumb from '../../component/PeriodBreadcrumb';

// Views.
import Activity from './tabs/Activity';
import ActivityGroupedStatements from './tabs/ActivityGroupedStatements';
import Statistics from './tabs/Statistics';
import Hosts from './tabs/Hosts';
import Insights from './tabs/Insights';
import Breadcrumb from "../../container/breadcrumb";
import HeaderActions from "../..//container/header_actions";
import TimeShift from "../../component/TimeShift";
import ConditionalRender from "../../helpers/ConditionalRender";
import FilterBar from "../../component/FilterBar";
import TabbedMenu from "../../container/tabbed-menu";
import EventHistoryContainer from "../../container/event-history";
import Alert from "../../types/instance/Alert";
import SqlStatistics from "./tabs/SqlStatistics";
import BlockingSessions from './tabs/BlockingSessions/index';
import { TimeRangeContext, TimeRangeContextType } from "../../context/TimeRangeContext";
import Deadlocks from './tabs/Deadlocks';
import TempDb from './tabs/TempDb';
import EventsApi from "../../api/instance/EventsApi";
import api from "../../api/Base";
import TransactionContention from "./tabs/TransactionContention";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IDetailProps {
    instances: InstanceTarget[],
    instanceId: number,
    hosts: HostTarget[],
    eventTypes: EventType[],
    batchId?: string,
    period: Period,
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}

interface IDetailState {
    loading: number,
    peakProcessor: number,
    peakMemory: number,
    instance?: InstanceTarget,
    changes: Change[],
    allEvents: any[],
    events: Event[],
    alerts: Alert[],
    combinedChanges: CombinedChanges[],
    chartPlotBands: ChartPlotBand[],
    chartPlotLines: ChartPlotLine[],
    hosts: HostTarget[]
}

const initialState: IDetailState = {
    loading: DATA_INITIALISING,
    peakProcessor: 0,
    peakMemory: 0,
    changes: [],
    allEvents: [],
    events: [],
    alerts: [],
    combinedChanges: [],
    chartPlotBands: [],
    chartPlotLines: [],
    hosts: []
};

class Detail extends Component<IDetailProps, IDetailState> {
    static propTypes: {
        toggleMenu: PropTypes.Validator<(...args: any[]) => any>,
        applyPeriod: PropTypes.Validator<(...args: any[]) => any>,
        setFilterOptions: PropTypes.Validator<(...args: any[]) => any>;
    };
    static contextType = TimeRangeContext;

    constructor(props: IDetailProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    componentDidUpdate() {
        if (!this.state.instance && this.props.instances.length) {
            this.getData();
        }
    }

    async getData() {
        const { instanceId } = this.props;
        const timeRangeContext = this.context

        this.getInstance()

        const promiseArray = [
            this.getInstanceHosts(),
            this.getChanges(timeRangeContext, instanceId),
        ]
        await Promise.all(promiseArray)

        this.setState({
            loading: DATA_LOADED
        });
    }

    async getChanges(timeRangeContext: TimeRangeContextType, instanceId: number) {

        const allEvents: any[] = await EventsApi.getEventsChangesAndAlerts(timeRangeContext, instanceId)

        let combinedChanges: CombinedChanges[] = [],
            chartPlotLines: ChartPlotLine[] = [];
        for (let index = 0; index < allEvents.length; index++) {
            const currentItem = allEvents[index];

            combinedChanges.push({
                id: index,
                instanceId: currentItem.datasourceid,
                timeslice: currentItem.timeslice,
                [currentItem.event]: currentItem
            });
        }

        // Set the sort order for the combined changes and events.
        combinedChanges.sort(function compare(a, b) {
            var aa: number = dayjs.default(a.timeslice).valueOf();
            var bb: number = dayjs.default(b.timeslice).valueOf();
            return bb - aa;
        });

        const interval = Helper.getInterval(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, timeRangeContext.timeRange?.interval)
        const chartCategories = Helper.getChartAxisCategories(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, interval)
        chartPlotLines = Helper.getChartPlotLines(null, combinedChanges, chartCategories, interval);
        const chartPlotBands = Helper.getChartPlotbands(combinedChanges, chartCategories);
        const alerts = allEvents.filter((e: any) => e.event === 'alert')
        const events = allEvents.filter((e: any) => e.event === 'custom')
        const changes = allEvents.filter((e: any) => e.event === 'change')

        this.setState({
            alerts,
            events,
            changes,
            allEvents,
            combinedChanges,
            chartPlotBands,
            chartPlotLines,
        });
    }

    getInstance() {
        const { instanceId, instances } = this.props;

        const filteredInstances: InstanceTarget[] = instances.filter(instance => Number(instance.id) === Number(instanceId));

        if (filteredInstances.length > 0) {
            this.setState({
                instance: filteredInstances[0]
            });
        }
    }

    async getInstanceHosts() {
        const { instanceId } = this.props;

        let hosts: HostTarget[] = [];

        // Get any hosts related to this instance.
        await api.get(`datasource/host?id=${instanceId}`)
            .then((response: { data: InstanceTargetHost[]; }) => {

                for (let index = 0; index < response.data.length; index++) {

                    // Get any matching hosts.
                    const matchedHosts = this.props.hosts.filter(host => host.id === response.data[index].hostid);

                    if (matchedHosts.length === 1) {
                        // Append the matched hosts to any pre-existing hosts.
                        hosts.push(matchedHosts[0]);
                    }
                }
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.log('An error occurred:', error);
            })
            .then(async() => {
                this.setState({
                    hosts
                });
            });
    }

    shouldShowTab(value: string): boolean {
        const currentInstance: any = this.state.instance
        return currentInstance !== undefined && (!!(currentInstance[`has${value}`] && currentInstance[`collect${value}`]))
    }

    /* Action Methods */

    refresh = () => {

        this.setLoadingStatus(DATA_LOADING);

        this.getData();
    }

    setLoadingStatus = (loading: number) => {
        this.setState({
            loading
        });
    }

    showTransactionContentionTab = (instance: InstanceTarget) => {
        return instance.type === 'cockroachdb' && instance.collectdeadlocks
    }

    render() {
        const { period, setFilterOptions } = this.props;
        const {
            loading, instance, hosts,
            chartPlotBands, chartPlotLines, changes, events, alerts
        } = this.state;

        if (!instance) {
            return <React.Fragment>...</React.Fragment>
        }

        return (
            <div id="content">
                <Helmet>
                    <title>{instance.name} - DBmarlin</title>
                    <meta name="description"
                          content="Instance performance detail view, showing key performance metrics and detailed executed statement and waits information"/>
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    {/* Heading */}
                    <Breadcrumb instanceId={instance.id}
                                type="instances"
                                heading={instance.name}>
                        <Link to="/instances">Analysis</Link>
                        <Link to="/instances">Instances</Link>
                        <Link to={`/instances/${instance.id}/activity`}>{instance.name}</Link>
                        <Switch>
                            <Route exact path={`/instances/${instance.id}/activity/batch`} render={() =>
                                <React.Fragment>
                                    <NavLink to={`/instances/${instance.id}/activity`}
                                             activeClassName={"current-breadcrumb-link"}>Activity</NavLink>
                                    <NavLink to={`/instances/${instance.id}/batch`}
                                             activeClassName={"current-breadcrumb-link"}>Batch</NavLink>
                                </React.Fragment>
                            }/>
                            <Route exact path={`/instances/${instance.id}/statistics`}
                                   render={() => <NavLink to={`/instances/${instance.id}/statistics`}
                                                          activeClassName={"current-breadcrumb-link"}>DB
                                       Statistics</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/activity/groupedstatements`}
                                   render={() => <NavLink to={`/instances/${instance.id}/activity/groupedstatements`}
                                                          activeClassName={"current-breadcrumb-link"}>Grouped
                                       Activity</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/activity`}
                                   render={() => <NavLink to={`/instances/${instance.id}/activity`}
                                                          activeClassName={"current-breadcrumb-link"}>Activity</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/sql-statistics`}
                                   render={() => <NavLink to={`/instances/${instance.id}/sql-statistics`}
                                                          activeClassName={"current-breadcrumb-link"}>SQL
                                       Statistics</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/hosts`}
                                   render={() => <NavLink to={`/instances/${instance.id}/hosts`}
                                                          activeClassName={"current-breadcrumb-link"}>Hosts</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/insights`}
                                   render={() => <NavLink to={`/instances/${instance.id}/insights`}
                                                          activeClassName={"current-breadcrumb-link"}>Insights</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/events`}
                                   render={() => <NavLink to={`/instances/${instance.id}/events`}
                                                          activeClassName={"current-breadcrumb-link"}>Events</NavLink>}/>
                            <Route exact path={`/instances/${instance.id}/tempdb`}
                                   render={() => <NavLink to={`/instances/${instance.id}/tempdb`}
                                                          activeClassName={"current-breadcrumb-link"}>TempDB</NavLink>}/>


                            <Route exact path={`/instances/${instance.id}/blocking-sessions`}
                                   render={() => <NavLink to={`/instances/${instance.id}/blocking-sessions`}
                                                          activeClassName={"current-breadcrumb-link"}>Blocking Sessions</NavLink>}/>

                            <Route exact path={`/instances/${instance.id}/deadlocks`}
                                   render={() => <NavLink to={`/instances/${instance.id}/deadlocks`}
                                                          activeClassName={"current-breadcrumb-link"}>Deadlocks</NavLink>}/>
                        </Switch>
                        <PeriodBreadcrumb/>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh}
                                   applyPeriod={this.props.applyPeriod}/>
                </div>

                <div className="loader">
                    <ConditionalRender if={loading === DATA_LOADING}>
                        <div className="bar"/>
                    </ConditionalRender>
                </div>

                {/* Filters */}
                <ConditionalRender if={this.props.setFilterOptions}>
                    {this.props.setFilterOptions &&
                        <FilterBar period={this.props.period} setFilterOptions={this.props.setFilterOptions}/>}
                </ConditionalRender>

                {/* Detail Views */}
                <TabbedMenu>
                    <TimeShift/>

                    <NavLink exact to={`/instances/${this.props.instanceId}/activity`} activeClassName="active">Database
                        Activity</NavLink>
                    <NavLink to={`/instances/${instance.id}/activity/groupedstatements`} activeClassName="active">Activity
                        (Grouped Statements)</NavLink>

                    <ConditionalRender if={this.shouldShowTab('sqlstatistics')}>
                        <NavLink exact to={`/instances/${instance.id}/sql-statistics`} activeClassName="active">SQL
                            Statistics</NavLink>
                    </ConditionalRender>
                    <NavLink exact to={`/instances/${instance.id}/statistics`} activeClassName="active">Database
                        Statistics</NavLink>
                    <NavLink exact to={`/instances/${this.props.instanceId}/hosts`} activeClassName="active">Host
                        Metrics</NavLink>
                    <span className="badge bg-info"
                          data-tip="Total host count">{hosts.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    <NavLink className="d-none" exact to={`/instances/${this.props.instanceId}/insights`}
                             activeClassName="active">Insights</NavLink>
                    <NavLink exact to={`/instances/${instance.id}/events`} activeClassName="active">Events</NavLink>
                    <ConditionalRender if={this.shouldShowTab('blockingsessions')}>
                        <NavLink exact to={`/instances/${instance.id}/blocking-sessions`} activeClassName="active">Blocking
                            Sessions</NavLink>
                    </ConditionalRender>
                    <ConditionalRender if={this.shouldShowTab('deadlocks')}>
                        <NavLink exact to={`/instances/${instance.id}/deadlocks`}
                                 activeClassName="active">Deadlocks</NavLink>
                    </ConditionalRender>
                    <ConditionalRender if={this.shouldShowTab('tempdb')}>
                        <NavLink exact to={`/instances/${instance.id}/tempdb`} activeClassName="active">TempDB</NavLink>
                    </ConditionalRender>


                    <ConditionalRender if={this.showTransactionContentionTab(instance)}>
                        <NavLink exact to={`/instances/${instance.id}/transaction-contention`} activeClassName="active">Transaction Contention</NavLink>
                    </ConditionalRender>
                </TabbedMenu>

                <Switch>
                    {/* Main Tabs */}
                    <Route exact path="/instances/:instanceId/activity"
                           render={() => <Activity period={period} alerts={alerts} instance={instance} hosts={hosts}
                                                   filterOptions={period.filters.options}
                                                   filterParameters={period.filters.parameters}
                                                   chartPlotBands={chartPlotBands} chartPlotLines={chartPlotLines}
                                                   changes={changes} events={events}
                                                   applyPeriod={this.props.applyPeriod}
                                                   setFilterOptions={setFilterOptions}/>}/>
                    <Route exact path="/instances/:instanceId/activity/groupedstatements"
                           render={() => <ActivityGroupedStatements alerts={alerts} instance={instance} hosts={hosts}
                                                                    period={period}
                                                                    filterOptions={period.filters.options}
                                                                    filterParameters={period.filters.parameters}
                                                                    chartPlotBands={chartPlotBands}
                                                                    chartPlotLines={chartPlotLines} changes={changes}
                                                                    events={events}
                                                                    applyPeriod={this.props.applyPeriod}
                                                                    setFilterOptions={setFilterOptions}/>}/>
                    <Route exact path="/instances/:instanceId/statistics"
                           render={() => <Statistics instance={instance}
                                                     applyPeriod={this.props.applyPeriod}/>}/>
                    <Route exact path="/instances/:instanceId/hosts"
                           render={() => <Hosts instance={instance} hosts={hosts} chartPlotLines={chartPlotLines}

                                                applyPeriod={this.props.applyPeriod}
                                                comparisonSupported={period.comparisonSupported}/>}/>
                    <Route exact path="/instances/:instanceId/sql-statistics"
                           render={() => <SqlStatistics chartPlotBands={chartPlotBands} chartPlotLines={chartPlotLines}
                                                        changes={changes} events={events} alerts={alerts}
                                                        applyPeriod={this.props.applyPeriod}
                                                        instance={instance}/>}/>
                    <Route exact path="/instances/:instanceId/insights" render={() => <Insights/>}/>
                    <Route exact path="/instances/:instanceId/events"
                           render={() => <EventHistoryContainer allEvents={this.state.allEvents} instance={instance}
                                                                applyPeriod={this.props.applyPeriod}
                                                                instances={this.props.instances}
                                                                hosts={this.props.hosts}
                                                                eventTypes={this.props.eventTypes}/>}/>
                    <Route exact path="/instances/:instanceId/blocking-sessions"
                           render={() => <BlockingSessions period={period}
                                                           instance={instance}/>}/>

                    <Route exact path="/instances/:instanceId/transaction-contention"
                           render={() => <TransactionContention instance={instance}/>}/>
                    <Route exact path="/instances/:instanceId/deadlocks"
                           render={() => <Deadlocks instance={instance} setFilterOptions={setFilterOptions}
                                                    filterOptions={period.filters.options}/>}/>
                    <Route exact path="/instances/:instanceId/tempdb" render={() => <TempDb instance={instance} setFilterOptions={setFilterOptions} filterOptions={period.filters.options}
                                                                                            applyPeriod={this.props.applyPeriod} />} />
                </Switch>

            </div>
        );
    }
}

Detail.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Detail;
