// Components
import ExecutionPlans from "../../../component/statement/ExecutionPlans";
import StatementText from "../../../component/statement/StatementText";

// Types
import InstanceTarget from "../../../types/instance/InstanceTarget";

// Utils
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useState } from "react";
import Statement from "../../../types/instance/Statement";

function TextAndPlan(props: {
    instance: InstanceTarget,
    batchId?: string,
    batchStatementId?: string,
    setExecutionPlanOptions: Function,
    statementId?: string
}) {
    const {instance, statementId, batchId, batchStatementId} = props
    const [statement, setStatement] = useState<Statement>();

    // send the needed id. if it's batchStatementId then it's a statement inside a batch
    const currentStatementId = batchStatementId || statementId || batchId

    return (<Splitter layout='vertical'>
        <SplitterPanel size={50} className="plan-master-panel">
            <StatementText instanceId={instance.id} id={currentStatementId} setStatement={setStatement}/>
        </SplitterPanel>
        <SplitterPanel size={50} className="plan-detail-panel">
            <ExecutionPlans instance={instance} batchId={batchId} statement={statement} statementId={batchStatementId || statementId}
                            setExecutionPlanOptions={props.setExecutionPlanOptions}/>
        </SplitterPanel>
    </Splitter>);
}

export default TextAndPlan
