import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Third-parties.
import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import Skeleton from "react-loading-skeleton";

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import EventsDataTable from '../../../types/instance/tables/EventsDataTable';

// Components.
import RegisterEvent from '../../RegisterEvent';
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';

// Constants.
import ReactTooltip from "react-tooltip";
import {
    capitalizeString,
    getDefaultPageSize,
    prettifyChangesDetailsString,
} from "../../../helpers/utils";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { InstanceContext } from "../../../context/InstanceContext";
import { DATA_LOADING } from "../../Constants";
import InstanceEvents from "../InstanceEvents";

function EventsTable(props: { loading: boolean | number, eventsDataTable: EventsDataTable[] }) {
    const searchParams = new URLSearchParams(window.location.search)
    const [tableFilter, setTableFilter] = useState<string>(searchParams.get('filter') || '');

    const {instances} = useContext(InstanceContext);

    const timeRangeContext = useContext(TimeRangeContext)

    function clearTableFilter() {
        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = useMemo(() => {
        const filteredData = props.eventsDataTable.filter(row => {
            return (
                row.instanceName.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.changeType.toLowerCase().includes(tableFilter.toLowerCase()) ||
                ((!row.details) ? '' : row.details).toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.changeFrom?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                (row.changeFrom === null && 'null'.includes(tableFilter.toLowerCase()) && row.changeType === 'Parameter Altered') ||
                (row.changeTo === null && 'null'.includes(tableFilter.toLowerCase()) && row.changeType === 'Parameter Altered') ||
                (row.timeslice.toString().includes(tableFilter)) ||
                row.changeTo?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.event?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.startedEnded?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.units?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.threshold?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.statistic?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.title?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                (row.decreasing?.toString() === 'true' && 'less than'.includes(tableFilter.toLowerCase())) ||
                (row.decreasing?.toString() === 'false' && 'greater than'.includes(tableFilter.toLowerCase()))
            )
        })
        // TODO: temporary fix for oracle event type name, a different solution is needed here
        let instanceType = ''

        filteredData.forEach(row => {
            if (props.eventsDataTable) {
                instanceType = instances.find(instance => instance.id === row.instanceId)?.type || ''
            }
            if (instanceType === 'oracle') {
                row.changeType = row.changeType.replace('database', 'schema');
            }
        })
        return filteredData
    }, [props.eventsDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            accessor: 'timesliceEnd',
            disableSortBy: true
        },
        {
            accessor: 'color',
            disableSortBy: true
        },
        {
            accessor: 'htmlIconCode',
            disableSortBy: true
        },
        {
            accessor: 'instanceId',
            disableSortBy: true
        },
        {
            accessor: 'title',
            disableSortBy: true
        },
        {
            accessor: 'detailsUrl',
            disableSortBy: true
        },
        {
            accessor: 'changeFrom',
            disableSortBy: true
        },
        {
            accessor: 'changeTo',
            disableSortBy: true
        },
        {
            accessor: 'isHost',
            disableSortBy: true
        },
        {
            accessor: 'startedEnded',
            disableSortBy: true
        },
        {
            accessor: 'units',
            disableSortBy: true
        },
        {
            accessor: 'statistic',
            disableSortBy: true
        },
        {
            accessor: 'threshold',
            disableSortBy: true
        },
        {
            accessor: 'decreasing',
            disableSortBy: true
        },
        {
            Header: 'Time',
            accessor: 'timeslice',
            className: 'fw-bold',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => {
                const type = cell.row.values.isHost ? '/hosts' : '/instances';
                return cell.row.values.timesliceEnd !== undefined ? (
                    cell.row.values.instanceName === 'Unknown' ? (
                        `${dayjs.default(cell.row.values.timeslice).format('YYYY-MM-DD HH:mm')} - ${dayjs.default(cell.row.values.timesliceEnd).format('YYYY-MM-DD HH:mm')}`
                    ) : (
                        <Link
                            to={`${type}/${cell.row.values.instanceId}/activity${Helper.getQueryStringNoInterval(timeRangeContext, dayjs.default(cell.row.values.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(cell.row.values.timesliceEnd).add(30, 'minute').format('YYYY-MM-DD+HH:mm'))}`}>{`${dayjs.default(cell.row.values.timeslice).format('YYYY-MM-DD HH:mm')} - ${dayjs.default(cell.row.values.timesliceEnd).format('YYYY-MM-DD HH:mm')}`}</Link>
                    )
                ) : (
                    cell.row.values.instanceName === 'Unknown' ? (
                        dayjs.default(cell.row.values.timeslice).format('YYYY-MM-DD HH:mm')
                    ) : (
                        <Link
                            to={`${type}/${cell.row.values.instanceId}/activity${Helper.getQueryStringNoInterval(timeRangeContext, dayjs.default(cell.row.values.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(cell.row.values.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'))}`}>{dayjs.default(cell.row.values.timeslice).format('YYYY-MM-DD HH:mm')}</Link>
                    )
                )
            },
        },
        {
            Header: 'Entity',
            accessor: 'instanceName',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => {
                const type = cell.row.values.isHost ? '/hosts' : '/instances';
                return cell.row.values.instanceName === 'Unknown' ? (
                    <span>Unknown</span>
                ) : (
                    <Link
                        to={`${type}/${cell.row.values.instanceId}/activity${Helper.getQueryStringNoInterval(timeRangeContext, dayjs.default(cell.row.values.timeslice).subtract(30, 'minute').format('YYYY-MM-DD+HH:mm'), dayjs.default(cell.row.values.timeslice).add(30, 'minute').format('YYYY-MM-DD+HH:mm'))}`}>{cell.row.values.instanceName}</Link>
                )
            },
        },
        {
            Header: 'Event',
            accessor: 'event',
            defaultCanSort: false,
            Cell: ({cell}) => {
                return <span className="text-capitalize">{cell.row.values.event}</span>
            }
        },
        {
            Header: 'Type',
            accessor: 'changeType',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => {

                return (
                    cell.row.values.changes !== null || cell.row.values.event === 'change' ? (
                        <React.Fragment>
                            <span className={`${cell.row.values.htmlIconCode !== '' ? 'with-icon': ''} event-type `}>
                                    <div>
                                        {cell.row.values.event !== 'custom' ? capitalizeString(cell.row.values.changeType) : cell.row.values.changeType}
                                    </div>
                                        {cell.row.values.htmlIconCode !== '' && (
                                            <i className={`${cell.row.values.htmlIconCode} ms-2`} aria-hidden="true"/>
                                        )}
                            </span>
                        </React.Fragment>
                    ) : (
                        <Skeleton/>
                    )
                )
            }
        },
        {
            Header: 'Details',
            accessor: 'details',
            defaultCanSort: false,
            Cell: ({cell}) => {
                return <InstanceEvents event={cell.row.values} />
            }
        },
    ], [timeRangeContext, props.eventsDataTable]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id', 'timesliceEnd', 'color', 'instanceId', 'htmlIconCode', 'title', 'detailsUrl', 'changeFrom', 'changeTo', 'startedEnded', 'units', 'statistic', 'threshold', 'decreasing'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'timeslice', desc: true}]
            }
        },
        useSortBy, usePagination);

    const csvData = useMemo(() => {
        let csvAlteredData = JSON.parse(JSON.stringify(data))
        csvAlteredData = csvAlteredData.map((row: EventsDataTable) => {
            row.details = row.event === 'alert' ? `${row.startedEnded} ${row.statistic} ${row.decreasing?.toString() === 'true' ? 'less than' : ' greater than'} ${row.threshold}${row.units}` : row.details;
            row.details = row.event !== 'alert' ? `${row.title === '' ? row.details : row.title}` : row.details;
            row.details += row.changeFrom && row.changeTo ? `(from ${prettifyChangesDetailsString(row.changeFrom + (row.units ? row.units : ''))} to ${prettifyChangesDetailsString(row.changeTo + (row.units ? row.units : ''))})` : ''
            return row;
        })
        return csvAlteredData
    }, [data])

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [pageIndex])

    ReactTooltip.rebuild();

    return (
        <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2 table-search">
                <div className="col col-md-9">
                    <RegisterEvent timeRangeContext={timeRangeContext}/>
                    <CSVLink role="button"
                             headers={[
                                 {label: 'Timeslice', key: 'timeslice'},
                                 {label: 'Entity', key: 'instanceName'},
                                 {label: 'Event', key: 'event'},
                                 {label: 'Type', key: 'changeType'},
                                 {label: 'Details', key: 'details'},
                                 {label: 'Url', key: 'detailsUrl'}]}
                             data={csvData}
                             download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Changes and Events.csv`}
                             className="btn btn-sm btn-primary">
                        <i className="fal fa-file-export fa-fw"/><span>Export</span>
                    </CSVLink>
                    <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                        className="far fa-undo"/><span>Clear</span></button>
                </div>
                <div className="col col-md-3">
                    <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                           value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)}/>
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={(column as any).className} key={index}>
                                    {column.render('Header')}
                                    {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                            <i className="fal fa-sort-amount-up-alt"/> :
                                            <i className="fal fa-sort-amount-down-alt"/>) :
                                        <i className="fal fa-sort-amount-down-alt text-light"/>) : ''}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={(cell.column as any).className}
                                            style={{borderLeft: (cell.column as any).color}}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <NoData
                    error={null}
                    loading={props.loading === DATA_LOADING || props.loading === true}
                    length={data.length}
                />
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </React.Fragment>
    );
}

export default EventsTable;
