import React from 'react';

// Third-parties.
import ReactTooltip from 'react-tooltip';

// Types.
import Period from '../../types/Period';
import InstanaSnapshot from '../../types/integration/InstanaSnapshot';

function InstanaInstanceButton(props: { period: Period, instanaSnapshots: InstanaSnapshot[] }) {
        
    ReactTooltip.rebuild();

    return (
        props.instanaSnapshots.length > 0 ? (
            <button type="button" className="btn btn-heading stan" data-tip="View this instance in Instana" onClick={() => window.open(`${props.instanaSnapshots[0].url.replace('timeline.to=', `timeline.to=${props.period.ui.current.to.valueOf()}`).replace('timeline.fm=', `timeline.fm=${props.period.ui.current.from.valueOf()}`)}&timeline.ws=${props.period.ui.current.to.diff(props.period.ui.current.from, 'millisecond')}`, "_blank")}><i className="fak fa-stan fa-fw" aria-hidden="true"></i></button>
        ) : (
            <React.Fragment></React.Fragment>
        )
    );
}

export default InstanaInstanceButton;