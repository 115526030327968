import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss'

// Context.
import { InstanceContext } from '../../context/InstanceContext';
import { HostContext } from '../../context/HostContext';

// Components.
import StatusIcon from '../../component/StatusIcon';
import ConditionalRender from "../../helpers/ConditionalRender";
import { instanceMetaData, instanceTabs } from "../Constants";
// Types
import InstanceTarget from "../../types/instance/InstanceTarget";
import HostTarget from "../../types/host/HostTarget";
import Tags from "../../types/Tags";
import TagsList from "../TagsList";
import InstanceApi from "../../api/instance/InstanceApi";

function HeaderFilter(props: { instanceId: number, hostId: number, heading: string, filterItems?: (item: InstanceTarget | HostTarget) => void, type?: string }) {
    const {instances} = useContext(InstanceContext);
    const {hosts} = useContext(HostContext);
    const [filter, setFilter] = useState<string>('');
    const [instanceData, setInstanceData] = useState<any>({});
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [tags, setTags] = useState<Tags[]>([]);

    const hostsToShow: HostTarget[] = useMemo(() => {
        return JSON.parse(JSON.stringify(hosts))
    }, [hosts])
    const instancesToShow: InstanceTarget[] = useMemo(() => {
        return JSON.parse(JSON.stringify(props.filterItems ? instances.filter(props.filterItems) : instances))
    }, [instances, props.filterItems])

    function clearFilter() {
        // Clear any pre-existing filter values.
        setFilter('');
    }

    useEffect(() => {
      InstanceApi.getTags(props.instanceId).then(setTags)
    }, []);

    useEffect(() => {
        let getSelectedInstanceData = instances.find(instance => instance.id === props.instanceId) || {}
        setInstanceData(getSelectedInstanceData)
    }, [props.instanceId]);

    return (
        <>
            <div className="dropdown d-inline">
                {(props.instanceId > 0 || props.hostId > 0) && (
                    <StatusIcon instanceId={props.instanceId} hostId={props.hostId} mode="public"/>
                )}
                <button type="button" id="filter" className="btn btn-dropdown dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false" onClick={() => searchInputRef.current?.focus()}>
                    {((props.heading.length > Number(process.env.REACT_APP_CHART_LEGEND_LENGTH) ? `${props.heading.substring(0, Number(process.env.REACT_APP_CHART_LEGEND_LENGTH))}...` : props.heading))}
                </button>
                <div id="dropdown-menu" className="row g-0 dropdown-menu dropdown-menu-scroll" aria-labelledby="filter"
                     onClick={(e) => e.stopPropagation()}>
                    <ul className="col">
                        <li className="search">
                            <div className="row g-0">
                                <div className="col-9">
                                    <input type="text" autoFocus className="form-control"
                                           placeholder={`Search ${props.type}`} value={filter}
                                           onChange={(e) => setFilter(e.target.value.toLowerCase())}
                                           ref={searchInputRef}/>
                                </div>
                                <div className="col-3 text-end">
                                    <button type="button" className="btn btn-small btn-dark" onClick={clearFilter}><i
                                        className="far fa-undo" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>

                        <ConditionalRender
                            if={props.type === 'instances' && instancesToShow.length > 0}>
                            {instancesToShow.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter(item => item.name.toLowerCase().includes(filter)).map((item: { id: number; name: string; }) => (
                                <li key={item.id}>
                                    {item.id === props.instanceId ? (
                                        <Link to={`/instances/${item.id}/activity`} className="dropdown-item active"
                                              type="button">{item.name}</Link>
                                    ) : (
                                        <Link to={`/instances/${item.id}/activity`} className="dropdown-item"
                                              type="button">{item.name}</Link>
                                    )}
                                </li>
                            ))}
                        </ConditionalRender>
                        <ConditionalRender if={props.type === 'hosts' && hostsToShow.length > 0}>
                            {hostsToShow.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter(item => item.name.toLowerCase().includes(filter)).map((item: { id: number; name: string; }) => (
                                <li key={item.id}>
                                    {item.id === props.hostId ? (
                                        <Link to={`/hosts/${item.id}`} className="dropdown-item active"
                                              type="button">{item.name}</Link>
                                    ) : (
                                        <Link to={`/hosts/${item.id}`} className="dropdown-item"
                                              type="button">{item.name}</Link>
                                    )}
                                </li>
                            ))}
                        </ConditionalRender>
                    </ul>
                </div>

            </div>
            <ConditionalRender if={Object.keys(instanceData).some(key => instanceMetaData.includes(key))}>
                <div className="dropdown d-inline">

                    <button type="button" id="info" className="btn info-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        <i className="fa fa-info-circle" aria-hidden="true"/>
                    </button>

                    <div id="info-menu" className="row g-0 dropdown-menu dropdown-menu-scroll" aria-labelledby="info"
                         onClick={(e) => e.stopPropagation()}>
                        {Object.entries(instanceData).map(([key, value]) => {
                            const shouldDisplay = instanceMetaData.includes(key);

                            if (shouldDisplay) {
                                const formattedValue = JSON.stringify(value).replaceAll('"', '');
                                const isValueTrue = value === true;
                                const isKeyInInstanceTabs = instanceTabs.includes(key);
                                const isKeyNotCollected = isValueTrue && isKeyInInstanceTabs && !instanceData[`${key.replace('has', 'collect')}`];
                                return (
                                    <ConditionalRender key={key} if={shouldDisplay}>
                                        <div key={key}>
                                            <span>{key}</span>
                                            <span>{formattedValue} {isValueTrue && isKeyInInstanceTabs && isKeyNotCollected ? '(not collected)' : ''}</span>
                                        </div>
                                    </ConditionalRender>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </ConditionalRender>

            <ConditionalRender if={true}>
                <TagsList tagList={tags} />
            </ConditionalRender>
            <br/>
        </>
    );
}

export default HeaderFilter;
