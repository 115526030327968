import { createContext, useContext } from 'react';

// Types.
import InstanaIntegration from '../types/integration/InstanaIntegration';

// Set the internal context object.
let instanaIntegrations: InstanaIntegration[] = [];

// Define the context type, including the update function.
export type InstanaIntegrationContextType = {
    instanaIntegrations: InstanaIntegration[];
    setInstanaIntegrations: (InstanaIntegrations: InstanaIntegration[]) => void;
}

// Define the context object.
export const InstanaIntegrationContext = createContext<InstanaIntegrationContextType>({ instanaIntegrations: instanaIntegrations, setInstanaIntegrations: instanaIntegrations => console.warn('No Instana integrations set.', instanaIntegrations) });
InstanaIntegrationContext.displayName = 'Instana Integrations';

// Export the update function for the context object.
export const useInstanaIntegrations = () => useContext(InstanaIntegrationContext);