// React.
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-parties.
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";

// Types.
import { InstanceOptions, OracleSqlHashWithMetric, StatisticsResultsTableProps } from "./types";

// Helper.
import Helper from '../../helpers/Helper';
import {
    archiverUrl,
    fetchResults, fetchWithAuthorization,
    getToolTipPosition,
    stringFormatting
} from '../../helpers/utils';
import { InstanceContext } from "../../context/InstanceContext";

// Components.
import Alert from "../../component/Alert";
import NoData from "../../component/NoData";
import StatementToolTip from "../../component/instance/table/StatementToolTip";
import { CSVLink } from "react-csv";

// Constants.
import { DEFAULT_TABLE_METRICS, SQL_STATISTICS_API_VERSION } from "./constants";
import ConditionalRender from "../../helpers/ConditionalRender";

const StatementsTable = (props: StatisticsResultsTableProps) => {
    const searchParams = new URLSearchParams(window.location.search)
    const apiVersion = parseInt(searchParams.get('api_version') || '', 10) || SQL_STATISTICS_API_VERSION
    const [sqlStatistics, setSqlStatistics] = useState<OracleSqlHashWithMetric[]>([]);
    const [statements, setStatements] = useState<OracleSqlHashWithMetric[][]>([]);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const {instances} = useContext(InstanceContext);
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    // Update this if data that are not in topN should not be updated
    const withOutsideTopNData = true
    ReactTooltip.rebuild();


    const getInstanceType = (id: number) => {
        const instanceFound = instances.find(instance => instance.id === id)
        return instanceFound ? instanceFound.type : 'unknown'
    }

    const aggregateInt = useMemo(() => {
        switch (props.options.aggregate) {
            case 'auto':
                return Math.max(...props.instanceOptions.map(option => Helper.getInterval(dayjs(option.periodStart), dayjs(option.periodEnd))))
            case 'timeslice':
                return Math.max(...props.instanceOptions.map(option => instances.find(i => i.id === option.instanceId)?.batchintervalseconds || 0)) * 1000
            default:
                return parseInt(props.options.aggregate, 10)
        }
    }, [props.options.aggregate])


    const columns: Column[] = useMemo(() => {
        let defaultColumns = [
            {
                Header: '#',
                accessor: 'sqlhash',
                headerClassName: 'text',
                className: 'concatenate',
                canSort: true,
                // @ts-ignore
                Cell: ({cell}) => {
                    const from = dayjs(cell.row.original.periodStart).format('YYYY-MM-DD+HH:mm')
                    const to = dayjs(cell.row.original.periodEnd).format('YYYY-MM-DD+HH:mm')
                    const interval = useMemo(() => {
                        return Math.max(...props.instanceOptions.map(option => Helper.getInterval(dayjs(option.periodStart), dayjs(option.periodEnd))))
                    }, [props.generateId])

                    const customId = props.options.showSqlId ? cell.row.original.sqlid : cell.row.original.sqlhash
                    // @ts-ignore
                    const getColor = props.statementColors[customId]
                    return <React.Fragment>
                            <span className="me-1 ps-1"
                                  style={{backgroundColor: getColor}}>&nbsp;</span>
                        <Link target="_blank"
                              to={`/reports/sql-stats-explorer?instance=${cell.row.original.instanceId}&fm=${from}&to=${to}&tz=${timezone}&interval=${interval}&customId=${customId}&filter=${cell.row.values.sqlhash}&metric=${props.options.metric}&chart=${props.options.chart}&showSqlId=${props.options.showSqlId}`}
                              className='mr-1 open_statement'>{props.options.showSqlId && cell.row.original.sqlid ? cell.row.original.sqlid : cell.row.values.sqlhash}</Link>
                    </React.Fragment>
                }
            }, {
                Header: 'Statement',
                accessor: 'sqltext',
                headerClassName: 'text',
                className: 'concatenate-large',
                canSort: true,
                Cell: ({cell}: any) =>  <p className='statement-text'>{cell.row.values.sqltext}</p>
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                headerClassName: 'concatenate-medium text-end grey',
                className: 'concatenate-medium text-end grey',
                canSort: true,
                sortDescFirst: true,
                sortType: (rowA: any, rowB: any) => {
                    return (rowA.original.duration - rowB.original.duration) || (rowA.values.duration_1 - rowB.values.duration_1)
                },
                // @ts-ignore
                Cell: ({cell}) =>
                    <React.Fragment>
                        {cell.row.original.duration !== undefined ? Helper.getTimeInEnglish(cell.row.original.duration) : ''}
                    </React.Fragment>
            },
            {
                Header: 'Executions',
                accessor: 'executions',
                headerClassName: 'concatenate-medium text-end grey',
                className: 'concatenate-medium text-end grey',
                canSort: true,
                sortDescFirst: true,
                sortType: (rowA: any, rowB: any) => {
                    return (rowA.original.executions - rowB.original.executions) || (rowA.values.executions_1 - rowB.values.executions_1)
                },
                // @ts-ignore
                Cell: ({cell}) => {
                    return Helper.getFormattedNumber(cell.row.original.executions === 0 ? 0 : cell.row.original.executions || null) || ''
                }

            },
            {
                Header: 'Avg. Duration',
                accessor: 'average_duration',
                headerClassName: 'concatenate-medium text-end grey',
                className: 'concatenate-medium text-end grey',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) => cell.row.values.average_duration ? Helper.getTimeInEnglish(cell.row.values.average_duration) : ''
            },
        ]
        const doesSelectedMetricExist = DEFAULT_TABLE_METRICS.indexOf(props.options.metric)
        if (doesSelectedMetricExist < 0 && props.options.metric) {
            defaultColumns.push({
                Header: stringFormatting(props.options.metric).replace(/_/gi, ' '),
                accessor: props.options.metric,
                headerClassName: 'concatenate-medium col-width-4 text-end grey',
                className: 'concatenate-medium text-end grey',
                canSort: true,
                sortDescFirst: true,
                sortType: (rowA: any, rowB: any) => {
                    return (rowA.original[`${props.options.metric}`] - rowB.original[`${props.options.metric}`]) || (rowA.values[`${props.options.metric}_1`] - rowB.values[`${props.options.metric}_1`])
                },
                // @ts-ignore
                Cell: ({cell}) => {
                    const value = cell.row.values[props.options.metric] !== undefined ? Helper.getNumberFormatType(props.options.metric, cell.row.values[props.options.metric]) : ''
                    return <React.Fragment>
                        {`${value}`}
                    </React.Fragment>
                }
            })
        }
        // add a set of 3 columns for every extra instance
        for (let i = 1; i < props.instanceOptions.length; i++) {
            defaultColumns.push(
                {
                    Header: 'Duration',
                    accessor: `duration_${i}`,
                    headerClassName: 'concatenate-medium text-end',
                    className: 'concatenate-medium text-end',
                    canSort: true,
                    sortDescFirst: true,
                    sortType: (rowA: any, rowB: any) => {
                        return (rowA.values.duration_1 - rowB.values.duration_1) || (rowA.original.duration - rowB.original.duration)
                    },
                    // @ts-ignore
                    Cell: ({cell}) =>
                        <React.Fragment>
                            {cell.row.values[`duration_${i}`] !== undefined ? Helper.getTimeInEnglish(cell.row.values[`duration_${i}`]) : ''}
                            <i data-tip={Helper.getToolTip(cell.row.values.duration, cell.row.values[`duration_${i}`], 'duration')}
                               className={Helper.getChangeIcon(cell.row.values.duration, cell.row.values[`duration_${i}`])}/>
                        </React.Fragment>
                },
                {
                    Header: 'Executions',
                    accessor: `executions_${i}`,
                    headerClassName: 'concatenate-medium text-end',
                    className: 'concatenate-medium text-end',
                    canSort: true,
                    sortDescFirst: true,
                    sortType: (rowA: any, rowB: any) => {
                        return (rowA.values.executions_1 - rowB.values.executions_1) || (rowA.original.executions - rowB.original.executions)
                    },
                    // @ts-ignore
                    Cell: ({cell}) => cell.row.values[`executions_${i}`] ?
                        <React.Fragment>
                            {Helper.getFormattedNumber(cell.row.values[`executions_${i}`])}
                            <i data-tip={Helper.getToolTip(cell.row.values.executions, cell.row.values[`executions_${i}`], 'executions')}
                               className={Helper.getChangeIcon(cell.row.values.executions, cell.row.values[`executions_${i}`])}/>
                        </React.Fragment>
                        : ''
                },
                {
                    Header: 'Avg. Duration',
                    accessor: `average_duration_${i}`,
                    headerClassName: 'concatenate-medium text-end',
                    className: 'concatenate-medium text-end',
                    canSort: true,
                    sortType: 'basic',
                    sortDescFirst: true,
                    // @ts-ignore
                    Cell: ({cell}) => cell.row.values[`average_duration_${i}`] ?
                        <React.Fragment>
                            {cell.row.values[`average_duration_${i}`] !== undefined ? Helper.getTimeInEnglish(cell.row.values[`average_duration_${i}`]) : ''}
                            <i data-tip={Helper.getToolTip(cell.row.values.average_duration, cell.row.values[`average_duration_${i}`], 'average_duration')}
                               className={Helper.getChangeIcon(cell.row.values.average_duration, cell.row.values[`average_duration_${i}`])}/>
                        </React.Fragment>
                        : ''
                },
            )
            if (doesSelectedMetricExist < 0 && props.options.metric) {
                defaultColumns.push({
                    Header: stringFormatting(props.options.metric).replace(/_/gi, ' '),
                    accessor: `${props.options.metric}_${i}`,
                    headerClassName: 'concatenate-medium col-width-4 text-end',
                    className: 'concatenate-medium text-end',
                    canSort: true,
                    sortDescFirst: true,
                    sortType: (rowA: any, rowB: any) => {
                        return (rowA.values[`${props.options.metric}_${i}`] - rowB.values[`${props.options.metric}_${i}`]) || (rowA.original[`${props.options.metric}`] - rowB.original[`${props.options.metric}`])
                    },
                    // @ts-ignore
                    Cell: ({cell}) => cell.row.values[`${props.options.metric}_${i}`] ?
                        <React.Fragment>
                            {`${Helper.getNumberFormatType(props.options.metric, cell.row.values[`${props.options.metric}_${i}`])}`}
                            <i data-tip={Helper.getToolTip(cell.row.values[`${props.options.metric}`], cell.row.values[`${props.options.metric}_${i}`], props.options.metric)}
                               className={Helper.getChangeIcon(
                                   cell.row.values[`${props.options.metric}`],
                                   cell.row.values[`${props.options.metric}_${i}`],
                               )}/>
                        </React.Fragment> : ''

                })
            }
        }

        return defaultColumns
    }, [props.generateId, props.statementColors]);

    // Table data
    useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const apiTagString = `${archiverUrl(apiVersion)}/sql/statistic/sql?`
                const promiseArray: any[] = []
                const statementsArray: any[] = []
                let customIdApiString = ''
                if (props.options.customSqlId) {
                    customIdApiString = props.options.showSqlId ? `&sqlid=${props.options.customSqlId}` : `&sqlhash=${props.options.customSqlId}`
                }
                props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
                    const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
                    const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
                    promiseArray.push(fetchResults([fetchWithAuthorization(`${apiTagString}from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${instanceOption.instanceType}&limit=${props.options.topN}&${customIdApiString}`)], false))
                })

                const results = await Promise.all(promiseArray)

                results.forEach((result => {
                    if (typeof result[0] !== 'string') {
                        statementsArray.push(result[0])
                    } else {
                        // error came back from api call
                        statementsArray.push([])
                    }
                }))

                setLoading(false)
                setStatements(statementsArray);
            } catch (x: any) {
                setError(x.message);
                setLoading(false);
                setSqlStatistics([]);
            }
        }

        load();
    }, [props.generateId]);

    const getMissingData = async(instanceOption: InstanceOptions, sqlhash: string) => {
        const apiTagString = `${archiverUrl(apiVersion)}/sql/statistic/sql?`
        const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
        const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
        const results = await fetchResults([fetchWithAuthorization(`${apiTagString}from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${instanceOption.instanceType}&limit=${props.options.topN}&sqlhash=${sqlhash}`)], false)
        return results[0]
    }

    const concatArrays = async(list: any[]) => {
        const arr: OracleSqlHashWithMetric[] = []
        list.forEach((item, idx: number) => {
            item.forEach((subItem: any) => {
                if (idx === 0) {
                    arr.push({
                        ...subItem,
                        instanceId: props.instanceOptions[idx].instanceId,
                        periodStart: props.instanceOptions[idx].periodStart,
                        periodEnd: props.instanceOptions[idx].periodEnd,
                        average_duration: subItem.duration / (subItem.executions || 1)
                    })
                } else {
                    const atIndex: number = arr.findIndex(data => data.sqlhash === subItem.sqlhash)
                    const statementExist: any = atIndex > -1 ? arr[atIndex] : {
                        sqlhash: subItem.sqlhash,
                        sqltext: subItem.sqltext,
                        sqlid: subItem.sqlid,
                    }
                    statementExist[`executions_${idx}`] = subItem.executions
                    statementExist[`duration_${idx}`] = subItem.duration
                    statementExist[`average_duration_${idx}`] = subItem.duration / (subItem.executions || 1)
                    statementExist.instanceId = props.instanceOptions[idx].instanceId
                    statementExist.periodStart = props.instanceOptions[idx].periodStart
                    statementExist.periodEnd = props.instanceOptions[idx].periodEnd

                    if (!selectedMetricExist) {
                        statementExist[`${props.options.metric}_${idx}`] = subItem[props.options.metric]
                    }

                    if (atIndex === -1) {
                        arr.push(statementExist)
                    }
                }
            })
        })
        arr.sort((a: any, b: any) => a?.[props.options.metric] - b?.[props.options.metric])
        if (withOutsideTopNData) {
            await updateArrWithMissingData(arr)
        } else {
            setSqlStatistics(arr)
        }
    }

    const updateArrWithMissingData = async(list: any[]) => {
        for (let item in list) {
            if (!list[item].executions || !list[item].duration || !list[item][`${props.options.metric}`]) {
                const data = await getMissingData(props.instanceOptions[0], list[item].sqlhash)
                if (data?.length) {
                    data[0].average_duration = data[0].duration / (data[0].executions || 1)
                    list[item] = {...list[item], ...data[0]}
                }
            }
            if (!list[item].executions_1 || !list[item].duration_1 || !list[item][`${props.options.metric}_1`]) {
                const data = await getMissingData(props.instanceOptions[1], list[item].sqlhash)
                if (data?.length) {
                    list[item].executions_1 = data[0].executions
                    list[item].duration_1 = data[0].duration
                    list[item].average_duration_1 = data[0].duration / (data[0].executions || 1)
                    list[item][`${props.options.metric}_1`] = data[0][props.options.metric]
                }
            }
        }
        setSqlStatistics(list)
    }

    useEffect(() => {
        if (statements.length === props.instanceOptions.length) {
            void concatArrays(statements)
        }
    }, [props.generateId, statements])


    const data = useMemo(() => {
        const filteredData = sqlStatistics.length && sqlStatistics.filter((row) =>
            row.sqltext?.toLowerCase().includes(tableFilter.toLowerCase()) ||
            row.sqlid?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
            row.sqlhash?.toLowerCase().includes(tableFilter.toLowerCase())) || []

        filteredData.forEach((row) => {
            row.sqltext = row.sqltext?.replace(/"/gi, '\'') || ''
        })

        return filteredData
    }, [tableFilter, sqlStatistics]);

    const getCsvData = useCallback(() => {
        const stmts = JSON.parse(JSON.stringify(statements))
        let csvRows: any[] = []
        try {
            stmts.forEach((statementsSet: any, index: number) => {
                csvRows = csvRows.concat(statementsSet.map((item: any) => {
                    const from = dayjs(props.instanceOptions[index].periodStart).format('YYYY-MM-DD HH:mm')
                    const to = dayjs(props.instanceOptions[index].periodEnd).format('YYYY-MM-DD HH:mm')

                    if (item.timeslice) {
                        item.timeslice = item.timeslice.toString().replace(' ', ' - ')
                    }

                    return {
                        instance: `${props.instanceOptions[0].instanceName} | ${from} - ${to} `,
                        ...item
                    };
                }))
            })

            return [...csvRows]
        } catch (ignore) {
            return []
        }
    }, [props.instanceOptions, props, statements])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable(
        {
            columns,
            data,
            initialState:
                {
                    pageIndex: 0,
                    pageSize: 200,
                    sortBy: [{id: props.options.metric, desc: true}],
                },
            disableSortRemove: true
        }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }

    const selectedMetricExist = useMemo(() => {
        return DEFAULT_TABLE_METRICS.indexOf(props.options.metric) > -1
    }, [props.generateId]);

    return (
        <div className="card">
            <div className="card-header">
                Statements
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseStatements" aria-expanded="false" aria-controls="collapseStatements"/>
            </div>
            <div id="collapseStatements" className="card-body collapse show">
                {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 &&
                    (<Alert
                        message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by the number of executions.`}
                        heading="Statement records" variant="alert-info"/>)}
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 data={getCsvData()}
                                 download={`DBmarlin-${dayjs().format('YYYY-MM-DD_HH-mm')}_SQL-Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive mt-4">
                    <ConditionalRender if={!loading && data.length}>
                        <table {...getTableProps()} className="table">
                            <thead>
                            <tr>
                                <td/>
                                <td/>
                                {props.instanceOptions.map((instance, index) => <td
                                    key={`${instance.instanceId}${index}`}
                                    className={`text-center fw-bold ${index % 2 == 0 ? 'gray-bg' : ''}`}
                                    colSpan={selectedMetricExist ? 3 : 4}>{Helper.getFormattedPeriodHeadingFromDate(instance.periodStart, instance.periodEnd)}</td>)}
                            </tr>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                            key={`${column.id}${index}`}
                                            className={(column as any).headerClassName}>
                                            {column.render('Header')}

                                            {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                    <i className="fal fa-sort-amount-up-alt"></i> :
                                                    <i className="fal fa-sort-amount-down-alt"></i>) :
                                                <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                        </th>))}
                                </tr>))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                // @ts-ignore
                                return (<tr {...row.getRowProps()} key={`row_${row.original.sqlid}${row.original.instanceId}`}>
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className={(cell.column as any).className}>

                                                {cell.column.id === "sqltext" ?
                                                    <div className='tooltip-scroll ellipsis'
                                                         onMouseEnter={(event) => setVisibleToolTip({
                                                             id: cell.row.values.sqlhash,
                                                             position: getToolTipPosition(event)
                                                         })}
                                                         onMouseLeave={() => setVisibleToolTip({
                                                             id: '',
                                                             position: 0
                                                         })}
                                                    >
                                                        {cell.render('Cell')}
                                                        {(cell.row.values.sqlhash === visibleToolTip.id &&
                                                            <StatementToolTip instanceType={getInstanceType(cell.row.original.instanceId)}
                                                                              position={visibleToolTip.position}
                                                                              id={cell.row.values.sqlhash}
                                                                                          link={`/instances/${cell.row.original.instanceId}/activity/statement/${cell.row.values.sqlhash}`}
                                                                              cell={cell}/>
                                                        )}
                                                    </div> : cell.render('Cell')}

                                            </td>)
                                    })}
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </ConditionalRender>
                    <ConditionalRender if={loading || !data.length}>
                        <NoData error={error} loading={loading} length={data.length}/>
                    </ConditionalRender>
                </div>
            </div>
        </div>
    )
}

export default StatementsTable;