import React, { useEffect, useMemo, useState } from 'react';
import { Link } from "react-router-dom";

// Third-party packages.
import { Column, usePagination, useSortBy, useTable } from 'react-table';

// Constants.
import { DATA_LOADED, DATA_LOADING } from "../../../component/Constants";

// Components.
import NoData from "../../../component/NoData";
import {
    archiverUrl,
    fetchResults,
    fetchWithAuthorization,
    getDefaultPageSize,
    stringFormatting,
} from "../../../helpers/utils";
import ExtendedStatistics from "../../../types/ExtendedStatistics";
import SortClass from "../../../component/SortClass";

const List = () => {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [sqlExtendedOptionsList, setSqlExtendedOptionsList] = useState<any[]>([]);
    const [loading, setLoading] = useState<number>(DATA_LOADING);

    const getCollectStatisticsList = async() => {
        const results: any[][] = await fetchResults(
            [fetchWithAuthorization(archiverUrl() + `/sql/statistic/type`)]);
        setLoading(DATA_LOADED);
        return createSqlTAbleData(results[0])
    }

    const getTotalSelected = (list: any) => {
        let count = 0;
        for (let i = 0; i < list.length; i++) {
            if (list[i].collect === true) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        // @ts-ignore
        getCollectStatisticsList().then(setSqlExtendedOptionsList)
    }, []);

    const createSqlTAbleData = (list: ExtendedStatistics[]) => {
        const arr: any[] = []
        list.forEach((item: ExtendedStatistics) => {
            const dbExist = arr.find(db => db?.name === item.type)
            if (!dbExist) {
                arr.push({
                    name: item.type,
                    list: [item]
                })
            } else {
                dbExist.list.push(item)
            }
        })
        return arr
    }

    function clearTableFilter() {
        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data: ExtendedStatistics[] = useMemo(() => sqlExtendedOptionsList.filter(option => {
        return (
            (option.name === null ? '' : option.name).toLowerCase().includes(tableFilter.toLowerCase())
        )
    }), [sqlExtendedOptionsList, tableFilter]);


    const columns: Column[] = useMemo(() => [
        {
            Header: 'Database',
            accessor: 'name',
            className: 'fw-bold',
            defaultCanSort: true,
            Cell: ({cell}) => (
                <React.Fragment>
                    {stringFormatting(cell.row.values.name)}
                </React.Fragment>
            )
        },
        {
            Header: 'Total Metrics',
            canSort: false,
            accessor: 'list',
            className: 'fw-bold text-center',
            disableSortBy: true,
            Cell: ({cell}) => (
                <React.Fragment>
                    {cell.row.values.list ? cell.row.values.list.length : 0}
                </React.Fragment>
            )
        },
        {
            Header: 'Selected Metrics ',
            accessor: 'selected',
            className: 'fw-bold text-center',
            disableSortBy: true,
            Cell: ({cell}) => (
                <React.Fragment>
                    {cell.row.values?.list ? getTotalSelected(cell.row.values?.list) : 0}
                </React.Fragment>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            className: 'actions',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({cell}) => (
                <React.Fragment>
                    <Link to={`/admin/database-extended-statistics/edit/${cell.row.values.name}`} role="button"
                          className="btn btn-sm btn-primary">
                        <i className="fal fa-edit fa-fw"/><span>Edit</span>
                    </Link>
                </React.Fragment>
            )
        }
    ], [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'name', desc: false}]
            }
        },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                    Database SQL Statistics
                    <span className="badge bg-info" data-tip="Total SQL Statistics">{data.length}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseStatistics" aria-expanded="false" aria-controls="collapseStatistics"/>
                </div>
                <div id="collapseStatistics" className="card-body collapse show">

                    <React.Fragment>
                        <div className="row row-cols-1 row-cols-md-2 table-search">
                            <div className="col col-md-9">
                                <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                    className="far fa-undo"/><span>Clear</span></button>
                            </div>
                            <div className="col col-md-3">
                                <input type="text" autoFocus className="form-control form-control-sm"
                                       placeholder="Search"
                                       value={tableFilter} data-lpignore={true}
                                       onChange={(e) => setTableFilter(e.target.value)}/>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table {...getTableProps()} className="table">
                                <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={(column as any).className}>
                                                {column.render('Header')}
                                                <SortClass column={column} />
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}
                                                        className={(cell.column as any).className}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <NoData
                                error={null}
                                loading={loading === DATA_LOADING}
                                length={data.length}
                            />
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}

export default List;
