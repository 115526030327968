// React.
import { useContext, useState } from 'react';

// Types.
import Filter from '../types/Filter';

// Context.
import { FilterContext } from '../context/FilterContext';

export default function FilterDropdown (props: { filterId: string, dropdownId: string, dataTip: string, applyFilter: Function })
{
	const { filterId, dropdownId, dataTip, applyFilter } = props;
	const { filters } = useContext (FilterContext);
	const [ search, setSearch ] = useState<string> ('');

	return (<>
		<button id={filterId} type="button" className="btn btn btn-dropdown dropdown-toggle btn-heading" data-bs-toggle="dropdown" aria-expanded="false" data-class="text-center" data-tip={dataTip}>
			<i className="fal fa-filter fa-fw" aria-hidden="true"></i>
		</button>
		<div id={dropdownId} className="row g-0 heading dropdown-menu dropdown-menu-scroll" aria-labelledby={filterId} onClick={(e) => e.stopPropagation ()}>
			<ul className="col">
				<li className="search">
					<div className="row g-0">
						<div className="col-9">
							<input type="text" autoFocus className="form-control" placeholder="Search snapshots" value={search} onChange={(e) => setSearch (e.target.value.toLowerCase ())} />
						</div>
						<div className="col-3 text-end">
							<button type="button" className="btn btn-small btn-dark" onClick={() => setSearch ('')}><i className="far fa-undo" aria-hidden="true"></i></button>  
						</div>
					</div>
				</li>
				<li><hr className="dropdown-divider" /></li>
				{filters.filter (item => item.name.toLowerCase ().includes (search)).map ((item: Filter) => (
				<li key={item.id}>
					<button type="button" className="btn btn-filter-name" onClick={() => applyFilter (item.parameters)}>{item.name}</button>
				</li>
				))}
			</ul>
		</div>
	</>)
}
