export default function NoData (props: { error: string | null,
	loading: boolean, length: number })
{
	const { error, loading, length } = props;

	if (error)
	{
		return (
			<div className="w-100 text-center text-muted my-3">
				<i className="fal fa-ban fa-fw fa-2x"></i>
				<p className="mt-3">
					{error}
				</p>
			</div>
		)
	}
	else if (loading)
	{
		return (
			<div className="w-100 text-center text-muted my-3">
				<div className="loader spinner">
				</div>
				<p className="mt-3">
					Loading data...
				</p>
			</div>
		)
	}
	else if (length === 0)
	{
		return (
			<div className="w-100 text-center text-muted my-3">
				<i className="fal fa-ban fa-fw fa-2x"></i>
				<p className="mt-3">
					No data found
				</p>
			</div>
		)
	}
	else
	{
		return (<></>)
	}
}
