function Footer() {
    return (
        <div id="footer">
            <div className="menu"></div>
            <div className="row row-cols-1 row-cols-md-2">
                <div className="col">
                    Copyright &copy; <a href="https://www.applicationperformance.com/" rel="noopener noreferrer" target="_blank">Application Performance Ltd</a>. All rights reserved.
              </div>
                <div className="col">
                    {process.env.REACT_APP_VER}
                </div>
            </div>
        </div>
    );
}

export default Footer;