import React, { useState, useEffect, useContext, useMemo } from 'react';
import Helmet from "react-helmet";
import { Link, NavLink } from "react-router-dom";

// Types
import HostTarget from "../../types/host/HostTarget";
import InstanceTarget from "../../types/instance/InstanceTarget";
import HostStatistic from "../../types/host/HostStatistic";
import Period from "../../types/Period";

// Components
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import HeaderActions from "../../container/header_actions";
import ProcessorWidget from "../../component/host/widget/ProcessorWidget";
import MemoryWidget from "../../component/host/widget/MemoryWidget";
import DisksWidget from "../../component/host/widget/DisksWidget";
import Breadcrumb from "../../container/breadcrumb";
import ConditionalRender from "../../helpers/ConditionalRender";
import ProcessorChart from "../../component/host/chart/ProcessorChart";
import MemoryChart from "../../component/host/chart/MemoryChart";
import DiskChart from "../../component/host/chart/DiskChart";
import DiskUtilisationChart from "../../component/host/chart/DiskUtilisationChart";

// Helpers
import Helper from "../../helpers/Helper";

// Constants
import { DATA_LOADED, DATA_LOADING } from "../../component/Constants";

// Contexts
import { TimeRangeContext } from "../../context/TimeRangeContext";
import api from "../../api/Base";

interface IListProps {
    period: Period,
    hostId: number,
    instances: InstanceTarget[],
    hosts: HostTarget[],
    toggleMenu: Function,
    applyPeriod: Function
}

const Detail: React.FC<IListProps> = (props) => {
    const [loading, setLoading] = useState<number>(DATA_LOADING)

    const [averageProcessorMetric, setAverageProcessorMetric] = useState<number>(0)
    const [previousAverageProcessorMetric, setPreviousAverageProcessorMetric] = useState<number | null>(0)
    const [averageMemoryMetric, setAverageMemoryMetric] = useState<number>(0)
    const [previousAverageMemoryMetric, setPreviousAverageMemoryMetric] = useState<number | null>(0)
    const [averageDiskMetric, setAverageDiskMetric] = useState<number | null>(0)
    const [previousAverageDiskMetric, setPreviousAverageDiskMetric] = useState<number | null>(0)

    // const [combinedChanges, setCombinedChanges] = useState<CombinedChanges[]>([]);
    // const [hostChartPlotLines, setHostChartPlotLines] = useState<ChartPlotLine[]>([])
    const timeRangeContext = useContext(TimeRangeContext)

    const { hosts, hostId } = props;
    // Get the selected host.
    const filteredHosts: HostTarget[] = hosts.filter(host => Number(host.id) === Number(hostId));
    const host = filteredHosts[0]

    const comparisonSupported = useMemo(() => {
        return Helper.isComparisonSupported(timeRangeContext)
    }, [timeRangeContext]);

    const getData = async() => {

        const promiseArray = [getProcessorAverage(filteredHosts[0]), getMemoryAverage(filteredHosts[0]), getDiskAverage(filteredHosts[0])]

        await Promise.all(promiseArray)
        setLoading(DATA_LOADED)
    }

    useEffect(() => {
        setLoading(DATA_LOADING)
        void getData();

    }, []);

    const getProcessorAverage = async(host: HostTarget) => {
        let averageProcessorMetric: number = 0,
            previousAverageProcessorMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation&id=${host.id}`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    averageProcessorMetric = Math.round(response.data[0].avg);
                }
            })

        if (comparisonSupported) {
            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=cpuutilisation&id=${host.id}`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageProcessorMetric = Math.round(response.data[0].avg);
                    }
                })
        }
        setAverageProcessorMetric(averageProcessorMetric)
        setPreviousAverageProcessorMetric(previousAverageProcessorMetric)
    }

    const getMemoryAverage = async(host: HostTarget) => {
        let averageMemoryMetric: number = 0,
            previousAverageMemoryMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=memoryutilisation&id=${host.id}`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    averageMemoryMetric = Math.round(response.data[0].avg);
                }
            })

        if (comparisonSupported) {
            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=memoryutilisation&id=${host.id}`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousAverageMemoryMetric = Math.round(response.data[0].avg);
                    }
                })
        }

        setAverageMemoryMetric(averageMemoryMetric)
        setPreviousAverageMemoryMetric(previousAverageMemoryMetric)
    }

    const getDiskAverage = async(host: HostTarget) => {

        let metric: number = 0,
            previousMetric: number | null = null;

        // Get current processor average.
        await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=ioutilisation&id=${host.id}`)
            .then((response: { data: HostStatistic[]; }) => {
                if (response.data.length > 0) {
                    metric = Math.round(response.data[0].avg);
                }
            })

        if (comparisonSupported) {
            // Get previous processor average.
            await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=ioutilisation&id=${host.id}`)
                .then((response: { data: HostStatistic[]; }) => {
                    if (response.data.length > 0) {
                        previousMetric = Math.round(response.data[0].avg);
                    }
                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.log('An error occurred:', error);
                })
        }
        setAverageDiskMetric(metric)
        setPreviousAverageDiskMetric(previousMetric)
    }

    // const getChanges = async() => {
    //
    //     // Get the event types, known, detected changes and registered events.
    //     const allEvents: any[] = await EventsApi.getAllEventsChangesAndAlerts(timeRangeContext);
    //     let combinedChanges: CombinedChanges[] = [],
    //         chartPlotLines: ChartPlotLine[] = [];
    //
    //     for (let index = 0; index < allEvents.length; index++) {
    //         const currentItem = allEvents[index];
    //         combinedChanges.push({
    //             id: index,
    //             instanceId: currentItem.datasourceid,
    //             timeslice: currentItem.timeslice,
    //             [currentItem.event]: currentItem
    //         });
    //     }
    //
    //     // Set the sort order for the combined changes and events.
    //     combinedChanges.sort(function compare(a, b) {
    //         var aa: number = dayjs(a.timeslice).valueOf();
    //         var bb: number = dayjs(b.timeslice).valueOf();
    //         return bb - aa;
    //     });
    //     chartPlotLines = Helper.getChartPlotLines(props.period, combinedChanges, props.period.ui.current.chartCategories);
    //
    //     setCombinedChanges(combinedChanges)
    //     setHostChartPlotLines(chartPlotLines)
    // }

    const refresh = () => {
        setLoading(DATA_LOADING)
        void getData();
    }
    //
    // const changes = combinedChanges.filter(item => item.change);
    // const events = combinedChanges.filter(item => item.event);
    // const alerts = combinedChanges.filter(item => item.alert && item.instanceId);

    return (
        <div id="content">

            <Helmet>
                <title>{host.name || ''} - DBmarlin</title>
                <meta name="description"
                      content="Host performance detail view, showing key performance metrics and detailed executed statement and waits information"/>
            </Helmet>

            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading={host.name} type="hosts" hostId={host.id}>
                    <Link to="/hosts">Analysis</Link>
                    <Link to="/hosts">Hosts</Link>
                    <Link to={`/hosts/${host.id}`}>{host.name}</Link>
                    <NavLink to={`/hosts/${host.id}`} activeClassName={"current-breadcrumb-link"}>Host metrics</NavLink>
                    <PeriodBreadcrumb/>
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={refresh}
                               applyPeriod={props.applyPeriod}/>
            </div>

            <div className="loader">
                <ConditionalRender if={false}>
                    <div className="bar"/>
                </ConditionalRender>
            </div>

            {/* Tab Headings */}
            <ul id="menu-top" className="row row-cols-auto" role="tablist">
                <Link to={`/hosts/${host.id}`} className="active">Host Metrics</Link>
                <Link to={`/hosts/${host.id}/instances`}>Database Activity</Link>
                <Link to={`/hosts/${host.id}/events`}> Events</Link>
            </ul>

            {/* Metric Widgets - Host */}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
                <ProcessorWidget metric={averageProcessorMetric} metricPrevious={previousAverageProcessorMetric}/>
                <MemoryWidget metric={averageMemoryMetric} metricPrevious={previousAverageMemoryMetric}/>
                <DisksWidget metric={averageDiskMetric} metricPrevious={previousAverageDiskMetric}/>
                {/*<LegacyChangesWidget changes={changes} events={events} alerts={alerts}*/}
                {/*                     loading={loading === DATA_LOADING}/>*/}
            </div>

            {/* Charts */}
            <div className="row">
                <div className="col">
                    {Boolean(process.env.REACT_APP_HOST_CHARTS) && loading === DATA_LOADED && (
                        <React.Fragment>
                            <ProcessorChart applyPeriod={props.applyPeriod} host={host} plotBands={[]} plotLines={[]}/>
                            <MemoryChart applyPeriod={props.applyPeriod} host={host} plotBands={[]} plotLines={[]}/>
                            <DiskChart applyPeriod={props.applyPeriod} host={host} plotBands={[]} plotLines={[]}/>
                            <DiskUtilisationChart applyPeriod={props.applyPeriod} host={host} plotBands={[]} plotLines={[]}/>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Detail;
