import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

// Helper.
import Helper from '../../helpers/Helper';

// Api.
import api from '../../api/Base';

// Types.
import Status from '../../types/Status';
import InstanceTarget from '../../types/instance/InstanceTarget';
import HostTarget from '../../types/host/HostTarget';
import EventType from '../../types/instance/EventType';

// Constants.
import { TARGET_TYPE_DATABASE, TARGET_TYPE_HOST } from '../Constants';
import { InstanceContext } from "../../context/InstanceContext";
import { HostContext } from "../../context/HostContext";
import * as dayjs from "dayjs";

interface IActionButtonsProps {
    host?: HostTarget,
    instance?: InstanceTarget,
    removeFromList?: Function,
    eventTypes: EventType[]
}

interface IActionButtonsState {
    status: Status
}

const initialState: IActionButtonsState = {
    status: {
        type: TARGET_TYPE_DATABASE,
        sensorstatus: 200,
        message: 'OK',
        updated: new Date(),
        timedout: false,
        state: 'Started',
        status: 200,
        loading: true,
        reason: ''
    }
};

class ActionButtons extends Component<IActionButtonsProps, IActionButtonsState> {

    intervalTimerId: any | undefined;
    intervalTimer: number = 5000;
    static contextType = HostContext; // Default context type

    constructor(props: IActionButtonsProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.intervalTimerId);
    }

    getData() {
        const { instance, host } = this.props;

        let successful: boolean = true;

        if (instance) {

            // Instance target status.
            successful = this.getInstanceStatus(instance);
        }

        if (host) {

            // Host target status.
            successful = this.getHostStatus(host);
        }

        // Only set the interval timer if the API request to get the instance or host status was successful.
        if (successful) {

            // Repeat as per the defined timer interval.
            this.intervalTimerId = setTimeout(this.getData.bind(this), this.intervalTimer)
        }
    }

    getInstanceStatus(instance: InstanceTarget) {

        let successful: boolean = true;

        // Get database target status.
        api.get(`datasource?full=true&id=${instance.id}`)
            .then((response: { data: InstanceTarget[]; }) => {

                if (response.data.length) {
                    let status: Status = {
                        type: TARGET_TYPE_DATABASE,
                        sensorstatus: response.data[0].sensorstatus,
                        message: response.data[0].message,
                        reason: response.data[0].reason,
                        updated: response.data[0].updated,
                        timedout: response.data[0].timedout,
                        state: response.data[0].state,
                        loading: false,
                        status: response.data[0].status
                    }

                    this.setState({
                        status
                    });
                } else if (this.props.removeFromList) {
                    this.props.removeFromList(instance.id)

                }
            })
            .catch((error: any) => {
                successful = false;
                console.error('Failed to get instance status, stopping timer interval until a browser refresh has taken place.', error);
            })
            .then(function () {
                // Todo: Always executed - do we need this?
            });

        return successful;
    }

    getHostStatus(host: HostTarget) {

        let successful: boolean = true;

        // Get host target status.
        api.get(`host?id=${host.id}`)
            .then((response: { data: HostTarget[]; }) => {

                if (response.data.length) {
                    let status: Status = {
                        type: TARGET_TYPE_HOST,
                        sensorstatus: response.data[0].sensorstatus,
                        message: response.data[0].message,
                        reason: response.data[0].reason,
                        updated: response.data[0].updated,
                        timedout: response.data[0].timedout,
                        state: response.data[0].state,
                        loading: false,
                        status: response.data[0].status
                    }

                    this.setState({
                        status
                    });
                }

            })
            .catch((error: any) => {

                successful = false;

                console.error('Failed to get host status, stopping timer interval until a browser refresh has taken place.', error);
            })

        return successful;
    }

    toggleTargetStatus = async(monitor: boolean) => {
        const { host, instance } = this.props;

        let id: number = 0,
            url: string = 'monitor',
            toastMessage: string = '';

        if (host) {
            id = host.id;
            url = `host/${url}`;

            // Update instance context
            const { setHosts } = this.context;
            setHosts((prevData: HostTarget[]) =>
                prevData.map((obj: HostTarget) =>
                    obj.id === id ? { ...obj, monitor } : obj
                )
            );

            if (monitor) {
                toastMessage = 'Stopping host sensor';
            } else {
                toastMessage = 'Starting host sensor';
            }
        }

        if (instance) {
            id = instance.id;
            url = `datasource/${url}`;

            // Update instance context
            const { setInstances } = this.context;
            setInstances((prevData: InstanceTarget[]) =>
                prevData.map((obj: InstanceTarget) =>
                    obj.id === id ? { ...obj, monitor } : obj
                )
            );
            if (monitor) {
                toastMessage = 'Stopping instance sensor...';
            } else {
                toastMessage = 'Starting instance sensor...';
            }
        }

        const data = [{
            id,
            monitor,
            reason: 'User-initiated.'
        }];

        const result = await api.put(url, data);

        if (result.status !== 200) {

            if (host) {

                // Failed to update, log to console.
                console.error('Failed to update host status', result, data, host, toastMessage);
            }

            if (instance) {

                // Failed to update, log to console.
                console.error('Failed to update instance status', result, data, instance, toastMessage);
            }

            return;
        }

        if (instance) {

            Helper.createInstanceEvent(this.props.eventTypes, id, ((monitor) ? 'Monitoring Enabled' : 'Monitoring Disabled'), ((monitor) ? 'Enabling monitoring' : 'Stopping monitoring'));
        }
    }

    render() {
        const { host, instance } = this.props;
        const { status } = this.state;

        if (instance) {
            // @ts-ignore
            ActionButtons.contextType = InstanceContext;
        } else {
            ActionButtons.contextType = HostContext;
        }

        const parsedDate = dayjs.default(status.updated);
        const lastContactedValue = status.updated === null ? `-` : dayjs.default(parsedDate.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').fromNow().replace('in a few seconds', 'A few seconds ago')

        let buttonClassName: string = 'btn btn-sm btn-secondary',
            iconClassName: string = 'fal fa-stop fa-fw',
            buttonTooltip: string = status.state,
            buttonTitle: string = 'Stop';

        if (status.reason !== null) {
            buttonTooltip = `${status.state} - ${status.reason.toLowerCase()}`;
        }

        if (status.state === 'Stopped' || status.state === 'Stopping') {

            // Sensor is stopped or stopping.
            buttonClassName = 'btn btn-sm btn-outline-secondary';
            iconClassName = 'fal fa-play fa-fw';
            buttonTitle = 'Start';
        }

        return (
            <React.Fragment>
                <div className='d-flex justify-content-between'>
                    <div className='text-start last-contacted'>
                        {this.state.status.loading ? <span/> : `${lastContactedValue}    `}
                    </div>
                    <div>
                        <button type="button" className={buttonClassName} data-tip={buttonTooltip}
                                onClick={() => this.toggleTargetStatus(((buttonTitle === 'Stop') ? false : true))}>
                            <i className={iconClassName}></i><span>{buttonTitle}</span>
                        </button>
                        {host !== undefined && (
                            <React.Fragment>
                                <Link to={`/admin/hosts/edit/${host.id}`} role="button"
                                      className="btn btn-sm btn-primary">
                                    <i className="fal fa-edit fa-fw"></i><span>Edit</span>
                                </Link>
                                <Link className='btn btn-sm btn-success' to={`/hosts/${host.id}/activity`}><i
                                    className="fal fa-eye fa-fw"/><span>View</span></Link>
                                {status.state === 'Stopped' ? (
                                    <Link to={`/admin/hosts/delete/${host.id}`} role="button"
                                          className="btn btn-sm btn-danger">
                                        <i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
                                    </Link>
                                ) : (
                                    <button type="button" className="btn btn-sm btn-danger"
                                            data-tip="To enable delete functionality, please first stop the sensor">
                                        <i
                                            className="fal fa-trash-alt fa-fw"></i><span>Delete</span></button>
                                )}
                            </React.Fragment>
                        )}
                        {instance !== undefined && (
                            <React.Fragment>
                                <Link to={`/admin/instances/edit/${instance.id}`} role="button"
                                      className="btn btn-sm btn-primary">
                                    <i className="fal fa-edit fa-fw"></i><span>Edit</span>
                                </Link>
                                <Link className='btn btn-sm btn-success'
                                      to={`/instances/${instance.id}/activity`}><i
                                    className="fal fa-eye fa-fw"/><span>View</span></Link>
                                {status.state === 'Stopped' ? (
                                    <Link to={`/admin/instances/delete/${instance.id}`} role="button"
                                          className="btn btn-sm btn-danger">
                                        <i className="fal fa-trash-alt fa-fw"></i><span>Delete</span>
                                    </Link>
                                ) : (
                                    <button type="button" className="btn btn-sm btn-danger"
                                            data-tip="To enable delete functionality, please first stop the sensor">
                                        <i
                                            className="fal fa-trash-alt fa-fw"></i><span>Delete</span></button>
                                )}
                            </React.Fragment>
                        )}
                        <ReactTooltip className="tooltip" effect="solid"/>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default ActionButtons;
