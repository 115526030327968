import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Third-party packages.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Types.
import ChartOption from '../types/instance/ChartOption';
import { FilterType } from '../typescript/Enums';
import FilterOption from '../types/instance/FilterOption';
import { highchartsCredits } from "../helpers/utils";

// Contexts
import { useLicences } from "../context/LicenceContext";
import { TimeRangeContext } from "../context/TimeRangeContext";
import ConditionalRender from "../helpers/ConditionalRender";

// Components
import ChatButton from "./chatGPT/ChatButton";
import Prompts from "./chatGPT/Prompts";

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function PieChart(props: { filtersOptions: FilterOption[], uniqueIndex: number, chartOptions: ChartOption[], defaultChartOption: ChartOption, setFilterOptions: Function, instanceType?: string }) {
    const timeRangeContext = useContext(TimeRangeContext)
    const history = useHistory();
    const { licences } = useLicences ();
    const [chartOption, setChartOption] = useState<ChartOption>(props.defaultChartOption);

    const chartOptions = useMemo(() => {
        return {
            chart: {
                height: '300px',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                spacing: [0, 0, 0, 0],
                type: 'pie',
                style: {
                    fontFamily: 'system-ui, Arial, sans-serif',
                    fontSize: '1rem',
                    letterSpacing: 'normal'
                }
            },
            title: {text: ''},
            tooltip: {
                useHTML: true,
                formatter: function (): string {
                    // @ts-ignore
                    const title = this.point.name;
                    // @ts-ignore
                    const formattedTime = this.point.formatted;
                    // @ts-ignore
                    const percentage = this.point.percentage.toFixed(0);

                    return `
            <div>
                <b>${title}</b>
                <div class="d-flex justify-content-between">
                    <span>Total Time:</span>
                    <b style="padding-left: 20px">${formattedTime}</b>
                </div>
                <div class="d-flex justify-content-between">
                    <span>Time Percentage:</span>
                    <b style="padding-left: 20px">${percentage}%</b>
                </div>
            </div>`;
                }
            },
            accessibility: {
                enabled: false,
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                animation: false,
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.formatted}'
                    }
                }
            },
            series: [{
                name: JSON.parse(JSON.stringify(chartOption.key)),
                colorByPoint: true,
                innerSize: '50%',
                data: JSON.parse(JSON.stringify(chartOption.data)),
                dataLabels: {
                    style: {
                        fontSize: '11.3px',
                    }
                },
                events: {
                    click: function (event: any) {
                        // TODO Find a common mechanism for all click events.
                        function shouldRedirect(name: string): boolean {
                            switch (name) {
                                case 'Batches':
                                case 'Statements':
                                case 'Grouped Batches':
                                case 'Grouped Statements':
                                    return true;

                                default:
                                    return false;
                            }
                        }

                        function getFilterType(name: string): FilterType {
                            switch (name) {
                                case 'Batches':
                                    // return FilterType.Batches;
                                    break;

                                case 'Clients':
                                    return FilterType.Clients;

                                case 'Databases':
                                    return FilterType.Databases;

                                case 'Programs':
                                    return FilterType.Programs;

                                case 'Statements':
                                    // return FilterType.Statements;
                                    break;

                                case 'Sessions':
                                    return FilterType.Sessions;

                                case 'Users':
                                    return FilterType.Users;

                                case 'Waits':
                                    return FilterType.Waits;

                                default:
                                    break;
                            }

                            throw new Error
                            (`No equivalent filter type for ${name}.`);
                        }

                        if (shouldRedirect(chartOption.key)) {
                            const pathname: string = event.point.url;
                            const search: string = event.point.queryParameters ?
                                event.point.queryParameters :
                                timeRangeContext.getTimeRangeQueryString();
                            history.push({pathname, search});
                        } else {
                            const filterType: FilterType = getFilterType(chartOption.key);
                            // @ts-ignore
                            let options: FilterOption[] = props.period ? props.period.filters.options : props.filtersOptions;

                            // TODO Find out why options becomes empty...
                            if (options.length <= filterType) {
                                options =
                                    [
                                        {
                                            filterType: FilterType.Waits,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Databases,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Sessions,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Clients,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Users,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Programs,
                                            filters: []
                                        },
                                        {
                                            filterType: FilterType.Modules,
                                            filters: []
                                        }
                                    ];
                            }

                            const filters: string[] =
                                options[filterType].filters;

                            // Only allow one filter of each type
                            if (filters.length === 0 &&
                                event.point.name !== '-') {
                                filters.push(event.point.name);
                                props.setFilterOptions(options);
                            }
                        }
                    }
                }
            }],
            exporting: {enabled: false},
            credits: {enabled: highchartsCredits(licences)}
        };

    }, [chartOption.data, chartOption.key, history, props.filtersOptions]);

    function setOption(chosenOption: string) {

        let chartOptions = props.chartOptions.filter(chartOption => chartOption.key === chosenOption);

        if (chartOptions.length > 0) {

            // Requested chart option found.
            setChartOption(chartOptions[0]);
        }
    }

    Highcharts.setOptions({
        plotOptions: {series: {animation: false}},
        lang: {
            thousandsSep: ','
        }
    });

    if (props.chartOptions.length === 0) {

        // No chart options passed through.
        return (
            <div className="col">
                <div className="card collapsible">
                    <div className="card-header">
                        Missing required properties
                        <div className="btn-group float-end">
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target=".multi-collapse-pie" aria-expanded="false" aria-controls=""></i>
                        </div>
                    </div>
                    <div id={`collapseChart${props.uniqueIndex}`}
                         className="card-body collapse show multi-collapse-pie">
                        No chart options have been passed through - please check component properties and ensure valid
                        objects are getting passed through.
                    </div>
                </div>
            </div>
        )
    }

    const getTopWaits = () => {
        const waits = props.chartOptions.filter(option => option.key === 'Waits')[0]
        const sortedData = waits.data.sort((a, b) => b.y - a.y);
        // Calculate the total time
        const totalTime = sortedData.reduce((total, item) => total + item.y, 0);

        // Calculate 80% of the total time
        const eightyPercentTotal = 0.8 * totalTime;

        // Find the top items whose total time sums 80% of the total
        let cumulativeSum = 0;
        let topItems = [];
        for (let item of sortedData) {
            cumulativeSum += item.y;
            topItems.push(item);
            if (cumulativeSum >= eightyPercentTotal) {
                break;
            }
        }
        return topItems.map(item => item.name).slice(0, 8);
    }

    return (
        <div className="col">
            <div className="card collapsible">
                <div className="card-header">
                    <i className="fal fa-chart-pie-alt fa-fw" aria-hidden="true"></i>
                    Top {chartOption.key.toLowerCase()}

                    <div className="btn-group float-end" role="group" aria-label="Button group with nested dropdown">
                        <div className="btn-group" role="group">
                            <ConditionalRender if={props.instanceType && chartOption.key === 'Waits'}>
                                <ChatButton value={Prompts.getInstanceWaitsPrompt(getTopWaits(), props.instanceType)}/>
                            </ConditionalRender>
                            <button id="btnGroupDrop1" type="button"
                                    className="btn btn-xsm btn-primary dropdown-toggle ms-1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                {chartOption.key} Chart
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                {props.chartOptions.map((option: ChartOption, index) => (
                                    <li key={index}>
                                        {option.key === chartOption.key ? (
                                            <span className="dropdown-item active" role="button"
                                                  onClick={() => setOption(option.key)}>{option.key}</span>
                                        ) : (
                                            <span className="dropdown-item" role="button"
                                                  onClick={() => setOption(option.key)}>{option.key}</span>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target=".multi-collapse-pie" aria-expanded="false" aria-controls=""></i>
                        </div>
                    </div>
                </div>

                <div id={`collapseChart${props.uniqueIndex}`} className="card-body collapse show multi-collapse-pie">
                    <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={chartOptions}/>
                </div>
            </div>
        </div>
    );
}

export default PieChart;
