import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Constants
import {
    ACTION_CREATE,
    ACTION_DELETE,
    ACTION_UPDATE,
    DATA_EMPTY,
    DATA_FAILED,
    DATA_LOADED
} from "../../../../component/Constants";

// Types
import PagerDutyType from "../../../../types/integration/IntegrationType";

// Utils
import { CSVLink } from "react-csv";
import * as dayjs from "dayjs";
import { findIntegrationValue } from "../../../../helpers/utils";
import ConditionalRender from "../../../../helpers/ConditionalRender";


function List(props: { loading: number, pagerDuty: PagerDutyType[], refresh: Function }) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);

    function refresh() {
        props.refresh();
    }

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    useEffect(() => {
        if (props.loading === DATA_LOADED) {
            {/*@ts-ignore*/}
            const showButton = !props.pagerDuty.length || (!props.pagerDuty.find(item => item.name === 'pagerduty.url'))
            setShowCreate(showButton)
        }
    }, [props.loading]);

    const data = [{
        url: findIntegrationValue('url', props.pagerDuty, 'pagerduty'),
        dbmarurl: findIntegrationValue('dbmarlin.url', props.pagerDuty, 'pagerduty'),
        enable: findIntegrationValue('enable', props.pagerDuty, 'pagerduty')
    }]

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fak fa-pagerduty fa-fw" aria-hidden="true"/>
                    PagerDuty
                    <span className="badge bg-info" data-tip="Total PagerDuty integration count">{data[0].url ? 1 : 0}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapsePagerDuty" aria-expanded="false" aria-controls="collapsePagerDuty"/>
                </div>
                <div id="collapsePagerDuty" className="card-body collapse show">

                    {props.loading === DATA_EMPTY ? (
                        <div className="w-100 text-center text-muted my-3">
                            <i className="fal fa-ban fa-fw fa-2x"/>
                            <p>
                                <button type="button" className="btn btn-link" onClick={refresh}>PagerDuty not found -
                                    Click to Refresh
                                </button>
                            </p>
                        </div>
                    ) : (
                        props.loading === DATA_FAILED ? (
                            <div className="w-100 text-center text-muted my-3">
                                <i className="fal fa-ban fa-fw fa-2x text-danger"/>
                                <p>
                                    <button type="button" className="btn btn-link" onClick={refresh}>Failed to Retrieve
                                       PagerDuty - Click to Refresh
                                    </button>
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="row row-cols-1 row-cols-md-2 table-search">
                                    <div className="col col-md-9">
                                        {/* show create button if account filed which is required is not present */}
                                        <ConditionalRender if={showCreate}>
                                            <Link to={{
                                                pathname: `/admin/integrations/pager-duty/create`,
                                                state: { action: ACTION_CREATE, pagerDuty: props.pagerDuty }
                                            }} role="button" className="btn btn-sm btn-success">
                                                <i className="fal fa-plus-square fa-fw"/><span>Create</span>
                                            </Link>
                                        </ConditionalRender>

                                        <CSVLink role="button"
                                                 headers={[{ label: 'URL', key: 'url' },
                                                     { label: 'Api Key', key: 'apikey' },
                                                     { label: 'DBmarlin URl', key: 'dbmarurl' },
                                                     { label: 'Enabled', key: 'enable' }]}
                                                 data={data}
                                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - PagerDuty.csv`}
                                                 className="btn btn-sm btn-primary">
                                            <i className="fal fa-file-export fa-fw"/><span>Export</span>
                                        </CSVLink>
                                        <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                            className="far fa-undo"/><span>Clear</span></button>
                                    </div>
                                    <div className="col col-md-3">
                                        <input type="text" autoFocus className="form-control form-control-sm"
                                               placeholder="Search" value={tableFilter} data-lpignore={true}
                                               onChange={(e) => setTableFilter(e.target.value.toLowerCase())}/>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Event Endpoint</th>
                                            <th scope="col">DBmarlin Url</th>
                                            <th scope="col">Enabled</th>
                                            <th className="actions" scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data[0].url && data.filter(item => item.url.toLowerCase().includes(tableFilter) || item.dbmarurl.toLowerCase().includes(tableFilter)).map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    {item.url}
                                                </th>
                                                <td className="concatenate">
                                                    {item.dbmarurl}
                                                </td>
                                                <td className="concatenate">
                                                    <div className='form-check form-switch'>
                                                        <input checked={item.enable}
                                                               type="checkbox"
                                                               id="enabled"
                                                               className="form-check-input"
                                                               disabled={true}
                                                               data-lpignore={true}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="actions">
                                                    <Link to={{
                                                        pathname: `/admin/integrations/pager-duty/edit`,
                                                        state: { action: ACTION_UPDATE, data }
                                                    }} role="button" className="btn btn-sm btn-primary">
                                                        <i className="fal fa-edit fa-fw"/><span>Edit</span>
                                                    </Link>
                                                    <Link to={{
                                                        pathname: `/admin/integrations/pager-duty/delete`,
                                                        state: { action: ACTION_DELETE, data }
                                                    }} role="button" className="btn btn-sm btn-danger">
                                                        <i className="fal fa-trash-alt fa-fw"/><span>Delete</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
