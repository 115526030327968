import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Api.
import api from '../../../api/Base';
import HostApi from '../../../api/host/HostApi';

// Context.
import { HostContext } from '../../../context/HostContext';
import { InstanceContext } from '../../../context/InstanceContext';

// Types.
import Action from '../../../types/Action';
import HostTarget from '../../../types/host/HostTarget';
import InstanceTargetHost from '../../../types/instance/InstanceTargetHost';

// Constants.
import { ACTIONS } from '../../../component/Constants';
import { HOST_TYPES } from '../../../component/Constants';

// Components.
import Alert from '../../../component/Alert';

function Detail(props: { actionId: string, hostId?: number }) {
    const history = useHistory();
    const { hosts, setHosts } = useContext(HostContext);
    const { instances } = useContext(InstanceContext);

    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(props.actionId === ACTIONS.DELETE.type);
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState<string>('');
    const [nameUsed, setNameUsed] = useState<boolean>(false);
    const [nameClass, setNameClass] = useState<string>('');
    const [hostAddress, setHostAddress] = useState<string>('');
    const [hostAddressUsed, setHostAddressUsed] = useState<boolean>(false);
    const [hostAddressClass, setHostAddressClass] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [typeClass, setTypeClass] = useState<string>('');
    const [agentId, setAgentId] = useState<number>(1);
    const [agentIdClass, setAgentIdClass] = useState<string>('');
    const [debug, setDebug] = useState<boolean>(false);
    const [port, setPort] = useState<number>(22);
    const [portClass, setPortClass] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [usernameClass, setUsernameClass] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordClass, setPasswordClass] = useState<string>('');
    const [passwordElementType, setPasswordElementType] = useState<string>('password');
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [keyfile, setKeyfile] = useState<string>('');
    const [keyfileClass, setKeyfileClass] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [regionClass, setRegionClass] = useState<string>('');
    const [filter, setFilter] = useState<string>('');
    const [monitor, setMonitor] = useState<boolean>(false);
    const [mappedInstances, setMappedInstances] = useState<number[]>([]);
    const [monitoringInterval, setMonitoringInterval] = useState<number>(1000);
    const [agents, setAgents] = useState<any[]>([]);

    useEffect(() => {

        const getHost = (hostId: number) => {

            const host = hosts.filter((host: HostTarget) => host.id === Number(hostId));

            if (host.length === 1) {

                setId(host[0].id);
                setName(host[0].name);
                setHostAddress(((host[0].host === null) ? '' : host[0].host));
                setType(host[0].type);
                setAgentId(host[0].agentid);
                setMonitor(host[0].monitor);
                setDebug(host[0].debug);
                setPort(host[0].port);
                setUsername(host[0].username);
                setPassword(((host[0].password === null) ? '' : host[0].password));
                setKeyfile(((host[0].keyfile === null) ? '' : host[0].keyfile));
                setRegion(host[0].region || '');
                setMonitoringInterval(host[0].monitoringintervalmilliseconds);
            } else {
                setIsMissing(true);
            }
        }

        const getAction = () => {

            switch (props.actionId) {
                case 'create':

                    setAction(ACTIONS.CREATE);

                    break;

                case 'edit':

                    setAction(ACTIONS.UPDATE);

                    break;

                case 'delete':

                    setAction(ACTIONS.DELETE);
                    setDisabled(true);

                    break;

                default:

                    console.error('Unknown action requested', props.actionId);

                    setIsError(true);

                    break;
            }
        }

        const getMappedInstances = async(hostId: number) => {

            api.get(`datasource/host?hostid=${hostId}`)
                .then((response: { data: InstanceTargetHost[]; }) => {

                    let mappedInstances: number[] = [];

                    for (let index = 0; index < response.data.length; index++) {

                        // Get the instance ID.
                        mappedInstances.push(response.data[index].id);
                    }

                    setMappedInstances(mappedInstances);

                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.error('Failed to retrieve mapped instances for host.', error);
                })
                .then(function () {
                    // Todo: Always executed - do we need this?
                });
        };

        const getAgents = async() => {
            const response = await api.get(`agent `) || []
            if (response) {

                setAgents(response.data)
            }
        }

        getAction();

        getAgents();

        if (props.hostId) {
            getHost(props.hostId);
            getMappedInstances(props.hostId);
        }

    }, [hosts, props.actionId, props.hostId]);

    function handleNameBlur() {

        // Trim any white space.
        let value = name.trim();

        // Save to state.
        setName(value);

        if (value === '') {

            // Invalid, set the class.
            setNameClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setNameClass('is-valid');
        }

        // Check for duplicate names.
        const index = hosts.findIndex(object => object.id !== id && object.name.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setNameClass('is-valid');
            setNameUsed(false);
        } else {

            // Duplicate found.
            setNameClass('is-invalid');
            setNameUsed(true);
        }
    }

    function handleHostAddressBlur() {

        // Trim any white space.
        let value = hostAddress.trim();

        // Save to state.
        setHostAddress(value);

        if (value === null || value === '') {

            // Invalid, set the class.
            setHostAddressClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setHostAddressClass('is-valid');
        }

        // Check for duplicate names.
        const index = hosts.filter(object => object.host !== null).findIndex(object => object.host.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setHostAddressClass('is-valid');
            setHostAddressUsed(false);
        } else {

            // Duplicate found.
            setHostAddressClass('is-valid');
            setHostAddressUsed(false);
        }
    }

    function handlePortBlur() {

        // Validate the entered port value.
        const regexp = /^[0-9\b]+$/;

        if ((port.toString().length > 0 || regexp.test(port.toString())) && (port.toString().length < 6) && (port <= 65535)) {
            setPortClass('is-valid');
        } else {
            setPortClass('is-invalid');
        }
    }

    function handleInstanceMapping(instanceId: number) {

        let updatedMappedInstances = [...mappedInstances];

        // Look for a match.
        let match = updatedMappedInstances.filter(id => {
            return id === instanceId;
        });

        if (match.length === 0) {
            // Add the association.
            updatedMappedInstances.push(instanceId);
        } else {
            // Remove the association.
            updatedMappedInstances = [...updatedMappedInstances.filter(id => id !== instanceId)];
        }
        ;

        setMappedInstances([...updatedMappedInstances]);
    }

    function clearFilter() {

        // Clear any pre-existing filter values.
        setFilter('');
    }

    function canViewPassword() {
        return action === ACTIONS.CREATE ||
            (action === ACTIONS.UPDATE && passwordChanged);
    }

    function changePassword(e: ChangeEvent<HTMLInputElement>): void {
        if (!passwordChanged) {
            // Just want the character entered.
            setPassword((e.nativeEvent as any).data || '');
            setPasswordChanged(true);
        } else {
            setPassword(e.target.value);
        }
    }

    async function handleActionClick() {

        // Validate the form.
        let valid = true;

        if (name.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setNameClass('is-invalid');
        } else {

            // Valid.
            setNameClass('is-valid');
        }

        if (hostAddress.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setHostAddressClass('is-invalid');
        } else {

            // Valid.
            setHostAddressClass('is-valid');
        }

        if (type.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setTypeClass('is-invalid');
        } else {

            // Valid.
            setTypeClass('is-valid');
        }

        if (agentId === 0) {

            valid = false;

            // Invalid, set the class.
            setAgentIdClass('is-invalid');
        } else {

            // Valid.
            setAgentIdClass('is-valid');
        }

        if (type !== 'windows') {

            if (username.trim() === '') {

                valid = false;

                // Invalid, set the class.
                setUsernameClass('is-invalid');
            } else {

                // Valid.
                setUsernameClass('is-valid');
            }

            if (password.trim() !== '' && keyfile.trim() !== '') {

                valid = false;

                setPasswordClass('is-invalid');
                setKeyfileClass('is-invalid');
            }

            if (password.trim() === '' && keyfile.trim() === '') {

                // Invalid as no password or keyfile set - one or the other, but not both, is required.
                valid = false;

                setPasswordClass('is-invalid');
                setKeyfileClass('is-invalid');

            } else {

                if (password.trim() === '' && keyfile.trim() !== '') {

                    // Valid.
                    setPasswordClass('');
                    setKeyfileClass('is-valid');

                } else {

                    if (password.trim() !== '' && keyfile.trim() === '') {

                        // Password set.
                        setPasswordClass('is-valid');
                        setKeyfileClass('');
                    }
                }
            }

        }

        if (type === 'awsrds') {

            if (region.trim() === '') {

                valid = false;

                // Invalid, set the class.
                setRegionClass('is-invalid');
            } else {

                // Valid.
                setRegionClass('is-valid');
            }
        }

        setIsValid(valid);

        if (!valid) {
            return;
        }

        // Build the event type object.
        let data = [{
            id,
            name,
            host: hostAddress,
            agentid: agentId,
            type: type,
            monitor,
            debug: debug,
            port: Number(port),
            username: ((username === null) ? '' : username),
            password: ((password === '') ? null : password),
            passwordchanged: passwordChanged,
            keyfile: ((keyfile === '') ? null : keyfile),
            region: ((region === '') ? null : region),
            monitoringintervalmilliseconds: monitoringInterval
        }];
        let result: any = undefined;

        switch (action) {
            case ACTIONS.CREATE:

                // Save the object.
                result = await api.post('host', data);

                if (result.status === 200) {

                    // Create any instance mappings.
                    let instanceTargetHosts: InstanceTargetHost[] = [];

                    for (let index = 0; index < mappedInstances.length; index++) {

                        // Build object array of mapped instances for this host.
                        instanceTargetHosts.push({
                            id: mappedInstances[index],
                            hostid: result.data[0]
                        });
                    }

                    // Recreate the host and instance associations.
                    result = await api.post('datasource/host', instanceTargetHosts);
                }

                break;
            case ACTIONS.UPDATE:

                // Update the object.
                result = await api.put('host', data);

                // Delete all instance associations.
                result = await api.delete('datasource/host', { data: [{ 'hostid': id }] });

                let instanceTargetHosts: InstanceTargetHost[] = [];

                for (let index = 0; index < mappedInstances.length; index++) {

                    // Build object array of mapped instances for this host.
                    instanceTargetHosts.push({
                        id: mappedInstances[index],
                        hostid: id
                    });
                }

                // Recreate the host and instance associations.
                result = await api.post('datasource/host', instanceTargetHosts);

                break;
            case ACTIONS.DELETE:

                // Delete all instance associations.
                result = await api.delete('datasource/host', { data: [{ 'hostid': id }] });

                if (result.status !== 200) {

                    // Failed to complete the API request.
                    console.error('Failed to complete requested action', action, result, data);

                    // Failed to complete request.
                    setIsError(true);

                    // An error occurred, so exit this function.
                    return;
                }

                // Delete the object.
                result = await api.delete('host', { data });

                // Get the latest hosts and update the context object.
                const updatedHosts = await HostApi.getHosts();

                setHosts(updatedHosts);

                // Redirect the user back to the list view.
                history.push('/admin/hosts');

                return;
            default:

                // Unknown action requested.
                console.error('Unknown action requested', action);

                setIsError(true);

                break;
        }

        if (result.status !== 200) {

            // Failed to complete the API request.
            console.error('Failed to complete requested action', action, result, data);

            // Failed to complete request.
            setIsError(true);

            // An error occurred, so exit this function.
            return;
        }

        // Get the latest hosts and update the context object.
        const updatedHosts = await HostApi.getHosts();

        setHosts(updatedHosts);

        // Redirect the user back to the last view.
        history.goBack()
        // history.push('/admin/hosts');
    }

    useEffect(() => {
            const value = type === 'awsrds' ? 60000 : 1000
            setMonitoringInterval(value)
        }, [type])

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-server fa-fw" aria-hidden="true"></i>
                    {action.title} host
                </div>
                <div className="card-body">
                    {isMissing ? (
                        <React.Fragment>
                            <Alert message="The requested host is either invalid or no longer available."
                                   heading="Invalid request" variant="alert-danger"/>
                            <div className="row g-0 form">
                                <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                    <Link to="/admin/hosts/" role="button" className="btn btn-secondary"><i
                                        className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            {action.type === 'delete' && (
                                <Alert
                                    message="Please check and ensure you want to delete this host.  Any associated media will remain, and will need to be deleted separately."
                                    heading="Deletes are final and cannot be undone" variant="alert-warning"/>
                            )}

                            {isError && (
                                <Alert
                                    message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request."
                                    heading={`Failed to ${action.title.toLowerCase()} host`} variant="alert-danger"/>
                            )}

                            {!isValid && (
                                <Alert message="Please complete all the required fields below."
                                       heading="Missing required fields" variant="alert-danger"/>
                            )}

                            <p>Please complete the required details below:</p>

                            <div className="row g-0 form">

                                {/* Title */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="name" className="reminder"
                                                                      data-tip="The host name">Name</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type="text"
                                        id="name"
                                        autoFocus
                                        className={`form-control ${nameClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleNameBlur}
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {nameUsed && (
                                        <small className="text-danger">Host name already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>

                                {/* Host Type */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="type" className="reminder"
                                                                      data-tip="Select the host type">Host Type</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <select
                                        id="type"
                                        value={type}
                                        onChange={e => setType(e.target.value)}
                                        className={`form-select ${typeClass}`}
                                        disabled={disabled}>
                                        <option value="">Select Host Type</option>
                                        {HOST_TYPES.map((item, index) => (
                                            <option key={index}
                                                    value={item.toLowerCase().replace(/\s/g, '')}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-100"></div>

                                {/* Host Address */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="hostAddress" className="reminder"
                                                                      data-tip="The host address">{type === 'awsrds' ? 'DB Identifier' : 'Host Address'}</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type="text"
                                        id="hostAddress"
                                        className={`form-control ${hostAddressClass}`}
                                        required
                                        maxLength={250}
                                        onBlur={handleHostAddressBlur}
                                        onChange={e => setHostAddress(e.target.value)}
                                        value={hostAddress}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    {hostAddressUsed && (
                                        <small className="text-danger">Host address already used.</small>
                                    )}
                                </div>
                                <div className="w-100"></div>


                                {type === 'linux' && (
                                    <React.Fragment>

                                        {/* Port */}
                                        <div className="col-xs-12 col-md-3">
                                            <span className="required"><label htmlFor="port" className="reminder"
                                                                              data-tip="Set the connection port number">SSH Port</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-4">
                                            <input
                                                type="text"
                                                id="port"
                                                className={`form-control ${portClass}`}
                                                required
                                                maxLength={5}
                                                onBlur={handlePortBlur}
                                                onChange={e => setPort(Number(e.target.value))}
                                                value={port}
                                                disabled={disabled}
                                                data-lpignore={true}
                                                placeholder="Port number, e.g. 22"
                                            />
                                            <small className="text-secondary">The SSH port is normally 22 and must be
                                                65,535 or lower.</small>
                                        </div>
                                        <div className="w-100"></div>

                                    </React.Fragment>
                                )}

                                {/* Connection Username */}
                                <div className="col-xs-12 col-md-3">
                                    <span className={((type !== 'windows') ? 'required' : '')}><label htmlFor="username"
                                                                                                      className="reminder"
                                                                                                      data-tip="Set the connection username">{type === 'awsrds' ? 'Access Key' : 'Username'}</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type="text"
                                        id="username"
                                        className={`form-control ${usernameClass}`}
                                        required
                                        maxLength={250}
                                        onChange={e => setUsername(e.target.value)}
                                        value={username}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"></div>

                                {/* Connection Password */}
                                <div className="col-xs-12 col-md-3">
                                    <span className={((type === 'awsrds') ? 'required' : '')}><label htmlFor="password" className="reminder"
                                           data-tip="Set the connection password">{type === 'awsrds' ? 'Secret Key' : 'Password'}</label>
                                    {canViewPassword() && (
                                        <i className="fas fa-eye fa-fw fa-xs ms-1" role="button"
                                           onClick={() => ((passwordElementType === 'text') ? setPasswordElementType('password') : setPasswordElementType('text'))}></i>
                                    )}</span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <input
                                        type={passwordElementType}
                                        id="password"
                                        className={`form-control ${passwordClass}`}
                                        required
                                        maxLength={250}
                                        onChange={e => changePassword(e)}
                                        value={password}
                                        disabled={disabled}
                                        data-lpignore={true}
                                    />
                                    <small className="text-secondary">
                                        {type === 'linux' ? 'Enter a password to use password authentication, or' :
                                        type === 'windows' ? 'Enter a password to use password authentication' :
                                        type === 'awsrds' ? 'Enter the access key and secret key for your IAM user' :
                                        ''}
                                    </small>

                                </div>
                                <div className="w-100"></div>

                                {type === 'linux' && (
                                    <React.Fragment>

                                        {/* PEM */}
                                        <div className="col-xs-12 col-md-3">
                                            <label htmlFor="keyfile" className="reminder"
                                                   data-tip="Set the local SSH PEM keyfile location">SSH Private
                                                Keyfile</label>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-4">
                                            <input
                                                type="text"
                                                id="keyfile"
                                                className={`form-control ${keyfileClass}`}
                                                maxLength={250}
                                                onChange={e => setKeyfile(e.target.value)}
                                                value={keyfile}
                                                disabled={disabled}
                                                data-lpignore={true}
                                                placeholder="PEM File"
                                            />
                                            <small className="text-secondary">Enter the full path to the PEM file
                                                location on the DBmarlin agent to use Private Key Authentication</small>
                                        </div>
                                        <div className="w-100"></div>

                                    </React.Fragment>
                                )}

                                {type === 'awsrds' && (
                                    <React.Fragment>

                                        {/* Region */}
                                        <div className="col-xs-12 col-md-3">
                                            <span className={((type === 'awsrds') ? 'required' : '')}><label htmlFor="keyfile" className="reminder"
                                                   data-tip="Set the AWS region">AWS Region</label></span>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-lg-4">
                                            <input
                                                type="text"
                                                id="region"
                                                className={`form-control ${regionClass}`}
                                                required
                                                maxLength={250}
                                                onChange={e => setRegion(e.target.value)}
                                                value={region}
                                                disabled={disabled}
                                                data-lpignore={true}
                                                placeholder="Region"
                                            />
                                        </div>
                                        <div className="w-100"></div>

                                    </React.Fragment>
                                )}

                                {/* Monitoring Interval  */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="monitoring-interval" className="reminder"
                                           data-tip="Set the monitoring interval">Monitoring Interval
                                        (milliseconds)</label>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <select id="monitoring-interval" className={`form-control`} required
                                            onChange={e => setMonitoringInterval(Number(e.target.value))}
                                            value={monitoringInterval} disabled={disabled || type === 'awsrds'}>
                                        {[100, 200, 500, 1000, 2000, 5000, 10000, 20000, 30000, 60000].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-100"></div>

                                {/* Agent */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="agentId" className="reminder"
                                                                      data-tip="The agent">Agent</label></span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <select
                                        id="agentId"
                                        value={agentId}
                                        onChange={e => setAgentId(Number(e.target.value))}
                                        className={`form-select ${agentIdClass}`}
                                        disabled={disabled}>
                                        {agents.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name} ({item.hostname})</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-100"></div>

                                {/* Debug Mode */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="debug" className="reminder" data-tip="Enable debug mode">Debug
                                        Enabled</label>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="debug" checked={debug}
                                               onChange={() => ((debug === true) ? setDebug(false) : setDebug(true))}
                                               disabled={disabled}/>
                                    </div>
                                </div>
                                <div className="w-100"></div>

                                {/* Instance Mappings */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="mappings" className="reminder"
                                           data-tip="Map this host to one or more instances">Instance Mappings</label>
                                    <span className="badge bg-info"
                                          data-tip="Total mapped instance count">{mappedInstances.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="row g-0 mb-2">
                                        <div className="col-9">
                                            <input type="text" className="form-control" placeholder="Filter Instances"
                                                   value={filter} data-lpignore={true}
                                                   onChange={(e) => setFilter(e.target.value.toLowerCase())}/>
                                        </div>
                                        <div className="col-3 text-end">
                                            <button type="button" className="btn btn-small btn-dark"
                                                    onClick={clearFilter}><i className="fal fa-times"></i></button>
                                        </div>
                                    </div>
                                    <div className="list-group">
                                        {instances.filter(item => item.name.toLowerCase().includes(filter)).sort((a, b) => a.name.localeCompare(b.name)).slice(0, 7).map((instance: {
                                            id: number;
                                            name: string;
                                        }) => (
                                            <label key={instance.id} className="list-group-item">
                                                <input className="form-check-input me-2" type="checkbox"
                                                       value={instance.id}
                                                       checked={mappedInstances.filter(id => id === instance.id).length > 0}
                                                       onChange={() => handleInstanceMapping(instance.id)}/>
                                                {instance.name}
                                            </label>
                                        ))}
                                        {instances.filter(item => item.name.toLowerCase().includes(filter)).length > 7 && (
                                            <label className="list-group-item text-black-50">
                                                <small>Filter for more instances...</small>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="w-100"></div>

                                {/* Actions */}
                                <div className="col-xs-12 col-md-9 col-lg-7 actions">
                                    <button onClick={() => history.goBack()} className="btn btn-secondary"><i
                                        className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span></button>
                                    <button className={`btn btn-${action.variant}`} onClick={handleActionClick}><i
                                        className={`${action.icon} fa-fw fa-sm`}></i><span>{action.title}</span>
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Detail;
