import './style.scss'

import React, {ReactElement} from "react";

import HeaderFilter from "../../component/HeaderFilter";

import ConditionalRender from "../../helpers/ConditionalRender";
import HostTarget from "../../types/host/HostTarget";
import InstanceTarget from "../../types/instance/InstanceTarget";

type BreadcrumbProps = {
    heading: string
    type?: string | undefined
    children: ReactElement[]
    instanceId?: number,
    filterItems?: (item: InstanceTarget | HostTarget) => void
    hostId?: number,
}

const Breadcrumb = (props: BreadcrumbProps) => {
    return <React.Fragment>
        <div className="breadcrumb-container col-lg-7 col-md-6 col-sm-6">
            <div className="col order-first g-0">
                <ConditionalRender if={props.type}>
                    <HeaderFilter filterItems={props.filterItems} instanceId={props.instanceId ?? 0} hostId={props.hostId ?? 0} heading={props.heading} type={props.type}/>
                </ConditionalRender>
                <ConditionalRender if={!props.type}>
                    <h3>{props.heading}</h3>
                </ConditionalRender>
                <span className="breadcrumbs">{props.children}</span>
            </div>
        </div>
    </React.Fragment>
};


export default Breadcrumb;
