import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

// Types.
import Action from '../../../types/Action';
import AlertRules from "../../../types/Alerts/AlertRules";
import InstanceTarget from "../../../types/instance/InstanceTarget";
import HostTarget from "../../../types/host/HostTarget";

// Constants.
import { ACTIONS, DATA_LOADED, generalErrorMessage } from '../../../component/Constants';

// Helpers
import { getNameAlreadyUsed } from "../../../helpers/validators";
// import { MultiSelect } from 'primereact/multiselect';
import api from "../../../api/Base";
import { getAction, getValidClass } from "../../../helpers/utils";

//Components
import Alert from "../../../component/Alert";
import Scope from "../../../component/scope/";
import Metric from "../../../component/metrics/";
import ConditionalRender from "../../../helpers/ConditionalRender";

const emptyState = {
    id: '',
    hostid: 0,
    datasourceid: 0,
    type: 'host_statistic',
    name: "",
    entitytype: "host",
    metrictype: "statistic",
    intervalminutes: 1,
    threshold: NaN,
    units: "",
    decreasing: 'false',
    disabled: false,
    statistic: "cpuutilisation",
    aggregation: "avg"
}

function Detail(props: { actionId: string, alertRuleId?: string, alertRules: AlertRules[], instances: InstanceTarget[], hosts: HostTarget[], refresh: Function, loading: number }) {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<AlertRules>({
        mode: "onBlur",
        reValidateMode: 'onBlur'
    });
    const history = useHistory();

    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const isDelete = action.type === ACTIONS.DELETE.type;
    const [entityType, setEntityType] = useState<string>('host');
    const [metricType, setMetricType] = useState<string>('statistic');
    const [aggregation, setAggregation] = useState<string>('avg');
    const [statistic, setStatistic] = useState('');
    const [hostId, setHostId] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [statisticError, setStatisticError] = useState('');
    const [datasourceId, setDatasourceId] = useState(0);
    const [isMissing, setIsMissing] = useState<boolean>(false);

    // const [actions, setActions] = useState<[]>([]);
    const [disabled, setDisabled] = useState<boolean>(isDelete);

    // search for the edited item or set to initial state value
    const editDetails = (props.alertRules.length && props.alertRules.find(rule => parseInt(rule.id) === parseInt(props.alertRuleId as string))) || emptyState

    const instances = props.instances.sort((a, b) => a.name.localeCompare(b.name))
    const hosts = props.hosts.sort((a, b) => a.name.localeCompare(b.name))

    useEffect(() => {
        if (editDetails.id) {
            setValue('name', editDetails.name)
            setEnabled(!editDetails.disabled)
            setValue('threshold', editDetails.threshold)
            setValue('decreasing', editDetails.decreasing ? 'true' : 'false')
            setEntityType(editDetails.entitytype)
            setMetricType(editDetails.metrictype)
            setStatistic(editDetails.statistic)
            setHostId(editDetails.hostid)
            setDatasourceId(editDetails.datasourceid)
        }
    }, [editDetails, setValue]);

    useEffect(() => {
        if (props.loading === DATA_LOADED && !editDetails.id && action.type === ACTIONS.UPDATE.type) {
            setIsMissing(true)
        }
    }, [props.loading, action.type, editDetails.id]);

    useEffect(() => {
        getAction(props.actionId, setAction, setDisabled);
    }, [props.actionId]);

    // get entity or metric value based on selected type
    const getEntityOrMetric = (key: string, typeValue: string) => {
        const typeArray = typeValue.split('_')
        return typeArray[key === 'entity' ? 0 : 1]
    }

    // reset all fields when type is changed
    const resetFields = (typeValue: string) => {
        setEntityType(getEntityOrMetric('entity', typeValue))
        setMetricType(getEntityOrMetric('metric', typeValue))
        setHostId(0)
        setDatasourceId(0)
        setStatistic('')
    }

    let isValid = Object.keys(errors).length === 0

    const validateMetric = () => {
        if (!statistic) {
            setStatisticError('invalid')
            isValid = false
            return false
        } else {
            isValid = true
        }

    }

    const onSubmit = async (data: AlertRules) => {
        const serverData = [{
            "id": editDetails.id,
            "hostid": hostId,
            "datasourceid": datasourceId,
            "name": data.name,
            "entitytype": entityType,
            "metrictype": metricType,
            "intervalminutes": 1,
            "threshold": data.threshold,
            "units": data.units,
            "decreasing": data.decreasing === 'true',
            "disabled": !enabled,
            "statistic": statistic,
            "aggregation": aggregation
        }]
        let result: any = undefined;
        let url = `/alert/rule`;

        if (isValid) {
            switch (action.type) {
                case 'create':
                    // Create New Rule.
                    result = await api.post(url, serverData);
                    break;

                case 'update':
                    // Update Rule.
                    result = await api.put(url, serverData)
                    break;

                case 'delete':
                    // Delete Rule.
                    result = await api.delete(url, { data: serverData });
                    break;

                default:
                    // Unknown action requested.
                    console.error('Unknown action requested', action);
                    break;
            }

        }
        if (result && result.status === 200) {
            history.push('/admin/alert-rules');
            props.refresh()
        }
    }

    const validateThreshold = (val: number) => {
        const isPercent = getValues('units') === '%'
        return val < 0 ? 'Must be a positive number' : isPercent && val > 100 ? 'Can\'t be more than 100%' : true
    }

    return (
        <div className="col">
            {props.loading === DATA_LOADED &&
                <div className="card">
                    <div className="card-header">
                        <i className="fal fa-exclamation-triangle fa-fw mr-10" aria-hidden="true"/>
                        {action.title} Alert Rule
                    </div>
                    <div className="card-body">

                        {isMissing ? (
                            <React.Fragment>
                                <Alert message="The requested alert rule is either invalid or no longer available."
                                       heading="Invalid request" variant="alert-danger"/>
                                <div className="row g-0 form">
                                    <div className="col-xs-12 col-md-9 col-lg-6 actions">
                                        <Link to="/admin/alert-rules/" role="button" className="btn btn-secondary"><i
                                            className="fal fa-arrow-circle-left fa-fw fa-sm"/><span>Back</span></Link>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>

                                {(!isValid || (statisticError && !statistic)) && (
                                    <Alert message={generalErrorMessage.text} heading={generalErrorMessage.title}
                                           variant="alert-danger"/>
                                )}

                                <p>Please complete the required details below:</p>

                                <div className="row g-0 form">

                                    {/* Name */}
                                    <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="name" className="reminder">Alert rule name</label>
                                </span>
                                    </div>

                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <input
                                            type="text"
                                            id="name"
                                            maxLength={250}
                                            {...register("name", {
                                                required: "Please fill in this field",
                                                validate: (value) => getNameAlreadyUsed(parseInt(props.alertRuleId as string), value, props.alertRules)
                                            })}
                                            className={`form-control ${getValidClass(errors.name, getValues('name'), isDelete)}`}
                                            defaultValue={editDetails.name}
                                            disabled={disabled}
                                            title={errors.name?.message}
                                            data-lpignore={true}
                                        />
                                        <small className="text-danger">{errors.name?.message}</small>
                                    </div>
                                    <div className="w-100"/>

                                    {/* Alert type */}
                                    <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="type" className="reminder">Alert type</label>
                                </span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-3">
                                        <select
                                            id="type"
                                            autoFocus
                                            className={`form-control`}
                                            onChange={e => resetFields(e.target.value)}
                                            value={`${entityType}_${metricType}`}
                                            disabled={disabled}
                                            data-lpignore={true}
                                        >
                                            <option value='host_statistic'>Host Statistic</option>
                                            <option value='datasource_statistic'>Instance Statistic</option>
                                            <option value='datasource_activity'>Instance Activity</option>
                                        </select>
                                    </div>
                                    <div className="w-100"/>

                                    {/* Scope */}
                                    <Scope entityType={entityType}
                                           hostId={hostId}
                                           setHostId={setHostId}
                                           datasourceId={datasourceId}
                                           setStatistic={setStatistic}
                                           setDatasourceId={setDatasourceId}
                                           hosts={hosts}
                                           instances={instances}
                                           disabled={disabled}
                                    />

                                    {/* Metric */}
                                    <Metric entityType={entityType}
                                            metricType={metricType}
                                            hostId={hostId}
                                            datasourceId={datasourceId}
                                            disabled={disabled}
                                            setAggregation={setAggregation}
                                            aggregation={aggregation}
                                            setStatistic={setStatistic}
                                            statisticError={statisticError}
                                            statistic={statistic}
                                            isDelete={isDelete}
                                            hosts={hosts}
                                            instances={instances}
                                    />

                                    {/* Threshold */}
                                    <div className="col-xs-12 col-md-3">
                                        <span className="required">
                                            <label htmlFor="type" className="reminder">Threshold</label>
                                        </span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <div className=" d-flex justify-content-between">
                                            <select
                                                id="decreasing"
                                                autoFocus
                                                {...register("decreasing")}
                                                className={`form-control w-25 mr-10`}
                                                defaultValue={editDetails.decreasing}
                                                disabled={disabled}
                                                data-lpignore={true}
                                            >
                                                <option value='false'>{'>='}</option>
                                                <option value='true'>{'<='}</option>
                                            </select>
                                            <input
                                                type="number"
                                                id="threshold"
                                                maxLength={250}
                                                {...register("threshold", {
                                                    required: "Please fill in this field",
                                                    valueAsNumber: true,
                                                    validate: (value) => validateThreshold(value)
                                                })}
                                                className={`form-control w-50 mr-10 no-arrows ${getValidClass(errors.threshold, getValues('threshold'), isDelete)}`}
                                                style={{ width: '45%' }}
                                                defaultValue={editDetails.threshold || ''}
                                                disabled={disabled}
                                                // @ts-ignore
                                                title={validateThreshold(getValues('threshold')) !== true ? validateThreshold(getValues('threshold')) : errors.threshold?.message}
                                                data-lpignore={true}
                                            />

                                            <select
                                                id="type"
                                                autoFocus
                                                className={`form-control w-25`}
                                                {...register("units")}
                                                defaultValue={editDetails.units}
                                                disabled={disabled}
                                                data-lpignore={true}
                                            >
                                                <option value=''>&nbsp;</option>
                                                <option value='%'>%</option>
                                                <option value='ms'>ms</option>
                                            </select>
                                        </div>
                                        <ConditionalRender if={errors.threshold?.message}>
                                            <small className="text-danger mid-error">{errors.threshold?.message}</small>
                                        </ConditionalRender>
                                    </div>
                                    <div className="w-100"/>

                                    {/*Rule evaluation frequency (mins)*/}
                                    <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="frequency"
                                           className="reminder">Rule evaluation frequency (mins)</label>
                                </span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-3">
                                        <select
                                            id="frequency"
                                            autoFocus
                                            className={`form-control w-25`}
                                            value={1}
                                            disabled={true}
                                            data-lpignore={true}
                                        >
                                            <option value='1'>1</option>
                                        </select>
                                    </div>
                                    <div className="w-100"/>

                                    {/*Evaluation window (mins)*/}
                                    <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="evaluation" className="reminder">Evaluation window (mins)</label>
                                </span>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-3">
                                        <select
                                            id="evaluation"
                                            autoFocus
                                            className={`form-control w-25`}
                                            value={1}
                                            disabled={true}
                                            data-lpignore={true}
                                        >
                                            <option value='1'>1</option>
                                        </select>
                                    </div>
                                    <div className="w-100"/>


                                    {/* Actions */}
                                    {/*<div className="col-xs-12 col-md-3">*/}
                                    {/*    <label className="reminder">Action (s)</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-xs-12 col-md-6 col-lg-3">*/}
                                    {/*    <MultiSelect value={actions} options={alertActions} showSelectAll={false}*/}
                                    {/*                 onChange={(e) => setActions(e.value)}*/}
                                    {/*                 optionLabel="name" display="chip"/>*/}

                                    {/*</div>*/}
                                    {/*<div className="w-100"/>*/}

                                    {/* enabled */}
                                    <div className="col-xs-12 col-md-3">
                                        <label className="reminder">Rule enabled</label>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-3">
                                        <div className='form-check form-switch'>
                                            <input
                                                type="checkbox"
                                                id="disabled"
                                                className="form-check-input"
                                                disabled={disabled}
                                                onChange={() => setEnabled(!enabled)}
                                                checked={enabled}
                                                data-lpignore={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-100"/>

                                    {/* Form Actions */}
                                    <div className="col-12 actions">
                                        <Link to="/admin/alert-rules/" role="button" className="btn btn-secondary">
                                            <i className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span>
                                        </Link>
                                        <button onClick={() => validateMetric()} type='submit' className={`btn btn-${action.variant}`}>
                                            <i className={`${action.icon} fa-sm`}/><span>{action.title}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default Detail;