import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_V2
});

// Add an interceptor to include the authorization header in all requests
api.interceptors.request.use((config: any) => {
    const savedToken = window.localStorage.getItem('dbmar-token') || ''
    config.headers.Authorization = `Basic ${savedToken}`;
    return config;
});

export default api;
