//import React from 'react';

// Constants.
import { HOST_TYPE_LINUX, HOST_TYPE_WINDOWS, HOST_TYPE_AWSRDS} from '../../component/Constants';

// Types.
import HostTarget from '../../types/host/HostTarget';

// Helpers.
import Helper from '../../helpers/Helper';

function HostType(props: { host: HostTarget }) {

    const tooltip = Helper.getHostType(props.host);

    let iconClassName = '';

    switch (props.host.type) {
        case HOST_TYPE_LINUX:
            iconClassName = 'fab fa-linux fa-fw';
            break;
        case HOST_TYPE_WINDOWS:
            iconClassName = 'fab fa-windows fa-fw';
            break;
        case HOST_TYPE_AWSRDS:
            iconClassName = 'fab fa-aws fa-fw';
            break;
        default:
            // Unknown host type.
            iconClassName = 'fab fa-server fa-fw';
            break;
    }

    return (
        <span className="float-center" data-tip={tooltip}>
            <i className={iconClassName} aria-hidden="true"></i>
        </span>
    );
}

export default HostType;