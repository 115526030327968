import React, { useContext, useEffect, useState } from 'react';

// Third-parties.
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Api.
import api from '../api/Base';

// Context
import { InstanceContext } from '../context/InstanceContext';
import { EventTypeContext } from '../context/EventTypeContext';

// Types.
import { TimeRangeContext, TimeRangeContextType } from "../context/TimeRangeContext";
import InstanceTarget from '../types/instance/InstanceTarget';

// Constants.
import { ACTION_CREATE, ACTION_UPDATE, ACTION_DELETE } from './Constants';

// Components.
import Alert from './Alert';

function RegisterEvent(props: { timeRangeContext: TimeRangeContextType }) {
    // Set the state.
    const { instances } = useContext(InstanceContext);
    const { eventTypes } = useContext(EventTypeContext);
    const timeRangeContext = useContext(TimeRangeContext)
    const [events, setEvents] = useState<any[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);    
    const [disabled] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [eventId] = useState<number>(0);
    const [title, setTitle] = useState<string>('');
    const [titleUsed, setTitleUsed] = useState<boolean>(false);
    const [titleClass, setTitleClass] = useState<string>('');
    const [eventTypeId, setEventTypeId] = useState(0);
    const [eventTypeIdClass, setEventTypeIdClass] = useState<string>('');
    const [startDateTime, setStartDateTime] = useState<Date>(new Date());
    const [startDateTimeClass, setStartDateTimeClass] = useState<string>('');
    const [endDateTime, setEndDateTime] = useState<Date>(new Date());
    const [endDateTimeClass, setEndDateTimeClass] = useState<string>('');
    const [isPeriod, setIsPeriod] = useState<boolean>(false);
    const [advanced, setAdvanced] = useState<boolean>(false);
    const [colourCode, setColourCode] = useState<string>('#6c757d');
    const [detailsUrl, setDetailsUrl] = useState<string>('');
    const [detailsUrlClass, setDetailsUrlClass] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [filter, setFilter] = useState<string>('');
    const [filterClass, setFilterClass] = useState<string>('');
    const [selectedInstances, setSelectedInstances] = useState<InstanceTarget[]>([]);
    const [mappedInstances, setMappedInstances] = useState<number[]>([]);

    useEffect(() => {
        void getChanges()
    }, []);

    const getChanges = async() => {
        let url = `event?${timeRangeContext.getTimeRangeQueryString()}&sort=timeslice+desc&limit=50000`
        const allEvents = await api.get(url).then((response) => {
            return response.data
        })
        setEvents(allEvents)
    }

    function handleNameBlur() {

        // Trim any white space.
        let value = title.trim();

        // Save to state.
        setTitle(value);

        if (value === '') {

            // Invalid, set the class.
            setTitleClass('is-invalid');

            return;

        } else {

            // Valid, set the class.
            setTitleClass('is-valid');
        }

        // Check for duplicate names.
        const index: number = events.findIndex(object => object.eventId !== eventId && object.title.toLowerCase() === value.toLowerCase());

        if (index === -1) {

            // No duplicates found.
            setTitleClass('is-valid');
            setTitleUsed(false);
        } else {

            // Duplicate found.
            setTitleClass('is-invalid');
            setTitleUsed(true);
        }
    }

    function handleDetailsUrlBlur(e: { target: { value: string; }; }) {

        if (e.target.value.trim().length === 0) {

            setDetailsUrl('');
            setDetailsUrlClass('');

            return;
        }

        // Validate entry as a valid, but basic, URL, while catering for custom ports too.
        let data = e.target.value.toString(),
            regexp = /^(http|https):\/\/[^ "]+$/;

        if (regexp.test(data)) {
            setDetailsUrlClass('is-valid');
        } else {
            setDetailsUrlClass('is-invalid');
        }
    }

    function handleInstanceMapping(instanceId: number) {
        
        let updatedMappedInstances = [...mappedInstances];

        // Look for a match.
        let match = updatedMappedInstances.filter(id => {
            return id === instanceId;
        });

        if (match.length === 0) {

            // Add the association.
            updatedMappedInstances.push(instanceId);

            setSelectedInstances([...selectedInstances, ...instances.filter(instance => instance.id === instanceId)]);

        } else {

            // Remove the association.
            updatedMappedInstances = [...updatedMappedInstances.filter(id => id !== instanceId)];
        }
        setMappedInstances([...updatedMappedInstances]);
    }

    function clearFilter() {

        // Clear any pre-existing filter values.
        setFilter('');
    }

    async function handleActionClick() {

        // Validate the form.
        let valid = true;

        if (title.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setTitleClass('is-invalid');
        } else {

            // Valid.
            setTitleClass('is-valid');
        }

        if (eventTypeId === 0) {

            valid = false;

            // Invalid, set the class.
            setEventTypeIdClass('is-invalid');
        } else {

            // Valid.
            setEventTypeIdClass('is-valid');
        }

        if (mappedInstances.length === 0) {

            // Invalid, set the class.
            valid = false;

            setFilterClass('is-invalid')
        } else {

            setFilterClass('is-valid')
        }

        if (colourCode === '') {

            // If missing, just reset the colour code to the original default colour code.
            setColourCode('#6c757d');
        }

        if (isPeriod) {

            // Ensure the start date is before the end date.
            const 
                from: Dayjs = dayjs.default(startDateTime),
                to: Dayjs = dayjs.default(endDateTime);

            if (!from.isValid() || !to.isValid() || from.isAfter(to)) {

                // Invalid.
                valid = false;

                setStartDateTimeClass('is-invalid');
                setEndDateTimeClass('is-invalid');
            } else {

                setStartDateTimeClass('');
                setEndDateTimeClass('');
            }
        } else {

            setStartDateTimeClass('');
            setEndDateTimeClass('');
        }

        setIsValid(valid);

        if (!valid) {
            return;
        }

        // For now, we're only allowing create functionality.
        const action = ACTION_CREATE;

        let results: any[] = [];

        // Create an event for each mapped instance.
        for (let index = 0; index < mappedInstances.length; index++) {

            // Build the event type object.
            let data = [{
                eventId: 0,
                databaseTargetId: mappedInstances[index],
                eventTypeId,
                startDateTime,
                endDateTime: ((isPeriod === true) ? endDateTime : null),
                colourCode,
                title,
                description,
                detailsUrl
            }];

            switch (action) {
                case ACTION_CREATE:

                    // Save the object.
                    results.push(await api.post(`event?tz=${props.timeRangeContext.timeRange.timezone}`, data));

                    break;
                case ACTION_UPDATE:

                    // Update the object.
                    results.push(await api.put(`event?tz=${props.timeRangeContext.timeRange.timezone}`, data));

                    break;
                case ACTION_DELETE:

                    // Delete the object.
                    results.push(await api.delete(`event?tz=${props.timeRangeContext.timeRange.timezone}`, { data }));

                    break;
                default:

                    // Unknown action requested.
                    console.error('Unknown action requested', action);

                    setIsError(true);

                    break;
            }
        }

        if (results.filter(result => result.status !== 200).length > 0) {

            // Failed to complete the API request.
            console.error('Failed to complete one or more requested actions', action, results);

            // Failed to complete request.
            setIsError(true);

            // An error occurred, so exit this function.
            return;
        }

        // Todo: Get all the latest events, and update the context.

        // Inform the user of the successful completion.
        setIsSuccess(true);

        // Reset the input element values.
        setEventTypeId(0);
        setEventTypeIdClass('');
        setFilter('');
        setFilterClass('');
        setMappedInstances([]);
        setIsPeriod(false);
        setStartDateTime(new Date());
        setStartDateTimeClass('');
        setEndDateTime(new Date());
        setEndDateTimeClass('');
        setAdvanced(false);
        setColourCode('#6c757d');
        setTitle('');
        setTitleClass('');
        setDescription('');
        setDetailsUrl('');
    }

    return (
        <React.Fragment>
            <button type="button" className="btn btn-sm btn-success btn-register me-1" data-bs-toggle="modal" data-bs-target="#registerEvent"><i className="fal fa-plus-square fa-fw" /><span>Register Event</span></button>
            <div id="registerEvent" className="modal fade text-start" tabIndex={-1} aria-labelledby="registerEventLabel" aria-hidden="true">
                <div className="modal-dialog register-event">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="registerEventLabel">
                                <i className="fal fa-exchange fa-fw" aria-hidden="true" />
                                Register Custom Event
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">

                                {isError && (
                                    <div className="m-2">
                                        <Alert message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request." heading="Failed to create event" variant="alert-danger" />
                                    </div>
                                )}

                                {!isValid && (
                                    <div className="m-2">
                                        <Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
                                    </div>
                                )}

                                {isSuccess && (
                                    <div className="m-2">
                                        <Alert message="The entered events have been successfully created." heading="Events successfully created" variant="alert-success" />
                                    </div>
                                )}

                                <div className="row form">

                                    <div className="col-12">
                                        <p>Please complete the required details below:</p>
                                    </div>
                                    
                                    {/* Title */}
                                    <div className="col-5">
                                        <span className="required"><label htmlFor="name" className="reminder" data-tip="The title you want this event to be listed as">Event Title</label></span>
                                    </div>
                                    <div className="col-7">
                                        <input
                                            type="text"
                                            id="name"
                                            autoFocus
                                            className={`form-control ${titleClass}`}
                                            required
                                            maxLength={50}
                                            onBlur={handleNameBlur}
                                            onChange={e => setTitle(e.target.value)}
                                            value={title}
                                            disabled={disabled}
                                            data-lpignore={true}
                                        />
                                        {titleUsed ? (
                                            <small className="text-warning">Event name already used.</small>
                                        ) : (
                                            <small className="text-secondary">We recommend using a release version.</small>
                                        )}
                                    </div>
                                    <div className="w-100" />
                                        
                                    {/* Event Type */}
                                    <div className="col-5">
                                        <span className="required"><label htmlFor="eventTypeId" className="reminder" data-tip="The event type">Event Type</label></span>
                                    </div>
                                    <div className="col-7">
                                        <select
                                            id="eventTypeId"
                                            value={eventTypeId}
                                            onChange={e => setEventTypeId(Number(e.target.value))}
                                            className={`form-select ${eventTypeIdClass}`}
                                            disabled={disabled}>
                                            <option value="0">Select Event Type</option>
                                            {eventTypes.map((eventType) => (
                                                <option key={eventType.eventTypeId} value={eventType.eventTypeId}>{eventType.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-100" />

                                    {/* Instances */}
                                    <div className="col-5">
                                        <span className="required"><label htmlFor="mappings" className="reminder" data-tip="Map this custom event to one or more instances">Instances</label></span>
                                        <span className="badge bg-info" data-tip="Total mapped instance count">{mappedInstances.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div className="col-7">
                                        <div className="row g-0 mb-2">
                                            <div className="col-9">
                                                <input type="text" className={`form-control ${filterClass}`} placeholder="Filter Instances" value={filter} data-lpignore={true} onChange={(e) => setFilter(e.target.value.toLowerCase())} />
                                            </div>
                                            <div className="col-3 text-end">
                                                <button type="button" className="btn btn-small btn-dark" onClick={clearFilter}><i className="fal fa-times" /></button>  
                                            </div>
                                        </div>
                                        <div className="list-group">
                                            {instances.filter(item => item.name.toLowerCase().includes(filter)).sort((a, b) => a.name.localeCompare(b.name)).slice(0, 7).map((instance: { id: number; name: string; }) => (
                                                <label key={instance.id} className="list-group-item">
                                                    <input className="form-check-input me-2" type="checkbox" value={instance.id} checked={mappedInstances.filter(id => id === instance.id).length > 0} onChange={() => handleInstanceMapping(instance.id)} />
                                                    {instance.name}
                                                </label>
                                            ))}
                                            {instances.filter(item => item.name.toLowerCase().includes(filter)).length > 7 && (
                                                <label className="list-group-item text-black-50">
                                                    <small>Filter for more instances...</small>
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-100" />

                                    {/* Event Period */}
                                    <div className="col-5 d-none">
                                        <label htmlFor="periodEvent" className="reminder" data-tip="Check to set a defined period of time for this event">Event is over a Period</label>
                                    </div>
                                    <div className="col-7 d-none">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="periodEvent" checked={isPeriod} onChange={() => ((isPeriod === true) ? setIsPeriod(false) : setIsPeriod(true))} disabled={disabled} />
                                        </div>
                                    </div>
                                    <div className="w-100 d-none" />

                                    {/* Start Date and Time */}
                                    <div className="col-5">
                                        <span className="required"><label htmlFor="startDateTime" className="reminder" data-tip="The event date time">
                                            {isPeriod ? (
                                                <React.Fragment>Start Date</React.Fragment>
                                            ) : (
                                                <React.Fragment>Date and Time</React.Fragment>
                                            )}
                                        </label></span>
                                    </div>
                                    <div className="col-7">
                                        <DatePicker
                                            id="startDateTime"
                                            className={`form-control ${startDateTimeClass}`}
                                            dateFormat="yyyy/MM/dd HH:mm"
                                            placeholderText="yyyy/MM/dd"
                                            popperPlacement="auto"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            onChange={(date: Date) => setStartDateTime(date)}
                                            selected={startDateTime}
                                            shouldCloseOnSelect={true} />
                                    </div>
                                    <div className="w-100" />

                                    {isPeriod && (
                                        <React.Fragment>
                                            {/* End Date and Time */}
                                            <div className="col-5">
                                                <span className="required"><label htmlFor="endDateTime" className="reminder" data-tip="The event's end date time">End Date</label></span>
                                            </div>
                                            <div className="col-7">
                                                <DatePicker
                                                    id="endDateTime"
                                                    className={`form-control ${endDateTimeClass}`}
                                                    dateFormat="yyyy/MM/dd HH:mm"
                                                    placeholderText="yyyy/MM/dd"
                                                    popperPlacement="auto"
                                                    minDate={startDateTime}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    onChange={(date: Date) => setEndDateTime(date)}
                                                    selected={endDateTime}
                                                    shouldCloseOnSelect={true} />
                                            </div>
                                            <div className="w-100" />
                                        </React.Fragment>
                                    )}

                                    {/* Advanced Mode */}
                                    <div className="col-5">
                                        <label htmlFor="advanced" className="reminder" data-tip="Enable advanced mode">Advanced Mode</label>
                                    </div>
                                    <div className="col-7">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="advanced" checked={advanced} onChange={() => ((advanced === true) ? setAdvanced(false) : setAdvanced(true))} disabled={disabled} />
                                        </div>
                                    </div>
                                    <div className="w-100" />

                                    {advanced && (
                                        <React.Fragment>
                                            {isPeriod && (
                                                <React.Fragment>
                                                    {/* Event Colour */}
                                                    <div className="col-5">
                                                        <label htmlFor="colourCode" className="float-start reminder" data-tip="Set a custom colour code for this event">Custom Colour Code</label>
                                                        <div className="float-start rounded-pill mt-1 ms-2" style={{ backgroundColor: colourCode, height: '10px', width: '10px' }} />
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="row row-cols-4 g-0">
                                                            <div className="color bg-primary" role="button" onClick={() => setColourCode('#007bff')} />
                                                            <div className="color bg-secondary mx-1" role="button" onClick={() => setColourCode('#6c757d')} />
                                                            <div className="color bg-success" role="button" onClick={() => setColourCode('#28a745')}>&nbsp;</div>
                                                            <div className="color bg-danger mt-1" role="button" onClick={() => setColourCode('#dc3545')} />
                                                            <div className="color bg-warning mt-1 mx-1" role="button" onClick={() => setColourCode('#ffc107')} />
                                                            <div className="color bg-info mt-1" role="button" onClick={() => setColourCode('#17a2b8')}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100" />
                                                </React.Fragment>
                                            )}

                                            {/* Event Description */}
                                            <div className="col-5">
                                                <label htmlFor="description" className="reminder" data-tip="Set the event description">Short Description</label>
                                            </div>
                                            <div className="col-7">
                                                <textarea id="description" className={`form-control`} defaultValue={description} onBlur={e => setDescription(e.target.value)} />
                                            </div>
                                            <div className="w-100" />

                                            {/* Release Notes Url */}
                                            <div className="col-5">
                                                <label htmlFor="detailsUrl" className="reminder" data-tip="The URL to your release notes">Release Notes Url</label>
                                            </div>
                                            <div className="col-7">
                                                <input
                                                    type="text"
                                                    id="detailsUrl"
                                                    className={`form-control ${detailsUrlClass}`}
                                                    required
                                                    maxLength={250}
                                                    onBlur={handleDetailsUrlBlur}
                                                    onChange={e => setDetailsUrl(e.target.value)}
                                                    value={detailsUrl}
                                                    disabled={disabled}
                                                    data-lpignore={true}
                                                />
                                                {detailsUrlClass !== '' ? (
                                                    <small className="text-danger">Invalid web address entered.</small>
                                                ) : (
                                                    <small className="text-secondary">Optional, set a web link to release notes.</small>
                                                )}
                                            </div>
                                            <div className="w-100" />
                                                
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer actions">
                            {isSuccess ? (
                                <button className="btn btn-secondary" data-bs-dismiss="modal"><i className="fal fa-arrow-circle-left fa-fw fa-sm" aria-hidden="true" /><span>Close</span></button>
                            ) : (
                                <React.Fragment>
                                    <button className="btn btn-secondary" data-bs-dismiss="modal"><i className="fal fa-times-circle fa-fw fa-sm" aria-hidden="true" /><span>Cancel</span></button>
                                    <button className="btn btn-success" onClick={handleActionClick}><i className="fal fa-save fa-fw fa-sm" /><span>Register</span></button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RegisterEvent;