import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as dayjs from "dayjs";
import { getDefaultPageSize } from "../../../helpers/utils";
import SortClass from "../../../component/SortClass";
import NoData from "../../../component/NoData";
import { DATA_LOADING } from "../../../component/Constants";

function List(props: { loading: number, refresh: Function, integrations: any }) {
    const [tableFilter, setTableFilter] = useState<string>('');

    function clearTableFilter() {
        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = props.integrations

    const columns: Column[] = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            className: 'fw-bold',
            defaultCanSort: true,
        },
        {
            Header: 'Description',
            accessor: 'desc',
            defaultCanSort: true,
        },
        {
            Header: 'Defined',
            accessor: 'isSet',
            defaultCanSort: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    <div className='form-check form-switch'>
                        <input checked={cell.row.values.isSet}
                               type="checkbox"
                               className="form-check-input"
                               disabled={true}
                               data-lpignore={true}
                        />
                    </div>
                </React.Fragment>
            )
        },
        {
            Header: 'Enabled',
            accessor: 'enabled',
            defaultCanSort: true,
            Cell: ({ cell }) => (
                <React.Fragment>
                    <div className='form-check form-switch'>
                        <input checked={cell.row.values.enabled === 'true'}
                               type="checkbox"
                               className="form-check-input"
                               disabled={true}
                               data-lpignore={true}
                        />
                    </div>
                </React.Fragment>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            className: 'actions',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({ cell }) => (
                // @ts-ignore
                <Link to={cell.row.original.url} role="button"
                      className="btn btn-sm btn-primary">
                    <i className="fal fa-edit fa-fw"/><span>Edit</span>
                </Link>
            )
        }
    ], []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['alertActionId', 'htmlIconCode'],
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{ id: 'title', desc: false }]
            }
        },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-object-ungroup fa-fw" aria-hidden="true"/>
                    Integrations
                    <span className="badge bg-info" data-tip="Total alert actions count">{data.length}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseAlerts" aria-expanded="false" aria-controls="collapseAlerts"/>
                </div>
                <div id="collapseAlerts" className="card-body collapse show">
                    <React.Fragment>
                        <div className="row row-cols-1 row-cols-md-2 table-search">
                            <div className="col col-md-9">
                                <CSVLink role="button"
                                         headers={[
                                             { label: 'Name', key: 'name' },
                                             { label: 'Description', key: 'description' },
                                         ]}
                                         data={data}
                                         download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Alert Actions.csv`}
                                         className="btn btn-sm btn-primary">
                                    <i className="fal fa-file-export fa-fw"/><span>Export</span>
                                </CSVLink>
                                <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                                    className="far fa-undo"/><span>Clear</span></button>
                            </div>
                            <div className="col col-md-3">
                                <input type="text" autoFocus className="form-control form-control-sm"
                                       placeholder="Search"
                                       disabled={true}
                                       value={tableFilter} data-lpignore={true}
                                       onChange={(e) => setTableFilter(e.target.value)}/>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table {...getTableProps()} className="table">
                                <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={(column as any).className}>
                                                {column.render('Header')}
                                                <SortClass column={column} />
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}
                                                        className={(cell.column as any).className}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>

                            <NoData
                                error={''}
                                loading={props.loading === DATA_LOADING}
                                length={data.length}
                            />
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}

List.propTypes = {
    refresh: PropTypes.func.isRequired,
};

export default List;
