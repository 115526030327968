import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import api from "../../../api/Base";

// Third-party packages.
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

import { CSVLink } from 'react-csv';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

// Context.
import { EventTypeContext } from '../../../context/EventTypeContext';

// Types.
import HostsAdminDataTable from '../../../types/host/tables/HostsAdminDataTable';

// Constants.
import { DATA_LOADING } from '../../../component/Constants';

// Components.
import StatusIcon from '../../../component/StatusIcon';
import HostType from '../../../component/host/HostType';
import ActionButtons from '../../../component/buttons/ActionButtons';
import NoData from '../../../component/NoData';
import TablePagination from '../../../component/TablePagination';
import { getDefaultPageSize } from "../../../helpers/utils";
import SortClass from "../../../component/SortClass";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(relativeTime.default);
dayjs.extend(customParseFormat.default);

function List(props: { loading: number, hostsAdminDataTable: HostsAdminDataTable[] }) {
    const { eventTypes } = useContext(EventTypeContext);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [agents, setAgents] = useState<any[]>([]);
    const [hostsList, setHostsList] = useState<HostsAdminDataTable[]>([]);

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const removeHost = (hostId: number) => {
        const updatedHostsList = hostsList.filter(host => host.id !== hostId);
        setHostsList(updatedHostsList);
    }

    const data = useMemo(() => hostsList.filter(row => (row.name.toLowerCase().includes(tableFilter.toLowerCase())) || (row.hostAddress.toLowerCase().includes(tableFilter.toLowerCase()))), [hostsList, tableFilter]);

    useEffect(() => {
        setHostsList(props.hostsAdminDataTable)
    }, [props.hostsAdminDataTable]);

    useEffect(() => {

        const getAgents = async() => {
            const response = await api.get(`agent `) || []
            if (response) {

                setAgents(response.data)
            }
        }
        void getAgents();
    }, []);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            accessor: 'host',
            disableSortBy: true
        },
        {
            Header: 'Host',
            accessor: 'name',
            className: 'fw-bold',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <StatusIcon hostId={cell.row.values.id} removeFromList={removeHost} mode="admin" />
                    {cell.row.values.name}
                </React.Fragment>
            )
        },
        {
            Header: '',
            accessor: 'type',
            className: 'text-center col-width-5',
            headerClassName: 'text-center col-width-5',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <HostType host={cell.row.values.host} />
                </React.Fragment>
            )
        },
        {
            Header: 'Host Address',
            accessor: 'hostAddress',
            className: 'concatenate',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({ cell }) => (
                cell.row.values.hostAddress
            )
        },
        {
            Header: 'Agent',
            accessor: 'agent',
            sortType: (rowA: any, rowB: any) => {
                return (rowA.values.host.agentid - rowB.values.host.agentid)
            },
            Cell: ({cell}) => {
                const getAgent = agents.find(agent => agent.id === cell.row.values.host.agentid)
                return getAgent ? getAgent.name : ''
            }
        },
        {
            Header: 'Agent Version',
            accessor: 'version',
            sortType: (rowA: any, rowB: any) => {
                return (rowA.values.host.agentid - rowB.values.host.agentid)
            },
            Cell: ({cell}) => {
                const getAgent = agents.find(agent => agent.id === cell.row.values.host.agentid)
                return getAgent?.version ? getAgent.version : ''
            }
        },
        {
            Header: <div className='d-flex justify-content-between'><span className='w-25 text-start'>Last Contacted</span><span>Actions</span></div>,
            accessor: 'actions',
            className: 'actions',
            disableSortBy: true,
            headerClassName: 'actions',
            Cell: ({ cell }) => (
                <ActionButtons host={cell.row.values.host} eventTypes={eventTypes}/>
            )
        }
    ], [eventTypes, agents]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        initialState: { hiddenColumns: ['id', 'host'], pageIndex: 0, pageSize: getDefaultPageSize(), sortBy: [{ id: 'name', desc: false }] }
    },
        useSortBy, usePagination);

    return (
        <div className="col h-100">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-server fa-fw" aria-hidden="true"></i>
                    Monitored hosts
                    <span className="badge bg-info" data-tip="Total host count">{data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseHosts" aria-expanded="false" aria-controls="collapseHosts"></i>
                </div>
                <div id="collapseHosts" className="card-body collapse show">
                    <div className="row row-cols-1 row-cols-md-2 table-search">
                        <div className="col col-md-9">
                            <Link to="/admin/hosts/create" role="button" className="btn btn-sm btn-success">
                                <i className="fal fa-plus-square fa-fw"></i><span>Create</span>
                            </Link>
                            <CSVLink role="button"
                                headers={[ { label: 'ID', key: 'id' }, { label: 'Host Name', key: 'name' }, { label: 'Type', key: 'type' }, { label: 'Host Address', key: 'hostAddress' }, { label: 'Last Contact', key: 'updated' } ]}
                                data={data}
                                download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Hosts.csv`}
                                className="btn btn-sm btn-primary">
                                <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                            </CSVLink>
                            <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i className="far fa-undo"></i><span>Clear</span></button>
                        </div>
                        <div className="col col-md-3">
                            <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search" value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={(column as any).className}>
                                                {column.render('Header')}
                                                <SortClass column={column} />
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps ()} className={(cell.column as any).className}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
						<NoData
							error={null}
							loading={props.loading === DATA_LOADING}
							length={data.length}
						/>
						<TablePagination
							length={data.length}
							pageSize={pageSize}
							setPageSize={setPageSize}
							pageOptions={pageOptions}
							pageCount={pageCount}
							canPreviousPage={canPreviousPage}
							previousPage={previousPage}
							canNextPage={canNextPage}
							nextPage={nextPage}
							gotoPage={gotoPage}
							pageIndex={pageIndex}
						/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List;
