import React, { useEffect } from 'react';

// Third-parties.
import * as QueryPlan from 'html-query-plan';

// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

function SqlBatchPlan(props: { executionPlan: ExecutionPlan }) {

	// TODO This is a horrible hack.
	// React renders all tabs in Batch.tsx before any tabs are shown.
	// QueryPlan in the Execution Plans tab renders the boxes as divs.
	// But it draws the arrows dynamically using JavaScript.
	// Calling showPlan when the Execution Plans tab is not visible yet results
	// in the arrows being squashed into the top-left-hand corner of the tab
	// body. This is because the bounding client rectangle is always zero when
	// the tab is not visible.
	// The tab is selected by dynamic CSS. React does not know it has been
	// selected and therefore does not try to show the plan in a valid
	// rectangle. Perhaps should drop dynamic CSS and use a tab click event.
	//
	// Tried using an on-click handler in Batch.tsx but it can only draw the
	// lines using QueryPlan.drawLines ('query-plan-diagram').
	// It does not know which plan to use in a call to QueryPlan.showPlan
	// ('query-plan-diagram', plan?).
	// It also fires before the plan tab is shown so the bounding rectangle is
	// still zero on first click.
	useEffect (function ()
	{
		function showPlanWhenVisible ()
		{
			// Set the plan.
			// console.log ('1-second timer');
			const container: HTMLElement | null =
				document.getElementById ("query-plan-diagram");

			if (container)
			{
				// Inspect the bounding client rectangle before QueryPlan does
				// in showPlan / drawLines.
				const clientRect: DOMRect = container.getBoundingClientRect ();

				if (clientRect.height !== 0 && clientRect.width !== 0)
				{
					// No need for the timer anymore.
					// console.log ('client rectangle is valid', clientRect);
					QueryPlan.showPlan (container, props.executionPlan.sqlplan);
				}
				else
				{
					// Continue waiting for the plan tab to become visible.
					setTimeout (showPlanWhenVisible, 1000);
				}
			}
		}

		if (props.executionPlan.sqlplan)
		{
			// Wait for the plan tab to become visible.
			setTimeout (showPlanWhenVisible, 1000);
		}
	}, [props.executionPlan.sqlplan]);

    return (
        <React.Fragment>
            <div id="query-plan-diagram">&nbsp;</div>
        </React.Fragment>
    );
}

export default SqlBatchPlan;
