import { useContext, useEffect, useMemo, useState } from 'react';

// Third-parties.
import Highlight from 'react-highlight'

// Types.
import Statement from '../../types/instance/Statement';

// Helpers.
import {
    copyToClipboard,
    formatSqlStatement,
    formatStatement
} from "../../helpers/utils";

// Components.
import SqlIdentifiers from '../SqlIdentifiers';
import ConditionalRender from "../../helpers/ConditionalRender";

// Contexts.
import { InstanceContext } from "../../context/InstanceContext";
import { TimeRangeContext } from "../../context/TimeRangeContext";
import { getStatementTypeFromURL } from "../../views/instances/tabs/utils";
import api from "../../api/Base";
import api_v2 from "../../api/Base_v2";

function StatementText(props: { instanceId: number, id?: string, setStatement: Function }) {
    const [statement, setStatement] = useState<Statement[]>([]);
    const [sqlIDs, setSQLIDs] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const {instances} = useContext(InstanceContext);
    const instance = instances.find(i => i.id === props.instanceId)
    const instanceType = instance?.type ?? ''
    const [copyToolTip, setCopyToolTip] = useState<string>('');
    const timeRangeContext = useContext(TimeRangeContext)

    const { isBatch, isGroup } = getStatementTypeFromURL();

    useEffect(() => {

        const getStatement = async() => {
            let data: Statement[] = [];

            await api.get(`/sql?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&sqlhash=${props.id}`)
                .then((response: { data: Statement[]; }) => {
                    data = response.data;
                    setStatement(data);
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve statement.', error);
                })
        }

        const getStatementActivity = async() => {
            let data: Statement[] = [];
            const isGroupText = isGroup ? '/group' : ''

            await api.get(`/activity/sql${isGroupText}?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&sqlhash=${props.id}`)
                .then((response: { data: Statement[]; }) => {
                    data = response.data;
                    if (data[0]) {
                        const newSQLID = data[0].sqlid;
                        const idList = (prevSQLIDs: any) => {
                            if (!prevSQLIDs.includes(newSQLID)) {
                                return [...prevSQLIDs, newSQLID];
                            }
                            return prevSQLIDs;
                        }
                        setSQLIDs(idList);
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve statement activity.', error);
                })
        }

        const getStatementStatistics = async () => {
            let data: Statement[] = [];
            const isGroupText = isGroup ? '/group' : '/sql';

            try {
                const response = await api_v2.get(`/sql/statistic${isGroupText}?${timeRangeContext.getTimeRangeQueryString()}&type=${instance?.type}&id=${props.instanceId}&sqlhash=${props.id}`);
                data = response.data;
                if (data[0]) {
                    const newSQLID = data[0].sqlid;
                    const idList = (prevSQLIDs: any) => {
                        if (!prevSQLIDs.includes(newSQLID)) {
                            return [...prevSQLIDs, newSQLID];
                        }
                        return prevSQLIDs;
                    };
                    setSQLIDs(idList);
                }
            } catch (error) {
                console.error('Failed to retrieve statement sql activity.', error);
            }
        };

        void getStatement();

        // Get sql ID for statement
        void getStatementStatistics();
        void getStatementActivity();

    }, [props.instanceId, props.id]);

    const formattedSql: string = useMemo(() => formatStatement(statement, instance?.type ?? ''), [statement, instance?.type]);


    useEffect(() => {
        props.setStatement(formattedSql)
    }, [formattedSql]);

    if (statement.length === 0) {
        return (
            <div id="statement" role="tabpanel" aria-labelledby="statement-tab">
                <div className="card">
                    <div className="card-header sticky-card-header">
                        <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                        Statement text
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                           data-bs-target="#collapseStatements" aria-expanded="false"
                           aria-controls="collapseStatements"/>
                    </div>
                    <ConditionalRender if={loading}>
                        <div className="w-100 text-center text-muted mt-3">
                            <div className="loader spinner chartSpinner">
                            </div>
                            <p className="mt-3">
                                Loading data...
                            </p>
                        </div>
                    </ConditionalRender>

                    <ConditionalRender if={!loading}>
                        <div id="collapseStatements" className="card-body collapse show">
                            <div className="w-100 text-center text-muted my-3">
                                <i className="fal fa-ban fa-fw fa-2x"/>
                                <p>
                                    No Statement Found
                                </p>
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
            </div>
        )
    }

    const setCopyToolTipText = () => {
        setCopyToolTip('Copied to Clipboard')
        setTimeout(() => setCopyToolTip(''), 500)
    }

    const showSQLID = () => {
        const sqlIDText = isBatch ? 'Batch text for' : 'Statement text for';
        const sqlIdentifier = statement[0]?.batchsqlhash || statement[0]?.sqlhash;
        const sqlIDsFiltered = sqlIDs.filter(id => id !== sqlIdentifier.toString());
        return (
            <>
                {sqlIDText} <SqlIdentifiers sqlhash={sqlIdentifier} sqlid={sqlIDsFiltered.join(', ')} />
            </>
        );
    };

    return (
        <div id="statement">
            <div className="card">
                <div className="card-header sticky-card-header">
                    <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                    {showSQLID()}
                    <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                       data-bs-target="#collapseStatements" aria-expanded="false"
                       aria-controls="collapseStatements"/>
                    <i data-testid='copy-clipboard'
                       onClick={() => copyToClipboard(formatSqlStatement(statement[0].sqltext, instanceType), setCopyToolTipText)}
                       className="fal fa-regular fa-copy clipboard-copy" role="button"/>
                    <ConditionalRender if={copyToolTip}>
                        <p className='clipboard-tooltip bottom'>{copyToolTip}</p>
                    </ConditionalRender>
                </div>
                <div id="collapseStatements" className="card-body collapse show">
                    <Highlight className="sql">
                        {formattedSql}
                    </Highlight>
                </div>
            </div>
        </div>
    );
}

StatementText.propTypes = {};

export default StatementText;
