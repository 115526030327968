// Api.
import api from '../Base';

// Types.
import Statistic from '../../types/instance/Statistic';
import DatabaseTime from '../../types/instance/DatabaseTime';
import { TimeRangeContextType } from "../../context/TimeRangeContext";

const StatisticApi = {
    
    getStatistic: async (timeRangeContext: TimeRangeContextType, instanceId: number, statistic: string, filterParams: string) => {
		// Special handling for executions with a filter.
		if (filterParams && statistic === 'executions') {
			let url = `activity/summary?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}${filterParams}`;

			let data: number = 0;

            try {
                const response = await api.get<DatabaseTime[]>(url)
                if (response.data.length > 0 && response.data[0].executions) {
                    data = response.data[0].executions;
                }
            } catch (ignore) {}

			return data;
		}

        let url = `statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=${statistic}&id=${instanceId}`;
        let data: number = 0;
        try {
            const response = await api.get<Statistic[]>(url)
            if (response.status === 200 && response.data.length > 0) {
                data = response.data[0].sum;
            }
        } catch (ignore) {}

        return data;
    },
    
    getTimeStatistic: async (timeRangeContext: TimeRangeContextType, instanceId: number, filterParams: string) => {

        let url = `activity/summary?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}${filterParams}`;

        let data: number = 0;

        await api.get<DatabaseTime[]>(url)
            .then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    data = response.data[0].waittime;
                }
            })
            .catch((error: any) => {
                console.error('Failed to retrieve instance time statistic.', error, timeRangeContext, instanceId);
            })
            .then(function () {});

        return data;
    }
}

export default StatisticApi;
