// React.
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-parties.
import dayjs from 'dayjs';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { CSVLink } from 'react-csv';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { OracleSqlHashTimeSlice, OracleSqlHashWithMetric } from "../types";

// Helper.
import Helper from '../../../helpers/Helper';
import {
    archiverUrl,
    fetchResults, fetchWithAuthorization,
    getDefaultPageSize, getToolTipPosition,
    stringFormatting
} from '../../../helpers/utils';

// Components.
import Alert from "../../../component/Alert";
import TablePagination from "../../../component/TablePagination";
import NoData from "../../../component/NoData";
import StatementToolTip from "../../../component/instance/table/StatementToolTip";

// Constants.
import { DEFAULT_TABLE_METRICS, SQL_STATISTICS_API_VERSION } from "../constants";

export default function OracleStatisticsTable(props: {
                                                  period: Period,
                                                  instance: InstanceTarget,
                                                  generateId: number,
                                                  aggregate: number,
                                                  chart: string,
                                                  filter: string,
                                                  metric: string, tagValue: string,
                                                  tagName: string, topN: number,
                                                  shwSqlID: boolean,
                                                  statements: OracleSqlHashWithMetric[],
                                                  customSqlId: string,
                                              }
) {
    const searchParams = new URLSearchParams(window.location.search)
    const apiVersion = parseInt(searchParams.get('api_version') || '', 10) || SQL_STATISTICS_API_VERSION

    const [sqlStatistics, setSqlStatistics] = useState<OracleSqlHashWithMetric[]>([]);
    const [singleSqlStats, setSingleSqlStats] = useState<OracleSqlHashTimeSlice[]>([]);
    const [tableFilter, setTableFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });
    const intervalQuery =  props.aggregate ? `&interval=${props.aggregate}` : ''
    const initialColumns = useMemo(() => {
        if (props.tagName !== 'none' && !props.tagValue) {
            return [{
                Header: 'Tag Value',
                accessor: 'tagValue',
                headerClassName: 'text',
                className: 'concatenate',
                canSort: true,
                Cell: ({cell}: any ) =>
                    <React.Fragment>
                        <span className="me-1 ps-1"
                              style={{backgroundColor: cell.row.original.color}}>&nbsp;</span>
                        {props.customSqlId ?
                            <Link target="_blank"
                                  to={`/reports/sql-stats-explorer?instance=${props.instance.id}&fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}${intervalQuery}&filter=${props.customSqlId}&metric=${props.metric}&chart=${props.chart}&showSqlId=${props.shwSqlID}&customId=${props.shwSqlID ? cell.row.original.sqlid : cell.row.original.sqlhash}`}
                                  className='mr-1 open_statement'>{cell.row.original.tagvalue}</Link>
                            :
                            <Link target="_blank"
                                  to={`/reports/sql-stats-explorer?instance=${props.instance.id}&fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}${intervalQuery}&metric=${props.metric}&chart=${props.chart}&showSqlId=${props.shwSqlID}&tagValue=${cell.row.original.tagvalue}&tagName=${props.tagName}`}>{cell.row.original.tagvalue}</Link>}
                    </React.Fragment>
            }]
        } else {
            return [{
                Header: '#',
                accessor: 'sqlhash',
                headerClassName: 'text',
                className: 'concatenate',
                canSort: true,
                Cell: ({cell}: any) =>
                    <React.Fragment>
                        <span className="me-1 ps-1"
                              style={{backgroundColor: cell.row.original.color}}>&nbsp;</span>
                        {(!props.filter || props.tagValue) && <Link target="_blank"
                                                                    to={`/reports/sql-stats-explorer?instance=${props.instance.id}&fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}${intervalQuery}&filter=${cell.row.values.sqlhash}&metric=${props.metric}&chart=${props.chart}&showSqlId=${props.shwSqlID}&customId=${props.shwSqlID ? cell.row.original.sqlid : cell.row.original.sqlhash}`}
                                                                    className='mr-1 open_statement'>
                            <i className={`fal fa-light fa-arrow-up-right-from-square fa-fw`}
                               data-tip="Open statement in new tab"/>
                        </Link>}
                        <Link target="_blank"
                              to={`/instances/${props.instance.id}/activity/statement/${cell.row.values.sqlhash}/sql-statistics?fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}${intervalQuery}`}>{props.shwSqlID && cell.row.original.sqlid ? cell.row.original.sqlid : cell.row.values.sqlhash}</Link>
                    </React.Fragment>
            },
                {
                    Header: 'Statement',
                    accessor: 'sqltext',
                    headerClassName: 'text',
                    className: 'concatenate-large',
                    canSort: true,
                    Cell: ({cell}: any) => <Link
                        to={`/instances/${props.instance.id}/activity/statement/${cell.row.values.sqlhash}/sql-statistics?fm=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}${intervalQuery}`}>{cell.row.values.sqltext.substring(0, 200)}</Link>
                }
            ]
        }
    }, [sqlStatistics])

    // Table columns
    const columns: Column[] = useMemo(() => {
        let secondSortOption = props.tagName !== 'none' ? 'tagvalue' : 'sqlhash'
        let defaultColumns = [
            ...initialColumns,
            {
                Header: 'Duration',
                accessor: DEFAULT_TABLE_METRICS.DURATION,
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.DURATION] - rowB.values[DEFAULT_TABLE_METRICS.DURATION] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) =>
                    (Helper.getTimeInEnglish(cell.row.values.duration))

            },
            {
                Header: 'Executions',
                accessor: DEFAULT_TABLE_METRICS.EXECUTIONS,
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.EXECUTIONS] - rowB.values[DEFAULT_TABLE_METRICS.EXECUTIONS] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) =>
                    (cell.row.values.executions ? Helper.getFormattedNumber(cell.row.values.executions) : '')
            },
            {
                Header: 'Avg. Duration',
                accessor: 'average_duration',
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) => cell.row.values.average_duration ? Helper.getTimeInEnglish(cell.row.values.average_duration) : ''
            },
            {
                Header: 'CPU Time',
                accessor: DEFAULT_TABLE_METRICS.CPU_TIME_MILLISECONDS,
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortDescFirst: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.CPU_TIME_MILLISECONDS] - rowB.values[DEFAULT_TABLE_METRICS.CPU_TIME_MILLISECONDS] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                // @ts-ignore
                Cell: ({cell}) =>
                    (cell.row.values.cpu_time_milliseconds ? Helper.getTimeInEnglish(cell.row.values.cpu_time_milliseconds) : '')
            },
            {
                Header: 'Physical Reads',
                accessor: DEFAULT_TABLE_METRICS.PHYSICAL_READS,
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.PHYSICAL_READS] - rowB.values[DEFAULT_TABLE_METRICS.PHYSICAL_READS] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) =>
                    (cell.row.values.physical_reads ? Helper.getFormattedNumber(cell.row.values.physical_reads) : '')
            },
            {
                Header: 'Logical Reads',
                accessor: DEFAULT_TABLE_METRICS.LOGICAL_READS,
                headerClassName: 'concatenate-medium text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.LOGICAL_READS] - rowB.values[DEFAULT_TABLE_METRICS.LOGICAL_READS] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) =>
                    (cell.row.values.logical_reads ? Helper.getFormattedNumber(cell.row.values.logical_reads) : '')
            },
            {
                Header: 'Logical Writes',
                accessor: DEFAULT_TABLE_METRICS.LOGICAL_WRITES,
                headerClassName: 'concatenate-medium col-width-4 text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[DEFAULT_TABLE_METRICS.LOGICAL_WRITES] - rowB.values[DEFAULT_TABLE_METRICS.LOGICAL_WRITES] || rowA.original[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                sortDescFirst: true,
                // @ts-ignore
                Cell: ({cell}) =>
                    (cell.row.values.logical_writes ? Helper.getFormattedNumber(cell.row.values.logical_writes) : '')
            }
        ]

        const doesSelectedMetricExist = Object.keys(DEFAULT_TABLE_METRICS).indexOf(props.metric.toUpperCase())
        if (doesSelectedMetricExist < 0 && props.metric) {
            defaultColumns.push({
                Header: stringFormatting(props.metric).replace(/_/gi, ' '),
                accessor: props.metric,
                headerClassName: 'concatenate-medium col-width-4 text-end',
                className: 'concatenate-medium text-end',
                canSort: true,
                sortDescFirst: true,
                sortType: (rowA: any, rowB: any) => {
                    return rowA.values[props.metric] - rowB.values[props.metric] || rowA.values[secondSortOption]?.localeCompare(rowB.original[secondSortOption])
                },
                // @ts-ignore
                Cell: ({cell}) => `${Helper.getFormattedNumber(cell.row.values[props.metric], props.metric)}${Helper.getMetricFormatString(props.metric, false)}`
            })
        }
        return defaultColumns
    }, [sqlStatistics]);

    // Table data
    useEffect(() => {
            async function load() {
                try {
                    setLoading(true);
                    if (props.filter || props.tagValue) {
                        const apiSqlHash = props.tagName === 'none' ? `sqlhash=${props.filter}` : `tagname=${props.tagName}`
                        const resultWithTimeSlices = await fetchResults([fetchWithAuthorization(`${archiverUrl(apiVersion)}/sql/statistic/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&id=${props.instance.id}${intervalQuery}&sort=${props.metric}+desc&type=${props.instance.type}&${apiSqlHash}`)])
                        const sortedResultsWithTimeSlices = resultWithTimeSlices[0].sort(function compare(a: OracleSqlHashTimeSlice, b: OracleSqlHashTimeSlice) {
                            const aa: number = dayjs(a.timeslice).valueOf();
                            const bb: number = dayjs(b.timeslice).valueOf();
                            return aa < bb ? -1 : 1;
                        })
                        setSingleSqlStats(sortedResultsWithTimeSlices)
                    }
                    const results = props.statements
                    const resultsWithAverage = Helper.addAverageAndColor('duration', 'executions', results, props.topN)
                    if (active) {
                        setLoading(false);
                        setSqlStatistics(resultsWithAverage);
                    }
                } catch (x: any) {
                    setError(x.message);
                    setLoading(false);
                    setSqlStatistics([]);
                }
            }

            let active: boolean = true;
            load();
            return () => {
                active = false;
            }
        },
        [props.period.api, props.instance.id, props.statements, props.aggregate, props.filter, props.instance.id, props.metric, props.tagName, props.shwSqlID, apiVersion, props.tagValue]);

    const data = useMemo(() => {
        const filteredData = sqlStatistics.filter((row) =>
            row.sqltext?.toLowerCase().includes(tableFilter.toLowerCase()) ||
            row.sqlid?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
            row.sqlhash?.toLowerCase().includes(tableFilter.toLowerCase()) ||
            row.tagvalue?.toLowerCase().includes(tableFilter.toLowerCase()))

        filteredData.forEach((row) => {
            row.sqltext = row.sqltext?.replace(/"/gi, '\'') || ''
        })

        return filteredData
    }, [sqlStatistics, tableFilter]);

    const getCsvData = useCallback(() => {
        let csvData = props.filter && !props.tagValue ? singleSqlStats : data
        csvData = JSON.parse(JSON.stringify(csvData))
        try {
            let title = `Instance: ${props.instance.name}\nFrom: ${props.period.api.current.from}\nTo: ${props.period.api.current.to}\n`
            title += props.filter ? `Filter ${props.filter}` : props.tagName !== 'none' ? props.tagName : ''
            title += props.tagValue ? `/${props.tagValue}\n` : ''
            const sortedCSV: any = []
            let header = {[title]: null}


            // @ts-ignore
            csvData.forEach((item, i) => {
                if (item.timeslice) {
                    item.timeslice = item.timeslice.toString().replace(' ', ' - ')
                }
                if (props.filter && !props.tagValue && i === 0) {
                    item.sqlhash = props.statements[0].sqlhash
                    item.sqlid = props.statements[0].sqlid
                    item.sqltext = props.statements[0].sqltext.replace(/[\r\n]+/g, " ");
                }

                let csvOrder = {
                    'sqlhash': null,
                    'sqlid': null,
                }
                // move sqltext at the end
                const sqltextValue = item.sqltext
                // @ts-ignore
                delete item.sqltext
                delete item.color
                item.sqltext = sqltextValue


                if (props.tagName !== 'none') {
                    // delete sqltext and sqlid for tag name list
                    // @ts-ignore
                    delete item.sqltext
                    delete item.sqlid
                    // @ts-ignore
                    delete item.sqlhash
                    item.tagName = props.tagName
                    // @ts-ignore
                    csvOrder = {}


                    // add back sqltext and sqlid fot statements inside tag list
                    if (props.statements[0].sqlhash) {
                        item.sqlhash = props.statements[0].sqlhash
                        csvOrder['sqlhash'] = null
                    }
                    if (props.statements[0].sqlid) {
                        item.sqlid = props.statements[0].sqlid
                        csvOrder['sqlid'] = null
                    }
                }
                sortedCSV.push(Object.assign(csvOrder, item));
            })
            return [header, ...sortedCSV]
        } catch (ignore) {
            return csvData
        }
    }, [singleSqlStats, data, props.filter, props.tagValue])

    const tableTitle = useMemo(() => {
        if (props.filter) {
            return props.statements.length ? `Statements ${`for # ${props.statements[0].sqlhash}, ID ${props.statements[0].sqlid}`}` : ''
        }
        if (props.tagValue) {
            return `Statements for Tag Name: ${props.tagName}, Tag Value: ${props.tagValue}`
        }
        return `Statements `
    }, [props.generateId, props.statements])

    const
        {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            setPageSize,
            pageOptions,
            pageCount,
            canPreviousPage,
            previousPage,
            canNextPage,
            nextPage,
            gotoPage,
            state: {pageIndex, pageSize}
        } = useTable(
            {
                columns,
                data,
                initialState:
                    {
                        pageIndex: 0,
                        pageSize: getDefaultPageSize(),
                        sortBy: [{id: props.metric, desc: true}],
                    },
                disableSortRemove: true
            }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }

    return (
        <div className="card">
            <div className="card-header">
                {tableTitle}
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseStatements" aria-expanded="false" aria-controls="collapseStatements"/>
            </div>
            <div id="collapseStatements" className="card-body collapse show">
                {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 &&
                    (<Alert
                        message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by the number of executions.`}
                        heading="Statement records" variant="alert-info"/>)}
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 data={getCsvData()}
                                 download={`DBmarlin_${dayjs().format('YYYY-MM-DD_HH-mm')}_SQL-Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table">
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={(column as any).headerClassName}>
                                        {column.render('Header')}

                                        {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                <i className="fal fa-sort-amount-up-alt"></i> :
                                                <i className="fal fa-sort-amount-down-alt"></i>) :
                                            <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className={(cell.column as any).className}>


                                                {cell.column.id === "sqltext" ?
                                                    <div className='tooltip-scroll ellipsis'
                                                         onMouseEnter={(event) => setVisibleToolTip({
                                                             id: cell.row.values.sqlhash,
                                                             position: getToolTipPosition(event)
                                                         })}
                                                         onMouseLeave={() => setVisibleToolTip({
                                                             id: '',
                                                             position: 0
                                                         })}
                                                    >
                                                        {cell.render('Cell')}
                                                        {(cell.row.values.sqlhash === visibleToolTip.id &&
                                                            <StatementToolTip instanceType={props.instance.type}
                                                                              position={visibleToolTip.position}
                                                                              id={cell.row.values.sqlhash}
                                                                              link={`/instances/${props.instance.id}/activity/statement/${cell.row.values.sqlhash}`}
                                                                              cell={cell}/>
                                                        )}
                                                    </div> : cell.render('Cell')}
                                            </td>)
                                    })}
                                </tr>)
                        })}
                        </tbody>
                    </table>
                    <NoData error={error} loading={loading}
                            length={data.length}/>
                    <TablePagination
                        length={data.length}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                    />
                </div>
            </div>
        </div>
    )
}
