import './style.scss'

import React from "react";
import PropTypes from 'prop-types';

const TabbedMenu = (props: any) => {
    return <React.Fragment>
        <div id="menu-top" className="row row-cols-auto">
            <React.Fragment>{props.children}</React.Fragment>
        </div>
    </React.Fragment>
};

TabbedMenu.propTypes = {
    children: PropTypes.any.isRequired,
};

export default TabbedMenu;
