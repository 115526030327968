import { createContext, useContext } from 'react';

// Types.
import EventType from '../types/instance/EventType';

// Set the internal context object.
let eventTypes: EventType[] = [];

// Define the context type, including the update function.
export type EventTypeContextType = {
    eventTypes: EventType[];
    setEventTypes: (EventTypes: EventType[]) => void;
}

// Define the context object.
export const EventTypeContext = createContext<EventTypeContextType>({ eventTypes: eventTypes, setEventTypes: eventTypes => console.warn('No event types set.', eventTypes) });
EventTypeContext.displayName = 'Event Types';

// Export the update function for the context object.
export const useEventTypes = () => useContext(EventTypeContext);