import React, { useContext, useEffect, useState } from 'react';

// Third-party.

import ReactTooltip from 'react-tooltip';

// Helpers.
import Helper from '../../helpers/Helper';

// Types.
import Batch from '../../types/instance/Batch';
import Wait from '../../types/instance/Wait';
import { TimeRangeContext } from "../../context/TimeRangeContext";
import api from "../../api/Base";

function BatchBreakdown(props: { batch: Batch, waits: Wait[], instanceId: number, instanceTime?: number, filterParameters: string }) {
    const [waits, setWaits] = useState<Wait[]>([]);
    const [tooltip, setTooltip] = useState<string>();
    const timeRangeContext = useContext(TimeRangeContext)

    const chartSeriesCeiling = Number(process.env.REACT_APP_CHART_SERIES_CEILING);

    let batchWeight: number = 0;

    if (props.instanceTime) {
        batchWeight = Math.floor((100 / props.instanceTime) * props.batch.waittime);
    }

    useEffect(() => {

        const getBatchWaits = async () => {

            // Get the batch waits.
            api.get(`activity/waitevent?${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${props.instanceId}&batchsqlhash=${props.batch.batchsqlhash}${props.filterParameters}`)
            .then(async (response: { data: Wait[]; }) => {

                let batchWaits: Wait[] = response.data;
                
                let generatedTooltip: string = '';

                // Build top waits pie chart.
                for (let index = 0; index < batchWaits.length; index++) {

                    // Look for a matching passed wait state.
                    let matchedWaits: Wait[] = props.waits.filter(wait => wait.waitevent === batchWaits[index].waitevent);

                    if (matchedWaits.length > 0) {

                        // Use the matched wait colour.
                        batchWaits[index].color = matchedWaits[0].color;
                    } else {

                        // Unmatched wait, so use the generic colour.
                        batchWaits[index].color = '#999';
                    }

                    // Work out the wait percentage.
                    const weight = Math.floor((100 / props.batch.waittime) * batchWaits[index].waittime);

                    // Set the batch weight and build the tooltip.
                    batchWaits[index].weight = weight;

                    if (weight > 0) {
                        generatedTooltip = `${generatedTooltip}<li>${batchWaits[index].waitevent}: ${Helper.getTimeInEnglish(batchWaits[index].waittime)} (${batchWaits[index].weight}%)</li>`;
                    }
                }

                setWaits(batchWaits);
                setTooltip('<p>This batch has the following top waits:</p><ol>{waits}</ol>'.replace('{waits}', generatedTooltip));
            })
            .catch((error: any) => {
                console.error('Failed to retrieve wait event for batch.', error);
            })
            .then(function () {
                // As we're late data-binding, rebuild the tooltips.
                ReactTooltip.rebuild();
            });
        }

        if (props.instanceTime) {
            getBatchWaits();
        }

    }, [props.batch, props.waits, props.instanceTime, timeRangeContext, props.instanceId, chartSeriesCeiling, batchWeight, props.filterParameters]);

    if (batchWeight < 5) {
        return (
            <React.Fragment>-</React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{ width: `${batchWeight}%` }} data-tip={tooltip}>
                {waits.filter(wait => wait.weight && wait.weight > 0).map((wait: Wait, index) => (
                    <div key={index} style={{ width: `${wait.weight}%`, backgroundColor: wait.color }}>&nbsp;</div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default BatchBreakdown;
