import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, NavLink } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

// Helpers.
import Helper from "../../helpers/Helper";
import { getPlanAndTextTabName, getStatementTypeFromURL } from "../instances/tabs/utils";

// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import Period from '../../types/Period';
import EventType from '../../types/instance/EventType';
import ExecutionPlan from '../../types/instance/ExecutionPlan';
import ChartPlotLine from "../../types/ChartPlotLine";

// Components.
import PeriodBreadcrumb from '../../component/PeriodBreadcrumb';
import Breadcrumb from "../../container/breadcrumb";
import HeaderActions from "../../container/header_actions";
import ConditionalRender from "../../helpers/ConditionalRender";
import TimeShift from "../../component/TimeShift";
import FilterBar from "../../component/FilterBar";
import TabbedMenu from "../../container/tabbed-menu";
import TextAndPlan from "./tabs/TextAndPlan";
import StatementActivity from "./tabs/Activity";
import SqlStatistics from "./tabs/SQLStatistics";
import Batches from "../../component/instance/Tabs/Batches";
import GroupedBatches from "../../component/instance/Tabs/GroupedBatches";

import { INSTANCE_TYPE_SQLSERVER } from "../../component/Constants";

interface IStatementDetailProps {
    instanceId: number,
    batchId?: string,
    batchStatementId?: string,
    statementId?: string,
    instances: InstanceTarget[],
    period: Period,
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}


const Statement = (props: IStatementDetailProps) => {
    const {instanceId, batchId, statementId, instances, period, batchStatementId} = props;
    const getInstance: InstanceTarget[] = instances.filter(instance => instance.id === Number(instanceId))
    const instance = getInstance[0]
    const [chartPlanPlotLines, setChartPlanPlotLines] = useState<ChartPlotLine[]>([]);

    if (instance === undefined) {
        return (
            <React.Fragment>No Instance</React.Fragment>
        )
    }

    const setExecutionPlanOptions = (executionPlans: ExecutionPlan[]) => {
        setChartPlanPlotLines(Helper.getChartPlotLinesForExecutionPlans(period, executionPlans, period.ui.current.chartCategories))

    }

    const shouldShowTab = (value: string): boolean => {
        const currentInstance: any = instance
        return currentInstance !== undefined && (!!(currentInstance[`has${value}`] && currentInstance[`collect${value}`]))
    }

    const statementType = getStatementTypeFromURL()

    return (
        <div id="content">
            <Helmet>
                <title>{instance.name} - Statement View - DBmarlin</title>
                <meta name="description"
                      content="Instance statement performance view, showing key performance metrics and detailed executed statement and waits information"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb instanceId={instance.id}
                            type="instances"
                            heading={instance.name}>
                    <Link to="/instances">Analysis</Link>
                    <Link to="/instances">Instances</Link>
                    <Link to={`/instances/${instanceId}/activity`}>{instance.name}</Link>
                    <Link
                        to={`/instances/${instanceId}/${statementType.isGroup ? 'activity/groupedstatements' : 'activity'}`}>{statementType.isGroup ? 'Grouped Activity' : 'Activity'}</Link>

                    <ConditionalRender if={batchStatementId}>
                        <Link
                            to={`/instances/${instanceId}/activity/${statementType.isGroup ? 'groupedbatch' : 'batch'}/${batchId}`}>{statementType.isGroup ? 'Gr Batch' : 'Batch '} {((batchId && batchId.length > 50) ? `${batchId?.substring(0, 50)}...` : batchId)}</Link>
                        <NavLink to={`/instances/`}
                                 className="current-breadcrumb-link pe-none">{statementType.isGroup ? 'Gr Statement ' : 'Statement '}{batchStatementId}</NavLink>
                    </ConditionalRender>

                    <ConditionalRender if={!batchStatementId}>
                        <NavLink to={`/instances/`}
                                 className="current-breadcrumb-link pe-none">{statementType.breadcrumb} {statementId || batchId}</NavLink>
                    </ConditionalRender>
                    <PeriodBreadcrumb/>
                </Breadcrumb>

                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={() => {
                }}
                               applyPeriod={props.applyPeriod}/>
            </div>


            <div className="loader"/>
            <TabbedMenu>
                <TimeShift/>

                <Switch>
                    {/*Statement and Plans*/}
                    <Route path="/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/activity/batch/${batchId}/statement/${batchStatementId}/text`}
                                                  activeClassName="active">{instance.type !== INSTANCE_TYPE_SQLSERVER || statementType.isBatch ? 'Statement and Plans' : 'Statement'}</NavLink>}/>
                    <Route
                        path="/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId"
                        render={() => <NavLink exact
                                               to={`/instances/${instanceId}/activity/groupedbatch/${batchId}/groupedstatement/${batchStatementId}/text`}
                                               activeClassName="active">Statements and Plans</NavLink>}/>

                    {['statement', 'groupedstatement', 'batch', 'groupedbatch'].map((type) => (
                        <Route
                            key={type}
                            path={`/instances/:instanceId/activity/${type}/:id`}
                            render={({match}) => {
                                return (
                                    <NavLink
                                        exact
                                        to={`/instances/${match.params.instanceId}/activity/${type}/${match.params.id}/text`}
                                        activeClassName="active"
                                    >
                                        {getPlanAndTextTabName(type, instance).plans}
                                    </NavLink>
                                );
                            }}
                        />
                    ))}
                </Switch>

                {/*Activity*/}
                <Switch>
                    <Route path="/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/activity/batch/${batchId}/statement/${batchStatementId}`}
                                                  activeClassName="active">Statement Activity</NavLink>}/>
                    <Route
                        path="/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId"
                        render={() => <NavLink exact
                                               to={`/instances/${instanceId}/activity/groupedbatch/${batchId}/groupedstatement/${batchStatementId}`}
                                               activeClassName="active">Grouped Statement Activity</NavLink>}/>
                    {['statement', 'groupedstatement', 'batch', 'groupedbatch'].map((type) => (
                        <Route
                            key={type}
                            path={`/instances/:instanceId/activity/${type}/:id`}
                            render={({match}) => {
                                return (
                                    <NavLink
                                        exact
                                        to={`/instances/${match.params.instanceId}/activity/${type}/${match.params.id}`}
                                        activeClassName="active"
                                    >
                                        {getPlanAndTextTabName(type, instance).activity}
                                    </NavLink>
                                );
                            }}
                        />
                    ))}
                </Switch>

                <ConditionalRender if={shouldShowTab('sqlstatistics')}>
                    <Switch>

                        <Route path="/instances/:instanceId/activity/batch/:batchid/statement/:batchStatementId"
                               render={() => <NavLink exact
                                                      to={`/instances/${instanceId}/activity/batch/${batchId}/statement/${batchStatementId}/sql-statistics`}
                                                      activeClassName="active">SQL Statistics</NavLink>}/>


                        <Route
                            path="/instances/:instanceId/activity/groupedbatch/:batchId/groupedstatement/:batchStatementId"
                            render={() => <NavLink exact
                                                   to={`/instances/${instanceId}/activity/groupedbatch/${batchId}/groupedstatement/${batchStatementId}/sql-statistics`}
                                                   activeClassName="active">Grouped SQL Statistics</NavLink>}/>

                        <Route path="/instances/:instanceId/activity/statement/:statementId"
                               render={() => <NavLink exact
                                                      to={`/instances/${instanceId}/activity/statement/${statementId}/sql-statistics`}
                                                      activeClassName="active">SQL Statistics</NavLink>}/>

                        <Route path="/instances/:instanceId/activity/groupedstatement/:statementId"
                               render={() => <NavLink exact
                                                      to={`/instances/${instanceId}/activity/groupedstatement/${statementId}/sql-statistics`}
                                                      activeClassName="active">Grouped SQL Statistics</NavLink>}/>


                    </Switch>

                </ConditionalRender>
                <ConditionalRender if={instance?.type === INSTANCE_TYPE_SQLSERVER}>
                    <Route path="/instances/:instanceId/activity/statement/:statementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/activity/statement/${statementId}/batches`}
                                                  activeClassName="active">Calling Batches</NavLink>}/>


                    <Route path="/instances/:instanceId/activity/groupedstatement/:statementId"
                           render={() => <NavLink exact
                                                  to={`/instances/${instanceId}/activity/groupedstatement/${statementId}/batches`}
                                                  activeClassName="active">Calling Grouped Batches</NavLink>}/>

                    <ConditionalRender if={statementType.type === "groupedbatch"}>
                        <Route path="/instances/:instanceId/activity/groupedbatch/:statementId"
                               render={() => <NavLink exact
                                                      to={`/instances/${instanceId}/activity/groupedbatch/${statementId}/batches`}
                                                      activeClassName="active">Batches</NavLink>}/>
                    </ConditionalRender>


                    <Route
                        path="/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId"
                        render={() => <NavLink exact
                                               to={`/instances/${instanceId}/activity/batch/${batchId}/statement/${batchStatementId}/batches`}
                                               activeClassName="active">Calling Batches</NavLink>}/>

                    <ConditionalRender if={statementType.type === "groupedstatement"}>

                        <Route
                            path="/instances/:instanceId/activity/groupedbatch/:batchId/groupedstatement/:batchStatementId"
                            render={() => <NavLink exact
                                                   to={`/instances/${instanceId}/activity/groupedbatch/${batchId}/groupedstatement/${batchStatementId}/batches`}
                                                   activeClassName="active">Calling Grouped Batches</NavLink>}/>
                    </ConditionalRender>

                </ConditionalRender>

            </TabbedMenu>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions &&
                    <FilterBar period={props.period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            <Switch>
                <Route
                    exact
                    path={[
                        "/instances/:instanceId/activity/statement/:statementId/text",
                        "/instances/:instanceId/activity/groupedstatement/:statementId/text",
                        "/instances/:instanceId/activity/batch/:batchId/text",
                        "/instances/:instanceId/activity/groupedbatch/:batchId/text",
                        "/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId/text",
                        "/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId/text",
                    ]}
                    render={() => <TextAndPlan
                        setExecutionPlanOptions={setExecutionPlanOptions}
                        batchId={batchId}
                        batchStatementId={batchStatementId}
                        instance={instance}
                        statementId={statementId}
                    />}
                />

                <Route
                    exact
                    path={[
                        "/instances/:instanceId/activity/statement/:statementId",
                        "/instances/:instanceId/activity/groupedstatement/:statementId",
                        "/instances/:instanceId/activity/batch/:batchId",
                        "/instances/:instanceId/activity/groupedbatch/:batchId",
                        "/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId",
                        "/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId",
                    ]}
                    render={() => <StatementActivity eventTypes={props.eventTypes}
                                                     instance={instance}
                                                     batchId={batchId}
                                                     batchStatementId={batchStatementId}
                                                     period={period}
                                                     applyPeriod={props.applyPeriod}
                                                     chartPlanPlotLines={chartPlanPlotLines}
                                                     statementId={statementId}
                                                     setFilterOptions={props.setFilterOptions}
                                                     filterParameters={period.filters.parameters}
                        // chartPlanPlotLines={this.state.chartPlanPlotLines}
                        // statementTimePrevious={this.state.statementTimePrevious}/>}
                    />}
                />

                <Route exact path={[
                    "/instances/:instanceId/activity/statement/:statementId/sql-statistics",
                    "/instances/:instanceId/activity/groupedstatement/:statementId/sql-statistics",
                    "/instances/:instanceId/activity/batch/:batchId/sql-statistics",
                    "/instances/:instanceId/activity/groupedbatch/:batchId/sql-statistics",
                    "/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId/sql-statistics",
                    "/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId/sql-statistics",
                ]}
                       render={() => <SqlStatistics instance={instance}
                                                    filterParameters={period.filters.parameters}
                                                    applyPeriod={props.applyPeriod}
                                                    batchStatementId={batchStatementId}
                                                    statementId={statementId || '0'}
                       />}/>


                <Route exact path="/instances/:instanceId/activity/statement/:statementId/batches"
                       render={() => <Batches filtersParameters={period.filters.parameters}
                                              instanceId={instance.id}
                                              statementId={statementId} showByDefault={true}
                                              isBatchesForStatement={true}
                       />}/>

                <Route exact path="/instances/:instanceId/activity/groupedbatch/:batchId/batches"
                       render={() => <Batches filtersParameters={period.filters.parameters}
                                              instanceId={instance.id}
                                              statementId={statementId} showByDefault={true}
                                              isBatchesForStatement={true}
                       />}/>

                <Route exact path="/instances/:instanceId/activity/groupedstatement/:statementId/batches"
                       render={() => <GroupedBatches filtersParameters={period.filters.parameters}
                                                     instanceId={instance.id} statementId={statementId}
                                                     showByDefault={true}
                                                     isBatchesForStatement={true}
                       />}/>

                <Route exact path="/instances/:instanceId/activity/batch/:batchId/statement/:batchStatementId/batches"
                       render={() => <Batches filtersParameters={period.filters.parameters}
                                              instanceId={instance.id}
                                              batchStatementId={batchStatementId}
                                              statementId={statementId} showByDefault={true}
                                              isBatchesForStatement={true}
                       />}/>

                <Route exact
                       path="/instances/:instanceId/activity/groupedbatch/:batchid/groupedstatement/:batchStatementId/batches"
                       render={() => <GroupedBatches filtersParameters={period.filters.parameters}
                                                     instanceId={instance.id} statementId={statementId}
                                                     batchStatementId={batchStatementId}
                                                     showByDefault={true}
                                                     isBatchesForStatement={true}
                       />}/>

            </Switch>
        </div>
    )
}

Statement.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Statement;