import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Helper.
import Helper from '../../helpers/Helper';
import ConditionalRender from '../../helpers/ConditionalRender';

// Types.
import HostTarget from '../../types/host/HostTarget';
import InstanceTarget from '../../types/instance/InstanceTarget';
import Licence from '../../types/Licence';

// Constants.
import { DATA_LOADED, LAST_PAGE_KEY, LICENCE_TIER } from '../Constants';

//style
import './style.scss'
import ReactTooltip from "react-tooltip";

const defaultSubMenusState = {
    analysis: true,
    reports: true,
    settings: true,
    help: false
}

function MenuSide(props: {
    hosts: HostTarget[],
    instances: InstanceTarget[],
    licences: Licence[],
    toggleMenu: Function,
    loading: number,
    mustLogIn: boolean
}) {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const soredMenuState = window.localStorage.getItem('menuState') || ''
    const [subMenu, setSubMenu] = useState<any>(soredMenuState ? JSON.parse(soredMenuState) : defaultSubMenusState);
    const [userName, setUserName] = useState<string>('');

    useEffect(() => {
        const savedToken = window.localStorage.getItem('dbmar-token')
        if (savedToken) {
            // Decode the Base64 encoded token
            const decodedString = atob(savedToken);
            // Split the decoded string at the colon
            const [userName] = decodedString.split(':');
            setUserName(userName)
        }
        ReactTooltip.rebuild();
    }, []);

    function toggleSubMenu(item: string) {
        const newState = { ...subMenu, [item]: !subMenu[item] }
        setSubMenu(newState)
        window.localStorage.setItem('menuState', JSON.stringify(newState))
    }

    function logOut() {
        window.localStorage.removeItem('dbmar-token')
        window.localStorage.removeItem(LAST_PAGE_KEY);
        window.location.reload();
    }

    function toggleMenu() {
        setIsCollapsed(!isCollapsed);
        props.toggleMenu();
    }

    const hasInstanceWithNewSqlStatistics = useMemo(() => {
        return !!props.instances.find(i => i.collectsqlstatistics)
    }, [props.instances])

    const licence = useMemo(() => {

        const licences: Licence[] = props.licences.filter(licence => licence.id === 0);
        if (props.loading === DATA_LOADED) {
            if (licences.length > 0) {

                switch (licences[0].settings?.tier) {
                    case LICENCE_TIER.STARTER:
                        return 'Starter';

                    case LICENCE_TIER.STANDARD:
                        return 'Standard';

                    case LICENCE_TIER.PREMIUM:
                        return 'Premium';

                    default:
                        console.error('Failed to find matching licence type.');
                        break;
                }

            } else {

                console.error('Failed to find base licence.');
            }
        }


    }, [props.licences, props.loading]);

    const getTooltip = (value: string) => {
        return isCollapsed ? value : ''
    }

    return (
        <nav id="menu-side">
            <i id="menu-arrow" data-tip={!isCollapsed ? 'Collapse' : 'Expand'} onClick={toggleMenu}
               className={isCollapsed ? 'fal  fa-fw  fa-angle-right' : 'fal  fa-fw  fa-angle-left'}
               aria-hidden="true"/>
            <ul className="list-group">
                <li className="nav-item">
                    <NavLink exact to={`/instances`}>
                        <img src="/icons/logo.svg" alt="DBmarlin" className="logo-large" width="200px" height="70px"/>
                        <img src="/icons/logo-small.svg" alt="DBmarlin" className="logo-small" width="40px"
                             height="70px"/>
                    </NavLink>
                    <a href="https://www.dbmarlin.com/pricing" className="licence-type" rel="noopener noreferrer"
                       target="_blank">{licence}</a>
                </li>

                <li id='upgrade-button' style={{ display: isCollapsed ? 'none' : 'block' }}>
                    {licence === "Starter" && (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <a href="https://www.dbmarlin.com/pricing-calculator" target="_blank"
                               rel="noopener noreferrer" style={{ display: 'inline-block' }}>
                                <button className="btn btn-sm btn-primary"
                                        style={{
                                            width: '100%',
                                            padding: '2px 12px',
                                            backgroundColor: '#073363',
                                            color: 'white',
                                            borderRadius: '15px'
                                        }}>
                                    <i className="fas fa-sparkles"></i> UPGRADE
                                </button>
                            </a>
                        </div>
                    )}
                </li>


            </ul>

            <div className="menu-scroll">
                {/*<ul className="list-group">*/}
                {/*    <li className="nav-item" onClick={toggleMenu}>*/}
                {/*        <div className="nav-link d-xs-block d-sm-block d-md-block d-lg-none" role="button">*/}
                {/*            <i className="fal fa-chevron-double-left fa-fw" aria-hidden="true"/><span>Hide Menu</span>*/}
                {/*        </div>*/}
                {/*        <div className="nav-link d-none d-lg-block" role="button">*/}
                {/*            <i className={((menuClass === 'collapsed') ? 'fal fa-chevron-double-right fa-fw' : 'fal fa-chevron-double-left fa-fw')}*/}
                {/*               aria-hidden="true"/><span>{((menuClass === 'collapsed') ? '' : 'Hide Menu')}</span>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*<hr/>*/}
                {/*<ul className="list-group d-none">*/}
                {/*    <li className="nav-item">*/}
                {/*        <NavLink exact to={`/`} className="nav-link" activeClassName="active"><i*/}
                {/*            className="fal fa-tachometer-fast fa-fw"*/}
                {/*            aria-hidden="true"/><span>Dashboard</span></NavLink>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*<hr />*/}
                <div onClick={() => toggleSubMenu('analysis')} className='heading'>
                    <span className="nav-link basic expandable">Analysis<i
                        className={`fal fa-solid ${subMenu.analysis ? 'fa-minus' : 'fa-plus'}`}/></span>
                </div>
                <ul className={`list-group ${subMenu.analysis ? 'show' : 'hide'}`}>
                    <li className="nav-item">
                        <NavLink to={`/instances`} className="nav-link" activeClassName="active"><i
                            className="fal fa-database fa-fw" data-tip={getTooltip('Database Instances')}
                            aria-hidden="true"/><span>Database Instances</span>
                            <span className="badge bg-info"
                                  data-tip="Total instance count">{Helper.getFormattedBadgeCount(props.instances.length)}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`/hosts`} className="nav-link" activeClassName="active"><i
                            className="fal fa-server fa-fw" data-tip={getTooltip('Hosts')}
                            aria-hidden="true"/><span>Hosts</span>
                            <span className="badge bg-info"
                                  data-tip="Total host count">{Helper.getFormattedBadgeCount(props.hosts.length)}</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to={`/event-history`} className="nav-link" activeClassName="active"><i
                            className="fal fa-exchange fa-fw" data-tip={getTooltip('Event History')}
                            aria-hidden="true"/><span>Event History</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to={`/sql-search`} className="nav-link" activeClassName="active"><i
                            className="fal fa-search fa-fw" data-tip={getTooltip('SQL Search')}
                            aria-hidden="true"/><span>SQL Search</span></NavLink>
                    </li>
                    {/*<li className="nav-item d-none">*/}
                    {/*    <NavLink exact to={`/insights`} className="nav-link" activeClassName="active"><i*/}
                    {/*        className="fal fa-lightbulb fa-fw" aria-hidden="true"/><span data-tip={getTooltip('Insights')}>Insights</span></NavLink>*/}
                    {/*</li>*/}
                </ul>
                <hr/>
                <div onClick={() => toggleSubMenu('reports')} className='heading'>
                    <span className="nav-link basic expandable">Reports<i
                        className={`fal fa-solid ${subMenu.reports ? 'fa-minus' : 'fa-plus'}`}/></span>
                </div>
                <ul className={`list-group ${subMenu.reports ? 'show' : 'hide'}`}>
                    <li className="nav-item">
                        <NavLink to={`/reports/time-comparison`} className="nav-link"
                                 data-tip={getTooltip('Time Comparison')} activeClassName="active"><i
                            className="fal fa-stopwatch fa-fw"
                            aria-hidden="true"/><span>Time Comparison</span></NavLink>
                        <ConditionalRender if={hasInstanceWithNewSqlStatistics}>
                            <NavLink to={`/reports/sql-stats-explorer`} className="nav-link"
                                     data-tip={getTooltip('SQL Stats Explorer')} activeClassName="active"><i
                                className="fal fa-chart-bar fa-fw"
                                aria-hidden="true"/><span>SQL Stats Explorer</span></NavLink>
                            <NavLink to={`/reports/sql-stats-comparison`} className="nav-link"
                                     data-tip={getTooltip('SQL Stats Comparison')} activeClassName="active"><i
                                className="fal fa-chart-bar fa-fw"
                                aria-hidden="true"/><span>SQL Stats Comparison</span></NavLink>
                        </ConditionalRender>
                    </li>
                </ul>
                <hr/>
                <div onClick={() => toggleSubMenu('settings')} className='heading'>
                    <span className="nav-link basic expandable">Settings<i
                        className={`fal fa-solid ${subMenu.settings ? 'fa-minus' : 'fa-plus'}`}/></span>
                </div>
                <ul className={`list-group ${subMenu.settings ? 'show' : 'hide'}`}>
                    <li className="nav-item">
                        <NavLink to="/admin/instances" className="nav-link" data-tip={getTooltip('Database Instances')} activeClassName="active"><i
                            className="fal fa-database fa-fw"
                            aria-hidden="true"/><span>Database Instances</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/hosts" className="nav-link" data-tip={getTooltip('Hosts')} activeClassName="active"><i
                            className="fal fa-server fa-fw" aria-hidden="true"/><span>Hosts</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/database-extended-statistics" className="nav-link" data-tip={getTooltip('SQL Statistics')} activeClassName="active"><i
                            className="fal fa-chart-bar fa-fw"
                            aria-hidden="true"/><span>SQL Statistics</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/alert-rules" className="nav-link" data-tip={getTooltip('Alert Rules')} activeClassName="active"><i
                            className="fal fa-exclamation-triangle fa-fw"
                            aria-hidden="true"/><span>Alert Rules</span></NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/admin/alert-actions" className="nav-link" data-tip={getTooltip('Alert Actions')} activeClassName="active"><i*/}
                    {/*        className="fal fa-location-circle fa-fw"*/}
                    {/*        aria-hidden="true"/><span>Alert Actions</span></NavLink>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <NavLink to="/admin/filters" className="nav-link" data-tip={getTooltip('Snapshots')} activeClassName="active"><i
                            className="fal fa-camera-alt fa-fw" aria-hidden="true"/><span>Snapshots</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/integrations/" className="nav-link" data-tip={getTooltip('Integrations')} activeClassName="active"><i
                            className="fal fa-plug fa-fw" aria-hidden="true"/><span>Integrations</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/event-types" className="nav-link" data-tip={getTooltip('Event Types')} activeClassName="active"><i
                            className="fal fa-object-ungroup fa-fw"
                            aria-hidden="true"/><span>Event Types</span></NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/admin/licences" className="nav-link" data-tip={getTooltip('Licences')} activeClassName="active"><i
                            className="fal fa-file-certificate fa-fw"
                            aria-hidden="true"/><span>Licences</span></NavLink>
                    </li>
                </ul>
                <hr/>
                <div onClick={() => toggleSubMenu('help')} className='heading'>
                    <span className="nav-link basic expandable">Help<i
                        className={`fal fa-solid ${subMenu.help ? 'fa-minus' : 'fa-plus'}`}/></span>
                </div>
                <ul className={`list-group ${subMenu.help ? 'show' : 'hide'}`}>
                    <li className="nav-item">
                        <a href="https://docs.dbmarlin.com/docs/DBmarlin/introduction" data-tip={getTooltip('Documentation')} className="nav-link"
                           rel="noopener noreferrer" target="_blank"><i className="fal fa-book-reader fa-fw"
                                                                        aria-hidden="true"/><span>Documentation</span></a>
                    </li>
                    <li className="nav-item">
                        <a href="https://community.dbmarlin.com/invitation?code=74020A" className="nav-link" data-tip={getTooltip('Community')}
                           rel="noopener noreferrer"
                           target="_blank"><i className="fal fa-users fa-fw"
                                              aria-hidden="true"/><span>Community</span></a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.dbmarlin.com/support" className="nav-link" data-tip={getTooltip('Support')} rel="noopener noreferrer"
                           target="_blank"><i className="fal fa-life-ring fa-fw"
                                              aria-hidden="true"/><span>Support</span></a>
                    </li>
                    <li className="nav-item">
                        <a href="https://feedback.dbmarlin.com/t/voting" data-tip={getTooltip('Feedback')} className="nav-link" rel="noopener noreferrer"
                           target="_blank"><i className="fal fa-comment-dots fa-fw"
                                              aria-hidden="true"/><span>Feedback</span></a>
                    </li>
                    <li className="nav-item">
                        <a href="https://feedback.dbmarlin.com/t/roadmap" className="nav-link" rel="noopener noreferrer"  data-tip={getTooltip('Roadmap')}
                           target="_blank"><i className="fal fa-road fa-fw" aria-hidden="true"/><span>Roadmap</span></a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.dbmarlin.com/latest-release-notes" className="nav-link" data-tip={getTooltip('Release Notes')}
                           rel="noopener noreferrer"
                           target="_blank"><i className="fal fa-file-alt fa-fw"
                                              aria-hidden="true"/><span>Release Notes</span></a>
                    </li>
                </ul>
                <hr/>
                <div onClick={() => toggleSubMenu('logout')} className='heading'>
                    <span className="nav-link basic expandable">Logout<i
                        className={`fal fa-solid ${subMenu.logout ? 'fa-minus' : 'fa-plus'}`}/></span>
                </div>
                <ul className={`list-group ${subMenu.logout ? 'show' : 'hide'}`}>
                    <li>
                        <ConditionalRender if={props.mustLogIn}>
                            <li>
                                <a onClick={() => logOut()} data-tip={getTooltip('Log Out')} className="nav-link basic pointer"><i
                                    className={`fa fa-sign-out`}/><span>Log Out ({userName})</span></a>
                            </li>
                        </ConditionalRender>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

MenuSide.propTypes = {
    toggleMenu: PropTypes.func.isRequired
};

export default MenuSide;

