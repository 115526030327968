import { Link, useHistory } from 'react-router-dom';
import { useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";

// Types.cd w
import IntegrationType from "../../../../types/integration/IntegrationType";

// Constants.
import {
    ACTIONS,
    generalErrorMessage, integrationAttributesArray,
} from '../../../../component/Constants';

//Utils
import { findIntegrationValue, getAction, getValidClass } from "../../../../helpers/utils";
import Action from "../../../../types/Action";
import api from "../../../../api/Base";
import Alert from "../../../../component/Alert";

function Detail(props: { actionId: string, slack: IntegrationType[], refresh: Function }) {
    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const history = useHistory();
    const isDelete = action.type === ACTIONS.DELETE.type;
    const {
        register,
        trigger,
        handleSubmit,
        getValues,
        setValue,
        control,
        formState: {errors}
    } = useForm<IntegrationType>({
        mode: "onBlur",
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    });

    useEffect(() => {
        getAction(props.actionId, setAction, setDisabled);
    }, [props.actionId]);

    const [disabled, setDisabled] = useState(isDelete);

    const parseEditResponse = (data: any) => {
        if (data.length) {
            // @ts-ignore
            integrationAttributesArray.forEach(attr => setValue(attr, findIntegrationValue(attr, data, 'slack')))
            const dbmarurl = data.find((item: { name: string; }) => item?.name === 'slack.dbmarlin.url')
            setValue('dbmarurl', dbmarurl.value)
            void trigger()
        }
    }

    useEffect(() => {
        parseEditResponse(props.slack)
    }, [props.slack]);

    const {dirtyFields} = useFormState({
        control
    })


    const onSubmit = async(data: IntegrationType) => {
        setDisabled(true)
        const updatedValues: { name: string; value: any; }[] = []
        let result: any;
        let url = `/parameter`;

        Object.keys(data).forEach(item => {
            let name = (item === 'dbmarurl') ? `slack.dbmarlin.url` : `slack.${item}`
            // @ts-ignore
            const value = data[item] === true ? 'true' : data[item] === false ? 'false' : data[item]
            if (action.type === 'create' || action.type === 'delete') {
                updatedValues.push({name, value})
                // @ts-ignore
            } else if (dirtyFields[item]) {
                updatedValues.push({name, value})
            }
        })
        result = action.type === 'delete' ? await api.delete(url, {data: updatedValues}) : await api.post(url, updatedValues)

        if (result.status === 200) {
            history.push('/admin/integrations/slack')
            props.refresh()
        }
    }

    const isValid = Object.keys(errors).length === 0

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fak fa-slack fa-fw" aria-hidden="true"/>
                    {action.title} Slack Integration
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {!isValid && (
                            <Alert message={generalErrorMessage.text} heading={generalErrorMessage.title}
                                   variant="alert-danger"/>
                        )}

                        <p>Please complete the required details below:</p>

                        <div className="row g-0 form">

                            {/* URL */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="url" className="base-url">URL</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="url"
                                    maxLength={250}
                                    {...register('url', {
                                        required: "Please fill in this field",
                                        pattern: {
                                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                                            message: "Invalid Url"
                                        }
                                    })}
                                    className={`form-control ${getValidClass(errors.url, getValues('url'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.url?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.url?.message}</small>

                            </div>
                            <div className="w-100"/>

                            {/* DBmarlin URL */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="url" className="base-url">DBmarlin URL</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="dbmarurl"
                                    maxLength={250}
                                    {...register('dbmarurl', {
                                        required: "Please fill in this field",
                                        pattern: {
                                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                                            message: "Invalid Url"
                                        }
                                    })}
                                    className={`form-control ${getValidClass(errors.dbmarurl, getValues('dbmarurl'), isDelete)}`}
                                    defaultValue=''
                                    disabled={disabled}
                                    title={errors.dbmarurl?.message}
                                    data-lpignore={true}
                                />
                                <small className="text-danger">{errors.dbmarurl?.message}</small>
                            </div>
                            <div className="w-100"/>

                            {/* Enabled */}
                            <div className="col-xs-12 col-md-3">
                                <label className="auth">Enabled</label>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="enable"
                                        {...register("enable")}
                                        disabled={disabled}
                                        className="form-check-input"
                                        // onChange={() => setValue('enable', !getValues('enable'))}
                                        defaultChecked={true}
                                        data-lpignore={true}
                                    />
                                </div>
                            </div>
                            <div className="w-100"/>


                            {/* Actions */}
                            <div className="col-12 actions">
                                <Link to="/admin/integrations/slack" role="button" className="btn btn-secondary"><i
                                    className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span></Link>
                                <button type="submit" className={`btn btn-${action.variant}`}><i
                                    className={`${action.icon} fa-sm`}/><span>{action.title}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Detail;