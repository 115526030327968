function Insights() {

    return (
        <div className="row row-cols-1">    
            <div className="col">
                <div className="card collapsible">
                    <div className="card-header">
                        <i className="fal fa-lightbulb fa-fw" aria-hidden="true"></i>
                        Insights
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseInsights" aria-expanded="false" aria-controls="collapseInsights"></i>
                    </div>
                    <div id="collapseInsights" className="card-body collapse show">
                        <div className="table-responsive">
                            <p>
                                Any future insights will go here...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Insights;