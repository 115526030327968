import React, { useContext, useMemo, useState } from 'react';

// Third-parties.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import HostTarget from '../../../types/host/HostTarget';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartSeries from '../../../types/ChartSeries';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED, CHART_DATA_TYPE } from '../../Constants';
import { CHART_COLOURS_STATEMENTS } from '../../Constants';
import { VIEW_MODE } from '../../Constants';
import { useLicences } from "../../../context/LicenceContext";
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import ConditionalRender from "../../../helpers/ConditionalRender";
import api from "../../../api/Base";

function ProcessorChart(props: { host: HostTarget, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], applyPeriod: Function }) {
    const [loadingBasic, setLoadingBasic] = useState<number>(DATA_LOADING);
    const [loadingDetail, setLoadingDetail] = useState<number>(DATA_INITIALISING);

    const [timeSeries, setTimeSeries] = useState<number[][]>([]);
    const [series, setSeries] = useState<ChartSeries[]>([]);
    const [mode, setMode] = useState<number>(VIEW_MODE.BASIC);

    const timeRangeContext = useContext(TimeRangeContext)
    const {licences} = useLicences();

    const timePeak = useMemo(() => {
        return Helper.getPeakValue(timeSeries.map(i => i[1]))
    }, [timeSeries])

    void useMemo(async() => {
        api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&statistic=cpuutilisation&id=${props.host.id}`)
            .then((response: { data: HostTimeStatistic[]; }) => {
                setTimeSeries(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.avg]
                }));
                setLoadingBasic(DATA_LOADED);
            })
            .catch((error: any) => {
                console.error('Failed to retrieve activity time.', error);
            })
    }, [timeRangeContext, props.host, props.plotLines]);

    async function getData() {

        // Set the metrics we're going to retrieve via API calls.
        const metrics: string[] = ['non-nice user cpu ticks', 'nice user cpu ticks', 'system cpu ticks', 'IO-wait cpu ticks', 'IRQ cpu ticks', 'softirq cpu ticks', 'stolen cpu ticks'];

        let seriesData: any[] = [];
        // Get detailed data.
        for (let index = 0; index < metrics.length; index++) {

            // Prep the metric key for the API call.
            const metric = metrics[index].replace(' ', '%20');
            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&statistic=${metric}&id=${props.host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    seriesData.push({
                        name: metrics[index],
                        // Warning: if updating, make sure the index doesn't exceed the available colour array.
                        color: CHART_COLOURS_STATEMENTS[index],
                        type: 'column',
                        data: response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.avg]
                        }),
                        zIndex: 1

                    });

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor metric over time for host', error, props.host, metrics[index]);
                })
        }
        setSeries(seriesData)
        // Mark the content as loaded.
        setLoadingDetail(DATA_LOADED);
    }

    const basicChart = useMemo(() => {
        const basicChartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                name: 'Average CPU',
                type: 'spline',
                minHeight: 3,
                data: timeSeries,
                zIndex: 1,
                yAxis: 1
            }], timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            timeRangeContext: timeRangeContext,
            // plotLines: props.plotLines,
            // plotBands: props.plotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
                }
            },
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={basicChartOptions}/>;

    }, [timeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    const detailChart = useMemo(() => {

        const detailChartOptions = chartOptionsGranularity(props.applyPeriod,
            series, 100, {
                credits: {enabled: highchartsCredits(licences)},
                timeRangeContext: timeRangeContext,
                // plotLines: props.plotLines,
                // plotBands: props.plotBands,
                legend: {
                    align: 'left',
                    backgroundColor: 'rgba(255,255,255,0.25)',
                    enabled: false,
                    floating: false,
                    itemMarginTop: 0,
                    itemMarginBottom: 2,
                    itemStyle: {
                        color: '#212529',
                        fontSize: '.7rem'
                    },
                    layout: 'horizontal',
                    padding: 0,
                    x: 0,
                    y: 0,
                    verticalAlign: 'top'
                },
                tooltip: {
                    formatter: function () {
                        return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
                    }
                },
            })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={detailChartOptions}/>;

    }, [series, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    function toggleChart() {
        if (loadingDetail === DATA_INITIALISING) {
            setLoadingDetail(DATA_LOADING)
            getData();
        }

        // Toggle the displayed chart.
        setMode(((mode === VIEW_MODE.BASIC) ? VIEW_MODE.DETAIL : VIEW_MODE.BASIC));
    }

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                Average CPU over time
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseProcessorCharts" aria-expanded="false"
                   aria-controls="collapseProcessorCharts"/>
                <div className="btn-group float-end">
                    <button type="button" className="btn btn-xsm btn-primary float-end ms-1"
                            onClick={toggleChart}>{((mode === VIEW_MODE.BASIC) ? 'Show Breakdown' : 'Show Summary')}</button>
                </div>
            </div>
            <div id="collapseProcessorCharts" className="card-body collapse show p-0">
                <div className="row row-cols-1">
                    <div className="col p-3">
                        <React.Fragment>
                            <h4 className='icons-chart-title'>
                                Average CPU
                                {timePeak !== null && (
                                    <span className="peak">Peak Average CPU {Math.round(timePeak)}%</span>
                                )}
                            </h4>

                            <ConditionalRender if={mode === VIEW_MODE.BASIC}>
                                <ConditionalRender if={loadingBasic === DATA_LOADING}>
                                    <div className="w-100 text-center text-muted mt-3">
                                        <div className="loader spinner chartSpinner">
                                        </div>
                                        <p className="mt-3">
                                            Loading data...
                                        </p>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender if={loadingBasic === DATA_LOADED}>
                                    {basicChart}
                                </ConditionalRender>
                            </ConditionalRender>


                            <ConditionalRender if={mode === VIEW_MODE.DETAIL}>
                                <ConditionalRender if={loadingDetail === DATA_LOADING}>
                                    <div className="w-100 text-center text-muted mt-3">
                                        <div className="loader spinner chartSpinner">
                                        </div>
                                        <p className="mt-3">
                                            Loading data...
                                        </p>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender if={loadingDetail === DATA_LOADED}>
                                    {detailChart}
                                </ConditionalRender>
                            </ConditionalRender>

                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessorChart;
