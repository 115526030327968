// React.
import { useContext, useEffect, useState } from 'react';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { Deadlock } from '../../../types/instance/Deadlock';

// Components.
import DeadlocksTable from '../../../component/instance/table/DeadlocksTable';

// Helper.
import { archiverUrl, fetchResults, fetchWithAuthorization } from '../../../helpers/utils';
import FilterOption from "../../../types/instance/FilterOption";


import { TimeRangeContext } from "../../../context/TimeRangeContext";

export default function Deadlocks(props: { instance: InstanceTarget, filterOptions: FilterOption[], setFilterOptions: Function }) {
    const {instance} = props;
    const timeRangeContext = useContext(TimeRangeContext)

    const [deadlocks, setDeadlocks] =
        useState<Deadlock[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function load() {
            try {
                const instanceType = instance.type || ''
                setLoading(true);
                const results: any[][] = await fetchResults(
                    [
                        fetchWithAuthorization(archiverUrl() + `/deadlock/${instanceType}?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}`)
                    ]);

                if (active) {
                    setDeadlocks(results[0]);
                    setLoading(false);
                }
            } catch (x: any) {
                console.log(x.message);
                setError(x.message);
                setDeadlocks([]);
                setLoading(false);
            }
        }

        let active: boolean = true;
        load();
        return () => {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [timeRangeContext, instance.id]);

    return (<>
        <div className="row row-cols-1">
            <div id="statements" className="tab-pane fade show active" role="tabpanel" aria-labelledby="statements-tab">
                <DeadlocksTable setFilterOptions={props.setFilterOptions} instanceType={instance.type} filterOptions={props.filterOptions}
                                loading={loading} error={error} deadlocks={deadlocks}/>
            </div>
        </div>
    </>)
}
