// React.
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-parties.
import * as dayjs from 'dayjs';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { CSVLink } from 'react-csv';

// Types.
import { Deadlock } from '../../../types/instance/Deadlock';

// Components.
import Alert from "../../Alert";
import NoData from "../../NoData";
import TablePagination from "../../TablePagination";
import { TimeRangeContext } from '../../../context/TimeRangeContext';

// Helper.
import { getDefaultPageSize } from '../../../helpers/utils';
import Helper from "../../../helpers/Helper";
import { FilterType } from "../../../typescript/Enums";
import FilterOption from "../../../types/instance/FilterOption";
import SortClass from "../../../component/SortClass";

export default function DeadlocksTable(props: {
    loading: boolean,
    instanceType: string,
    error: string | null, deadlocks: Deadlock[], filterOptions: FilterOption[], setFilterOptions: Function
}) {
    const {loading, error, deadlocks} = props;
    const [tableFilter, setTableFilter] = useState<string>('');
    const timeRangeContext = useContext(TimeRangeContext);

    // CSV headers
    let headers: any[] = useMemo(() =>
            props.instanceType !== 'oracle' ? [
                {
                    label: 'Time',
                    key: 'timeslice'
                },
                {
                    label: 'Victim Session',
                    key: 'victimsession'
                },
                {
                    label: 'Other Session',
                    key: 'othersession'
                },
                {
                    label: 'Raw Data',
                    key: 'rawdata'
                }
            ] : [
                {
                    label: 'Time',
                    key: 'timeslice'
                },
                {
                    label: 'Record Id',
                    key: 'recordid'
                },
                {
                    label: 'Module Id',
                    key: 'moduleid'
                },
                {
                    label: 'Process Id',
                    key: 'processid'
                },
                {
                    label: 'Message',
                    key: 'messagetext'
                }
            ],
        []);

    const getEmptySessionFilters = () => {
        const newFilter = props.filterOptions
        newFilter.forEach(item => {
            if (item.filterType === FilterType.Sessions) {
                item.filters = [];
            }
        });
        return newFilter
    }


    // Table columns
    const columns: Column[] = useMemo(() => {
            function getActivityUrl(id: number, session: string): string {
                return '/instances/' + id + '/activity?' +
                    timeRangeContext.getTimeRangeRedirectString() + `${session ? `&sessions=${session}` : ''}`
            }

            return props.instanceType !== 'oracle' ? [
                {
                    Header: 'Time',
                    accessor: 'timeslice',
                    headerClassName: 'text',
                    className: 'concatenate',
                    canSort: true,
                    sortDescFirst: false,
                    sortType: 'basic',
                    Cell: ({cell}) =>
                        dayjs.default(cell.value).format('YYYY-MM-DD HH:mm')
                },
                {
                    Header: 'Victim Session',
                    accessor: 'victimsession',
                    headerClassName: 'text',
                    className: 'concatenate',
                    canSort: true,
                    sortDescFirst: false,
                    sortType: 'basic',
                    Cell: ({cell, row}: any) => {
                        const sessions = cell.value ? cell.value.split(',') : []
                        return sessions.map((item: string, index: number) => <Link key={index}
                                                                                   onClick={() => props.setFilterOptions(Helper.updateFilterOptions(FilterType.Sessions, item, getEmptySessionFilters()))}
                                                                                   to={getActivityUrl(row.original.id, item)}>{item}{index < sessions.length - 1 ? ', ' : ''}</Link>
                        )
                    }
                },
                {
                    Header: 'Other Session',
                    accessor: 'survivorsession',
                    headerClassName: 'text',
                    className: 'concatenate',
                    canSort: true,
                    sortDescFirst: false,
                    sortType: 'basic',

                    Cell: ({cell, row}: any) => {
                        const sessions = cell.value ? cell.value.split(',') : []
                        if (sessions && sessions.length) {
                            return sessions.map((item: string, index: number) => {

                                    return <Link key={index}
                                                 onClick={() => props.setFilterOptions(Helper.updateFilterOptions(FilterType.Sessions, item, getEmptySessionFilters()))}
                                                 to={getActivityUrl(row.original.id, item)}>{item}{index < sessions.length - 1 ? ', ' : ''}</Link>
                                }
                            )
                        } else {
                            return <Link
                                onClick={() => props.setFilterOptions(Helper.updateFilterOptions(FilterType.Sessions, row.original.id, getEmptySessionFilters()))}
                                to={getActivityUrl(row.original.id, '')}>sessions</Link>
                        }
                    }

                },
                {
                    Header: 'Raw Data',
                    accessor: 'rawdata',
                    headerClassName: 'text',
                    className: 'concatenate',
                    canSort: false,
                    sortDescFirst: false,
                    sortType: 'basic',
                    Cell: ({row}: any) => {
                        return <CSVLink role="button" data={row.values.rawdata.replace(/"/g, "'")}
                                        enclosingCharacter={`"`}
                                        className="btn btn-xsm btn-primary"
                                        download={'Deadlock' +
                                            '-' + dayjs.default(row.original.timeslice)
                                                .format('YYYY-MM-DD-HH-mm') +
                                            '-' + row.original.id +
                                            '-' + row.original.victimsession +
                                            '.xml'}
                        >Download</CSVLink>
                    }

                }
            ] : [{
                Header: 'Time',
                accessor: 'timeslice',
                headerClassName: 'text',
                className: 'concatenate col-width-10',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
                Cell: ({cell}) =>
                    dayjs.default(cell.value).format('YYYY-MM-DD HH:mm')
            }, {
                Header: 'Message',
                accessor: 'messagetext',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
            }, {
                Header: 'Module ID',
                accessor: 'moduleid',
                headerClassName: 'text',
                className: 'concatenate  col-width-10',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
            }, {
                Header: 'Process ID',
                accessor: 'processid',
                headerClassName: 'text',
                className: 'concatenate  col-width-10',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
            }, {
                Header: 'Record ID',
                accessor: 'recordid',
                headerClassName: 'text',
                className: 'concatenate  col-width-10',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
            }]
        },
        [timeRangeContext]);

    const data = useMemo(() => {
            const filteredData = deadlocks.filter((row) =>
                row.timeslice.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.victimsession?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.othersession?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.messagetext?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.moduleid?.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.processid?.toString().toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.recordid?.toString().toLowerCase().includes(tableFilter.toLowerCase()))
            return filteredData
        }, [deadlocks, tableFilter]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        pageOptions,
        pageCount,
        canPreviousPage,
        previousPage,
        canNextPage,
        nextPage,
        gotoPage,
        state: {pageIndex, pageSize}
    } = useTable(
        {
            columns,
            data,
            initialState:
                {
                    pageIndex: 0,
                    pageSize: getDefaultPageSize(),
                    sortBy:
                        [
                            {
                                id: 'timeslice',
                                desc: true
                            }
                        ]
                },
            disableSortRemove: true
        }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }

    return (
        <div className="card">
            <div className="card-header">
                <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                Deadlocks
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapseStatements" aria-expanded="false" aria-controls="collapseStatements"/>
            </div>
            <div id="collapseStatements" className="card-body collapse show">
                {Number(process.env.REACT_APP_API_LIMIT) - 1 === data.length - 1 &&
                    (<Alert
                        message={`To help preserve performance, we limit the total number of statement records below to the top ${Number(process.env.REACT_APP_API_LIMIT) - 1} in descending order by the number of executions.`}
                        heading="Statement records" variant="alert-info"/>)}
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 headers={headers}
                                 data={data}
                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - SQL Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table">
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={(column as any).headerClassName}>
                                        {column.render('Header')}
                                        <SortClass column={column}/>
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className={(cell.column as any).className}>
                                                {cell.render('Cell')}
                                            </td>)
                                    })}
                                </tr>)
                        })}
                        </tbody>
                    </table>
                    <NoData error={error} loading={loading}
                            length={data.length}/>
                    <TablePagination
                        length={data.length}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        canNextPage={canNextPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        pageIndex={pageIndex}
                    />
                </div>
            </div>
        </div>
    )
}
