import PropTypes from 'prop-types';

// Types.
import Period from '../../../types/Period';

// Components.
import UsersTable from '../table/UsersTable';

function Users(props: { instanceId: number, batchId?: string, statementId?: string, period: Period, totalInstanceTime: number, setUsersOptions: Function, setFilterOptions: Function }) {
    return (
        <div id="users" className="tab-pane fade" role="tabpanel" aria-labelledby="users-tab">
            <UsersTable period={props.period} instanceId={props.instanceId} batchId={props.batchId} statementId={props.statementId} instanceTime={props.totalInstanceTime} setUsersOptions={props.setUsersOptions} setFilterOptions={props.setFilterOptions} />
        </div>
    );
}

Users.propTypes = {
    setUsersOptions: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default Users;