// React.
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Third-parties.
import ReactTooltip from 'react-tooltip';

// Types.
import Period from '../../types/Period';

function RefreshButton(props: { period?: Period, refresh: Function, applyPeriod?: Function }) {
    const { period, refresh, applyPeriod } = props;
        
    function updatePeriod() {
        alert('Todo! We need to make the code in PeriodSelector global, and use that approach to reset/update the period.');
    }

	useEffect (() => { ReactTooltip.rebuild (); }, []);

    return (
        // Hidden for now as not working properly
        period && applyPeriod ? (
            <button type="button" className="btn btn-heading d-none" data-tip="Refresh this view and the displayed data" onClick={() => updatePeriod()}><i className="fal fa-redo fa-fw"></i></button>            
        ) : (
            <button type="button" className="btn btn-heading d-none" data-tip="Refresh this view and the displayed data" onClick={() => refresh()}><i className="fal fa-redo fa-fw"></i></button>
        )
    );
}

RefreshButton.propTypes = {
    refresh: PropTypes.func.isRequired
};

export default RefreshButton;
