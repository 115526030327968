import { useMemo } from "react";
import WidgetCard from "./index";

function LegacyChangesWidget(props: { changes: any[], events: any[], alerts?: any[], loading?: boolean }) {
    const valuesList = useMemo(() => {
        // if (props.alerts || props.alerts === 0) {
        //     events.push(
        //         {icon: 'fa-exclamation-triangle red-icon', value: props.alerts.toString(), tooltip: "Total alerts count"},
        //     )
        // }
        return [
            {
                icon: 'fa-exchange',
                value: props.changes.length,
                tooltip: "Total auto-detected change count"
            },
            { icon: 'fa-calendar', value: props.events.length, tooltip: "Total custom events count" },
            {
                icon: 'fa-exclamation-triangle red-icon',
                value: props.alerts?.length || 0,
                tooltip: "Total alerts count"
            },
        ]
    }, [props.changes, props.events, props.alerts])

    return (
        <WidgetCard values={valuesList} cardTooltipText={"The total event count"} name={"Events"}
                    iconClassName={"changes"} loading={!!props.loading}/>
    );
}

export default LegacyChangesWidget;