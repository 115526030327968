// Third-party packages.
import { TreeTableSelectionKeys } from 'primereact/treetable';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

// Types.
import FilterOption from '../types/instance/FilterOption';
import { FilterType } from '../typescript/Enums';
import Licence from '../types/Licence';

// Constants.
import { ACTIONS, EXPIRATION_DAYS, EXPIRATION_KEY, INSTANCE_TYPE_ORACLE, TOKEN_KEY } from '../component/Constants';
import * as sqlFormatter from "sql-formatter";

import { INSTANCE_TYPE_TO_QUERY_LANGUAGE } from "../constants/statements";
import { TimeRangeContextType } from "../context/TimeRangeContext";

// Extensions.
dayjs.extend(utc.default);
dayjs.extend(timezone.default);

// Functions.
export function archiverUrl(version = 1): string {
    // @ts-ignore
    const url: string | undefined = process.env.REACT_APP_API.replace('v1', `v${version}`)

    if (url) {
        if (url.endsWith('/')) {
            return url.substring(0, url.length - 1);
        }

        return url;
    }

    return '';
}

export function base64Decode(b: string) {
    return b ? atob(b) : b;
}

export function base64Encode(s: string) {
    // A null string gets converted to the base-64 equivalent of "null".
    return s ? btoa(s) : s;
}

function clearDropdownStyle(id: string): void {
    const element = document.getElementById(id);

    if (element) {
        element.classList.remove('show');
        element.setAttribute('aria-expanded', 'false');
    }
}

export function closeFilterDropdown(filterId: string, dropdownId: string): void {
    clearDropdownStyle(filterId);
    clearDropdownStyle(dropdownId);
}


export function defaultTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function dateWithTimeZone(date: string | null, tz: string | null): Dayjs {
    return date ? (tz ?
            dayjs.tz(date, tz) :
            dayjs.default(date)) :
        dayjs.default();
}

export function getParameter(parameters: string, name: string): string | null {
    return new URLSearchParams(parameters).get(name);
}

export function getParameterValues(parameters: string, name: string): string[] {
    return new URLSearchParams(parameters).getAll(name);
}

export function getFilterOptions(parameters: string): FilterOption[] {
    let filterOptions: FilterOption[] = [];
    filterOptions.push({
        filterType: FilterType.Waits,
        filters: getParameterValues(parameters, 'waitevent')
    });
    filterOptions.push({
        filterType: FilterType.Databases,
        filters: getParameterValues(parameters, 'database')
    });
    filterOptions.push({
        filterType: FilterType.Sessions,
        filters: getParameterValues(parameters, 'session')
    });
    filterOptions.push({
        filterType: FilterType.Clients,
        filters: getParameterValues(parameters, 'client')
    });
    filterOptions.push({
        filterType: FilterType.Users,
        filters: getParameterValues(parameters, 'username')
    });
    filterOptions.push({
        filterType: FilterType.Programs,
        filters: getParameterValues(parameters, 'program')
    });
    filterOptions.push({
        filterType: FilterType.Modules,
        filters: getParameterValues(parameters, 'module')
    });
    return filterOptions;
}

export function getFilterParameters(options: FilterOption[]): string {
    let parameters: string = '';
    let name: string | undefined = undefined;

    // Build the filter parameters for future API calls.
    options.forEach(option => {
        switch (option.filterType) {
            case FilterType.Waits:
                name = 'waitevent';
                break;

            case FilterType.Databases:
                name = 'database';
                break;

            case FilterType.Sessions:
                name = 'session';
                break;

            case FilterType.Clients:
                name = 'client';
                break;

            case FilterType.Users:
                name = 'username';
                break;

            case FilterType.Programs:
                name = 'program';
                break;

            case FilterType.Modules:
                name = 'module';
                break;

            default:
                name = undefined;
                break;
        }

        if (name) {
            option.filters.forEach(value => {
                parameters += `&${name}=${encodeURIComponent(value)}`;
            });
        }
    });

    return parameters
}

export const getSQStatisticsParameters = (options: string[]) => {
    let parameters: string = '';
    let value: string | null = null;
    const searchParams = new URLSearchParams(window.location.search)

    options.forEach(option => {
        value = searchParams.get(option)
        if (value) {
            parameters += `&${option}=${encodeURIComponent(value)}`;
        }
    });

    return parameters
}

export async function fetchResults(fetchArray: Promise<Response>[], throwError = true): Promise<any[]> {
    let errorIndex: number = -1;
    const responses: Response[] = await Promise.all(fetchArray);
    const promises: Promise<any>[] = await responses.map((response, index) => {
        if (!response.ok) {
            // response body is a promise at the moment that will be resolved
            // by the time console.log displays it. Unfortunately cannot throw
            // an exception with a promise as an argument.
            // console.log (response.text ());
            // throw new Error (/*response.text ()*/);

            if (errorIndex === -1) {
                errorIndex = index;
            }

            return response.text();
        }

        return response.json();
    });
    // console.log ('promises', promises);
    const results: any[] = await Promise.all(promises);
    // console.log ('results', results);

    if (errorIndex > -1 && throwError) {
        throw new Error(results[errorIndex]);
    }

    return results;
}

export function fetchWithAuthorization(url: string) {
    const savedToken = window.localStorage.getItem('dbmar-token') || '';
    return fetch(url, {
        headers: {
            'Authorization': `Basic ${savedToken}`
        }
    });
}

export async function fetchFilters() {
    const results: any[][] = await fetchResults(
        [
            fetchWithAuthorization(archiverUrl() + '/filter?sort=name')
        ]);
    // console.log (results);
    return results[0];
}

export async function sendData(method: string, uri: string, data: any):
    Promise<any[]> {
    const savedToken = window.localStorage.getItem('dbmar-token') || '';
    return await fetchResults(
        [
            fetch(archiverUrl() + uri,
                {
                    method: method,
                    body: JSON.stringify(data),
                    headers:
                        {
                            'Authorization': `Basic ${savedToken}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                })
        ]);
}

function shouldUseSchema(type: string): boolean {
    // React thinks useSchema is a hook, which was my first choice.
    return type === INSTANCE_TYPE_ORACLE;
}

export function DatabaseLabel(type: string): string {
    return shouldUseSchema(type) ? 'Schema' : 'Database';
}

export function DatabasesLabel(type: string): string {
    return shouldUseSchema(type) ? 'Schemas' : 'Databases';
}

export function databaseLabel(type: string): string {
    return shouldUseSchema(type) ? 'schema' : 'database';
}

export function databasesLabel(type: string): string {
    return shouldUseSchema(type) ? 'schemas' : 'databases';
}

export function dhms(milliseconds: number): string {
    // Convert milliseconds to days, hours, minutes and seconds.
    // Milliseconds must be positive to avoid rounding up -0.5.
    if (milliseconds === null) {
        return '-';
    }

    if (milliseconds <= 0) {
        return '0ms';
    }

    if (milliseconds < 1) {
        return milliseconds.toFixed(2) + 'ms';
    }

    if (milliseconds < 1000) {
        return Math.round(milliseconds) + 'ms';
    }

    const seconds: number = Math.round(milliseconds / 1000);
    const segments: number[] =
        [
            Math.floor(seconds / 86400),
            Math.floor(seconds / 3600) % 24,
            Math.floor(seconds / 60) % 60,
            seconds % 60
        ];
    const units: string[] = ['d ', 'h ', 'm ', 's'];
    let result: string = '';
    let i: number;

    // Skip leading zeros.
    for (i = 0; i < segments.length - 1; i++) {
        if (segments[i] > 0) break;
    }

    // Include the rest.
    for (/*i = i*/; i < segments.length; i++) {
        result += segments[i] + units[i];
    }

    return result;
}

export function formatNumber(n: number): string {
    return Math.round(n).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function formatSqlStatement(statement: string, instanceType = 'unknown') {
    let result: string = statement;
    if (statement.length > 0) {
        try {
            result = sqlFormatter.format(statement, { language: getQueryLanguageFromInstanceType(instanceType) });
        } catch (error: any) {
        }
    }
    return result;
}

export interface Step {
    key: string,
    data: any,
    children: Step[]
}

export function getStepDetails(steps: Step[],
                               selectedStepKey: TreeTableSelectionKeys): any[] {
    function findStep(steps: Step[]): Step | null {
        for (const step of steps) {
            if (step.key === selectedStepKey) {
                // console.log ('Found key ' + selectedStepKey);
                return step;
            } else {
                const child: Step | null = findStep(step.children)

                if (child) {
                    return child;
                }
            }
        }

        // console.log ('Did not find key ' + selectedStepKey);
        return null;
    }

    const details: any[] = [];
    const step: Step | null = findStep(steps);

    if (step) {
        for (const field in step.data) {
            details.push(
                {
                    name: field,
                    value: step.data[field]
                });
        }
    }

    return details;
}

export function getTime(isoDate: string, isoFormat = true): string | number {
    // Adjust for Highcharts adding the time zone offset.
    const t: any[] = isoDate.split(/[- :+]/);
    const date: Date = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    const time: number = date.getTime() - date.getTimezoneOffset() * 60000;

    return isoFormat ? time : dayjs.default(date.getTime()).format('YYYY/MM/DD HH:mm:ss');
}

export function timeSeries(tdata: any[], field1: string, field2: string, isoFormat = true): any[] {
    let result: any = [];

    for (let i = 0; i < tdata.length; i++) {
        try {
            result[i] = [getTime(tdata[i][field1], isoFormat), Math.round((tdata[i][field2] * 100)) / 100];
        } catch (ignore) {
        }
    }

    return result;
}

export function timeSeriesWithGranularity(tdata: any[], field2: string): any[] {
    let result: any = [];

    for (let i = 0; i < tdata.length; i++) {
        try {
            result[i] = [dayjs.default(tdata[i].timeslice).valueOf()

            , tdata[i][field2]];
        } catch (ignore) {
        }
    }
    return result;
}


export function prettifyChangesDetailsString(str: string | undefined | null) {
    if (str === 'NULL' || str === null || str === undefined) {
        return 'NULL'
    }

    str = str || ''
    return str.length > 30 ? `'${str.substring(0, 30)}...'` : `'${str.substring(0, 30)}'`
}

export const getQueryLanguageFromInstanceType = (instanceType: string) => {
    try {
        return INSTANCE_TYPE_TO_QUERY_LANGUAGE[instanceType]
    } catch (error: any) {
        return 'tsql'
    }
}

export const formatStatement = (statements: string | any[], instanceType: string) => {

    let result: string = '';
    if (statements.length > 0) {
        try {
            result = statements[0].sqltext;
            result = formatSqlStatement(result, instanceType)
        } catch (error: any) {
            console.error('Failed to format SQL', error);
        }
    }

    return result
}

export const copyToClipboard = (text: string, setToolTip: Function) => {
    const input = document.body.appendChild(document.createElement("textarea"));
    input.value = text;
    input.focus();
    input.select();
    try {
        document.execCommand('copy');
        input?.parentNode?.removeChild(input);
        setToolTip()
    } catch (ignore) {
    }
}

export const getToolTipPosition = (event: any) => {
    const getMenu = document.getElementById('menu-side')
    const menuWidth = getMenu?.clientWidth || 150;
    const pointerX = event.pageX;
    return pointerX - menuWidth + 10
}


export const getAction = (actionId: string, setAction: Function, setDisabled: Function) => {
    switch (actionId) {
        case 'create':
            setAction(ACTIONS.CREATE);
            break;

        case 'edit':
            setAction(ACTIONS.UPDATE);
            break;

        case 'delete':
            setAction(ACTIONS.DELETE);
            setDisabled(true);
            break;

        default:
            console.error('Unknown action requested', actionId);
            break;
    }
}

export const stringFormatting = (val: string) => {
    const mapping = {
        'cockroachdb': 'CockroachDB',
        'db2': 'DB2',
        'informix': 'Informix',
        'mysql': 'MySQL',
        'postgresql': 'PostgreSQL',
        'oracle': 'Oracle',
        'saphana': 'SAP HANA',
        'sqlserver': 'SQL Server',
        'activesessions': 'Active Sessions',
        'idlesessions': 'Idle Sessions',
        'memoryutilisation': 'Memory Utilisation',
        'ioutilisation': 'I/O Utilisation',
        'cpuutilisation': 'CPU Utilisation',
        'diskutilisation': 'Disk Utilisation',
        'waittime': 'Wait Time',
        'executions': 'Executions',
        'duration': 'Duration',
        'batchexecutions': 'Batch Executions',
    }
    // @ts-ignore
    const isException = mapping[val]
    return isException || val
}

export const capitalizeString = (val: string): string => {
    const lower = val.toLowerCase();

    if (val.includes(' ')) {
        const wordList = val.split(' ')
        return wordList.map((word: string) => capitalizeString(word)).join(' ')
    }

    return val.charAt(0).toUpperCase() + lower.slice(1);
}

export const getValidClass = (hasError: any, value: string | number, isDelete: boolean) => {
    return isDelete ? '' : hasError ? 'is-invalid' : value ? 'is-valid' : ''
}

export const findEmailIntegrationValue = (name: any, list: any[]) => {
    let prefix = name === 'starttls.enable' ? 'mail.smtp.starttls.' : 'mail.smtp.'
    prefix = name === 'url' ? 'mail.smtp.dbmarlin.' : prefix
    const findElem = list.find((elem: { name: string; }) => elem?.name === `${prefix}${name}`) || {}
    return findElem ? findElem?.value === 'true' ? true : findElem?.value === 'false' ? false : findElem?.value : undefined
}

export const findIntegrationValue = (name: any, list: any[], key: string) => {
    const findElem = list.find((elem: { name: string; }) => elem?.name === `${key}.${name}`) || {}
    return findElem ? findElem?.value === 'true' ? true : findElem?.value === 'false' ? false : findElem?.value : undefined
}

const _getTotalChanges = (val: number) => {
    let totalChanges: string = val.toString();

    if (totalChanges === '0' || totalChanges === '1') {
        return '';
    } else {
        return val.toString()
    }
}

// export const combinedIcons2 = (timesliceEvents: any[], timesliceChanges: any[], timesliceAlerts: any[]) => {
//     let icons = '';
//
//     const eventsTexts: any[] = [];
//     const changesTexts: any[] = [];
//     const alertsTexts: any[] = [];
//     timesliceChanges.forEach(change => changesTexts.push(`${change.change?.type}: ${change.change?.name}`));
//     timesliceEvents.forEach(event => eventsTexts.push(`${event.custom?.type}: ${event.custom?.name} - ${event.custom?.description}`));
//     timesliceAlerts.forEach(alert => alertsTexts.push(`${alert.alert.timeslice}: ${alert.alert.statistic}${alert.alert.decreasing && alert.alert.decreasing?.toString() === 'true' ? ' less than ' : ' greater than '}${alert.alert.threshold || ''}${alert.alert.units || ''}`));
//
//     console.log('timesliceEvents', timesliceEvents)
//     // Add correspondent icon or empty span for each type of change
//     icons += timesliceAlerts.length ? `<span class="hover-trigger"><i class="fal red-icon chart-icon fa-exclamation-triangle fa-fw"></i><sup class="text-secondary">${_getTotalChanges(timesliceAlerts.length)}</sup></span>` : `<span class="empty-icon">&nbsp;</span>`;
//     icons += `<div class="event-tooltip">`;
//     alertsTexts.forEach((alert, i) => {
//         if (i < 9) {
//             icons += `<p>${alert}</p>`
//         }
//     });
//     if (alertsTexts.length > 10) {
//         icons += `<p>[...]</p>`;
//     }
//     icons += `<p class="text-end"><a target="_blank" href="/event-history">See more</a></p>`;
//     icons += `</div>`;
//
//
//     icons += timesliceChanges.length ? `<span class="hover-trigger"><i class="fal chart-icon fa-exchange fa-fw"></i><sup class="text-secondary">${_getTotalChanges(timesliceChanges.length)}</sup></span>` : `<span class="empty-icon">&nbsp;</span>`;
//     icons += `<div class="event-tooltip">`;
//     changesTexts.forEach((change, i) => {
//         if (i < 9) {
//             icons += `<p>${change}</p>`
//         }
//     });
//     if (changesTexts.length > 10) {
//         icons += `<p>[...]</p>`;
//     }
//     icons += `<p class="text-end"><a target="_blank" href="/event-history">See more</a></p>`;
//     icons += `</div>`;
//
//
//     icons += timesliceEvents.length ? `<span class="hover-trigger"><i class="fal chart-icon fa-calendar fa-fw"></i><sup class="text-secondary">${_getTotalChanges(timesliceEvents.length)}</sup></span>` : `<span class="empty-icon">&nbsp;</span>`;
//     icons += `<div class="event-tooltip">`;
//     eventsTexts.forEach((event, i) => {
//         if (i < 9) {
//             icons += `<p>${event}</p>`
//         }
//     });
//     if (eventsTexts.length > 10) {
//         icons += `<p>[...]</p>`;
//     }
//     icons += `<p class="text-end"><a target="_blank" href="/event-history">See more</a></p>`;
//     icons += `</div>`;
//
//     return icons;
// };

export const combinedIcons = (timesliceEvents: any[], timesliceChanges: any[], timesliceAlerts: any[]) => {
    const eventsTexts = timesliceEvents.map(event => `${event.custom?.type}: ${event.custom?.name} - ${event.custom?.description}`);
    const changesTexts = timesliceChanges.map(change => `${change.change?.type}: ${change.change?.name}`);
    const alertsTexts = timesliceAlerts.map(alert => `${alert.alert.timeslice}: ${alert.alert.statistic}${alert.alert.decreasing && alert.alert.decreasing?.toString() === 'true' ? ' less than ' : ' greater than '}${alert.alert.threshold || ''}${alert.alert.units || ''}`);

    let icons = '<div class="hover-trigger">';
    icons += generateIconSpan(timesliceAlerts.length, 'fal red-icon chart-icon fa-exclamation-triangle fa-fw');
    icons += generateIconSpan(timesliceChanges.length, 'fal chart-icon fa-exchange fa-fw');
    icons += generateIconSpan(timesliceEvents.length, 'fal chart-icon fa-calendar fa-fw');
    icons += '</div><div class="event-tooltip">';
    icons += generateTooltipItems(alertsTexts, 'fal red-icon chart-icon fa-exclamation-triangle fa-fw');
    icons += generateTooltipItems(changesTexts, 'fal chart-icon fa-exchange fa-fw');
    icons += generateTooltipItems(eventsTexts, 'fal chart-icon fa-calendar fa-fw');
    icons += '<p class="mt-2">See Events tab for more details</p>';
    icons += '</div>';

    return icons;
};

const generateTooltipItems = (texts: any[], iconClass: string) => {
    let tooltipItems = '';
    texts.slice(0, 5).forEach((text, i) => {
        tooltipItems += `<p><i class="${iconClass}"></i> ${text} ${i === 4 && texts.length > 5 ? '<a target="_blank" href="/event-history">[...]</a>' : ''}</p>`;
    });
    return tooltipItems;
};

const generateIconSpan = (length: number, iconClass: string) => {
    return length ? `<span><i class="${iconClass}"></i><sup class="text-secondary">${_getTotalChanges(length)}</sup></span>` : '<span class="empty-icon">&nbsp;<span>';
};

export const mergeArraysByProp = (a: any[], b: any[], prop: string) => {
    const reduced = [];
    for (let i = 0; i < a.length; i++) {
        const aitem = a[i];
        let found = false;

        for (let ii = 0; ii < b.length; ii++) {
            if (aitem[prop] === b[ii][prop]) {
                found = true;
                break;
            }
        }

        if (!found) {
            reduced.push(aitem);
        }
    }

    return reduced.concat(b);
}

export const getDefaultPageSize = () => {
    const value = JSON.parse(window.localStorage.getItem('pageSize') || '10')
    return Number(value)
}

export function highchartsCredits(licences: Licence[]): boolean {
    // Enable Highcharts watermark if no paid-for, in-date licence.
    // Example call from a component:
    // const { licences } = useLicences ();
    // enabled: highchartsCredits (licences)
    // It should be able to use the last, aggregated entry in the licence list
    // but there is no concept yet of an aggregated type.
    /*
    if (licences.length > 0)
    {
        const licence: Licence = licences[licences.length - 1];

        if (licence.settings?.type === 'Paid')
        {
            return false;
        }
    }
    */

    // Instead iterate through the valid licences.
    for (const licence of licences) {
        if (licence.valid && licence.settings?.type === 'Paid') {
            return false;
        }
    }

    return true;
}


export const getWaitEventLink = (waitEvent: string) => {
    // Some processing is need of the wait-event to match the URL on the docs.dbmarlin.com side.
    // Swap slash for dash and strip brackets and percent characters
    let waitEventNew = waitEvent.replace("/", "-")

    // For CockroachDB the event 'executing (XXX.XX%)' can be any percentage but we have just 1 KB article for XXX.XX%
    var pattern = /executing \(.*\)/i;
    waitEventNew = waitEventNew.replace(pattern, "executing (XXX.XX%)");

    waitEventNew = waitEventNew.replace("(", "")
    waitEventNew = waitEventNew.replace(")", "")
    waitEventNew = waitEventNew.replace("%", "")
    waitEventNew = encodeURIComponent(waitEventNew)
    return (waitEventNew)
}

export function instanaTechnology(instanceType: string): string {
    // Map DBmarlin instance type to Instana technology.
    const map: any =
        {
            cockroachdb: 'cockroachDatabase',
            db2: 'db2Database',
            mysql: 'mySqlDatabase',
            postgresql: 'postgreSqlDatabase',
            oracle: 'oracleDB',
            sqlserver: 'msSqlDatabase'
        };

    return map[instanceType] || instanceType;
}

export const determineTimeUnit = (current_interval_filter_ms: number) => {
    if (current_interval_filter_ms <= 120000) {
        return 'minute'; // Less than 2 minutes
    } else if (current_interval_filter_ms <= 600000) {
        return 'minutes_10'; // Less 10 minutes
    } else if (current_interval_filter_ms <= 1800000) {
        return 'minutes_30'; // Less 30 minutes
    } else if (current_interval_filter_ms <= 3600000) {
        return 'hours_1'; // Less than 1 hour
    } else if (current_interval_filter_ms <= 7200000) {
        return 'hours_2'; // Less than 2 hours
    } else if (current_interval_filter_ms <= 28800000) {
        return 'hours_8'; // Less than 8 hours
    } else if (current_interval_filter_ms <= 86400000) {
        return 'day'; // Less than 1 day
    } else if (current_interval_filter_ms <= 608400000) {
        return 'week_1'; // Less than 1 week
    } else if (current_interval_filter_ms <= 3024000000) {
        return 'weeks_5'; // Less than 5 weeks
    } else if (current_interval_filter_ms <= 12096000000) {
        return 'weeks_20'; // Less than 20 weeks
    } else {
        return 'weeks'; // 20 weeks or more
    }
};

export const getConvertedTimeToUTC = ( value: any) => {
    return dayjs.default(value.timeslice).unix() * 1000
};

export const getMinMAx = (timeRangeContext: TimeRangeContextType) => {
    const formattedFromDate = timeRangeContext.timeRange?.from.format('YYYY-MM-DD HH:mm:ss')
    const formattedToDate = timeRangeContext.timeRange?.to.format('YYYY-MM-DD HH:mm:ss')
    let from = dayjs.default(formattedFromDate).valueOf()
    let to = dayjs.default(formattedToDate).valueOf()

    return {
        minX: from || undefined,
        maxX: to || undefined
    }
}

export const getToken = () => {
    const token = window.localStorage.getItem(TOKEN_KEY);
    const expiration = window.localStorage.getItem(EXPIRATION_KEY);

    if (!token || !expiration) {
        return null;
    }

    const expirationDate = new Date(expiration);
    const now = new Date();

    if (now > expirationDate) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(EXPIRATION_KEY);
        return null;
    }

    // Update the expiration date on each visit
    const newExpirationDate = new Date();
    newExpirationDate.setDate(newExpirationDate.getDate() + EXPIRATION_DAYS);
    // newExpirationDate.setMinutes(newExpirationDate.getMinutes() + 10);

    window.localStorage.setItem(EXPIRATION_KEY, newExpirationDate.toISOString());

    return token;
};

export const updateExpiration = () => {
    const newExpirationDate = new Date();
    // newExpirationDate.setMinutes(newExpirationDate.getMinutes() + 10);
    newExpirationDate.setDate(newExpirationDate.getDate() + EXPIRATION_DAYS);
    window.localStorage.setItem(EXPIRATION_KEY, newExpirationDate.toISOString());
};
