import {INSTANCE_TYPE_COCKROACHDB, INSTANCE_TYPE_DB2, INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER} from "../../component/Constants";

export const STATISTICS_ENABLED_INSTANCE_TYPES = [
    INSTANCE_TYPE_SQLSERVER,
    INSTANCE_TYPE_ORACLE,
    INSTANCE_TYPE_DB2,
    INSTANCE_TYPE_COCKROACHDB
]

export const STATISTICS_DISABLED_INSTANCE_TYPES = [
]

export const STATISTICS_BLOCKING_SESSIONS = [
    INSTANCE_TYPE_SQLSERVER,
    INSTANCE_TYPE_ORACLE,
]

export const SQL_STATISTICS_API_VERSION = parseInt(process.env.REACT_APP_SQL_STATISTICS_API_VERSION ?? '', 10) || 2

export const STATISTICS_FILTERS = ['metric', 'chart', 'topN', 'other', 'instance', 'tagName', 'tagValue', 'showSqlId', 'customId','firstInstanceId', 'firstInstanceFrom', 'firstInstanceTo', 'secondInstanceId', 'secondInstanceFrom', 'secondInstanceTo']

export const DEFAULT_TABLE_METRICS = {
    DURATION: 'duration',
    EXECUTIONS :'executions',
    CPU_TIME_MILLISECONDS :'cpu_time_milliseconds',
    PHYSICAL_READS :'physical_reads',
    LOGICAL_READS: 'logical_reads',
    LOGICAL_WRITES: 'logical_writes'
}
