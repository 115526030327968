import React, { useContext, useEffect, useState } from 'react';

// Third-party.

import ReactTooltip from 'react-tooltip';

// Helpers.
import Helper from '../../helpers/Helper';

// Types.
import Statement from '../../types/instance/Statement';
import Wait from '../../types/instance/Wait';
import { TimeRangeContext } from "../../context/TimeRangeContext";
import api from "../../api/Base";

function StatementBreakdown(props: { statement: Statement, waits: Wait[], instanceId: number, instanceTime?: number, filterParameters: string, batchId?: string }) {
    const [waits, setWaits] = useState<Wait[]>([]);
    const [tooltip, setTooltip] = useState<string>();
    const timeRangeContext = useContext(TimeRangeContext)

    const chartSeriesCeiling = Number(process.env.REACT_APP_CHART_SERIES_CEILING);

    let statementWeight: number = 0;

    if (props.instanceTime) {
        statementWeight = Math.floor((100 / props.instanceTime) * props.statement.waittime);
    }

    useEffect(() => {

        const getStatementWaits = async () => {

            let batch: string = '';

            if (props.batchId) {
                batch = `&batchsqlhash=${props.batchId}`;
            }

            // Get the statement waits.
            api.get(`activity/waitevent?${timeRangeContext.getTimeRangeQueryString()}&sort=waittime+desc&id=${props.instanceId}&sqlhash=${props.statement.sqlhash}${batch}${props.filterParameters}`)
            .then(async (response: { data: Wait[]; }) => {

                let statementWaits: Wait[] = response.data;
                
                let generatedTooltip: string = '';

                // Build top waits pie chart.
                for (let index = 0; index < statementWaits.length; index++) {

                    // Look for a matching passed wait state.
                    let matchedWaits: Wait[] = props.waits.filter(wait => wait.waitevent === statementWaits[index].waitevent);

                    if (matchedWaits.length > 0) {

                        // Use the matched wait colour.
                        statementWaits[index].color = matchedWaits[0].color;
                    } else {

                        // Unmatched wait, so use the generic colour.
                        statementWaits[index].color = '#999';
                    }

                    // Work out the wait percentage.
                    const weight = Math.floor((100 / props.statement.waittime) * statementWaits[index].waittime);

                    // Set the statement weight and build the tooltip.
                    statementWaits[index].weight = weight;

                    if (weight > 0) {
                        generatedTooltip = `${generatedTooltip}<li>${statementWaits[index].waitevent}: ${Helper.getTimeInEnglish(statementWaits[index].waittime)} (${statementWaits[index].weight}%)</li>`;
                    }
                }

                setWaits(statementWaits);
                setTooltip('<p>This statement has the following top waits:</p><ol>{waits}</ol>'.replace('{waits}', generatedTooltip));
            })
            .catch((error: any) => {
                console.error('Failed to retrieve statement wait events.', error);
            })
            .then(function () {
                // As we're late data-binding, rebuild the tooltips.
                ReactTooltip.rebuild();
            });
        }

        if (props.instanceTime) {
            getStatementWaits();
        }

    }, [props.statement, props.waits, props.instanceTime, props.filterParameters, props.instanceId, chartSeriesCeiling, statementWeight, props.batchId, timeRangeContext]);

    if (statementWeight < 5) {
        return (
            <React.Fragment>-</React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{ width: `${statementWeight}%` }} data-tip={tooltip}>
                {waits.filter(wait => wait.weight && wait.weight > 4).map((wait: Wait, index) => (
                    <div key={index} style={{ width: `${wait.weight}%`, backgroundColor: wait.color }}>&nbsp;</div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default StatementBreakdown;
