import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Types.
import Period from '../../../../types/Period';
import InstanaIntegration from '../../../../types/integration/InstanaIntegration';

// Constants.
import { ACTIONS } from '../../../../component/Constants';
import { DATA_LOADING, DATA_LOADED } from '../../../../component/Constants';

// Apis.
import IntegrationsApi from '../../../../api/IntegrationApi';

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../../container/breadcrumb";
import HeaderActions from "../../../../container/header_actions";
import ConditionalRender from "../../../../helpers/ConditionalRender";
import { InstanaIntegrationContext } from "../../../../context/InstanaIntegrationContext";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface IInstanaProps {
    period: Period,
    instanaIntegrations: InstanaIntegration[],
    toggleMenu: Function,
    applyPeriod: Function
}

interface IInstanaState {
    loading: number,
    instanaIntegrations: InstanaIntegration[],
    instanaIntegration: InstanaIntegration
}

const initialState: IInstanaState = {
    loading: DATA_LOADING,
    instanaIntegrations: [],
    instanaIntegration: {
        id: 0,
        name: '',
        host: '',
        apikey: ''
    }
};

class Instana extends Component<IInstanaProps, IInstanaState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };
    static contextType = InstanaIntegrationContext;

    constructor(props: IInstanaProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        this.getInstanaIntegrations();
    }

    async getData() {
        this.setState({
            loading: DATA_LOADING
        });
        this.getInstanaIntegrations();


    }

    async getInstanaIntegrations() {

        // Get integrations.
        const instanaIntegrations: InstanaIntegration[] = await IntegrationsApi.getIntegrations();

        this.setState({
            loading: DATA_LOADED,
            instanaIntegrations
        });
    }

    /* Action Methods */

    refresh = () => {

        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, instanaIntegrations, instanaIntegration } = this.state;


        return (
            <div id="content">
                <Helmet>
                    <title>Instana - Settings - DBmarlin</title>
                    <meta name="description" content="Manage your Instana integrations" />
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Instana">
                        <Link to="/admin">Settings</Link>
                        <Link to="/admin/integrations">Integrations</Link>
                        <span>Instana</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh} />
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/integrations/instana/create" render={() => <Detail refresh={this.refresh} actionId={ACTIONS.CREATE.type} />} />
                        <Route exact path="/admin/integrations/instana/:actionId/:instanaId" render={(props) => <Detail refresh={this.refresh} {...props} actionId={props.match.params.actionId} instanaId={Number(props.match.params.instanaId)} />} />
                        <Route exact path="/admin/integrations/instana/" render={(props) => <List {...props} loading={loading} instanaIntegrations={instanaIntegrations} instanaIntegration={instanaIntegration} refresh={this.refresh} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

Instana.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Instana;
