// React.
import { useContext, useEffect, useState } from 'react';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { TempDbSpaceTime, TempDbUsageSession } from '../../../types/instance/TempDb';

// Components.
import TempDbSpaceChart from '../../../component/instance/chart/TempDbSpaceChart';
import TempDbUsageTable from '../../../component/instance/table/TempDbUsageTable';

// Helper.
import { archiverUrl, fetchResults, fetchWithAuthorization } from '../../../helpers/utils';
import FilterOption from "../../../types/instance/FilterOption";


import { TimeRangeContext } from "../../../context/TimeRangeContext";

export default function TempDb(props: {
    instance: InstanceTarget,
    filterOptions: FilterOption[],
    setFilterOptions: Function,
    applyPeriod: Function
}) {
    const { instance } = props;
    const timeRangeContext = useContext(TimeRangeContext);

    const [tempDbSpace, setTempDbSpace] = useState<TempDbSpaceTime[]>([]);
    const [tempDbUsage, setTempDbUsage] = useState<TempDbUsageSession[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function load() {
            try {
                setLoading(true);
                const results: any[][] = await fetchResults(
                    [
                        fetchWithAuthorization(archiverUrl() + `/tempdb/space/time?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}`),
                        fetchWithAuthorization(archiverUrl() + `/tempdb/usage/session?${timeRangeContext.getTimeRangeQueryString()}&id=${instance.id}`)
                    ]);

                if (active) {
                    setTempDbSpace(results[0]);
                    setTempDbUsage(results[1]);
                    setLoading(false);
                }
            } catch (x: any) {
                console.log(x.message);
                setError(x.message);
                setTempDbSpace([]);
                setTempDbUsage([]);
                setLoading(false);
            }
        }

        let active: boolean = true;
        load();
        return () => {
            // Clean up afterwards to avoid race conditions.
            active = false;
        }
    }, [instance.id, timeRangeContext]);

    return (<>
        <div className="row row-cols-1">
            <div id="space" className="tab-pane fade show active" role="tabpanel" aria-labelledby="space-tab">
                <TempDbSpaceChart error={error} loading={loading}
                                  applyPeriod={props.applyPeriod}
                                  data={tempDbSpace}/>
            </div>
            <div id="space" className="tab-pane fade show active" role="tabpanel" aria-labelledby="space-tab">
                <TempDbUsageTable error={error} loading={loading}
                                  setFilterOptions={props.setFilterOptions}
                                  data={tempDbUsage} instance={instance}/>
            </div>
        </div>
    </>)
}
