// React.
import { useEffect, useState } from 'react';

// Third-parties.
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { TreeTable, TreeTableExpandedKeysType,
	TreeTableSelectionKeys } from 'primereact/treetable';

// Types.
import ExecutionPlan from '../../../types/instance/ExecutionPlan';

// Helpers.
import { Step, formatNumber, getStepDetails } from '../../../helpers/utils';

// Components.
import KnowledgeBasePlanButton from '../../buttons/KnowledgeBasePlanButton';

export default function MySqlPlan (props: { executionPlan: ExecutionPlan })
{
	const [treeSteps, setTreeSteps] = useState<Step[]> ([]);
	const [expandedKeys, setExpandedKeys] =
		useState<TreeTableExpandedKeysType> ({});
	const [selectedStepKey, setSelectedStepKey] =
		useState<TreeTableSelectionKeys> (null);
	const [stepDetails, setStepDetails] = useState<any[]> ([]);
	const [stepOperation, setStepOperation] = useState<string> ('');

	function getStepOperation (details: any[]): string
	{
		const detail: any[] =
			details.filter (detail => detail.name === 'operation');
		return detail.length === 1 ? detail[0].value : '';
	}

	useEffect (() =>
	{
		// These closure variables are required by the recursive functions.
		let n: number = 0;
		const expanded: TreeTableExpandedKeysType = {};

		function getKey (): string
		{
			return String (++n);
		}

		function inlineArray (field: string): boolean
		{
			return field === 'possible_keys' || field === 'used_key_parts' ||
				field === 'used_columns' || field === 'ref';
		}

		function inline (field: string): boolean
		{
			return field === 'cost_info' || inlineArray (field);
		}

		function getData (step: any, stepName: string): any
		{
			const data: any = { operation: stepName };

			for (const field in step)
			{
				const type: string = typeof (step[field]);

				if (type === 'string' || type === 'boolean')
				{
					data[field] = String (step[field]);
				}
				else if (type === 'number')
				{
					data[field] = formatNumber (step[field]);

					// MySQL has two row fields:
					// rows_examined_per_scan and rows_produced_per_join
					// MariaDB only has a field called rows.
					if (field === 'rows_examined_per_scan')
					{
						data['rows'] = data[field];
					}
				}
				else if (type === 'object')
				{
					// Special handling for interesting sub-objects.
					// Could be made recursive but requires the same structure.
					const object = step[field];

					if (field === 'cost_info')
					{
						for (const costField in object)
						{
							const costType: string = typeof (object[costField]);

							if (costType === 'string')
							{
								data[costField] = object[costField];

								// Need query or prefix cost in the tree table.
								// Surprisingly the number is of type string.
								if (costField === 'prefix_cost')
								{
									data['query_cost'] = data[costField];
								}
							}
							else if (costType === 'number')
							{
								data[costField] =
									formatNumber (object[costField]);
							}
						}
					}
					else if (inlineArray (field))
					{
						data[field] = object.join (', ');
					}
				}
			}

			return data;
		}

		function getChildren (step: any): Step[]
		{
			const children: Step[] = [];

			for (const field in step)
			{
				const type: string = typeof (step[field]);

				if (type === 'object')
				{
					if (field === 'nested_loop' ||
						field === 'attached_subqueries')
					{
						const childSteps: any[] = step[field];

						for (let i: number = 0; i < childSteps.length; i++)
						{
							children.push (getStep (childSteps[i],
								field + '#' + (i + 1)));
						}
					}
					else if (!inline (field))
					{
						const childStep: any = step[field];
						children.push (getStep (childStep, field));
					}
				}
			}

			return children;
		}

		function getStep (step: any, stepName: string): Step
		{
			const key: string = getKey ();

			// Expand all rows as a side-effect.
			expanded[key] = true;

			const treeStep: Step =
			{
				key: key,
				data: getData (step, stepName),
				children: getChildren (step)
			};

			return treeStep;
		}

		const plan: any = JSON.parse (props.executionPlan.sqlplan);
		// console.log ('plan', plan);
		const tree: Step[] = [];
		tree.push (getStep (plan['query_block'], 'query_block'));
		// const details: any[] = getStepDetails (tree, '1');
		setTreeSteps (tree);
		setExpandedKeys (expanded);
		setSelectedStepKey ('1');
		// setStepDetails (details);
		// setStepOperation (getStepOperation (details));
	}, [props.executionPlan.sqlplan]);

	useEffect (() =>
	{
		const details: any[] = getStepDetails (treeSteps, selectedStepKey);
		const operation: string = getStepOperation (details);
		setStepDetails (details);
		setStepOperation (operation);
	}, [treeSteps, selectedStepKey]);

	// The bracket must be present and on the same line as the return.
	return (<>
		<Splitter>
			<SplitterPanel size={65} className="plan-master-panel">
				<TreeTable
					value={treeSteps}
					expandedKeys={expandedKeys}
					onToggle={e => setExpandedKeys (e.value)}
					selectionMode="single"
					resizableColumns
					reorderableColumns
					selectionKeys={selectedStepKey}
					onSelectionChange={e => setSelectedStepKey (e.value)}
					className="treetable-responsive"
				>
					<Column header="Operation" field="operation" expander
						className="col-width-35 plan-first-column"/>
					<Column header="Object" field="table_name"
						className="col-width-25"/>
					<Column header="Access" field="access_type"
						className="col-width-10"/>
					<Column header="Cost" field="query_cost"
						className="col-width-15 text-end"/>
					<Column header="Rows" field="rows"
						className="col-width-15 text-end"/>
				</TreeTable>
			</SplitterPanel>
			<SplitterPanel size={35} className="plan-detail-panel">
				<DataTable value={stepDetails} resizableColumns stripedRows>
					<Column header="Name" field="name"
						className="col-width-50 plan-first-column"/>
					<Column header="Value" field="value"
						className="col-width-50"/>
				</DataTable>
				{
				<KnowledgeBasePlanButton databaseType="mysql"
					planOperation={stepOperation}
					planOption=""/>
				}
			</SplitterPanel>
		</Splitter>
	</>)
}
