import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Api.
import LicenceApi from '../../../api/LicenceApi';

// Types.
import Period from '../../../types/Period';
import Licence from '../../../types/Licence';

// Constants.
import { DATA_INITIALISING, DATA_LOADING, DATA_LOADED } from '../../../component/Constants';

// Views.
import List from './List';
import Detail from './Detail';
import Breadcrumb from "../../../container/breadcrumb";
import HeaderActions from "../../../container/header_actions";
import ConditionalRender from "../../../helpers/ConditionalRender";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);

interface ILicencesProps {
    period: Period,
    licences: Licence[],
    toggleMenu: Function,
    applyPeriod: Function
}

interface ILicencesState {
    loading: number,
    licences: Licence[],
    licence: Licence
}

const initialState: ILicencesState = {
    loading: DATA_INITIALISING,
    licences: [],
    licence: {
        id: 0,
        valid: false,
        key: '',
        properties: ''
    }
};

class Licences extends Component<ILicencesProps, ILicencesState> {
    static propTypes: {  toggleMenu: PropTypes.Validator<(...args: any[]) => any>, applyPeriod: PropTypes.Validator<(...args: any[]) => any>; };

    constructor(props: ILicencesProps) {
        super(props);

        this.state = initialState;
    };

    componentDidMount() {
        const { loading } = this.state;

        // this.getCountExample();

        if (loading === DATA_INITIALISING) {

            this.setState({
                loading: DATA_LOADING
            });

            this.getData();
        }
    }

    async componentDidUpdate(prevProps: ILicencesProps, prevState: ILicencesState) {
        const { licences } = this.state
        
        try {

            if (prevProps.licences !== licences) {

                // Update the state to the version from the properties (which is actually context driven).
                this.setState({
                    licences: prevProps.licences
                });
            }

        } catch (error) {

            console.error('Failed to update licences', prevProps, prevState);
        }
    }

    // async getCountExample() {
    //
    //     /*
    //     const count: undefined | number = await activityApi.getCount(Dimension.Database);
    //     console.log('database count', count);
    //
    //     const time: undefined | DatabaseTime[] = await activityApi.getSummary();
    //     console.log('time', time);
    //     */
    //
    //     try {
    //
    //         const
    //             datasourceId: undefined | number = undefined,
    //             sqlhash: undefined | string = undefined,
    //             batchsqlhash: undefined | string = undefined;
    //
    //         const activityApi = new Api(process.env.REACT_APP_API, this.props.period, datasourceId, sqlhash, batchsqlhash);
    //
    //         const resultPromises = await Promise.all([
    //             activityApi.getCount(Dimension.Database),
    //             activityApi.getInstanceTime()
    //         ]);
    //
    //         const dataPromises = await resultPromises.map(result => result.json());
    //
    //         await Promise.all(dataPromises);
    //
    //     } catch (error) {
    //         console.error('An error occurred', error);
    //     }
    // }

    async getData() {

        // Get licences.
        let licences: Licence[] = await LicenceApi.getLicences();

        for (let index = 0; index < licences.length; index++) {
            
            // Build the licence settings (via the random JSON properties field).
            licences[index].settings = JSON.parse(licences[index].properties);

            if (licences[index].settings && licences[index].settings?.enddate) {

                // Get the end date.
                const enddate = licences[index].settings?.enddate;

                if (enddate !== undefined) {

                    // Depending upon the licence expiry, set the CSS class name to be applied.
                    if (dayjs.default().isAfter(dayjs.default(enddate))) {

                    // Licence has expired.
                    licences[index].class = 'text-danger';
                    
                    } else if (dayjs.default().add(1, 'month').isAfter(enddate)) {

                        // Due to expire in the next four weeks.
                        licences[index].class = 'text-amber';

                    } else if (dayjs.default().add(6, 'week').isAfter(enddate)) {

                        // Due to expire in the next six weeks.
                        licences[index].class = 'text-warning';
                    }
                }
            }
        }

        this.setState({
            loading: DATA_LOADED,
            licences
        });
    }

    /* Action Methods */

    refresh = () => {
        
        this.setState({
            loading: DATA_LOADING
        });

        this.getData();
    }

    render() {
        const { loading, licences, licence } = this.state;

        return (
            <div id="content">
                <Helmet>
                    <title>Licences - Settings - DBmarlin</title>
                    <meta name="description" content="Manage your DBmarlin licences" />
                </Helmet>

                <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                    <Breadcrumb heading="Licences">
                        <Link to="/admin">Settings</Link>
                        <span>Licences</span>
                    </Breadcrumb>
                    <HeaderActions period={this.props.period} toggleMenu={this.props.toggleMenu} refresh={this.refresh} />
                </div>

                <div className="loader">
                    <ConditionalRender if={false}>
                        <div className="bar"></div>
                    </ConditionalRender>
                </div>

                <div className="row row-cols-1">
                    <Switch>
                        <Route exact path="/admin/licences/create" render={() => <Detail />} />
                        <Route exact path="/admin/licences/:licenceId" render={(props) => <Detail {...props} licenceId={Number(props.match.params.licenceId)} />} />
                        <Route exact path="/admin/licences/" render={(props) => <List {...props} key={licences.length} loading={loading} licences={licences} licence={licence} refresh={this.refresh} />} />
                    </Switch>
                </div>
            </div>
        );
    }
}

Licences.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired
};

export default Licences;
