import React, { useContext, useEffect, useMemo, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';

// Helpers.
import {
    archiverUrl,
    fetchResults, fetchWithAuthorization,
    highchartsCredits,
    mergeArraysByProp,
    stringFormatting,
    timeSeriesWithGranularity
} from "../../../helpers/utils";
import Helper from "../../../helpers/Helper";
import { useHistory } from 'react-router-dom';
import ConditionalRender from "../../../helpers/ConditionalRender";
import * as QueryString from "query-string";

// Oracle.
import OracleStatisticsTable from "./oracle-statistics-table";


//Constants
import {
    CHART_DATA_TYPE,
    OTHER_COLOUR_CODE,
    TARGET_COLOUR_CODES
} from "../../../component/Constants";

//style
import './styles.scss'
import { OracleSqlHashTimeSlice, OracleSqlHashWithMetric } from "../types"
import { SQL_STATISTICS_API_VERSION } from "../constants"
import { InstanceContext } from "../../../context/InstanceContext";
import { useLicences } from "../../../context/LicenceContext";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

const UPMOST_CHART_INDEX_VALUE = 99999

export default function OracleStatistics(props: {
    metricsList: string[];
    setInstanceType: Function;
    type: string;
    period: Period,
    applyPeriod: Function
}) {
    const searchParams = new URLSearchParams(window.location.search)
    const { instances } = useContext(InstanceContext);
    const { licences } = useLicences();

    instances.sort((a, b) => a.name.localeCompare(b.name))

    const apiVersion = parseInt(searchParams.get('api_version') || '', 10) || SQL_STATISTICS_API_VERSION

    const [instanceId, setInstanceId] = useState<number>(parseInt(searchParams.get('instance') || '', 10) || -1)
    const [filter] = useState<string>(searchParams.get('filter') || '')
    // const [aggregate, setAggregate] = useState<number>(parseInt(searchParams.get('aggregate') || '0'));
    const [metric, setMetric] = useState<string>(searchParams.get('metric') || '');
    // const [defaultTimeSlice, setDefaultTimeSlice] = useState<number>(INTERVAL_10_SECONDS);
    const [generateEnabled, setGenerateEnabled] = useState<boolean>(false);
    const [chartVisible, setChartVisible] = useState<boolean>(true);
    const [metricsList, setMetricsList] = useState<string[]>([]);
    const [statementsApiData, setStatementsApiData] = useState<any[]>([])
    const [chart, setChart] = useState<string>(searchParams.get('chart') || 'column');
    const [topN, setTopN] = useState<number>(searchParams.get('topN') ? parseInt(searchParams.get('topN') as string) : 10);
    const [other, setOther] = useState<boolean>(searchParams.get('other') === 'true');
    const [topNChartData, setTopNChartData] = useState<{
        [key: string]: { values: OracleSqlHashTimeSlice[], orderIndex: number }
    }>({});
    const [tagName, setTagName] = useState<string>(searchParams.get('tagName') || 'none');
    const [generateId, setGenerateId] = useState<number>(1);
    const [tagList, setTagList] = useState<any[]>([]);
    const tagValue = searchParams.get('tagValue') || ''
    const [customSqlId, setCustomSqlID] = useState<string>(searchParams.get('customId') || searchParams.get('filter') || '')
    const [loading, setLoading] = useState<boolean>(false);

    const [generatedTagName, setGeneratedTagName] = useState<string>('none')
    const [generatedTagValue, setGeneratedTagValue] = useState<string>('')
    const [generatedSqlId, setGeneratedSQlId] = useState<boolean>(searchParams.get('showSqlId') === 'true');
    const [generatedMetric, setGeneratedMetric] = useState<string>(searchParams.get('metric') || '')
    const [generatedCustomId, setGeneratedCustomId] = useState<string>(searchParams.get('customId') || '')
    const [generatedInstance, setGeneratedInstance] = useState<InstanceTarget | null>(null)

    const history = useHistory();
    const timeRangeContext = useContext(TimeRangeContext)

    const availableInstances = useMemo(() => {
        return instances.filter((instance: InstanceTarget) => instance.collectsqlstatistics)
    }, [instances])

    const instanceType = availableInstances.find(instance => instance.id === instanceId)?.type
    const [sqlId, setSQlId] = useState<boolean>(searchParams.get('showSqlId') === 'true');


    const updateUrlParams = (type: string, value: string, isDelete?: boolean) => {
        const query = window.location.search;
        let queryString = QueryString.parse(query);
        if (isDelete) {
            delete queryString[type]
        } else {
            queryString[type] = value
        }
        history.push(`?${QueryString.stringify(queryString)}`)
    }

    const deleteUrlParam = (type: string) => {
        const query = window.location.search;
        let params = QueryString.parse(query);
        delete params[type]
        history.push(`?${QueryString.stringify(params)}`)
    }

    useEffect(() => {
        setTagName(searchParams.get('tagName') || 'none')
    }, [tagList, timeRangeContext])


    useEffect(() => {
        const list = props.metricsList
        setMetricsList(list)
        const selectedMetric = searchParams.get('metric')
        let newMetric = ''
        if (list.length && selectedMetric) {
            newMetric = list.indexOf(selectedMetric) > -1 ? selectedMetric : ''
            setMetric(newMetric)
        }

    }, [instanceId, timeRangeContext, props.metricsList])


    const __getOtherTotal = (allMetrics: any, topNMetrics: any): OracleSqlHashTimeSlice => {
        let totalValue = allMetrics[metric]
        topNMetrics.forEach((item: any) => {
            const getTopNItemAtTimeSlice = item.find((x: OracleSqlHashTimeSlice) => x.timeslice === allMetrics.timeslice) || 0
            totalValue = getTopNItemAtTimeSlice[metric] ? totalValue - getTopNItemAtTimeSlice[metric] : totalValue
        })
        return {
            timeslice: allMetrics.timeslice,
            [metric]: totalValue < 0 ? 0 : totalValue
        }
    }

    const selectedInstanceTarget = useMemo(() => {
        // const getInstance = instances.find(instance => instance.id === instanceId)
        // const getTimeSliceValue = getInstance?.batchintervalseconds ? getInstance?.batchintervalseconds * 1000 : INTERVAL_10_SECONDS
        // setDefaultTimeSlice(getTimeSliceValue)
        return instances.find((instance: InstanceTarget) => instance.id === instanceId) || null
    }, [instanceId])

    useEffect(() => {
        setGenerateEnabled(!!(topN && metric && instanceId && (filter ? customSqlId : true)))
    }, [topN, metric, instanceId, customSqlId])

    useEffect(() => {
        async function gettagNames() {
            let gettagNames: any = []
            gettagNames = await fetchResults([fetchWithAuthorization(`${archiverUrl(1)}/sql/tag/name?id=${instanceId}`)])
            setTagList(gettagNames[0])
        }

        gettagNames()

        // set instance type to get list of metrics for it
        if (instanceType) {
            props.setInstanceType(instanceType)
        }
    }, [instanceId])


    useEffect(() => {
        if (instanceId && metric && topN) {
            load()
        }
    }, [])

    useEffect(() => {
        if (topN < 1) {
            return setTopN(1)
        }
        if (topN > 100) {
            return setTopN(100)
        }
    }, [topN])

    async function load() {
        setGenerateId(new Date().getTime())
        try {
            let chartApiDataResults: OracleSqlHashWithMetric[][] = [[]]
            const instanceType = availableInstances.find(instance => instance.id === instanceId)?.type || 'oracle'
            const apiTagString = tagName === 'none' ? `${archiverUrl(apiVersion)}/sql/statistic/sql?` : `${archiverUrl(1)}/sql/statistic/tag/value?tagname=${tagName}&`

            // let groupStatementsData: OracleSqlHashWithMetric[][] = [];
            if (!loading && metric && chart && instanceId) {
                setLoading(true);
                let topNChartApiDataResults: OracleSqlHashWithMetric[] = []
                if (filter) {
                    const apiString = sqlId ? `&sqlid=${customSqlId}` : `&sqlhash=${customSqlId}`
                    chartApiDataResults = await fetchResults([fetchWithAuthorization(`${apiTagString}${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&sort=${metric}+desc&type=${instanceType}${apiString}`)])
                }
                if (!filter && !tagValue) {
                    let apiString = ''
                    if (customSqlId) {
                        apiString = sqlId ? `&sqlid=${customSqlId}` : `&sqlhash=${customSqlId}`
                    }
                    chartApiDataResults = await fetchResults([fetchWithAuthorization(`${apiTagString}${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&sort=${metric}+desc&type=${instanceType}${apiString}`)])
                }
                if (tagValue) {
                    chartApiDataResults = await fetchResults([fetchWithAuthorization(`${archiverUrl(apiVersion)}/sql/statistic/sql?${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&sort=${metric}+desc&type=${instanceType}&tagvalue=${tagValue}&tagname=${tagName}`)])
                }
                let secondSortOption = tagName !== 'none' ? 'tagvalue' : 'sqlhash'
                const sortedResults = chartApiDataResults[0].sort((a: any, b: any) => {
                    return (b[metric] - a[metric]) || b[secondSortOption]?.localeCompare(a[secondSortOption])
                })

                topNChartApiDataResults = sortedResults.slice(0, topN)


                let topNChartTimePromises = []
                for (let sqlHashData of topNChartApiDataResults) {
                    const apiSqlHash = (tagName === 'none' || filter || tagValue) ? `sqlhash=${sqlHashData.sqlhash}` : `tagname=${tagName}&tagvalue=${sqlHashData.tagvalue}`
                    topNChartTimePromises.push(fetchWithAuthorization(`${archiverUrl(apiVersion)}/sql/statistic/time?${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&type=${instanceType}&${apiSqlHash}&limit=50000&component=OracleStatistics`))
                }

                const topNChartTimeResults = await fetchResults(topNChartTimePromises)

                let allChartTimeResults: OracleSqlHashTimeSlice[][] = []
                if (other) {
                    allChartTimeResults = await fetchResults([fetchWithAuthorization(`${archiverUrl(apiVersion)}/sql/statistic/time?${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&type=${instanceType}&limit=50000&component=OracleStatistics`)]);  // add tag name?
                }


                const topNData: { [key: string]: { values: OracleSqlHashTimeSlice[], orderIndex: number } } = {}

                topNChartTimeResults.forEach((result: OracleSqlHashTimeSlice[], index: number) => {
                    const sqlhash = (tagName !== 'none' && !tagValue) ? topNChartApiDataResults[index].tagvalue : (sqlId && topNChartApiDataResults[index].sqlid) ? topNChartApiDataResults[index].sqlid : topNChartApiDataResults[index].sqlhash

                    if (sqlhash) {
                        topNData[sqlhash] = {
                            values: result.map((item: any) => {
                                return {
                                    timeslice: item.timeslice,
                                    [metric]: item[metric],
                                }
                            }),
                            orderIndex: index + 1
                        }
                    }
                    if (other) {
                        const otherValues = result.map((item: OracleSqlHashTimeSlice) => {
                            const getAllAtTimeSlice = allChartTimeResults[0].find((otherItem: OracleSqlHashTimeSlice) => otherItem.timeslice === item.timeslice)
                            if (getAllAtTimeSlice) {
                                const otherTotal = __getOtherTotal(getAllAtTimeSlice, topNChartTimeResults)
                                const valuesExist = topNData['Other SQL']?.values?.find(item => item.timeslice === otherTotal.timeslice)
                                // return topNData['Other SQL']?.values.find(item => item.timeslice === otherTotal.timeslice) ? [] : otherTotal
                                return valuesExist || otherTotal
                            }
                            return []
                        })
                        topNData['Other SQL'] = {
                            // @ts-ignore
                            // values:  topNData['Other SQL']?.values.length ? topNData['Other SQL']?.values.concat(otherValues) : otherValues,
                            values: topNData?.['Other SQL']?.values ? mergeArraysByProp(topNData?.['Other SQL']?.values, otherValues, 'timeslice') : otherValues,
                            orderIndex: UPMOST_CHART_INDEX_VALUE
                        }
                    }
                })

                // Fill missing data with 0 values
                const filledData = Helper.fillChartDataWithZeroValues(topNData, metric)

                // const customId = sqlId ? sortedResults[0].sqlid : sortedResults[0].sqlhash
                // setCustomSqlID(filter && customId ? customId.toString() : '')
                setLoading(false);
                setTopNChartData(filledData)
                // @ts-ignore
                setGeneratedMetric(metric)
                setGeneratedCustomId(customSqlId)
                setGeneratedSQlId(sqlId)
                setGeneratedTagName(tagName)
                setGeneratedTagValue(tagValue)
                setStatementsApiData(sortedResults)
                setGeneratedInstance(selectedInstanceTarget)
            }

            if (!loading && !metric) {
                setLoading(true);
                chartApiDataResults = await fetchResults([fetchWithAuthorization(`${apiTagString}${timeRangeContext.getTimeRangeQueryString(false)}&id=${instanceId}&type=${instanceType}`)])
                setStatementsApiData(chartApiDataResults[0])
                setGeneratedMetric(metric)
                setGeneratedCustomId(customSqlId)
                setGeneratedSQlId(sqlId)
                setGeneratedTagName(tagName)
                setGeneratedInstance(selectedInstanceTarget)
                setGeneratedTagValue(tagValue)
                setTopNChartData({})
                setLoading(false);
            }
        } catch (error: any) {
            setStatementsApiData([])
            setTopNChartData({})
            setLoading(false);
        }
    }

    const timeChart = useMemo(() => {
        const timeChartOptions = chartOptionsGranularity(props.applyPeriod, Object.keys(topNChartData).map((key: string) => {
            return {
                name: key,
                type: chart,
                data: timeSeriesWithGranularity(topNChartData[key].values, metric),
                zIndex: 1,
                color: key === 'Other SQL' ? OTHER_COLOUR_CODE : TARGET_COLOUR_CODES[topNChartData[key].orderIndex - 1],
                legendIndex: topNChartData[key].orderIndex,
                index: (UPMOST_CHART_INDEX_VALUE - topNChartData[key].orderIndex),
                boostThreshold: 0
            }
        }), null, {
            timeRangeContext: timeRangeContext,
            credits: { enabled: highchartsCredits(licences) },
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
                },
            },
            chart: {
                height: '500px',
                type: 'column',
            },
            legend: {
                align: 'center',
                backgroundColor: 'rgba(255,255,255,0.25)',
                enabled: true,
                floating: false,
                itemMarginTop: 0,
                itemMarginBottom: 2,
                itemStyle: {
                    color: '#212529',
                    fontSize: '.7rem'
                },
                layout: 'horizontal',
                padding: 0,
                x: 0,
                y: 0,
                verticalAlign: 'bottom'
            },
            yAxis: [{
                labels: {
                    enabled: true
                },
                crosshair: true,
                min: 0,
                title: {
                    text: `${stringFormatting(metric).replace(/_/gi, ' ')} ${Helper.getMetricFormatString(metric, true)}`
                }
            }],
        }, 500)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                options={timeChartOptions}/>;

    }, [topNChartData, filter, chart, timeRangeContext]);


    useEffect(() => {
        updateUrlParams('instance', instanceId.toString())
        updateUrlParams('metric', metric)
        updateUrlParams('chart', chart)
        updateUrlParams('tagName', tagName)
        updateUrlParams('topN', topN.toString())
        updateUrlParams('other', JSON.stringify(other))
        updateUrlParams('showSqlId', JSON.stringify(sqlId))
        updateUrlParams('customId', customSqlId, !customSqlId)
    }, [instanceId, metric, chart, topN, other, sqlId, tagName, customSqlId])

    const setInstance = (val: number) => {
        setInstanceId(val)
        setTagName('none')
        deleteUrlParam('tagName')
        deleteUrlParam('tagValue')

        const instanceType = availableInstances.find(instance => instance.id === val)?.type
        props.setInstanceType(instanceType)
        if (!filter) {
            setSQlId(instanceType === 'oracle')
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            load()
        }
    }
    const chartTitle = useMemo(() => {
        if (filter) {
            return statementsApiData.length ? `Statements ${generatedMetric && `By ${stringFormatting(generatedMetric).replace(/_/gi, ' ')}`} ${`for # ${statementsApiData[0].sqlhash}, ID ${statementsApiData[0].sqlid}`}` : ''
        }
        if (generatedTagValue) {
            return `Statements ${generatedMetric && `By ${stringFormatting(generatedMetric).replace(/_/gi, ' ')}`} ${generatedTagValue ? `for Tag Name: ${generatedTagName}, Tag Value: ${generatedTagValue}` : ''}`
        }
        return `Statements ${generatedMetric && `By ${stringFormatting(generatedMetric).replace(/_/gi, ' ')}`}`
    }, [generateId, statementsApiData])

    return <React.Fragment>
        <div className="row row-cols-1">
            {(loading) ?
                <div className="loader chart" style={{ height: "auto" }}>
                    <div className="bar"/>
                </div> : ''
            }
            <div id="statistics" className="show active" role="tabpanel" aria-labelledby="statements-tab">
                <div className="row">
                    <div className="card filter">
                        <div className="sql-filters d-flex flex-wrap">
                            <div className="col-xs-4 col-md-4 col-lg-2 ">
                                <span className="required">
                                    <label>Instance</label>
                                </span>
                                <select className="form-select"
                                        value={instanceId}
                                        disabled={loading || !!filter || !!tagValue}
                                        aria-label="Select Instance"
                                        onChange={(e) => {
                                            setInstance(parseInt(e.target.value, 10))
                                        }}>
                                    <option key={-1} value="-1" disabled={true}>Select Instance</option>
                                    {availableInstances.map((instance, key) => <option key={key}
                                                                                       value={instance.id}>{instance.name}</option>)}
                                </select>
                            </div>
                            <div className="col-xs-12 col-md-4 col-lg-2 ">
                                <span className="required">
                                    <label>Metric</label>
                                </span>
                                <select className="form-select"
                                        value={metric}
                                        defaultValue={undefined}
                                        disabled={loading}
                                        aria-label="Select Metric"
                                        onChange={(e) => {
                                            setMetric(e.target.value);
                                        }}>
                                    <option key={-1} value="" disabled={true}>Select metric</option>
                                    {metricsList.map((metric, key) => <option key={key}
                                                                              value={metric}>{metric}</option>)}
                                </select>
                            </div>
                            <div className="col-xs-12 col-md-4 col-lg-2 ">
                                <label>Chart</label>
                                <select className="form-select"
                                        value={chart || undefined}
                                        disabled={loading}
                                        aria-label="Select Chart Type"
                                        onChange={(e) => {
                                            setChart(e.target.value)
                                        }}>
                                    <option selected={true} disabled={true}>Select chart type</option>
                                    <option value='area'>Stacked Area</option>
                                    <option value='column'>Stacked Column</option>
                                    <option value='line'>Multi-Series Line Chart</option>
                                </select>
                            </div>
                            <ConditionalRender if={!filter}>
                                <div className="col-xs-12 col-md-4 col-lg-2">
                                    <span className="required">
                                        <label>Top N</label>
                                    </span>
                                    <input className="form-control"
                                           type='number'
                                           max={100}
                                           min={1}
                                           value={topN || ''}
                                           disabled={loading}
                                           onChange={(e) => {
                                               setTopN(parseInt(e.target.value));
                                           }}
                                           aria-label="Select Top N Statement to Show"/>
                                </div>
                            </ConditionalRender>
                            <div className="col-xs-12 col-md-4 col-lg-2">
                                <ConditionalRender if={!tagValue && !filter}>
                                        <span>
                                            <label>Tag Name</label>
                                        </span>
                                    <select className="form-select"
                                            value={tagName}
                                            disabled={loading}
                                            aria-label="Select Tag"
                                            onChange={(e) => {
                                                setTagName(e.target.value)
                                            }}>
                                        <option value="none">None</option>
                                        {tagList.length &&
                                            <optgroup label="Tag Name">
                                                {tagList.map((tag, i) => <option key={i} value={tag}>{tag}</option>)}
                                            </optgroup>}
                                    </select>
                                </ConditionalRender>
                            </div>


                            <ConditionalRender if={filter || tagValue}>
                                <div className="col-xs-12 col-md-2 col-lg-1">
                                </div>
                            </ConditionalRender>

                            <div className="col-xs-12 col-md-4 col-lg-2">
                                <label data-tip='All other statements combined'>Other</label>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <div className='form-check form-switch'>
                                        <input
                                            type="checkbox"
                                            id="disabled"
                                            disabled={loading}
                                            className="form-check-input"
                                            onChange={() => {
                                                setOther(!other)
                                            }}
                                            checked={other}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-xs-12 col-md-4 col-lg-2">
                                 <span className={`${filter ? "required" : ''}`}>
                                    <label>{`${sqlId ? 'SQL ID' : 'SQL #'}`}</label>
                                 </span>
                                <input className="form-control"
                                       type='text'
                                       value={customSqlId || ''}
                                       disabled={loading}
                                       onKeyDown={handleKeyDown}
                                       onChange={(e) => {
                                           setCustomSqlID(e.target.value);
                                       }}/>
                            </div>

                            <div className="col-xs-12 col-md-4 col-lg-2 ">
                                {instanceType === 'oracle' &&
                                    <>
                                        <label>Show SQL IDs</label>
                                        <div className="col-xs-12 col-md-6 col-lg-3">
                                            <div className='form-check form-switch'>
                                                <input
                                                    type="checkbox"
                                                    id="disabled"
                                                    disabled={loading}
                                                    className="form-check-input"
                                                    onChange={() => {
                                                        setSQlId(!sqlId)
                                                    }}
                                                    checked={sqlId}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <button className="btn btn-success" disabled={!generateEnabled} onClick={load}><i
                            className="fal fa-play fa-fw fa-sm"/><span>Generate</span></button>
                    </div>
                </div>

                {selectedInstanceTarget && statementsApiData && <div className="card">
                    <div className="card-header">
                        <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                        {chartTitle}
                        {/*set chart visible fa case in which is generated in minimised card version */}
                        <i className="collapse-toggle" role="button" onClick={() => setChartVisible(!chartVisible)}
                           data-bs-toggle="collapse"
                           data-bs-target="#collapseStatementsChart" aria-expanded="false"
                           aria-controls="collapseStatementsChart"/>
                    </div>
                    <ConditionalRender if={chartVisible}>
                        <div id="collapseStatementsChart" className="card-body collapse show">
                            {timeChart}
                        </div>
                    </ConditionalRender>
                </div>}
                <div className="col">
                    {selectedInstanceTarget && statementsApiData && generatedInstance &&
                        <OracleStatisticsTable period={props.period}
                                               aggregate={timeRangeContext.timeRange.interval}
                                               generateId={generateId}
                                               customSqlId={generatedCustomId}
                                               instance={generatedInstance} filter={filter} topN={topN}
                                               tagName={generatedTagName} tagValue={generatedTagValue}
                                               shwSqlID={generatedSqlId}
                                               metric={generatedMetric} chart={chart} statements={statementsApiData}/>}
                </div>

            </div>
        </div>
    </React.Fragment>
}