import Helper from "../../../helpers/Helper";
import DatabaseTime from "../../../types/instance/DatabaseTime";
import Statistic from "../../../types/instance/Statistic";
import { TimeRangeContextType } from "../../../context/TimeRangeContext";
import { INSTANCE_TYPE_SQLSERVER } from "../../../component/Constants";
import InstanceTarget from "../../../types/instance/InstanceTarget";
import api from "../../../api/Base";
import api_v2 from "../../../api/Base_v2";

export const getInstanceExecutions = async(instanceId: number, timeRangeContext: TimeRangeContextType, filterParameters: string = '') => {
    let executions: number = 0
    let previousExecutions: number = 0
    // The executions statistic is only available at the instance level.
    // If there are filter parameters then must use hte activity figure.
    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
    if (filterParameters) {
        // Get total executions.
        await api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}${filterParameters}`)
            .then((response: { data: DatabaseTime[] }) => {
                if (response.data.length > 0 && response.data[0].executions) {
                    executions = response.data[0].executions
                }
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.error('Failed to retrieve executions statistic.',
                    error);
            });

        if (comparisonSupported) {
            // Get total executions for the previous period.
            await api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString(true)}&id=${instanceId}${filterParameters}`)
                .then((response: { data: DatabaseTime[] }) => {
                    if (response.data.length > 0 &&
                        response.data[0].executions) {
                        previousExecutions = response.data[0].executions
                    }
                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.error
                    ('Failed to retrieve executions statistic.', error);
                });
        }
    } else {
        // Get total executions.
        await api.get(`statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=executions&id=${instanceId}`)
            .then((response: { data: Statistic[]; }) => {
                if (response.data.length > 0) {
                    executions = response.data[0].sum
                }
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.error('Failed to retrieve executions statistic.', error);
            })
            .then(function () {
                // Todo: Always executed - do we need this?
            });

        if (comparisonSupported) {
            // Get total executions for the previous period.
            await api.get(`statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=executions&id=${instanceId}`)
                .then((response: { data: Statistic[]; }) => {
                    if (response.data.length > 0) {
                        previousExecutions = response.data[0].sum
                    }
                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.error('Failed to retrieve executions statistic.', error);
                })
                .then(function () {
                    // Todo: Always executed - do we need this?
                });
        }
    }
    return {executions, previousExecutions}
}

export const getInstanceTotalTime = async(instanceId: number, timeRangeContext: TimeRangeContextType, filterParameters: string = '') => {
    let totalInstanceTime = 0
    let totalPreviousInstanceTime = 0
    // Get total database time.
    await api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}${filterParameters}`)
        .then((response: { data: DatabaseTime[]; }) => {
            if (response.data.length > 0) {
                totalInstanceTime = response.data[0].waittime
            }
        })
        .catch((error: any) => {
            console.log('An error occurred:', error);
        })
        .then(function () {
            // Todo: Always executed - do we need this?
        });

    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
    if (comparisonSupported) {
        // Get previous total database time.
        await api.get(`activity/summary?${timeRangeContext.getTimeRangeQueryString(true)}&id=${instanceId}${filterParameters}`)
            .then((response: { data: DatabaseTime[]; }) => {
                if (response.data.length > 0) {
                    totalPreviousInstanceTime = response.data[0].waittime
                }
            })
            .catch((error: any) => {
                console.log('An error occurred:', error);
            })
            .then(function () {
                // Todo: Always executed - do we need this?
            });
    }
    return {totalInstanceTime, totalPreviousInstanceTime}
}

export const getInstanceTotalDuration = async(instanceId: number, timeRangeContext: TimeRangeContextType, instanceType: string = '', statementId: string, isGroup: boolean, batchStatementId?: string) => {
    let totalInstanceTime = 0
    let totalPreviousInstanceTime = 0
    const apiString = isGroup ? 'group' : 'sql'
    const sqlHashString = isGroup ? 'grouphash' : 'sqlhash'
    // Get total database time.
    await api_v2.get(`sql/statistic/${apiString}?${timeRangeContext.getTimeRangeQueryString()}&id=${instanceId}&type=${instanceType}&sort=executions+desc&${sqlHashString}=${batchStatementId || statementId}`)
        .then((response: { data: any[]; }) => {
            // const filteredList = response.data.filter(item => item[sqlHashString] === statementId)
            if (response.data.length > 0) {
                totalInstanceTime = response.data.length ? response.data[0].duration : 0
            }
        })
        .catch((error: any) => {
            console.log('An error occurred:', error);
        })

    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
    if (comparisonSupported) {
        // Get previous total database time.
        await api_v2.get(`sql/statistic/${apiString}?${timeRangeContext.getTimeRangeQueryString(true)}&id=${instanceId}&type=${instanceType}&sort=executions+desc&${sqlHashString}=${batchStatementId || statementId}`)
            .then((response: { data: any[]; }) => {
                // const filteredList = response.data.filter(item => item[sqlHashString] === statementId)
                if (response.data.length > 0) {
                    totalPreviousInstanceTime = response.data.length ? response.data[0].duration : 0
                }
            })
            .catch((error: any) => {
                console.log('An error occurred:', error);
            })
    }
    return {totalInstanceTime, totalPreviousInstanceTime}
}

export const getStatementExecutions = async(instanceId: number, type: string, statementId: string, timeRangeContext: TimeRangeContextType, filterParameters: string = '', isGroup: boolean, batchStatementId?: string) => {
    let data: number = 0,
        previousData: number = 0;

    const apiString = isGroup ? 'group' : 'sql'
    const sqlHashString = isGroup ? 'grouphash' : 'sqlhash'
    // Get the statement execution plans.
    await api_v2.get(`/sql/statistic/${apiString}?${timeRangeContext.getTimeRangeQueryString()}&type=${type}&id=${instanceId}&${sqlHashString}=${batchStatementId || statementId}${filterParameters}`)
        .then(async(response: { data: any[]; }) => {
            // const filteredList = response.data.filter(item => item[sqlHashString] === statementId)
            data = (( response.data.length > 0) ?  response.data[0].executions : 0)
        })
        .catch((error: any) => {
            // Todo: handle and log the error.
            console.error('Failed to retrieve statement activity.', error);
        })

    const comparisonSupported = Helper.isComparisonSupported(timeRangeContext)
    if (comparisonSupported) {
        // Get previous period statement data.
        await api_v2.get(`/sql/statistic/${apiString}?${timeRangeContext.getTimeRangeQueryString(true)}&type=${type}&id=${instanceId}&${sqlHashString}=${batchStatementId || statementId}${filterParameters}`)
            .then(async(response: { data: any[]; }) => {
                // const filteredList = response.data.filter(item => item[sqlHashString] === statementId)
                previousData = (( response.data.length > 0) ?  response.data[0].executions : 0)
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.error('Failed to retrieve previous statement activity.', error);
            })
    }
    return {data, previousData}
}

export const getAPIString = (statementId?: string, batchStatementId?: string) => {
    const statementType = getStatementTypeFromURL()

    // Not a statement inside batch
    if (!batchStatementId) {
        return `&${statementType.api}=${statementId}`
    }


    // Statement inside batch
    if (statementType.isGroup) {
        return `&grouphash=${batchStatementId}`
    }
    return `&batchsqlhash=${statementId}&sqlhash=${batchStatementId}`
}

export const getStatementTypeFromURL = () => {
    const pathname = window.location.pathname
    const parts = pathname.split('/');

    // Get statement type frm URL
    let activityIndex = parts.indexOf("activity");

    let activityType = ''
    let breadcrumb = ''
    if (activityIndex !== -1 && activityIndex + 1 < parts.length) {
        activityType = parts[activityIndex + 1];
    }

    // check if is statement inside batch
    if (activityType === 'batch' || activityType === 'groupedbatch'){
        if (pathname.includes('/statement/')) {
            activityType= 'statement'
        } else if (pathname.includes('/groupedstatement/')) {
            activityType= 'groupedstatement'
        }
    }

    // special case for sql statistic statemnts page
    if (parts[3] === "sql-statistics") {
        activityType= 'statement'
    }
    // Use statement type for api calls and also for breadcrumb
    let apiString = ''
    switch (activityType) {
        case 'batch':
            apiString = 'batchsqlhash'
            breadcrumb = 'Batch'
            break
        case 'groupedbatch':
            apiString = 'batchgrouphash'
            breadcrumb = 'Gr Batch'
            break
        case 'statement':
            apiString = 'sqlhash'
            breadcrumb = 'Statement'
            break
        case 'groupedstatement':
            apiString = 'grouphash'
            breadcrumb = 'Gr Statement'
            break
        default:
            apiString = 'sqlhash'
            breadcrumb = 'Statement'
            break
    }
    return {
        type: activityType,
        api: apiString,
        breadcrumb,
        isBatch: activityType === 'batch' || activityType === 'groupedbatch',
        isGroup: activityType === 'groupedbatch' || activityType === 'groupedstatement',
    }
}

export const getPlanAndTextTabName = (type: string, instance: InstanceTarget) => {

    let tabTexts = {
        plans: 'Statement and Plans',
        activity: 'Statement Activity'
    }
    switch (type) {
        case 'batch':
            tabTexts.plans = 'Batch and Plans'
            tabTexts.activity = 'Batch Activity'
            break;

        case 'groupedbatch':
            tabTexts.plans = 'Batch and Plans'
            tabTexts.activity = 'Grouped Batch Activity'
            break;

        case 'statement':
            tabTexts.plans = instance.type === INSTANCE_TYPE_SQLSERVER ? 'Statement' : 'Statement and Plans'
            tabTexts.activity = 'Statement Activity'
            break;

        case 'groupedstatement':
            tabTexts.plans = instance.type === INSTANCE_TYPE_SQLSERVER ? 'Statement' : 'Statement and Plans'
            tabTexts.activity = 'Grouped Statement Activity'
            break;
        default:
            break;
    }
    return tabTexts
}

// check if it's a searched statement 
export const isGenericStatement = () => {
    const url = window.location.href;
    return url.includes("statement-activity")
}