import React, { useContext, useMemo, useState } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Context
import { InstanceContext } from '../../../context/InstanceContext';

// Types.
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartSeries from '../../../types/ChartSeries';
import Time from '../../../types/instance/Time';
import Wait from '../../../types/instance/Wait';
import Helper from '../../../helpers/Helper';
import InstanceTarget from "../../../types/instance/InstanceTarget";

// Constants.
import { CHART_DATA_TYPE, DATA_LOADED, DATA_LOADING, INSTANCE_TYPE_COCKROACHDB } from '../../Constants';
import { INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER } from '../../Constants';

// Helpers
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";

// Context
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { useLicences } from "../../../context/LicenceContext";
import { getAPIString } from "../../../views/instances/tabs/utils";
import api from "../../../api/Base";

function StatementTimeChart(props: { displayTimeChart: boolean, instance: InstanceTarget, batchId?: string, batchStatementId?: string, statementId?: string, filtersParameters: string, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], waits: Wait[], applyPeriod: Function }) {
    const {instances} = useContext(InstanceContext);
    const timeRangeContext = useContext(TimeRangeContext)
    const {licences} = useLicences();

    const [loading, setLoading] = useState<number>(DATA_LOADING);

    const [timeSeries, setTimeSeries] = useState<ChartSeries[]>([]);
    const [waitSeries, setWaitSeries] = useState<ChartSeries[]>([]);

    const timePeak = useMemo(() => {
        // @ts-ignore
        return Helper.getPeakValue(timeSeries.map(i => i[1]))
    }, [timeSeries])

    const apiString = getAPIString(props.statementId || props.batchId, props.batchStatementId)

    void useMemo(async() => {
        setLoading(DATA_LOADING);
        // Get time for the period.
        api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instance.id}${apiString}${props.filtersParameters}`)
            .then((response: { data: Time[]; }) => {
                // @ts-ignore
                setTimeSeries(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.waittime]
                }));
                setLoading(DATA_LOADED);
            })
            .catch((error: any) => {
                console.error('Failed to retrieve activity time.', error);
            })
    }, [props.batchId, timeRangeContext, props.instance, props.statementId, props.filtersParameters])

    void useMemo(async() => {
        setLoading(DATA_LOADING);

        const waitsPromises = []
        let waitSeries: any[] = []

        // Get the top individual waits for the period.
        for (let index = 0; index < props.waits.slice(0, 9).length; index++) {
            let wait: Wait = props.waits[index];
            waitsPromises.push(api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${props.instance.id}${apiString}${props.filtersParameters}`))
        }

        const responses = await Promise.all(waitsPromises)
        responses.forEach((response: { data: Time[]; }, index: number) => {
            let wait: Wait = props.waits[index];
            let waitData: number[][] = [];

            waitData = waitData.concat(response.data.map(item => {
                return [getConvertedTimeToUTC(item), item.waittime]
            }));

            // Add the chart series.
            waitSeries.push({
                name: wait.waitevent,
                color: wait.color,
                type: 'column',
                data: waitData,
                tooltip: {
                    valueSuffix: 'ms',
                },
                zIndex: 1
            });
        })

        setWaitSeries(waitSeries);
        setLoading(DATA_LOADED);
    }, [props.batchId, props.statementId, timeRangeContext, props.instance, props.waits, props.filtersParameters])

    void useMemo(() => {

        // Get the current instance.
        const matchedInstances = instances.filter(instance => Number(instance.id) === Number(props.instance.id));

        if (matchedInstances.length > 0) {
            switch (matchedInstances[0].type) {
                case INSTANCE_TYPE_ORACLE:
                case INSTANCE_TYPE_SQLSERVER:
                case INSTANCE_TYPE_COCKROACHDB:
                    break;

                default:
                    break;
            }
        }
    }, [props.batchId, props.statementId, timeRangeContext, props.instance, instances, props.filtersParameters])

    const timeChart = useMemo(() => {
        const timeChartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                type: 'column', data: timeSeries, name: 'Time Spent', zIndex: 1, tooltip: {valueSuffix: 'ms',}
            },
        ], timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            legend: true,
            timeRangeContext: timeRangeContext,
            plotLines: props.plotLines,
            plotBands: props.plotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                },
            },
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                options={timeChartOptions}/>;

    }, [timeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    const waitsChart = useMemo(() => {
        const waitsChartOptions = chartOptionsGranularity(props.applyPeriod, waitSeries, timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            legend: true,
            timeRangeContext: timeRangeContext,
            plotLines: props.plotLines,
            plotBands: props.plotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                },
            },
        })
        return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={waitsChartOptions}/>;


    }, [waitSeries, timePeak, props.plotBands, props.plotLines, timeRangeContext, licences]);

    return (
        <React.Fragment>
            {loading === DATA_LOADED && (
                <React.Fragment>
                    <span className="peak">
                        
                        {(timePeak === Infinity || timePeak === -Infinity) ? (
                            <React.Fragment>Peak Time: -</React.Fragment>
                        ) : (
                            <React.Fragment>Peak Time: {Helper.getTimeInEnglish(timePeak)}</React.Fragment>
                        )}
                    </span>
                    <br/>
                    <div className={((props.displayTimeChart) ? '' : 'd-none')}>
                        {timeChart}
                    </div>
                    <div className={((props.displayTimeChart) ? 'd-none' : '')}>
                        {waitsChart}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default StatementTimeChart;
