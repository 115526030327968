// React.
import { useContext, useMemo } from 'react';

// Types.
import { TempDbSpaceTime } from '../../../types/instance/TempDb';

// Components.
import HighchartsWithData from '../../../component/HighchartsWithData';

// Helpers.
import { getConvertedTimeToUTC, highchartsCredits } from '../../../helpers/utils';
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";

// Context
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import { useLicences } from "../../../context/LicenceContext";
import { TARGET_COLOUR_CODES } from "../../Constants";

export default function TempDbSpaceChart(props: { error: string | null, loading: boolean, data: TempDbSpaceTime[], applyPeriod: Function }) {
    const { error, loading, data } = props;
    const { licences } = useLicences();
    const timeRangeContext = useContext(TimeRangeContext)

    const options = useMemo(() => {
            return chartOptionsGranularity(props.applyPeriod, [
                {
                    name: 'Version store reserved',
                    color: TARGET_COLOUR_CODES[0],
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['versionstorereservedpagecount']]
                    })
                },
                {
                    name: 'User object reserved',
                    color: TARGET_COLOUR_CODES[1],
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['userobjectreservedpagecount']]
                    })
                },
                {
                    name: 'Internal object reserved',
                    color: TARGET_COLOUR_CODES[2],
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['internalobjectreservedpagecount']]
                    })
                },
                {
                    name: 'Mixed extent',
                    color: TARGET_COLOUR_CODES[3],
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['mixedextentpagecount']]
                    })
                },
                {
                    name: 'Modified extent',
                    color: TARGET_COLOUR_CODES[4],
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['modifiedextentpagecount']]
                    })
                },
                {
                    name: 'Total',
                    type: 'spline',
                    color: '#DE1173',
                    data: data.map(item => {
                        return [getConvertedTimeToUTC(item), item['totalpagecount']]
                    })
                }
            ], null, {
                credits: { enabled: highchartsCredits(licences) },
                timeRangeContext: timeRangeContext,
                legend: true,
                plotLines: [],
                plotBands: [],
                yAxis: [{
                    labels: {
                        align: 'left',
                        enabled: true,
                        x: 0,
                        y: 15
                    },
                    min: 0,
                    showFirstLabel: false,
                    title: {
                        text: ''
                    }
                }],
            })
        },
        [data, licences, timeRangeContext]);

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                Space (pages)
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapse-space" aria-expanded="false"
                   aria-controls="collapse-space"/>
            </div>
            <div id="collapse-space" className="card-body collapse show">
                <HighchartsWithData error={error} loading={loading}
                                    length={data.length} options={options}/>
            </div>
        </div>
    )
}
