// Api.
import api from '../Base';

// Helper.
import Helper from '../../helpers/Helper';

// Types.
import HostStatistic from '../../types/host/HostStatistic';
import { TimeRangeContextType } from "../../context/TimeRangeContext";

const StatisticApi = {
    
    getStatistic: async (timeRangeContext: TimeRangeContextType, hostId: number, statistic: string) => {

        let url = `host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=${statistic}&id=${hostId}`;

        let data: number = 0;

        await api.get<HostStatistic[]>(url)
            .then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    data = response.data[0].avg;
                }
            })
            .catch((error: any) => {
                console.error('Failed to retrieve host statistic.', error, timeRangeContext, hostId, statistic);
            })
            .then(function () {});

        return data;
    },
    
    getStatisticPeak: async (timeRangeContext: TimeRangeContextType, hostId: number, statistic: string) => {

        let url = `host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=${statistic}&id=${hostId}`;

        let data: number[] = [0];

        await api.get<HostStatistic[]>(url)
            .then((response) => {

                if (response.status === 200) {
                    for (let index = 0; index < response.data.length; index++) {

                        data.push(response.data[index].avg);
                    }
                }
            })
            .catch((error: any) => {
                console.error('Failed to retrieve host statistic over time.', error, timeRangeContext, hostId, statistic);
            })
            .then(function () {});

        return Math.round(Helper.getPeakValue(data));
    }
}

export default StatisticApi;