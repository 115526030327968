import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// Third-party packages.
import * as dayjs from 'dayjs';

// Api.
import api from '../../../api/Base';
import LicenceApi from '../../../api/LicenceApi';

// Context.
import { LicenceContext } from '../../../context/LicenceContext';

// Types.
import Licence from '../../../types/Licence';

// Constants.
import { ACTION_CREATE, ACTION_DELETE } from '../../../component/Constants';

// Components.
import Alert from '../../../component/Alert';

interface LocationState {
    action: {
        icon: string,
        title: string,
        type: string,
        variant: string
    },
    licence: Licence
}

function Detail(props: { licenceId?: number } ) {
    const history = useHistory();
    const { licences, setLicences } = useContext(LicenceContext);

    // Get state from the location store.
    const location = useLocation<LocationState>();
    const { action, licence } = location.state;

    useEffect(() => {

        const getLicence = (licenceId: number) => {

            const matchedLicence = licences.filter((licence: Licence) => licence.id === licenceId);

            if (matchedLicence.length === 1) {
                /*
                setTitle(matchedLicence[0].title);
                */
            }
        }

        if (props.licenceId) {
            getLicence(props.licenceId);
        }

    }, [licences, licence, props.licenceId]);

    // Set the state.
    const [isError, setIsError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [disabled] = useState<boolean>(action === ACTION_DELETE);
    const [id] = useState<number>(licence.id);
    const [key, setKey] = useState<string>(licence.key);
    const [keyClass, setKeyClass] = useState<string>('');

    async function handleActionClick() {

        // Validate the form.
        let valid = true;

        if (key.trim() === '') {

            valid = false;

            // Invalid, set the class.
            setKeyClass('is-invalid');
        } else {

            // Valid.
            setKeyClass('is-valid');
        }

        setIsValid(valid);

        if (!valid) {
            return;
        }

        // Build the licence object.
        let data = [{
            id
        }];

        let result: any = undefined;

        switch (action) {
            case ACTION_CREATE:

                // Save the object.
                try {
                    result = await api.post('licence', [{ 'key': key }]);    
                } catch (error) {

                    console.error('Failed to register licence', error, key);
                    
                    setIsError(true);

                    return;
                }

                break;
            case ACTION_DELETE:

                // Delete the object.
                result = await api.delete('licence', { data });

                break;
            default:

                // Unknown action requested.
                console.error('Unknown action requested', action);

                setIsError(true);

                break;
        }

        if (result.status !== 200) {

            // Failed to complete the API request.
            console.error('Failed to complete requested action', action, result, data);

            // Failed to complete request.
            setIsError(true);

            // An error occurred, so exit this function.
            return;
        }

        // Get the latest licences and update the context object.
        const updatedLicences = await LicenceApi.getLicences();
        for (let index = 0; index < updatedLicences.length; index++) {
            
            // Build the licence settings (via the random JSON properties field).
            updatedLicences[index].settings = JSON.parse(updatedLicences[index].properties);

            if (updatedLicences[index].settings && updatedLicences[index].settings?.enddate) {

                // Get the end date.
                const enddate = updatedLicences[index].settings?.enddate;

                if (enddate !== undefined) {

                    // Depending upon the licence expiry, set the CSS class name to be applied.
                    if (dayjs.default().isAfter(dayjs.default(enddate))) {

                    // Licence has expired.
                    updatedLicences[index].class = 'text-danger';
                    
                    } else if (dayjs.default().add(1, 'month').isAfter(enddate)) {

                        // Due to expire in the next four weeks.
                        updatedLicences[index].class = 'text-amber';

                    } else if (dayjs.default().add(6, 'week').isAfter(enddate)) {

                        // Due to expire in the next six weeks.
                        updatedLicences[index].class = 'text-warning';
                    }
                }
            }
        }

        setLicences(updatedLicences);

        // Redirect the user back to the list view.
        history.push('/admin/licences', { refresh: true });
    }

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-file-certificate fa-fw" aria-hidden="true"></i>
                    {action.title.replace('Create', 'Register').replace('Update', 'View')} licence
                </div>
                <div className="card-body">

                    {action.type === 'delete' && (
                        <Alert message="Please check and ensure you want to delete this licence." heading="Deletes are final and cannot be undone" variant="alert-warning" />
                    )}

                    {isError && (
                        <Alert message="Sorry, but something went wrong and the requested action failed.  If the issue persists, please raise a support request." heading={`Failed to ${action.title.toLowerCase()} licence`} variant="alert-danger" />
                    )}

                    {!isValid && (
                        <Alert message="Please complete all the required fields below." heading="Missing required fields" variant="alert-danger" />
                    )}

                    {action === ACTION_CREATE && (
                        <p>Please complete the required details below:</p>
                    )}

                    <div className="row g-0 form">

                        {action === ACTION_CREATE ? (
                            <React.Fragment>

                                {/* Key */}
                                <div className="col-xs-12 col-md-3">
                                    <span className="required"><label htmlFor="key" className="reminder" data-tip="The licence key">Licence Key</label></span>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <textarea id="key" autoFocus className={`form-control ${keyClass}`} defaultValue={key} onBlur={e => setKey(e.target.value)} disabled={disabled} data-lpignore={true}></textarea>
                                    <small className="text-secondary">If you need help, please contact technical support.</small>
                                </div>
                                <div className="w-100"></div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>

                                {/* Key */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="key" className="reminder" data-tip="The licence key">Licence Key</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <textarea id="key" className={`form-control ${keyClass}`} defaultValue={licence.key} onBlur={e => setKey(e.target.value)} disabled={true} data-lpignore={true}></textarea>
                                </div>
                                <div className="w-100"></div>

                                {/* Type */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="type" className="reminder" data-tip="The licence type">Licence Type</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">

                                    {(licence.settings && licence.settings.tier && licence.settings.type) ? (
                                        <input
                                            type="text"
                                            id="type"
                                            className={`form-control`}
                                            required
                                            value={`${licence.settings.tier.toString().replace('1', 'Starter').replace('2', 'Standard').replace('3', 'Premium')} - ${licence.settings.type}`}
                                            disabled={true}
                                            data-lpignore={true}
                                        />
                                    ) : (
                                        (licence.settings && licence.settings.type) ? (
                                            <input
                                                type="text"
                                                id="type"
                                                className={`form-control`}
                                                required
                                                value={`${licence.settings.type}`}
                                                disabled={true}
                                                data-lpignore={true}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                id="type"
                                                className={`form-control`}
                                                required
                                                value="-"
                                                disabled={true}
                                                data-lpignore={true}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="w-100"></div>

                                {/* MAC Address */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="macaddress" className="reminder" data-tip="The assigned MAC address">MAC Address</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <input
                                        type="text"
                                        id="macaddress"
                                        className={`form-control`}
                                        required
                                        value={licence.settings?.macaddress}
                                        disabled={true}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"></div>

                                {/* Valid Period */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="validperiod" className="reminder" data-tip="The licence period">Valid Period</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <input
                                        type="text"
                                        id="validperiod"
                                        className={`form-control`}
                                        required
                                        value={((licence.settings?.startdate && licence.settings?.enddate) ? `${licence.settings?.startdate} to ${licence.settings?.enddate}` : ((licence.settings?.startdate) ? `${licence.settings?.startdate}` : ((licence.settings?.enddate) ? `${licence.settings?.enddate}` : '-')))}
                                        disabled={true}
                                        data-lpignore={true}                                        
                                    />
                                </div>
                                <div className="w-100"></div>

                                {/* Licence Count */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="count" className="reminder" data-tip="">Licence Count</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <input
                                        type="text"
                                        id="count"
                                        className={`form-control`}
                                        required
                                        value={licence.settings?.count}
                                        disabled={true}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"></div>

                                {/* Client */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="client" className="reminder" data-tip="The registered client name">Client Name</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <input
                                        type="text"
                                        id="client"
                                        className={`form-control`}
                                        required
                                        value={licence.settings?.client}
                                        disabled={true}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"></div>

                                {/* Contact */}
                                <div className="col-xs-12 col-md-3">
                                    <label htmlFor="contact" className="reminder" data-tip="The licencee contact name">Contact Name</label>
                                </div>
                                <div className="col-xs-12 col-md-9 col-lg-6">
                                    <input
                                        type="text"
                                        id="contact"
                                        className={`form-control`}
                                        required
                                        value={licence.settings?.contact}
                                        disabled={true}
                                        data-lpignore={true}
                                    />
                                </div>
                                <div className="w-100"></div>

                            </React.Fragment>
                        )}

                        {/* Actions */}
                        <div className="col-xs-12 col-md-12 col-lg-9 actions">
                        {(action === ACTION_CREATE || action === ACTION_DELETE) ? (
                            <React.Fragment>
                                <Link to="/admin/licences/" role="button" className="btn btn-secondary"><i className="fal fa-times-circle fa-fw fa-sm"></i><span>Cancel</span></Link>
                                <button className={`btn btn-${action.variant}`} onClick={handleActionClick}><i className={`${action.icon} fa-sm`}></i><span>{action.title.replace('Create', 'Register')}</span></button>
                            </React.Fragment>
                        ) : (
                            <Link to="/admin/licences/" role="button" className="btn btn-secondary"><i className="fal fa-arrow-circle-left fa-fw fa-sm"></i><span>Back</span></Link>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Detail;