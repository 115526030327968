// Third-parties.
import ReactTooltip from 'react-tooltip';

function KnowledgeBasePlanButton(props: { databaseType: string, planOperation: string, planOption: string }) {
        
    ReactTooltip.rebuild();

    // Some processing is need of the wait-event to match the URL on the docs.dbmarlin.com side.
    // Swap slash for dash and strip brackets and percent characters
    let planOperation = props.planOperation.replace("/", "-")
    let planOption = props.planOption
    let planOperationNew = planOperation 
    let planOptionNew = planOption

    planOperationNew = planOperationNew.replace("(", "")
    planOperationNew = planOperationNew.replace(")", "")
    planOperationNew = planOperationNew.replace("%", "")
    planOperationNew = encodeURIComponent (planOperationNew)
    // console.log("Plan Operation is:" + planOperationNew + " and database type is: " + props.databaseType)

    // Example URL = https://docs.dbmarlin.com/docs/kb/execution-plans/postgresql/Seq%20Scan
    return (
        <div className='knowledgeBasePlanButton'>
            <button type="button" className="btn btn-sm btn-outline-secondary kb-plan-button" 
            onClick={() => window.open(`https://docs.dbmarlin.com/docs/kb/execution-plans/${props.databaseType}/${planOperationNew} ${planOptionNew}`, "_blank")}>
                <i className="fas fa-book-reader" aria-hidden="true"></i> Find out more about {props.databaseType} {planOperation} {planOption}</button>
        </div>
    );
}

export default KnowledgeBasePlanButton;
