import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Types.
import Action from '../../../types/Action';
import AlertActions from "../../../types/Alerts/AlertActions";

// Constants.
import { ACTIONS } from '../../../component/Constants';

// Third-party packages.
import { useForm } from "react-hook-form";

// Validators
import { getNameAlreadyUsed } from "../../../helpers/validators";
import { getAction } from "../../../helpers/utils";
import api from "../../../api/Base";

// TODO : remove this after api integration
const emptyState = {
    name: '',
    address: '',
    type: 'Email',
    enabled: true
}

function Detail(props: { actionId: string, alertActionId?: string, alertActions: AlertActions[], refresh: Function  }) {
    const { register, handleSubmit, getValues, formState: { errors } } = useForm<AlertActions>({
        mode: "onBlur",
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    });

    const [action, setAction] = useState<Action>(ACTIONS.CREATE);
    const [disabled, setDisabled] = useState<boolean>(props.actionId === ACTIONS.DELETE.type);
    const history = useHistory();

    // search for the edited item or set to initial state value
    const editDetails = (props.alertActions.length && props.alertActions.find(action => action.id === props.alertActionId)) || emptyState

    useEffect(() => {
        getAction(props.actionId, setAction, setDisabled);
    }, [props.actionId]);

    const onSubmit = async (data: AlertActions) => {
        let result: any = undefined;
        let url = `/alert/action`;
        switch (action.type) {
            case 'create':
                // Create New Action.
                result = await api.post(url, data);
                break;

            case 'update':
                // Update Action.
                result = await api.put(url, data)
                break;

            case 'delete':
                // Delete Action.
                result = await api.delete(url, { data: data });
                break;

            default:
                // Unknown action requested.
                console.error('Unknown action requested', action);
                break;
        }
        if (result && result.status === 200) {
            history.push('/admin/alert-actions');
            props.refresh()
        }
    }

    // const isValid = Object.keys(errors).length === 0

    return (
        <div className="col">
            <div className="card">
                <div className="card-header">
                    <i className="fal fa-location-circle fa-fw mr-10" aria-hidden="true"/>
                    {action.title} Alert Action
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p>Please complete the required details below:</p>

                        <div className="row g-0 form">

                            {/* Name */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="name" className="reminder">Alert action name</label>
                                </span>
                            </div>

                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="text"
                                    id="name"
                                    maxLength={250}
                                    {...register("name", { required: "Please fill in this field",
                                        validate: (value) => getNameAlreadyUsed(props.alertActionId, value, props.alertActions)
                                    })}
                                    className={`form-control ${errors.name ? 'is-invalid' : getValues('name') ? 'is-valid' : ''}`}
                                    defaultValue={editDetails.name}
                                    disabled={disabled}
                                    title={errors.name?.message}
                                    data-lpignore={true}
                                />
                            </div>
                            <div className="w-100"/>

                            {/*Action type */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="type" className="reminder">Action type</label>
                                </span>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <select
                                    id="type"
                                    autoFocus
                                    {...register("type")}
                                    className={`form-control`}
                                    defaultValue={editDetails.type}
                                    disabled={disabled}
                                    data-lpignore={true}
                                >
                                    <option value='Email'>Email</option>
                                </select>
                            </div>
                            <div className="w-100"/>

                            {/* Email */}
                            <div className="col-xs-12 col-md-3">
                                <span className="required">
                                    <label htmlFor="address" className="reminder">Recipient email address</label>
                                </span>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-4">
                                <input
                                    type="email"
                                    id="address"
                                    {...register("address", {
                                        required : "Please enter a valid email",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })}
                                    className={`form-control ${errors.address ? 'is-invalid' : getValues('address') ? 'is-valid' : ''}`}
                                    maxLength={250}
                                    title={errors.address?.message}
                                    defaultValue={editDetails.address}
                                    disabled={disabled}
                                    data-lpignore={true}
                                />
                            </div>
                            <div className="w-100"/>

                            {/* enabled */}
                            <div className="col-xs-12 col-md-3">
                                <label className="reminder">Action enabled</label>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-3">
                                <div className='form-check form-switch'>
                                    <input
                                        type="checkbox"
                                        id="enabled"
                                        {...register("enabled")}
                                        className="form-check-input"
                                        disabled={false}
                                        defaultChecked={editDetails.enabled}
                                        data-lpignore={true}
                                    />
                                </div>
                            </div>
                            <div className="w-100"/>

                            {/* Actions */}
                            <div className="col-12 actions">
                                <Link to="/admin/alert-actions/" role="button" className="btn btn-secondary"><i
                                    className="fal fa-times-circle fa-fw fa-sm"/><span>Cancel</span></Link>
                                <button type="submit" className={`btn btn-${action.variant}`}><i
                                    className={`${action.icon} fa-sm`}/><span>{action.title}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <p>*Remember to setup your email SMTP server or other action type under <Link to="/admin/integrations/" role="button"><span>integrations</span></Link></p>
        </div>
    )
}

export default Detail;