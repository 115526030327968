import React from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route, NavLink, Redirect } from 'react-router-dom';

// Third-party packages.
import Helmet from 'react-helmet';


// Types.
import InstanceTarget from '../../types/instance/InstanceTarget';
import Period from '../../types/Period';
import EventType from '../../types/instance/EventType';
import HostTarget from '../../types/host/HostTarget';

// Components.
import PeriodBreadcrumb from '../../component/PeriodBreadcrumb';
import Breadcrumb from "../../container/breadcrumb";
import HeaderActions from "../../container/header_actions";
import TimeShift from "../../component/TimeShift";
import TabbedMenu from "../../container/tabbed-menu";
import TextAndPlan from "./tabs/TextAndPlan";
import ConditionalRender from "../../helpers/ConditionalRender";
import FilterBar from "../../component/FilterBar";
import GenericActivity from "./tabs/GenericActivity";

interface IStatementDetailProps {
    instanceId: number,
    batchId?: string,
    batchStatementId?: string,
    statementId?: string,
    instances: InstanceTarget[],
    period: Period,
    hosts: HostTarget[],
    eventTypes: EventType[],
    toggleMenu: Function,
    applyPeriod: Function,
    setFilterOptions: Function
}


const GenericStatement = (props: IStatementDetailProps) => {
    const {instanceId, batchId, statementId, instances, period, batchStatementId} = props;
    const getInstance: InstanceTarget[] = instances.filter(instance => instance.id === Number(instanceId))
    const instance = getInstance[0]
    // const [chartPlanPlotLines, setChartPlanPlotLines] = useState<ChartPlotLine[]>([]);

    if (instance === undefined) {
        return (
            <React.Fragment>No Instance</React.Fragment>
        )
    }

    return (
        <div id="content">
            <Helmet>
                <title>{instance.name} - Statement View - DBmarlin</title>
                <meta name="description"
                      content="Instance statement performance view, showing key performance metrics and detailed executed statement and waits information"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb instanceId={instance.id}
                            type="instances"
                            heading={instance.name}>
                    <Link to="/instances">Analysis</Link>
                    <Link to="/instances">Instances</Link>
                    <Link to={`/instances/${instanceId}/activity`}>{instance.name}</Link>
                    <Link to={`/instances/${instanceId}/activity`}>Activity</Link>
                    <NavLink to={`/hosts/`}
                             className="current-breadcrumb-link pe-none">{statementId || batchId}</NavLink>
                    <PeriodBreadcrumb/>
                </Breadcrumb>

                <HeaderActions period={period} toggleMenu={props.toggleMenu} refresh={() => {
                }}
                               applyPeriod={props.applyPeriod}/>
            </div>


            <div className="loader"/>
            <TabbedMenu>
                <TimeShift/>

                <NavLink exact to={`/instances/${instanceId}/statement-activity/${statementId}/text`}
                         activeClassName="active">
                    SQL Text and Plans
                </NavLink>
                <NavLink exact to={`/instances/${instanceId}/statement-activity/${statementId}`}
                         activeClassName="active">
                    SQL Performance
                </NavLink>
            </TabbedMenu>

            {/* Filters */}
            <ConditionalRender if={props.setFilterOptions}>
                {props.setFilterOptions &&
                    <FilterBar period={period} setFilterOptions={props.setFilterOptions}/>}
            </ConditionalRender>

            <Switch>
                <Route
                    exact
                    path={"/instances/:instanceId/statement-activity/:statementId/text"}
                    render={() => <TextAndPlan
                        setExecutionPlanOptions={() => {}}
                        batchId={batchId}
                        batchStatementId={batchStatementId}
                        instance={instance}
                        statementId={statementId}
                    />}
                />

                <Route
                    exact
                    path={"/instances/:instanceId/statement-activity/:statementId"}
                    render={() => <GenericActivity
                        instanceId={instance.id}
                        instanceType={instance.type}
                        applyPeriod={props.applyPeriod}
                        // @ts-ignore
                        statementId={statementId}
                        apiString='sqlhash'
                    />}
                />

                <Redirect to="/not-found" />

            </Switch>
        </div>
    )
}

GenericStatement.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    applyPeriod: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired
};

export default GenericStatement;