import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLicences } from "../../../context/LicenceContext";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

// Third-party.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HC_brokenAxis from "highcharts/modules/broken-axis";
import Boost from "highcharts/modules/boost";

// Context
import { InstanceContext } from '../../../context/InstanceContext';

// Types.
import Statement from '../../../types/instance/Statement';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import Time from '../../../types/instance/Time';
import Execution from '../../../types/instance/Execution';
import Wait from '../../../types/instance/Wait';
import ActivityExecution from "../../../types/instance/ActivityExecution";
import Batch from "../../../types/instance/Batch";

// Constants.
import { CHART_DATA_OPTION, CHART_DATA_TYPE } from '../../Constants';
import { DATA_LOADED, DATA_LOADING, INSTANCE_TYPE_COCKROACHDB } from '../../Constants';
import { INSTANCE_TYPE_ORACLE, INSTANCE_TYPE_SQLSERVER } from '../../Constants';
import { CHART_COLOURS_STATEMENTS } from '../../Constants';

// Helpers
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import ConditionalRender from "../../../helpers/ConditionalRender";
import Helper from "../../../helpers/Helper";
import api from "../../../api/Base";

HC_brokenAxis(Highcharts);
Boost(Highcharts);

function TimeChart(props: {
    dataOption: CHART_DATA_OPTION,
    instanceId: number,
    batchId?: string,
    statementId?: string,
    statements: Statement[],
    plotBands: ChartPlotBand[],
    plotLines: ChartPlotLine[],
    waits: Wait[],
    applyPeriod: Function,
    batches: Batch[],
    filters: any
}) {
    const {instances} = useContext(InstanceContext);
    const timeRangeContext = useContext(TimeRangeContext)
    const {licences} = useLicences();
    const history = useHistory();

    const [loading, setLoading] = useState<number>(DATA_LOADING);

    const [timeSeries, setTimeSeries] = useState<number[][]>([]);
    const [waitSeries, setWaitSeries] = useState<number[][]>([]);
    const [statementSeries, setStatementSeries] = useState<number[][]>([]);
    const [batchSeries, setBatchSeries] = useState<number[][]>([]);
    const [executionSeries, setExecutionSeries] = useState<number[][]>([]);

    let batchId: string = '',
        statementId: string = '';

    if (props.batchId) {
        batchId = props.batchId;
    }

    if (props.statementId) {
        statementId = props.statementId;
    }

    const timePeak = useMemo(() => {
        return Helper.getPeakValue(timeSeries.map(i => i[1]))
    }, [timeSeries])

    const executionPeak = useMemo(() => {
        return Helper.getPeakValue(executionSeries.map(i => i[1]))
    }, [executionSeries])

    void useMemo(async() => {
        if (props.dataOption === CHART_DATA_OPTION.STATEMENTS) {
            setLoading(DATA_LOADING);
            const statementPromises = []
            let statementSeries: any[] = [];

            // Get the top individual statements for the period.
            for (let index = 0; index < props.statements.slice(0, 9).length; index++) {
                let statement: Statement = props.statements[index];
                if (statement.sqlhash !== null) {
                    statementPromises.push(api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&sqlhash=${statement.sqlhash}${props.filters}`))
                }
            }

            const responsesStatements = await Promise.all(statementPromises)
            responsesStatements.forEach((response: { data: Time[]; }, index: number) => {
                let statement: Statement = props.statements[index];
                let statementData: number[][] = [];

                statementData = statementData.concat(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.waittime]
                }));

                // Add the chart series.
                statementSeries.push({
                    name: ((statement.sqlhash === null) ? '-' : statement.sqlhash),
                    color: statement.color,
                    cursor: 'pointer',
                    type: 'column',
                    data: statementData,
                    url: `/instances/${props.instanceId}/activity/statement/${statement.sqlhash}?${timeRangeContext.getTimeRangeQueryString()}`,
                    zIndex: statementSeries.length
                });
            })

            setStatementSeries(statementSeries);
            setLoading(DATA_LOADED);
        }
    }, [timeRangeContext, props.instanceId, props.statements, props.dataOption])

    void useMemo(async() => {
        if (props.dataOption === CHART_DATA_OPTION.BATCHES) {
            setLoading(DATA_LOADING);
            const batchPromises = []
            let batchSeries: any[] = [];

            // Get the top individual batches for the period.
            for (let index = 0; index < props.batches.slice(0, 9).length; index++) {
                let batch: Batch = props.batches[index];
                if (batch.batchsqlhash !== null) {
                    batchPromises.push(api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&batchsqlhash=${batch.batchsqlhash}${props.filters}`))
                }
            }

            const batchResponses = await Promise.all(batchPromises)
            batchResponses.forEach((response: { data: Time[]; }, index: number) => {
                let batch: Batch = props.batches[index];
                let batchData: number[][] = [];

                batchData = batchData.concat(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.waittime]
                }));

                // Add the chart series.
                batchSeries.push({
                    name: ((batch.batchsqlhash === null) ? '-' : batch.batchsqlhash),
                    color: CHART_COLOURS_STATEMENTS?.[index] ?? '',
                    data: batchData,
                    type: 'column',
                    tooltip: {
                        valueSuffix: 'ms',
                    },
                    url: `/instances/${props.instanceId}/activity/batch/${batch.batchsqlhash}?${timeRangeContext.getTimeRangeQueryString()}`,
                    zIndex: batchSeries.length
                });
            })
            setBatchSeries(batchSeries);
            setLoading(DATA_LOADED);
        }
    }, [timeRangeContext, props.instanceId, props.batches, props.dataOption])

    void useMemo(async() => {
        if (props.dataOption === CHART_DATA_OPTION.WAITS) {
            setLoading(DATA_LOADING);

            const waitsPromises = []
            let waitSeries: any[] = []

            let batchApi: string = '',
                statementApi: string = '';

            if (batchId.length > 0) {
                batchApi = `&batchsqlhash=${batchId}`;
            }

            if (statementId.length > 0) {
                statementApi = `&sqlhash=${statementId}`;
            }

            // Get the top individual waits for the period.
            for (let index = 0; index < props.waits.slice(0, 9).length; index++) {
                let wait: Wait = props.waits[index];
                waitsPromises.push(api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&waitevent=${encodeURIComponent(wait.waitevent)}&id=${props.instanceId}${batchApi}${statementApi}${props.filters}`))
            }

            const responses = await Promise.all(waitsPromises)
            responses.forEach((response: { data: Time[]; }, index: number) => {
                let wait: Wait = props.waits[index];
                let waitData: number[][] = [];

                waitData = waitData.concat(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.waittime]
                }));

                // Add the chart series.
                waitSeries.push({
                    name: wait.waitevent,
                    color: wait.color,
                    type: 'column',
                    data: waitData,
                    tooltip: {
                        valueSuffix: 'ms',
                    },
                    zIndex: 1
                });
            })

            setWaitSeries(waitSeries);
            setLoading(DATA_LOADED);
        }
    }, [batchId, statementId, timeRangeContext, props.instanceId, props.waits, props.dataOption])

    void useMemo(async() => {
        if (props.dataOption === CHART_DATA_OPTION.ACTIVITY) {
            setLoading(DATA_LOADING);
            let batchApi: string = '',
                statementApi: string = '';

            if (batchId.length > 0) {
                batchApi = `&batchsqlhash=${batchId}`;
            }

            if (statementId.length > 0) {
                statementApi = `&sqlhash=${statementId}`;
            }

            // Get time for the period.
            api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&id=${props.instanceId}&limit=${process.env.REACT_APP_API_LIMIT}&${batchApi}${statementApi}${props.filters}`)
                .then((response: { data: Time[]; }) => {
                    // @ts-ignore
                    setTimeSeries(response.data.map(item => {
                        return [getConvertedTimeToUTC(item), item.waittime]
                    }));
                    setLoading(DATA_LOADED);
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve activity time.', error);
                })
        }
    }, [batchId, timeRangeContext, props.instanceId, statementId, props.dataOption])

    void useMemo(() => {
        let showExecutions: boolean = !(batchId.length && statementId.length)
        let batchApi: string = '',
            statementApi: string = '';

        if (batchId.length > 0) {
            batchApi = `&batchsqlhash=${batchId}`;
        }

        if (statementId.length > 0) {
            statementApi = `&sqlhash=${statementId}`;
        }

        // Get the current instance.
        const matchedInstances = instances.filter(instance => Number(instance.id) === Number(props.instanceId));

        if (matchedInstances.length > 0) {
            switch (matchedInstances[0].type) {
                case INSTANCE_TYPE_ORACLE:
                case INSTANCE_TYPE_SQLSERVER:
                case INSTANCE_TYPE_COCKROACHDB:
                    showExecutions = true;
                    break;

                default:
                    break;
            }
        }

        if (showExecutions) {
            if (props.filters) {
                api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=executions&limit=${process.env.REACT_APP_API_LIMIT}&id=${props.instanceId}${batchApi}${statementApi}${props.filters}`)
                    .then((response: { data: ActivityExecution[]; }) => {
                        // @ts-ignore
                        setExecutionSeries(response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.executions]
                        }));
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve executions statistic.', error);
                    })
            } else {
                api.get(`statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=executions&limit=${process.env.REACT_APP_API_LIMIT}&id=${props.instanceId}${batchApi}${statementApi}${props.filters}`)
                    .then((response: { data: Execution[]; }) => {
                        // @ts-ignore
                        setExecutionSeries(response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.sum]
                        }));
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve executions statistic.', error);
                    })
            }
        }
    }, [batchId, statementId, timeRangeContext, props.instanceId, instances])

    const timeChart = useMemo(() => {
        if (props.dataOption === CHART_DATA_OPTION.ACTIVITY) {
            const timeChartOptions = chartOptionsGranularity(props.applyPeriod, [
                {
                    type: 'column', data: timeSeries, name: 'Time Spent', zIndex: 1
                },
                ...(!props.filters
                        ? [
                            {
                                type: 'spline',
                                minHeight: 3,
                                data: executionSeries,
                                name: 'Executions',
                                zIndex: 1,
                                yAxis: 1
                            },
                        ]
                        : []
                ),
            ], timePeak, {
                credits: {enabled: highchartsCredits(licences)},
                legend: true,
                timeRangeContext: timeRangeContext,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tooltip: {
                    formatter: function () {
                        return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                    },
                },
            })
            return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts}
                                    options={timeChartOptions}/>;
        }

        return null
    }, [executionSeries, timeSeries, timePeak, props.plotBands, props.plotLines, props.dataOption, licences]);

    const waitsChart = useMemo(() => {
        if (props.dataOption === CHART_DATA_OPTION.WAITS) {
            const waitsChartOptions = chartOptionsGranularity(props.applyPeriod,[...waitSeries,
                ...(!props.filters
                        ? [
                            {
                                type: 'spline',
                                minHeight: 3,
                                data: executionSeries,
                                name: 'Executions',
                                zIndex: 999,
                                yAxis: 1,
                            },
                        ]
                        : []
                )], timePeak, {
                credits: {enabled: highchartsCredits(licences)},
                legend: true,
                timeRangeContext: timeRangeContext,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tooltip: {
                    formatter: function () {
                        return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                    },
                },
            })
            return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={waitsChartOptions}/>;
        }

        return null

    }, [executionSeries, waitSeries, timePeak, props.plotBands, props.plotLines, props.dataOption, licences]);

    const statementsChart = useMemo(() => {
        if (props.dataOption === CHART_DATA_OPTION.STATEMENTS) {
            const statementsChartOptions = chartOptionsGranularity(props.applyPeriod,[...statementSeries, ...(!props.filters
                    ? [
                        {
                            type: 'spline',
                            minHeight: 3,
                            data: executionSeries,
                            name: 'Executions',
                            zIndex: 999,
                            yAxis: 1,
                        },
                    ]
                    : []
            )], timePeak, {
                credits: {enabled: highchartsCredits(licences)},
                legend: true,
                timeRangeContext: timeRangeContext,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tooltip: {
                    formatter: function () {
                        return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                    },
                },
            })
            // @ts-ignore
            statementsChartOptions.plotOptions.series.events = {
                click: function () {
                    const column: any = this;
                    if (column.name !== 'Executions') {
                        history.push(`/instances/${props.instanceId}/activity/statement/${column.name}?${timeRangeContext.getTimeRangeQueryString()}`);
                    }
                }
            }

            return <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                    options={statementsChartOptions}/>;
        }

        return null

    }, [props.plotBands, props.plotLines, props.instanceId, props.dataOption, timeRangeContext, executionSeries, statementSeries, history, timePeak, licences]);

    const batchesChart = useMemo(() => {
        if (props.dataOption === CHART_DATA_OPTION.BATCHES) {
            const batchesChartOptions = chartOptionsGranularity(props.applyPeriod,[...batchSeries, ...(!props.filters
                    ? [
                        {
                            type: 'spline',
                            minHeight: 3,
                            data: executionSeries,
                            name: 'Executions',
                            zIndex: 999,
                            yAxis: 1,
                        },
                    ]
                    : []
            )], timePeak, {
                credits: {enabled: highchartsCredits(licences)},
                legend: true,
                timeRangeContext: timeRangeContext,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tooltip: {
                    formatter: function () {
                        return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
                    },
                },
            })
            // @ts-ignore
            batchesChartOptions.plotOptions.series.events = {
                click: function () {
                    const column: any = this;
                    if (column.name !== 'Executions') {
                        history.push(`/instances/${props.instanceId}/activity/batch/${column.name}${timeRangeContext.getTimeRangeQueryString}`);
                    }
                }
            }

            return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={batchesChartOptions}/>;
        }

        return null
    }, [executionSeries, batchSeries, history, timeRangeContext, timePeak, props.dataOption, props.plotLines, props.plotBands, licences, props.instanceId]);

    /* 
        Note: From reading various posts and articles, passing new options doesn't work as the chart is already rendered, and third-party React components/wrappers 
        for Highcharts actually destroy and recreate the Highchart object as they can't get it to work either.  Ultimately, we want to use "chart.update".
    */
    return (
        <React.Fragment>
            <ConditionalRender if={loading === DATA_LOADING}>
                <div className="w-100 text-center time-chart-center text-muted mt-3">
                    <div className="loader spinner chartSpinner">
                    </div>
                    <p>
                        Loading data...
                    </p>
                </div>
            </ConditionalRender>
            <ConditionalRender if={loading === DATA_LOADED}>
                    <span className="peak">
                        {(timePeak === Infinity || timePeak === -Infinity) ? (
                            <React.Fragment>Peak Time: -</React.Fragment>
                        ) : (
                            <React.Fragment>Peak Time: {Helper.getTimeInEnglish(timePeak)}</React.Fragment>
                        )}
                        {batchId.length === 0 && (
                            <React.Fragment>,
                                Executions: {executionPeak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</React.Fragment>
                        )}
                    </span>
                    <br/>
                    <div className={((props.dataOption === CHART_DATA_OPTION.ACTIVITY) ? 'mt-35' : 'd-none')}>
                        {timeChart}
                    </div>
                    <div className={((props.dataOption === CHART_DATA_OPTION.BATCHES) ? 'mt-35' : 'd-none')}>
                        {batchesChart}
                    </div>
                    <div className={((props.dataOption === CHART_DATA_OPTION.STATEMENTS) ? 'mt-35' : 'd-none')}>
                        {statementsChart}
                    </div>
                    <div className={((props.dataOption === CHART_DATA_OPTION.WAITS) ? '' : 'd-none')}>
                        {waitsChart}
                    </div>
            </ConditionalRender>
        </React.Fragment>
    );
}

TimeChart.defaultProps = {
    statements: []
};

export default TimeChart;
