import HostTarget from "../../types/host/HostTarget";
import ConditionalRender from "../../helpers/ConditionalRender";
import InstanceTarget from "../../types/instance/InstanceTarget";

function Index(props: { entityType: string, hostId: number, setHostId: Function, setStatistic: Function, datasourceId: number, setDatasourceId: Function, instances: InstanceTarget[], hosts: HostTarget[], disabled: boolean }) {
    const {
        entityType,
        hostId,
        setHostId,
        datasourceId,
        setDatasourceId,
        hosts,
        instances,
        setStatistic,
        disabled
    } = props


    const setHostAndDataSourceId = (hostValue: string, dattaSourceValue: string) => {
        setHostId(parseInt(hostValue))
        setDatasourceId(parseInt(dattaSourceValue))
        setStatistic('')
    }

    return (
        <>
            <ConditionalRender if={entityType === 'host'}>
                {/* Host Id */}
                <div className="col-xs-12 col-md-3">
                    <span className="required">
                        <label htmlFor="scope" className="reminder">Scope</label>
                    </span>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3">
                    <select
                        id="hostid"
                        autoFocus
                        className={`form-control`}
                        value={hostId.toString()}
                        disabled={disabled}
                        onChange={(e) => setHostAndDataSourceId(e.target.value, '0')}
                        data-lpignore={true}
                    >
                        <option value='0'>All Hosts</option>
                        {hosts.map((host, i) =>
                            <option key={i} value={host.id}>{host.name}</option>
                        )}
                    </select>
                </div>
                <div className="w-100"/>
            </ConditionalRender>

            <ConditionalRender if={entityType !== 'host'}>
                {/*Instance Id*/}
                <div className="col-xs-12 col-md-3">
                    <span className="required">
                        <label htmlFor="scope" className="reminder">Scope</label>
                    </span>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3">
                    <select
                        id="datasourceid"
                        autoFocus
                        className={`form-control`}
                        value={datasourceId.toString()}
                        disabled={disabled}
                        onChange={(e) => setHostAndDataSourceId('0', e.target.value)}
                        data-lpignore={true}
                    >
                        <option value='0'>All Instances</option>
                        {instances.map((instance, i) =>
                            <option key={i} value={instance.id}>{instance.name}</option>
                        )}
                    </select>
                </div>
                <div className="w-100"/>
            </ConditionalRender>
        </>
    )

}


export default Index;