export const SQL_STATISTICS_API_VERSION = parseInt(process.env.REACT_APP_SQL_STATISTICS_API_VERSION ?? '', 10) || 2

export const DEFAULT_TABLE_METRICS = ['duration','executions']

export const TIME_METRICS = ['duration']

export const ERROR_MESSAGE_DATES = {
    INVALID: {
        TITLE: 'Check dates',
        TEXT: 'Please make sure end dates are after the start dates'
    }
}