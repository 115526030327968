import { useMemo, useState } from 'react';

// Types.
import Period from '../../../types/Period';
import InstanceTarget from '../../../types/instance/InstanceTarget';
import Wait from '../../../types/instance/Wait';
import WaitsDataTable from '../../../types/instance/tables/WaitsDataTable';

// Components.
import WaitsTable from '../table/WaitsTable';

function Waits(props: { loading: boolean, waits: Wait[], instance: InstanceTarget, instanceId: number, period: Period, totalInstanceTime: number, setFilterOptions: Function, showByDefault?: boolean }) {
    const [waitsDataTable, setWaitsDataTable] = useState<WaitsDataTable[]>([]);

    useMemo(() => {

        let data: WaitsDataTable[] = [];

        // Build top waits pie chart.
        for (let index = 0; index < props.waits.length; index++) {

            data.push({
                id: index,
                color: props.waits[index].color,
                waitevent: props.waits[index].waitevent,
                waittime: props.waits[index].waittime
            });
        }

        setWaitsDataTable(data);

    }, [props.waits]);

    return (
        <div id="waits" className={`tab-pane fade ${props.showByDefault ? 'active show' : ''}`} role="tabpanel" aria-labelledby="waits-tab">
            <WaitsTable loading={props.loading} period={props.period} instance={props.instance} instanceId={props.instanceId} instanceTime={props.totalInstanceTime} waitsDataTable={waitsDataTable} setFilterOptions={props.setFilterOptions}/>
        </div>
    );
}

export default Waits;
