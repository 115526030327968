import React, { useContext, useEffect, useMemo, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// Types.
import CombinedChanges from "../../types/instance/CombinedChanges";
import EventsDataTable from "../../types/instance/tables/EventsDataTable";

// Constants.

// Helpers.
import { highchartsCredits } from "../../helpers/utils";
import Helper from "../../helpers/Helper";
import { chartOptionsGranularity } from "../../helpers/chartOptionsIncreasedGranularity";

// Components.
import HostTarget from "../../types/host/HostTarget";
import EventType from "../../types/instance/EventType";
import InstanceTarget from "../../types/instance/InstanceTarget";
import {
    CHART_DATA_TYPE,
    COLOUR_ALERTS,
    COLOUR_CHANGES,
    COLOUR_EVENTS,
    DATA_FAILED,
    DATA_INITIALISING, DATA_LOADED,
    DATA_LOADING
} from "../../component/Constants";
import WidgetCard from "../../component/instance/widget";
import EventsTable from "../../component/instance/table/EventsTable";

// Context
import { TimeRangeContext } from "../../context/TimeRangeContext";
import { LicenceContext } from "../../context/LicenceContext";
import api from "../../api/Base_v2";

// DayJS plugins - note, the position of these in this file are important.
dayjs.extend(isoWeek.default);


function EventHistoryContainer(props: {
    instances: InstanceTarget[],
    hosts: HostTarget[],
    eventTypes: EventType[],
    allEvents?: any[],
    instance?: InstanceTarget
    host?: HostTarget,
    applyPeriod: Function
}) {

    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [combinedChanges, setCombinedChanges] = useState<CombinedChanges[]>([])

    const [peakChanges, setPeakChanges] = useState<number>(0)
    const [peakEvents, setPeakEvents] = useState<number>(0)
    const [peakAlerts, setPeakAlerts] = useState<number>(0)

    // const [plotBands, setPlotBands] = useState<ChartPlotBand[]>([])
    const [eventsDataTable, setEventsDataTable] = useState<EventsDataTable[]>([])

    const [changeChartData, setChangeChartData] = useState<number[][]>([])
    const [eventChartData, setEventChartData] = useState<number[][]>([])
    const [alertChartData, setAlertChartData] = useState<number[][]>([])
    // const [eventChartDataCombined, setEventChartDataCombined] = useState<{ custom: number, alert: number, change: number }[]>([])

    const timeRangeContext = useContext(TimeRangeContext)
    const licences = useContext(LicenceContext)
    const allEvents = props.allEvents
    const getData = async() => {
        await getEvents();
    }

    useEffect(() => {
        setLoading(DATA_LOADING)
        void getData();
    }, [timeRangeContext]);

    const interval = Helper.getInterval(timeRangeContext.timeRange?.from, timeRangeContext.timeRange?.to, timeRangeContext.timeRange?.interval)
    const getEventsData = async() => {
        let url = `event?${timeRangeContext.getTimeRangeQueryString()}&sort=timeslice+desc&limit=50000`
        url += props.instance ? `&datasourceid=${props.instance.id}` : ''
        url += props.host ? `&hostid=${props.host.id}` : ''

        const response = await api.get(url)
        return response ? response.data : []
    }

    const getEvents = async() => {
        let events = []

        try {
            // don't fetch data again if already exist
            const data = allEvents?.length ? allEvents : await getEventsData()

            events = data.map((event: any) => {
                if (event.event === 'change') {
                    event.instanceName = props.instances.find(instance => instance.id === event.id)?.name || ''
                }
                if (event.event === 'custom') {
                    event.databaseTargetName = props.instances.find(instance => instance.id === event.id)?.name || ''
                    if (event.endDateTime !== null) {
                        event.isPlotband = true
                    }
                }
                return event
            });
        } catch (error: any) {
            console.error('Failed to retrieve event data.', error);
            setLoading(DATA_FAILED);
        }

        const combinedEvents = events.map((event: any, index: number) => {
            return {
                ...event,
                id: index,
                instanceId: event.datasourceid,
                timeslice: event.timeslice,
            }
        })

        combinedEvents.sort(function compare(a: any, b: any) {
            const aa: number = dayjs(a.timeslice).valueOf();
            const bb: number = dayjs(b.timeslice).valueOf();
            return bb - aa;
        });


        const alertsArray = events.filter((e: any) => e.event === 'alert')
        const eventsArray = events.filter((e: any) => e.event === 'custom')
        const changesArray = events.filter((e: any) => e.event === 'change')

        const alertsData = Helper.groupByInterval(alertsArray, interval);
        const eventsData = Helper.groupByInterval(eventsArray, interval);
        const changesData = Helper.groupByInterval(changesArray, interval);

        let eventsDataTable: EventsDataTable[] = [];
        combinedEvents.forEach((event: any) => {
            const matchedInstance = props.instances.find(instance => Number(instance.id) === Number(event.instanceId))
            const matchedHost = props.hosts.find(host => host.name === event.entity)
            let changeType: string,
                htmlIconCode: string = '',
                detailsUrl: string = '',
                color: string = '',
                title: string = '',
                details: string;

            if (event.event === 'custom') {
                const matchedEventTypes = props.eventTypes.filter(eventType => eventType.title === event.type)

                if (matchedEventTypes.length > 0) {
                    if (matchedEventTypes[0].htmlIconCode !== undefined) {
                        htmlIconCode = matchedEventTypes[0].htmlIconCode;
                    }
                }

                if (event.detailsUrl !== undefined) {
                    detailsUrl = event.detailsUrl;
                }

                color = event.colourCode;
                title = event.name || event.details;
            }
            const dataSourceType = event.object === 'datasource' ? 'instance' : event.object
            changeType = `${dataSourceType} ${event.type}`
            details = event.name;

            if (event.event === 'custom') {
                changeType = event.type
                details = event.description
            }

            if (!matchedInstance?.name && matchedHost?.name) {
                event.instanceId = matchedHost.id
                event.isHost = true
            }

            eventsDataTable.push({
                id: event.id,
                instanceId: event.instanceId,
                event: event.event,
                changeType,
                changeFrom: event.oldvalue,
                changeTo: event.newvalue,
                htmlIconCode,
                instanceName: matchedInstance?.name || matchedHost?.name || 'Unknown',
                detailsUrl,
                color,
                title,
                details,
                isHost: !!event.isHost,
                timeslice: event.timeslice,
                startedEnded: event.startedended,
                statistic: event.statistic,
                threshold: event.threshold,
                decreasing: event.decreasing,
                units: event.units,
                timesliceEnd: ((event.isPlotband === true) ? event.endDateTime : undefined)
            });
        });

        const peakChanges = changesData.reduce((max, current) => Math.max(max, current[1]), 0);
        const peakEvents = eventsData.reduce((max, current) => Math.max(max, current[1]), 0);
        const peakAlerts = alertsData.reduce((max, current) => Math.max(max, current[1]), 0);

        const uniqueTimeslices  = Helper.extractUniqueTimeslices(alertsData, eventsData, changesData);
        // const plotBands = Helper.getChartPlotBandsCombinedV2(combinedEvents, period.ui.current.chartCategories);

        setChangeChartData(Helper.populateMissingTimeslices(changesData, uniqueTimeslices))
        setEventChartData(Helper.populateMissingTimeslices(eventsData, uniqueTimeslices))
        setAlertChartData(Helper.populateMissingTimeslices(alertsData, uniqueTimeslices))
        // setEventChartDataCombined(eventChartDataCombined)
        setCombinedChanges(combinedEvents)
        setPeakChanges(peakChanges)
        setPeakEvents(peakEvents)
        setPeakAlerts(peakAlerts)
        // setPlotBands(plotBands)
        setEventsDataTable(eventsDataTable)
        setLoading(DATA_LOADED)
    }

    const chart = useMemo(() => {
        const chartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                color: COLOUR_CHANGES,
                type: 'column',
                data: changeChartData,
                name: 'Changes',
            },
            {
                color: COLOUR_EVENTS,
                type: 'column',
                data: eventChartData,
                name: 'Custom Events',
            },
            {
                color: COLOUR_ALERTS,
                type: 'column',
                data: alertChartData,
                name: 'Alerts',
            }
        ], Math.max(peakChanges, peakEvents, peakAlerts), {
            credits: { enabled: highchartsCredits(licences.licences) },
            legend: true,
            timeRangeContext: timeRangeContext,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
                }
            },
        }, 200)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={chartOptions}/>;

    }, [timeRangeContext, licences, peakChanges, peakEvents, peakAlerts, changeChartData, alertChartData, eventChartData, combinedChanges]);

    const totalChanges = combinedChanges.filter((event: any) => event.event === 'change').length
    const totalEvents = combinedChanges.filter((event: any) => event.event === 'custom').length
    const totalAlerts = combinedChanges.filter((event: any) => event.event === 'alert').length

    return (
        <React.Fragment>
            {/* Metric Widgets - Instances */}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
                <WidgetCard cardTooltipText={"The total auto-detected changes count"} iconClassName={"changes"}
                            name={"Changes"}
                            values={[{
                                'icon': '',
                                value: totalChanges,
                                tooltip: 'Total auto-detected change count'
                            }]}/>
                <WidgetCard cardTooltipText={"The total custom events count"} iconClassName={"calendar"}
                            icon="fa-calendar" name={"Custom Events"}
                            values={[{ 'icon': '', value: totalEvents, tooltip: 'Total custom event count' }]}/>
                <WidgetCard cardTooltipText={"The total alerts count"} iconClassName={"alerts"}
                            icon="fa-exclamation-triangle" name={"Alerts"}
                            values={[{ 'icon': '', value: totalAlerts, tooltip: 'Total triggered alerts count' }]}/>
            </div>

            {/* Charts */}
            <div className="row">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                            Changes and events over time
                            <span className="badge bg-info"
                                  data-tip="Total change count">{eventsDataTable.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseCharts" aria-expanded="false" aria-controls="collapseCharts"/>
                        </div>
                        <div id="collapseCharts" className="card-body collapse show p-0">
                            <div className="row">
                                <div className="col p-3">
                                    <h4>Changes and Events<span
                                        className="peak">Peak Changes: {peakChanges}, Events: {peakEvents}, Alerts: {peakAlerts}</span>
                                    </h4>
                                    {chart}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Change History */}
            <div className="row">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-exchange fa-fw" aria-hidden="true"/>
                            Change and event history
                            <span className="badge bg-info"
                                  data-tip="Total change count">{combinedChanges.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseTable" aria-expanded="false" aria-controls="collapseTable"/>
                        </div>
                        <div id="collapseTable" className="card-body collapse show">
                            <EventsTable loading={loading} eventsDataTable={eventsDataTable}/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EventHistoryContainer;