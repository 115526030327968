// Third-party packages.
import React, {useCallback, useEffect, useState} from "react";
import StatisticsDataFactory from "./statistics-data-factory";

// Apis.
import Breadcrumb from "../breadcrumb";
import { Link, NavLink } from "react-router-dom";
import HeaderActions from "../header_actions";
import PageContent from "../page_content"
import PeriodBreadcrumb from "../../component/PeriodBreadcrumb";
import InstanceApi from "../../api/instance/InstanceApi";

type StatisticsWrapperProps = {
    type: string | null,
    [key: string]: any
}

const StatisticsWrapper = (props: StatisticsWrapperProps) => {
    const [metricsList, setMetricsList] = useState<string[]>([])
    const [instanceType, setInstanceType] = useState<string>()

    const metrics: string[] = []

    const getMetrics = useCallback(async () => {
        const promises: Promise<any>[] = []
        const metricTypes = ['sql/statistic']
        if (instanceType) {
            metricTypes.forEach((metricType: string) => {
                promises.push(InstanceApi.getAvailableMetrics(`/${metricType}/name?type=${instanceType}&collect_extended_sql_statistics=true`))
            })
        }
        const results = await Promise.all(promises)
        results.forEach((metricListResult: string[]) => {
            metricListResult.forEach((metric: string) => {
                if (!metrics.includes(metric)) {
                    metrics.push(metric)
                }
            })
        })
        setMetricsList(metrics)
    }, [props.instance, props.hosts, props.type, instanceType])


    useEffect(() => {
        void getMetrics()
    }, [props.instance, props.hosts, instanceType])

    return <React.Fragment>
        <PageContent title='SQL Stats Explorer - DBmarlin' description='List of all monitored DB instances with high-level performance statistics'>
            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="SQL Stats Explorer">
                    <Link to="#">Reports</Link>
                    <NavLink to="/reports/sql-stats-explorer" activeClassName={"current-breadcrumb-link"}>SQL Stats Explorer</NavLink>
                    <PeriodBreadcrumb />
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu} refresh={() => {}} applyPeriod={props.applyPeriod}/>
            </div>
            <div className="row row-cols-1">
                <StatisticsDataFactory {...props} type='oracle' setInstanceType={setInstanceType} metricsList={metricsList}/>
            </div>
        </PageContent>
    </React.Fragment>
};

export default StatisticsWrapper;
