import { TreeSelect } from "primereact/treeselect";
import InstanceTarget from "../../types/instance/InstanceTarget";
import HostTarget from "../../types/host/HostTarget";
import { useEffect, useMemo, useState } from "react";
import { InputText } from "primereact/inputtext";
import {
    hostType, instanceType,
    metricHostsType,
    metricInstanceType,
    metricInstanceTypeMapping
} from "../Constants";
import { stringFormatting } from "../../helpers/utils";
import ConditionalRender from "../../helpers/ConditionalRender";
import InstanceApi from "../../api/instance/InstanceApi";

const os: { [key: string]: string } = {
    LINUX: 'Linux',
    WINDOWS: 'Windows',
    AWSRDS: 'AWS RDS'
}

function Index(props: {
    setAggregation: Function,
    aggregation: string,
    entityType: string,
    metricType: string,
    datasourceId: number,
    disabled: boolean,
    setStatistic: Function,
    statistic: string,
    hostId: number,
    statisticError: string,
    isDelete: boolean,
    instances: InstanceTarget[],
    hosts: HostTarget[], [propName: string]: any
}) {
    const {
        entityType,
        metricType,
        disabled,
        setStatistic,
        statistic,
        setAggregation,
        instances,
        datasourceId,
        hosts,
        hostId,
        isDelete,
        statisticError,
        aggregation
    } = props

    const [statisticsList, setStatisticsList] = useState({});
    const [type, setType] = useState('Any');

    const __handleGetDataResponseByInstanceType = async () => {
        let result: { [key: string]: string } = {}
        const metricsList = Object.keys(metricInstanceTypeMapping)
        for (const item of metricsList) {
            const data = await InstanceApi.getAvailableMetrics(`/${metricType}/name?type=${item}`);
            let uniqueArray = data.filter((value: string, index: number, self: any) => self.indexOf(value) === index);
            // @ts-ignore
            const formattedName = metricInstanceTypeMapping[item]
            result[formattedName] = uniqueArray.map((d: string) => {
                return {
                    "label": stringFormatting(d), "data": d, "key": d
                }
            })
        }
        setStatisticsList(result)
    }

    const __handleGetDataResponseByHostType = async () => {
        let result: { [key: string]: string } = {}

        for (const item of [metricHostsType.WINDOWS, metricHostsType.LINUX]) {
            const data = await InstanceApi.getAvailableMetrics(`/host/${metricType}/name?type=${item.toLowerCase()}`);
            let uniqueArray = data.filter((value: string, index: number, self: any) => self.indexOf(value) === index);
            result[item] = uniqueArray.map((d: string) => {
                return {
                    "label": stringFormatting(d), "data": d, "key": d
                }
            })
        }
        setStatisticsList(result)
    }

    const getData = async () => {
        if (entityType === 'host') {
            await __handleGetDataResponseByHostType()
        } else {
            await __handleGetDataResponseByInstanceType()
        }
    }

    useEffect(() => {
        void getData();
    }, [entityType, metricType, type]);


    useEffect(() => {
        // Default values if scope is set to for all hosts
        if (entityType === 'host' && !datasourceId && !hostId) {
            setType(hostType[0])
        }

        // Default values if scope is set to for all instances (datasources)
        if (entityType !== 'host' && !datasourceId && !hostId) {
            setType(instanceType[0])
        }

        // Value for specific host
        if (entityType === 'host' && hostId) {
            const hostType = hosts.find(host => host.id === hostId)
            if (hostType) {
                setType(hostType.type)
            }
        }

        // Value for specific instance (datasource)
        if (entityType !== 'host' && datasourceId) {
            const instanceType = instances.find(instance => instance.id === datasourceId)
            if (instanceType) {
                setType(instanceType.type)
            }
        }
    }, [hostId, datasourceId, entityType, hosts, instances]);

    const metricsList = useMemo(() => {
        if (entityType === 'host') {
            const valuesArray = type === 'Any' ? Object.keys(os) : Object.keys(os).filter(key => key.toLowerCase() === type)
            return valuesArray.map((key: string) => {
                return {
                    "key": os[key],
                    "label": os[key],
                    "selectable": false,
                    // @ts-ignore
                    "children": statisticsList[os[key]]
                }
            })
        }
        if (entityType === 'datasource') {
            // @ts-ignore
            const valuesArray = type === 'Any' ? Object.keys(metricInstanceType) : Object.keys(metricInstanceType).filter(i => metricInstanceTypeMapping[type.toLowerCase()] === metricInstanceType[i])
            return valuesArray.map((key: any) => {
                return {
                    "key": metricInstanceType[key],
                    "label": metricInstanceType[key],
                    "selectable": false,
                    // @ts-ignore
                    "children": statisticsList[metricInstanceType[key]]
                }
            })
        }
    }, [type, statisticsList, entityType])

    return (
        <>
            <div className="col-xs-12 col-md-3">
                <span className="required">
                    <label htmlFor="metric" className="reminder">Metric</label>
                </span>
            </div>
                <div className="col-xs-12 col-md-6 col-lg-4 d-flex ">
                    <select
                        id="decreasing"
                        autoFocus
                        className={`form-control w-25 mr-10`}
                        defaultValue={aggregation}
                        disabled={disabled}
                        onChange={e => setAggregation(e.target.value)}
                        data-lpignore={true}
                    >
                        <option value='avg'>Average</option>
                        <option value='sum'>Sum</option>
                    </select>
                    <div className='w-50 mr-10 '>
                        <InputText
                            id="statistic"
                            name="statistic"
                            className={`form-control p-treenode-label ${statistic && !isDelete ? 'is-valid' : statisticError === 'invalid' ? 'is-invalid' : ''}`}
                            defaultValue={stringFormatting(statistic)}
                            disabled={true}
                            placeholder="Select Metric >"/>
                        <ConditionalRender if={statisticError && !statistic}>
                            <small className="text-danger">Metric is Required</small>
                        </ConditionalRender>
                    </div>
                    <TreeSelect value=''
                                options={metricsList}
                                className={`form-control w-25`}
                                onChange={(e) => setStatistic(e.value)}
                                filter
                                disabled={disabled}
                                placeholder="Select Metric"/>
            </div>
            <div className="w-100"/>
        </>
    )

}


export default Index;