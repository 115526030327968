// React.
import { useContext, useEffect, useState } from 'react';

// Third-party.
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

// Types.
import Time from '../../../types/instance/Time';
import InstancesDataTable from '../../../types/instance/tables/InstancesDataTable';

// Helpers
import Helper from "../../../helpers/Helper";
import ConditionalRender from "../../../helpers/ConditionalRender";
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";

// Components.
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";

// Contexts
import { LicenceContext } from "../../../context/LicenceContext";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

import { CHART_DATA_TYPE } from "../../Constants";
import api from "../../../api/Base";

function InstancesOverTimeChart(props: { setLoading: Function, instancesDataTable: InstancesDataTable[], filters: any, applyPeriod: Function}) {

    const [timeSeries, setTimeSeries] = useState<any[]>([]);
    const [executionSeries, setExecutionSeries] = useState<any[]>([]);
    const licences = useContext(LicenceContext)
    const timeRangeContext = useContext(TimeRangeContext)

    const [peakTimeSeries, setPeakTimeSeries] = useState<number>(0);
    const [peakExecutionSeries, setPeakExecutionSeries] = useState<number>(0);

    const [executionsChartLoading, setExecutionsChartLoading] = useState<boolean>(true)
    const [timeChartLoading, setTimeChartLoading] = useState<boolean>(true)

    const {instancesDataTable} = props

    // Limit the number of lines drawn.
    const topN: number = 10;

    useEffect(() => {
            const getTimeSeries = async() => {
                props.setLoading(true);
                setTimeChartLoading(true)
                let timeSeriesArray: any[] = [],
                    timeSeriesPeak: number[] = [];

                // Get time for the period for each instance.
                for (let index = 0; index < instancesDataTable.length && index < topN; index++) {
                    const instance: InstancesDataTable = instancesDataTable[index];

                    let timeData: number[] = [];

                    await api.get(`activity/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&id=${instance.id}${props.filters}`).then((response: {
                        data: Time[]
                    }) => {
                        const data = response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.waittime]
                        });
                        // Add the chart series.
                        timeSeriesArray.push({
                            name: instance.name,
                            color: instance.color,
                            type: 'spline',
                            minHeight: 3,
                            data: data,
                            zIndex: 1
                        });
                        response.data.map(item => {
                            return timeData.push(item.waittime || 0)
                        })
                    }).then(() => {
                        // Get the peak time spent value.
                        timeSeriesPeak.push(Helper.getPeakValue(timeData));
                    });
                }
                props.setLoading(false);
                const peakTimeSeries = Helper.getPeakValue(timeSeriesPeak);
                setPeakTimeSeries(peakTimeSeries);
                setTimeSeries(timeSeriesArray)
                setTimeChartLoading(false)
            }

            getTimeSeries();
        },
        [timeRangeContext, instancesDataTable]);

    useEffect(() => {
            const getExecutions = async() => {
                props.setLoading(true);
                setExecutionsChartLoading(true)
                let executionSeries: any[] = [],
                    executionSeriesPeak: number[] = [];

                // Get time for the period for each instance.
                for (let index = 0; index < instancesDataTable.length && index < topN; index++) {
                    const instance: InstancesDataTable = instancesDataTable[index];
                    const executionsString = props.filters ? `activity/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&id=${instance.id}&${props.filters}` : `statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&statistic=executions&id=${instance.id}`

                    let executionData: number[] = [];

                    await api.get(executionsString).then((response: { data: any[] }) => {
                        const data = response.data.map(item => {
                            return [getConvertedTimeToUTC(item), item.executions || item.sum || 0]
                        });
                        // Add the chart series.
                        executionSeries.push({
                            name: instance.name,
                            color: instance.color,
                            type: 'spline',
                            minHeight: 3,
                            data: data,
                            zIndex: 1
                        });
                        response.data.map(item => {
                            return executionData.push(item.executions || item.sum || 0)
                        })

                    }).then(() => {
                        executionSeriesPeak.push(Helper.getPeakValue(executionData));
                    });
                }
                props.setLoading(false);
                const peakExecutionSeries = Helper.getPeakValue(executionSeriesPeak);
                setPeakExecutionSeries(peakExecutionSeries);
                setExecutionSeries(executionSeries)
                setExecutionsChartLoading(false)
            }

            getExecutions();
        },
        [timeRangeContext, instancesDataTable]);

    const timeChartOptions = chartOptionsGranularity(props.applyPeriod, timeSeries, null, {
        credits: {enabled: highchartsCredits(licences.licences)},
        legend: true,
        timeRangeContext: timeRangeContext,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.TIME);
            },
        },
    }, 300)

    const executionsChartOptions = chartOptionsGranularity(props.applyPeriod, executionSeries, null, {
        boost: {
            useGPUTranslations: true
        },
        chart: {
            height: '40%',
            spacing: [0, 0, 0, 0],
            type: 'spline',
            minHeight: 3,
            zoomType: 'xy'
        },
        credits: {enabled: highchartsCredits(licences.licences)},
        legend: true,
        timeRangeContext: timeRangeContext,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
            },
        },
    }, 300)

    return (
        <div className="row">
            <div className="col">
                <div className="card collapsible">
                    <div className="card-header">
                        <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                        Top instances by total time
                        <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                           data-bs-target="#collapseCharts" aria-expanded="false" aria-controls="collapseCharts"/>
                    </div>
                    <div id="collapseCharts" className="card-body collapse show p-0">
                        <div className="row row-cols-1 row-cols-lg-2">
                            <div className="col p-3">
                                <h4>Time Spent<span
                                    className="peak">Peak: {Helper.getTimeInEnglish(peakTimeSeries)}</span></h4>
                                <ConditionalRender if={timeChartLoading}>
                                    <div className="w-100 text-center chart-center text-muted mt-3">
                                        <div className="loader spinner chartSpinner">
                                        </div>
                                        <p className="mt-3">
                                            Loading data...
                                        </p>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender if={!timeChartLoading}>
                                    <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                                     options={timeChartOptions}/>
                                </ConditionalRender>
                            </div>
                            <div className="col p-3 border-start">
                                <h4>Executions<span
                                    className="peak">Peak: {peakExecutionSeries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </h4>
                                <ConditionalRender if={executionsChartLoading}>
                                    <div className="w-100 text-center chart-center text-muted mt-3">
                                        <div className="loader spinner chartSpinner">
                                        </div>
                                        <p className="mt-3">
                                            Loading data...
                                        </p>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender if={!executionsChartLoading}>
                                    <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                                     options={executionsChartOptions}/>
                                </ConditionalRender>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstancesOverTimeChart;
