import React, { useContext, useMemo, useState } from 'react';

import { TimeRangeContext } from "../../../context/TimeRangeContext";

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import ReactTooltip from 'react-tooltip';
import { useLicences } from "../../../context/LicenceContext";

// Api.
import StatisticApi from '../../../api/host/StatisticApi';

// Context.
import { InstanceContext } from '../../../context/InstanceContext';

// Helpers.
import Helper from '../../../helpers/Helper';
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";

// Constants.
import { CHART_DATA_TYPE, DATA_INITIALISING, DATA_LOADED, DATA_LOADING } from '../../../component/Constants';
import { COLOUR_HOST_PROCESSOR, COLOUR_HOST_MEMORY } from '../../../component/Constants';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import ChartSeries from '../../../types/ChartSeries';
import HostTarget from '../../../types/host/HostTarget';
import ChartPlotLine from '../../../types/ChartPlotLine';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';
import HostStatistic from '../../../types/host/HostStatistic';

import HostsDataTable from '../../../types/host/tables/HostsDataTable';
import InstanceTargetHost from '../../../types/instance/InstanceTargetHost';

// Components.
import ProcessorWidget from '../../../component/host/widget/ProcessorWidget';
import MemoryWidget from '../../../component/host/widget/MemoryWidget';
//import DisksWidget from '../../../component/host/widget/DisksWidget';
//import NetworkWidget from '../../../component/host/widget/NetworkWidget';
import HostsTable from '../../../component/host/table/HostsTable'
import api from "../../../api/Base";

function Hosts(props: { instance: InstanceTarget, hosts: HostTarget[], chartPlotLines: ChartPlotLine[], comparisonSupported: boolean, applyPeriod: Function }) {
    const {instances} = useContext(InstanceContext);
    const {licences} = useLicences();
    const [loading, setLoading] = useState<number>(DATA_INITIALISING);
    const [tableLoading, setTableLoading] = useState<number>(DATA_INITIALISING);
    const [hostsDataTable, setHostsDataTable] = useState<HostsDataTable[]>([]);
    const [processorSeries, setProcessorSeries] = useState<ChartSeries[]>([]);
    const [memorySeries, setMemorySeries] = useState<ChartSeries[]>([]);
    const [processorMetric, setProcessorMetric] = useState<number>(0);
    const [processorMetricPrevious, setProcessorMetricPrevious] = useState<number | null>(null);
    const [memoryMetric, setMemoryMetric] = useState<number>(0);
    const [memoryMetricPrevious, setMemoryMetricPrevious] = useState<number | null>(null);
    const timeRangeContext = useContext(TimeRangeContext)

    const memoryPeak = useMemo(() => {
        let peaks: number[] = []
        memorySeries.forEach((serie: any) => {
            peaks = peaks.concat(serie.data.map((i: any) => i[1]))
        })
        return Helper.getPeakValue(peaks)
    }, [memorySeries])

    const processorPeak = useMemo(() => {
        let peaks: number[] = []
        processorSeries.forEach((serie: any) => {
            peaks = peaks.concat(serie.data.map((i: any) => i[1]))
        })
        return Helper.getPeakValue(peaks)
    }, [processorSeries])

    // Get the instance hosts processor and memory data over time.
    useMemo(() => {

        // for each host, we need to get processor, memory over time, plus their peak values.
        async function getHostsData() {
            setLoading(DATA_LOADING);
            const hosts: HostTarget[] = []
            await api.get(`datasource/host?id=${props.instance.id}`)
                .then((response: { data: InstanceTargetHost[]; }) => {
                    for (let index = 0; index < response.data.length; index++) {
                        // Get any matching hosts.
                        const matchedHosts = props.hosts.filter(host => host.id === response.data[index].hostid);

                        if (matchedHosts.length === 1) {
                            // Append the matched hosts to any pre-existing hosts.
                            hosts.push(matchedHosts[0]);
                        }
                    }
                })
                .catch((error: any) => {
                    // Todo: handle and log the error.
                    console.log('An error occurred:', error);
                })
                .then(async() => {

                });
            const memSeries: any[] = []
            const procSeries: any[] = []

            for (let index = 0; index < hosts.length; index++) {
                // Get the host we're currently dealing with.
                const host = hosts[index];

                // Get host CPU over time.
                await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation&id=${host.id}`).then((response: { data: HostTimeStatistic[]; }) => {
                    procSeries.push({
                        name: `CPU - ${host.name}`,
                        color: COLOUR_HOST_PROCESSOR,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map((item) => {
                            return [getConvertedTimeToUTC(item), Math.round(item.avg)]
                        }),
                        yAxis: 0,
                        zIndex: 2
                    })
                }).catch((error: any) => {
                    console.error('Failed to retrieve processor over time data for host', error, host);
                })

                // Get host memory over time.
                await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=memoryutilisation&id=${host.id}`).then((response: { data: HostTimeStatistic[]; }) => {
                    memSeries.push({
                        name: `Memory - ${host.name}`,
                        color: COLOUR_HOST_MEMORY,
                        type: 'spline',
                        minHeight: 3,
                        data: response.data.map((item) => {
                            return [getConvertedTimeToUTC(item), Math.round(item.avg)]
                        }),
                        yAxis: 0,
                        zIndex: 1
                    })
                }).catch((error: any) => {
                    console.error('Failed to retrieve memory over time data for host', error, host);
                })
            }
            setMemorySeries(memSeries)
            setProcessorSeries(procSeries)
            setLoading(DATA_LOADED);
        }

        async function getWidgetData() {

            let processorMetrics: number[] = [],
                previousProcessorMetrics: number[] = [],
                memoryMetrics: number[] = [],
                previousMemoryMetrics: number[] = [];

            for (let index = 0; index < props.hosts.length; index++) {

                const host = props.hosts[index];

                // Get current processor average.
                await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=cpuutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            processorMetrics.push(Math.round(response.data[0].avg))
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve processor metric', error, host);
                    })
                    .then(function () {
                    });

                // Get current memory average.
                await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString()}&statistic=memoryutilisation&id=${host.id}`)
                    .then((response: { data: HostStatistic[]; }) => {
                        if (response.data.length > 0) {
                            memoryMetrics.push(Math.round(response.data[0].avg))
                        }
                    })
                    .catch((error: any) => {
                        console.error('Failed to retrieve processor metric', error, host);
                    })
                    .then(function () {
                    });

                if (props.comparisonSupported === true) {

                    // Get previous processor average.
                    await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=cpuutilisation&id=${host.id}`)
                        .then((response: { data: HostStatistic[]; }) => {
                            if (response.data.length > 0) {
                                previousProcessorMetrics.push(Math.round(response.data[0].avg));
                            }
                        })
                        .catch((error: any) => {
                            console.error('Failed to retrieve previous processor metric', error, host);
                        })
                        .then(function () {
                        });

                    // Get previous memory average.
                    await api.get(`host/statistic/summary?${timeRangeContext.getTimeRangeQueryString(true)}&statistic=memoryutilisation&id=${host.id}`)
                        .then((response: { data: HostStatistic[]; }) => {
                            if (response.data.length > 0) {
                                previousMemoryMetrics.push(Math.round(response.data[0].avg));
                            }
                        })
                        .catch((error: any) => {
                            console.error('Failed to retrieve previous processor metric', error, host);
                        })
                        .then(function () {
                        });
                }
            }

            // Calculate the average metric value for all hosts.
            const sumProcessor = processorMetrics.reduce((a, b) => a + b, 0);
            const avgProcessor = (sumProcessor / processorMetrics.length) || 0;

            setProcessorMetric(Math.round(avgProcessor));

            const sumMemory = memoryMetrics.reduce((a, b) => a + b, 0);
            const avgMemory = (sumMemory / memoryMetrics.length) || 0;

            setMemoryMetric(Math.round(avgMemory));

            if (props.comparisonSupported === true) {

                // Calculate the average previous metric value for all hosts.
                const sumProcessorPrevious = previousProcessorMetrics.reduce((a, b) => a + b, 0);
                const avgProcessorPrevious = (sumProcessorPrevious / previousProcessorMetrics.length) || 0;

                setProcessorMetricPrevious(Math.round(avgProcessorPrevious));

                const sumMemoryPrevious = previousMemoryMetrics.reduce((a, b) => a + b, 0);
                const avgMemoryPrevious = (sumMemoryPrevious / previousMemoryMetrics.length) || 0;

                setMemoryMetricPrevious(Math.round(avgMemoryPrevious));
            }
        }

        getHostsData();
        getWidgetData();

    }, [props.hosts, timeRangeContext, props.comparisonSupported]);


    // Build the processor and memory charts.
    const charts = useMemo(() => {
        return (
            <div className="row row-cols-1 row-cols-lg-2">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            CPU Average
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseProcessor" aria-expanded="false"
                               aria-controls="collapseProcessor"/>
                        </div>
                        <div id="collapseProcessor" className="card-body collapse show">
                            <p className="peak-wrapper"><span className="peak">Peak Average CPU: {processorPeak}%</span>
                            </p>

                            <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                             options={chartOptionsGranularity(props.applyPeriod, [...processorSeries], Helper.getPeakValue([processorPeak]), {
                                                 credits: {enabled: highchartsCredits(licences)},
                                                 timeRangeContext: timeRangeContext,
                                                 plotLines: props.chartPlotLines,
                                                 legend: 'bottom',
                                                 tooltip: {
                                                     formatter: function () {
                                                         return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
                                                     },
                                                 },
                                             })}/>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            Memory Average
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseMemory" aria-expanded="false" aria-controls="collapseMemory"/>
                        </div>
                        <div id="collapseMemory" className="card-body collapse show">
                            <p className="peak-wrapper"><span className="peak">Peak Average Memory: {memoryPeak}%</span>
                            </p>
                            <HighchartsReact constructorType={"chart"} highcharts={Highcharts}
                                             options={chartOptionsGranularity(props.applyPeriod, [...memorySeries], Helper.getPeakValue([memoryPeak]), {
                                                 credits: {enabled: highchartsCredits(licences)},
                                                 timeRangeContext: timeRangeContext,
                                                 plotLines: props.chartPlotLines,
                                                 legend: 'bottom',
                                                 tooltip: {
                                                     formatter: function () {
                                                         return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
                                                     },
                                                 },
                                             })}/>
                        </div>
                    </div>
                </div>
            </div>
        );

    }, [timeRangeContext, props.chartPlotLines, processorSeries, memorySeries, licences, processorPeak, memoryPeak]);

    // Build the host data required for the hosts table.
    useMemo(() => {

        const getHostsTableData = async() => {
            setTableLoading(DATA_LOADING)
            let instanceTargetHosts: InstanceTargetHost[] = [],
                hostsDataTable: HostsDataTable[] = [];

            // Get any instances related to this host.
            await api.get(`datasource/host`)
                .then((response: { data: InstanceTargetHost[]; }) => {

                    instanceTargetHosts = response.data;
                })
                .catch((error: any) => {

                    console.error('Failed to retrieve list of instance hosts', error);
                })
                .then(async() => {
                });

            // Loop through all the hosts, and get the initial required data.
            for (let index = 0; index < props.hosts.length; index++) {

                // Get the current host and a list of instance IDs linked to this host.
                const
                    host = props.hosts[index],
                    matchedInstanceIds = instanceTargetHosts.filter(item => Number(item.hostid) === Number(host.id));

                // Todo: Get any matched instances.
                let matchedInstances: InstanceTarget[] = [];

                for (let instanceIndex = 0; instanceIndex < matchedInstanceIds.length; instanceIndex++) {

                    // Append any matched instances to the existing array.
                    matchedInstances = [...matchedInstances, ...instances.filter(instance => Number(instance.id) === Number(matchedInstanceIds[instanceIndex].id))];

                }

                // Get average processor.
                const processor: number = await StatisticApi.getStatistic(timeRangeContext, host.id, 'cpuutilisation');

                // Get average memory.
                const memory: number = await StatisticApi.getStatistic(timeRangeContext, host.id, 'memoryutilisation');

                // Get peak processor.
                const processorPeak: number = await StatisticApi.getStatisticPeak(timeRangeContext, host.id, 'cpuutilisation');

                // Get peak memory.
                const ioutilisation: number = await StatisticApi.getStatistic(timeRangeContext, host.id, 'ioutilisation');

                hostsDataTable.push({
                    id: host.id,
                    name: host.name,
                    type: host.type,
                    processor,
                    processorPeak,
                    memory,
                    ioutilisation,
                    instances: matchedInstances
                });
            }
            setTableLoading(DATA_LOADED);
            setHostsDataTable(hostsDataTable);
        }

        getHostsTableData();

    }, [instances, timeRangeContext, props.instance, props.hosts]);

    const hostsTable = useMemo(() => {
        return <HostsTable loading={tableLoading} hostsDataTable={hostsDataTable}/>;
    }, [hostsDataTable, loading])

    Highcharts.setOptions({
        plotOptions: {series: {animation: false}},
        lang: {
            thousandsSep: ','
        }
    });

    // As we're potentially late data-binding, rebuild the tooltips.
    ReactTooltip.rebuild();

    return (
        <React.Fragment>

            {/* Metric Widgets - Host */}
            {props.hosts.length > 0 && (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 flexlist">
                    <ProcessorWidget metric={processorMetric} metricPrevious={processorMetricPrevious}/>
                    <MemoryWidget metric={memoryMetric} metricPrevious={memoryMetricPrevious}/>
                    {/*
                    <DisksWidget />
                    <NetworkWidget />
                    */}
                </div>
            )}

            {/* Host Metrics over Time */}
            {props.hosts.length > 0 && (
                charts
            )}

            {/* Hosts */}
            <div className="row row-cols-1">
                <div className="col">
                    <div className="card collapsible">
                        <div className="card-header">
                            <i className="fal fa-server fa-fw" aria-hidden="true" />
                            Monitored hosts
                            <span className="badge bg-info"
                                  data-tip="Total host count">{props.hosts.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                               data-bs-target="#collapseHosts" aria-expanded="false" aria-controls="collapseHosts" />
                        </div>
                        <div id="collapseHosts" className="card-body collapse show">
                            {hostsTable}
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}
export default Hosts;
