import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';

// Third-parties.
import Helmet from 'react-helmet';

// Types.
import Period from '../../../types/Period';

// Components.
import Breadcrumb from "../../../container/breadcrumb";
import ConditionalRender from "../../../helpers/ConditionalRender";
import HeaderActions from "../../../container/header_actions";
import List from "./List";
import Details from "./Details";
import { stringFormatting } from "../../../helpers/utils";

function ExtendedStatistics(props: { period: Period, toggleMenu: Function }) {

    return (
        <div id="content">

            <Helmet>
                <title>SQL Statistics - DBmarlin</title>
                <meta name="description" content="Collect Extended SQL Statistics"/>
            </Helmet>

            {/* Heading */}
            <div id="top-header-wrapper" className="row row-cols-lg-3 row-cols-sm-1 row-cols-md-2">
                <Breadcrumb heading="SQL Statistics">
                    <Link to="/admin">Settings</Link>

                    <Switch>
                        <Route exact path="/admin/database-extended-statistics"
                               render={() => <span>SQL Statistics</span>}/>
                    </Switch>

                    <Switch>
                        <Route exact path="/admin/database-extended-statistics/:actionId/:id" render={() =>
                            <Link to="/admin/database-extended-statistics/">Update SQL Statistics</Link>}/>
                    </Switch>

                    <Switch>
                        <Route exact path="/admin/database-extended-statistics/:actionId/:id" render={(props) =>
                            <span>{stringFormatting(props.match.params.id)}</span>}/>
                    </Switch>
                </Breadcrumb>
                <HeaderActions period={props.period} toggleMenu={props.toggleMenu}/>
            </div>

            <div className="loader">
                <ConditionalRender if={false}>
                    <div className="bar"/>
                </ConditionalRender>
            </div>

            <div className="row row-cols-1">
                <Switch>
                    <Route exact path="/admin/database-extended-statistics/:actionId/:id"
                           render={(props) => <Details {...props} instance={props.match.params.id}/>}/>
                    <Route exact path="/admin/database-extended-statistics" render={() => <List/>}/>
                </Switch>
            </div>

        </div>
    );
}

ExtendedStatistics.propTypes = {
    toggleMenu: PropTypes.func.isRequired
};

export default ExtendedStatistics;