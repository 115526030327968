// Api.
import api from './Base';

// Types.
import Licence from '../types/Licence';

const LicenceApi = {

    getLicences:async () => {

        let url = `licence`;

        let instances: Licence[] = [];

        await api.get<Licence[]>(url)
            .then((response) => {
                instances = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve licence keys.', error);
            })
            .then(function () {});

        return instances;
    },
    
    getLicence:(id: number) => {

        let url = `licence?id=${id}`;

        api.get<Licence[]>(url)
            .then((response) => {
                return response.data[0];
            })
            .catch((error: any) => {
                console.error('Failed to retrieve requested licence key.', error);
            })
            .then(function () {});
    }
}

export default LicenceApi;