// Api.
import api from './Base';

// Types.
import InstanaIntegration from '../types/integration/InstanaIntegration';

const IntegrationApi = {

    getIntegrations:async () => {

        let url = `integration`;

        let instances: InstanaIntegration[] = [];

        await api.get<InstanaIntegration[]>(url)
            .then((response) => {
                instances = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve integrations.', error);
            })
            .then(function () {});

        return instances;
    },
    
    // getIntegration:(id: number) => {
    //
    //     let url = `integration?id=${id}`;
    //
    //     api.get<InstanaIntegration[]>(url)
    //         .then((response) => {
    //             return response.data[0];
    //         })
    //         .catch((error: any) => {
    //             console.error('Failed to retrieve integration.', error);
    //         })
    //         .then(function () {});
    // }
}

export default IntegrationApi;