import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Helper from "../../helpers/Helper";
import { archiverUrl, fetchResults, fetchWithAuthorization } from "../../helpers/utils";
import dayjs from "dayjs";
import { InstanceContext } from "../../context/InstanceContext";
import { InstanceOptions, StatisticsResultsTableProps } from "./types";
import ConditionalRender from "../../helpers/ConditionalRender";
import React from "react";

const StatisticsResultsTable = (props: StatisticsResultsTableProps) => {
    const {instances} = useContext(InstanceContext);

    const [loading, setLoading] = useState(false)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [waitTimes, setWaitTimes] = useState<any[]>([])
    const [cpuCounts, setCpuCounts] = useState<any[]>([])
    const [totalExecutions, setTotalExecutions] = useState<any[]>([])
    const [totalMetricData, setTotalMetricData] = useState<any[]>([])

    const columns = useMemo(() => {
        return Math.abs((12 / 2) / props.instanceOptions.length)
    }, [props.generateId])

    const aggregateInt = useMemo(() => {
        switch (props.options.aggregate) {
            case 'auto':
                return Math.max(...props.instanceOptions.map(option => Helper.getInterval(dayjs(option.periodStart), dayjs(option.periodEnd))))
            case 'timeslice':
                return Math.max(...props.instanceOptions.map(option => instances.find(i => i.id === option.instanceId)?.batchintervalseconds || 0)) * 1000
            default:
                return parseInt(props.options.aggregate, 10)
        }
    }, [props.generateId])

    const getPeriodHeaders = useCallback(() => {
        return props.instanceOptions.map((instanceOption: InstanceOptions, key: number) => {
            if (instanceOption.periodStart && instanceOption.periodEnd) {
                return <th key={key} className={`col-${columns} text-end`} scope="col">
                    {Helper.getFormattedPeriodHeadingFromDate(instanceOption.periodStart, instanceOption.periodEnd)}
                </th>
            }
            return null
        })
    }, [props.generateId])

    const getInstanceRows = useCallback(() => {
        return props.instanceOptions.map((instanceOption: InstanceOptions, key: number) => {
            if (instanceOption.instanceName) {
                return <td key={key} className="text-end">
                    {instanceOption.instanceName}
                </td>
            }
            return null
        })
    }, [props.generateId])

    const getTotalTimeRows = useCallback(() => {
        return waitTimes.map((time: any, index: number) => {
            return <td key={index} className="text-end">
                {time ? Helper.getTimeInEnglish(time) : '-'}
            </td>
        })
    }, [waitTimes])

    const getTotalExecutionsRows = useCallback(() => {
        return totalExecutions.map((executions: any, index: number) => {
            return <td key={index} className="text-end">
                {executions ? Helper.getFormattedNumber(executions) : '-'}
            </td>
        })
    }, [totalExecutions])

    const getTotalMetricRows = useCallback(() => {
        return totalMetricData.map((metricData: any, index: number) => {
            return <td key={index} className="text-end">
                {`${Helper.getNumberFormatType(props.options.metric, metricData)}`}
            </td>
        })
    }, [totalMetricData])

    const metricDifference = useMemo(() => {
        if (totalMetricData[0] && totalMetricData[1]) {
            const diff = Math.abs(totalMetricData[0] - totalMetricData[1])
            return `${Helper.getNumberFormatType(props.options.metric, diff)}`
        }
        return `${Helper.getNumberFormatType(props.options.metric, 0)}${Helper.getMetricFormatString(props.options.metric)}`
    }, [totalMetricData])

    const cpuDifference = useMemo(() => {
        if (cpuCounts[0] && cpuCounts[1]) {
            return Helper.getFormattedNumber(Math.abs(cpuCounts[0] - cpuCounts[1]))
        }
        return '0'
    }, [cpuCounts])

    const averageExecutions = useMemo(() => {
        return totalExecutions.map((executions: any, index: number) => {
            return executions && waitTimes[index] ? parseFloat((waitTimes[index] / executions).toString()) : 0
        })
    }, [waitTimes, totalExecutions])

    const getAverageExecutionRows = useCallback(() => {
        return averageExecutions.map((averageExecution: any, index: number) => {
            return <td key={index} className="text-end">
                {averageExecution ? Helper.getTimeInEnglish(averageExecution) : '-'}
            </td>
        })
    }, [averageExecutions])

    const getCpuCountRows = useCallback(() => {
        return cpuCounts.map((cpuCount: number | undefined, index: number) => {
            return <td key={index} className="text-end">
                {cpuCount ? cpuCount : '-'}
            </td>
        })
    }, [cpuCounts])

    const timeDifference = useMemo(() => {
        if (waitTimes[0] && waitTimes[1]) {
            return Helper.getTimeInEnglish(Math.abs(waitTimes[0] - waitTimes[1]))
        }

        return '-'
    }, [waitTimes])

    const executionsDifference = useMemo(() => {
        if (totalExecutions[0] && totalExecutions[1]) {
            return Helper.getNumberFormatType('executions', Math.abs(totalExecutions[0] - totalExecutions[1]))
        }

        return '-'
    }, [totalExecutions])

    const averageExecutionsDifference = useMemo(() => {
        if (averageExecutions[0] && averageExecutions[1]) {
            return Helper.getTimeInEnglish(Math.abs(averageExecutions[0] - averageExecutions[1]))
        }

        return '-'
    }, [averageExecutions])

    const getTotalTime = useCallback(async() => {
        const promises: Promise<Response>[] = []
        props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
            const type = instances.find(i => i.id === instanceOption.instanceId)?.type
            promises.push(fetchWithAuthorization(`${archiverUrl(2)}/sql/statistic/sql?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=duration+desc&type=${type}`))
        })

        const results = await fetchResults(promises, false)
        const total: any[] = []
        results.forEach((result: any[]) => {
            let sum = 0;
            try {
                sum = result.reduce((accumulator, resultItem) => {
                    // @ts-ignore
                    return accumulator + resultItem.duration;
                }, 0);
            } catch (ignore) {

            }
            total.push(sum)
        })
        setWaitTimes(total)
    }, [props.generateId])

    const getCpuCounts = useCallback(async() => {
        const promises: Promise<Response>[] = []
        props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
            promises.push(fetchWithAuthorization(`${archiverUrl(1)}/statistic/summary?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&statistic=cpucount`))
        })

        const results = await fetchResults(promises)
        const cpuCountResponses = results.map(i => i?.[0] || 0).map(i => i.avg)
        setCpuCounts(cpuCountResponses)
    }, [props.generateId])

    const getTotalExecutions = useCallback(async() => {
        const promises: Promise<Response>[] = []
        props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
            const type = instances.find(i => i.id === instanceOption.instanceId)?.type
            promises.push(fetchWithAuthorization(`${archiverUrl(2)}/sql/statistic/sql?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=executions+desc&type=${type}`))
        })

        const results = await fetchResults(promises, false)
        const total: any[] = []
        results.forEach((result: any[]) => {
            let sum = 0;
            try {
                sum = result.reduce((accumulator, resultItem) => {
                    // @ts-ignore
                    return accumulator + resultItem.executions;
                }, 0);
            } catch (ignore) {

            }
            total.push(sum)
        })
        setTotalExecutions(total)
    }, [props.generateId])

    const getTotalMetricData = useCallback(async() => {
        const promises: Promise<Response>[] = []
        props.instanceOptions.forEach((instanceOption: InstanceOptions) => {
            const from = dayjs(instanceOption.periodStart).format('YYYY-MM-DD+HH:mm')
            const to = dayjs(instanceOption.periodEnd).format('YYYY-MM-DD+HH:mm')
            const type = instances.find(i => i.id === instanceOption.instanceId)?.type
            promises.push(fetchWithAuthorization(`${archiverUrl(2)}/sql/statistic/sql?from=${from}&to=${to}&tz=${timezone}&id=${instanceOption.instanceId}&interval=${aggregateInt}&sort=${props.options.metric}+desc&type=${type}`))
        })

        const results = await fetchResults(promises, false)
        const total: any[] = []
        results.forEach((result: any[]) => {
            let sum = 0;
            try {
                sum = result.reduce((accumulator, resultItem) => {
                    // @ts-ignore
                    return accumulator + resultItem[props.options.metric];
                }, 0);
            } catch (ignore) {

            }
            total.push(sum)
        })
        setTotalMetricData(total)

    }, [props.generateId])

    const tableMetric = useMemo(() => {
        return props.options.metric
    }, [props.generateId])

    useEffect(() => {
        setLoading(true)
        Promise.all([getTotalTime(), getTotalExecutions(), getTotalMetricData(), getCpuCounts()]).then(() => {
            setLoading(false)
        })
    }, [props.generateId])

    return <React.Fragment>
        <div className="card">
            <div className="card-body show">
                <ConditionalRender if={loading}>
                    <div className="loader"
                         style={{borderWidth: 0, position: "absolute", width: '80%', margin: 'auto', height: "5px"}}>
                        <div className="bar"/>
                    </div>
                </ConditionalRender>
                <div className="row row-cols-lg-12 mt-3">
                    <table className="table stats-comparison-summary">
                        <thead>
                        <tr>
                            <th className={`col-${columns}`} scope="col">Metric</th>
                            {getPeriodHeaders()}
                            <th className={`col-${columns} text-end`} scope="col">Period Difference</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Instance Name</td>
                            {getInstanceRows()}
                            <td className="text-end"></td>
                        </tr>
                        <tr>
                            <td>Total Duration</td>
                            {getTotalTimeRows()}
                            <td className="text-end">
                                {timeDifference}
                                {timeDifference && <i className={Helper.getChangeIcon(waitTimes[0], waitTimes[1])}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Executions</td>
                            {getTotalExecutionsRows()}
                            <td className="text-end">
                                {executionsDifference}
                                {executionsDifference &&
                                    <i className={Helper.getChangeIcon(totalExecutions[0], totalExecutions[1])}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>Average Execution Time</td>
                            {getAverageExecutionRows()}
                            <td className="text-end">
                                {averageExecutionsDifference}
                                {averageExecutionsDifference &&
                                    <i className={Helper.getChangeIcon(averageExecutions[0], averageExecutions[1])}/>}
                            </td>
                        </tr>
                        <tr>
                            <td>CPU Count</td>
                            {getCpuCountRows()}
                            <td className="text-end">
                                {cpuDifference}
                                {cpuDifference && <i className={Helper.getChangeIcon(cpuCounts[0], cpuCounts[1])}/>}
                            </td>
                        </tr>
                        <ConditionalRender if={!['duration', 'executions'].includes(tableMetric)}>
                            <tr>
                                <td><strong>{tableMetric}</strong></td>
                                {getTotalMetricRows()}
                                <td className="text-end">
                                    {metricDifference}
                                    {metricDifference &&
                                        <i className={Helper.getChangeIcon(totalMetricData[0], totalMetricData[1])}/>}
                                </td>
                            </tr>
                        </ConditionalRender>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default StatisticsResultsTable;
