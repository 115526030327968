// React.
import { useCallback, useContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';

// Third-party packages.
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

import * as QueryString from 'query-string';

import ReactTooltip from 'react-tooltip';

import { ToastProvider } from 'react-toast-notifications';

// Context.
import { InstanceContext } from './context/InstanceContext';
import { HostContext } from './context/HostContext';
import { EventTypeContext } from './context/EventTypeContext';
import { InstanaIntegrationContext } from './context/InstanaIntegrationContext';
import { LicenceContext } from './context/LicenceContext';
import { FilterContext } from './context/FilterContext';
import { TimeRangeContext } from "./context/TimeRangeContext";
import { ChatContext } from './context/ChatContext';

// Helpers.
import Helper from './helpers/Helper';
import { fetchFilters, getFilterParameters } from './helpers/utils';
import ConditionalRender from "./helpers/ConditionalRender";

// Types.
import Period from './types/Period';
import InstanceTarget from './types/instance/InstanceTarget';
import HostTarget from './types/host/HostTarget';
import EventType from './types/instance/EventType';
import InstanaIntegration from './types/integration/InstanaIntegration';
import Licence from './types/Licence';
import Filter from './types/Filter';
import FilterOption from './types/instance/FilterOption';

// Constants.
import {
    DATA_LOADED,
    DATA_LOADING, initialMessage, messages,
    PERIOD_CUSTOM,
    REPORT_PERIOD_CURRENT
} from './component/Constants';
import { TARGET_COLOUR_CODES } from './component/Constants';
import { INTERVAL_SECOND, INTERVAL_MINUTE, INTERVAL_ONE_HOUR, INTERVAL_ONE_DAY } from './component/Constants';
import {
    PERIOD_MINUTE,
    PERIOD_FIVE_MINUTES,
    PERIOD_TEN_MINUTES,
    PERIOD_THIRTY_MINUTES,
    PERIOD_ONE_HOUR,
    PERIOD_SIX_HOURS,
    PERIOD_TWELVE_HOURS,
    PERIOD_TWENTY_FOUR_HOURS,
    PERIOD_SEVEN_DAYS,
    PERIOD_THIRTY_ONE_DAYS,
    PERIOD_TODAY,
    PERIOD_YESTERDAY,
    PERIOD_DAY_BEFORE_YESTERDAY,
    PERIOD_THIS_WEEK,
    PERIOD_PREVIOUS_WEEK
} from './component/Constants';

// Apis.
import InstanceApi from './api/instance/InstanceApi';
import HostApi from './api/host/HostApi';
import EventTypeApi from './api/instance/EventTypeApi';
import IntegrationsApi from './api/IntegrationApi';
import LicenceApi from './api/LicenceApi';

// Components.
import MenuSide from './component/MenuSide';
import Footer from './component/Footer';
import ChatComponent from "./component/chatGPT/index";

// General Views.
//import Dashboard from './views/Dashboard';
import EventHistory from './views/events/EventHistory';
import SQLSearch from './views/sql-search';

// Views.
import Instances from './views/instances/Instances';
import Hosts from './views/hosts/Hosts';
import Admin from './views/admin/Admin';
import NotFound from './views/NotFound';
import Reports from './views/reports/Reports';

// Styles.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'highlight.js/styles/vs.css';
import 'html-query-plan/css/qp.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './css/App.css';
import './css/html-query-plan-overrides.css';
import './css/primereact-overrides.css';
import './css/primereact-execution-plans.css';
import './css/tooltip.css';
import './fonts/mfizz/font-mfizz.css';
import './fonts/fontawesome/css/all.min.css';
import './fonts/fontawesome/css/custom-icons.min.css';
import 'highlight.js/styles/vs.css'

// Check for QueryString parameters.
let queryString = QueryString.parse(window.location.search);

const savedTimeZone = window.localStorage.getItem('dbmar-timeZone') || ''
const timeZoneValue = savedTimeZone ? JSON.parse(savedTimeZone) : Intl.DateTimeFormat().resolvedOptions().timeZone
const isUTC = timeZoneValue === 'UTC'

let
    base: Dayjs = isUTC ? dayjs.utc() : dayjs.default(),
    from: Dayjs = base.subtract(1, 'hour'),
    to: Dayjs = base,
    previousFrom: Dayjs = base.subtract(2, 'hour'),
    previousTo: Dayjs = base.subtract(1, 'hour'),
    interval: number = INTERVAL_MINUTE,
    periodOption: number = PERIOD_ONE_HOUR,
    heading: string = 'Last hour',
    subheading: string = from.format('MMM DD')

// Set the subheading.
if (from.diff(to, 'day') !== 0) {

    // The selected period greater than one day.
    subheading = `${from.format('MMM DD')} - ${to.format('MMM DD')}`;
} else {

    // The selected period is a same day enquiry.
    subheading = from.format('MMM DD');
}

if (queryString.fm !== undefined && queryString.fm !== null && queryString.to !== undefined && queryString.to !== null && queryString.tz !== undefined && queryString.tz !== null) {

    const timezone = (typeof queryString.tz === 'object' ? queryString.tz[0] : queryString.tz) || 'UTC'
    // Override the default settings.
    from = dayjs.tz(queryString.fm.toString(), 'YYYY-MM-DD+HH:mm', timezone)
    from = dayjs.default(from).tz(dayjs.tz.guess())
    to = dayjs.tz(queryString.to.toString(), 'YYYY-MM-DD+HH:mm', timezone)
    to = dayjs.default(to).tz(dayjs.tz.guess())
    previousFrom = from.subtract(to.diff(from, 'millisecond'), 'millisecond');
    previousTo = to.subtract(to.diff(from, 'millisecond'), 'millisecond');
    if (queryString.interval && parseInt(queryString.interval?.toString())) {
        interval = parseInt(queryString.interval?.toString());
    }
    periodOption = PERIOD_CUSTOM;
    heading = 'Custom';

    // Set the subheading.
    if (from.diff(to, 'day') !== 0) {

        // The selected period greater than one day.
        subheading = `${from.format('MMM DD')} - ${to.format('MMM DD')}`;
    } else {

        // The selected period is a same day enquiry.
        subheading = from.format('MMM DD');
    }
}
const options = Helper.getFilterParams()

let initialPeriod: Period = {
    chartTickInterval: Helper.getChartTickInterval(from, to, periodOption, interval),
    comparisonSupported: true,
    isValid: true,
    timestamp: '0',
    dateFormat: Helper.getChartDateFormat(interval),
    api: {
        interval,
        periodOption,
        time: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        current: {
            from: from.format('YYYY-MM-DD+HH:mm:00'),
            to: to.format('YYYY-MM-DD+HH:mm:00')
        },
        previous: {
            from: previousFrom.format('YYYY-MM-DD+HH:mm:00'),
            to: previousTo.format('YYYY-MM-DD+HH:mm:00')
        }
    },
    ui: {
        heading,
        subheading,
        queryString: '',
        current: {
            chartCategories: Helper.getChartAxisCategories(from, to, interval),
            formattedChartCategories: Helper.getFormattedChartAxisCategories(from, to, periodOption, interval),
            from: from,
            to: to
        },
        previous: {
            chartCategories: Helper.getChartAxisCategories(previousFrom, previousTo, interval),
            formattedChartCategories: Helper.getFormattedChartAxisCategories(previousFrom, previousTo, periodOption, interval),
            from: previousFrom,
            to: previousTo
        }
    },
    filters: {
        options,
        parameters: getFilterParameters(options)
    }
};

initialPeriod.ui.subheading = Helper.getFormattedPeriodSubheading(initialPeriod, REPORT_PERIOD_CURRENT);


function getFilters(options: FilterOption[]) {
    return { options, parameters: getFilterParameters(options) };
}

function App(props: { showLogOut: boolean }) {
    const [updateKey, setUpdateKey] = useState<number>(0);
    const [loading, setLoading] = useState<number>(DATA_LOADING);
    const [className, setClassName] = useState<string>('expanded');
    const [instances, setInstances] = useState<InstanceTarget[]>([]);
    const [hosts, setHosts] = useState<HostTarget[]>([]);
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [instanaIntegrations, setInstanaIntegrations] = useState<InstanaIntegration[]>([]);
    const [licences, setLicences] = useState<Licence[]>([]);
    const [licencesLoaded, setLicencesLoaded] = useState<number>(DATA_LOADING);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [message, setMessage] = useState<string>(initialMessage[Math.floor(random() * initialMessage.length)]);

    const timeRangeContext = useContext(TimeRangeContext)

    const [isOpen, setIsOpen] = useState(false);
    const [chatData, setChatData] = useState('');
    const history = useHistory();

    const toggleChat = (value: any) => {
        setChatData(value);
        setIsOpen(value ? true : !isOpen);
    };

    const toggleMenu = useCallback(function () {

        // Set the target class name to toggle the menu and content area being collapsed or expanded.
        if (className === 'expanded') {
            setClassName('collapsed');
        } else {
            setClassName('expanded');
        }
    }, [className]);

    const applyPeriod = useCallback(function (periodOption: number, from: Dayjs, to: Dayjs, options?: FilterOption[], timeZone?: string) {
        const time = to.diff(from, 'ms');
        let heading: string = 'Custom',
            subheading: string = 'Subheading',
            interval: number = INTERVAL_ONE_DAY,
            comparisonSupported: boolean = true;

        // Set the heading.
        switch (periodOption) {
            case PERIOD_MINUTE:
                heading = 'Last minute';
                break;
            case PERIOD_FIVE_MINUTES:
                heading = 'Last 5 minutes';
                break;
            case PERIOD_TEN_MINUTES:
                heading = 'Last 10 minutes';
                break;
            case PERIOD_THIRTY_MINUTES:
                heading = 'Last 30 minutes';
                break;
            case PERIOD_ONE_HOUR:
                heading = 'Last hour';
                break;
            case PERIOD_SIX_HOURS:
                heading = 'Last 6 hours';
                break;
            case PERIOD_TWELVE_HOURS:
                heading = 'Last 12 hours';
                break;
            case PERIOD_TWENTY_FOUR_HOURS:
                heading = 'Last 24 hours';
                break;
            case PERIOD_SEVEN_DAYS:
                heading = '7 days';
                break;
            case PERIOD_THIRTY_ONE_DAYS:
                heading = '31 days';
                break;
            case PERIOD_TODAY:
                heading = 'Today';
                break;
            case PERIOD_YESTERDAY:
                heading = 'Yesterday';
                break;
            case PERIOD_DAY_BEFORE_YESTERDAY:
                heading = 'Day Before Yesterday';
                break;
            case PERIOD_THIS_WEEK:
                heading = 'This Week';
                break;
            case PERIOD_PREVIOUS_WEEK:
                heading = 'Previous Week';
                break;
            default:
                break;
        }

        // Set the subheading.
        if (from.diff(to, 'day') !== 0) {

            // The selected period greater than one day.
            subheading = `${from.format('MMM DD')} - ${to.format('MMM DD')}`;
        } else {

            // The selected period is a same day enquiry.
            subheading = from.format('MMM DD');
        }

        // Calculate the time difference and set the interval.
        if (time <= 60000) {
            // Minute or less.
            interval = INTERVAL_SECOND;
        } else if (time <= 300000) {
            // Five minutes or less.
            interval = INTERVAL_SECOND;
        } else if (time <= 600000) {
            // Ten minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 900000) {
            // Fifteen minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 1800000) {
            // Thirty minutes or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 3600000) {
            // Hour or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 14400000) {
            // Four hours or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 21600000) {
            // Six hours or less.
            interval = INTERVAL_MINUTE;
        } else if (time <= 43200000) {
            // Twelve hours or less.
            interval = INTERVAL_ONE_HOUR;
        } else if (time <= 86400000) {
            // 24 hour period - set interval to hourly.
            interval = INTERVAL_ONE_HOUR;
        } else if (time <= 137460000) {
            // Twenty four hours of less - set interval to hourly..
            interval = INTERVAL_ONE_HOUR;
        } else if (time <= 604800000) {
            // Over week period - set interval to a day.
            interval = INTERVAL_ONE_DAY;
        } else if (time <= 2678400000) {
            // Over a month period - set interval to a day.
            interval = INTERVAL_ONE_DAY;
            comparisonSupported = false;
        }

        let previousFrom: Dayjs = from,
            previousTo: Dayjs = to;

        switch (periodOption) {
            case PERIOD_MINUTE:
                previousFrom = previousFrom.subtract(1, 'minute');
                previousTo = previousTo.subtract(1, 'minute');
                break;
            case PERIOD_FIVE_MINUTES:
                previousFrom = previousFrom.subtract(5, 'minute');
                previousTo = previousTo.subtract(5, 'minute');
                break;
            case PERIOD_TEN_MINUTES:
                previousFrom = previousFrom.subtract(10, 'minute');
                previousTo = previousTo.subtract(10, 'minute');
                break;
            case PERIOD_THIRTY_MINUTES:
                previousFrom = previousFrom.subtract(30, 'minute');
                previousTo = previousTo.subtract(30, 'minute');
                break;
            case PERIOD_ONE_HOUR:
                previousFrom = previousFrom.subtract(1, 'hour');
                previousTo = previousTo.subtract(1, 'hour');
                break;
            case PERIOD_SIX_HOURS:
                previousFrom = previousFrom.subtract(6, 'hour');
                previousTo = previousTo.subtract(6, 'hour');
                break;
            case PERIOD_TWELVE_HOURS:
                previousFrom = previousFrom.subtract(12, 'hour');
                previousTo = previousTo.subtract(12, 'hour');
                break;
            case PERIOD_TWENTY_FOUR_HOURS:
                previousFrom = previousFrom.subtract(1, 'day');
                previousTo = previousTo.subtract(1, 'day');
                break;
            case PERIOD_SEVEN_DAYS:
                previousFrom = previousFrom.subtract(7, 'day');
                previousTo = previousTo.subtract(7, 'day');
                break;
            case PERIOD_THIRTY_ONE_DAYS:
                previousFrom = previousFrom.subtract(31, 'day');
                previousTo = previousTo.subtract(31, 'day');
                break;
            case PERIOD_TODAY:
                previousFrom = previousFrom.subtract(1, 'day');
                previousTo = previousTo.subtract(1, 'day');
                break;
            case PERIOD_YESTERDAY:
                previousFrom = previousFrom.subtract(1, 'day');
                previousTo = previousTo.subtract(1, 'day');
                break;
            case PERIOD_DAY_BEFORE_YESTERDAY:
                previousFrom = previousFrom.subtract(1, 'day');
                previousTo = previousTo.subtract(1, 'day');
                break;
            case PERIOD_THIS_WEEK:
                previousFrom = previousFrom.subtract(1, 'week');
                previousTo = previousTo.subtract(1, 'week');
                break;
            case PERIOD_PREVIOUS_WEEK:
                previousFrom = previousFrom.subtract(1, 'week');
                previousTo = previousTo.subtract(1, 'week');
                break;
            default:

                // Assume custom period, so get the immediate period prior to the selected range.
                previousFrom = from.subtract(to.diff(from, 'millisecond'), 'millisecond');
                previousTo = to.subtract(to.diff(from, 'millisecond'), 'millisecond');

                break;
        }

        if (previousFrom.isBefore(dayjs.default().subtract(31, 'day'))) {

            // Comparisons beyond the 31=day period is not supported.
            comparisonSupported = false;
        }

        const timeFormat: string = ((periodOption === PERIOD_MINUTE || periodOption === PERIOD_FIVE_MINUTES) ? 'HH:mm:ss' : 'HH:mm:00');

        let newPeriod: Period = {
            chartTickInterval: Helper.getChartTickInterval(from, to, periodOption, interval),
            comparisonSupported,
            isValid: true,
            timestamp: `${dayjs.default().valueOf()}`,
            dateFormat: Helper.getChartDateFormat(interval),
            api: {
                interval,
                periodOption,
                time: 1,
                timezone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
                current: {
                    from: from.format(`YYYY-MM-DD+${timeFormat}`),
                    to: to.format(`YYYY-MM-DD+${timeFormat}`)
                },
                previous: {
                    from: previousFrom.format(`YYYY-MM-DD+${timeFormat}`),
                    to: previousTo.format(`YYYY-MM-DD+${timeFormat}`)
                }
            },
            ui: {
                heading,
                subheading,
                queryString: '',
                current: {
                    chartCategories: Helper.getChartAxisCategories(from, to, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(from, to, periodOption, interval),
                    from,
                    to
                },
                previous: {
                    chartCategories: Helper.getChartAxisCategories(previousFrom, previousTo, interval),
                    formattedChartCategories: Helper.getFormattedChartAxisCategories(previousFrom, previousTo, periodOption, interval),
                    from: previousFrom,
                    to: previousTo
                }
            },
            filters: options ? getFilters(options) : period.filters
        };

        newPeriod.ui.subheading = Helper.getFormattedPeriodSubheading(newPeriod, REPORT_PERIOD_CURRENT);

        setPeriod(newPeriod);
        setUpdateKey(dayjs.default().valueOf());

        const agg = JSON.parse(window.localStorage.getItem('dbmar-interval') || '0')
        const aggValid = Helper.aggregateIsValid(agg, timeRangeContext)

        const currentPath = history.location.pathname;
        history.replace(currentPath);

        timeRangeContext.setTimeRange({
            from: from,
            to: to,
            timezone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            interval: aggValid ? agg : 0,
        })
    }, [period.filters]);

    const setFilterOptions = useCallback(function (options: FilterOption[]) {

        let updatedPeriod: Period = period;
        const parameters: string = getFilterParameters(options);

        // Update the filter parameters.
        updatedPeriod.timestamp = `${from.valueOf()}${to.valueOf()}-$parameters}`;
        updatedPeriod.filters = {
            options,
            parameters
        };

        setPeriod(updatedPeriod);

        setUpdateKey(dayjs.default().valueOf());
    }, [period]);

    useEffect(() => {
        const getData = async() => {
            const instances = await InstanceApi.getInstances()

            setInstances(instances);

            const hosts = await HostApi.getHosts()
            for (let index = 0; index < hosts.length; index++) {

                if (index >= TARGET_COLOUR_CODES.length) {
                    break;
                }

                // Set a unique colour for the host.
                hosts[index].color = TARGET_COLOUR_CODES[index]
            }

            setHosts(hosts);

            let eventTypes: EventType[] = await EventTypeApi.getEventTypes();

            setEventTypes(eventTypes);

            IntegrationsApi.getIntegrations().then((instanaIntegrations: InstanaIntegration[]) => {
                setInstanaIntegrations(instanaIntegrations);
            })
            LicenceApi.getLicences().then((licences: Licence[]) => {
                for (let index = 0; index < licences.length; index++) {

                    // Build the licence settings (via the random JSON properties field).
                    licences[index].settings = JSON.parse(licences[index].properties);

                    if (licences[index].settings && licences[index].settings?.enddate) {

                        // Get the end date.
                        const enddate = licences[index].settings?.enddate;

                        if (enddate !== undefined) {

                            // Depending upon the licence expiry, set the CSS class name to be applied.
                            if (dayjs.default().isAfter(dayjs.default(enddate))) {

                                // Licence has expired.
                                licences[index].class = 'text-danger';

                            } else if (dayjs.default().add(1, 'month').isAfter(enddate)) {

                                // Due to expire in the next four weeks.
                                licences[index].class = 'text-amber';

                            } else if (dayjs.default().add(6, 'week').isAfter(enddate)) {

                                // Due to expire in the next six weeks.
                                licences[index].class = 'text-warning';
                            }
                        }
                    }
                }

                setLicences(licences);
                setLicencesLoaded(DATA_LOADED);
            });
            fetchFilters().then(setFilters)
            setLoading(DATA_LOADED);
        };
        getData();

    }, [period]);

    function random(): number {
        return process.env.NODE_ENV === 'test' ? 0 : Math.random();
    }


    useEffect(() => {

        let timeout: NodeJS.Timeout;

        if (loading !== DATA_LOADED) {
            timeout = setInterval(() => setMessage(messages[Math.floor(random() * messages.length)]), 9000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [loading, message]);


    if (document.location.pathname === '/' && process.env.NODE_ENV !== 'test') {

        // Todo: remove this JavaScript redirect once we have a root dashboard view sorted.
        document.location.href = `${document.location.href}instances`
    }

    return (
        <>
            <ConditionalRender if={loading === DATA_LOADED}>
                <div>
                    <div className={`right-panel ${isOpen ? 'chat-visible' : 'chat-hidden'}`}>
                        <div className="chat-arrow" onClick={() => toggleChat('')}>
                            <i className={`fal  fa-fw  ${!isOpen ? 'fa-angle-left' : 'fa-angle-right'}`}></i>
                        </div>
                        <ChatComponent data={chatData}/>
                    </div>
                    <div className={`container-fluid ${className}`}>
                        <MenuSide mustLogIn={props.showLogOut} instances={instances} hosts={hosts} licences={licences}
                                  toggleMenu={toggleMenu}
                                  loading={licencesLoaded}/>
                        <InstanceContext.Provider value={{ instances, setInstances }}>
                            <HostContext.Provider value={{ hosts, setHosts }}>
                                <EventTypeContext.Provider value={{ eventTypes, setEventTypes }}>
                                    <InstanaIntegrationContext.Provider
                                        value={{ instanaIntegrations, setInstanaIntegrations }}>
                                        <LicenceContext.Provider value={{ licences, setLicences }}>
                                            <FilterContext.Provider value={{ filters, setFilters }}>
                                                <ChatContext.Provider
                                                    value={{ isOpen, chatData, toggleChat }}>
                                                    <ToastProvider>
                                                        <Switch>

                                                            {/* Dashboards */}
                                                            {/*<Route exact path="/" render={(props) => <Dashboard {...props} key={period.timestamp} period={period} instances={instances} hosts={hosts} eventTypes={eventTypes} toggleMenu={toggleMenu} applyPeriod={applyPeriod} />} />*/}
                                                            <Route exact path="/event-history"
                                                                   render={(props) =>
                                                                       <EventHistory {...props}
                                                                                     key={updateKey}
                                                                                     period={period}
                                                                                     instances={instances}
                                                                                     hosts={hosts}
                                                                                     eventTypes={eventTypes}
                                                                                     toggleMenu={toggleMenu}
                                                                                     applyPeriod={applyPeriod}/>}/>

                                                            <Route path="/sql-search"
                                                                   render={(props) => <SQLSearch {...props}
                                                                                                 instances={instances}
                                                                                                 key={updateKey}/>}/>

                                                            {/* Instances */}
                                                            <Route path="/instances"
                                                                   render={(props) => <Instances {...props}
                                                                                                 key={updateKey}
                                                                                                 period={period}
                                                                                                 instances={instances}
                                                                                                 hosts={hosts}
                                                                                                 eventTypes={eventTypes}
                                                                                                 toggleMenu={toggleMenu}
                                                                                                 applyPeriod={applyPeriod}
                                                                                                 setFilterOptions={setFilterOptions}/>}/>

                                                            {/* Hosts */}
                                                            <Route path="/hosts"
                                                                   render={(props) => <Hosts {...props}
                                                                                             key={updateKey}
                                                                                             period={period}
                                                                                             instances={instances}
                                                                                             hosts={hosts}
                                                                                             eventTypes={eventTypes}
                                                                                             toggleMenu={toggleMenu}
                                                                                             applyPeriod={applyPeriod}
                                                                                             setFilterOptions={setFilterOptions}/>}/>


                                                            {/* Reports */}
                                                            <Route path="/reports"
                                                                   render={(props) => <Reports {...props}
                                                                                               key={updateKey}
                                                                                               period={period}
                                                                                               instances={instances}
                                                                                               hosts={hosts}
                                                                                               eventTypes={eventTypes}
                                                                                               applyPeriod={applyPeriod}
                                                                                               toggleMenu={toggleMenu}/>}/>

                                                            {/* Admin */}
                                                            <Route path="/admin"
                                                                   render={(props) => <Admin {...props}
                                                                                             key={updateKey}
                                                                                             period={period}
                                                                                             instances={instances}
                                                                                             hosts={hosts}
                                                                                             eventTypes={eventTypes}
                                                                                             instanaIntegrations={instanaIntegrations}
                                                                                             licences={licences}
                                                                                             toggleMenu={toggleMenu}
                                                                                             applyPeriod={applyPeriod}/>}/>


                                                            {/* Not Found Page */}
                                                            <Route exact path="/not-found" render={() => <NotFound />}/>

                                                            {/* Catch-all route */}
                                                            <Redirect to="/not-found" />
                                                        </Switch>
                                                    </ToastProvider>
                                                </ChatContext.Provider>
                                            </FilterContext.Provider>
                                        </LicenceContext.Provider>
                                    </InstanaIntegrationContext.Provider>
                                </EventTypeContext.Provider>
                            </HostContext.Provider>
                        </InstanceContext.Provider>
                        <Footer/>
                        <ReactTooltip className="tooltip" effect="solid" html={true}/>
                    </div>
                </div>
            </ConditionalRender>

            <ConditionalRender if={loading === DATA_LOADING}>
                <div className="initialising">
                    <div className="w-100 text-center text-muted">
                        <div className="spinner"><img src="/icons/icon.png" className="fish" width="100"
                                                      height="100"
                                                      alt="DBmarlin"/></div>
                        <div className="background">
                            <div className="waves"></div>
                            <div className="message">{message}</div>
                        </div>
                    </div>
                </div>
            </ConditionalRender>
        </>
    );
}

export default App;
