import { useContext, useMemo, useState } from 'react';

// Third-parties.
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import ConditionalRender from "../../../helpers/ConditionalRender";

// Helper.
import Helper from '../../../helpers/Helper';
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";

// Types.
import HostTarget from '../../../types/host/HostTarget';
import ChartPlotBand from '../../../types/ChartPlotBand';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartSeries from '../../../types/ChartSeries';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';

// Constants.
import {
    DATA_INITIALISING,
    DATA_LOADING,
    DATA_LOADED,
    CHART_COLOURS_STATEMENTS, CHART_DATA_TYPE
} from '../../Constants';
import { VIEW_MODE } from '../../Constants';
import { getConvertedTimeToUTC, highchartsCredits } from "../../../helpers/utils";
import {useLicences} from "../../../context/LicenceContext";
import { TimeRangeContext } from "../../../context/TimeRangeContext";
import api from "../../../api/Base";

function DiskChart(props: {host: HostTarget, plotBands: ChartPlotBand[], plotLines: ChartPlotLine[], applyPeriod: Function }) {
    const [loadingBasic, setLoadingBasic] = useState<number>(DATA_LOADING);
    const [loadingDetail, setLoadingDetail] = useState<number>(DATA_INITIALISING);

    const [writeSeries, setWriteSeries] = useState<ChartSeries[]>([]);
    const [readWrittenSeries, setReadWrittenSeries] = useState<ChartSeries[]>([]);

    const [basicTimeSeries, setBasicTimeSeries] = useState<number[][]>([]);
    const [timeSeries, setTimeSeries] = useState<number[][]>([]);
    const [mode, setMode] = useState<number>(VIEW_MODE.BASIC);
    const { licences } = useLicences ();
    const timeRangeContext = useContext(TimeRangeContext)

    const timePeak = useMemo(() => {
        return Helper.getPeakValue(basicTimeSeries.map(i => i[1]))
    }, [basicTimeSeries])

    void useMemo(async() => {
        api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&statistic=ioutilisation&id=${props.host.id}`)
            .then((response: { data: HostTimeStatistic[]; }) => {
                setBasicTimeSeries(response.data.map(item => {
                    return [getConvertedTimeToUTC(item), item.avg]
                }));
                setLoadingBasic(DATA_LOADED);
            })
            .catch((error: any) => {
                console.error('Failed to retrieve activity time.', error);
            })
    }, [timeRangeContext, props.host, props.plotLines]);

    const basicChart = useMemo(() => {
        const basicChartOptions = chartOptionsGranularity(props.applyPeriod, [
            {
                name: 'Average disk I/O',
                type: 'spline',
                minHeight: 3,
                data: basicTimeSeries,
                zIndex: 1,
                yAxis: 1,
            }], timePeak, {
            credits: {enabled: highchartsCredits(licences)},
            timeRangeContext: timeRangeContext,
            // plotLines: props.plotLines,
            // plotBands: props.plotBands,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.PERCENTAGE);
                }
            },
        })
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={basicChartOptions}/>;

    }, [basicTimeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);


    async function getReadData() {

        // Set the metrics we're going to retrieve via API calls.
        const metrics: string[] = ['K read', 'K written']

        let seriesData: any[] = [];
        // Get detailed data.
        for (let index = 0; index < metrics.length; index++) {

            // Set the metrics we're going to retrieve via API calls.
            const metric = metrics[index].replace(' ', '%20');
            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&limit=${process.env.REACT_APP_API_LIMIT}&statistic=${metric}&id=${props.host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    seriesData.push({
                        name: metrics[index],
                        // Warning: if updating, make sure the index doesn't exceed the available colour array.
                        color: CHART_COLOURS_STATEMENTS[index],
                        type: 'column',
                        data: response.data.map(dat => {
                            return [getConvertedTimeToUTC(dat), dat.avg]
                        }),
                        zIndex: 1

                    });

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor metric over time for host', error, props.host, metrics[index]);
                })
        }
        setWriteSeries(seriesData)
    }

    async function getWrittenData() {

        // Set the metrics we're going to retrieve via API calls.
        const metrics: string[] = ['reads completed', 'writes completed', 'I/Os currently in progress'];

        let seriesData: any[] = [];
        // Get detailed data.
        for (let index = 0; index < metrics.length; index++) {

            // Set the metrics we're going to retrieve via API calls.
            const metric = metrics[index].replace(' ', '%20');

            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=${metric}&id=${props.host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    seriesData.push({
                        name: metrics[index],
                        // Warning: if updating, make sure the index doesn't exceed the available colour array.
                        color: CHART_COLOURS_STATEMENTS[index],
                        type: 'column',
                        data: response.data.map(dat => {
                            return [getConvertedTimeToUTC(dat), dat.avg]
                        }),
                        zIndex: 1

                    });

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor metric over time for host', error, props.host, metrics[index]);
                })
        }
        setReadWrittenSeries(seriesData)
    }


    async function getTimeData() {

        // Set the metrics we're going to retrieve via API calls.
        const metrics: string[] = ['time spent reading (ms)', 'time spent writing (ms)', 'time spent doing I/Os (ms)', 'weighted time spent doing I/Os (ms)'];

        let seriesData: any[] = [];
        // Get detailed data.
        for (let index = 0; index < metrics.length; index++) {

            // Set the metrics we're going to retrieve via API calls.
            const metric = metrics[index].replace(' ', '%20');


            await api.get(`host/statistic/time?${timeRangeContext.getTimeRangeQueryString()}&statistic=${metric}&id=${props.host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {
                   let type: string = ((metrics[index] === 'time spent doing I/Os (ms)' || metrics[index] === 'weighted time spent doing I/Os (ms)') ? 'spline' : 'column');
                    seriesData.push(
                        {
                        name: metrics[index],
                        // Warning: if updating, make sure the index doesn't exceed the available colour array.
                        color: CHART_COLOURS_STATEMENTS[index],
                        type: type,
                        data: response.data.map(dat => {
                            return [getConvertedTimeToUTC(dat), dat.avg]
                        }),
                        zIndex: 1,
                        yAxis: ((type === 'spline') ? 1 : 0)
                    });

                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor metric over time for host', error, props.host, metrics[index]);
                })
        }
        // Mark the content as loaded.
        setLoadingDetail(DATA_LOADED);
        setTimeSeries(seriesData)
    }

    const chartOptions = {
        credits: {enabled: highchartsCredits(licences)},
        timeRangeContext: timeRangeContext,
        legend: {
            align: 'left',
            backgroundColor: 'rgba(255,255,255,0.25)',
            enabled: false,
            floating: false,
            itemMarginTop: 0,
            itemMarginBottom: 2,
            itemStyle: {
                color: '#212529',
                fontSize: '.7rem'
            },
            layout: 'horizontal',
            padding: 0,
            x: 0,
            y: 0,
            verticalAlign: 'top'
        },
        // plotLines: props.plotLines,
        // plotBands: props.plotBands,
        tooltip: {
            formatter: function () {
                return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
            }
        },
    }

    const readWritesChart = useMemo(() => {
        const detailChartOptions = chartOptionsGranularity(props.applyPeriod,
            writeSeries, 100, chartOptions)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={detailChartOptions}/>;

    }, [writeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    const readWrittenChart = useMemo(() => {
        const detailChartOptions = chartOptionsGranularity(props.applyPeriod,
            readWrittenSeries, 100, chartOptions)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={detailChartOptions}/>;

    }, [readWrittenSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    const timeChart = useMemo(() => {
        const detailChartOptions = chartOptionsGranularity(props.applyPeriod,
            timeSeries, 100, chartOptions)
        return <HighchartsReact useUtcconstructorType={"chart"} highcharts={Highcharts} options={detailChartOptions}/>;

    }, [timeSeries, timePeak, timeRangeContext, props.plotBands, props.plotLines, licences]);

    function toggleChart() {

        // Do we need to make additional API calls to get the detailed chart data?
        if (loadingDetail === DATA_INITIALISING) {
            setLoadingDetail(DATA_LOADING)
            getReadData();
            getWrittenData();
            getTimeData();
        }

        // Toggle the displayed chart.
        setMode(((mode === VIEW_MODE.BASIC) ? VIEW_MODE.DETAIL : VIEW_MODE.BASIC));
    }

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true" />
                Average I/O over time
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#collapseDiskCharts" aria-expanded="false" aria-controls="collapseDiskCharts" />
                <div className="btn-group float-end">
                    <button type="button" className="btn btn-xsm btn-primary float-end ms-1" onClick={toggleChart}>{((mode === VIEW_MODE.BASIC) ? 'Show Breakdown' : 'Show Summary')}</button>
                </div>
            </div>
            <div id="collapseDiskCharts" className="card-body collapse show p-0">
                <div className="row row-cols-1">
                    <div className="col p-3">
                        <h4 className='icons-chart-title'>
                            Average I/O
                            {timePeak !== null && (
                                <span className="peak">Peak Average I/O {Math.round(timePeak)}%</span>
                            )}
                        </h4>
                        <ConditionalRender if={mode === VIEW_MODE.BASIC}>
                            <ConditionalRender if={loadingBasic === DATA_LOADING}>
                                <div className="w-100 text-center text-muted mt-3">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="mt-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={loadingBasic === DATA_LOADED}>
                                {basicChart}
                            </ConditionalRender>
                        </ConditionalRender>

                        <ConditionalRender if={mode === VIEW_MODE.DETAIL}>
                            <ConditionalRender if={loadingDetail === DATA_LOADING}>
                                <div className="w-100 text-center text-muted mt-3">
                                    <div className="loader spinner chartSpinner">
                                    </div>
                                    <p className="mt-3">
                                        Loading data...
                                    </p>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={loadingDetail === DATA_LOADED}>
                                {readWritesChart}
                                <br />
                                <br />
                                {readWrittenChart}
                                <br />
                                <br />
                                {timeChart}
                            </ConditionalRender>
                        </ConditionalRender>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiskChart;
