import React, { useMemo, useState } from 'react';

// Third-party packages.

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Helper.
import Helper from '../../../helpers/Helper';

// Types.
import Period from '../../../types/Period';
import HostTarget from '../../../types/host/HostTarget';
import HostTimeStatistic from '../../../types/host/HostTimeStatistic';
import ChartSeries from '../../../types/ChartSeries';
import ChartPlotLine from '../../../types/ChartPlotLine';
import ChartPlotBand from '../../../types/ChartPlotBand';

// Constants.
import { COLOUR_HOST_PROCESSOR, COLOUR_HOST_MEMORY, CHART_DATA_TYPE } from '../../../component/Constants';

// Highcharts boost mode.
import Boost from 'highcharts/modules/boost';
import {highchartsCredits} from "../../../helpers/utils";
import {useLicences} from "../../../context/LicenceContext";
import api from "../../../api/Base";
Boost(Highcharts);

// Highchart modules - note, the position of these in this file are important.
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function HostsProcessorMemoryChart(props: { period: Period, matchedHosts:[HostTarget[], HostTarget[]], chartCategories: string[], plotBands: ChartPlotBand[], plotLines: ChartPlotLine[] }) {
    const { licences } = useLicences();
    const [processorSeries, setProcessorSeries] = useState<ChartSeries[]>([]);
    const [processorPeak, setProcessorPeak] = useState<null | number>(null);
    const [memorySeries, setMemorySeries] = useState<ChartSeries[]>([]);
    const [memoryPeak, setMemoryPeak] = useState<null | number>(null);

    // Get host chart metrics.
    useMemo(() => {

        async function getHostsChartData() {

            let processorChartSeries: ChartSeries[] = [],
                processorPeakSeries: number[] = [],
                memoryChartSeries: ChartSeries[] = [],
                memoryPeakSeries: number[] = [];

            // Get a unique list of all hosts for both possible instances and periods.
            const filteredHosts = Helper.getUniqueArrayValues(props.matchedHosts[0], props.matchedHosts[1]);

            for (let index = 0; index < filteredHosts.length; index++) {

                const host = filteredHosts[index];
                
                // Get processors over time.
                let processorData: (number | null)[] = [],
                    memoryData: (number | null)[] = [];

                //#region Processor Usage

                // Get processor data over time for the first period.
                await api.get(`host/statistic/time?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=cpuutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.previous.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            processorData.push(0);
                        } else {
                            // Data exists.
                            processorData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor usage over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    processorPeakSeries.push(Helper.getPeakValue(processorData));
                });
    
                // Interim data push.
                processorData.push(null);

                // Get processor data over time for the second period.
                await api.get(`host/statistic/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=cpuutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.current.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            processorData.push(0);
                        } else {
                            // Data exists.
                            processorData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor usage over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    processorPeakSeries.push(Helper.getPeakValue(processorData));
                });
        
                // Add the chart series.
                processorChartSeries.push({
                    name: `${host.name} (CPU)`,
                    color: COLOUR_HOST_PROCESSOR,
                    type: 'spline',
                    data: processorData,
                    tooltip: {
                        valueSuffix: '%'
                    },
                    zIndex: 1
                });

                //#endregion Processor Usage

                //#region Memory Usage

                // Get memory data over time for the first period.
                await api.get(`host/statistic/time?from=${props.period.api.previous.from}&to=${props.period.api.previous.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=memoryutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.previous.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.previous.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            memoryData.push(0);
                        } else {
                            // Data exists.
                            memoryData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor usage over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    memoryPeakSeries.push(Helper.getPeakValue(memoryData));
                });
    
                // Interim data push.
                memoryData.push(null);

                // Get memory data over time for the second period.
                await api.get(`host/statistic/time?from=${props.period.api.current.from}&to=${props.period.api.current.to}&tz=${props.period.api.timezone}&interval=${props.period.api.interval}&statistic=memoryutilisation&id=${host.id}`)
                .then((response: { data: HostTimeStatistic[]; }) => {

                    // Check, does the time match?
                    for (let index = 0; index < props.period.ui.current.chartCategories.length; index++) {

                        let result = response.data.filter((item: HostTimeStatistic) => {
                            return item.timeslice === props.period.ui.current.chartCategories[index]
                        });

                        if (result.length === 0) {
                            // Data missing.
                            memoryData.push(0);
                        } else {
                            // Data exists.
                            memoryData.push(Math.round(result[0].avg));
                        }
                    }
                })
                .catch((error: any) => {
                    console.error('Failed to retrieve processor usage over time data', error, host);
                })
                .then(() => {
                    
                    // Get the peak time spent value.
                    memoryPeakSeries.push(Helper.getPeakValue(memoryData));
                });
        
                // Add the chart series.
                memoryChartSeries.push({
                    name: `${host.name} (Memory)`,
                    color: COLOUR_HOST_MEMORY,
                    type: 'spline',
                    data: memoryData,
                    tooltip: {
                        valueSuffix: '%'
                    },
                    zIndex: 1
                });

                //#endregion Memory Usage
            }

            setProcessorSeries(processorChartSeries);
            setMemorySeries(memoryChartSeries);
            setProcessorPeak(Helper.getPeakValue(processorPeakSeries));
            setMemoryPeak(Helper.getPeakValue(memoryPeakSeries));
        }

        getHostsChartData();

    }, [props.matchedHosts, props.period.api, props.period.ui]);

    const hostsChart = useMemo(() => {

        const options = {
            chart: {
                height: '200px',
                spacing: [0, 0, 0, 0],
                type: 'column'
            },
            title: { text: '' },
            xAxis: [{
                categories: props.chartCategories,
                crosshair: true,
                plotLines: props.plotLines,
                plotBands: props.plotBands,
                tickInterval: props.period.chartTickInterval
            }],
            yAxis: [{
                labels: {
                    align: 'left',
                    enabled: true,
                    x: 0,
                    y: 15
                },
                min: 0,
                max: 100,
                showFirstLabel: false,
                title: {
                    text: ''
                }
            }],
            plotOptions: {
				animation: false,
                column: { stacking: 'normal' },
                series: { marker: { enabled: false } }
            },
            legend: {
                align: 'center',
                backgroundColor: 'rgba(255,255,255,0.25)',
                borderColor: '#dee2e6',
                borderRadius: 5,
                borderWidth: 1,
                enabled: true,
                floating: false,
                itemMarginTop: 0,
                itemMarginBottom: 0,
                itemStyle: {
                    color: '#212529',
                    fontSize: '.7rem'
                },
                layout: 'horizontal',
                padding: 10,
                x: 0,
                y: 0,
                verticalAlign: 'bottom'
            },
            series: [...processorSeries, ...memorySeries],
            exporting: { enabled: false },
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltips(this, CHART_DATA_TYPE.PERCENTAGE);
                },
                borderWidth: 0,
                outide: true,
                padding: 0,
                shadow: false,
                shared: true,
                useHTML: true
            },
            credits: { enabled: highchartsCredits(licences) },
            accessibility: {
                enabled: false
            }
        };

        return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={options} />

    }, [props.period, props.chartCategories, props.plotBands, props.plotLines, processorSeries, memorySeries])

    return (
        <React.Fragment>
            <h4>
                Average CPU and memory (%)
                {processorPeak !== null && memoryPeak !== null 
                    && processorPeak !== Infinity && memoryPeak !== Infinity 
                    && processorPeak !== -Infinity && memoryPeak !== -Infinity && (
                    <span className="peak">Peak Average CPU: {Helper.getTimeInEnglish(processorPeak)}, Peak Average Memory: {Helper.getTimeInEnglish(memoryPeak)}</span>
                )}
            </h4>
            {hostsChart}
        </React.Fragment>
    );
}

export default HostsProcessorMemoryChart;
