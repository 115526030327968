import { createContext, useContext } from 'react';

// Types.
import HostTarget from '../types/host/HostTarget';

// Set the internal context object.
let hosts: HostTarget[] = [];

// Define the context type, including the update function.
export type HostContextType = {
    hosts: HostTarget[];
    setHosts: (HostTargets: HostTarget[]) => void;
}

// Define the context object.
export const HostContext = createContext<HostContextType>({ hosts: hosts, setHosts: hosts => console.warn('No hosts set.', hosts) });
HostContext.displayName = 'Hosts';

// Export the update function for the context object.
export const useHostTargets = () => useContext(HostContext);