import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Third-parties.
import * as dayjs from 'dayjs';
import { InstanceContext } from "../../../context/InstanceContext";

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';
import { getDefaultPageSize, getToolTipPosition } from "../../../helpers/utils";

// Types.
import Wait from "../../../types/instance/Wait";
import GroupedBatchesDataTable from '../../../types/instance/tables/GroupedBatchesDataTable';

// Components.
import GroupedBatchBreakdown from '../GroupedBatchBreakdown';
import GenericBreakdown from '../../GenericBreakdown';
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';
import StatementToolTip from "./StatementToolTip";

// Constants.
import { DATA_LOADING, INSTANCE_TYPE_SQLSERVER } from "../../Constants";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

function GroupedBatchesTable(props: {
    loading: number,
    filtersParameters: string,
    instanceId: number,
    instanceTime: number,
    waits: Wait[],
    batchesDataTable: GroupedBatchesDataTable[],
    isBatchesForStatement: boolean
}) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    const {instances} = useContext(InstanceContext);
    const instance = instances.find(i => i.id === props.instanceId)
    const instanceType = instance?.type ?? ''
    const timeRangeContext = useContext(TimeRangeContext)


    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const data = useMemo(() => props.batchesDataTable.filter(row => {
        return (
            (row.batchgrouphash === null ? '' : row.batchgrouphash).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (row.sqltext === null ? '' : row.sqltext).toLowerCase().includes(tableFilter.toLowerCase()) ||
            (row.batch.sqlid === null ? '' : row.batch.sqlid).toLowerCase().includes(tableFilter.toLowerCase())
        )
    }), [props.batchesDataTable, tableFilter]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'batch',
            disableSortBy: true
        },
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            accessor: 'color',
            disableSortBy: true
        },
        {
            accessor: 'batchwaittime',
            disableSortBy: true
        },
        {
            Header: '#',
            accessor: 'batchgrouphash',
            className: 'concatenate-small col-width-5',
            defaultCanSort: true,
            sortType: 'alphanumeric',
            Cell: ({cell}) => (
                <React.Fragment>
                    <span className="me-1 ps-1" style={{backgroundColor: cell.row.values.color}}>&nbsp;</span>
                    <Link
                        to={`/instances/${props.instanceId}/activity/groupedbatch/${cell.row.values.batchgrouphash}/text?${timeRangeContext.getTimeRangeRedirectString()}`}>{((cell.row.values.batchgrouphash === null) ? '-' : cell.row.values.batchgrouphash)}</Link>
                </React.Fragment>
            ),
        },
        {
            Header: 'Grouped Batch',
            accessor: 'sqltext',
            className: 'concatenate',
            disableSortBy: true,
            Cell: ({cell}) => cell.row.values.sqltext ? <Link
                    to={`/instances/${props.instanceId}/activity/groupedbatch/${cell.row.values.batchgrouphash}/text?${timeRangeContext.getTimeRangeRedirectString()}`}>{cell.row.values.sqltext.substring(0, 200)}</Link> :
                <React.Fragment>-</React.Fragment>,
        },
        {
            Header: 'Breakdown',
            accessor: 'statement-waits',
            className: 'statement-waits col-width-5',
            disableSortBy: true,
            Cell: ({cell}) => (
                cell.row.values.time !== null ? (
                    cell.row.values.id < 10 ? (
                        <GroupedBatchBreakdown filterParameters={props.filtersParameters} batch={cell.row.values.batch}
                                               waits={props.waits} instanceId={props.instanceId}
                                               instanceTime={props.instanceTime}/>
                    ) : (
                        cell.row.values.id < 20 ? (
                            <GenericBreakdown individualTime={cell.row.values.waittime} totalTime={props.instanceTime}
                                              color="#999" tooltip={cell.row.values.id.toString()}/>
                        ) : (
                            <React.Fragment>-</React.Fragment>
                        )
                    )
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Executions',
            accessor: 'executions',
            headerClassName: 'text-end',
            className: 'text-end col-width-5',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                Helper.getFormattedNumber(cell.row.values.executions)
            )
        },
        {
            Header: 'Average Time',
            accessor: 'averagetime',
            headerClassName: 'text-end',
            className: 'text-end col-width-5',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.averagetime !== null && cell.row.values.executions !== null && cell.row.values.executions > 0 ? (
                    <React.Fragment>
                        {Helper.getTimeInEnglish(cell.row.values.averagetime)}
                    </React.Fragment>
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Total Time',
            accessor: 'waittime',
            headerClassName: 'text-end',
            className: 'text-end  col-width-5 text-nowrap',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.waittime !== null && cell.row.values.waittime > 0 ? (
                    <React.Fragment>
                        {Helper.getTimeInEnglish(cell.row.values.waittime)}
                    </React.Fragment>
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
        {
            Header: 'Weight %',
            accessor: 'weight',
            headerClassName: 'text-end',
            className: 'text-end col-width-5',
            defaultCanSort: true,
            sortType: 'basic',
            Cell: ({cell}) => (
                cell.row.values.waittime !== null ? (
                    Boolean(props.isBatchesForStatement) === true ? (
                        <React.Fragment>
                            {Number((100 / cell.row.values.batchwaittime) * cell.row.values.waittime).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {Number((100 / props.instanceTime) * cell.row.values.waittime).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
                        </React.Fragment>
                    )
                ) : (
                    <React.Fragment>-</React.Fragment>
                )
            )
        },
    ], [props.instanceId, props.instanceTime, props.isBatchesForStatement, props.filtersParameters, props.waits, instanceType]);

    let hiddenColumns: string[] = [];

    switch (instanceType) {
        case INSTANCE_TYPE_SQLSERVER:
            hiddenColumns = ['batch', 'id', 'color', 'batchwaittime', 'averagetime', 'executions'];
            break;

        default:
            hiddenColumns = ['batch', 'id', 'color', 'batchwaittime'];

            break;
    }

    if (Boolean(props.isBatchesForStatement)) {
        hiddenColumns = ['batch', 'id', 'color', 'batchwaittime', 'averagetime', 'executions', 'statement-waits']
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: hiddenColumns,
                pageIndex: 0,
                pageSize: getDefaultPageSize(),
                sortBy: [{id: 'waittime', desc: true}]
            }
        },
        useSortBy, usePagination);

    return (
        <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2 table-search">
                <div className="col col-md-9">
                    <CSVLink role="button"
                             headers={[{label: 'ID', key: 'id'}, {
                                 label: 'Batch Text',
                                 key: 'sqltext'
                             }, {label: 'Executions', key: 'executions'}, {
                                 label: 'Average Time',
                                 key: 'averagetime'
                             }, {label: 'Wait Time', key: 'waittime'}]}
                             data={data}
                             download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Batches.csv`}
                             className="btn btn-sm btn-primary">
                        <i className="fal fa-file-export fa-fw"></i><span>Export</span>
                    </CSVLink>
                    <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                        className="far fa-undo"></i><span>Clear</span></button>
                </div>
                <div className="col col-md-3">
                    <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                           value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)}/>
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={(column as any).className}>
                                    {column.render('Header')}
                                    {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                            <i className="fal fa-sort-amount-up-alt"></i> :
                                            <i className="fal fa-sort-amount-down-alt"></i>) :
                                        <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={(cell.column as any).className}
                                            style={{borderLeft: (cell.column as any).color}}>

                                            {cell.column.id === "sqltext" ?
                                                <div className='tooltip-scroll ellipsis'
                                                     onMouseEnter={(event) => setVisibleToolTip({
                                                         id: cell.row.values.batchgrouphash,
                                                         position: getToolTipPosition(event)
                                                     })}
                                                     onMouseLeave={() => setVisibleToolTip({
                                                         id: '',
                                                         position: 0
                                                     })}
                                                >
                                                    {cell.render('Cell')}
                                                    {(cell.row.values.batchgrouphash === visibleToolTip.id &&
                                                        <StatementToolTip instanceType={instanceType}
                                                                          position={visibleToolTip.position}
                                                                          id={cell.row.values.batchgrouphash}
                                                                          link={`/instances/${props.instanceId}/activity/groupedbatch/${cell.row.values.batchgrouphash}/text`}
                                                                          cell={cell}/>
                                                    )}
                                                </div> : cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <NoData
                    error={null}
                    loading={props.loading === DATA_LOADING}
                    length={data.length}
                />
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </React.Fragment>
    );
}

export default GroupedBatchesTable;
