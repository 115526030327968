// React.
import { useContext, useMemo } from 'react';
import { TimeRangeContext } from "../../../context/TimeRangeContext";

// Types.
import { StatisticTimeslice } from '../../../types/instance/Statistic';

// Components.
import HighchartsWithData from '../../../component/HighchartsWithData';

// Helpers.
import { getConvertedTimeToUTC, highchartsCredits } from '../../../helpers/utils';
import { useLicences } from "../../../context/LicenceContext";
import { chartOptionsGranularity } from "../../../helpers/chartOptionsIncreasedGranularity";
import Helper from "../../../helpers/Helper";

// Constants
import { CHART_DATA_TYPE } from "../../Constants";

export default function StatisticChart(props: {
    error: string | null,
    loading: boolean, data: StatisticTimeslice[],
    statistic: string, cumulative: boolean, index: number, applyPeriod: Function
}) {
    const { error, loading, data, statistic, cumulative, index }
        = props;
    const { licences } = useLicences();
    const timeRangeContext = useContext(TimeRangeContext)

    const basicChart = useMemo(() => {
        const options = chartOptionsGranularity(props.applyPeriod, [
            {
                name: statistic,
                data: data.map((dat: any) => [
                    getConvertedTimeToUTC(dat),
                    dat[cumulative ?
                        'sum' : 'avg']
                ]),
                showInLegend: false,
                color: '#7cb5ec',
            }
        ], 0, {
            credits: { enabled: highchartsCredits(licences) },
            timeRangeContext: timeRangeContext,
            tooltip: {
                formatter: function () {
                    return Helper.getChartTooltipsNew(this, CHART_DATA_TYPE.GENERIC);
                }
            },
            yAxis: {
                labels: {
                    enabled: true
                },
                min: 0,
                title: {
                    text: ''
                },
                opposite: false
            }
        }, 200)
        return <HighchartsWithData error={error} loading={loading}
                                   length={data.length} options={options}/>;

    }, [timeRangeContext, data, statistic, cumulative]);

    return (
        <div className="card collapsible">
            <div className="card-header">
                <i className="fal fa-chart-bar fa-fw" aria-hidden="true"/>
                {statistic}
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target={"#collapse-" + index} aria-expanded="false" aria-controls={"collapse-" + index}/>
            </div>
            <div id={"collapse-" + index} className="card-body collapse show">
                {basicChart}
            </div>
        </div>
    )
}
