import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Third-parties.
import * as dayjs from 'dayjs';

import { Column, usePagination, useSortBy, useTable } from 'react-table';

import Skeleton from "react-loading-skeleton";

import { CSVLink } from 'react-csv';

// Helper.
import Helper from '../../../helpers/Helper';
import ConditionalRender from "../../../helpers/ConditionalRender";

// Types.
import StatusIcon from '../../StatusIcon';

// import StatusIconV2 from '../../StatusIcon';
import InstanceType from '../InstanceType';
import InstancesDataTable from '../../../types/instance/tables/InstancesDataTable';
import { getDefaultPageSize } from "../../../helpers/utils";

// Components.
import TimeStatistic from '../TimeStatistic';
import ExecutionsStatistic from "../ExecutionsStatistic";
import NoData from '../../NoData';
import TablePagination from '../../TablePagination';
import SortClass from '../../SortClass';
import Tags from "../../../types/Tags";

// Constants.
import { DATA_LOADING } from "../../Constants";
import { TimeRangeContext } from "../../../context/TimeRangeContext";

function InstancesTable(props: {
    error: string | null,
    loading: number,
    instancesDataTable: InstancesDataTable[],
    tags: Tags[]
}) {
    const [tableFilter, setTableFilter] = useState<string>('');
    const [columnOptions, setColumnOptions] = useState<string[]>([]);
    const [showColumnsSelection, setShowColumnsSelection] = useState<boolean>(false);
    const timeRangeContext = useContext(TimeRangeContext)
    const [instancesList, setInstancesList] = useState<InstancesDataTable[]>([]);

    const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
    const {tags} = props

    useEffect(() => {
        setInstancesList(props.instancesDataTable)
    }, [props.instancesDataTable]);

    function clearTableFilter() {

        // Clear any pre-existing filter values.
        setTableFilter('');
    }

    const removeInstance = (instanceId: number) => {
        const updatedInstancesList = instancesList.filter(instance => instance.id !== instanceId);
        setInstancesList(updatedInstancesList);
    }

    useEffect(() => {
        const tagNames = tags.map(item => item.tagname);

        // const mergedColumns = [...INSTANCE_COLUMNS, ...tagNames];

        setColumnOptions(tagNames)

    }, [tags]);

    const data = useMemo(() => {
        const filteredList: any[] = [];

        instancesList.forEach(row => {
            if (
                (row.name.toLowerCase().includes(tableFilter.toLowerCase()) ||
                    row.type.toLowerCase().includes(tableFilter.toLowerCase())) ||
                (tags.length > 0 &&
                    tags.some(tag => {
                        // @ts-ignore
                        const instanceTag = row[tag.tagname];
                        return (
                            instanceTag &&
                            instanceTag.toLowerCase().includes(tableFilter.toLowerCase()) &&
                            instanceTag !== ""
                        );
                    }))
            ) {
                filteredList.push(row);
            }
        });

        return filteredList;
    }, [instancesList, tableFilter, tags]);

    const columns: Column[] = useMemo(() => [
        {
            accessor: 'id',
            disableSortBy: true
        },
        {
            accessor: 'color',
            disableSortBy: true
        },
        {
            accessor: 'instance',
            disableSortBy: true
        },
        {
            Header: 'Instance',
            accessor: 'name',
            className: 'border-spacer fw-bold col-width-40 text-nowrap',
            canSort: true,
            sortDescFirst: false,
            sortType: 'basic',
            Cell: ({cell}) => (
                <React.Fragment>
                    <span className="me-1 ps-1" style={{backgroundColor: cell.row.values.color}}>&nbsp;</span>
                    <StatusIcon mode="public" removeFromList={removeInstance} instanceId={cell.row.values.id}/>
                    <Link to={`/instances/${cell.row.values.id}/activity?${timeRangeContext.getTimeRangeRedirectString()}`}>{cell.row.values.name}</Link>&nbsp;
                </React.Fragment>
            ),
        },
        {
            Header: '',
            accessor: 'type',
            className: 'text-center col-width-5',
            headerClassName: 'text-center col-width-5',
            canSort: true,
            sortDescFirst: false,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                <React.Fragment>
                    <InstanceType instance={cell.row.values.instance}/>
                </React.Fragment>
            )
        }, {
            Header: 'Changes',
            accessor: 'changes',
            headerClassName: 'text-center',
            className: 'text-center col-width-10',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                cell.row.values.changes !== null ? (
                    <React.Fragment>
                        {cell.row.values.changes > 0 ? <Link
                            to={`/instances/${cell.row.values.id}/events?filter=change`}>{Helper.getFormattedNumber(cell.row.values.changes)}</Link> : Helper.getFormattedNumber(cell.row.values.changes)}
                    </React.Fragment>
                ) : (
                    <Skeleton/>
                )
            )
        },
        {
            Header: 'Events',
            accessor: 'customEvents',
            headerClassName: 'text-center',
            className: 'text-center col-width-10',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                cell.row.values.customEvents !== null ? (
                    <React.Fragment>
                        {cell.row.values.customEvents > 0 ? <Link
                            to={`/instances/${cell.row.values.id}/events?filter=custom`}>{Helper.getFormattedNumber(cell.row.values.customEvents)}</Link> : Helper.getFormattedNumber(cell.row.values.customEvents)}
                    </React.Fragment>
                ) : (
                    <Skeleton/>
                )
            )
        },
        {
            Header: 'Alerts',
            accessor: 'alerts',
            headerClassName: 'text-center',
            className: 'text-center col-width-10',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                cell.row.values.alerts !== null ? (
                    <React.Fragment>
                        <React.Fragment>
                            <ConditionalRender if={cell.row.values.alerts}>
                                <i className="fal fa-exclamation-triangle" style={{'color': 'red'}}/>
                            </ConditionalRender>
                            {cell.row.values.alerts > 0 ? <Link
                                to={`/instances/${cell.row.values.id}/events?filter=alert`}>{Helper.getFormattedNumber(cell.row.values.alerts)}</Link> : Helper.getFormattedNumber(cell.row.values.alerts)}
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <Skeleton/>
                )
            )
        },
        {
            Header: 'Total Time',
            accessor: 'time',
            headerClassName: 'text-end ',
            className: 'text-end col-width-5',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                <TimeStatistic current={cell.value} previous={(cell.row.original as any).previousTime}/>
            )
        },
        {
            Header: 'Executions',
            accessor: 'executions',
            headerClassName: 'text-end',
            className: 'text-end col-width-10',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                <ExecutionsStatistic current={cell.value} previous={(cell.row.original as any).previousExecutions}/>
            )
        },
        {
            Header: 'Average Time',
            accessor: 'averageTime',
            headerClassName: 'text-end',
            className: 'text-end col-width-15',
            canSort: true,
            sortDescFirst: true,
            sortType: 'basic',
            Cell: ({cell}: any) => (
                <TimeStatistic current={cell.value} previous={(cell.row.original as any).previousAverageTime}/>
            )
        },
        ...(tags.length > 0 ? tags.filter(tag => visibleColumns.includes(tag.tagname))
                .map((tag, i) => ({
                    Header: tag.tagname,
                    accessor: tag.tagname,
                    headerClassName: 'text-end',
                    className: 'text-end',
                    Cell: ({cell}: any) => (
                        <React.Fragment key={i}>
                            {/*<a onClick={() => props.updateUrlParams(cell.row.values[tag.tagname], tag.tagname)}>{cell.row.values[tag.tagname]}</a>*/}
                            {cell.row.values[tag.tagname]}
                        </React.Fragment>
                    ),
                }))
            : []),

    ], [props.tags, visibleColumns])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: ['id', 'color', 'instance'],
            pageIndex: 0,
            pageSize: getDefaultPageSize(),
            sortBy: [{id: 'time', desc: true}, {id: 'id', desc: false}]
        },
        disableSortRemove: true
    }, useSortBy, usePagination);

    useEffect(() => {
        const savedValues = window.localStorage.getItem('instanceListColumns')
        if (savedValues && tags.length) {
            // check if there are any saved tags that does not exist anymore
            const filteredValues = JSON.parse(savedValues).filter((tag: string) => {
                return tags.some(dbTag => dbTag.tagname === tag);
            });
            setVisibleColumns(filteredValues)
            window.localStorage.setItem('instanceListColumns', JSON.stringify(filteredValues))
        }
    }, [tags]);


    function updateColumns(value: string) {
        const newColumns: string[] = [...visibleColumns]
        if (!newColumns.includes(value)) {
            newColumns.push(value);
        } else {
            const index = newColumns.indexOf(value);
            if (index !== -1) {
                newColumns.splice(index, 1);
            }
        }

        window.localStorage.setItem('instanceListColumns', JSON.stringify(newColumns))
        setVisibleColumns(newColumns)
    }

    return (
        <React.Fragment>
            <div className="row row-cols-1 row-cols-md-2 table-search">
                <div className="col col-md-9">
                    <CSVLink role="button"
                             headers={[{label: 'ID', key: 'id'}, {
                                 label: 'Instance Name',
                                 key: 'name'
                             }, {label: 'Instance Type', key: 'type'}, {label: 'Total Time', key: 'time'}]}
                             data={data}
                             download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - Instances.csv`}
                             className="btn btn-sm btn-primary">
                        <i className="fal fa-file-export fa-fw"/><span>Export</span>
                    </CSVLink>
                    <button className="btn btn-sm btn-dark" onClick={clearTableFilter}><i
                        className="far fa-undo"/><span>Clear</span></button>
                </div>
                <div className="col col-md-3 d-flex">


                    <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                           value={tableFilter} data-lpignore={true} onChange={(e) => setTableFilter(e.target.value)}/>
                    <ConditionalRender if={tags.length}>
                        <div data-bs-toggle="dropdown" aria-expanded="false"
                             className='btn btn btn-dropdown' id="columns"
                             aria-labelledby="columns" onClick={() => setShowColumnsSelection(!showColumnsSelection)}><i
                            className="fal fa-gear fa-fw"/></div>
                        <div id="columnsDropdown" className="dropdown-menu columns-dropdown-menu"
                             aria-labelledby="period"
                             onClick={(e) => e.stopPropagation()}>
                            <div className='m-0 px-2'>
                                <p className='m-0 p-0'>Tag Columns:</p>
                                <hr className='m-1'/>
                                {columnOptions.map((item: string, i: number) => <div key={i}
                                                                                     className='user-select-none mb-1'>
                                    <input
                                        onClick={() => updateColumns(item)} checked={visibleColumns.includes(item)}
                                        type="checkbox"
                                        id={item}
                                        className="form-check-input pointer"
                                        data-lpignore={true}
                                    />
                                    <label className='pointer' htmlFor={item}>{item}</label>
                                </div>)}
                            </div>
                        </div>
                    </ConditionalRender>


                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={(column as any).headerClassName}>
                                    {column.render('Header')}
                                    <SortClass column={column}/>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={(cell.column as any).className}
                                            style={{borderLeft: (cell.column as any).color}}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <NoData
                    error={props.error}
                    loading={props.loading === DATA_LOADING}
                    length={data.length}
                />
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </React.Fragment>
    );
}

export default InstancesTable;
