import { createContext, useContext } from 'react';

// Types.
import Licence from '../types/Licence';

// Set the internal context object.
let licences: Licence[] = [];

// Define the context type, including the update function.
export type LicenceContextType = {
    licences: Licence[];
    setLicences: (Licences: Licence[]) => void;
}

// Define the context object.
export const LicenceContext = createContext<LicenceContextType>({ licences: licences, setLicences: licences => console.warn('No licences set.', licences) });
LicenceContext.displayName = 'Licences';

// Export the update function for the context object.
export const useLicences = () => useContext(LicenceContext);