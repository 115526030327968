// React.
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Third-parties.
import * as dayjs from 'dayjs';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { CSVLink } from 'react-csv';

// Types.
import InstanceTarget from '../../../types/instance/InstanceTarget';
import { TempDbUsageSession } from '../../../types/instance/TempDb';

// Components.
import NoData from "../../NoData";
import TablePagination from "../../TablePagination";

// Helper.
import { getDefaultPageSize, getToolTipPosition } from '../../../helpers/utils';
import StatementToolTip from "./StatementToolTip";
import Helper from "../../../helpers/Helper";
import { FilterType } from "../../../typescript/Enums";

export default function TempDbUsageTable(props: {
    error: string | null,
    loading: boolean,
    data: TempDbUsageSession[],
    instance: InstanceTarget,
    setFilterOptions: Function
}) {
    const { error, loading, data, instance } = props;
    const [tableFilter, setTableFilter] = useState<string>('');
    const [visibleToolTip, setVisibleToolTip] = useState({
        id: '',
        position: 0
    });

    // CSV headers
    let headers: any[] = useMemo(() => {
        return [
            {
                label: 'Session',
                key: 'session'
            },
            {
                label: 'Login Time',
                key: 'logintime'
            },
            {
                label: 'Client',
                key: 'client'
            },
            {
                label: 'Username',
                key: 'username'
            },
            {
                label: 'Program',
                key: 'program'
            },
            {
                label: '#',
                key: 'sqlhash'
            },
            {
                label: 'SQL Text',
                key: 'sqltext'
            },
            {
                label: 'User Objects Alloc',
                key: 'userobjectsallocpagecount'
            },
            {
                label: 'User Objects Dealloc',
                key: 'userobjectsdeallocpagecount'
            },
            {
                label: 'Internal Objects Alloc',
                key: 'internalobjectsallocpagecount'
            },
            {
                label: 'Internal Objects Dealloc',
                key: 'internalobjectsdeallocpagecount'
            },
            {
                label: 'User Objects Deferred Dealloc',
                key: 'userobjectsdeferreddeallocpagecount'
            }
        ]
    }, []);

    const goToActivityPageWithSession = (sessionId: string) => {
        const options = Helper.getFilterParams()
        props.setFilterOptions(Helper.updateFilterOptions(FilterType.Sessions, sessionId, options));
    }

    // Table columns
    const columns: Column[] = useMemo(() => {
        return [
            {
                Header: 'Session',
                accessor: 'session',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic',
                Cell: ({ cell }: any) => {
                    return (<Link onClick={() => goToActivityPageWithSession(cell.value)} to={`/instances/${instance.id}/activity?sessions=${cell.value}`}>{cell.value}</Link>)
                }
            },
            {
                Header: 'Login Time',
                accessor: 'logintime',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic'
            },
            {
                Header: 'Client',
                accessor: 'client',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic'
            },
            {
                Header: 'Username',
                accessor: 'username',
                headerClassName: 'text',
                canSort: true,
                sortDescFirst: false,
                sortType: 'basic'
            },
            {
                Header: 'Database',
                accessor: 'database',
                headerClassName: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: false
            },
            {
                Header: 'Program',
                accessor: 'program',
                headerClassName: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: false
            },
            {
                Header: 'User Objects Alloc',
                accessor: 'userobjectsallocpagecount',
                headerClassName: 'text text-wrap',
                className: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true
            },
            {
                Header: 'User Objects Dealloc',
                accessor: 'userobjectsdeallocpagecount',
                headerClassName: 'text text-wrap',
                className: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true
            },
            {
                Header: 'Internal Objects Alloc',
                accessor: 'internalobjectsallocpagecount',
                headerClassName: 'text text-wrap',
                className: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true
            },
            {
                Header: 'Internal Objects Dealloc',
                accessor: 'internalobjectsdeallocpagecount',
                headerClassName: 'text text-wrap',
                className: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: true
            },
            {
                Header: 'User Objects Deferred Dealloc',
                accessor: 'userobjectsdeferreddeallocpagecount',
                headerClassName: 'text text-wrap',
                className: 'text',
                canSort: true,
                sortType: 'basic',
                sortDescFirst: false
            }
        ]
    }, [instance.id]);

    const tableData = useMemo(() =>
            data.filter((row) =>
                row.session.toLowerCase().includes(tableFilter.toLowerCase()) ||
                row.logintime.toLowerCase().includes(tableFilter.toLowerCase())),
        [data, tableFilter]);

    const csvData = useMemo(() => {
        // Escape embedded double-quotation marks (") in the SQL text.
        const csvData: any[] = JSON.parse(JSON.stringify(data));

        csvData.forEach((item) => {
            if (item.sqltext) {
                item.sqltext = item.sqltext.replaceAll('"', '""');
            }
        });

        return csvData;
    }, [data]);

    const
        {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            setPageSize,
            pageOptions,
            pageCount,
            canPreviousPage,
            previousPage,
            canNextPage,
            nextPage,
            gotoPage,
            state: { pageIndex, pageSize }
        } = useTable(
            {
                columns,
                data: tableData,
                initialState:
                    {
                        pageIndex: 0,
                        pageSize: getDefaultPageSize()
                    },
                disableSortRemove: true
            }, useSortBy, usePagination);

    function clearTableFilter() {
        setTableFilter('');
    }


    // The bracket must be present and on the same line as the return.
    return (
        <div className="card">
            <div className="card-header">
                <i className="fal fa-scroll fa-fw" aria-hidden="true"/>
                Session Usage (pages)
                <i className="collapse-toggle" role="button" data-bs-toggle="collapse"
                   data-bs-target="#collapse-usage" aria-expanded="false" aria-controls="collapse-usage"/>
            </div>
            <div id="collapse-usage" className="card-body collapse show">
                <div className="row row-cols-1 row-cols-md-2 table-search">
                    <div className="col col-md-9">
                        <CSVLink role="button"
                                 headers={headers}
                                 data={csvData}
                                 download={`DBmarlin - ${dayjs.default().format('YYYY-MM-DD HH:mm')} - SQL Statistics.csv`}
                                 className="btn btn-sm btn-primary">
                            <i className="fal fa-file-export fa-fw"/>
                            <span>Export</span>
                        </CSVLink>
                        <button className="btn btn-sm btn-dark"
                                onClick={clearTableFilter}>
                            <i className="far fa-undo"/>
                            <span>Clear</span>
                        </button>
                    </div>
                    <div className="col col-md-3">
                        <input type="text" autoFocus className="form-control form-control-sm" placeholder="Search"
                               value={tableFilter} data-lpignore={true}
                               onChange={(e) => setTableFilter(e.target.value)}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table">
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={(column as any).headerClassName}>
                                        {column.render('Header')}
                                        {column.canSort ? (column.isSorted ? (column.isSortedDesc ?
                                                <i className="fal fa-sort-amount-up-alt"></i> :
                                                <i className="fal fa-sort-amount-down-alt"></i>) :
                                            <i className="fal fa-sort-amount-down-alt text-light"></i>) : ''}
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className={(cell.column as any).className}>
                                                {cell.column.id === "sqltext" ?
                                                    <div className='tooltip-scroll ellipsis'
                                                         onMouseEnter={(event) => setVisibleToolTip({
                                                             id: cell.row.values.logintime,
                                                             position: getToolTipPosition(event)
                                                         })}
                                                         onMouseLeave={() => setVisibleToolTip({
                                                             id: '',
                                                             position: 0
                                                         })}
                                                    >
                                                        {cell.render('Cell')}
                                                        {(cell.row.values.logintime === visibleToolTip.id && cell.row.values.sqlhash &&
                                                            <StatementToolTip instanceType={props.instance.type}
                                                                              position={visibleToolTip.position}
                                                                              id={cell.row.values.logintime}
                                                                              link={`/instances/${props.instance.id}/activity/statement/${cell.row.values.sqlhash}/text`}
                                                                              cell={cell}/>
                                                        )}
                                                    </div> : cell.render('Cell')}
                                            </td>)
                                    })}
                                </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                <NoData error={error} loading={loading}
                        length={data.length}/>
                <TablePagination
                    length={data.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                />
            </div>
        </div>
    )
}
