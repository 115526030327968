import api from "../Base";
import AlertRules from "../../types/Alerts/AlertRules";
import AlertActions from "../../types/Alerts/AlertActions";
import Period from "../../types/Period";

const AlertApi = {

    async makeAlertApiCall(url: string) {
        let alerts: any = [];

        await api.get(url)
            .then((response: { data: any; }) => {
                alerts = response.data;
            })
            .catch((error: any) => {
                // Todo: handle and log the error.
                console.log('An error occurred:', error);
            })
            .then(function () {
                // Todo: Always executed - do we need this?
            });

        return alerts
    },

    async getAlertsSummaryCount(period: Period, type: string, id?: number, ) {
        let idParam = id && (type === 'host' ? `&hostid=${id}` : `&id=${id}`)
        idParam = idParam ||  (type === 'host' ? `&entitytype=host` : `&entitytype=datasource`);

        const alerts = await this.makeAlertApiCall(`${process.env.REACT_APP_API}alert/summary?from=${period.api.current.from}&to=${period.api.current.to}&tz=${period.api.timezone}&interval=0${idParam}`)
        return alerts.length ? alerts[0].count : 0
    },


    getAlertRules:async () => {
        let url = `/alert/rule`;
        let alertRules: AlertRules[] = [];

        await api.get<AlertRules[]>(url)
            .then((response) => {
                alertRules = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve alert rules.', error);
            })
            .then(function () {});
        return alertRules;
    },

    getAlertActions:async () => {
        let url = `/alert/action`;
        let alertActions: AlertActions[] = [];

        await api.get<AlertActions[]>(url)
            .then((response) => {
                alertActions = response.data;
            })
            .catch((error: any) => {
                console.error('Failed to retrieve alert actions.', error);
            })
            .then(function () {});
        return alertActions;
    },
}

export default AlertApi;